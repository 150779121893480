import React, { useEffect } from "react";

export default function TrackedDropdown({
  options,
  domTarget,
  onClose,
}: {
  options: { url: string; name: string }[];
  domTarget: HTMLElement;
  onClose: () => void;
}) {
  const trackedContainers = React.useRef([domTarget.parentElement]);
  const [targetBounds, setBounds] = React.useState({
    top: 0,
    left: -1000,
    width: 0,
  });
  const [inverted, setInverted] = React.useState(false);
  const thisDom = React.useRef<any>(null);

  const [selectedIndex, setSelected] = React.useState(-1);

  useEffect(() => {


    let onKey = (e: KeyboardEvent) => {
      if (e.key === "ArrowDown") {
        e.preventDefault();
        setSelected((prevIndex) => (prevIndex + 1) % options.length);
      } else if (e.key === "ArrowUp") {
        e.preventDefault();
        setSelected(
          (prevIndex) => (prevIndex - 1 + options.length) % options.length
        );
      } else if (e.key === "Enter") {
        if (thisDom.current) {
          let selected = thisDom.current.querySelector(".selected");
          if (selected) {
            (selected as HTMLElement).click();
          }
        }
      }
    };

    window.addEventListener("resize", onUpdatePosition);
    document.addEventListener("click", onCheckClickOutside);
    document.addEventListener("keydown", onKey);
    document.addEventListener("scroll", onUpdatePosition);
    let parent = domTarget.parentElement;

    const observer = new MutationObserver((mutations) => {
      onUpdatePosition();
    });
    const resizeObserver = new ResizeObserver((entries) => {
      onUpdatePosition();
    });

    resizeObserver.observe(domTarget);

    if ((window as any).isEditor) {
      let can = document.querySelector(".preview-content");
      if (can) {
        resizeObserver.observe(can);
      }
    }

    observer.observe(domTarget, {
      attributes: true,
      childList: true,

      subtree: true,
    });

    onUpdatePosition();
    while (parent?.parentElement) {
      parent = parent.parentElement;
      trackedContainers.current.push(parent);
    }
    track();
    return () => {
      observer.disconnect();
      resizeObserver.disconnect();
      document.removeEventListener("click", onCheckClickOutside);
      window.removeEventListener("resize", onUpdatePosition);
      document.removeEventListener("keydown", onKey);
      document.removeEventListener("scroll", onUpdatePosition);
      endTrack();
    };
  }, [domTarget]);

  const onCheckClickOutside = (e: MouseEvent) => {
    if (thisDom.current && !thisDom.current.contains(e.target as Node)&&!domTarget.contains(e.target as Node)) {
      onClose();
    }
  };

  const onUpdatePosition = () => {
    let bounds = domTarget.getBoundingClientRect();

    //hide when the button goes past previewe screen
    if (
      (window as any).isEditor &&
      (bounds.bottom < 50 || bounds.top > window.innerHeight - 50)
    ) {
      setBounds({ top: -1000, left: -1000, width: 0 });
    } else if (bounds.bottom < window.innerHeight - 300) {
      setBounds({ top: bounds.bottom, left: bounds.left, width: bounds.width });
      setInverted(false);
    } else {
      setBounds({ top: bounds.top, left: bounds.left, width: bounds.width });
      setInverted(true);
    }
  };

  const track = () => {
    for (let i = 0; i < trackedContainers.current.length; i++) {
      if (trackedContainers.current[i])
        trackedContainers.current[i]!.addEventListener(
          "scroll",
          onUpdatePosition
        );
    }
  };
  const endTrack = () => {
    for (let i = 0; i < trackedContainers.current.length; i++) {
      if (trackedContainers.current[i])
        trackedContainers.current[i]!.removeEventListener(
          "scroll",
          onUpdatePosition
        );
    }

    trackedContainers.current = [];
  };

  return (
    <div
      ref={thisDom}
      className="tracked-dropdown"
      style={{
        position: "fixed",
        zIndex: "11",
        left: targetBounds.left + "px",
        top: targetBounds.top + "px",
        minWidth: targetBounds.width,
        maxHeight: "200px",
        overflow: "auto",
        transform: inverted ? "translateY(-100%)" : "",
      }}
    >
      {options.map((o, i) => {
        const selected = i == selectedIndex ? "selected" : "";
        return (
          <a
            onClick={() => onClose()}
            className={"tracked-dropdown-item no-a " + selected}
            key={i}
            href={o.url}
          >
            {o.name}
          </a>
        );
      })}
    </div>
  );
}




