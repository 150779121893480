import React from "react";
import RenderImageSlider from "../ComponentRenders/RenderImageSlider";
import LayoutNode from "../Definitions/LayoutNode";
import { UserContext } from "../Definitions/UserContext";
import { wBComponentTypeNames } from "../Definitions/WBComponentTypeNames";
import RenderNavigationList from "../ComponentRenders/RenderNavigationList";
import RenderBeforeAndAfter from "../ComponentRenders/RenderBeforeAndAfter";
import RenderVideoPlayer from "../ComponentRenders/RenderVideoPlayer";
import RenderProductDetails from "../BusinessControls/RenderProductDetails";
import RenderNavigationBar from "../ComponentRenders/RenderNavigationBar";
import RenderButtonDropDown from "../ComponentRenders/RenderButtonDropdown";

export default function GenericDynamicComponent({dataKey,node,context}:{dataKey:string,node:LayoutNode,context:UserContext}){


    switch(dataKey){
        case wBComponentTypeNames.imageSlider:
            return <RenderImageSlider component={node} screenSize={0} userContext={context} isEditor={false} children={undefined} />;
          
        case wBComponentTypeNames.beforeAndAfter:
           return <RenderBeforeAndAfter component={node} screenSize={0} userContext={context} isEditor={false} children={undefined} />;
           case wBComponentTypeNames.navigationBar:
            return <RenderNavigationBar  node={node} userContext={context} />;
        case wBComponentTypeNames.navigationList:
             return <RenderNavigationList  node={node} isEditor={ (window as any).isEditor == true }/>;
        case wBComponentTypeNames.videoPlayer:
            return <RenderVideoPlayer component={node} />;
        case wBComponentTypeNames.button: // bind dropdownmenu when button is presetn
            return <RenderButtonDropDown component={node} />;

        
        default:
            return <></>
    }

}