import React, { Fragment, useEffect } from "react";
import { UserContext } from "../Definitions/UserContext";
import { utils } from "../../utils/Utils";
import CommonMessagepopup from "../../VagaroComponent/CommonMessagepopup";
import ReactDom from "react-dom";
import { GetFacilityInformation } from "../Utilities/BusinessData";


const CountryCodes = [
    "US",
    "CA",
    "AU",
    "UK",
]


export default function RenderBusinessCall({ layout = "1", siteData }: { layout: string, siteData: UserContext }) {


    const IsEditor = (window as any).isEditor !== undefined ? (window as any).isEditor : false;
    const [number, setNumber] = React.useState<string>(" •••  •••  •••• ");
    const GetCountryName=(countryId:number)=>
    {
        switch (countryId)
        {
            case 1:
                return "US";
            case 2:
                return "UK";
            case 3:
                return "CA";
            case 4:
                return "AU";
    
            default:
                return "US";
        }
    }

    useEffect(() => {
        if(IsEditor){

            GetFacilityInformation(siteData,(data:any)=>{

                if(data.Phone){
                    setNumber(data.Phone);
                }
                else{
                    setNumber("not available");
                }

            },(error:any)=>{setNumber("not available")});

        }

    },[]);

    switch (layout) {
        default:
            return <MessageCall siteData={siteData} />
    }

    function MessageCall({ siteData }: { siteData: any }) {

        const fullNumber = !(layout=="2");

        var businessDataPhone = utils.MultiLocationDataPhone(siteData, siteData.businessId, IsEditor);
        if (utils.isNotEmptyOrWhiteSpace(businessDataPhone)) {
            var businessData = businessDataPhone;
        }
        else {
            var businessData = utils.MultiLocationData(siteData, siteData.bidHash);
        }

        var tele  = (IsEditor)?number:businessData.telephone;
        let teleString = tele;

        let group = GetCountryName( (IsEditor?siteData.countryCode:businessData.countryID))??"US";

      

        


        if(teleString&&!teleString.includes("not available")){

            teleString = teleString.replace(/[^0-9]/g, '');

            
            switch(group){
                case "AU":
                    teleString = "("+teleString.substring(0,2)+") "+teleString.substring(2,6)+" "+teleString.substring(6,11);
                    break;
                case "UK":
                    teleString = teleString.substring(0,3)+" "+teleString.substring(3,7)+" "+teleString.substring(7,11);
                    break;
                case "CA":
                    teleString = teleString.substring(0,3)+"-"+teleString.substring(3,6)+"-"+teleString.substring(6,10);
                    break;
                default: //US
                    teleString = "("+teleString.substring(0,3)+") "+teleString.substring(3,6)+"-"+teleString.substring(6,10);
                    break;
            }
        }
      
        return <Fragment>
            {!utils.isNullEmptyOrWhiteSpace(tele) ?
                <a role="button"  className="v-button primary w-full  center-all row-gap-med invisible-button realative-posi"  href={"tel:"+tele.replace(/[^0-9]/g, '')} ><span className="call-text gap-med"><svg height="1em" viewBox="0 0 516 515" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path d="M5 386C0 365 11 343 31 334L133 290C151 283 173 288 185 303L219 344C271 315 315 271 344 219L303 186C288 173 283 151 291 133L334 31C343 11 365 0 386 5L480 27C501 31 516 49 516 71C516 316 316 515 71 515C49 515 31 501 26 480L5 386ZM36 379L58 473C59 479 64 484 71 484C298 484 484 298 484 71C484 64 479 59 473 58L379 36C378 36 377 36 376 36C371 36 366 39 364 44L320 145C318 151 319 157 324 161L374 202C380 207 382 215 378 222C345 289 289 345 221 378C215 382 207 380 202 374L161 324C157 319 151 318 145 320L44 363C38 366 34 372 36 379Z" fill="currentColor"/>
                 </svg><span className="call-span no-shrink">{(fullNumber?teleString: "Call") }</span></span><span className="hidden-text gap-med"><svg height="1em" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 128C0 93 28 64 64 64H448C483 64 512 93 512 128V384C512 420 483 448 448 448H64C28 448 0 420 0 384V128ZM32 128V168L227 312C244 324 267 324 284 312L480 168V128C480 111 465 96 448 96H63C46 96 31 111 31 128H32ZM32 208V384C32 402 46 416 64 416H448C465 416 480 402 480 384V208L303 338C275 358 236 358 208 338L32 208Z" fill="currentColor"/> </svg>  <span className="call-span no-shrink">{(fullNumber?teleString: "Call") }</span></span></a> :
                <a role="button" className="v-button  primary w-full center-all gap-med realative-posi" onClick={(event) => !IsEditor && (window as any).BusinessCall(siteData,businessData)}><span className="call-text gap-med"><svg height="1em" viewBox="0 0 516 515" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 386C0 365 11 343 31 334L133 290C151 283 173 288 185 303L219 344C271 315 315 271 344 219L303 186C288 173 283 151 291 133L334 31C343 11 365 0 386 5L480 27C501 31 516 49 516 71C516 316 316 515 71 515C49 515 31 501 26 480L5 386ZM36 379L58 473C59 479 64 484 71 484C298 484 484 298 484 71C484 64 479 59 473 58L379 36C378 36 377 36 376 36C371 36 366 39 364 44L320 145C318 151 319 157 324 161L374 202C380 207 382 215 378 222C345 289 289 345 221 378C215 382 207 380 202 374L161 324C157 319 151 318 145 320L44 363C38 366 34 372 36 379Z" fill="currentColor"/>
                </svg> <span className="call-span no-shrink"> {(fullNumber?teleString: "Call") }</span></span><span className="hidden-text gap-med"><svg height="1em" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 128C0 93 28 64 64 64H448C483 64 512 93 512 128V384C512 420 483 448 448 448H64C28 448 0 420 0 384V128ZM32 128V168L227 312C244 324 267 324 284 312L480 168V128C480 111 465 96 448 96H63C46 96 31 111 31 128H32ZM32 208V384C32 402 46 416 64 416H448C465 416 480 402 480 384V208L303 338C275 358 236 358 208 338L32 208Z" fill="currentColor"/> </svg>  <span className="call-span no-shrink">{(fullNumber?teleString: "Call") }</span></span></a>
            }
        </Fragment>
    }
}

declare global {
    interface Window { BusinessCall: any; }
}

window.BusinessCall = function (siteData :any,businessData:any) {    
    return ReactDom.render(
        <CommonMessagepopup   
            data={businessData}                 
            siteData={siteData}  
            isBusinessCall={true}          
        />,
        document.getElementById("OpenDialog1")
    );
};