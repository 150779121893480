import React from 'react';
import { NoRecordsBanner } from '../OopsBanner';

export default function RenderBusinessDescription({ data, layout }: { data: any, layout: string }) {
       if(data?.isStenciled){
              const style = {
                     maxHeight:"1em",
                     overflow:"hidden",
                     opacity:1
              }

              return <div className="stenciled  flex-column gap-small w-full">
                     <div className='w-full stencil-item' style={style}>This page contains basic business information like your address, phone number, and your personal Vagaro URL. This information will show up on your Vagaro page andor custom website. This page contains basic business information like your address, phone number, and your personal Vagaro URL. This information will show up on your Vagaro page andor custom website. This page contains basic business information like your address, phone number, and your personal Vagaro URL. This information will show up on your Vagaro page andor custom website.</div>
                     <div className='w-full stencil-item' style={style}>This page contains basic business information like your address, phone number, and your personal Vagaro URL. This information will show up on your Vagaro page andor custom website. This page contains basic business information like your address, phone number, and your personal Vagaro URL. This information will show up on your Vagaro page andor custom website. This page contains basic business information like your address, phone number, and your personal Vagaro URL. This information will show up on your Vagaro page andor custom website.</div>
                     <div className='w-full stencil-item' style={style}>This page contains basic business information like your address, phone number, and your personal Vagaro URL. This information will show up on your Vagaro page andor custom website. This page contains basic business information like your address, phone number, and your personal Vagaro URL. This information will show up on your Vagaro page andor custom website. This page contains basic business information like your address, phone number, and your personal Vagaro URL. This information will show up on your Vagaro page andor custom website.</div>
                     <div className='w-full stencil-item' style={style}>This page contains basic business information like your address, phone number, and your personal Vagaro URL. This information will show up on your Vagaro page andor custom website. This page contains basic business information like your address, phone number, and your personal Vagaro URL. This information will show up on your Vagaro page andor custom website. This page contains basic business information like your address, phone number, and your personal Vagaro URL. This information will show up on your Vagaro page andor custom website.</div>
                     <div className='w-full stencil-item' style={{...style,maxWidth:"80%"}}>This page contains basic business information like your address, phone number, and your personal Vagaro URL. This information will show up on your Vagaro page andor custom website. This page contains basic business information like your address, phone number, and your personal Vagaro URL. This information will show up on your Vagaro page andor custom website. This page contains basic business information like your address, phone number, and your personal Vagaro URL. This information will show up on your Vagaro page andor custom website.</div>
              </div>
       }
       
       else if (!data.description || data.description.length < 2)
              return <NoRecordsBanner message='Business description is not available.' />

              const desc = data.description;//.replace(/&amp;/g, "&").replace(/&lt;/g, "<").replace(/&gt;/g, ">");

       switch (layout) {
              default:
                     return <span dangerouslySetInnerHTML={{__html:desc}}></span> //setting inner to handle special chars and emojies
       }

}