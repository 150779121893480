import React, { useEffect, useRef } from 'react'
import ReactDOM, { unmountComponentAtNode, createPortal } from "react-dom";
import { Fragment, useState } from 'react';
import $, { event } from "jquery";
import "./VgAddonServicesPopups.scss";
import { ResponseCode, addtoCartEnum, utils } from '../utils/Utils';
import VGPopUp from '../components/Controls/VGPopUp';
import { AsyncAjaxPost } from '../components/Utilities/AsyncAjaxService'
import { Form } from 'react-bootstrap';
import Loader from '../components/Common/Loader';
import RenderBusinessCart from "../components/BusinessControls/RenderBusinessCart";


const Addon = (props) => {

    const ServiceData = props.Services;
    const CommanData = props.commonData;
    const SiteData = props.SiteData;
    const encBID = props.EndBID;

    const [addonservice, setaddonservice] = useState([]);
    const [parentService, setparentService] = useState([]);
    const [childService, setchildService] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [isContentLoading, setContentLoading] = useState(false);
    const [data, setdata] = useState([]);
    const [res, setres] = useState("");


    const SerialNo = useRef(1);
    const SelectedServiceAddon = useRef([]);

    const [SerialNumber, setSerialNumber] = useState(SerialNo.current);

    const AddonServiceBundleDetailBookNow = useRef([]);

    const [serviceId, setServiceId] = useState(ServiceData.encServiceId);
    const [IsBundle, setIsBundle] = useState(ServiceData.isBundle);
    const [ServiceTitle, setServiceTitle] = useState(ServiceData.serviceTitle);
    const [ServiceDesc, setServiceDesc] = useState(ServiceData.serviceDesc);
    const TotalPrice = useRef(ServiceData.price);

    const [promotionDetail, setPromotionDetail] = useState(ServiceData.promotionDetail)
    const [promotionTitle, setPromotionTitle] = useState(ServiceData.promotionTitle);

    let currencySymbol = CommanData.currencySymbol;

    const [ServicePrice, setServicePrice] = useState(!IsBundle ? ServiceData.price : 0);
    const [Price, setPrice] = useState(!IsBundle ? ServiceData.price : 0);
    const [ServicePhotoURL, setServicePhotoURL] = useState(ServiceData.serviceImageUrl);
    const [ServiceAddonTitle, setServiceAddonTitle] = useState("");


    const GetListAddonService = (SerialNo, isCheckNextSerialNumber) => {
        if (isCheckNextSerialNumber) {
            SerialNo = SerialNo + 1;
            var service = $.grep(AddonServiceBundleDetailBookNow.current, function (e) {
                return e.serialNo == SerialNo;
            });
        }
        else {
            setSerialNumber(SerialNo);
            var service = $.grep(AddonServiceBundleDetailBookNow.current, function (e) {
                return e.serialNo == SerialNo;
            });
            setaddonservice(service);
            if (CommanData.servicebundleList.length > 0 && service.length > 0) {
                const FilterAddonMasterData = CommanData.servicebundleList.filter(
                    (col) => col.serviceIdHash === service[0].eventServiceIDHash
                );
                if (FilterAddonMasterData.length > 0) {
                    setServicePhotoURL("");
                    setServiceAddonTitle(FilterAddonMasterData[0].serviceTitle);
                    setServicePrice(FilterAddonMasterData[0].servicePrice);
                    setPrice(FilterAddonMasterData[0].servicePrice);
                }
            }
            setparentService([]);
            setchildService([]);
            ParentServicefunctions(service);
        }
        return service;
    }

    let parseTodecimal = (value, decimals) => {
        return parseFloat((Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)).toFixed(decimals));
    }
    const ApplyMultipleTax = (AmtPrice, itemList) => {
        var taxes = itemList.BusinessTax;
        if (typeof taxes != "undefined" && taxes != null && taxes != '') {
            $.grep(taxes, function (Item) {
                Item.CalculatedTax = parseTodecimal((AmtPrice * Item.Tax) / 100, 3);
            });
        }
        return taxes;
    }
    var ApplyDiscoutTypePrepay = {
        NoDiscount: 0,
        Package: 1,
        GC: 2,
        Points: 4,
        Membership: 3,
        OnePerDealDiscount: 5,
        OutsideBiling: 6,
        ServcieMembershipAddon: 7,
        ServciePackageAddon: 8,
        PointsAddon: 9
    };
    var arrpaidType = {
        UnPaid: 0,
        Paid: 1,
        Deposits: 2,
        PartiallyPaid: 3
    };

    const AddonValidate = (EventServiceID) => {
        if (EventServiceID > 0) {
            var tempadddata = data.filter(
                (col) => col.encEventServiceID === EventServiceID
            );
        }
        else {
            var tempadddata = data;
        }
        if (tempadddata == undefined || tempadddata == null || tempadddata.length == 0) {
            return true;
        }
        var parentService = $.grep(tempadddata, function (service) {
            return service.serviceLevel == 0;
        });
        var _AllValidateAddon = 0;

        $.each(parentService, function (index) {
            var childService = $.grep(tempadddata, function (service) {
                return service.parentServiceIDHash == parentService[index].serviceIDHash && service.isOnlineService == true;;
            });
            var _isvalidate = ValidateAddonService(childService, parentService[index].minItem, parentService[index].maxItem, parentService[index].isRequired, false);
            $("#errMsg_" + parentService[index].divID).text(_isvalidate);
            $("#divErrorAddon_" + parentService[index].divID).hide();
            if (_isvalidate != "") {
                $("#divErrorAddon_" + parentService[index].divID).show();
                _AllValidateAddon++;
            }
        });

        if (_AllValidateAddon == 0) {
            return true;
        }
        else {
            return false;
        }
    }

    const ValidateAddonService = function (services, minitem, maxitm, isreq, isChecked) {
        var validatemsg = "";
        var ischkedcnt = 0;
        if (minitem != 0 || isreq == 1 || maxitm != 0) {
            $.each(services, function (index) {
                if (minitem == 1 && maxitm == 1) {
                    if ($("#rdo_" + services[index].divID).prop("checked")) {
                        ischkedcnt++;
                    }
                }
                else {
                    if ($("#check_" + services[index].divID).prop("checked")) {
                        ischkedcnt++;
                    }
                }
            });

            if (isreq == 1 && minitem > 0) {
                if (minitem > ischkedcnt && !isChecked) {
                    var straddon = minitem > 1 ? " add-ons." : " add-on.";
                    validatemsg = "Please select at least " + NumberToWord(minitem) + straddon;
                }
                else if (maxitm != 0 && ischkedcnt > maxitm) {
                    var straddon = maxitm > 1 ? " add-ons." : " add-on.";
                    validatemsg = "Please select total " + NumberToWord(maxitm) + straddon;
                }
            }
            else if (maxitm != 0 && ischkedcnt > maxitm) {
                var straddon = maxitm > 1 ? " add-ons." : " add-on.";
                validatemsg = "Please select total " + NumberToWord(maxitm) + straddon;
            }
            return validatemsg;
        }
        else {
            return validatemsg;
        }
    }

    const NumberToWord = (number) => {
        var NS = [
            { value: 10000000, str: "crore" },
            { value: 100000, str: "lakh" },
            { value: 1000, str: "thousand" },
            { value: 100, str: "hundred" },
            { value: 90, str: "ninety" },
            { value: 80, str: "eighty" },
            { value: 70, str: "seventy" },
            { value: 60, str: "sixty" },
            { value: 50, str: "fifty" },
            { value: 40, str: "forty" },
            { value: 30, str: "thirty" },
            { value: 20, str: "twenty" },
            { value: 19, str: "nineteen" },
            { value: 18, str: "eighteen" },
            { value: 17, str: "seventeen" },
            { value: 16, str: "sixteen" },
            { value: 15, str: "fifteen" },
            { value: 14, str: "fourteen" },
            { value: 13, str: "thirteen" },
            { value: 12, str: "twelve" },
            { value: 11, str: "eleven" },
            { value: 10, str: "ten" },
            { value: 9, str: "nine" },
            { value: 8, str: "eight" },
            { value: 7, str: "seven" },
            { value: 6, str: "six" },
            { value: 5, str: "five" },
            { value: 4, str: "four" },
            { value: 3, str: "three" },
            { value: 2, str: "two" },
            { value: 1, str: "one" }
        ];

        var result = '';
        NS.forEach(function (n) {
            if (number >= n.value) {
                if (number <= 99) {
                    result += n.str;
                    number -= n.value;
                    if (number > 0) result += ' ';
                } else {
                    var t = Math.floor(number / n.value);
                    // console.log(t);
                    var d = number % n.value;
                    if (d > 0) {
                        return NumberToWord(t) + ' ' + n.str + ' ' + NumberToWord(d);
                    } else {
                        return NumberToWord(t) + ' ' + n.str;
                    }

                }
            }
        });
        return result;
    }

    const CreateAddonCollection = () => {
        var AddonData = [];
        $.each($("#divAddonPopupSection input[type='checkbox']:checked"), function (key, obj) {
            var objaddon = {}
            objaddon.ServiceTitle = $(obj).attr("data-serviceTitle");
            objaddon.EncServiceId = $(obj).attr("data-serviceid");
            objaddon.EncParentServiceID = $(obj).attr("data-parentserviceId");
            objaddon.ServiceDesc = $(obj).attr("data-serviceDisc");
            objaddon.Duration = $(obj).attr("data-Duration");
            objaddon.PointGiven = $(obj).attr("data-PointGiven");
            objaddon.PointRedeem = $(obj).attr("data-PointRedeem");
            objaddon.IsShowPriceOnline = $(obj).attr("data-isshowpriceonline");
            objaddon.Originalprice = parseTodecimal($(obj).attr("data-price"), 2);
            var ServiceAddonList = $.grep(data, function (service) {
                return service.serviceLevel == 1 && service.serviceId == objaddon.ServiceId;
            });
            if (ServiceAddonList.length > 0) {
                objaddon.BusinessTax = ServiceAddonList[0].businessTax === undefined ? null : ServiceAddonList[0].businessTax;
                objaddon.TaxesValues = ServiceAddonList[0].taxesValues === undefined ? null : ServiceAddonList[0].taxesValues;
                objaddon.Taxes = ServiceAddonList[0].taxesValues === undefined ? null : ServiceAddonList[0].taxesValues;
                objaddon.Tax = 0;
                if (ServiceAddonList[0].businessTax != null && ServiceAddonList[0].businessTax.length > 0) {
                    for (var i = 0; i < ServiceAddonList[0].businessTax.length; i++) {
                        objaddon.Tax = objaddon.Tax + ServiceAddonList[0].businessTax[i].tax;
                        objaddon.TaxesValues = ServiceAddonList[0].businessTax[i].businessTaxID;
                        ApplyMultipleTax(objaddon.Originalprice, ServiceAddonList[0]);
                    }
                }
                objaddon.taxprice = parseTodecimal((objaddon.Originalprice * objaddon.Tax) / 100, 3);
                objaddon.actualTaxprice = objaddon.taxprice;
            }
            objaddon.Photo = "";
            objaddon.ApplyDiscoutType = ApplyDiscoutTypePrepay.NoDiscount;
            objaddon.redeemAmount = 0;
            objaddon.redeemTax = 0;
            objaddon.Discount = 0;
            objaddon.VisitRemaining = 0;
            objaddon.Visit = 0;
            objaddon.priceOriginal = parseTodecimal($(obj).attr("data-price"), 2);
            objaddon.GCDetail = [];
            objaddon.GCDetailPriceBase = [];
            objaddon.IsServiceBaseGC = false;
            objaddon.paidType = arrpaidType.UnPaid;
            objaddon.DiscountID = 0;
            objaddon.depositsPaid = 0;
            objaddon.depositsPercentage = 0;
            objaddon.payableAmount = objaddon.priceOriginal;
            objaddon.pointgivenOriginal = objaddon.PointGiven;
            objaddon.pointredeemOriginal = objaddon.PointRedeem;
            objaddon.PromotionId = $(obj).attr("data-promotionid");
            if (objaddon.PromotionId > 0) {
                objaddon.Price = parseTodecimal($(obj).attr("data-newprice"), 2);
            }
            else {
                objaddon.Price = parseTodecimal($(obj).attr("data-price"), 2);
            }

            AddonData.push(objaddon);
        });
        $.each($("#divAddonPopupSection input[type='radio']:checked"), function (key, obj) {

            if ($(obj).attr("data-IsNOTA") != 'true') {
                var objaddon = {}
                objaddon.ServiceTitle = $(obj).attr("data-serviceTitle");
                objaddon.EncServiceId = $(obj).attr("data-serviceid");
                objaddon.EncParentServiceID = $(obj).attr("data-parentserviceId");
                objaddon.ServiceDesc = $(obj).attr("data-serviceDisc");
                objaddon.Duration = $(obj).attr("data-Duration");
                objaddon.PointGiven = $(obj).attr("data-PointGiven");
                objaddon.PointRedeem = $(obj).attr("data-PointRedeem");
                objaddon.IsShowPriceOnline = $(obj).attr("data-isshowpriceonline");
                objaddon.Originalprice = parseTodecimal($(obj).attr("data-price"), 2);
                var ServiceAddonList = $.grep(data, function (service) {
                    return service.serviceLevel == 1 && service.serviceId == objaddon.ServiceId;
                });
                if (ServiceAddonList.length > 0) {
                    objaddon.BusinessTax = ServiceAddonList[0].businessTax === undefined ? null : ServiceAddonList[0].businessTax;
                    objaddon.TaxesValues = ServiceAddonList[0].taxesValues === undefined ? null : ServiceAddonList[0].taxesValues;
                    objaddon.Taxes = ServiceAddonList[0].taxesValues === undefined ? null : ServiceAddonList[0].taxesValues;
                    objaddon.Tax = 0;
                    if (ServiceAddonList[0].businessTax != null && ServiceAddonList[0].businessTax.length > 0) {
                        for (var i = 0; i < ServiceAddonList[0].businessTax.length; i++) {
                            objaddon.Tax = objaddon.Tax + ServiceAddonList[0].businessTax[i].tax;
                            objaddon.TaxesValues = ServiceAddonList[0].businessTax[i].businessTaxID;
                            ApplyMultipleTax(objaddon.Originalprice, ServiceAddonList[0]);
                        }
                    }
                    objaddon.taxprice = parseTodecimal((objaddon.Originalprice * objaddon.Tax) / 100, 3);
                    objaddon.actualTaxprice = objaddon.taxprice;
                }
                objaddon.Photo = "";
                objaddon.ApplyDiscoutType = ApplyDiscoutTypePrepay.NoDiscount;
                objaddon.redeemAmount = 0;
                objaddon.redeemTax = 0;
                objaddon.Discount = 0;
                objaddon.VisitRemaining = 0;
                objaddon.Visit = 0;
                objaddon.priceOriginal = parseTodecimal($(obj).attr("data-price"), 2);
                objaddon.GCDetail = [];
                objaddon.GCDetailPriceBase = [];
                objaddon.IsServiceBaseGC = false;
                objaddon.paidType = arrpaidType.UnPaid;
                objaddon.DiscountID = 0;
                objaddon.depositsPaid = 0;
                objaddon.depositsPercentage = 0;
                objaddon.payableAmount = objaddon.priceOriginal;
                objaddon.pointgivenOriginal = objaddon.PointGiven;
                objaddon.pointredeemOriginal = objaddon.PointRedeem;
                objaddon.PromotionId = $(obj).attr("data-promotionid");
                if (objaddon.PromotionId > 0) {
                    objaddon.Price = parseTodecimal($(obj).attr("data-newprice"), 2);
                }
                else {
                    objaddon.Price = parseTodecimal($(obj).attr("data-price"), 2);
                }
                AddonData.push(objaddon);
            }
        });

        return AddonData;
    }

    const CreateNoneOTAboveObject = (childData) => {
        childData.map(function (item) {
            item.isNOTA = false;
            return item;
        });
        if (childData[0].isRequired == 0 && ((childData[0].minItem == 1 && childData[0].maxItem == 1) || (childData[0].minItem == 0 && childData[0].maxItem == 1))) {
            var obj = {};
            obj.minItem = childData[0].minItem;
            obj.maxItem = childData[0].maxItem;
            obj.serviceId = childData[0].encServiceId + childData[0].encParentServiceID;
            obj.parentServiceID = childData[0].encParentServiceID;
            obj.serviceTitle = "None of the above";
            obj.serviceDesc = "";
            obj.isShowPriceOnline = false;
            obj.photo = "";
            obj.price = 0;
            obj.newPrice = 0;
            obj.promotionDetail = "";
            obj.isNOTA = true;
            childData.push(obj);
        }
    }

    useEffect(() => {
        Addonservice();
    }, []);

    const GotoBookNow = async (e) => {
        let Srno = SerialNo.current;
        if (event != undefined) {
            var service = $.grep(AddonServiceBundleDetailBookNow.current, function (e) {
                return e.serialNo == Srno;
            });
            if (service[0].encEventServiceID != "") {
                var _isVAlidaddons = AddonValidate(service[0].encEventServiceID);
                if (_isVAlidaddons == false) {
                    return;
                }
            }
        }
        SetServiceAddon(Srno);
        console.log(JSON.stringify(SelectedServiceAddon.current));
    }

    const AddCart = async (event) => {
        var _isVAlidaddons = AddonValidate(0);
        if (_isVAlidaddons == false) {
            return;
        }
        setContentLoading(true);
        let LoginDetails = utils.ReadCookie();
        if (LoginDetails.EncUid === undefined || LoginDetails.EncUid === "" || LoginDetails.EncUid === null) {
            setContentLoading(false);
            $("#VgAddon").hide();
            $(".modal-backdrop").hide();       
            $(".vgn-popup").hide();     
            window.wbc.openVagaroPage("divVagaroIframe", utils.VURL("Login", null) + addtoCartEnum.ServiceAddToCart + "&Data=" + event.target.id);
            return;
        }
        const AddonData = CreateAddonCollection();
        const Requestdata = {};
        Requestdata.EncServiceId = serviceId;
        Requestdata.EncUid = LoginDetails.EncUid;
        Requestdata.promotiondetail = promotionDetail;
        Requestdata.promotiontitle = promotionTitle;
        Requestdata.recGiftCertificateId = "";
        Requestdata.ServiceDesc = ServiceDesc;
        Requestdata.isCustomPackage = false;
        Requestdata.lstAddonService = AddonData;
        Requestdata.noOfVisit = 0;
        if (props.cartBook === true) {
            await AsyncAjaxPost(
                (encBID != undefined && encBID != null && encBID != "") ? encBID + "/addtocart/serviceaddtocart" : SiteData.businessId + "/addtocart/serviceaddtocart",
                Requestdata,
                SiteData.accessToken,
                function onSuccess(data) {
                    var addon = data.data.data;
                    unmountComponentAtNode(document.getElementById("divcart"));
                    ReactDOM.render(<RenderBusinessCart data={addon.itemCount} userContext={SiteData} />, document.getElementById("divcart"));
                    setres(data.data.data)
                    PopupCloseConfirmation();
                    setContentLoading(false);
                })
        }
        return <></>;
    }


    const Addonservice = async () => {
        setLoading(true);
        setparentService([]);
        setchildService([]);
        const Requestdata = {};
        Requestdata.EncServiceId = serviceId;
        Requestdata.ServiceProviderID = 0;
        Requestdata.IsBundle = IsBundle;
        await AsyncAjaxPost(
            (encBID != undefined && encBID != null && encBID != "") ? encBID + "/addonservice/addonservice" : SiteData.businessId + "/addonservice/addonservice",
            Requestdata,
            SiteData.accessToken,
            function onSuccess(data) {
                if (
                    data != null &&
                    data.data != null &&
                    data.data.responseCode == ResponseCode.Success
                ) {
                    if (data != null && data.data != null && data.data.data != null) {

                        if (data.data.data.serviceData != null) {

                            var responseData = data.data.data.addOnServiceList;
                            setdata(responseData);

                            var serdata = data.data.data.serviceData;

                            TotalPrice.current = serdata.price;

                            setServiceId(serdata.encServiceId);
                            setIsBundle(serdata.isBundle);
                            setServiceTitle(serdata.serviceTitle);
                            setServiceDesc(serdata.serviceDesc);
                            setPrice(!serdata.isBundle ? serdata.price : 0);
                            setServicePhotoURL(serdata.serviceImageUrl);
                            setServicePrice(!serdata.isBundle ? serdata.price : 0);

                            setPromotionDetail(serdata.promotionDetail);
                            setPromotionTitle(serdata.promotionTitle);

                            if (serdata.isBundle) {
                                AddonServiceBundleDetailBookNow.current = responseData;
                                responseData = GetListAddonService(SerialNo.current, false);
                                setaddonservice(responseData);
                            }
                            else {
                                setaddonservice(responseData);
                                ParentServicefunctions(responseData);
                            }
                        }
                    }
                }
            }
        )
    }

    const ParentServicefunctions = (SelectedAddonService) => {

        var parentService = $.grep(SelectedAddonService, function (service) {
            return service.serviceLevel == 0;
        });
        $.each(parentService, function (index) {
            var childService = $.grep(SelectedAddonService, function (service) {
                return service.parentServiceIDHash == parentService[index].serviceIDHash;
            });
            parentService[index].minItem = parentService[index].minItem > childService.length ? childService.length : parentService[index].minItem;
            parentService[index].maxItem = parentService[index].maxItem > childService.length ? childService.length : parentService[index].maxItem;

            childService = childService.map(function (object) {
                object.isRequired = parentService[index].isRequired;
                object.minItem = parentService[index].minItem;
                object.maxItem = parentService[index].maxItem;
                object.photo = object.photo.indexOf('no-shop-image-big.') > 0 ? "" : object.photo;
                object.currencySymbol = currencySymbol;
                object.onlinePrice = object.isShowPriceOnline == 1 ? Number.parseFloat(object.price).toFixed(2) : "0.00";
                object.isDealApplied = false;
                object.promotionID = 0;
                object.newPrice = 0;
                object.isIncludeTaxInOnlinePricing = false;
                object.onlinePricewithtax = object.isShowPriceOnline == 1 ? object.price : "0.00";
                object.newPricewithTax = object.price;

                if (object.promotionDetail != null && object.promotionDetail != '') {
                    var DealDetails = GetAddonDailyDealData(object.promotionDetail, object.onlinePrice, object.serviceID, 0, true);
                    if (DealDetails.PromotionID > 0) {
                        object.isDealApplied = true;
                        object.promotionID = DealDetails.PromotionID;
                        object.newPrice = DealDetails.NewPrice;
                        object.NewPricewithTax = object.NewPrice;
                    }
                    else {
                        object.isDealApplied = false;
                        object.promotionID = 0;
                        object.NewPrice = 0;
                    }
                }
                else {
                    object.isDealApplied = false;
                    object.promotionID = 0;
                    object.newPrice = 0;
                }

                try {
                    if (object.businessTax != null && object.businessTax != "") {
                        if (object.promotionDetail != null && object.promotionDetail != '') {
                            var taxAmountNew = parseFloat(object.newPricewithTax) * parseFloat((object.businessTax.map(x => x.Tax).reduce((a, b) => { return a + b }, 0)) / 100);
                            var TotalPriceNew = 0;
                            TotalPriceNew = parseFloat(object.newPricewithTax) + taxAmountNew;
                            object.newPricewithTax = TotalPriceNew;
                        }
                        var totaltax = object.businessTax.map(x => x.tax).reduce((a, b) => { return a + b }, 0);
                        object.onlinePricewithtax = CalAccPriceWithTax(object.onlinePrice, totaltax);
                        object.onlinePrice = object.onlinePricewithtax;
                    }
                } catch (e) { }

                return object;
            });
            CreateNoneOTAboveObject(childService);
            setparentService(prevparentService => ([...prevparentService, parentService[index]]));
            childService.length > 0 && childService.map((d) => {
                setchildService(prevchildService => ([...prevchildService, d]));
            });
            if (index == parentService.length - 1) {
                setLoading(false);
            }
        });
    }

    const CalAccPriceWithTax = (price, tax) => {
        var TotalPrice = 0;
        TotalPrice = parseFloat(ConvertTodecimalMorePrecious(tax / 100 * parseFloat(price), true, 2)) + parseFloat(price);
        return TotalPrice;
    }

    const ConvertTodecimalMorePrecious = (value, isFixed, decimalPoint) => {
        //If isFixed Flag will true then this function will return decimal value
        if (isNaN(parseFloat(value))) {
            value = 0;
        }
        if (isFixed) {
            return (Number(Math.round(value + 'e' + decimalPoint) + 'e-' + decimalPoint)).toFixed(decimalPoint);
        }
        //If isFixed Flag will false then this function will return string value
        else {
            return parseFloat((Number(Math.round(value + 'e' + decimalPoint) + 'e-' + decimalPoint)).toFixed(decimalPoint));
        }
    }

    const GetAddonDailyDealData = (promodetail, addonprice, serviceid, serviceproviderid, isfromservicetab) => {

        var objPromoData = {};
        let PromotionDetails = [];
        let lstDetail = $.grep(PromotionDetails, function (n) {
            return (((n.ServiceIDs.indexOf(serviceid)) >= 0) && (n.ServiceProviderIDs == null || serviceproviderid == 0 || n.ServiceProviderIDs.indexOf(serviceproviderid)) >= 0);
        });

        if ((lstDetail != null && lstDetail.length > 0) || isfromservicetab == true) {
            var arrPromo = promodetail.split('|~~|');

            if (arrPromo != null && arrPromo.length > 0) {
                //let SearchDate = $("#txtdivSearchDate").length > 0 ? getdatepickervalue("#txtdivSearchDate", null, true, true) : null;
                let SearchDate = (new Date());

                var promodiscount = arrPromo[0].split('-');
                var strStartDate = new Date(arrPromo[2]);
                var strEndDate = arrPromo[3] != '' ? new Date(arrPromo[3]) : '';
                if ((new Date(SearchDate.getFullYear(), SearchDate.getMonth(), SearchDate.getDate()) >= (new Date(strStartDate.getFullYear(), strStartDate.getMonth(), strStartDate.getDate()))
                    && (strEndDate == '' || (new Date(SearchDate.getFullYear(), SearchDate.getMonth(), SearchDate.getDate()) <= (new Date(strEndDate.getFullYear(), strEndDate.getMonth(), strEndDate.getDate())))))) {
                    if (promodiscount != null && promodiscount.length > 0) {
                        objPromoData.PromotionID = promodiscount[0];
                        objPromoData.IsAmount = promodiscount[1];
                        objPromoData.Discount = promodiscount[2];
                        objPromoData.PerUserOne = promodiscount[3];
                        objPromoData.PerTransactionOne = promodiscount[4];

                        if (objPromoData.IsAmount == "1") {
                            objPromoData.NewPrice = parseFloat(addonprice) - parseFloat(objPromoData.Discount);
                        }
                        else {
                            objPromoData.NewPrice = parseFloat(addonprice) - parseFloat((parseFloat(addonprice) * parseFloat(objPromoData.Discount)) / 100);
                        }
                    }
                }
            }
        }
        else {
            objPromoData.PromotionID = 0;
            objPromoData.NewPrice = 0;
        }
        return objPromoData;
    }


    const AddonServiceHead = (props) => {
        return <>
            <div className="addon-servies-head">
                {ServicePhotoURL && <div className="sericeimg">
                    <div
                        className="vg-addon-service-img-box"
                        style={{ backgroundImage: `url(${ServicePhotoURL})` }}
                    ></div>
                </div>}
                <div className="vg-addon-service-description">
                    <div className="servicename">{ServiceTitle}</div>
                    {ServicePrice != "" && <div className="discounted-price">{CommanData.currencySymbol + ServicePrice.toFixed(2)}</div>}
                    {ServiceDesc != "" && <div className="services-description">{ServiceDesc}</div>}
                </div>
            </div>
        </>
    }

    const OnEventAddonValidate = (parentServiceid, eventServiceID) => {

        var localdata = data.filter(
            (col) => col.encEventServiceID === eventServiceID
        );

        var tempadddata = localdata;
        if (tempadddata == undefined || tempadddata == null || tempadddata.length == 0) {
            return true;
        }
        var parentService = $.grep(tempadddata, function (service) {
            return service.serviceLevel == 0 && service.serviceIDHash == parentServiceid;
        });
        var _AllValidateAddon = 0;

        $.each(parentService, function (index) {
            var childService = $.grep(tempadddata, function (service) {
                return service.parentServiceIDHash == parentService[index].serviceIDHash && service.isOnlineService == true;;
            });
            var _isvalidate = ValidateAddonService(childService, parentService[index].minItem, parentService[index].maxItem, parentService[index].isRequired, true);
            $("#errMsg_" + parentService[index].divID).text(_isvalidate);
            $("#divErrorAddon_" + parentService[index].divID).hide();
            if (_isvalidate != "") {
                _AllValidateAddon++;
            }
        });

        if (_AllValidateAddon == 0) {
            return true;
        }
        else {
            return false;
        }
    }

    const [currentIndex, setcurrentIndex] = useState(0);
    const [totalIndex, setTotalIndex] = useState(0);
    const [eventServiceID, seteventServiceID] = useState(0);

    useEffect(() => {
        CalculateTotal();
    }, [currentIndex, totalIndex, eventServiceID]);


    const CalculateTotal = () => {
        TotalPrice.current = Price;
        $.each($("#divAddonPopupSection input[type='checkbox']:checked"), function (key, obj) {
            if ($(obj).attr("data-IsShowPriceOnline") == 'false') {
                TotalPrice.current = TotalPrice.current + parseFloat(("0.00"));
            }
            else {
                var newprice = parseFloat(($(obj).attr("data-newprice")))
                var promotionid = parseFloat(($(obj).attr("data-promotionid")));
                if (newprice > 0 || promotionid > 0) {
                    TotalPrice.current = TotalPrice.current + newprice;
                }
                else {
                    TotalPrice.current = TotalPrice.current + parseFloat(($(obj).attr("data-price")));
                }
            }
        });
        $.each($("#divAddonPopupSection input[type='radio']:checked"), function (key, obj) {
            if ($(obj).attr("data-IsShowPriceOnline") == 'false') {
                TotalPrice.current = TotalPrice.current + parseFloat(("0.00"));
            }
            else {
                var newprice = parseFloat(($(obj).attr("data-newprice")))
                var promotionid = parseFloat(($(obj).attr("data-promotionid")));
                if (newprice > 0 || promotionid > 0) {
                    TotalPrice.current = TotalPrice.current + newprice;
                }
                else {
                    TotalPrice.current = TotalPrice.current + parseFloat(($(obj).attr("data-price")));
                }
            }
        });
        TotalPrice.current = parseTodecimal(TotalPrice.current, 2);

        $(".TotalServicePrice").text(currencySymbol + TotalPrice.current.toFixed(2));
        if (TotalPrice.current == "$0.00" || TotalPrice.current == "0.00" || TotalPrice.current == "0") {
            $(".TotalServicePrice").css("display", "none");
            $('#servicenamepopup .footer-price-row-p .web-body-font').css("display", "none")
        }
        else {
            $(".TotalServicePrice").css("display", "block");
            $('#servicenamepopup .footer-price-row-p .web-body-font').css("display", "block")
        }
    }

    const CheckboxAddOnService = (event, parentServiceID, eventServiceID) => {
        if (event != undefined) {
            var _isVAlidaddons = OnEventAddonValidate(parentServiceID, eventServiceID);
            if (_isVAlidaddons == false) {
                $(event).prop('checked', false);
                return false;
            }
        }
        CalculateTotal();
    }


    const PackaItem = (param) => {
        var MasterServiceFilterAddonChild = [];
        var MasterServiceFilterAddon = [];
        if (IsBundle) {
            MasterServiceFilterAddon = SelectedServiceAddon.current.filter(
                (col) => col.serviceId === param.eventServiceID
            );
            if (MasterServiceFilterAddon.length > 0) {
                if (MasterServiceFilterAddon[0].lstAddonService.length > 0) {
                    MasterServiceFilterAddonChild = MasterServiceFilterAddon[0].lstAddonService.filter(
                        (col) => col.parentServiceIDHash == param.parentServiceIDHash && col.encServiceId == param.serviceId
                    );
                }
            }
            setcurrentIndex(param.index);
            setTotalIndex(param.totallength);
            seteventServiceID(param.eventServiceID);
        }
        return <Fragment>
            <div className="pack-item">
                <div className="pack-item-check-col">
                    {
                        (param.minItem == 1 && param.maxItem == 1) || (param.minItem == 0 && param.maxItem == 1) ?
                            <Form.Check
                                label=""
                                name={"rdo_" + param.divID}
                                type="radio"
                                defaultChecked={IsBundle && MasterServiceFilterAddon.length > 0 && MasterServiceFilterAddonChild.length > 0 ? true : false}
                                onClick={(e) => CheckboxAddOnService(e, param.parentServiceIDHash, param.eventServiceID)}
                                onChange={() => setdata(data)}
                                id={"rdo_" + param.divID}
                                className={`vg-form-check vg-only-radio`}
                                data-serviceTitle={param.serviceTitle}
                                data-price={param.onlinePrice}
                                data-serviceid={param.serviceId}
                                data-Duration={param.duration}
                                data-PointGiven={param.pointGiven}
                                data-PointRedeem={param.pointRedeem}
                                data-serviceDisc={param.serviceDesc}
                                data-parentserviceId={param.parentServiceID}
                                data-IsShowPriceOnline={param.isShowPriceOnline}
                                data-newprice={param.newPrice}
                                data-promotionid={param.promotionID}
                                data-photo={param.serviceImageUrl}
                                data-IsNOTA={param.isNOTA}
                            />
                            :
                            <Form.Check
                                label=""
                                name={"check_" + param.divID}
                                type="checkbox"
                                defaultChecked={IsBundle && MasterServiceFilterAddon.length > 0 && MasterServiceFilterAddonChild.length > 0 ? true : false}
                                onClick={(e) => CheckboxAddOnService(e, param.parentServiceIDHash, param.eventServiceID)}
                                onChange={() => setdata(data)}
                                id={"check_" + param.divID}
                                className={`vg-form-check vg-only-checkbox`}
                                data-serviceTitle={param.serviceTitle}
                                data-price={param.onlinePrice}
                                data-serviceid={param.serviceId}
                                data-Duration={param.duration}
                                data-PointGiven={param.pointGiven}
                                data-PointRedeem={param.pointRedeem}
                                data-serviceDisc={param.serviceDesc}
                                data-parentserviceId={param.parentServiceID}
                                data-IsShowPriceOnline={param.isShowPriceOnline}
                                data-newprice={param.newPrice}
                                data-photo={param.serviceImageUrl}
                                data-promotionid={param.promotionID}
                            />
                    }
                </div>
                {param.photo &&
                    <div className="pack-item-img-col" style={{ backgroundImage: `url(${param.photo})` }}></div>
                }
                {param.serviceTitle &&
                    <div className="pack-item-info-col">
                        <h3>{param.serviceTitle}</h3>
                        {param.serviceDesc && <p>{param.serviceDesc}</p>}
                    </div>
                }
                {param.isShowPriceOnline == 1 &&
                    <Fragment>
                        <div className="pack-item-price-col">
                            {param.onlinePrice == 0 ?
                                <div>FREE</div>
                                :
                                param.isDealApplied != 'undefined' && param.isDealApplied == true ?
                                    <div>
                                        <div className="oldprice">+{param.currencySymbol}{param.onlinePrice}</div>
                                        {param.newPrice == 0 ? <div>FREE</div> : <div>+{param.currencySymbol}{param.newPrice}</div>}
                                    </div>
                                    : <div>+{param.currencySymbol}{param.onlinePrice}</div>
                            }
                        </div>
                    </Fragment>
                }
            </div>
        </Fragment>
    }

    const popupBody = () => {
        return (
            <Fragment>
                {isContentLoading && < Loader />}
                <AddonServiceHead
                    serviceImg={ServicePhotoURL}
                    serviceName={ServiceTitle}
                    actualprice={ServicePrice}
                    discountedPrice={ServicePrice}
                    servicesDescription={ServiceDesc}
                />

                <div className="page-title">{!IsBundle ? <Fragment>Suggested Add-Ons Available</Fragment> : <Fragment>Service Suggested Add-Ons : {ServiceAddonTitle}</Fragment>}</div>
                <div className="addon-packages" id="divAddonPopupSection">
                    {parentService.length > 0 && parentService.map((d) => {
                        const FilterAddon = childService.filter(
                            (col) => col.parentServiceIDHash === d.serviceIDHash
                        );
                        return (
                            <div className="addon-pack">
                                <Fragment>
                                    <div className="pack-title">
                                        <div className="pack-name">
                                            <div className='service-addon-title'>{d.serviceTitle}</div>
                                            <Fragment>
                                                {d.isRequired == 1 && d.minItem > 0 ?
                                                    <Fragment>
                                                        {
                                                            d.minItem == d.maxItem ?
                                                                <div class="choos-text">Choose {d.minItem}</div>
                                                                : d.minItem > 0 && d.maxItem == 0 ?
                                                                    <div class="choos-text">Choose {d.minItem}</div>
                                                                    :
                                                                    <div class="choos-text">Choose {d.minItem} (up to {d.maxItem})</div>

                                                        }
                                                    </Fragment>
                                                    : <Fragment>
                                                        {d.maxItem > 1 || (d.maxItem > 0 && d.minItem == 0) ?
                                                            <div class="choos-text">Choose up to {d.maxItem}</div>
                                                            :
                                                            d.minItem > 0 ? <div class="choos-text">Choose {d.minItem}</div> :
                                                                ""
                                                        }
                                                    </Fragment>
                                                }
                                            </Fragment>
                                        </div>
                                        <div className="pack-badge">
                                            {d.isRequired == 1 && d.minItem > 0 ?
                                                <span className="badge required-badge">Required</span> : <span className="badge">Optional</span>}
                                        </div>
                                    </div>
                                    <div className="addon-error" style={{ display: 'none' }} id={"divErrorAddon_" + d.divID}>
                                        <div className="vg-status-deprecated-message"><span id={"errMsg_" + d.divID}></span></div>
                                    </div>
                                    {FilterAddon.length > 0 && FilterAddon.map((e, index) => {
                                        return (
                                            <Fragment>
                                                <PackaItem
                                                    divID = {e.divID}
                                                    serviceTitle={e.serviceTitle}
                                                    photo={e.photo}
                                                    serviceDesc={e.serviceDesc}
                                                    minItem={e.minItem}
                                                    maxItem={e.maxItem}
                                                    serviceId={e.encServiceId}
                                                    parentServiceID={e.encParentServiceID}
                                                    parentServiceIDHash={e.parentServiceIDHash}
                                                    duration={e.duration}
                                                    pointGiven={e.pointGiven}
                                                    pointRedeem={e.pointRedeem}
                                                    isShowPriceOnline={e.isShowPriceOnline}
                                                    newPrice={e.newPrice}
                                                    promotionID={e.promotionID}
                                                    isNOTA={e.isNOTA}
                                                    onlinePrice={e.onlinePrice}
                                                    isDealApplied={e.isDealApplied}
                                                    currencySymbol={e.currencySymbol}
                                                    eventServiceID={e.encEventServiceID}
                                                    index={index + 1}
                                                    totallength={FilterAddon.length}
                                                ></PackaItem>
                                            </Fragment>
                                        )
                                    })}
                                </Fragment>
                            </div>
                        )
                    })}
                </div>
            </Fragment >
        )
    }



    const SetServiceAddon = (Srno) => {
        var service = $.grep(AddonServiceBundleDetailBookNow.current, function (e) {
            return e.serialNo == Srno;
        });
        var ServiceDetail = {}
        if (CommanData.servicebundleList.length > 0 && service.length > 0) {
            const FilterAddonMasterData = CommanData.servicebundleList.filter(
                (col) => col.serviceIdHash === service[0].eventServiceIDHash
            );
            if (FilterAddonMasterData.length > 0) {
                ServiceDetail.SrNo = Srno;
                ServiceDetail.serviceId = service[0].encEventServiceID;
                ServiceDetail.ServiceDesc = FilterAddonMasterData[0].serviceDesc;
                ServiceDetail.ServicePrice = FilterAddonMasterData[0].servicePrice;
                ServiceDetail.ServiceProviderId = 0;
                ServiceDetail.ServicePhotoURL = "";
            }
        }

        var AddonService = {};
        AddonService = CreateAddonCollection();
        ServiceDetail.lstAddonService = AddonService;

        var ServiceAddon = SelectedServiceAddon.current.filter((arr) => arr.SrNo === Srno);
        if (ServiceAddon.length == 0) {
            SelectedServiceAddon.current.push(ServiceDetail);
        }
        else {
            let updatedItemList = SelectedServiceAddon.current.map((item) => {
                if (item.SrNo === Srno) {
                    return { SrNo: ServiceDetail.SrNo, serviceId: ServiceDetail.serviceId, ServiceProviderId: ServiceDetail.ServiceProviderId, ServicePhotoURL: ServiceDetail.ServicePhotoURL, ServiceDesc: ServiceDetail.ServiceDesc, lstAddonService: ServiceDetail.lstAddonService };
                }
                return item;
            });
            SelectedServiceAddon.current = updatedItemList;
        }

        GetListAddonService(SerialNo.current, false);
    }

    const GotoNext = (event) => {
        let Srno = SerialNo.current;
        if (event != undefined) {
            var service = $.grep(AddonServiceBundleDetailBookNow.current, function (e) {
                return e.serialNo == Srno;
            });
            if (service[0].encEventServiceID != "") {
                var _isVAlidaddons = AddonValidate(service[0].encEventServiceID);
                if (_isVAlidaddons == false) {
                    return;
                }
            }
        }
        SerialNo.current = SerialNo.current + 1;
        SetServiceAddon(Srno);
    }

    const GotoPrevious = () => {
        let Srno = SerialNo.current;
        SerialNo.current = SerialNo.current - 1;
        SetServiceAddon(Srno);
    }

    const Footer = () => {
        return <>
            <Fragment>
                {!IsBundle &&
                    <Fragment>
                        <div className="totalpricefooter">
                            <span>Total</span>
                            <span className="totalprice s-sub-heading TotalServicePrice">{currencySymbol}{Price.toFixed(2)}</span>
                        </div>
                        <button id="AddtocartContinue" className="v-button primary invisible-button" onClick={(event) => AddCart(event)}>Continue</button>
                    </Fragment >
                }
                {IsBundle &&
                    <Fragment>
                        {SerialNo.current >= 2 &&
                            <div style={{ marginRight: "auto" }}>
                                <button id="AddtocartPrevious" className="v-button secondary full-width-mobile" onClick={(e) => GotoPrevious(e)}>Back</button>
                            </div>
                        }
                        <div className="totalpricefooter">
                            <span>Total</span>
                            <span className="totalprice s-sub-heading TotalServicePrice">{currencySymbol}{Price.toFixed(2)}</span>
                        </div>

                        {GetListAddonService(SerialNo.current, true).length > 0 ?
                            <button id="AddtocartPNext" className="v-button primary invisible-button" onClick={(e) => GotoNext(e)}>Next</button>
                            :
                            <button id="AddtocartContinue" className="v-button primary invisible-button" onClick={(e) => GotoBookNow(e)}>Continue</button>
                        }
                    </Fragment >
                }
            </Fragment >
        </>
    }

    const PopupCloseConfirmation = () => {
        unmountComponentAtNode(document.getElementById("OpenDialog"));
    };

    return createPortal(
        isLoading ? <Loader /> : <>
            <VGPopUp
                id={"VgAddon"}
                show={true}
                onHide={PopupCloseConfirmation}
                title={props.title}
                dialogClassName="vgui-addonservices"
                body={popupBody()}
                isRemoveTabModalIndex={false}
                isShowFooter={true}
                size={"modal-sm"}
                backdrop={true}
                footer={Footer(props)}
            />
        </>,
        document.getElementById("OpenDialog")
    );
}
export default Addon;  