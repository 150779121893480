import React, { createRef, useEffect, useRef, useState } from "react";
import { ScreenSize } from "../Definitions/ScreenSize";

export default function ScreenStateHandler(){


    const firstLoad = useRef(false);
    const prevSize = useRef<number>(0); // store previous size outside of state to prevent re-renders/loops and avoid edge case flickering
 
    const evalSize = (size:number,log?:string)=>{
        

        let prev = prevSize.current;
        let newScreenSize = ScreenSize.Desktop;
     
        if(size<=768 ){
    
            prevSize.current = size;
            newScreenSize = ScreenSize.Mobile;
           
        }
        else if(size<1200) {
            prevSize.current = size;
            newScreenSize = ScreenSize.Tablet;
        }
        else{
            prevSize.current = size;
            newScreenSize = ScreenSize.Desktop;
        }

        if(prev!==newScreenSize){

       
    
                    document.body.classList.remove("screen-desktop");
  
                    document.body.classList.remove("tablet-break");
                    document.body.classList.remove("screen-tablet");
           
                    document.body.classList.remove("mobile-break");
                    document.body.classList.remove("screen-mobile");
 


            prevSize.current = newScreenSize;
            
         
            switch(newScreenSize){
                case ScreenSize.Desktop:
                    document.body.classList.add("screen-desktop");
                break;
                case ScreenSize.Tablet:
                    document.body.classList.add("tablet-break");
                    document.body.classList.add("screen-tablet");
                break;
                case ScreenSize.Mobile:
                    document.body.classList.add("tablet-break");
                    document.body.classList.add("mobile-break");
                    document.body.classList.add("screen-mobile");
                break;
                default:
                    break;
            }
       
        }

    }

    const getScreenWidth = ()=>{
        return Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
    }
    //resizing
    useEffect( ()=>{
   
        document.body.classList.add("v-site");
        document.body.classList.add("render");
        window.addEventListener("resize",()=>{evalSize(getScreenWidth())});
        evalSize(getScreenWidth(),"initial");

        return ()=>{window.removeEventListener("resize",()=>{evalSize(getScreenWidth())});} 

    },[])







    return <><AnimationHandler/></>
}

export function AnimationHandler(){

        //Animations
        useEffect(()=>{


            const options = {
                rootMargin: "0px",
                threshold: 0.0,
              };
              
              const observer = new IntersectionObserver((entries)=>{
                entries.forEach((entry)=>{
                    if(entry.isIntersecting){
                 
                        let animation = entry.target.getAttribute("vanimation");
                        if(animation){
                            entry.target.classList.add("vanimation-"+animation);
                        }
                    }
                })
              }, options);
    
    
              let toAnimate = document.querySelectorAll("[vanimation]");
         
              for(let i = 0;i<toAnimate.length;i++){
                    observer.observe(toAnimate[i]);
                    let animation = toAnimate[i].getAttribute("vanimation");
                    if(animation){
                        toAnimate[i].classList.remove("vanimation-"+animation);
                    }


                    toAnimate[i].classList.add("vanimation-hidden");
            }
    
            return ()=>{ observer.disconnect();
            
            let toAnimate = document.querySelectorAll(".vanimation-hidden");
            for(let i = 0;i<toAnimate.length;i++){
                toAnimate[i].classList.remove("vanimation-hidden");
            }
            
            }
        })

    return <></>
}