import $ from "jquery";
const { utils } = require("./Utils");

var isWindowsApp; // need to check and how to add is windows app availble or not 
var _siteBuilderSocialMedia ={
    

    PostOnPint: function (queryString) {
        queryString = decodeURIComponent(queryString).replace(/<br *\/?>/gi, '\n');
        queryString = queryString.replace(/#/g, '%23');
        _siteBuilderSocialMedia.PinUrl = "http://www.pinterest.com/pin/create/button/?url=" + queryString;
        if (typeof (isWindowsApp) != 'undefined' && isWindowsApp) {
            try {
                window.external.notify('pinterestpage|' + _siteBuilderSocialMedia.PinUrl);
            } catch (e) {
            }
        }
        else {
            _siteBuilderSocialMedia.left = (window.screen.width / 2) - (600 / 2);
            _siteBuilderSocialMedia.top = (window.screen.height / 2) - (280 / 2);
            _siteBuilderSocialMedia.targetWin = window.open(_siteBuilderSocialMedia.PinUrl, "Pintrest", 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + 600 + ', height=' + 280 + ', top=' + _siteBuilderSocialMedia.top + ', left=' + _siteBuilderSocialMedia.left);
            if (!_siteBuilderSocialMedia.targetWin || _siteBuilderSocialMedia.targetWin.closed || typeof _siteBuilderSocialMedia.targetWin == 'undefined' || typeof _siteBuilderSocialMedia.targetWin.closed == 'undefined') {
                // vagaro.alertsmall('Window cannot be opened as popup is blocked in your browser');
                if (!navigator.userAgent.toLowerCase().includes("iphone") && !navigator.userAgent.toLowerCase().includes("safari")) {
                    utils.ShowAlert("Alert","Window cannot be opened as popup is blocked in your browser","OpenDialog2")
                }
            }
        }
        return false;
    },
    PostOnTwitter : function (queryString) {
        queryString = decodeURIComponent(queryString).replace(/<br *\/?>/gi, '\n');
        _siteBuilderSocialMedia.TwitterURL = "https://twitter.com/intent/tweet?url=" + queryString;
        _siteBuilderSocialMedia.TwitterURL = encodeURI(_siteBuilderSocialMedia.TwitterURL);
        if (typeof (isWindowsApp) != 'undefined' && isWindowsApp) {
            try {
                window.external.notify('promotionsharetwitter|' + _siteBuilderSocialMedia.TwitterURL);
            } catch (e) {
            }
        }
        else {
            _siteBuilderSocialMedia.left = (window.screen.width / 2) - (600 / 2);
            _siteBuilderSocialMedia.top = (window.screen.height / 2) - (320 / 2);
            _siteBuilderSocialMedia.targetWin = window.open(_siteBuilderSocialMedia.TwitterURL, "shareWindow", "toolbar=no,location=no,status=no, menubar=no,scrollbars=yes,resizable=no,width=" + 600 + ",height=" + 320 + ",left=" + _siteBuilderSocialMedia.left + ",top=" + _siteBuilderSocialMedia.top);
            if (!_siteBuilderSocialMedia.targetWin || _siteBuilderSocialMedia.targetWin.closed || typeof _siteBuilderSocialMedia.targetWin == 'undefined' || typeof _siteBuilderSocialMedia.targetWin.closed == 'undefined') {
                // _siteBuilderSocialMedia.alert('Window cannot be opened as popup is blocked in your browser');
                if (!navigator.userAgent.toLowerCase().includes("iphone") && !navigator.userAgent.toLowerCase().includes("safari")) {
                    utils.ShowAlert("Alert","Window cannot be opened as popup is blocked in your browser","OpenDialog2")
                }
            }
        }
        return false;
    },
    PostonFacebook: function (id, callfrom, objBusinessDetail) {
        var _CheckFacebookRender;
        _siteBuilderSocialMedia.curtemp = $(id).closest('.brick');
        _siteBuilderSocialMedia.Spname = ''; 
        _siteBuilderSocialMedia.Address = '';
        _siteBuilderSocialMedia.BusinessUrl = '';
        _siteBuilderSocialMedia.image = '';
        _siteBuilderSocialMedia.BusinessName = '';
        _siteBuilderSocialMedia.Servicetitle = '';
        _siteBuilderSocialMedia.Styletitle = '';
        _siteBuilderSocialMedia.msg = '';
        if (callfrom == 'Stylist') {
            // _siteBuilderSocialMedia.Spname = $("#spnProviderName", _siteBuilderSocialMedia.curtemp).html();
            // _siteBuilderSocialMedia.BusinessName = $(_siteBuilderSocialMedia.curtemp).find("#spnAddress").data().businessname;
            // _siteBuilderSocialMedia.Address = $(_siteBuilderSocialMedia.curtemp).find("#spnAddress").data().address;
            // _siteBuilderSocialMedia.BusinessUrl = window.location.protocol + '//' + window.location.host + (IsVagaroDomain() ? "/" + $("#arating", _siteBuilderSocialMedia.curtemp).attr("href") : "");
            // _siteBuilderSocialMedia.image = $("#imgServiceProvider_" + _siteBuilderDetail.businessID + "_" + _siteBuilderSocialMedia.curtemp.data().spid, _siteBuilderSocialMedia.curtemp).attr("src");
            // _siteBuilderSocialMedia.msg = 'Service Provider has been posted.';
    
        }
        else if (callfrom == 'Photos') {
         
            _siteBuilderSocialMedia.image = objBusinessDetail.image;
            _siteBuilderSocialMedia.BusinessName = objBusinessDetail.BusinessName;
            _siteBuilderSocialMedia.Servicetitle = objBusinessDetail.Servicetitle
            _siteBuilderSocialMedia.Styletitle = objBusinessDetail.Styletitle
            _siteBuilderSocialMedia.BusinessUrl = window.location.protocol + '//' + window.location.host;
            _siteBuilderSocialMedia.msg = 'Portfolio has been posted.';

        }
        else if (callfrom == 'Deals') {
            // _siteBuilderSocialMedia.BusinessName = $("#lblPromotionTitleHome").text();
            // _siteBuilderSocialMedia.titletimestamp = $("#hdnScTitletimestamp").val();
            // _siteBuilderSocialMedia.vagaroURL = $("#hdnScVagarourl").val();
            // _siteBuilderSocialMedia.Styletitle = $("#promotionDesHome", ".deals-text-cont").text();
            // _siteBuilderSocialMedia.image = $("#imgPromotion").attr("src");
            // //_siteBuilderSocialMedia.image = $("#ctl00_imgPromotion").attr("src");
            // _siteBuilderSocialMedia.BusinessUrl = window.location.protocol + '//' + window.location.host + (IsVagaroDomain() ? "/" + _siteBuilderSocialMedia.vagaroURL : "") + "/deals/" + _siteBuilderSocialMedia.titletimestamp;
            // _siteBuilderSocialMedia.msg = 'Deals has been posted.';
        }
        else if (callfrom == 'BusinessDetail') {
            // _siteBuilderSocialMedia.image = objBusinessDetail.imageUrl;
            // _siteBuilderSocialMedia.BusinessName = objBusinessDetail.BusinessName;
            // _siteBuilderSocialMedia.BusinessUrl = window.location.protocol + '//' + window.location.host + (IsVagaroDomain() ? "/" + objBusinessDetail.vagaroUrl.toLowerCase() : "");
            // _siteBuilderSocialMedia.msg = 'Business has been posted.';
        }
        _CheckFacebookRender = _siteBuilderSocialMedia;
        if (typeof (isWindowsApp) != 'undefined' && isWindowsApp) {
            try {
                window.external.notify('promotionsharefacebook|' + _siteBuilderSocialMedia.BusinessUrl + '|' + _siteBuilderSocialMedia.Servicetitle);
            } catch (e) {
            }
        }
        else {
            _siteBuilderSocialMedia.CheckFacebookControllRenders(_CheckFacebookRender, callfrom);
        }
    },
    CheckFacebookControllRenders : function (_siteBuilderSocialMedia, callfrom) {
        if ($("#dvFaceBookControl").length > 0) {
            _siteBuilderSocialMedia.FacebookLoader(_siteBuilderSocialMedia, callfrom);
        }
        else {
            // ShowLoader(true);
            // var objReqFacebookData = {};
            // objReqFacebookData.UserControlPath = "~/UserControls/General/ucFacebookLoader.ascx";
            // objReqFacebookData.PageNamePath = "~/Home.aspx"
            // objReqFacebookData.isRemoveFormTag = true;
            // var vgFaceBookLoad = new vagaro.ajax();
            // vgFaceBookLoad.datamodel.IsNewVagaroAPI = true;
            // vgFaceBookLoad.datamodel.Token = "websiteapi/homepage/loadusercontrol";
            // vgFaceBookLoad.datamodel.data = objReqFacebookData;
            // vgFaceBookLoad.datamodel.callback = function (result) {
            //     // ShowLoader(false);
            //     $("body").append(result);
            //     if ($("#dvFaceBookControl").length > 0) {
            //         if (callfrom != undefined)
            //             _siteBuilderSocialMedia.FacebookLoader(_siteBuilderSocialMedia, callfrom);
            //     }
            // };
            // vgFaceBookLoad.datamodel.Error = function (e) {
            //     // ShowLoader(false);
            //     // vagaro.alert({ message: 'An Error Has Occurred', title: "Oops!" });
            //     utils.ShowAlert("Oops!",'An Error Has Occurred',"OpenDialog2")

            // };
            // vgFaceBookLoad.Post();
    
        }
    },
    FacebookLoader: function (_siteBuilderSocialMedia, callfrom) {

        if (callfrom == 'products') {
            _siteBuilderSocialMedia.LoadProductonFacebook();
        }
        else {
            // $FacebookLoader(function () {
            //     FBAPP.funcPostfeature(unescape(_siteBuilderSocialMedia.BusinessName), _siteBuilderSocialMedia.Spname, _siteBuilderSocialMedia.Address, _siteBuilderSocialMedia.BusinessUrl, _siteBuilderSocialMedia.Servicetitle, _siteBuilderSocialMedia.Styletitle, _siteBuilderSocialMedia.image, callfrom, function (callback) {
            //         if (callback != null && callback != undefined)
            //             if (callback.id != undefined && callback.id != null)
            //                 vagaro.alertsmall(_siteBuilderSocialMedia.msg);
            //     });
            // });
        }
    }
};




export default _siteBuilderSocialMedia;