import RenderStarRating from '../components/Icons/StarRating';
import VGPopUp from '../components/Controls/VGPopUp';
import React, { Fragment } from 'react';
import { createPortal, unmountComponentAtNode } from 'react-dom';
import RenderReviewWrapper from '../components/BusinessControls/RenderReviewsWrapper';
import "./StaffReview.scss";
const avatarStyle = { width: "3em", height: "3em", borderRadius: "50%", margin: "0 0.5em .5em 0" };
const ImageStyle = { width: "3em", height: "3em", borderRadius: "0%", margin: "0 0.5em .5em 0" };

const StaffReview = (props) => {

    const staffData = props.staffData;
    const siteData = props.userContext;

    const PopupBody = () => {
        return (
            <Fragment>
                <div class="review-top">
                    <div className={`reviewhead ${typeof staffData.photoURLForReviewPopup != 'undefined' && staffData.photoURLForReviewPopup != null && staffData.photoURLForReviewPopup != "" ? "":"noimg"}`}>
                        {typeof staffData.photoURLForReviewPopup != 'undefined' && staffData.photoURLForReviewPopup != null && staffData.photoURLForReviewPopup != "" &&
                            <div className="reviewimg">
                                <img src={staffData.photoURLForReviewPopup} alt="" />
                            </div>
                        }
                       {/*  {(typeof staffData.photoURL == 'undefined' || staffData.photoURL == null || staffData.photoURL == "") &&
                            <div className="review-profile vagaro-avatar-md mr15"><div className="review-profile-inner-div intialsname vg-avatar-inital" style={{ display: "block" }}>
                                <div className="review-profile-inner vg-avatar-inital-inner">
                                    <div className="review-profile-inner-text vg-avatar-inital-inner-text">{staffData.spintials}</div>
                                </div>
                            </div></div>
                        } */}
                        <div className="review-emp-profile">
                            <div className="review-emp-meta">
                                <h3 className='vg-custom-style'>{staffData.firstName + " " + staffData.lastName}</h3>
                                <div className='vg-star-rating review-stars'>
                                    <RenderStarRating rating={Math.round(staffData.reviewRank)} starColor="#f6d645"  isOutlined={true}/>
                                    <div className='gap-large'style={{padding:".2em 0"}}>({staffData.reviewCount})</div>
                                </div>
                                {staffData.businessSummary && <p className='vg-custom-style'>{staffData.businessSummary}</p>}
                            </div>
                        </div>
                    </div>
                </div>
                <RenderReviewWrapper userContext={siteData} staffData={staffData} />
            </Fragment>
        )
    }

    const handlepopupClose = () => {
        unmountComponentAtNode(document.getElementById("OpenDialog"));
    };


    return createPortal(
        <VGPopUp
            id={"Vgstaffreview"}
            show={true}
            onHide={handlepopupClose}
            title={props.title}
            dialogClassName="vgui-reviewby-employee"
            body={PopupBody(props)}
            isRemoveTabModalIndex={false}
            isShowFooter={false}
            size={"modal-sm"}
            backdrop={true}
        />,
        document.getElementById("OpenDialog")
    );
}

export default StaffReview;
