
import React, { ReactNode, useEffect } from 'react';
import { DownCaret } from './Icons/Carets';


export default function CustomMultiSelect(
    { startOpen,options,footer,placeholder,defaultValues,onChange,useDualHeader}:{startOpen:boolean, options:{label:string,value:string}[],footer:ReactNode,placeholder:string,defaultValues?:string[],onChange?:(values:string[])=>void,useDualHeader?:boolean }
){

    
    let defVal = defaultValues??[];
    defVal = defVal.filter((v)=>options.find((o)=>o.value==v));

    
    const [isOpen,setIsOpen] = React.useState(startOpen);
    const [selected,setSelected] = React.useState(defVal);
    const ref = React.useRef<HTMLDivElement>(null);


    const selectAll = ()=>{

        let newSelection = [];
        for(let i = 0;i<options.length;i++){
            newSelection.push(options[i].value);
        }
        setSelected(newSelection);
        if(onChange){
            onChange(newSelection);
        }
    }

    const selectNone = ()=>{
        setSelected([]);
        if(onChange){
            onChange([]);
        }
    }

    const handleClickOutside = (event:MouseEvent) => {

        if(ref.current && !ref.current.contains(event.target as Node)){
            setIsOpen(false);
        }
    }

    useEffect(() => {


        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    },[]);


    let label = placeholder;

  
    if(selected.length==options.length){
        label = "All selected"
    }
    else if(selected.length>0 && selected.length<4){
        for(let i = 0;i<selected.length;i++){
            let o = options.find((o)=>o.value==selected[i]);
            if(o){
                if(i==0){
                    label = o.label;
                }
                else{
                    label += ", "+o.label;
                }
            }
        }
    }
    else if(selected.length>0){
        label = selected.length+" of "+options.length+ " selected";
    }

    
    const allSelected = selected.length==options.length;

    return <> 
         <div className="multi-select w-full"  ref={ref} style={{position:"relative"}}>
            <div style={{padding:"8px",cursor:"pointer",background:"white",borderRadius:"5px", border: "1px solid #c7c7c7"}} onClick={()=>setIsOpen(!isOpen)}className='flex-row justify-between'>
                
                <span
                    style={{overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap"}}
                >
                {label}</span><DownCaret/>
                </div>

            {isOpen&&
            <div className="multi-select-options flex-column w-full" style={{padding:"0px 0px 8px 0px",position:"absolute",background:"white",zIndex:"10",border:"1px solid #C7C7C7",borderRadius:"5px",boxSizing:"content-box",left:"-1px",overflow:"auto",maxHeight:"240px"}} >
                {/* <div className='flex-row justify-between font-med w-full' style={{background:"#F8F8F8",padding:"8px",cursor:"pointer",color:"#333"}}>
                    <button className="invisible-button"
                    onClick={()=>{selectAll()}}
                    >Select All</button>
                    <button className="invisible-button" onClick={()=>{setSelected([])
                    
                    if(onChange)
                        onChange([]);
                    }}>Select None</button>
                </div> */}
            {useDualHeader?
            <div className='flex-row justify-between' style={{padding:"8px 4px",background:"#F8F8F8",position:"sticky",top:"0"}}>
                <button onClick={selectAll} className='invisible-button'>Select All</button>
                <button onClick={selectNone} className='invisible-button'>Select None</button>

            </div>:
                <div 
                    style={{cursor:"pointer",padding:"8px",fontWeight:"normal"}}
                    onClick={()=>{ 

                        if(!allSelected){
                        selectAll();
                        }
                        else{
                            setSelected([]);
                            if(onChange)
                            onChange([]);
                        }

                    }}
                      className={"flex-row gap-large multi-select-option "+(allSelected?"selected":"")} ><CheckSelect isChecked={allSelected} />{"[Select All]"}</div>

                }
                {options.map((o,i)=>{ 

                    let isSelected = selected.includes(o.value);
                    const selectedClass = isSelected? "selected":"";

                    return <div 
                    style={{cursor:"pointer",padding:"8px",fontWeight:"normal"}}
                    onClick={()=>{ 
                        let selectedCopy = [...selected];
                        if(isSelected){
                            selectedCopy = selectedCopy.filter((v)=>v!=o.value);

                        }
                        else{
                            selectedCopy.push(o.value);
                        }

                        setSelected(selectedCopy);
                        if(onChange){
                            onChange(selectedCopy);
                        }

                    }}
                     key={i} className={"flex-row gap-large multi-select-option "+selectedClass} >
                        <CheckSelect isChecked={isSelected} />
                        <div style={{whiteSpace:"nowrap", textOverflow:"ellipsis",overflow:"hidden"}}> {o.label}</div>
                        </div>

                })}


             {footer}
            </div>
            }
        </div>
    
    </> 
}

export function CheckSelect({isChecked}:{isChecked:boolean}){


    return <div style={{ width:"20px",height:"20px",border:"1px solid #CDCDCD",flexShrink:"0",borderRadius:"5px",background:(isChecked?"black":"white")}} className='center-all'>
        {
            isChecked&&<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512" fill="white"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
        }


    </div>





}