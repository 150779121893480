import React, { useEffect, useState, useRef } from "react";
import { utils } from "../utils/Utils";
import Form from "react-bootstrap/Form";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import "./slick.min.css";
import "./VgGiftCertificates.scss";
import Slider from "react-slick";
import $ from "jquery";
import GroupSingleSelect from "./Controls/GroupSingleSelect";
import MultiLocationDropdown from "./MultiLocationDropdown";
import ReactDom from "react-dom";
import moment from "moment";
import { PhonePlugin } from "../utils/PhonePlugin";
import "../Style/GoogleAddressController.css"
import SingleDateRangePicker from "./Controls/SingleDateRangePicker";

const GiftCertificates = (props) => {
    const PayTypes = {
        Ammount: "1",
        Service: "2",
    };
    var MobilePlugin = null;
    const access_token = props.userContext.accessToken;
    const CatagoryName = props.giftCard.giftCertificateCatName;
    const IsServiceBasedGCEnabled= props.giftCard.isServiceBasedGCEnabled;
    const { vplStatus, maxVPLAmout, minVPLAmount } = props.giftCard;
    const Merchantdata = utils.MultiLocationData(props.userContext, props.userContext.bidHash);
    const [subCatagoryes, setsubCatagory] = useState([]);
    const [SelectedCatagory, setCatagory] = useState({});
    const [ammountvalue, setammoutvalue] = useState(0);
    const [name, setName] = useState("");
    const [Color, setColor] = useState("");
    const [emailTo, setEmailTo] = useState("");
    const [sendDate, setSendDate] = useState(null);
    const [Phone, setPhone] = useState("");
    const [contryCode, setContryCode] = useState("");
    const [oldemailTo, setOldEmailTo] = useState("");
    const [massage, setMassage] = useState("");
    const [ammount, setAmmount] = useState("");
    const [payLaterAmmount, setPLAmmount] = useState("");
    const AmmountActiveRef = useRef(false)
    const [selectedammount, setSelectedammount] = useState({});
    const [selectedService, setelectedService] = useState("");
    const [searchService, setSearchService] = useState("");
    const [selectedOption, setSelectedOption] = useState("");
    const [iselectvalid, setIselectvalid] = useState(false);
    const [ServiceCount, setServiceCount] = useState(1);
    const [tempServiceCount, setTempServiceCount] = useState(1);
    const AmmountInput = useRef(null)
    const NameInput = useRef(null)
    const EmailInput = useRef(null)
    const PhoneInput = useRef(null)
    const dropdownRef = useRef(null);
    const validationActive = useRef(false);
    const LoginDetails = utils.ReadCookie();
    const [payType, setPayType] = useState(PayTypes.Ammount);
    function GCNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style }}
                onClick={onClick}
            ><i className="far fa-angle-right"></i></div>
        );
    };
    function GCPrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style }}
                onClick={onClick}
            ><i className="far fa-angle-left"></i></div>
        );
    };
    var gcThumOptions = {
        className: "gc-thumitem",
        dots: false,
        lazyLoad: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        variableWidth: false,
        mobileFirst: true,
        focusOnSelect: true,
        nextArrow: <GCNextArrow />,
        prevArrow: <GCPrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                    initialSlide: 5,
                    variableWidth: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    initialSlide: 4,
                    variableWidth: true

                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    initialSlide: 3,
                    variableWidth: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    variableWidth: true
                }
            }
        ]
    };
    const SubcatagoryChange = (val) => {        
        setCatagory(val)
        setColor(val.gcFontColor)
    };
    const renderDropdown = (filteredOptions) => {
        const searchInput = document.getElementById('textAmmount');
        const dropdown = document.getElementById('dropdown');
        dropdown.innerHTML = '';
        filteredOptions.forEach(option => {
            const li = document.createElement('li');
            li.innerHTML = `${option.text} <strong>( ${option.promotext} )</strong>`
            li.addEventListener('click', () => {
                searchInput.value = `${option.text} (${option.promotext})`;
                handleAmmount(option)
                dropdown.style.display = 'none';
                AmmountInput.current.title = "";
            });
            dropdown.appendChild(li);
        });
        if (filteredOptions.length > 0) {
            dropdown.style.display = 'block';
        } else {
            dropdown.style.display = 'none';
        }
    };
    const handleClickOutside = (event) => {
        const dropdown = document.getElementById('dropdown');
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            dropdown.style.display = 'none';
        }

    };

    const handleAmmount = (selectedOption, Change) => {
        setammoutvalue(selectedOption);
        if (Change) {
            const searchInput = document.getElementById('textAmmount');
            const searchText = selectedOption.toLowerCase();
            const options = props.gcDisCounts.map((data) => ({ "id": data.encId, "text": data.text, "price": data.price.toString(), "data": data }));
            const filteredOptions = options.find(option => (option.price.toLowerCase() == searchText));
            if (filteredOptions) {
                setAmmount(filteredOptions.price.toString());
                setPLAmmount(filteredOptions.data.priceAfterDiscount.toString());
                searchInput.value = `${filteredOptions.text} ${filteredOptions?.data?.promotext ? `(` + filteredOptions?.data?.promotext + `)` : ""}`;
                setSelectedammount(filteredOptions.data)
                AmmountActiveRef.current = true;
                ValidationChange("Ammount", false)
            } else {
                var price = searchText;
                var flotPrice = ((searchText));
                if (price && price.toString().length <= 6) {
                    setAmmount(flotPrice);
                    setPLAmmount(flotPrice);
                    setSelectedammount({})
                    searchInput.value = flotPrice;
                    AmmountActiveRef.current = true;
                    ValidationChange("Ammount", false)
                } else {
                    setAmmount("");
                    setPLAmmount("")
                    setSelectedammount({})
                    searchInput.value = "";
                }
            }
        } else {
            AmmountActiveRef.current = true;
            setTimeout(() => {
                setAmmount(selectedOption.price.toString());
                setPLAmmount(selectedOption.data.priceAfterDiscount.toString())
                setSelectedammount(selectedOption.data)
                ValidationChange("Ammount", false)
            }, 100);
        }
    };
    const RemoveEmoji = (element) => {
        var regexEmoji = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
        element.value = element.value.replace(regexEmoji, '');

    }
    const CheckAllBlank = (element,Name) => {
        if (element.value == ""){
            switch (Name) {
                case "Email":
                    if (name.trim() === "" && massage.trim() === "") {
                        ValidationChange("Email", false);
                        ValidationChange("Name", false);
                        ValidationChange("Message", false); 
                    }
                break;
                case "Name":
                    if(massage.trim() === "" && emailTo.trim() === ""){
                        ValidationChange("Email", false);
                        ValidationChange("Name", false);
                        ValidationChange("Message", false); 
                    }
                break;
                case "Message":
                    if (name.trim() === "" && emailTo.trim() === "") {
                        ValidationChange("Email", false);
                        ValidationChange("Name", false);
                        ValidationChange("Message", false); 
                    }

                break;
                default:
                    break;
            } 
        }
    }
    const validationcheck = (event, onBlur) => {
        if (onBlur && event && typeof event != 'undefined') {
            var regexEmoji = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
            EmailInput.current.value = event.replace(regexEmoji, '');
        }
        if ((event !== "" || event !== undefined || event !== null) && validationActive.current) {
            const regex = /^[A-Za-z0-9_\+-]+(\.[A-Za-z0-9_\+-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*\.([A-Za-z]{2,10})$/;
            if (!event || regex.test(event) === false) {
                return true; //fail
            } else {
                return false; //pass
            }

        } else {
            return false; //passs
        }

    }
    const ValidationChange = (name, type, Check, value) => {
        if (Check) {
            $("#RequiredValid" + name).show();
            $("#text" + name).addClass("errorInput");
            $("#text" + name).parent().addClass('form-row-invalid')
            if (name === "Email" && value === "") {
                $("#RequiredValid" + name).hide()
            } else {
                $("#Required" + name).hide();
            }
        } else if (!type) {
            $("#Required" + name).hide();
            $("#Required" + name).hide();
            $("#text" + name).removeClass("errorInput");
            $("#text" + name).parent().removeClass('form-row-invalid')
            if (name == "Email") { $("#RequiredValid" + name).hide() }
        } else {
            $("#Required" + name).show();
            $("#text" + name).addClass("errorInput");
            $("#text" + name).parent().addClass('form-row-invalid')
            if (name == "Email") { $("#RequiredValid" + name).hide() }
        }
    };
    const ValidField = (val) => {
        var check = val
        validationActive.current = true;
        if (name.trim() !== "" || emailTo.trim() !== "") {
            if (name.trim() === "") { ValidationChange("Name", true); check = false };
            if (emailTo.trim() === "") {
                ValidationChange("Email", true);
                check = false;
            } else if (validationcheck(emailTo)) {
                ValidationChange("Email", true, true);
                check = false;
            };
        }
        if (PhoneInput.current.className.includes("errorInput")) {
            ValidationChange("Phone", true); check = false 
        }
        if (payType === PayTypes.Ammount) {
            if (ammount === "") {
                ValidationChange("Ammount", true);
                check = false
            }
        }
        if (payType === PayTypes.Service) {
            if (selectedOption === "") {
                ValidationChange("Service", true);
                check = false;
            }
        }
        return check
    };
    const addToCart = (encBID, open) => {        
        var isvalid = ValidField(true);
        if (selectedOption === undefined || selectedOption === '') {
            setIselectvalid(true);
        }
        if (isvalid) {
            if (props.allowMultiLoc && open !== false) {
                const MultiGroupGC = props.giftCard.multiGroupGC;
                let Multilocations = [];
                if (!utils.isNullEmptyOrWhiteSpace(MultiGroupGC)) {
                    var businessData = JSON.parse(MultiGroupGC);
                    businessData.map((data, index) => {
                        let Data = [];
                        Data = props.userContext.MultilocationData.filter(
                            (col) => col.encBIDHash == data.BID
                        );
                        if (Data.length > 0) {
                            Multilocations.push(Data[0]);
                        }
                    });
                } 
                if (payType === PayTypes.Service) {
                    let tempMloction = [];
                    var multiloc = JSON.parse(selectedService.multiGroupService);
                    for (let index = 0; index < Multilocations.length; index++) {
                        const element = Multilocations[index];
                        if (multiloc.filter(val=> val.BID == element.encBIDHash).length > 0 ) {
                            tempMloction.push(element)
                        }
                    }
                    Multilocations = tempMloction
                }

                if (Multilocations.length > 1) {
                    return ReactDom.render(
                        <MultiLocationDropdown
                            Sitedata={Multilocations}
                            OnClickEvent={addToCart}
                            setammoutvalue={setammoutvalue}
                            Mode={"gcAddCart"}
                            userContext={props.userContext}
                            GiftCard={props.giftCard}
                            IsShowMap={true}
                        />,
                        document.getElementById("OpenDialogMultiLocation")
                    );
                }
                else {
                    encBID = "";
                }
            }
            let multilocationserviceID; 
            if (props.allowMultiLoc && encBID !== "" && payType === PayTypes.Service) {
                const MultiGroupGC = props.userContext.MultilocationData;
                if(MultiGroupGC.length > 1)
                {
                    var BIDHash = MultiGroupGC.find((val) => val.encBID === encBID).encBIDHash;
                    var multiloc = JSON.parse(selectedService.multiGroupService);
                    if (BIDHash && multiloc) {
                        var multilocstr = multiloc.find(val => val.BID === BIDHash);
                        if (multilocstr) {
                            multilocationserviceID = multilocstr.SID    
                        }
                    }
                }
            } 
            var Date = $("#dailydealdaterange").val();
            var request = {
                EncUid: LoginDetails.EncUid,
                GiftCertificatePhotoID: SelectedCatagory.encGiftCertificatePhotoID,
                FullName: name,
                Email: emailTo,
                Type: payType === PayTypes.Service ? "service" : "price",
                Price: payType === PayTypes.Service ? selectedService.price : selectedammount?.price ? selectedammount?.price : ammount,
                ServiceId: payType === PayTypes.Service ?  multilocationserviceID ? multilocationserviceID : selectedService.encServiceID : "U2FsdGVkX18EI4k80FtcVtq4PzxA94OPqaGqyI1vxNM=",
                NoOfVisit: ServiceCount,
                Message: massage,
                TotalPrice: payType === PayTypes.Service ? selectedService.price * ServiceCount : selectedammount?.price ? selectedammount?.price : ammount,
                promotiondetail: payType === PayTypes.Service ? "" : selectedammount?.promotionDetail ? selectedammount?.promotionDetail : "",
                promotiontitle: payType === PayTypes.Service ? "" : selectedammount?.promotionTitle ? selectedammount?.promotionTitle : "",
                ReciverPhoneNo: Phone,
                ScheduleDate: (Date && Date !== "Send Now" && sendDate !== null) ? sendDate : null
            }
            props.addToCart(request, encBID)
        }
    };

    const ChangeQty = (value) => {
        var val = parseFloat(value)
        if (value.trim() === "" || value === "0" || isNaN(value)) {
            setServiceCount(1);
            setTempServiceCount(1);
            value = 1;
        } else {
            setServiceCount(val);
            setTempServiceCount(val);
        }

    }
    useEffect(() => {
        if (selectedOption !== "") {
            var service = (props?.gcServices?.data || []).find((val) => val.encServiceID == selectedOption)
            setelectedService(service)
            setServiceCount(1)
            setTempServiceCount(1)
            ValidationChange("Service", false);
            setSearchService(service.serviceTitle)
        }
    }, [selectedOption])

    const SelectedCatagoryRef = useRef(true)
    useEffect(() => {
        if (SelectedCatagoryRef.current && props.subCatagoryes.length > 0) {
            setCatagory(props.subCatagoryes[0]);
            setColor(props.subCatagoryes[0].gcFontColor)
            if (props.businessData.countryID) {
                setContryCode(props.businessData.countryID)
            }
            NameInput.current.focus();
            MobilePlugin = PhonePlugin($('#textPhone'),{
                UserRole: 2,
                ControlText: 'Mobile Phone',
                CurrentLocationCountryID: typeof (props.businessData.countryID) != "undefined" && props.businessData.countryID != null ? props.businessData.countryID : props.businessData.countryID,
                ScrollType: 1,
                Token: access_token 
            },(Code)=> setContryCode(Code),(number)=> setPhone(number))
            SelectedCatagoryRef.current = false;
        }
        setsubCatagory(props.subCatagoryes)
    }, [props.subCatagoryes]);
    useEffect(() => {
        if (props?.selectGCdata?.price) {
            var price = parseInt(props.selectGCdata.price);
            handleAmmount(price.toString(), true)
        }
    }, [props.selectGCdata, props.gcDisCounts]);
    const Onselectservice = (val) => {        
        if (val == "") {
            setSelectedOption("")
            setelectedService("")
            ValidationChange("Service", true);
        } else {
            setSelectedOption(val);
            setIselectvalid(false);
        }
    }
    useEffect(() => {

        const searchInput = document.getElementById('textAmmount');
        const gcOpt = props.gcDisCounts;
        const options = gcOpt.map((data) => ({ "id": data.id, "text": data.text, "price": data.price, "data": data, "promotext": data.promotext }));
        searchInput.addEventListener('input', () => {
            const searchText = searchInput.value.toLowerCase();
            const filteredOptions = options.filter(option => option.text.toLowerCase().includes(searchText));
            renderDropdown(filteredOptions);
        });
        searchInput.addEventListener('focus', () => {
            const initsearchText = searchInput.value.toLowerCase();
            const filteredOptions = initsearchText.length == 0 ? options : options.filter(option => option.text.toLowerCase().includes(initsearchText));
            renderDropdown(filteredOptions);
        });
        document.addEventListener('click', handleClickOutside);

    }, [props.gcDisCounts])
    useEffect(() => {
        if (props.gcUserDetails.userID !== undefined) {
            var Name = `${props.gcUserDetails.firstName}  ${props.gcUserDetails.lastName}`
            if (name.trim() == "") {
                $("#textName").val(Name)
                setName(Name)
                ValidationChange("Name", false)
            } else {
                utils.ShowAlert("Alert", `Our records indicate that the entered email address belongs to ${Name} if this email was typed in incorrectly please change your entry to avoid assigning the gift certificate to the wrong person.`, "OpenDialog2")
            }
        }
    }, [props.gcUserDetails])

    const ValidateEmail = (val) => {
        setOldEmailTo(val); 
        if(typeof val != undefined && val != null && val.trim() != "")
        {           
            validationcheck(val, true) ? ValidationChange("Email", true, true, val) : (oldemailTo !== val) && props.callAPI(5, val);
        }
    }

    return (
        <>
            <div className="vg-giftCertificates">
                <div className="page-title">
                    <a href="javascript:void(0);" className="no-a"><i className="far fa-angle-left" onClick={() => props.onHide()}>  </i> </a> {CatagoryName}
                </div>
                <div className="certificates-imgs">
                    <Slider {...gcThumOptions}>

                        {subCatagoryes.map((data, index) => (
                            <div className="gc-thumitem active" onClick={() => SubcatagoryChange(data)}>
                                <img src={"https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/PublicImages/Images/gc-placeholder.png"} style={{ backgroundImage: `url("${data.giftCertificatePhotoImg}")` }} />
                            </div>
                        ))}
                    </Slider>
                </div>
                <div className="certificatePreview-wrapper">
                    <div className="gcpreview-wrapper">
                        <div className="gc-bg">
                            <img src={"https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/PublicImages/Images/gc-placeholder.png"}
                                style={{ backgroundImage: `url("${SelectedCatagory.giftCertificatePhotoImg}")` }} />
                            <div className="gc-texts" style={{ color: Color }}>
                                {payType !== "" && payType === PayTypes.Ammount ?
                                    AmmountActiveRef.current ?
                                        <div className="price">{props.CurrancySymbol}{ammount}</div> :
                                        <div class="w-gc-price-service"></div>
                                    :
                                    selectedService.serviceTitle !== undefined ?
                                        <div className="servicealign"> {selectedService.serviceTitle}{selectedService.serviceTitle !== undefined ? (ServiceCount > 1) ? ` ( ${ServiceCount} visits )` : ` ( ${ServiceCount} visit )` : ""}</div> :
                                        <div class="w-gc-price-service"></div>
                                }
                                <div className="from">From: {LoginDetails.UName}</div>
                                {name !== "" && <div className="fromto">To: {name}</div>}
                                {massage !== "" && <div className="message">{massage}</div>}
                                <div className="bottomtxts">
                                    <div className="gcid">ID : Not yet Purchased</div>
                                    <div class="gc-expire" id="divexpried">{Merchantdata.expiredMonthForGC !== undefined && Merchantdata.expiredMonthForGC !== "0001-01-01T00:00:00" && Merchantdata.expiredMonthForGC !== "" && moment(Merchantdata.expiredMonthForGC).format("MMM-DD-YYYY")}</div>
                                    <div class="business">{props.businessData.businessName}</div>
                                    <div class="address">{props.businessData.street}, {props.businessData.city}, {props.businessData.stateCode} {props.businessData.zip} <br />Contact: {utils.CountryTelephone(props.businessData.telephone, props.businessData.countryID)}</div>
                                </div>


                            </div>
                        </div>
                    </div>
                    <div className="gc-form form-content">
                        <Form.Group className={`form-row`} style={{fontWeight:600}}>
                            Recipient Name (Optional):
                            <Form.Control
                                type="text"
                                placeholder="Recipient Name"
                                ref={NameInput}
                                id="textName"
                                className={"form-control"}
                                onChange={(e) => { RemoveEmoji(e.target); CheckAllBlank(e.target,"Name"); setName(e.target.value); ValidationChange("Name", false) }}
                                autoComplete="off"
                                maxLength={256}
                                tabindex="1"
                            />
                            <label id={"RequiredName"} className="invalid-feedback" for="txtReceiverName">required.</label>
                        </Form.Group>
                        <Form.Group className={`form-row`} style={{fontWeight:600}}>
                            Recipient Email (Optional):
                            <Form.Control
                                type="text"
                                placeholder="Recipient Email"
                                id="textEmail"
                                ref={EmailInput}
                                className={"form-control"}
                                onChange={(e) => { RemoveEmoji(e.target); CheckAllBlank(e.target,"Email"); setEmailTo(e.target.value); ValidationChange("Email", false); if(e.target.value == ""){$("#dailydealdaterange").prop("disabled", true).val("Send Now").attr("title", "Please fill out recipient email field first");}else{$("#dailydealdaterange").prop("disabled", false).attr("title", "");};if (validationActive.current)  if (validationcheck(e.target.value)) ValidationChange("Email", true, true, e.target.value); } }
                                onBlur={(e) => { ValidateEmail(e.target.value) }}
                                autoComplete="off"
                                maxLength={256}
                                tabindex="2"
                            />
                            <label id={"RequiredEmail"} className="invalid-feedback" for="txtReceiverName">required.</label>
                            <label id={"RequiredValidEmail"} className="invalid-feedback" for="txtReceiverName">Please enter a valid email address.</label>
                        </Form.Group>
                        <Form.Group className={`form-row`}  style={{fontWeight:600}}>
                            Recipient Phone Number (Optional):
                            <div className="react-addresscontrol">
                            <Form.Control
                                type="text"
                                placeholder="Recipient Phone"
                                id="textPhone"
                                ref={PhoneInput}
                                className={"form-control "}
                                onChange={(e) => {ValidationChange("Phone", false); }}
                                onBlur={(e)=> {if(e.target.className.includes("errorInput")){ ValidationChange("Phone", true) }}}
                                autoComplete="off"
                                data-name="textPhone"
                                maxLength={20}
                                tabindex="2"
                            />
                            </div>
                            <label id={"RequiredPhone"} className="invalid-feedback" for="txtReceiverName"></label>
                            <label id={"RequiredValidPhone"} className="invalid-feedback" for="txtReceiverName">Please enter a valid Recipient Phone Number.</label>
                        </Form.Group>
                        <Form.Group className={`form-row`}  style={{fontWeight:600}}>
                            Message:
                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Add your message to the recipient here"
                                id="textMessage"
                                className={"form-control"}
                                onChange={(e) => { RemoveEmoji(e.target); CheckAllBlank(e.target,"Message"); setMassage(e.target.value); ValidationChange("Message", false) }}
                                autoComplete="off"
                                maxLength={100}
                                tabindex="3"
                            />
                            <label id={"RequiredMessage"} className="invalid-feedback" for="txtReceiverName">required.</label>
                        </Form.Group>
                        <Form.Group className={`form-row`}  style={{fontWeight:600}}>
                            Send On:
                            <SingleDateRangePicker custom disabled ControlID="dailydealdaterange" MinDate={""} MaxDate={""}  salontime={""} availableDatesdata={""} setSelectTime={""} dateOnChange={(d)=> { setSendDate(d) }}  placeholder={"Send Now"} applyAnyTime={false} title={"Please fill out recipient email field first"}/>
                            <label id={"RequiredSendDate"} className="invalid-feedback" for="txtReceiverName">required.</label>
                        </Form.Group>
                    </div>
                </div>
                <div className="vg-tabs-wrapper gc-tabs">
                    <Tabs
                        activeKey={payType}
                        transition={false}
                        className="vg-tabs"
                        onSelect={(tab) => {
                            setPayType(tab)
                            if (tab == 1 && AmmountInput.current) {
                                setTimeout(() => {
                                    AmmountInput.current.value = "";
                                    setAmmount("")
                                    setPLAmmount("")
                                    AmmountInput.current.focus();
                                }, 50);
                            } else {
                                setSelectedOption("")
                                setelectedService("")
                                ValidationChange("Service", false);
                                $("#ddlGCserviceSearch").val("");
                            }
                        }}
                    >
                        <Tab eventKey={PayTypes.Ammount} title="Choose an amount" >
                            <div className="form-content" style={{ width: props.gcDisCounts.length === 0 ? "125px" : "" }} >
                                <div ref={dropdownRef} className="form-row gc-amount-field" >
                                    
                                    {props.gcDisCounts.length == 0 && 
                                    <input ref={AmmountInput} id="textAmmount" title={"Please fill out this field."} onChange={(e) => { RemoveEmoji(e.target); ValidationChange("Ammount", false); if (e.target.value !== "") { e.target.title = ""; }else{ e.target.title = "Please fill out this field."; }}} onBlur={(e) => handleAmmount(e.target.value, true)} type="text" className="email-text-field gc-amount w-input vagaro-input form-control" maxlength="9" number="true" min="0.01" placeholder={props.CurrancySymbol + "0.00"} required="" tabindex="4" autoComplete="off" spellcheck="false" />}
                                    <div style={props.gcDisCounts.length > 0 ? { display: "block" } : { display: "none" }} className="vg-wb-select">
                                        {props.gcDisCounts.length > 0 && 
                                        <input ref={AmmountInput} type="text"  title={"Please fill out this field."} className="form-control" id="textAmmount" autoComplete="off" placeholder="Select or Enter Gift Card Amount" onChange={(e) => { ValidationChange("Ammount", false); if (e.target.value !== "") { e.target.title = ""; }else{ e.target.title = "Please fill out this field."; }}} onBlur={(e) => handleAmmount(e.target.value, true)} maxLength={100} tabindex={payType == 1 ? "4" : ""} />}
                                        <ul id="dropdown" class="dropdown-content gcDisCounts-dropdown"></ul>
                                    </div>

                                    <div class="autocomplete">
                                    </div>
                                    <label id={"RequiredAmmount"} className="invalid-feedback" for="txtReceiverName">required.</label>

                                </div>
                            </div>
                            <div>
                                {vplStatus == 2 && minVPLAmount <= ammount && ammount <= maxVPLAmout &&
                                    <div className="vgpay">
                                        <div className="vgpay-note">
                                            <div class="note">or 4 payments of {props.CurrancySymbol}{((payLaterAmmount) / 4).toFixed(2)} with </div><div class="paylater"><img width="58" src="https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/vpl-new-logo.svg" /></div>
                                        </div>
                                    </div>}
                            </div>
                        </Tab>
                        {IsServiceBasedGCEnabled &&
                            <Tab eventKey={PayTypes.Service} title="Services">
                                <div id="divservicedropdown" className="gc_selectservice form-content gc-amount-field" >
                                    <div className={`form-row ${iselectvalid && "form-row-invalid"}`}>
                                        <div id="textService" style={{ position: "relative" }}>
                                            <GroupSingleSelect ControlID={"ddlGCserviceSearch"} options={props?.gcServices?.groupedOptions} placeholder={"Select Service"} searchInputPlaceholder={"Search Service "} onChange={(val) => Onselectservice(val)} value={selectedService.serviceTitle ? selectedService.serviceTitle : ""} inputTabindex={payType == 2 ? "4" : ""} />
                                        </div>
                                        <label id={"RequiredService"} className="invalid-feedback" for="txtReceiverName">required.</label>
                                    </div>
                                </div>
                                {selectedService.encServiceID !== undefined && <>
                                    <div className="">
                                        <div className="product-qty">
                                            <label>QTY</label>
                                            <div className="input-group vg-stepbox">
                                                <input id="txtMaxQuantity"
                                                    type="text"
                                                    value={tempServiceCount}
                                                    onClick={(e) => e.target.select()}
                                                    onChange={(e) => isNaN(e.target.value) ? setTempServiceCount("") : setTempServiceCount(e.target.value)}
                                                    onBlur={(e) => ChangeQty(e.target.value)}
                                                />
                                                <button onClick={() => { setServiceCount(ServiceCount <= 1 ? 1 : ServiceCount - 1); setTempServiceCount(tempServiceCount <= 1 ? 1 : tempServiceCount - 1) }}><i className="fal fa-minus"></i></button>
                                                <button onClick={() => { setServiceCount(ServiceCount + 1); setTempServiceCount(tempServiceCount + 1) }}><i className="fal fa-plus"></i></button>
                                            </div>
                                            <div id="lblAmount" className="gc-price">{props.CurrancySymbol} {(ServiceCount * selectedService.price).toFixed(2)}</div>
                                        </div>

                                        {vplStatus == 2 && minVPLAmount <= (ServiceCount * selectedService.price) && (ServiceCount * selectedService.price) <= maxVPLAmout && <div className="vgpay">
                                            <div className="vgpay-note">
                                                <div class="note">or 4 payments of {props.CurrancySymbol}{((ServiceCount * selectedService.price) / 4).toFixed(2)} with </div><div class="paylater"><img width="58" src="https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/vpl-new-logo.svg" /></div>
                                            </div>
                                        </div>}
                                    </div></>}
                            </Tab>
                        }
                    </Tabs>
                </div>
                <div>
                    <button type="button" className="v-button primary invisible-button" onClick={() => addToCart("", true)}>Add to Cart</button>
                </div>
            </div>
        </>
    )
}

export default GiftCertificates;