import VGPopUp from '../components/Controls/VGPopUp';
import React, { useEffect, useState } from 'react'
import { AsyncAjaxGet } from '../components/Utilities/AsyncAjaxService';
import ReactDOM, { createPortal, unmountComponentAtNode } from "react-dom";
import Loader from '../components/Common/Loader';
import GiftCertificatesPopup from "./GCPopup";
import { addtoCartEnum, utils } from '../utils/Utils';
import $ from "jquery";

const GiftCertificatePopup = (props) => {
    const [gcdata, setGcdata] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const { siteData, data } = props;

    useEffect(() => {
        GetGcdata();
    }, [])
    const GetGcdata = async () => {
        setLoading(true);
        const url = "merchants/" + siteData.businessId + "/giftcards";
        await AsyncAjaxGet(
            url,
            siteData.accessToken,
            function onSuccess(data) {
                try {
                    if (data != null && data.data != null && data.data.data != null) {
                        setLoading(false);
                        console.log(data.data.data);
                        setGcdata(data.data.data)

                    }
                    else {
                        setLoading(false);
                    }

                } catch (error) {
                    setLoading(false);
                }
            },
            function OnError() {
                setLoading(false);
            }
        );
    }

    const body = () => {
        const RenderDefaultLayout = (prop) => {

            const rows = [];
            for (let i = 0; i < prop.data.length; i += 3) {
                const row = [];
                for (let j = 0; j < 3; j++) {
                    if (prop.data[i + j])
                        row.push(prop.data[i + j]);
                    else {
                        row.push({ photo: "" });
                    }
                }
                rows.push(row);
            }

            return <>
                <div class="popgiftcards" style={{ display: "flex", flexWrap: "wrap" }}>
                    {prop.data.map((giftCard, index) => { return <RenderGiftCard key={index} index={index} giftCard={giftCard} userContext={siteData} /> })}
                </div>
            </>
        }
        const RenderGiftCard = ({ index,giftCard, userContext }) => {
            if (!giftCard.photo)
                giftCard.photo = "";
            if (!giftCard.giftCertificateCatName)
                giftCard.giftCertificateCatName = "";

            return (

                <div id={"GCCard" + index } className="gift-card quarter-third-half-full" onClick={(event) => GCPopup({ giftCard, userContext,event })}>
                    <div className="inner-gift-card stencil-item " style={{ width: "100%", backgroundImage: `url(${giftCard.photo})`, backgroundPosition: "center", backgroundSize: "cover", position: "relative" }}>
                        <div className="gift-card-text center-all w-full " style={{ fontSize: "1.5em", position: "absolute", textAlign: "center", bottom: "0px", background: "linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.6))", color: "white", height: "33%" }} >{giftCard.giftCertificateCatName}</div>
                    </div>

                </div>
            )



        }
        const GCPopup = ({ giftCard, userContext,event }) => {
            var LoginDetails = utils.ReadCookie();
            if (LoginDetails === undefined || LoginDetails === "" || LoginDetails === null) {                
                window.wbc.openVagaroPage("divVagaroIframe", utils.VURL("Login", null) + addtoCartEnum.PurchaseGC + "&Data=" + event.currentTarget.id);
                $(".modal-backdrop").hide();
                $(".vgn-popup").hide();
                return;
            }
            const BusinessData = utils.MultiLocationData(userContext, userContext.bidHash);
            return ReactDOM.render(
                <GiftCertificatesPopup giftCard={giftCard} userContext={userContext} businessData={BusinessData}
                    selectGC={props.selectGC}
                    selectGCdata={props.selectGCdata}
                />,
                document.getElementById("OpenDialog")
            );
        }

        return (
            <>
                <RenderDefaultLayout data={gcdata} />
            </>
        )
    }

    const PopupCloseConfirmation = () => {
        unmountComponentAtNode(document.getElementById("OpenDialog1"));
    };
   
    return createPortal(
        isLoading ? <Loader /> :
            <><VGPopUp
                id={"VgGiftcertificate"}
                show={true}
                title={"Gift Certificates"}
                body={body()}
                isRemoveTabModalIndex={false}
                isShowFooter={false}
                onHide={PopupCloseConfirmation}
                size={"xl"}
                backdrop={true}
                fullscreen
            />
            </>,
        document.getElementById("OpenDialog1")
    )
}

export default GiftCertificatePopup
