import React, { useEffect, Fragment, useState } from 'react';
import "../../utils/select2_4.0.js";
import "./multilocation.scss";
import $ from 'jquery';

const GroupMultilocationSelect = (props) => {
    const [stateName, setstateName] = useState([]);
    const [selectedstate, setselectedState] = useState("");
    const [selectedLocation, setselectedLocation] = useState("");


    useEffect(() => {
        let StateName = [];
        let StateNameString = [];
        for (let index = 0; index < props.options.length; index++) {
            const element = props.options[index];
            if (!StateNameString.includes(element.stateName + element.stateID)) {
                StateNameString.push(element.stateName + element.stateID);
                StateName.push({ StateName: element.stateName, StateID: element.stateID });
            }
        }
        setstateName(StateName.sort((a, b) => a.StateName.localeCompare(b.StateName, 'en', { sensitivity: 'base' })));
    }, [props.options, props.Mode]);

    const handleAmmount = (selectedOption) => {
        setselectedLocation(selectedOption.encBID);
        props.onChange((props.Mode == "Cart" || props.Mode == "ServiceBook" || props.Mode == "wbClasses" || props.Mode == "wbBook") ? selectedOption.vagaroURL == "" ? selectedOption.encBID : selectedOption.vagaroURL : selectedOption.encBID);
    }


    return (
        <Fragment>
            <div class="Multilocation-main-container">
                {stateName.map((sname, index, array) => {
                    return (
                        <Multilocationcontainer {...props} stateName={array} sname={sname} handleAmmount={handleAmmount} length={array.length} index={index} selectedLocation={selectedLocation} selectedstate={selectedstate} setselectedState={setselectedState} />
                    )
                })
                }

            </div>
        </Fragment>
    );
}
const Multilocationcontainer = (props) => {

    const closetab = (id) => {
        var active = document.getElementById("div-" + id)?.className?.includes("active");
        $(".Multilocation-genral").each(function () {
            $(this).removeClass("active").removeClass("active-label");
        });
        if (active) {
            $("#div-" + id).removeClass("active");
            $("#div-" + id + "-arrow").removeClass("active").parent().removeClass("active-label");
        } else {
            $("#div-" + id).addClass("active");
            $("#div-" + id + "-arrow").addClass("active").parent().addClass("active-label");
        }

    }
    useEffect(() => {
        if (props.index === 0 && props.length < 20) {
            closetab(`${props.sname.StateID}-${props.index}`)
        }
    }, [])

    return (
        <Fragment>
            <div className='data-accordion'>
                <div className={'Multilocation-label accordian-label Multilocation-panel Multilocation-genral'} style={{ display: "flex", cursor: "pointer" }} onClick={() => { closetab(`${props.sname.StateID}-${props.index}`); props.setselectedState(props.index) }}>
                    <div id={`div-${props.sname.StateID}-${props.index}-arrow`} className={'Multilocation-panel-arrow Multilocation-genral'} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="1.3em" height="1.3em" viewBox="0 0 320 512" fill="currentColor"><path d="M273 239c9.4 9.4 9.4 24.6 0 33.9L113 433c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l143-143L79 113c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L273 239z"></path></svg>
                    </div>
                    <h3>{props.sname.StateName}</h3>
                </div>
                <div id={`div-${props.sname.StateID}-${props.index}`} className={"accordion-content  Multilocation-content Multilocation-panel Multilocation-genral d-flex flex-wrap"}>
                    {props.options.map((data) => {
                        if (props.sname.StateName === data.stateName) {
                            return (
                                <div className={props.selectedLocation === data.encBID ? "card-box active-cardbox" : "card-box"} onClick={() => { props.handleAmmount(data); }}>
                                    <div class="vg-box" >
                                        <div class="vg-box-tlt"><h4>{data.businessName}</h4></div>
                                        {data.countryID != 2 &&
                                            <div class="vg-box-decs vp-2">{data.street + ", " + data.city + ", " + data.stateCode + " " + data.zip}</div>
                                        }
                                        {data.countryID == 2 &&
                                            <div class="vg-box-decs vp-2">{data.street + ", " + data.city + ", " + data.zip}</div>
                                        }
                                    </div>
                                </div>
                            )
                        }
                    })}
                </div>
            </div>
        </Fragment>
    );

}

export default GroupMultilocationSelect;
