import React, { useEffect, useState } from "react";
import ReactDOM, { createPortal, unmountComponentAtNode } from "react-dom";
import { Modal } from "react-bootstrap";
import Loader from "../components/Common/Loader";
import { utils, ResponseCode } from "../utils/Utils";
import { AsyncAjaxGet, AsyncAjaxPost } from "../components/Utilities/AsyncAjaxService";
import GiftCertificates from "./GiftCertificates";
import $ from 'jquery';
import { NoRecordsBanner } from "../components/OopsBanner";
import RenderBusinessCart from "../components/BusinessControls/RenderBusinessCart";
import { destroydatepicker } from "./Controls/VagaroDateTimePicker";

const GCPopup = (props) => {
    const merchantID = props.userContext.businessId;
    let LoginDetails = utils.ReadCookie();
    const CurrancySymbol = props.businessData.currencySymbol;
    const CatagoryID = props.giftCard.giftCertificateCatIds;
    const CatagoryName = props.giftCard.giftCertificateCatName;
    const access_token = props.userContext.accessToken;
    const allowMultiLoc = props.allowMultiLoc;
    const userContext = props.userContext
    const [subCatagoryes, setsubCatagory] = useState([]);
    const [gcDisCounts, setGCDiscount] = useState([]);
    const [gcServices, setGCServices] = useState([]);
    const [gcUserDetails, setGCUserDetails] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const Apis = {
        GetSubCatagory: 1,
        GcDiscount: 2,
        GcService: 3,
        AddToCart: 4,
        GetCustomerDetail: 5,
        Getcountrylist: 6
    }
    const callAPI = async (api, data,encBID) => {
        if (utils.Validate(merchantID) && utils.Validate(access_token)) {
            if (api === Apis.GetCustomerDetail) {
                setLoading(false);
            } else {
                setLoading(true);
            }
            switch (api) {
                case Apis.GetSubCatagory:
                    const photoUrl = `merchants/${merchantID}/giftcards/${CatagoryID}`;
                    await AsyncAjaxGet(
                        photoUrl,
                        access_token,
                        function onSuccess(data) {
                            try {
                                if (
                                    data != null &&
                                    data.data != null &&
                                    data.data.responseCode == ResponseCode.Success
                                ) {
                                    if (data != null && data.data != null && data.data.data != null) {
                                        setsubCatagory(data.data.data);
                                    }
                                }
                                setLoading(false);
                            }
                            catch (error) {
                                setLoading(false);
                            }
                        },
                        function OnError() {
                            setLoading(false);
                        }
                    );
                    break;
                case Apis.GcDiscount:
                    const Url = `merchants/${merchantID}/giftcards/users/${LoginDetails.EncUid}`;
                    await AsyncAjaxGet(
                        Url,
                        access_token,
                        function onSuccess(data) {
                            try {
                                if (
                                    data != null &&
                                    data.data != null &&
                                    data.data.responseCode == ResponseCode.Success
                                ) {
                                    if (data != null && data.data != null && data.data.data != null) {
                                        setGCDiscount(data.data.data);
                                    }
                                }
                                setLoading(false);
                            }
                            catch (error) {
                                setLoading(false);
                            }
                        },
                        function OnError() {
                            setLoading(false);
                        }
                    );
                    break;
                case Apis.GcService:
                    const serviceUrl = `merchants/${merchantID}/GiftCards/salonservicecomboforgc?ShouldIncludeAddons=true&IsMultipleLocation=` + allowMultiLoc;
                    await AsyncAjaxGet(
                        serviceUrl,
                        access_token,
                        function onSuccess(data) {
                            try {
                                if (
                                    data != null &&
                                    data.data != null &&
                                    data.data.responseCode == ResponseCode.Success
                                ) {
                                    if (data != null && data.data != null && data.data.data != null) {
                                        var service = []
                                        var groupedOptions = [];
                                        for (var i in data.data.data) {
                                            var value = data.data.data[i]
                                            if (value?.encParentServiceIDHash == "B6589FC6AB0DC82CF12099D1C2D40AB994E8410C") {
                                                var val = data.data.data.filter((arr) => value.encServiceIDHash === arr.encParentServiceIDHash);
                                                groupedOptions.push({
                                                    text: value.serviceTitle,
                                                    children: val.map((da) => da.isOnlineService === true && ((da.eventType == 7 || da.eventType == 1 || da.eventType == 8) ? ((da.price !== 0 || da.price !== 0.00) && ({ id: da.encServiceID, text: da.serviceTitle })):({ id: da.encServiceID, text: da.serviceTitle }))).filter((d) => d !== false ),
                                                })
                                            }
                                        };
                                        service.data = data.data.data;
                                        service.groupedOptions = groupedOptions;
                                        setGCServices(service);
                                    }
                                }
                                setLoading(false);
                            } catch (error) {
                                setLoading(false);
                            }
                        },
                        function OnError() {
                            setLoading(false);
                        }
                    );
                    break;
                case Apis.AddToCart:
                    const AddtoCartUrl = (encBID != undefined && encBID !=null && encBID !="" ) ? encBID + "/addtocart/giftcertificateaddtocart"  : `${merchantID}/addtocart/giftcertificateaddtocart`;
                    await AsyncAjaxPost(
                        AddtoCartUrl,
                        data,
                        access_token,
                        function onSuccess(data) {
                            try {
                                if (
                                    data != null &&
                                    data.data != null &&
                                    data.data.responseCode == ResponseCode.Success
                                ) {
                                    if (data != null && data.data != null && data.data.data != null) {
                                        var gccart = data.data.data;                                        
                                        unmountComponentAtNode(document.getElementById("divcart"));
                                        ReactDOM.render(<RenderBusinessCart data={gccart.itemCount} userContext={userContext}/>, document.getElementById("divcart"));
                                        PopupCloseConfirmation()
                                    }
                                }
                                setLoading(false);
                            } catch (error) {
                                setLoading(false);
                            }
                        },
                        function OnError() {
                            setLoading(false);
                        }
                    );
                    break;
                case Apis.GetCustomerDetail:
                    const CustomerUrl = `${merchantID}/businesslogincustomerdetails/users?EmailId=${encodeURIComponent(data)}`;
                    await AsyncAjaxGet(
                        CustomerUrl,
                        access_token,
                        function onSuccess(data) {
                            try {
                                if (
                                    data != null &&
                                    data.data != null &&
                                    data.data.responseCode == ResponseCode.Success
                                ) {
                                    if (data != null && data.data != null && data.data.data != null) {
                                        setGCUserDetails(data.data.data)
                                    }
                                }else if (                                    
                                    data != null &&
                                    data.data != null &&
                                    data.data.responseCode == ResponseCode.NoRecordFound
                                ) {
                                    setGCUserDetails([])
                                }
                                setLoading(false);
                            }
                            catch (error) {
                                setLoading(false);
                            }
                        },
                        function OnError() {
                            setLoading(false);
                        }
                    );
                    break;
                    
                default:
                    break;
            }
        }
    };
    
    const PopupCloseConfirmation = () => {
        destroydatepicker({
            divid: "#dailydealdaterange",
            textid: "#dailydealdaterange",
            dateTimeFormat: "MMM D, YYYY",
            isMobileFriendly: false,

        });
        unmountComponentAtNode(document.getElementById("OpenDialog"));
        unmountComponentAtNode(document.getElementById("OpenDialog1"));
        
    };

    const handlescroll = () => {
        var datrange = document.getElementById("dailydealdaterange");
        if (datrange && datrange.disabled == false) {
            var Button = document.getElementsByClassName("xdsoft_cancel");
            if (Button[0]) {
                Button[0].click();
            }
 
        }
    }
    
    const addToCart = (reqData,encBID) => {
        callAPI(Apis.AddToCart, reqData,encBID)
    }
    useEffect(() => {
        callAPI(Apis.GetSubCatagory);
        callAPI(Apis.GcDiscount);
        callAPI(Apis.GcService);
        let $allActiveBootboxesModals = $('.modal');
        $allActiveBootboxesModals.each(function (index, modal) {
            let $modal = $(modal);
            $modal.removeAttr('tabindex');
        });
        if (props.otherRemoveTabClass) {
            let $OtherModals = $(`.${props.otherRemoveTabClass}`);
            $OtherModals.each(function (index, modal) {
                let $modal = $(modal);
                $modal.removeAttr('tabindex');
            });
        }
    }, []);
    if (utils.isCheckEmptyOrWhiteSpace(props.businessData)) return <NoRecordsBanner message='Marchant data is not valid.' />;
    const VgThemes =()=>{
        var styleobject = {};
        var gblstyle = $("#dvglbstyle");
        var primarybutton  = gblstyle.find("#btnprimarybutton");
        var secondorybutton  = gblstyle.find("#btnsecondary");  
        var lblstyle   = gblstyle.find("#txtfontfamily");
        var btnrating  =gblstyle.find("#btnrating");
        var glbbackground = gblstyle.find("#glbbackground");
        styleobject.primarybuttoncolor = primarybutton.css("color");
        styleobject.primarybuttonbackgroundcolor = primarybutton.css("background-color");
        styleobject.primarybuttonborderradius = primarybutton.css("border-radius");
        styleobject.primarybuttonboxshadow = primarybutton.css("box-shadow");
        styleobject.secondorybuttonbuttoncolor = secondorybutton.css("color");
        styleobject.secondorybuttonbuttonbackgroundcolor = secondorybutton.css("background-color");    
        styleobject.secondorybuttonbuttonborderradius = secondorybutton.css("border-radius");
        styleobject.secondorybuttonbuttonboxshadow = secondorybutton.css("box-shadow"); 
        styleobject.lblstylefontfamily = lblstyle.css("font-family");
        styleobject.lblstylecolor = lblstyle.css("color");  
        styleobject.lblstylefontweight = lblstyle.css("font-weight");
        styleobject.lblstylefontsize = lblstyle.css("font-size");
        styleobject.btnratingcolor = btnrating.css("color");
        styleobject.glbbackgroundcolor = glbbackground.css("background-color");
        return(
            <>
                <style>
                    {
                        `:root{
                            --vg-primary-bg-modal:${styleobject.glbbackgroundcolor};
                            --vg-body-text-color-modal:${styleobject.lblstylecolor};
                        }
                        `
                    }
                </style>
            </>
        )
        
    }
    return createPortal(
        <><VgThemes />
            {isLoading && <Loader />}
            <Modal
                id={"vgGiftCertificte"}
                show={true}
                onHide={() => PopupCloseConfirmation()}
                dialogClassName={"vgGiftCertificteView"}
                fullscreen={false}
                size={"xl"}
                centered
                scrollable={true}     
            >
                <Modal.Header closeButton>
                    <br />
                </Modal.Header>
                <Modal.Body onScroll={() => handlescroll()}>
                    <GiftCertificates {...props} callAPI={callAPI} addToCart={addToCart} gcUserDetails={gcUserDetails} subCatagoryes={subCatagoryes} gcDisCounts={gcDisCounts} gcServices={gcServices} CurrancySymbol={CurrancySymbol} onHide={() => PopupCloseConfirmation()} allowMultiLoc={allowMultiLoc} userContext={userContext}/>                </Modal.Body>
            </Modal>
        </>
        ,
        document.getElementById("OpenDialog")
    );
};
export default GCPopup;
