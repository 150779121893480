/* Options Start */
/*
  1) divid : Div Id it should starts with # or .
  2) textid : textbox Id it should starts with # or .
  3) dateTimeFormat : Datetime Format
  4) callBackOnHide : Pass function name if you want todo any operation after datetimepicker close
  5) callBackOnChange : Pass function name if you want todo any operation after datetimepicker date Change
  6) preset : This Option is use in case of mobile datetimepicker & it's values are 
     i) For Date time : datetime
     ii)For Date : date
  7) arrValidDates : if you want to enable any spacific dates then pass array of arrValidDates & it's format should be "MMM DD,YYYY"
  8) defaultDateTime : if you want to set datetime into datetimepicker at time you can use this option
  9) pickTime : if you want to use timepicker then use picktime true
  10) formatTime : if picktime true then provide timepicker format
  11) contentWindow : scrollbar content id
  12) isMobileFriendly : default value true if you do not want datetimepicker mobile friendly then set it's value false
  13) disabledWeekDays : Provide array of week numner which you want to disable
  14) MinDate : if you want set Min date then set mindate value
  15) MaxDate : if you want set Min date then set maxdate value
  16) disabledTimePicker : Provide array of Time slot which you want to enable & it's format should be [FromTime,ToTime] & Time format Should be in "AM/PM". //#547730...Virendra Gohil...14-03-2018.
  17) scrollMonth : if you want to set scrolling month on mouse roll

  18) inline : if you want to open datetimepicker after selection of date. //Waitlist 2.0: Add for waitlist,to open popup after selection of date. By Chandni Shah, 03/08/2018
  19) onGenerate : To select multiple dates in datetimepicker //Waitlist 2.0: To select multiple dates in datetimepicker. By Chandni Shah, 08/08/2018
  20) BeforecallBackOnOpenForMobile : This Function will use when mobile multimonth datetime picker open // Waitlist 2.1: Abhishek Shah
  21) AftercallBackOnOpenForMobile : This Function will use when mobile multimonth datetime picker open // Waitlist 2.1: Abhishek Shah
  22) disabledMobileTimePicker: This Function Will use for disbale time when mobile datetime picker open & it's format should be [DayID,FromTime,ToTime] & Time format Should be in "AM/PM". //#547730...Virendra Gohil...14-03-2018.
*/
/* Options Ends */
/* eslint-disable import/first */
import moment from "moment";
import $ from "jquery";
import "./jquery.datetimepicker.full";
import "./jquery.datetimepicker.css"
var serviceDateFormat_year = "MMM DD YYYY hh:mm A";
var userAgent = (navigator.userAgent || navigator.vendor || window.opera).toLowerCase();
var iosDevices = /iPad|iPhone|iPod|iTouch/.test(navigator.userAgent) && !window.MSStream;
var iPadAndroid = userAgent.match(/ipad/i) || userAgent.match(/iphone/i) || userAgent.match(/itouch/i) || userAgent.match(/android/i) || false;
var isWindowsPhone = userAgent.match(/windows phone/i) || false;
var AndroidMobile = userAgent.match(/Android/i) != null && userAgent.match(/Mobile/i) != null && userAgent.match(/Windows/i) == null;
var isiPhone = (userAgent.match(/iphone/i) != null);
var isAndroid = (userAgent.match(/android/i) != null);
var IsMobileDevice = ((/iPad/.test(navigator.userAgent) && !window.MSStream) == false && (isiPhone || isWindowsPhone || AndroidMobile));
var proImportDevice = (navigator.userAgent.toLowerCase().indexOf("com.vagaro.iospro") > -1 || navigator.userAgent.toLowerCase().indexOf("com.vagaro.androidpro") > -1);
var isWidget ="false";
var pageval = 0;
var utcOffset= 30;

function getLocationDate() {
    //var stepMin = (iPadAndroid || isWindowsPhone) ? 15 : 5;
    var stepMin = 5;
    var utcDate = moment();
    var min = utcDate.format('mm');
    var mindiff = parseInt(parseInt(min) % stepMin);
    if (mindiff > 0) {
        min = (min - mindiff) + stepMin;
        utcDate.minutes(min);
    }
    return utcDate;
}
function getLocationDatepickupinstrore() {

    if (window._onlineCheckout.minDate != null) {
        return window._onlineCheckout.minDate;
    }
    //var stepMin = (iPadAndroid || isWindowsPhone) ? 15 : 5;
    var stepMin = 30;
    var utcDate = moment();
    var min = utcDate.format('mm');
    var mindiff = parseInt(parseInt(min) % stepMin);
    if (mindiff > 0) {
        min = (min - mindiff) + stepMin;
        utcDate.minutes(min);
    }
    return utcDate;
}

function getCurrentLocationDate() {
    var stepMin = (iPadAndroid || isWindowsPhone) ? 15 : 5;
    var utcDate = moment();
    if (typeof utcOffset != "undefined") {
        var currDate = moment();
        utcDate = moment(currDate.getUTCFullYear(), currDate.getUTCMonth(), currDate.getUTCDate(), currDate.getUTCHours(), currDate.getUTCMinutes(), currDate.getUTCSeconds());
        utcDate.seconds(utcDate.seconds() + utcOffset);
    }
    var min = utcDate.format('nn');
    var mindiff = parseInt(parseInt(min) % stepMin);
    if (mindiff > 0) {
        min = (min - mindiff) + stepMin;
        utcDate.minutes(min);
    }

    return utcDate;
}

export function applydatepicker(objDateTimePickerOptions) {
    var divid = objDateTimePickerOptions.divid;
    var textid = objDateTimePickerOptions.textid;
    var dateTimeFormat = objDateTimePickerOptions.dateTimeFormat;
    var callBackOnHide = objDateTimePickerOptions.callBackOnHide;
    if (typeof (callBackOnHide) === "undefined" || callBackOnHide == '') {
        callBackOnHide = null;
    }

    var BeforecallBackOnOpenForMobile = objDateTimePickerOptions.BeforecallBackOnOpenForMobile;
    if (typeof (BeforecallBackOnOpenForMobile) === "undefined" || BeforecallBackOnOpenForMobile == '') {
        BeforecallBackOnOpenForMobile = null;
    }

    var AftercallBackOnOpenForMobile = objDateTimePickerOptions.AftercallBackOnOpenForMobile;
    if (typeof (AftercallBackOnOpenForMobile) === "undefined" || AftercallBackOnOpenForMobile == '') {
        AftercallBackOnOpenForMobile = null;
    }

    var callBackOnChange = objDateTimePickerOptions.callBackOnChange;
    if (typeof (callBackOnChange) === "undefined" || callBackOnChange == '') {
        callBackOnChange = null;
    }
	//To select multiple dates in datetimepicker //Waitlist 2.0: To select multiple dates in datetimepicker. By Chandni Shah, 08/08/2018
    var onGenerate = objDateTimePickerOptions.onGenerate;
    if (typeof (onGenerate) === "undefined" || onGenerate == '') {
        onGenerate = null;
    }
    var preset = objDateTimePickerOptions.preset;
    if (typeof (preset) === "undefined" || preset == null || preset == '') {
        preset = 'datetime';
    }

    var arrValidDates = objDateTimePickerOptions.arrValidDates;
    if (typeof (arrValidDates) === "undefined") {
        arrValidDates = null;
    }
    var strdefaultDateTime = objDateTimePickerOptions.defaultDateTime;

    var pickTime = objDateTimePickerOptions.pickTime;
    if (typeof (pickTime) === "undefined") {
        pickTime = false;
    }

    var formatTime = objDateTimePickerOptions.formatTime;
    if (typeof (formatTime) === "undefined") {
        formatTime = null;
    }

    var contentWindow = objDateTimePickerOptions.contentWindow;
    if (typeof (contentWindow) === "undefined") {
        contentWindow = null;
    }
    var parentID = objDateTimePickerOptions.parentID;
    if (typeof (parentID) === "undefined") {
        parentID = 'body';
    }

    var scrollMonth = objDateTimePickerOptions.scrollMonth;
    if (typeof (scrollMonth) === "undefined") {
        scrollMonth = true;
    }

    var isMobileFriendly = objDateTimePickerOptions.isMobileFriendly;
    if (typeof (isMobileFriendly) === "undefined") {
        if (typeof _vgClassSessionSelection !== 'undefined' && typeof _vgClassSessionSelection.VisibleControlLength !== 'undefined' && _vgClassSessionSelection.VisibleControlLength("#mobile-check") > 0 && ($("body").hasClass("Embedded_Widget") == false || ($("body").hasClass("Embedded_Widget") == true && typeof objDateTimePickerOptions.isApplyInWidget != 'undefined' && objDateTimePickerOptions.isApplyInWidget != null && objDateTimePickerOptions.isApplyInWidget == true))) {
            isMobileFriendly = true;
        }
        else {
            isMobileFriendly = false;
        }
    }

    var isPopupWidget = typeof (isWidget) != "undefined" && isWidget.toLowerCase() == "true" && $("body").hasClass("Popup_Widget");
    if (isPopupWidget) {
        isMobileFriendly = false;
    }

    var disabledWeekDays = objDateTimePickerOptions.disabledWeekDays;
    if (typeof (disabledWeekDays) === "undefined") {
        disabledWeekDays = null;
    }
    
    //Added by Ravi shah on 5th Feb 2018 for timepicker disable logic
    var disabledTimePicker = objDateTimePickerOptions.disabledTimePicker;
    if (typeof (disabledTimePicker) === "undefined") {
        disabledTimePicker = null;
    }
    //Ended by Ravi shah on 5th Feb 2018 for timepicker disable logic
    
    //#547730...Virendra Gohil...14-03-2018.
    var disabledMobileTimePicker = objDateTimePickerOptions.disabledMobileTimePicker;
    if (typeof (disabledMobileTimePicker) === "undefined")
    {
        disabledMobileTimePicker = null;
    }

    var strMinDate = objDateTimePickerOptions.MinDate;
    if (typeof (strMinDate) === "undefined") {
        strMinDate = null;
    }

    var strMaxDate = objDateTimePickerOptions.MaxDate;
    if (typeof (strMaxDate) === "undefined") {
        strMaxDate = null;
    }
    var unavailableShowDate = objDateTimePickerOptions.unavailableShowDate;
    if (typeof (unavailableShowDate) === "undefined") {
        unavailableShowDate = false;
    }
    //Waitlist 2.0: Add for waitlist,to open popup after selection of date. By Chandni Shah, 03/08/2018
    var inline = objDateTimePickerOptions.inline;
    if (typeof (inline) === "undefined") {
        inline = false;
    }

    if (isMobileFriendly && typeof (objDateTimePickerOptions.flagVgMultiMonthDatePicker) != undefined && objDateTimePickerOptions.flagVgMultiMonthDatePicker) {
        $(textid).data("dtmindate", "");
        var options = {};
        if (typeof (objDateTimePickerOptions.numberOfMonths) != "undefined" && objDateTimePickerOptions.numberOfMonths > 0) {
            options.numberOfMonths = objDateTimePickerOptions.numberOfMonths;
        }

        if (typeof (objDateTimePickerOptions.ignoreValidation) != "undefined" && objDateTimePickerOptions.ignoreValidation != null) {
            options.ignoreValidation = objDateTimePickerOptions.ignoreValidation;
        }

        if (arrValidDates != null && arrValidDates.length > 0) {
            options.arrValidDates = arrValidDates;
            if (arrValidDates[0] != "") {
                strMinDate = new Date(moment(arrValidDates[0])._d);
                strMaxDate = new Date(moment(arrValidDates[arrValidDates.length - 1])._d);
                $(textid).data("dtmindate", arrValidDates[0]);
            }
        }

        if (strMinDate != null) {
            options.minDate = new Date(strMinDate);
        }

        if (strMaxDate != null) {
            options.maxDate = new Date(strMaxDate);
        }

        if (typeof (dateTimeFormat) != "undefined" && dateTimeFormat != null) {
            options.dateFormat = dateTimeFormat;
        }
        
        if (disabledWeekDays != null) {
            options.disabledWeekDays = disabledWeekDays;
        }
        
        if (disabledTimePicker != null)
        {
            options.disabledTimePicker = disabledTimePicker;
        }
        if (typeof strdefaultDateTime != "undefined" && strdefaultDateTime != "") {
            var curdt = new Date(strdefaultDateTime);
            if (date > curdt) {
                strdefaultDateTime = date;
                curdt = date;
            }
            else {
                date = curdt;
            }
            options.selectedDate = strdefaultDateTime;
        }

        if (callBackOnChange != null) {
            options.ondatechange = callBackOnChange;
        }
        
		//To select multiple dates in datetimepicker //Waitlist 2.0: To select multiple dates in datetimepicker. By Chandni Shah, 08/08/2018
        //if (onGenerate != null) {
        //    dateTimePickerOptions.monthChangeSpinner = false;
        //    options.onGenerate = onGenerate;
        //}

        if (callBackOnHide != null) {
            options.onclose = callBackOnHide;
        }
       
        options.openBeforeCallBack = BeforecallBackOnOpenForMobile;
        options.openAfterCallBack = AftercallBackOnOpenForMobile;
        $(textid).vgMultiMonthDatePicker(options);
        return;
    }

    var inputdate = divid + "input";
    if ((iPadAndroid || isWindowsPhone) && isMobileFriendly == true) {
        if ($(divid).find(inputdate).length == 0) {

            $(divid).append("<input id=" + inputdate.replace(/[#.]/g, '') + " type=\"text\" style=\"display:none\" />");
            var ThemeName = 'android-ics light';
            var DisplayName = 'bottom';
            var MobiscrolldateOrder = "y";
            var stepMinutes = 15;

            //if (iosDevices) {
                ThemeName = 'ios';
                DisplayName = 'bottom';
                MobiscrolldateOrder = "y";
            //}
            if (isWindowsPhone) {
                ThemeName = 'wp light';
                DisplayName = 'modal';
                MobiscrolldateOrder = "MD ddyy";//"y";
            }

            var date;
            var minDate;
            var maxDate;
            if (arrValidDates != null) {
                if (arrValidDates.length > 0 && arrValidDates[0] != "") {
                    date = new Date(moment(arrValidDates[0])._d);
                    minDate = date;
                    maxDate = new Date(moment(arrValidDates[arrValidDates.length - 1])._d);
                    $(textid).data("dtmindate", arrValidDates[0]);

                }
                else {
                    arrValidDates = null;
                    date = new Date();
                    minDate = date;
                    maxDate = date;
                    $(textid).data("dtmindate", "");
                }
            }
            else if (arrValidDates == null) {
                if (typeof (getLocationDate) == 'undefined') {
                    if (typeof (getLocationDatepickupinstrore) == 'undefined') {
                        date = getCurrentLocationDate();
                    }
                    else if (typeof (pageval) != 'undefined' && typeof (getLocationDatepickupinstrore) != 'undefined' && pageval =='onlineshopppingcart') {
                        date = getLocationDatepickupinstrore();
                    }
                    else {
                        date = getCurrentLocationDate();
                    }
                }
                else { date = getLocationDate(); }
                var min = date.format('nn');
                var mindiff = parseInt(parseInt(min) % stepMinutes);
                if (mindiff > 0) {
                    min = (min - mindiff) + stepMinutes;
                    date.setMinutes(min);
                }
                minDate = date;
                maxDate = new Date(date);
                maxDate.setMonth(maxDate.getMonth() + 6);
            }

            if (preset == 'date' && unavailableShowDate == true) {
                $("body").addClass('iosdateonly');
            }            
            var datetimeoption = {
                preset: preset,//'datetime',
                minDate: (strMinDate != null ? strMinDate : minDate),
                maxDate: (strMaxDate != null ? strMaxDate : maxDate),
                stepMinute: stepMinutes,
                dateFormat: 'M dd yy',
                timeWheels: 'hhii A',
                separator: ' ',
                dateOrder: MobiscrolldateOrder,
                mergeDate: !isWindowsPhone, //Set true if you want to combine date, month and year drop down
                mergeTime: !isWindowsPhone, //set true if you want to combine hours, minutes and am/pm drop down
                validDates: arrValidDates,                
                unavailableShowDate: unavailableShowDate,
                onCancel: function () {
                    $(inputdate).val('Anytime');
                    $(textid).data("date", 'Anytime');
                    $(textid).val('Anytime');
                    if (typeof (callBackOnHide) != "undefined" && callBackOnHide != null) {
                        callBackOnHide(divid, textid);
                    }

                    $(inputdate).mobiscroll('hide');
                },
                onSelect: function (obj) {
                    ;
                    $(inputdate).val(moment(obj).format(serviceDateFormat_year));
                    $(textid).data("date", obj);
                    $(textid).val(moment(obj).format(dateTimeFormat));
                    if (typeof (callBackOnHide) != "undefined" && callBackOnHide != null) {
                        callBackOnHide(divid, textid);
                    }
                    if (typeof (callBackOnChange) != "undefined" && callBackOnChange != null) {
                        callBackOnChange(new Date(moment(obj)));
                    }
					////To select multiple dates in datetimepicker //Waitlist 2.0: To select multiple dates in datetimepicker. By Chandni Shah, 08/08/2018
     //               if (typeof (onGenerate) != "undefined" && onGenerate != null) {
     //                   onGenerate(new Date(moment(obj)));
     //           }
                }
            };
            
            //#547730...Virendra Gohil...14-03-2018.
            if (disabledWeekDays != null)
            {
                datetimeoption.disabledWeekDays = disabledWeekDays;
            }
            if (disabledMobileTimePicker != null)
            {
                datetimeoption.disabledMobileTimePicker = disabledMobileTimePicker;
            }

            if (typeof strdefaultDateTime != "undefined" && strdefaultDateTime != "") {
                var curdt = new Date(strdefaultDateTime);
                if (date > curdt) {
                    strdefaultDateTime = date;
                    curdt = date;
                }
                else {
                    date = curdt;
                }
            }
            $(textid).data("date", moment(date).format(serviceDateFormat_year));
            $(textid).data("preset", preset);
            $(inputdate).val(moment(date).format(serviceDateFormat_year)).scroller('destroy').scroller($.extend(datetimeoption, {
                theme: ThemeName, mode: "scroller", display: DisplayName
            }));
            $(textid).val(moment(date).format(dateTimeFormat));
            $(textid).bind('click touchend', function (e, data) {
                $(inputdate).mobiscroll('show');
            });

        }
        else {
            if (typeof strdefaultDateTime != "undefined" && strdefaultDateTime != "") {
                var curdt = new Date(strdefaultDateTime);
                if (date > curdt) {
                    strdefaultDateTime = date;
                    curdt = date;
                }
                else {
                    date = curdt;
                }
            }

            $(textid).data("date", moment(date).format(serviceDateFormat_year));
            $(inputdate).val(moment(date).format(serviceDateFormat_year))
            $(textid).val(moment(date).format(dateTimeFormat));
        }

    }
    else {
        if (typeof $(textid).data("xdsoft_datetimepicker") === 'undefined' || $(textid).data("xdsoft_datetimepicker") == null) {
            $.datetimepicker.setDateFormatter({
                parseDate: function (date, format) {
                    var d = moment(date, format);
                    return d.isValid() ? d.toDate() : false;
                },

                formatDate: function (date, format) {
                    return moment(date).format(format);
                }
            });

            var date;
            var maxDate;
            var picker;

            var dateTimePickerOptions = {
                minDate: new Date(),
                maxDate: new Date(),
                defaultDate: new Date(),
                format: dateTimeFormat,
                timepickerScrollbar: pickTime,
                timepicker: pickTime,
                validateOnBlur: false,
                id: $(textid).attr("id") + "_picker",
                closeOnTimeSelect: false,
                todayButton: false,
                showOkCloseButtons: (pickTime == true ? true : false),
                yearStart: new Date().getFullYear(),
                yearEnd: ((new Date()).getFullYear() + 5),
                scrollMonth: scrollMonth,
                
                inline: inline //Waitlist 2.0: Add for waitlist,top open popup after selection of date. By Chandni Shah, 03/08/2018
            };

            if (objDateTimePickerOptions.yearStart != null) {
                dateTimePickerOptions.yearStart = objDateTimePickerOptions.yearStart;
            }
            if (objDateTimePickerOptions.yearEnd != null) {
                dateTimePickerOptions.yearEnd = objDateTimePickerOptions.yearEnd;
            }
            if (contentWindow != null) {
                dateTimePickerOptions.contentWindow = contentWindow;
            }

            dateTimePickerOptions.parentID = parentID;

            if (disabledWeekDays != null) {
                dateTimePickerOptions.disabledWeekDays = disabledWeekDays;
            }

            //Added by Ravi shah on 5th Feb 2018 for timepicker disable logic
            
            if (disabledTimePicker != null)
            {
                dateTimePickerOptions.disabledTimePicker = disabledTimePicker;
            }
            //Ended by Ravi shah on 5th Feb 2018 for timepicker disable logic
            
            if (formatTime != null) {
                dateTimePickerOptions.formatTime = formatTime;
                dateTimePickerOptions.step = 30;
            }
            if (arrValidDates != null) {
                if (arrValidDates.length > 0 && arrValidDates[0] != "") {
                    date = new Date(moment(arrValidDates[0])._d);
                    maxDate = new Date(moment(arrValidDates[arrValidDates.length - 1])._d);

                    dateTimePickerOptions.defaultDate = new Date(date);
                    dateTimePickerOptions.minDate = new Date(date);
                    dateTimePickerOptions.maxDate = new Date(maxDate);
                    dateTimePickerOptions.allowDates = arrValidDates;
                    $(textid).data("dtmindate", arrValidDates[0]);

                    dateTimePickerOptions.yearEnd = new Date(moment(arrValidDates[arrValidDates.length - 1])).getFullYear();
                }
                else {
                    date = new Date();
                    dateTimePickerOptions.allowDates = arrValidDates;
                    $(textid).data("dtmindate", "");
                }
            }
            else {
                if (typeof (getLocationDate) == 'undefined') {
                    if (typeof (getLocationDatepickupinstrore) == 'undefined') {
                        date = getCurrentLocationDate();
                    }
                    else if (typeof (pageval) != 'undefined' && typeof (getLocationDatepickupinstrore) != 'undefined' && pageval == 'onlineshopppingcart') {
                        date = getLocationDatepickupinstrore();
                    }
                    else {
                        date = getCurrentLocationDate();
                    }
                }
                else { date = getLocationDate(); }
                if (typeof (getLocationDate) == 'undefined') {
                   date = getCurrentLocationDate();
                }
                else {
                   date = getLocationDate();
                }
                var min = date.format('nn');
                var mindiff = parseInt(parseInt(min) % 5);
                if (mindiff > 0) {
                    min = (min - mindiff) + 5;
                    date.minutes(min);
                }
                maxDate = new Date(date);
                maxDate.setMonth(maxDate.getMonth() + 6);

                dateTimePickerOptions.defaultDate = new Date(date);
                dateTimePickerOptions.minDate = new Date(date);
                dateTimePickerOptions.maxDate = maxDate;

                if (pickTime == true) {
                    dateTimePickerOptions.minDateTime = new Date(date);
                }

            }
            if (typeof (callBackOnChange) != "undefined" && callBackOnChange != null) {
                
                dateTimePickerOptions.onSelectDate = function (ev) {
                    callBackOnChange(new Date(ev));
                };
            }
			//To select multiple dates in datetimepicker //Waitlist 2.0: To select multiple dates in datetimepicker. By Chandni Shah, 08/08/2018
            if (typeof (onGenerate) != "undefined" && onGenerate != null) {
                dateTimePickerOptions.monthChangeSpinner = false;
                dateTimePickerOptions.onGenerate = function (ev) {
                    onGenerate(new Date(ev));
                };
            }
            

            if (typeof (callBackOnHide) != "undefined" && callBackOnHide != null) {
                dateTimePickerOptions.onClose = function (e) {
                    callBackOnHide(divid, textid);
                };
            }

            if (strMinDate != null) {
                dateTimePickerOptions.minDate = strMinDate;
                if (pickTime == true) {
                    dateTimePickerOptions.minDateTime = strMinDate;
                }
            }
            if (strMaxDate != null) {
                dateTimePickerOptions.maxDate = strMaxDate;
            }
             picker = $(textid).datetimepicker(dateTimePickerOptions);
        }

        if ((typeof (strdefaultDateTime) == 'undefined' || strdefaultDateTime == null) && pickTime == false && arrValidDates != null && arrValidDates.length > 0 && arrValidDates[0] != "") {
            
            var selectedDate = new Date(getdatepickervalue(textid));
            var minDate = new Date(moment(arrValidDates[0])._d);
            if (selectedDate <= minDate) {
                strdefaultDateTime = new Date(minDate);
            }
            else if (arrValidDates.indexOf(selectedDate.format("MMM DD,YYYY")) === -1) {
                for (var i = 0; i < arrValidDates.length; i++) {
                    var currDate = new Date(moment(arrValidDates[i])._d);
                    var flagFoundValid = false;
                    if (currDate > selectedDate) {
                        strdefaultDateTime = currDate;
                        flagFoundValid = true;
                    }

                    if (!flagFoundValid) {
                        strdefaultDateTime = new Date(moment(arrValidDates[0])._d);
                    }
                }
            }
        }

        if (typeof strdefaultDateTime != "undefined" && strdefaultDateTime != "") {
            if (typeof Search != "undefined" && window.Search.IsFromSearchrPage() && arrValidDates != null && arrValidDates.length > 0 && strdefaultDateTime.format() == new Date(moment(arrValidDates[arrValidDates.length - 1])._d).format()) {
                setdatepickervalue(objDateTimePickerOptions, new Date(moment($('#ucAppointments_hdnDate').val()).format()));
            }
            else {
                setdatepickervalue(objDateTimePickerOptions, new Date(moment(strdefaultDateTime).format()))
            }
        }
    }
}

function getdatepickervalue(textid, isMobileFriendly, flagVgMultiMonthDatePicker, isApplyInWidget, istxtValue) {
    if (typeof (isMobileFriendly) === "undefined" || isMobileFriendly == null) {
        if (typeof window._vgClassSessionSelection !== 'undefined' && typeof window._vgClassSessionSelection.VisibleControlLength !== 'undefined' && window._vgClassSessionSelection.VisibleControlLength("#mobile-check") > 0 && ($("body").hasClass("Embedded_Widget") == false || ($("body").hasClass("Embedded_Widget") == true && typeof isApplyInWidget != 'undefined' && isApplyInWidget != null && isApplyInWidget == true))) {
            isMobileFriendly = true;
        }
        else {
            isMobileFriendly = false;
        }
    }
    
    var isPopupWidget = typeof (isWidget) != "undefined" && isWidget.toLowerCase() == "true" && $("body").hasClass("Popup_Widget");
    if (isPopupWidget) {
        isMobileFriendly = false;
    }

    if (isMobileFriendly && typeof (flagVgMultiMonthDatePicker) != "undefined" && flagVgMultiMonthDatePicker) {
        var value = $(textid).data("vgselectedvalue").split("-");
        return new Date(value[0], value[1] - 1, value[2]);
    }

    if ((iPadAndroid || isWindowsPhone) && isMobileFriendly == true) {
        if (typeof $(textid).data("preset") != 'undefined' && $(textid).data("preset").toLowerCase() == "datetime") {
            return new Date($(textid).data("date"));
        }
        else {
            
            var selectedDate = new Date($(textid).data("date"));
            return new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate());
        }

    }
    else {
        if ($(textid).data("xdsoft_datetimepicker").data('options').timepicker == true) {
            return new Date(moment($(textid).data("xdsoft_datetimepicker").getValue()));
        }
        else {
            if ($(textid).val() == "" || $(textid).val() == "Never") {
                return "";
            }
            else if (typeof (istxtValue) != "undefined" && istxtValue != null && istxtValue != "" && istxtValue == true) {
                
                var selectedDate = new Date(moment($(textid).val()).format());
                return new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate());
            }
            else {
            var selectedDate = new Date(moment($(textid).data("xdsoft_datetimepicker").getValue()).format());
            return new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate());
        }
        }

    }
}

function setdatepickervalue(objDateTimePickerOptions, value) {
    var isMobileFriendly = objDateTimePickerOptions.isMobileFriendly;
    if (typeof (isMobileFriendly) === "undefined") {
        if (typeof window._vgClassSessionSelection !== 'undefined' && typeof window._vgClassSessionSelection.VisibleControlLength !== 'undefined' && window._vgClassSessionSelection.VisibleControlLength("#mobile-check") > 0 && ($("body").hasClass("Embedded_Widget") == false || ($("body").hasClass("Embedded_Widget") == true && typeof objDateTimePickerOptions.isApplyInWidget != 'undefined' && objDateTimePickerOptions.isApplyInWidget != null && objDateTimePickerOptions.isApplyInWidget == true))) {
            isMobileFriendly = true;
        }
        else {
            isMobileFriendly = false;
        }
    }

    var isPopupWidget = typeof (isWidget) != "undefined" && isWidget.toLowerCase() == "true" && $("body").hasClass("Popup_Widget");
    if (isPopupWidget) {
        isMobileFriendly = false;
    }

    var divid = objDateTimePickerOptions.divid;
    var textid = objDateTimePickerOptions.textid;
    var dateTimeFormat = objDateTimePickerOptions.dateTimeFormat;

    if (isMobileFriendly && typeof (objDateTimePickerOptions.flagVgMultiMonthDatePicker) != "undefined" && objDateTimePickerOptions.flagVgMultiMonthDatePicker) {
        if (typeof (_shopdetail) != "undefined" && window._shopdetail.IsClassSelected()) {
            $(textid).vgMultiMonthDatePicker({ value: value, dateFormat: dateTimeFormat, ignoreValidation: true });
        }
        else if (typeof (_invoice) != "undefined") {
            $(textid).vgMultiMonthDatePicker({ value: value, dateFormat: dateTimeFormat, ignoreValidation: true });
        }
        else {
            $(textid).vgMultiMonthDatePicker({ value: value, dateFormat: dateTimeFormat});
        }
        return;
    }

    if ((iPadAndroid || isWindowsPhone) && isMobileFriendly == true) {
        $(divid).find(divid + "input").val(moment(value).format(serviceDateFormat_year))
        $(textid).val(moment(value).format(dateTimeFormat));
        $(textid).data("date", moment(value).format(serviceDateFormat_year));
    }
    else {
        textid = $(textid);
        $(textid).datetimepicker({ "value": value });
        if (value == "" || value == "Never") {
            $(textid).val(value);
        }
    }
}

export function destroydatepicker(objDateTimePickerOptions) {
    var divid = objDateTimePickerOptions.divid;
    var textid = objDateTimePickerOptions.textid;

    var isMobileFriendly = objDateTimePickerOptions.isMobileFriendly;
    if (typeof (isMobileFriendly) === "undefined") {
        // Only for mobile this condition will true 
        if (typeof window._vgClassSessionSelection !== 'undefined' && typeof window._vgClassSessionSelection.VisibleControlLength !== 'undefined' && window._vgClassSessionSelection.VisibleControlLength("#mobile-check") > 0 && ($("body").hasClass("Embedded_Widget") == false || ($("body").hasClass("Embedded_Widget") == true && typeof objDateTimePickerOptions.isApplyInWidget != 'undefined' && objDateTimePickerOptions.isApplyInWidget != null && objDateTimePickerOptions.isApplyInWidget == true))) {
            isMobileFriendly = true;
        }
        else {
            isMobileFriendly = false;
        }
    }

    var isPopupWidget = typeof (isWidget) != "undefined" && isWidget.toLowerCase() == "true" && $("body").hasClass("Popup_Widget");
    if (isPopupWidget) {
        isMobileFriendly = false;
    }
    
    if (isMobileFriendly && typeof (objDateTimePickerOptions.flagVgMultiMonthDatePicker) != "undefined" && objDateTimePickerOptions.flagVgMultiMonthDatePicker) {
        $(textid).vgMultiMonthDatePicker("destroy");
    }
    else {
        if ((iPadAndroid || isWindowsPhone) && isMobileFriendly == true) {
            var inputdate = divid + "input";
            if ($(divid).find(inputdate).length > 0) {
                $(inputdate).scroller('destroy');
                $(textid).unbind('click touchend');
                $(inputdate).remove();
            }

        }
        else {
            if (typeof $(textid).data("xdsoft_datetimepicker") != 'undefined' && $(textid).data("xdsoft_datetimepicker") != null) {
                $(textid).datetimepicker('destroy')
            }
        }
    }
    applydatepicker(objDateTimePickerOptions);
}

function enabledisabledatepicker(isEnable, objDateTimePickerOptions) {
    var divid = objDateTimePickerOptions.divid;
    var textid = objDateTimePickerOptions.textid;

    var isMobileFriendly = objDateTimePickerOptions.isMobileFriendly;
    if (typeof (isMobileFriendly) === "undefined") {
        isMobileFriendly = true;
    }

    var isPopupWidget = typeof (isWidget) != "undefined" && isWidget.toLowerCase() == "true" && $("body").hasClass("Popup_Widget");
    if (isPopupWidget) {
        isMobileFriendly = false;
    }

    if (isMobileFriendly && typeof (objDateTimePickerOptions.flagVgMultiMonthDatePicker) != "undefined" && objDateTimePickerOptions.flagVgMultiMonthDatePicker) {
        $(textid).vgMultiMonthDatePicker("hide");
        $(textid).prop('disabled', !isEnable);
        return;
    }

    if (isEnable) {
        if ((iPadAndroid || isWindowsPhone) && isMobileFriendly == true) {
            var inputdate = divid + "input";
            $(textid).unbind('click touchend');
            $(textid).bind('click touchend', function (e, data) {
                $(inputdate).mobiscroll('show');
            });
        }
        else {
            $(textid).prop('disabled', false)
        }
    }
    else {
        if ((iPadAndroid || isWindowsPhone) && isMobileFriendly == true) {
            $(textid).unbind('click touchend');
        }
        else {
            $(textid).prop('disabled', true)
        }
    }
}

function getMinDateValue(objDateTimePickerOptions) {
    var mindate = $(objDateTimePickerOptions.textid).data("dtmindate");
    if (typeof (mindate) != "undefined" && mindate != "") {
        return new Date(moment(mindate)._d)
    }
    else {
        return new Date(moment($(objDateTimePickerOptions.textid).data("xdsoft_datetimepicker").getValue()));
    }

}
/* eslint no-undef: "off"*/
export function HideShowdatepicker(isShow, objDateTimePickerOptions) {
    var divid = objDateTimePickerOptions.divid;
    var textid = objDateTimePickerOptions.textid;

    var isMobileFriendly = objDateTimePickerOptions.isMobileFriendly;
    if (typeof (isMobileFriendly) === "undefined") {
        //isMobileFriendly = true;
        if (typeof _vgClassSessionSelection !== 'undefined' && typeof _vgClassSessionSelection.VisibleControlLength !== 'undefined' && _vgClassSessionSelection.VisibleControlLength("#mobile-check") > 0 && ($("body").hasClass("Embedded_Widget") == false || ($("body").hasClass("Embedded_Widget") == true && typeof objDateTimePickerOptions.isApplyInWidget != 'undefined' && objDateTimePickerOptions.isApplyInWidget != null && objDateTimePickerOptions.isApplyInWidget == true))) {
            isMobileFriendly = true;
        }
        else {
            isMobileFriendly = false;
        }
    }

    var isPopupWidget = typeof (isWidget) != "undefined" && isWidget.toLowerCase() == "true" && $("body").hasClass("Popup_Widget");
    if (isPopupWidget) {
        isMobileFriendly = false;
    }

    if (isMobileFriendly && typeof (objDateTimePickerOptions.flagVgMultiMonthDatePicker) != "undefined" && objDateTimePickerOptions.flagVgMultiMonthDatePicker) {
        $(textid).vgMultiMonthDatePicker(isShow ? "show" : "hide");
        return;
    }

    if (isShow == true) {
        if ((iPadAndroid || isWindowsPhone) && isMobileFriendly == true) {
            $(divid + "input").mobiscroll('show');
        }
        else {
            $(textid).datetimepicker('show');
        }
    }
    else {
        if ((iPadAndroid || isWindowsPhone) && isMobileFriendly == true) {
            $(divid + "input").mobiscroll('hide');
        }
        else {
            $(textid).datetimepicker('hide');
        }
    }
}

function setMinMaxTimeValue(objDateTimePickerOptions) {
    
    var isMobileFriendly = objDateTimePickerOptions.isMobileFriendly;
    if (typeof (isMobileFriendly) === "undefined") {
        if (typeof window._vgClassSessionSelection !== 'undefined' && typeof window._vgClassSessionSelection.VisibleControlLength !== 'undefined' && window._vgClassSessionSelection.VisibleControlLength("#mobile-check") > 0 && ($("body").hasClass("Embedded_Widget") == false || ($("body").hasClass("Embedded_Widget") == true && typeof objDateTimePickerOptions.isApplyInWidget != 'undefined' && objDateTimePickerOptions.isApplyInWidget != null && objDateTimePickerOptions.isApplyInWidget == true)))
        {
            isMobileFriendly = true;
        }
        else
        {
            isMobileFriendly = false;
        }
    }

    var isPopupWidget = typeof (isWidget) != "undefined" && isWidget.toLowerCase() == "true" && $("body").hasClass("Popup_Widget");
    if (isPopupWidget) {
        isMobileFriendly = false;
    }

    var divid = objDateTimePickerOptions.divid;
    var textid = objDateTimePickerOptions.textid;
    var dateTimeFormat = objDateTimePickerOptions.dateTimeFormat;
    if ((iPadAndroid || isWindowsPhone) && isMobileFriendly == true) {
        //$(divid).find(divid + "input").val(moment(value).format(serviceDateFormat_year))
        //$(textid).val(moment(value).format(dateTimeFormat));
        //$(textid).data("date", moment(value).format(serviceDateFormat_year));
    }
    else {
        textid = $(textid);
        
        $(textid).data("xdsoft_datetimepicker").setOptions({
            //minTime: objDateTimePickerOptions.minTime.replace("AM", " AM").replace("PM", " PM"),
            //maxTime: objDateTimePickerOptions.maxTime.replace("AM", " AM").replace("PM", " PM")
            //#547730...Virendra Gohil...14-03-2018.
            disabledTimePicker: objDateTimePickerOptions.disabledTimePicker
        });
    }
}

//function Register_MouseWheel_event(divId) {
//    try {
//        setTimeout(function () {
//            var elem1 = document.getElementById(divId.replace('#', '')).getElementsByClassName('xdsoft_datepicker active')[0]
//            if (elem1.addEventListener) {
//                if ('onwheel' in document) {
//                    elem1.addEventListener('wheel', MouseWheel_Event);
//                }
//                else if ('onmousewheel' in document) {
//                    elem1.addEventListener('mousewheel', MouseWheel_Event);
//                }
//                else {
//                    elem1.addEventListener('MozMousePixelScroll', MouseWheel_Event);
//                }
//            }
//            else {
//                elem1.attachEvent('onmousewheel', MouseWheel_Event);
//            }
//        }, 500);
//    } catch (e) {
//    }
//}

//function MouseWheel_Event(e) {    
//    try {
//        e = e || window.event;
//        var delta = e.deltaY || e.detail || e.wheelDelta;
//        if (delta > 0) {
//            $(this).find('.xdsoft_monthpicker .xdsoft_next').mousedown();
//            $(this).find('.xdsoft_monthpicker .xdsoft_next').mouseup();
//        } else {
//            $(this).find('.xdsoft_monthpicker .xdsoft_prev').mousedown();
//            $(this).find('.xdsoft_monthpicker .xdsoft_prev').mouseup();
//        }
//        e.preventDefault();
//        e.stopPropagation();
//    } catch (e) {}    
//    return false;
//}