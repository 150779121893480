
import { ScreenSize } from "./ScreenSize";
import CreateNewGUID from "../Utilities/CreateNewGUID";



export type attCollectionKeyValue = { [key: string]: string };


export class AttributeCollection {
    id: string = "";
    typename: string = "";
    screenSizeStyleAttributes: attCollectionKeyValue[] = [
        {}, //Desktop
        {}, //Tablet
        {}, //Mobile
    ];

    constructor(desktopStyles:attCollectionKeyValue = {}, componentAtts:attCollectionKeyValue = {}) {
        this.id = CreateNewGUID();
        this.componentAttributes = componentAtts;
        this.screenSizeStyleAttributes[0] = desktopStyles;
    }


    componentAttributes: attCollectionKeyValue = {};

}

export default class LayoutNode extends AttributeCollection {

    content: string | null = null;

    parent: LayoutNode | null = null;
    childIndex: number = -1;

    children: LayoutNode[] = []

    tokenClasses: string = "";

    inView: boolean = true;

    data?: any = undefined;

    screenResolutions:string = "";

    forceUpdate: () => void = () => { };



}
//fucnctions kept outside of the class to avoid compile issues with "function not found" when calling the function from within the layoutNode class *FRUSTATING*

export function getNodeStyleAttributes(node: AttributeCollection, screenSize: ScreenSize) {

    return node.screenSizeStyleAttributes[screenSize];

}

export function setNodeStyleAttributes(node: AttributeCollection, screenSize: ScreenSize, attributes: { [key: string]: string }) {

    node.screenSizeStyleAttributes[screenSize] = attributes;
}


export function setNodeStyleAttributeValue(node: AttributeCollection, screen: ScreenSize, key: string, value: string | null | undefined) {


    let atts = { ...node.screenSizeStyleAttributes[screen] };
    if (!value || value == "") {


        try {


            if (atts && atts.hasOwnProperty(key)) {

                delete atts[key];
                node.screenSizeStyleAttributes[screen] = atts;

            }
        } catch (e) {
            console.warn(e);
        }
        return;
    }
    else {

        if (atts[key])
            atts[key] = value;
        else
            atts = { ...atts, [key]: value };
        //const newStyle:attCollectionKeyValue = { ...atts, [key]: value };

        node.screenSizeStyleAttributes[screen] = atts;
    }
}

export function getNodeStyleAttributeValue(node: AttributeCollection, screen: ScreenSize, key: string, defaultValue = "") {
    if (node.screenSizeStyleAttributes[screen]) {
        return node.screenSizeStyleAttributes[screen][key] ?? defaultValue;
    }
    else return defaultValue;
}

//returns the closest valid style, if mobile does not exist defaults to tablet and then desktop. Used for things like the flex direction when a mobile style is relying on the set flex dirction
export function getNodeRootStyleAttributeValue(node: AttributeCollection, screen: ScreenSize, key: string, defaultValue = "") {

    for (let i = screen; i >= ScreenSize.Desktop; i--) {
        let val = getNodeStyleAttributeValue(node, i, key, "");

        if (val && val != "")
            return val;
    }

    return defaultValue;
}

export function getNodeComponentAttributeValue(node: AttributeCollection, key: string, defaultValue = "") {
    if (node != undefined) {
        return node.componentAttributes[key] ?? defaultValue;
    }
    else {
        return defaultValue;
    }
}

export function setNodeComponentAttributeValue(node: AttributeCollection, key: string, value: string | null | undefined) {



    if (!value || value == "") {
        try {
            let atts = { ...node.componentAttributes };
            if (atts[key]) {
                delete atts[key];
                node.componentAttributes = atts;
            }
        }
        catch (e) {
            console.warn(e);
        }
        return;
    }


    let newAtts = { ...node.componentAttributes, [key]: value };
    node.componentAttributes = newAtts;
}

export const DefaultNode = new LayoutNode();