import React, { Fragment, useEffect, useState } from "react";
import "./app.scss";
import { Layout } from './components/Layout';
import { Pages } from './components/Pages';
import './custom.css'
import { useDispatch, useSelector } from 'react-redux'
import {
    TokenGenerate,
} from './Slice/Token'
import { BusinessLocation } from "./Slice/BusinessLocation";
import { SetSiteURL } from "./Slice/MySite";
import ScreenStateHandler from "vagaro-website-rendering/src/components/Binding/ScreenStateHandler";
import { Site } from "Utils/Utils";
import store from './app/store';
import { utils } from "vagaro-website-rendering/src/utils/Utils";

const App = () => {
    const dispatch = useDispatch();
    const { generate_time, access_token } = useSelector((state) => state.Token);
    const { data, isCompletedLocationCall } = useSelector((state) => state.BusinessLocation);
    const { VGURL } = useSelector((state) => state.MySite);
    const [isCompleted, setCompleted] = useState(false);

    useEffect(() => {
        GetToken(access_token);
    }, [access_token]);

    const GetToken = async () => {
        if (typeof generate_time == "undefined" ||
            generate_time == null ||
            generate_time == "" || Date.parse(new Date()) > generate_time) {
            await dispatch(TokenGenerate());
        }
        await GetBusinessData(access_token)
    }
    const GetBusinessData = async (accesstoken) => {
        utils.Loaderstart();
        if (window.location.hostname == Site.MySiteName) {
            var myArrayNew = window.location.pathname.split("/").filter(function (el) {
                return el != null && el != "";
            });
            if (myArrayNew.length > 0) {
                const { VGURL } = store.getState().MySite;
                if (typeof accesstoken !== "undefined" && accesstoken !== null && accesstoken !== "") {
                    if (accesstoken == access_token) {
                        const RequestLocation = {};
                        RequestLocation.VGURL = myArrayNew[0].replace("/", "").toLowerCase();
                        RequestLocation.SiteName = "";
                        RequestLocation.access_token = accesstoken;
                        await dispatch(BusinessLocation(RequestLocation));
                    }
                }
                const RequestSiteURL = {};
                RequestSiteURL.VGURL = myArrayNew[0].replace("/", "").toLowerCase();
                RequestSiteURL.SiteURL = "";
                await dispatch(SetSiteURL(RequestSiteURL));
            }
            setCompleted(true);
        }
        else {

            const RequestSiteURL = {};
            RequestSiteURL.VGURL = "";
            RequestSiteURL.SiteURL = window.location.hostname.toLowerCase().replace("www.", "");
            await dispatch(SetSiteURL(RequestSiteURL));

            if ((typeof accesstoken !== "undefined" && accesstoken !== null && accesstoken !== "")) {
                if (accesstoken == access_token) {
                    const RequestLocation = {};
                    RequestLocation.VGURL = "";
                    RequestLocation.SiteName = window.location.hostname.toLowerCase().replace("www.", "");
                    RequestLocation.access_token = accesstoken;
                    await dispatch(BusinessLocation(RequestLocation));
                }
            }            
            setCompleted(true);
        }
    }

    const RedirectHomePage = () => {        
        let VgBUrl = localStorage.getItem('VgBUrl');
        if (typeof VgBUrl != "undefined" && VgBUrl != null && VgBUrl != "") {
            window.location.href = window.location.origin + "/" + VgBUrl + "/"
        }
    }

    return (
        <Fragment>
            {                
                isCompletedLocationCall && isCompleted &&
                (typeof access_token != "undefined" && access_token != null && access_token != "") &&
                ((window.location.hostname != Site.MySiteName && typeof data != "undefined" && data != null && data != "") ||
                    (window.location.hostname == Site.MySiteName)) &&
                <Layout>
                    {VGURL != "" && window.location.hostname == Site.MySiteName && (typeof data == "undefined" || data == null || data == "" || data == "undefined") ?
                        <Fragment>
                            <div className="utility-page-wrap">
                                <div className="utility-page-content">
                                    <h1 className="business-name web-display-large">Oops! The page you were looking for doesn't exist.</h1>
                                    <div className="normal-404-text web-body-font">You may have mistyped the address or the page may have moved.</div>
                                    <div className="space-30"></div>
                                    <a style={{cursor:"pointer"}} onClick={(e)=>RedirectHomePage(e)} className="common-btn-style w-button-primary w-inline-block flamingo-button">Go Back Home</a>
                                </div>
                            </div>
                        </Fragment>
                        :
                        <Pages accesstoken={access_token} accessgenerationtime={generate_time} multilocationdata={data} VGURL={VGURL} />
                    }
                    <ScreenStateHandler />
                </Layout>
            }
        </Fragment>
    );
};

export default App;