import React, { Fragment, useEffect, useState } from 'react';
import { utils } from '../../utils/Utils';
import $ from "jquery";
import MultiLocationEmbaded from "../../VagaroComponent/Controls/MultiLocationEmbaded"

export default function RenderBusinessBookNow({ data, layout, root, allowMultiLoc, userContext, VgUrl }: { data: any, layout: string, root: string, allowMultiLoc: boolean, userContext: any, VgUrl: any }) {
    var IsEditor = (window as any).isEditor !== undefined ? (window as any).isEditor : false;
    const [Multiloction, setMultiloction] = useState(allowMultiLoc && !IsEditor);

    const [isShowBusinessName, setIsShowBusinessName] = useState(false);
    const [businessName, setBusinessName] = useState("");
    const [businessAddress, setBusinessAddress] = useState("");
    const [isBusinessLocation, setIsBusinessLocation] = useState(true);

    const [Url, setUrl] = useState("");
    const [LayoutID, setLayoutID] = useState(1);

    if (!Multiloction) {
        $(".wbloader").css("display", "block");
    }

    useEffect(() => {
        if (data != undefined && data != null && data != "") {
            setUrl(data.vagaroURL);
        }
    }, [data]);

    var glbbackground: any = $("#dvglbstyle").find("#glbbackground");
    glbbackground = glbbackground.css("background-color");


    const MultilocationFunction = function (root: string) {
        setMultiloction(true);
        setIsBusinessLocation(true);
        window.wbc.GotoMultilocation();
    }

    switch (Multiloction) {
        case true:
            let Multilocations: any[] = [];
            let BData = userContext.MultilocationData.filter(
                (col: any) => col.businessID > 0 && col.isServiceAvailable == true
            );
            BData.map((data: any) => {
                Multilocations.push(data);
            });
            if (BData.length > 1) {
                return (
                    <Fragment>
                        <div id="dvmultilocation" style={{ display: isBusinessLocation == true ? "block" : "none" }}>
                            <MultiLocationEmbaded
                                Sitedata={Multilocations}
                                OnClickEvent={(VgUrl: any) => { setIsBusinessLocation(false); setUrl(VgUrl); }}
                                GetBusinessName={(BusinessName: any) => { setIsBusinessLocation(false); setBusinessName(BusinessName); setIsShowBusinessName(true); }}
                                GetBusinessAddress={(BusinessAddress: any) => { setIsBusinessLocation(false); setBusinessAddress(BusinessAddress); setIsShowBusinessName(true); }}
                                Mode={"wbBook"}
                                userContext={userContext}
                                ControlID={"wbmultilocationgroup"}
                                options={Multilocations}
                                searchInputPlaceholder={"Search Business"}
                                layoutID={LayoutID}
                                isShowHeader={true}
                            />
                        </div>
                        {isBusinessLocation == false &&
                            <div id="dvbook" style={{ display: isBusinessLocation == false ? "block" : "none" }}>
                                {(utils.isNotEmptyOrWhiteSpace(data) && utils.isNotEmptyOrWhiteSpace(Url)) &&
                                    <Fragment>
                                        <div id={"dvglbstyle"} style={{ display: "none" }}>
                                            <button id="btnprimarybutton" className="v-button primary invisible-button"></button>
                                            <button id="btnprimarybuttonhover" className="v-button-hover primary"></button>
                                            <button id="btnsecondary" className="v-button secondary"></button>
                                            <button id="btnsecondarybuttonhover" className="v-button-hover secondary"></button>
                                            <div id="txtfontfamily" className="v-text"></div>
                                            <button id="btnrating" className="v-filled-star"></button>
                                            <button id="glbbackground" className="v-site"></button>
                                            <h2 id="glbHeadingFont"></h2>
                                        </div>
                                        <div id="divbooknowiframe" className='vgFrameWrapper' style={{ minHeight: "80vh" }}>
                                            {isShowBusinessName &&
                                                <>
                                                    <div className='multi-location-details-section'>
                                                        <div onClick={() => MultilocationFunction(root)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="22" viewBox="0 0 13 22" fill="none"><path d="M11 21.5C10.5781 21.5 10.2031 21.3594 9.92188 21.0781L0.921875 12.0781C0.3125 11.5156 0.3125 10.5312 0.921875 9.96875L9.92188 0.96875C10.4844 0.359375 11.4688 0.359375 12.0312 0.96875C12.6406 1.53125 12.6406 2.51562 12.0312 3.07812L4.10938 11L12.0312 18.9688C12.6406 19.5312 12.6406 20.5156 12.0312 21.0781C11.75 21.3594 11.375 21.5 11 21.5Z" fill="#333333" /></svg>
                                                        </div>
                                                        <div>
                                                            <div style={{ cursor: "pointer" }} className='multi-hlocation-heading-form' onClick={() => MultilocationFunction(root)}>
                                                                {businessName}</div>
                                                            <div className='multi-business-details'>{businessAddress}</div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            <iframe scrolling="no" id={"booknow" + root} style={{ backgroundColor: glbbackground, minHeight: "80vh" }} onLoad={() => { LoadBookNow(root) }} src={utils.SiteName() + Url + "/book-now?Type=Builder&Event=WBBusinessServices"} width="100%"></iframe>
                                            {IsEditor && <div style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%" }} onClick={(e) => e.preventDefault()}></div>}
                                        </div>
                                    </Fragment>
                                }
                            </div>
                        }
                    </Fragment>
                )
            }
            else {
                utils.isNotEmptyOrWhiteSpace(BData) && BData.length == 1 &&
                    <Fragment>
                        <div id={"dvglbstyle"} style={{ display: "none" }}>
                            <button id="btnprimarybutton" className="v-button primary invisible-button"></button>
                            <button id="btnprimarybuttonhover" className="v-button-hover primary"></button>
                            <button id="btnsecondary" className="v-button secondary"></button>
                            <button id="btnsecondarybuttonhover" className="v-button-hover secondary"></button>
                            <div id="txtfontfamily" className="v-text"></div>
                            <button id="btnrating" className="v-filled-star"></button>
                            <button id="glbbackground" className="v-site"></button>
                            <h2 id="glbHeadingFont"></h2>
                        </div>
                        <div id="divbooknowiframe" className='vgFrameWrapper' style={{ minHeight: "80vh" }}>
                            <iframe scrolling="no" id={"booknow" + root} style={{ backgroundColor: glbbackground, minHeight: "80vh" }} onLoad={() => { LoadBookNow(root) }} src={utils.SiteName() + BData[0].vagaroURL + "/book-now?Type=Builder&Event=WBBusinessServices"} width="100%"></iframe>
                            {IsEditor && <div style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%" }} onClick={(e) => e.preventDefault()}></div>}
                        </div>
                    </Fragment>
            }
        case false:
            return <Fragment>
                {(utils.isNotEmptyOrWhiteSpace(data) && utils.isNotEmptyOrWhiteSpace(Url)) &&
                    <Fragment>
                        <div id={"dvglbstyle"} style={{ display: "none" }}>
                            <button id="btnprimarybutton" className="v-button primary invisible-button"></button>
                            <button id="btnprimarybuttonhover" className="v-button-hover primary"></button>
                            <button id="btnsecondary" className="v-button secondary"></button>
                            <button id="btnsecondarybuttonhover" className="v-button-hover secondary"></button>
                            <div id="txtfontfamily" className="v-text"></div>
                            <button id="btnrating" className="v-filled-star"></button>
                            <button id="glbbackground" className="v-site"></button>
                            <h2 id="glbHeadingFont"></h2>
                        </div>
                        <div id="divbooknowiframe" className='vgFrameWrapper' style={{ minHeight: "80vh" }}>
                            {isShowBusinessName &&
                                <>
                                    <div className='multi-location-details-section'>
                                        <div onClick={() => MultilocationFunction(root)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="22" viewBox="0 0 13 22" fill="none"><path d="M11 21.5C10.5781 21.5 10.2031 21.3594 9.92188 21.0781L0.921875 12.0781C0.3125 11.5156 0.3125 10.5312 0.921875 9.96875L9.92188 0.96875C10.4844 0.359375 11.4688 0.359375 12.0312 0.96875C12.6406 1.53125 12.6406 2.51562 12.0312 3.07812L4.10938 11L12.0312 18.9688C12.6406 19.5312 12.6406 20.5156 12.0312 21.0781C11.75 21.3594 11.375 21.5 11 21.5Z" fill="#333333" /></svg>
                                        </div>
                                        <div>
                                            <div style={{ cursor: "pointer" }} className='multi-hlocation-heading-form' onClick={() => MultilocationFunction(root)}>
                                                {businessName}</div>
                                            <div className='multi-business-details'>{businessAddress}</div>
                                        </div>
                                    </div>
                                </>
                            }
                            <iframe scrolling="no" id={"booknow" + root} style={{ backgroundColor: glbbackground, minHeight: "80vh" }} onLoad={() => { LoadBookNow(root) }} src={utils.SiteName() + Url + "/book-now?Type=Builder&Event=WBBusinessServices"} width="100%"></iframe>
                            {IsEditor && <div style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%" }} onClick={(e) => e.preventDefault()}></div>}
                        </div>
                    </Fragment>
                }
            </Fragment>
    }

}

const sendPositionbook = function (glbIframeParentID: any, src: any) {
    if (glbIframeParentID != '' && typeof glbIframeParentID != 'undefined') {
        var parentid = $('#' + glbIframeParentID) as any;
        var currentScrollPositionofParent = (document.scrollingElement as any).scrollTop;
        var iframestartposition = parentid.find('iframe').offset().top;
        var modalTop = currentScrollPositionofParent - iframestartposition
        var modalHeight = window.screen.availHeight;
        if (modalTop < 0) {
            modalTop = 0;
        }

        parentid.find('iframe')[0].contentWindow.postMessage('{"eventType": "modalPosition", "modalTop" : ' + modalTop + ',"modalHeight" : "' + modalHeight + '"}', getSource(src));
    }
}
const LoadBookNow = function (root: string) {


    const getId = 'booknow' + root;

    if (document.getElementById(getId) !== undefined || document.getElementById('booknow') !== null) {
        var iframeElement = document.getElementById(getId) as any;
        if (iframeElement) {
            var styleobject: any = {};
            var gblstyle: any = $("#dvglbstyle");
            var primarybutton: any = gblstyle.find("#btnprimarybutton");
            var secondorybutton: any = gblstyle.find("#btnsecondary");
            var primarybuttonHover: any = gblstyle.find("#btnprimarybuttonhover");
            var secondorybuttonHover = gblstyle.find("#btnsecondarybuttonhover");
            var lblstyle: any = gblstyle.find("#txtfontfamily");
            var btnrating: any = gblstyle.find("#btnrating");
            var glbbackground: any = gblstyle.find("#glbbackground");
            var glbHeadFonts: any = gblstyle.find("#glbHeadingFont");
            styleobject.primarybuttoncolor = primarybutton.css("color");
            styleobject.primarybuttonbackgroundcolor = primarybutton.css("background-color");
            styleobject.primarybuttonHoverbackgroundcolor = primarybuttonHover.css("background-color");
            styleobject.primarybuttonborderradius = primarybutton.css("border-radius");
            styleobject.primarybuttonboxshadow = primarybutton.css("box-shadow");
            styleobject.secondorybuttonbuttoncolor = secondorybutton.css("color");
            styleobject.secondorybuttonbuttonbackgroundcolor = secondorybutton.css("background-color");
            styleobject.secondorybuttonbuttonHoverbackgroundcolor = secondorybuttonHover.css("background-color");
            styleobject.secondorybuttonbuttonborderradius = secondorybutton.css("border-radius");
            styleobject.secondorybuttonbuttonboxshadow = secondorybutton.css("box-shadow");
            styleobject.lblstylefontfamily = 'proxima-nova'
            styleobject.lblstylecolor = lblstyle.css("color");
            styleobject.lblstylefontweight = lblstyle.css("font-weight");
            styleobject.lblstylefontsize = lblstyle.css("font-size");
            styleobject.btnratingcolor = btnrating.css("color");
            styleobject.glbbackgroundcolor = glbbackground.css("background-color");
            styleobject.glbHeadingFonts = 'proxima-nova'
            var cookieobject: any = {};
            cookieobject.vdsid = localStorage.getItem("vdsid");
            var styledata: any = { 'eventType': 'addStyle', style: styleobject, 'parentID': 'divbooknowiframe', 'cookieobject': cookieobject }
            iframeElement.contentWindow.postMessage(JSON.stringify(styledata), getSource(iframeElement.src));
            sendPositionbook('divbooknowiframe', iframeElement.src);
        }
    }
}

function getSource(src: any) {
    var _target = null;
    if (_target == null) {
        var targetRegex = /^(https*:\/\/[^\/]*).*$/g;
        var match = targetRegex.exec(src);
        if (match) {
            _target = match[1];
        }
    }
    return _target;
}


