import React, { useEffect, Fragment, useState, useRef, useCallback } from 'react';
import "../../utils/select2_4.0.js";
import "../../VagaroComponent/Controls/multilocation.scss";
import $ from 'jquery';
import { utils } from '../../utils/Utils.js'
import ReactDom, { unmountComponentAtNode } from "react-dom";
import VGPopUp from "../../components/Controls/VGPopUp";

export default function RenderBusinessLocation({ siteData, data, layout, layoutID, pages }) {

    const GOOGLE_MAP_API_KEY = 'AIzaSyDaa0FdAfpQzAF1c7Twg7QyIZZ_iFHthto';

    const [selectedLocation, setselectedLocation] = useState("");

    const isEditor = window.isEditor;

    useEffect(() => {
        const isStencil = data?.isStenciled === true;
        if (isStencil == false) {

            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(async function (position) {
                    var latitude = position.coords.latitude;
                    var longitude = position.coords.longitude;
                    getDistance(data, false, latitude, longitude);
                }, async function (error) {
                    getDistance(data, false, 0, 0);
                });
            }
            else {
                getDistance(data, false, 0, 0);
            }


        }
    }, [data]);



    const [mode, setMode] = useState(layoutID);
    const [expanded, setExpanded] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [loadMap, setLoadMap] = useState(false);
    const [reloadMap, setReLoadMap] = useState(false);

    const latituderef = useRef(0);
    const longituderef = useRef(0);
    const IsBusinessLocation = useRef(true);

    const [businessList, setBusinessList] = useState(data);
    const [filterbusinessList, setFilterBusinessList] = useState(data);
    const [inProgress, setInProgress] = useState(true);

    const [hideDivId, setHideDivId] = useState([]);
    const [isShowDiv, setIsShowDiv] = useState(false);

    const loadGoogleMapScript = (callback) => {
        if (typeof window.google === 'object' && typeof window.google.maps === 'object') {
            setReLoadMap(false);
            callback();
        } else {
            const googleMapScript = document.createElement("script");
            googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}`;
            window.document.body.appendChild(googleMapScript);
            setReLoadMap(true);
            googleMapScript.addEventListener("load", callback);

        }
    }


    const getDistance = (dataLocation, isExpand, latitude, longitude) => {
        if (dataLocation != null && dataLocation.length > 0) {
            if (isExpand) {
                setExpanded(!expanded);
            }
            if (latitude != 0 && longitude != 0) {
                latituderef.current = latitude;
                longituderef.current = longitude;
                IsBusinessLocation.current = false;
            }
            else {
                latituderef.current = dataLocation[0].latitude;
                longituderef.current = dataLocation[0].longitude;
                IsBusinessLocation.current = true;
            }
            var objbusinessdistance = dataLocation;
            objbusinessdistance.map((data, index) => {
                data.all = data.businessName + " " + data.street + " " + data.city + " " + data.stateCode + " " + data.stateName + " " + data.zip + " " + data.distance + " " + data.alias + " " + data.nickName
                data.cities = utils.isNotEmptyOrWhiteSpace(data.cities) ? JSON.parse(data.cities) : [];
                data.count = utils.isNotEmptyOrWhiteSpace(data.cities) ? data.cities.length : 0
                data.index = (index + 1)
            });
            setBusinessList(objbusinessdistance);
            setFilterBusinessList(objbusinessdistance);
            if (mode == 2) {
                loadGoogleMapScript(() => {
                    setLoadMap(true);
                });
            }
            setInProgress(false);
        }
    }


    const handleURL = (selectedOption) => {
        setselectedLocation(selectedOption.encBID);
        EventChange(selectedOption.vagaroURL, selectedOption.encBID, selectedOption.bidHash, selectedOption.alias);
    }

    const handleMapURL = (encBID, vagaroURL, bidHash, alias) => {
        setselectedLocation(encBID);
        EventChange(vagaroURL, encBID, bidHash, alias);
    }

    const EventChange = (vagaroURL, encBID, bidHash, alias) => {
        if (window.location.origin == utils.MySiteName()) {
            var jsonData = JSON.stringify({ vagaroURL: vagaroURL, bid: encBID, bidHash: bidHash });
            localStorage.setItem('location', jsonData);
            window.open(window.location.origin + "/" + siteData.vgurl + "/" + alias + "/" + pages, "_self");
        }
        else {
            var jsonData = JSON.stringify({ vagaroURL: vagaroURL, bid: encBID, bidHash: bidHash });
            localStorage.setItem('location', jsonData);
            window.open(window.location.origin + "/" + alias + "/" + pages, "_self");
        }
    }

    const GoToChartView = async () => {
        setMode(2);
        loadGoogleMapScript(() => {
            setLoadMap(true);
        });
    }

    const GoToTableView = async () => {
        setMode(1);
    }

    const searchlInput = useCallback((inputElement) => {
        if (inputElement && !isEditor) {
            inputElement.focus();
        }
    }, [mode]);

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
        setExpanded(false);
        if (utils.isNotEmptyOrWhiteSpace(event.target.value)) {
            var splitval = event.target.value.split(' ');
            if (splitval.length > 0) {

                let filterbdata = [];
                businessList.map((bdata, bindex) => {
                    var flag = true;
                    splitval.map((data, index) => {
                        if (flag) {
                            let resultbdata = bdata.all.toString().toLowerCase().indexOf(data.toLowerCase()) !== -1;
                            if (resultbdata) {
                                flag = true;
                            }
                            else {
                                flag = false;
                            }
                            if (flag && (index + 1 == splitval.length)) {
                                filterbdata.push(bdata);
                            }

                        }
                    });
                    if (bindex + 1 == businessList.length) {
                        setFilterBusinessList(filterbdata);
                        MapRefresh(filterbdata);
                    }
                })
            }

        }
        else {
            setFilterBusinessList(businessList);
            MapRefresh(businessList);
        }

    };

    const clearInputValue = () => {
        setInputValue("");
        setFilterBusinessList(businessList);
        MapRefresh(businessList);
    }

    const [allmarkers, setAllMarkers] = useState([]);
    const MapRefresh = (result) => {
        const refershMarker = allmarkers;
        for (var i = 0; i < refershMarker.length; i++) {
            refershMarker[i].setVisible(false);
            let merchant = result.filter(
                (col) => col.encBID === refershMarker[i].merchantid
            );
            if (merchant.length > 0) {
                refershMarker[i].setVisible(true);
            }
        }
    }

    const style = {
        maxHeight: "1em",
        overflow: "hidden",
        opacity: 1
    }

    const mapClickCallback = (encBID, rank) => {
        setselectedLocation(encBID);

        var $container = $('#divlocation'),
            $scrollTo = $('#div-' + rank);

        const isMobile = window.matchMedia("(max-width: 767px)");

        if (isMobile.matches) {
            $container.scrollLeft(
                $scrollTo.offset().left - $container.offset().left + $container.scrollLeft()
            );

            // Or you can animate the scrolling:
            $container.animate({
                scrollLeft: $scrollTo.offset().left - $container.offset().left + $container.scrollLeft()
            });
        }
        else {
            $container.scrollTop(
                $scrollTo.offset().top - $container.offset().top + $container.scrollTop()
            );

            // Or you can animate the scrolling:
            $container.animate({
                scrollTop: $scrollTo.offset().top - $container.offset().top + $container.scrollTop()
            });
        }
    }

    const googleMapRef = useRef(window.mydatamapref);
    useEffect(() => {
        setReLoadMap(!reloadMap);
    }, [mode]);

    useEffect(() => {
        if (loadMap) {
            var googleMap = initGoogleMap();
            window.googleMap = googleMap;
            var bounds = new window.google.maps.LatLngBounds();
            const markerList = []
            filterbusinessList.map((data) => {
                markerList.push({ rank: data.index, lat: data.latitude, lng: data.longitude, title: data.businessName, encBID: data.encBID, bidHash: data.bidHash, count: data.count, MRadius: data.mobileServiceRadius, URL: data.cdn, VagaroURL: data.vagaroURL, alias: data.alias })
            });
            var bounds = new window.google.maps.LatLngBounds();
            var infowindow = new window.google.maps.InfoWindow();

            if (IsBusinessLocation.current == false) {
                var LatLng = new window.google.maps.LatLng(latituderef.current, longituderef.current);
                new window.google.maps.Marker({
                    position: LatLng,
                    map: googleMap,
                    icon: {
                        path: window.google.maps.SymbolPath.CIRCLE,
                        fillColor: '#2063C6',
                        fillOpacity: 5,
                        strokeColor: '#FFFFFF',
                        strokeWeight: 5,
                        scale: 10
                    },
                });
            }

            var icon = {
                path: "M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0z", //SVG path of awesomefont marker
                fillColor: '#9b1003', //color of the marker
                fillOpacity: 1,
                strokeWeight: 0,
                scale: 0.09, //size of the marker, careful! this scale also affects anchor and labelOrigin
                anchor: new window.google.maps.Point(200, 510), //position of the icon, careful! this is affected by scale
                labelOrigin: new window.google.maps.Point(205, 190) //position of the label, careful! this is affected by scale
            }

            var iconBig = {
                path: "M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0z", //SVG path of awesomefont marker
                fillColor: '#9b1003', //color of the marker
                fillOpacity: 1,
                strokeWeight: 0,
                scale: 0.11, //size of the marker, careful! this scale also affects anchor and labelOrigin
                anchor: new window.google.maps.Point(200, 510), //position of the icon, careful! this is affected by scale
                labelOrigin: new window.google.maps.Point(205, 190) //position of the label, careful! this is affected by scale
            }

            markerList.forEach(function (point) {
                var pos = new window.google.maps.LatLng(point.lat, point.lng);
                bounds.extend(pos);
                var marker = new window.google.maps.Marker({
                    position: pos,
                    map: googleMap,
                    icon: icon,
                    label: {
                        text: point.rank.toString(), //text inside marker
                        color: '#FFFFFF', //color of the text inside marker
                    },
                    title: point.title,
                    merchantid: point.encBID,
                    bidHash: point.bidHash,
                    URL: point.URL,
                    VagaroURL: point.VagaroURL,
                    lat: point.lat,
                    lng: point.lng,
                    rank: point.rank,
                    alias: point.alias
                });
                allmarkers.push(marker);
                window.google.maps.event.addListener(marker, 'click', function () {
                    infowindow.setContent("<div class='infoBox' style='width: 250px; opacity: inherit; transform: translateZ(0px) position: absolute; visibility: visible; left: -125.104px; top: -217.746px; cursor: default;'> "
                        + " <div class='map-tooltip' id='mapCont' click='" + (() => handleMapURL(marker.merchantid, marker.VagaroURL, marker.bidHash, marker.alias)) + "'>"
                        + "     <div class='map-salon-img'>"
                        + "         <a click='" + (() => handleMapURL(marker.merchantid, marker.VagaroURL, marker.bidHash, marker.alias)) + "' id='aTemplateBusinessImage' class='map-imgcenter imgCover lazy' data-original='" + marker.URL + "' title='" + marker.title + "' style='background-image: url(" + marker.URL + ");'></a>"
                        + "     </div>"
                        + "     <div class='map-addres-col w-clearfix'>"
                        + "         <a click='" + (() => handleMapURL(marker.merchantid, marker.VagaroURL, marker.bidHash, marker.alias)) + "' id='aTemplateUrl'>"
                        + "             <div class='caption-map web-subheading-font' title='" + marker.title + "'>" + marker.title + "</div>"
                        + "         </a>"
                        + "     </div>"
                        + " </div>"
                        + "</div>");

                    infowindow.open(marker.map, marker);
                    setTimeout(() => {
                        var x = document.getElementsByClassName('gm-ui-hover-effect')[0].remove();
                    }, 10);
                    mapClickCallback(marker.merchantid, point.rank);
                });

                window.google.maps.event.addListener(
                    googleMap,
                    "click",
                    (function (event) {
                        return function () {
                            infowindow.close();
                            setselectedLocation("");
                        };
                    })(googleMap)
                );

                window.google.maps.event.addListener(
                    googleMap,
                    "dragend",
                    (function (event) {
                        return function () {
                            var bounds = this.getBounds();
                            var ne = bounds.getNorthEast();
                            var sw = bounds.getSouthWest();
                            var bounds = new window.google.maps.LatLngBounds(sw, ne);
                            let hideDivIds = [];
                            setIsShowDiv(false);
                            for (var i = 0; i < allmarkers.length; i++) {
                                $("#div-" + allmarkers[i].rank).hide();
                                if (bounds.contains(new window.google.maps.LatLng(allmarkers[i].lat, allmarkers[i].lng))) {
                                    $("#div-" + allmarkers[i].rank).show();
                                    setIsShowDiv(true);
                                }
                                else {
                                    hideDivIds.push(allmarkers[i].rank);
                                }
                            }
                            setHideDivId(hideDivIds);

                        };
                    })(googleMap)
                );

                window.google.maps.event.addListener(
                    googleMap,
                    "zoom_changed",
                    (function (event) {
                        return function () {
                            var bounds = this.getBounds();
                            var ne = bounds.getNorthEast();
                            var sw = bounds.getSouthWest();
                            var bounds = new window.google.maps.LatLngBounds(sw, ne);
                            let hideDivIds = [];
                            setIsShowDiv(false);
                            for (var i = 0; i < allmarkers.length; i++) {
                                $("#div-" + allmarkers[i].rank).hide();
                                if (bounds.contains(new window.google.maps.LatLng(allmarkers[i].lat, allmarkers[i].lng))) {
                                    $("#div-" + allmarkers[i].rank).show();
                                    setIsShowDiv(true);
                                }
                                else {
                                    hideDivIds.push(allmarkers[i].rank);
                                }
                            }
                            setHideDivId(hideDivIds);

                        };
                    })(googleMap)
                );

                window.google.maps.event.addListener(
                    marker,
                    "mouseover",
                    (function (marker) {
                        return function () {
                            marker.setIcon(iconBig);
                        };
                    })(marker)
                );

                window.google.maps.event.addListener(
                    marker,
                    "mouseout",
                    (function (marker, i) {
                        return function () {
                            marker.setIcon(icon);
                        };
                    })(marker)
                );
                if (point.count > 0) {
                    var circle = {
                        strokeColor: "#ff0000",
                        strokeOpacity: 0.8,
                        strokeWeight: 1,
                        fillColor: "#ff0000",
                        fillOpacity: 0.20,
                        map: googleMap,
                        center: pos,
                        radius: (point.MRadius * 1.60934) * 1000
                    };
                    var drawCirle =
                        new window.google.maps.Circle(circle);
                }
            });
            setAllMarkers(allmarkers);
            googleMap.fitBounds(bounds); // the map to contain all markers
        }
    }, [loadMap]);

    // initialize the google map
    const initGoogleMap = () => {
        window.mydata = new window.google.maps.Map(googleMapRef.current, {
            center: { lat: latituderef.current, lng: longituderef.current },
            zoom: 8,
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            mapTypeControl: false,
            streetViewControl: false
        });
        window.mydatamapref = googleMapRef;
        return window.mydata;
    }


    return (
        <Fragment>
            <div className='select-location-title'><h3>Select location</h3></div>
            <div className='search-location-header'>
                <div className={mode == 1 ? 'select-location-input' : "select-location-input select-location-input-icon"}>
                    <div className='select-location-input-icon'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                            <path d="M15.7656 14.6738C16.0586 14.9668 16.0586 15.4062 15.7656 15.6699C15.6484 15.8164 15.4727 15.875 15.2969 15.875C15.0918 15.875 14.916 15.8164 14.7695 15.6699L10.8438 11.7441C9.78906 12.5938 8.4707 13.0625 7.06445 13.0625C3.72461 13.0625 1 10.3379 1 6.96875C1 3.62891 3.69531 0.875 7.06445 0.875C10.4043 0.875 13.1582 3.62891 13.1582 6.96875C13.1582 8.4043 12.6895 9.72266 11.8398 10.748L15.7656 14.6738ZM2.40625 6.96875C2.40625 9.57617 4.48633 11.6562 7.09375 11.6562C9.67188 11.6562 11.7812 9.57617 11.7812 6.96875C11.7812 4.39062 9.67188 2.28125 7.09375 2.28125C4.48633 2.28125 2.40625 4.39062 2.40625 6.96875Z" fill="#A2A0A2" />
                        </svg>
                    </div>
                    <input ref={searchlInput} type='text' defaultValue={inputValue} value={inputValue} placeholder='Business Name or Location' onChange={handleInputChange}></input>
                    {inputValue.length > 0 &&
                        <div className='search-remove-icon' onClick={() => clearInputValue()}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                            </svg>
                        </div>
                    }
                </div>
                {mode == 1 &&
                    <div className='map-list-icon' onClick={() => GoToChartView()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="22" viewBox="0 0 27 22" fill="none">
                            <path d="M26.25 0.5C26.625 0.5 27 0.828125 27 1.29688V17.5156C27 18.0781 26.5312 18.6875 26.0156 18.9219L18.9375 21.3594C18.6562 21.4531 18.2344 21.5 18 21.5C17.7188 21.5 17.2969 21.4531 17.0625 21.3594L9 18.5L0.984375 21.4531C0.890625 21.5 0.796875 21.5 0.703125 21.5C0.328125 21.5 0 21.2188 0 20.75V4.53125C0 3.96875 0.421875 3.35938 0.9375 3.125L8.01562 0.6875C8.34375 0.59375 8.67188 0.5 8.95312 0.5C9.23438 0.546875 9.65625 0.59375 9.9375 0.6875L18 3.5L25.9688 0.59375C26.0625 0.546875 26.1562 0.5 26.25 0.5ZM10.5 3.26562V16.6719L16.5 18.7812V5.375L10.5 3.26562ZM2.25 18.6406L8.20312 16.3906H8.25V2.98438L2.25 5.09375V18.6406ZM24.75 16.9531V3.40625L18.75 5.65625V19.0625L24.75 16.9531Z" fill="#333333" />
                        </svg>
                    </div>
                }
                {mode == 2 &&
                    <div className='map-list-icon' onClick={() => GoToTableView()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 20" fill="none">
                            <path d="M3.625 0.25C4.23438 0.25 4.75 0.765625 4.75 1.375V3.625C4.75 4.28125 4.23438 4.75 3.625 4.75H1.375C0.71875 4.75 0.25 4.28125 0.25 3.625V1.375C0.25 0.765625 0.71875 0.25 1.375 0.25H3.625ZM22.375 1.375C22.9844 1.375 23.5 1.89062 23.5 2.5C23.5 3.15625 22.9844 3.625 22.375 3.625H8.125C7.46875 3.625 7 3.15625 7 2.5C7 1.89062 7.46875 1.375 8.125 1.375H22.375ZM22.375 8.875C22.9844 8.875 23.5 9.39062 23.5 10C23.5 10.6562 22.9844 11.125 22.375 11.125H8.125C7.46875 11.125 7 10.6562 7 10C7 9.39062 7.46875 8.875 8.125 8.875H22.375ZM22.375 16.375C22.9844 16.375 23.5 16.8906 23.5 17.5C23.5 18.1562 22.9844 18.625 22.375 18.625H8.125C7.46875 18.625 7 18.1562 7 17.5C7 16.8906 7.46875 16.375 8.125 16.375H22.375ZM0.25 8.875C0.25 8.26562 0.71875 7.75 1.375 7.75H3.625C4.23438 7.75 4.75 8.26562 4.75 8.875V11.125C4.75 11.7812 4.23438 12.25 3.625 12.25H1.375C0.71875 12.25 0.25 11.7812 0.25 11.125V8.875ZM3.625 15.25C4.23438 15.25 4.75 15.7656 4.75 16.375V18.625C4.75 19.2812 4.23438 19.75 3.625 19.75H1.375C0.71875 19.75 0.25 19.2812 0.25 18.625V16.375C0.25 15.7656 0.71875 15.25 1.375 15.25H3.625Z" fill="#333333" />
                        </svg>
                    </div>

                }
            </div>
            <div className='mbl-view-only' style={{ display: mode == 2 ? "block" : "none" }}>
                <div className='content-with-map'>
                    <div id="divlocation" class="select-location-list map-view" onClick={() => !isEditor && GoToChartView()}>
                        {inProgress ? <Fragment>
                            <div className="stenciled  flex-column gap-small w-full max-100" style={{ width: "49%" }}>
                                <div className='w-full stencil-item' style={style}>&nbsp;</div>
                                <div className='w-full stencil-item' style={{ ...style, maxWidth: "100%" }}>&nbsp;</div>
                                <div className='w-full stencil-item' style={{ ...style, maxWidth: "100%" }}>&nbsp;</div>
                            </div>
                            <div className="stenciled  flex-column gap-small w-full max-100" style={{ width: "49%" }}>
                                <div className='w-full stencil-item' style={style}>&nbsp;</div>
                                <div className='w-full stencil-item' style={{ ...style, maxWidth: "100%" }}>&nbsp;</div>
                                <div className='w-full stencil-item' style={{ ...style, maxWidth: "100%" }}>&nbsp;</div>
                            </div>
                            <div className="stenciled  flex-column gap-small w-full max-100" style={{ width: "49%" }}>
                                <div className='w-full stencil-item' style={style}>&nbsp;</div>
                                <div className='w-full stencil-item' style={{ ...style, maxWidth: "100%" }}>&nbsp;</div>
                                <div className='w-full stencil-item' style={{ ...style, maxWidth: "100%" }}>&nbsp;</div>
                            </div>
                            <div className="stenciled  flex-column gap-small w-full max-100" style={{ width: "49%" }}>
                                <div className='w-full stencil-item' style={style}>&nbsp;</div>
                                <div className='w-full stencil-item' style={{ ...style, maxWidth: "100%" }}>&nbsp;</div>
                                <div className='w-full stencil-item' style={{ ...style, maxWidth: "100%" }}>&nbsp;</div>
                            </div>
                        </Fragment> :
                            <Multilocationcontainer layout={layout} hideDivId={hideDivId} isShowDiv={isShowDiv} businessList={filterbusinessList} mode={mode} handleURL={handleURL} selectedLocation={selectedLocation} />
                        }
                    </div>
                    <div className='current-map-section'>
                        {!loadMap ? <div>Loading...</div> : <Fragment>
                            <div
                                ref={googleMapRef}
                                style={{ width: "100%", height: 650 }}
                            />
                        </Fragment>}
                        <div className='mobile-view-btn'>
                            <span className='list-view-btn' onClick={() => !isEditor && GoToTableView()}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14 12" fill="none">
                                    <path d="M0.4375 0.953125C0.4375 0.572266 0.730469 0.25 1.14062 0.25H12.8594C13.2402 0.25 13.5625 0.572266 13.5625 0.953125C13.5625 1.36328 13.2402 1.65625 12.8594 1.65625H1.14062C0.730469 1.65625 0.4375 1.36328 0.4375 0.953125ZM0.4375 5.64062C0.4375 5.25977 0.730469 4.9375 1.14062 4.9375H12.8594C13.2402 4.9375 13.5625 5.25977 13.5625 5.64062C13.5625 6.05078 13.2402 6.34375 12.8594 6.34375H1.14062C0.730469 6.34375 0.4375 6.05078 0.4375 5.64062ZM12.8594 11.0312H1.14062C0.730469 11.0312 0.4375 10.7383 0.4375 10.3281C0.4375 9.94727 0.730469 9.625 1.14062 9.625H12.8594C13.2402 9.625 13.5625 9.94727 13.5625 10.3281C13.5625 10.7383 13.2402 11.0312 12.8594 11.0312Z" fill="#333333" />
                                </svg> List
                            </span>

                        </div>
                    </div>
                </div>

            </div>
            <div class="view-location-list" style={{ display: mode == 1 ? "block" : "none" }}>
                <div class="select-location-list" onClick={() => GoToTableView()}>
                    {inProgress ? <Fragment>
                        <div className="stenciled  flex-column gap-small w-full max-100" style={{ width: "49%" }}>
                            <div className='w-full stencil-item' style={style}>&nbsp;</div>
                            <div className='w-full stencil-item' style={{ ...style, maxWidth: "100%" }}>&nbsp;</div>
                            <div className='w-full stencil-item' style={{ ...style, maxWidth: "100%" }}>&nbsp;</div>
                        </div>
                        <div className="stenciled  flex-column gap-small w-full max-100" style={{ width: "49%" }}>
                            <div className='w-full stencil-item' style={style}>&nbsp;</div>
                            <div className='w-full stencil-item' style={{ ...style, maxWidth: "100%" }}>&nbsp;</div>
                            <div className='w-full stencil-item' style={{ ...style, maxWidth: "100%" }}>&nbsp;</div>
                        </div>
                        <div className="stenciled  flex-column gap-small w-full max-100" style={{ width: "49%" }}>
                            <div className='w-full stencil-item' style={style}>&nbsp;</div>
                            <div className='w-full stencil-item' style={{ ...style, maxWidth: "100%" }}>&nbsp;</div>
                            <div className='w-full stencil-item' style={{ ...style, maxWidth: "100%" }}>&nbsp;</div>
                        </div>
                        <div className="stenciled  flex-column gap-small w-full max-100" style={{ width: "49%" }}>
                            <div className='w-full stencil-item' style={style}>&nbsp;</div>
                            <div className='w-full stencil-item' style={{ ...style, maxWidth: "100%" }}>&nbsp;</div>
                            <div className='w-full stencil-item' style={{ ...style, maxWidth: "100%" }}>&nbsp;</div>
                        </div>
                    </Fragment> :
                        <Multilocationcontainer layout={layout} businessList={filterbusinessList} mode={mode} handleURL={handleURL} selectedLocation={selectedLocation} />
                    }
                </div>
                <div className='mobile-view-btn'>
                    <span className='map-view-btn' onClick={() => GoToChartView()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none">
                            <path d="M16.9688 0.8125C17.2031 0.8125 17.4375 1.01758 17.4375 1.31055V11.4473C17.4375 11.7988 17.1445 12.1797 16.8223 12.3262L12.3984 13.8496C12.2227 13.9082 11.959 13.9375 11.8125 13.9375C11.6367 13.9375 11.373 13.9082 11.2266 13.8496L6.1875 12.0625L1.17773 13.9082C1.11914 13.9375 1.06055 13.9375 1.00195 13.9375C0.767578 13.9375 0.5625 13.7617 0.5625 13.4688V3.33203C0.5625 2.98047 0.826172 2.59961 1.14844 2.45312L5.57227 0.929688C5.77734 0.871094 5.98242 0.8125 6.1582 0.8125C6.33398 0.841797 6.59766 0.871094 6.77344 0.929688L11.8125 2.6875L16.793 0.871094C16.8516 0.841797 16.9102 0.8125 16.9688 0.8125ZM7.125 2.54102V10.9199L10.875 12.2383V3.85938L7.125 2.54102ZM1.96875 12.1504L5.68945 10.7441H5.71875V2.36523L1.96875 3.68359V12.1504ZM16.0312 11.0957V2.62891L12.2812 4.03516V12.4141L16.0312 11.0957Z" fill="#333333" />
                        </svg> Map
                    </span>
                </div>
            </div>
            {isEditor && <div style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%" }} onClick={(e) => e.preventDefault()}></div>}
        </Fragment>
    );
}




const HouseCallLocationIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="currentColor"><path fill-rule="evenodd" clip-rule="evenodd" d="M2 14.803v6.447c0 .414.336.75.75.75h1.614a.75.75 0 0 0 .74-.627L5.5 19h13l.395 2.373a.75.75 0 0 0 .74.627h1.615a.75.75 0 0 0 .75-.75v-6.447a5.954 5.954 0 0 0-1-3.303l-.78-1.17a1.994 1.994 0 0 1-.178-.33h.994a.75.75 0 0 0 .671-.415l.25-.5A.75.75 0 0 0 21.287 8H19.6l-.31-1.546a2.5 2.5 0 0 0-1.885-1.944C15.943 4.17 14.141 4 12 4c-2.142 0-3.943.17-5.405.51a2.5 2.5 0 0 0-1.886 1.944L4.399 8H2.714a.75.75 0 0 0-.67 1.085l.25.5a.75.75 0 0 0 .67.415h.995a1.999 1.999 0 0 1-.178.33L3 11.5c-.652.978-1 2.127-1 3.303zm15.961-4.799a4 4 0 0 0 .34.997H5.699c.157-.315.271-.65.34-.997l.632-3.157a.5.5 0 0 1 .377-.39C8.346 6.157 10 6 12 6c2 0 3.654.156 4.952.458a.5.5 0 0 1 .378.389l.631 3.157zM5.5 16a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM20 14.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" /></svg>
}

const PopupClose = () => {
    unmountComponentAtNode(document.getElementById("OpenDialog"));
};

const ShowCityModal = (CityList, CityCount, BusinessName) => {
    return ReactDom.render(
        <VGPopUp
            id={"ShowCityModal"}
            show={true}
            onHide={PopupClose}
            title={BusinessName + "'s Service Cities"}
            dialogClassName="block-edit"
            body={
                <div className="alertContent">
                    {CityList.map((data) => {
                        return <Fragment><span>{data.City}</span></Fragment>
                    })}
                </div>
            }
            footer={
                <Fragment>
                    <button className="v-button primary row-gap-med invisible-button realative-posi" onClick={() => PopupClose()}>Ok</button>
                </Fragment >
            }
            isRemoveTabModalIndex={false}
            isShowFooter={true}
            size={"md"}
        />,
        document.getElementById("OpenDialog"));
}

const Multilocationcontainer = (props) => {
    var businessList = props.businessList;

    var bidHash = "";
    const items = JSON.parse(localStorage.getItem('location'));
    if (items) {
        bidHash = items.bidHash;
    }

    const opentab = () => {
        $(".Multilocation-genral").each(function () {
            $(this).addClass("active").addClass("active-label");
        });
    }
    useEffect(() => {
        opentab();
    }, [])

    return (
        <Fragment>
            {businessList.length > 0 && businessList.map((data) => {
                let divhide = [];
                if (props.mode == 2) {
                    if (utils.Validate(props.hideDivId)) {
                        divhide = props.hideDivId.filter(
                            (col) => col == data.index
                        );
                    }
                }
                return (
                    <Fragment>
                        {props.mode == 1 && props.layout == "1" &&

                            <div className={(props.selectedLocation === data.encBID || bidHash == data.bidHash) ? "location-box active-cardbox" : "location-box"} >
                                <div className="location-header" onClick={() => { props.handleURL(data); }} >
                                    <h6>{data.businessName + (utils.Validate(data.nickName) ? " (" + data.nickName + ")" : "")}</h6>
                                    <h6>{data.distance} {data.countryID == 1 ? <Fragment>mi</Fragment> : <Fragment>km</Fragment>}</h6>
                                </div>
                                <p className="" onClick={() => { props.handleURL(data); }}>{data.street}, {data.city}, {data.stateName}, {data.zip}</p>
                                {data.count > 0 &&
                                    <a href="javascript:void(0)" onClick={() => ShowCityModal(data.cities, data.count, data.businessName)} className="stencil-item map-label" style={{ display: "flex", alignItems: "center", cursor: "default", marginTop: "8px" }}><span><HouseCallLocationIcon IsBlack={true} /></span>&nbsp;Offers Mobile Services in {data.count} Cities</a>
                                }
                            </div>
                        }
                        {props.mode == 1 && props.layout == "2" &&
                            <div className={(props.selectedLocation === data.encBID || bidHash == data.bidHash) ? "location-box active-cardbox" : "location-box"} >
                                <div className="location-header" onClick={() => { props.handleURL(data); }} >
                                    <h6>{data.street}, {data.city}, {data.stateName}, {data.zip}</h6>
                                    <h6>{data.distance} {data.countryID == 1 ? <Fragment>mi</Fragment> : <Fragment>km</Fragment>}</h6>
                                </div>
                                {data.count > 0 &&
                                    <a href="javascript:void(0)" onClick={() => ShowCityModal(data.cities, data.count, data.businessName)} className="stencil-item map-label" style={{ display: "flex", alignItems: "center", cursor: "default", marginTop: "8px" }}><span><HouseCallLocationIcon IsBlack={true} /></span>&nbsp;Offers Mobile Services in {data.count} Cities</a>
                                }
                            </div>
                        }
                        {props.mode == 1 && props.layout == "3" &&
                            <div className={(props.selectedLocation === data.encBID || bidHash == data.bidHash) ? "location-box active-cardbox" : "location-box"} >
                                <div className="location-header" onClick={() => { props.handleURL(data); }} >
                                    <h6>{(utils.Validate(data.nickName) ? data.nickName : data.businessName)}</h6>
                                    <h6>{data.distance} {data.countryID == 1 ? <Fragment>mi</Fragment> : <Fragment>km</Fragment>}</h6>
                                </div>
                                <p className="" onClick={() => { props.handleURL(data); }}>{data.street}, {data.city}, {data.stateName}, {data.zip}</p>
                                {data.count > 0 &&
                                    <a href="javascript:void(0)" onClick={() => ShowCityModal(data.cities, data.count, data.businessName)} className="stencil-item map-label" style={{ display: "flex", alignItems: "center", cursor: "default", marginTop: "8px" }}><span><HouseCallLocationIcon IsBlack={true} /></span>&nbsp;Offers Mobile Services in {data.count} Cities</a>
                                }
                            </div>
                        }
                        {props.mode == 2 && props.layout == "1" &&
                            <div style={{ display: (divhide.length > 0 ? "none" : "block") }} id={"div-" + (data.index)} className={(props.selectedLocation === data.encBID || bidHash == data.bidHash) ? "location-box active-cardbox" : "location-box"}>
                                <div className="location-header" onClick={() => { props.handleURL(data); }}>
                                    <h6><span style={{ marginRight: "4px", marginTop: "-2px", fontWeight: "bold" }}>{(data.index)}.</span>{data.businessName + (utils.Validate(data.nickName) ? " (" + data.nickName + ")" : "")}</h6>
                                    <h6>{data.distance} {data.countryID == 1 ? <Fragment>mi</Fragment> : <Fragment>km</Fragment>}</h6>
                                </div>
                                <p className="" onClick={() => { props.handleURL(data); }}>{data.street}, {data.city}, {data.stateName}, {data.zip}</p>
                                {data.count > 0 &&
                                    <a href="javascript:void(0)" onClick={() => ShowCityModal(data.cities, data.count, data.businessName)} className="stencil-item map-label" style={{ display: "flex", alignItems: "center", cursor: "default", marginTop: "8px" }}><span><HouseCallLocationIcon IsBlack={true} /></span>&nbsp;Offers Mobile Services in {data.count} Cities</a>
                                }
                            </div>
                        }
                        {props.mode == 2 && props.layout == "2" &&
                            <div style={{ display: (divhide.length > 0 ? "none" : "block") }} id={"div-" + (data.index)} className={(props.selectedLocation === data.encBID || bidHash == data.bidHash) ? "location-box active-cardbox" : "location-box"}>
                                <div className="location-header" onClick={() => { props.handleURL(data); }}>
                                    <h6><span style={{ marginRight: "4px", marginTop: "-2px", fontWeight: "bold" }}>{(data.index)}.</span>{data.street}, {data.city}, {data.stateName}, {data.zip}</h6>
                                    <h6>{data.distance} {data.countryID == 1 ? <Fragment>mi</Fragment> : <Fragment>km</Fragment>}</h6>
                                </div>
                                {data.count > 0 &&
                                    <a href="javascript:void(0)" onClick={() => ShowCityModal(data.cities, data.count, data.businessName)} className="stencil-item map-label" style={{ display: "flex", alignItems: "center", cursor: "default", marginTop: "8px" }}><span><HouseCallLocationIcon IsBlack={true} /></span>&nbsp;Offers Mobile Services in {data.count} Cities</a>
                                }
                            </div>
                        }
                        {props.mode == 2 && props.layout == "3" &&
                            <div style={{ display: (divhide.length > 0 ? "none" : "block") }} id={"div-" + (data.index)} className={(props.selectedLocation === data.encBID || bidHash == data.bidHash) ? "location-box active-cardbox" : "location-box"}>
                                <div className="location-header" onClick={() => { props.handleURL(data); }}>
                                    <h6><span style={{ marginRight: "4px", marginTop: "-2px", fontWeight: "bold" }}>{(data.index)}.</span>{(utils.Validate(data.nickName) ? data.nickName : data.businessName)}</h6>
                                    <h6>{data.distance} {data.countryID == 1 ? <Fragment>mi</Fragment> : <Fragment>km</Fragment>}</h6>
                                </div>
                                <p className="" onClick={() => { props.handleURL(data); }}>{data.street}, {data.city}, {data.stateName}, {data.zip}</p>
                                {data.count > 0 &&
                                    <a href="javascript:void(0)" onClick={() => ShowCityModal(data.cities, data.count, data.businessName)} className="stencil-item map-label" style={{ display: "flex", alignItems: "center", cursor: "default", marginTop: "8px" }}><span><HouseCallLocationIcon IsBlack={true} /></span>&nbsp;Offers Mobile Services in {data.count} Cities</a>
                                }
                            </div>
                        }
                    </Fragment>
                )
            })}
            {(businessList.length == 0 || props.isShowDiv == false) &&
                <Fragment>
                    <div className="location-box-norecords">
                        <div className="location-header" style={{ marginTop: "4px" }}>
                            <h6>No record found</h6>
                        </div>
                    </div>
                </Fragment>
            }
        </Fragment>
    );

}