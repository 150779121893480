import { ReactNode } from "react";
import { wBComponentTypeNames } from "./WBComponentTypeNames";
import React from "react";

export const attributeTypes = {

    textAlign:"textAlign",
    fontColor:"color",
    fontFamily:"fontFamily",
    fontSize:"fontSize",
    fontWeight:"fontWeight",
    letterSpacing:"letterSpacing",
    lineHeight:"lineHeight",
    textTransform:"textTransform",
    boxShadow:"boxShadow",
    margin:"margin",
    padding:"padding",
    flexWrap:"flexWrap",
    borderStyle:"borderStyle",
    borderWidth:"borderWidth",
    borderColor:"borderColor",
    clipPath:"clipPath",
    opacity:"opacity",
    objectFit:"objectFit",
    objectPosition:"objectPosition",
    overflow:"overflow",
    borderRadius:"borderRadius",
    flexBias:"flexBasis",
    flexGrow:"flexGrow",
    flexShring:"flexShrink",
    flexDirection:"flexDirection",
    gap:"gap",
    justifyContent:"justifyContent",
    alignContent:"alignContent",
    alignItems:"alignItems",
    width:"width",
    height:"height",
    zIndex:"zIndex",
    minWidth:"minWidth",
    minHeight:"minHeight",

    maxWidth:"maxWidth",
    maxHeight:"maxHeight",
    textShadow:"textShadow",
    keyWordSizing:"sizing",
    keyWordStartAdvanced:"advanced",

    textDecoration:"textDecoration",
    blendMode:"mixBlendMode",


    color:"color",
    backgroundColor:"backgroundColor",
    backgroundImage:"backgroundImage",
    backgroundSize:"backgroundSize",
    backgroundPosition:"backgroundPosition",
    backgroundRepeat:"backgroundRepeat",
    backgroundAttachment:"backgroundAttachment",
    position:"position",
    left:"left",
    top:"top",
    right:"right",
    bottom:"bottom",
   


}

export const componentAttributeTypes = {

    horizontalAlign:"horizontalAlign",
    verticalAlign:"verticalAlign",
    animation:"animation",
    overlayColor:"backgroundOverlayColor",
    anchorType:"anchorType",
    layoutId:"layoutId",
    locked:"locked",
    visibility:"dVisibility",
    multiLocation:"location",
    alloWMultiLocalationBooking:"allowMultiLocalBooking",
    customCSS:"customCSS",
    filters:"categoryFilters"
}



export const defaultAttributesFont = [
    
    attributeTypes.color,
    attributeTypes.fontFamily,
    attributeTypes.fontSize,
    attributeTypes.fontWeight,

    attributeTypes.letterSpacing,
    attributeTypes.lineHeight,
    attributeTypes.textTransform,
    attributeTypes.textDecoration,
    attributeTypes.textAlign,
    attributeTypes.textShadow,

]

export const defaultAttributeAnchor= [
    attributeTypes.position,
    componentAttributeTypes.anchorType,
   
        "posAnchor",
        "anchorType",
        "left",
        "right",
        "top",
        "bottom",
    
    
]

export const defaultAttributesShape = [

    attributeTypes.padding,

    // attributeTypes.paddingTop,
    // attributeTypes.paddingBottom,
    // attributeTypes.paddingLeft,
    // attributeTypes.paddingRight,

     attributeTypes.margin,
    // attributeTypes.marginTop,
    // attributeTypes.marginBottom,
    // attributeTypes.marginRight,
    // attributeTypes.marginLeft,
    attributeTypes.clipPath,
    attributeTypes.borderRadius,
    attributeTypes.boxShadow,
    attributeTypes.borderStyle,
    attributeTypes.borderWidth,
    attributeTypes.borderColor,
    attributeTypes.opacity,
    attributeTypes.overflow,

]



export const defaultAttributesBackground = [

    attributeTypes.backgroundColor,
    attributeTypes.backgroundImage,
    attributeTypes.backgroundSize,
    attributeTypes.backgroundPosition,
    attributeTypes.backgroundRepeat,
    attributeTypes.backgroundAttachment,
    componentAttributeTypes.overlayColor,
   

]

export const defaultAttributesSize =[
    attributeTypes.width,
    attributeTypes.height,
]

export const defaultAttributesFlex =[
  
    attributeTypes.gap,
    attributeTypes.flexWrap,
]
export const defaultAllAttributes = [
    componentAttributeTypes.animation,
    ...defaultAttributesFont,
    ...defaultAttributesShape,
    ...defaultAttributesFlex,
    ...defaultAttributesBackground
]

export const defaultAllAttributesNoFlex = [
    componentAttributeTypes.animation,
    ...defaultAttributesFont,
    ...defaultAttributesShape,
    ...defaultAttributesBackground

]

export const defaultAllAttributesNoFontsOrFlex = [
    componentAttributeTypes.animation,
    ...defaultAttributesFont,
    ...defaultAttributesShape,
    ...defaultAttributesBackground


]


export default function CreateAttributeList(shape:boolean = true,fonts:boolean = true,background:boolean = true,size:boolean = true,gap:boolean= true,anchor:boolean = true,animation= true):string[]{

    let list: string[] = [ componentAttributeTypes.visibility,attributeTypes.opacity,attributeTypes.blendMode,componentAttributeTypes.customCSS,attributeTypes.zIndex];
    if(animation)
        list.push(componentAttributeTypes.animation)

    if(shape)
        list = [...list,...defaultAttributesShape];
    if(fonts)
        list = [...list,...defaultAttributesFont];
    if(background)
        list = [...list,...defaultAttributesBackground];
    if(size)
        list = [...list,...defaultAttributesSize];
    if(gap)
        list = [...list,...defaultAttributesFlex];
    if(anchor)
        list = [...list,...defaultAttributeAnchor];
    return list;

}

export type AttributeGroup = {
    name:string,
    icon:ReactNode,
    attributes:string[]
}

const geomGroup:AttributeGroup = {
    name:"Shape",
    icon:<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M192 288V272 224l-64 0c-8.8 0-16-7.2-16-16s7.2-16 16-16h64V128l-64 0c-8.8 0-16-7.2-16-16s7.2-16 16-16l64 0 0-32c0-8.8-7.2-16-16-16L64 48c-8.8 0-16 7.2-16 16l0 272 0 112c0 .6 0 1.2 .1 1.7c.8 8 7.7 14.3 15.9 14.3H176l272 0c8.8 0 16-7.2 16-16l0-112c0-8.8-7.2-16-16-16H416v64c0 8.8-7.2 16-16 16s-16-7.2-16-16V320H320v64c0 8.8-7.2 16-16 16s-16-7.2-16-16V320H240 224v64c0 8.8-7.2 16-16 16s-16-7.2-16-16V320H128c-8.8 0-16-7.2-16-16s7.2-16 16-16h64zm96-16H448c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64l-272 0H64C30.9 512 3.6 486.8 .3 454.5c-.2-2.2-.3-4.3-.3-6.5V336 64C0 28.7 28.7 0 64 0H176c35.3 0 64 28.7 64 64l0 160 0 48h48z"/></svg>
    ,attributes:[
        "posAnchor",
        attributeTypes.gap,
        attributeTypes.margin,
        attributeTypes.padding,
        
        attributeTypes.borderRadius,
        attributeTypes.clipPath,
 
      
    ]
}

const effectGroup:AttributeGroup = {
    name:"Effects",
    icon:<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M325.8 152.3c1.3 4.6 5.5 7.7 10.2 7.7s8.9-3.1 10.2-7.7L360 104l48.3-13.8c4.6-1.3 7.7-5.5 7.7-10.2s-3.1-8.9-7.7-10.2L360 56 346.2 7.7C344.9 3.1 340.7 0 336 0s-8.9 3.1-10.2 7.7L312 56 263.7 69.8c-4.6 1.3-7.7 5.5-7.7 10.2s3.1 8.9 7.7 10.2L312 104l13.8 48.3zM115.7 346.2L75.5 307l55.5-8.1c15.6-2.3 29.2-12.1 36.1-26.3l24.8-50.3 24.8 50.3c7 14.2 20.5 24 36.1 26.3l55.5 8.1-40.2 39.2c-11.3 11-16.4 26.9-13.8 42.4l9.5 55.4-49.5-26.1c-14-7.4-30.7-7.4-44.7 0L120 444l9.5-55.4c2.7-15.6-2.5-31.4-13.8-42.4zm54.7-188.8l-46.3 94L20.5 266.5C.9 269.3-7 293.5 7.2 307.4l74.9 73.2L64.5 483.9c-3.4 19.6 17.2 34.6 34.8 25.3l92.6-48.8 92.6 48.8c17.6 9.3 38.2-5.7 34.8-25.3L301.6 380.6l74.9-73.2c14.2-13.9 6.4-38.1-13.3-40.9L259.7 251.4l-46.3-94c-8.8-17.9-34.3-17.9-43.1 0zm258.4 85.8l11 38.6c1 3.6 4.4 6.2 8.2 6.2s7.1-2.5 8.2-6.2l11-38.6 38.6-11c3.6-1 6.2-4.4 6.2-8.2s-2.5-7.1-6.2-8.2l-38.6-11-11-38.6c-1-3.6-4.4-6.2-8.2-6.2s-7.1 2.5-8.2 6.2l-11 38.6-38.6 11c-3.6 1-6.2 4.4-6.2 8.2s2.5 7.1 6.2 8.2l38.6 11z"/></svg>,
    attributes:[
        componentAttributeTypes.animation,
        componentAttributeTypes.visibility,
        attributeTypes.borderStyle,
        attributeTypes.boxShadow,
        attributeTypes.textShadow,
        attributeTypes.blendMode,
        attributeTypes.opacity,
        attributeTypes.overflow,
        
    ]

}
const backgroundGroup:AttributeGroup = {
    name:"Background",
    icon:<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M448 80c8.8 0 16 7.2 16 16V415.8l-5-6.5-136-176c-4.5-5.9-11.6-9.3-19-9.3s-14.4 3.4-19 9.3L202 340.7l-30.5-42.7C167 291.7 159.8 288 152 288s-15 3.7-19.5 10.1l-80 112L48 416.3l0-.3V96c0-8.8 7.2-16 16-16H448zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm80 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"/></svg>,
    attributes:[
        attributeTypes.backgroundColor,
        attributeTypes.backgroundImage,
        componentAttributeTypes.overlayColor,


    ]

}
const textGroup:AttributeGroup = {
    name:"Text",
    icon:<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M64 96v32c0 17.7-14.3 32-32 32s-32-14.3-32-32V80C0 53.5 21.5 32 48 32H224 400c26.5 0 48 21.5 48 48v48c0 17.7-14.3 32-32 32s-32-14.3-32-32V96H256l0 320h48c17.7 0 32 14.3 32 32s-14.3 32-32 32H144c-17.7 0-32-14.3-32-32s14.3-32 32-32h48l0-320H64z"/></svg>,
    attributes:[
        attributeTypes.fontColor,
        attributeTypes.fontFamily,
        attributeTypes.fontSize,
        attributeTypes.textAlign,
  
        attributeTypes.textTransform,
        attributeTypes.textDecoration,
        attributeTypes.letterSpacing,
        attributeTypes.lineHeight,
        attributeTypes.fontWeight


    ]

}

const customGroup:AttributeGroup = {
    name:"CSS",
    icon:<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M64 464c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16H224v80c0 17.7 14.3 32 32 32h80V448c0 8.8-7.2 16-16 16H64zM64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V154.5c0-17-6.7-33.3-18.7-45.3L274.7 18.7C262.7 6.7 246.5 0 229.5 0H64zm97 289c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L79 303c-9.4 9.4-9.4 24.6 0 33.9l48 48c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-31-31 31-31zM257 255c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l31 31-31 31c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l48-48c9.4-9.4 9.4-24.6 0-33.9l-48-48z"/></svg>,
    attributes:[
        componentAttributeTypes.customCSS,


    ]

};

export const AttributeGroups:AttributeGroup[] = [
    backgroundGroup,
    effectGroup,
    geomGroup,
    textGroup,
    customGroup
  

   
  

]




