import React, { Fragment } from "react";
import { UserContext } from "../Definitions/UserContext";
import { utils } from "../../utils/Utils";
import ReactDom from "react-dom";
import StaffMessage from "../../Hooks/StaffMessage";

export default function RenderBusinessMessage({ layout = "1", siteData }: { layout: string, siteData: UserContext }) {
    switch (layout) {
        default:
            return <MessageButtons siteData={siteData} />
    }

    function MessageButtons({ siteData }: { siteData: any }) {
        var IsEditor = (window as any).isEditor !== undefined ? (window as any).isEditor : false;
        return <Fragment>
            <a role="button" className="v-button center-all gap-med primary w-full"  onClick={(event) => !IsEditor && (window as any).SendMessage(false,siteData)}><svg height="1em" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 128C0 93 28 64 64 64H448C483 64 512 93 512 128V384C512 420 483 448 448 448H64C28 448 0 420 0 384V128ZM32 128V168L227 312C244 324 267 324 284 312L480 168V128C480 111 465 96 448 96H63C46 96 31 111 31 128H32ZM32 208V384C32 402 46 416 64 416H448C465 416 480 402 480 384V208L303 338C275 358 236 358 208 338L32 208Z" fill="currentColor"/> </svg> Message</a>
        </Fragment>
    }
}

declare global {
    interface Window { SendMessage: any; }
}

window.SendMessage = function (staffMember: any, siteData :any) {
    let LoginDetails= utils.ReadCookie();
    return ReactDom.render(
        <StaffMessage
            data={staffMember}            
            LoginDetails={LoginDetails}
            siteData={siteData}
            isMessage={true}
        />,
        document.getElementById("OpenDialog")
    );
};