import React, { Fragment, useEffect, useState } from "react"
import ReactDom from "react-dom";
import styled from 'styled-components';

import { addtoCartEnum, utils } from "../..//utils/Utils";
import StaffMessage from "../../Hooks/StaffMessage";
import StaffReview from "../../Hooks/StaffReview"
import { UserContext } from "../Definitions/UserContext";
import OopsBanner, { NoRecordsBanner } from "../OopsBanner";
import { AsyncAjaxGet } from "../Utilities/AsyncAjaxService";
import RenderAlternatingProfiles from "./layouts/RenderAlternatingProfiles";
import RenderStaffMember from './layouts/RenderStaffMember'
import RenderStaffMemberCards from './layouts/RenderStaffMemberCards'
import RenderStaffMemberCircles from './layouts/RenderStaffMemberCircles'
import RenderStaffMemberSmallCircles from './layouts/RenderStaffMemberSmallCircles'
import RenderStaffRectangles from "./layouts/RenderStaffRectangles";
import styles from './layouts/ResponsiveGrid.module.css';
import './layouts/component-layouts.css';
// import styles from './RenderBusinessStaff.module.css';
const avatarSize = "4.5em";
const avatarStyle = { width: avatarSize, height: avatarSize, borderRadius: "50%" };

export default function RenderBusinessStaff({ data, siteData, layout }: { data: any, siteData: UserContext, layout: string }) {

    const [serviceList, setServiceList] = useState(0);
    const Url = `merchants/${siteData.businessId}/services`;


    useEffect(() => {

        if (serviceList == 0) {
            AsyncAjaxGet(
                Url,
                siteData.accessToken,
                function onSuccess(data: any) {
                    if (data !== null && data.data !== null && data.data.data !== null) {
                        if (data.data.data[0].serviceCategoryList !== undefined && data.data.data[0].serviceCategoryList !== null && data.data.data[0].serviceCategoryList !== '') {
                            setServiceList(data.data.data[0].serviceCategoryList.length);
                        }
                    }
                },
                function onFailure() {
                    console.warn("error");
                }
            );
        }
    }, []);

    const isStenciled = data[0]?.isStenciled === true;

    if (data.length == 0) {
        return <NoRecordsBanner message='Staff is not available.' />
    }

    switch (layout) {
        default: return <RenderDefaultLayout data={data} siteData={siteData} isStenciled={isStenciled} serviceList={serviceList} layout={layout} />
        case "2": return <RenderCardLayout data={data} siteData={siteData} isStenciled={isStenciled} serviceList={serviceList} layout={layout} />
        case "3": return <RenderStaffCircleLayout data={data} siteData={siteData} isStenciled={isStenciled} serviceList={serviceList} layout={layout} />
        case "4": return <RenderStaffSmallCircleLayout data={data} siteData={siteData} isStenciled={isStenciled} serviceList={serviceList} layout={layout} />
        case "5": return <RenderAlternatingProfileLayout data={data} siteData={siteData} isStenciled={isStenciled} serviceList={serviceList} layout={layout} />
        case "6": return <RenderStaffRectangleLayout data={data} siteData={siteData} isStenciled={isStenciled} serviceList={serviceList} layout={layout}/>
    }
}

function RenderDefaultLayout({ data, siteData, isStenciled, serviceList, layout }: { data: any, siteData: UserContext, isStenciled: boolean, serviceList: any, layout: any }) {
    if (data.map === undefined)
        return <OopsBanner />

    return <>
        <div className={isStenciled ? "stenciled" : ""} style={{ display: "flex", flexDirection: "inherit", gap: "inherit", flexShrink: "0" }}>
            {data.map((staffMember: any, index: number) => {
                return <Fragment key={index}>{index > 0 && <hr />}
                    <RenderStaffMember isStenciled={isStenciled} siteData={siteData} staffMember={staffMember} serviceList={serviceList} layout={layout} /></Fragment>
            })}
        </div>
    </>
}

function RenderCardLayout({ data, siteData, isStenciled, serviceList, layout }: { data: any, siteData: UserContext, isStenciled: boolean, serviceList: any, layout: any }) {
    if (data.map === undefined)
        return <OopsBanner />

    return <>
        <div className={`row ${isStenciled ? "stenciled" : ""}`} style={{ backgroundColor: isStenciled ? "#f0f0f0" : "" ,maxWidth:"100%",margin:"auto"}}>
            <div className={styles.cardWrapper}>

            <div className="staff-grid-container gap-4" style={{ maxWidth: "1149x", padding: "20px" }}>
                {data.map((staffMember: any, index: number) => (
                    <div className="staff-grid-item" key={index}>
                        <RenderStaffMemberCards
                            isStenciled={isStenciled}
                            siteData={siteData}
                            staffMember={staffMember}
                            serviceList={serviceList}
                            layout={layout}
                        />
                    </div>
                ))}
            </div>
            </div>
        </div>
    </>
}

const FlexContainer = styled.div`
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around; // Adjust this as needed
      align-items: center; // Adjust this as needed
`;

function RenderStaffCircleLayout({ data, siteData, isStenciled, serviceList, layout }: { data: any, siteData: UserContext, isStenciled: boolean, serviceList: any, layout: any }) {

    if (data.map === undefined)
        return <OopsBanner />

    return <>
        <div className={`row ${isStenciled ? "stenciled" : "container-test"}`} style={{ backgroundColor: isStenciled ? "#f0f0f0" : "",maxWidth:"100%",margin:"auto" }}>
            {/* <BusinessStaffCircles /> */}

            <FlexContainer>
                {data.map((staffMember: any, index: number) => (
                    <Fragment key={index}>
                        <div className="">
                            <RenderStaffMemberCircles
                                isStenciled={isStenciled}
                                siteData={siteData}
                                staffMember={staffMember}
                                serviceList={serviceList}
                                layout={layout}
                            />
                        </div>
                    </Fragment>
                ))}
            </FlexContainer>
        </div>


    </>

}


const FlexContainerSmallCircles = styled.div`
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin: auto;
  padding-top: 8px;
  padding-bottom: 120px;
  // Mobile devices (default)
  width: 100%; // Full width for mobile
  flex-direction: row;

  & > div { // Direct child divs
    width: 100%; // Full width for mobile
  }

  // Tablet devices (medium screens)
  @media (min-width: 768px) {
    width: 100%; // Adjust width as needed for tablet
    & > div {
      flex: 0 0 33.3333%; // 3 items per row
    }
  }

  // Desktop devices (large screens)
  @media (min-width: 1024px) {
    width: 100%; // Adjust width as needed for desktop
    & > div {
      flex: 0 0 33.3333%; // 3 items per row
    }
  }
`;

const CustomContainerWrapper = styled.div`
  width: 90%;
  margin: 0  auto;
  margin-top: 32px;
  margin-bottom: 32px;
`;

function RenderStaffSmallCircleLayout({ data, siteData, isStenciled, serviceList, layout }: { data: any, siteData: UserContext, isStenciled: boolean, serviceList: any, layout: any }) {

    if (data.map === undefined)
        return <OopsBanner />

    return <>
        <CustomContainerWrapper>
            <div className={`container-query-target mx-auto mt-5 mb-5 ${isStenciled ? "stenciled" : ""}`} style={{ backgroundColor: isStenciled ? "#f0f0f0" : "" }}>
                {/* <BusinessStaffCircles /> */}
                <FlexContainerSmallCircles>
                   
                    {data.map((staffMember: any, index: number) => (
                        <Fragment key={index}>
                            <div className="ucq col-sm-6 col-md-3 col-lg-3">
                                <RenderStaffMemberSmallCircles
                                    isStenciled={isStenciled}
                                    siteData={siteData}
                                    staffMember={staffMember}
                                    serviceList={serviceList}
                                    layout={layout}
                                />
                            </div>
                        </Fragment>
                    ))}
                </FlexContainerSmallCircles>
            </div>
        </CustomContainerWrapper>
    </>

}

function RenderAlternatingProfileLayout({ data, siteData, isStenciled, serviceList, layout }: { data: any, siteData: UserContext, isStenciled: boolean, serviceList: any, layout: any }) {

    if (data.map === undefined)
        return <OopsBanner />

    return <>
        <div className={`row ${isStenciled ? "stenciled" : "container-test"}`} style={{ backgroundColor: isStenciled ? "#f0f0f0" : "" ,maxWidth:"100%",margin:"auto"}}>
            {/* <BusinessStaffCircles /> */}

            <FlexContainer>
                {data.map((staffMember: any, index: number) => (
                    <Fragment key={index}>
                        <RenderAlternatingProfiles
                            isStenciled={isStenciled}
                            siteData={siteData}
                            staffMember={staffMember}
                            serviceList={serviceList}
                            layout={layout}
                            isReversed={index % 2 !== 0}
                        />
                    </Fragment>
                ))}
            </FlexContainer>
        </div>
    </>

}

// Define a type for the item
type GridItem = {
    content: string;
  };
  
  // Define a type for the props
  type ResponsiveGridProps = {
    items: GridItem[];
  };

const ResponsiveGrid: React.FC<ResponsiveGridProps> = ({ items }) => {
    return (
       
        <div className={styles.gridContainer}>
        {items.map((item, index) => (
          <div key={index} className={styles.gridItem}>
            <div className={styles.gridContent}>
              {item.content}
            </div>
          </div>
        ))}
      </div>
      
    );
  };

function RenderStaffRectangleLayout ({ data, siteData, isStenciled, serviceList, layout }: { data: any, siteData: UserContext, isStenciled: boolean, serviceList: any, layout: any }) {

    if (data.map === undefined)
        return <OopsBanner />

        const gridItems = data.map((staffMember: any, index: number) => ({
            content: (
                <RenderStaffRectangles
                    key={index}
                    isStenciled={isStenciled}
                    siteData={siteData}
                    staffMember={staffMember}
                    serviceList={serviceList}
                    layout={layout}
                />
            )
        }));

    return <>
         
         <div className={`${isStenciled ? "stenciled" : ""}`} style={{ backgroundColor: isStenciled ? "#f0f0f0" : "" }}>
            {/* Use ResponsiveGrid here */}
            <ResponsiveGrid items={gridItems} />
        </div>
    </>

}
declare global {
    interface Window { Staffreview: any; BookStaffSerice: any; BookStaffClass: any; wbc: any; StaffMessage: any; BookServiceClass: any }
}

window.Staffreview = function (staffMember: any, siteData: UserContext, BusinessData: any) {
    OpenStaffreview({ staffMember, siteData, BusinessData });
};

window.BookStaffSerice = function (staffMember: any, BusinessData: any) {
    var jsonData = JSON.stringify({ serviceid: "0", encspid: staffMember.encServiceProviderID, styleID: "0", styleName: "", bookingDateTime: "", PromotionID: "", EventType: "", isdeeplinkentry: null, PromotionProviderID: "" }).replace(/\\\"/g, '"').replace(/"/g, "\\\"");
    window.wbc.openVagaroPage("divVagaroIframe", utils.VURL(null, BusinessData.vagaroURL) + addtoCartEnum.StaffServiceBook + "&Data=" + jsonData);
    return;
};

window.BookStaffClass = function (staffMember: any, BusinessData: any) {
    var jsonData = JSON.stringify({ serviceid: "0", encspid: staffMember.encServiceProviderID, styleID: "0", styleName: "", bookingDateTime: "", PromotionID: "", EventType: "", isdeeplinkentry: null, PromotionProviderID: "" }).replace(/\\\"/g, '"').replace(/"/g, "\\\"");
    window.wbc.openVagaroPage("divVagaroIframe", utils.VURLClasses(BusinessData.vagaroURL) + addtoCartEnum.StaffClassBook + "&Data=" + jsonData);
    return;
};

window.BookServiceClass = function (staffMember: any, BusinessData: any) {
    if (staffMember.isServiceTab) {
        var jsonData = JSON.stringify({ serviceid: "0", encspid: staffMember.encServiceProviderID, styleID: "0", styleName: "", bookingDateTime: "", PromotionID: "", EventType: "", isdeeplinkentry: null, PromotionProviderID: "" }).replace(/\\\"/g, '"').replace(/"/g, "\\\"");
        window.wbc.openVagaroPage("divVagaroIframe", utils.VURL(null, BusinessData.vagaroURL) + addtoCartEnum.StaffServiceBook + "&Data=" + jsonData);
        return;
    }
    else {
        var jsonData = JSON.stringify({ serviceid: "0", encspid: staffMember.encServiceProviderID, styleID: "0", styleName: "", bookingDateTime: "", PromotionID: "", EventType: "", isdeeplinkentry: null, PromotionProviderID: "" }).replace(/\\\"/g, '"').replace(/"/g, "\\\"");
        window.wbc.openVagaroPage("divVagaroIframe", utils.VURLClasses(BusinessData.vagaroURL) + addtoCartEnum.StaffClassBook + "&Data=" + jsonData);
        return;
    }
};

window.StaffMessage = function (staffMember: any, siteData: any, BusinessData: any) {
    let LoginDetails = utils.ReadCookie();
    return ReactDom.render(
        <StaffMessage
            data={staffMember}
            LoginDetails={LoginDetails}
            siteData={siteData}
            isMessage={false}
            BusinessData={BusinessData}
        />,
        document.getElementById("OpenDialog")
    );
};

function OpenStaffreview({ staffMember, siteData, BusinessData }: { staffMember: any, siteData: UserContext, BusinessData: any }) {
    return ReactDom.render(
        <StaffReview
            staffData={staffMember}
            userContext={siteData}
        />,
        document.getElementById("OpenDialog")
    );
}