import { useEffect, useState } from "react";
import { UserContext } from "../Definitions/UserContext";
import { GetBusienssHours, GetBusinessDetails, GetBusinessGallery, GetBusinessMap, GetBusinessMemberships, GetBusinessPackages, GetBusinessProducts, GetBusinessReviews, GetBusinessServices, GetBusinessSocialLinks, GetBusinessStaff, GetFacilityInformation, GetGiftCertificates, getDailyDeals, GetBusinessReviewPecentage, GetCommonReponse, GetProductDetails, GetBusinessCart, GetProductBundleDetails, GetBusienssForm, GetBusinessLocation, GetBusinessName } from "../Utilities/BusinessData"
import EditorLoadingSpinner from "../EditorLoadingSpinner";
import React from "react";
import { RenderBusinessServices } from "../BusinessControls/RenderBusinessServices";
import RenderBusinessReviews from "../BusinessControls/RenderBusinessReviews";
import RenderBusinessStaff from "../BusinessControls/RenderBusinessStaff";
import RenderBusinessSocialLinks from "../BusinessControls/RenderBusinessSocialLinks";
import RenderBusinessHours from "../BusinessControls/RenderBusinessHours";
import RenderBusinessProducts from "../BusinessControls/RenderBusinessProducts";
import RenderBusinessGallery from "../BusinessControls/RenderBusinessGallery";
import RenderBusinessFacilityInfo from "../BusinessControls/RenderBusinessFacilityInfo";
import LayoutNode from "../Definitions/LayoutNode";
import RenderBusinessDailyDeals from "../BusinessControls/RenderBusinessDailyDeals";
import ErrorBoundry from "../ErrorBoundry";
import RenderBusinessGiftCards from "../BusinessControls/RenderBusinessGiftCards";
import RenderBusinessMap from "../BusinessControls/RenderBusinessMap";
import RenderBusinessMemberships from "../BusinessControls/RenderBusinessMemberships";
import RenderBusinessPackages from "../BusinessControls/RenderBusinessPackages";
import RenderBusinessDescription from "../BusinessControls/RenderBusinessDescription";
import OopsBanner, { NoRecordsBanner } from "../OopsBanner";
import RenderBusinessReviewPercentage from "../BusinessControls/RenderBusinessReviewPercentage";
import RenderBusinessMessage from "../BusinessControls/RenderBusinessMessage";
import RenderBusinessCall from "../BusinessControls/RenderBusinessCall";
import RenderBusinessContactLess from "../BusinessControls/RenderBusinessContactLess";
import RenderProductDetails, { GetItemBundleInfoFromData } from "../BusinessControls/RenderProductDetails";
import GetProductInfoFromURL from "../Utilities/GetProductInfoFromURL";
import RenderBusinessCart from "../BusinessControls/RenderBusinessCart";
import RenderMyAccount from "../BusinessControls/RenderMyAccount";
import RenderBusinessBookNow from "../BusinessControls/RenderBusinessBookNow";
import RenderBusinessClass from "../BusinessControls/RenderBusinessClass";
import RenderBusinessForm from "../BusinessControls/RenderBusinessForm";
import RenderBusinessLocation from "../BusinessControls/RenderBusinessLocation";
import RenderBusinessName from "../BusinessControls/RenderBusinessName";

import { HoursPlaceHolder, MembershipPlaceHolder, PackagesPlaceHolder, ReviewPlaceHolder, ServicesPlaceHolder, dailyDealsPlaceholder as DailyDealsPlaceholder, GiftCardPlaceHolder, FacilityPlaceholder, ProductPlaceHolder, ProductDetailsPlaceHodler, ReviewSummaryPlaceholder, StaffPlaceholder, VenueGalleryPlaceholder } from "./BusinessPlaceholderData";


export const BusienssControlNames = {
    services: "services",
    description: "description",
    reviews: "reviews",
    staff: "staff",
    gallery: "gallery",
    socialLinks: "socialLinks",
    hours: "hours",
    products: "products",
    facility: "facility",
    dailyDeals: "dailyDeals",
    giftCertificates: "giftCertificates",
    businessMap: "businessMap",
    memberships: "memberships",
    packages: "packages",
    reviewpercentage: "reviewpercentage",
    message: "message",
    call: "call",
    contactless: "contactless",
    productDetails: "productDetails",
    cart: "cart",
    myaccount: "myaccount",
    booknow: "book-now",
    classes: "classes",
    form: "form",
    location: "location",
    Name:"name"
}

export function GenericBusinessControl({ layout, dataKey, userContext, node, rootId, bLocationId, allowMultiLoc, inHTML, forceStencil, filters }: { layout: string, dataKey: string, userContext: UserContext, inData?: any, node?: LayoutNode, rootId?: string, bLocationId?: string | null, allowMultiLoc?: boolean, inHTML?: string, forceStencil?: boolean, filters?: string }) {
    //if a node was provided check the nodes stored data, otherwise set to null to force an api call
    let inData = null;

    if (node?.data) {
        if (forceStencil) {
            if (node.data.length && node.data.length > 0) {
                inData = [...node.data];
                inData[0] = { ...node.data[0] };
                inData[0].isStenciled = true;
            }
            else {
                inData = { ...node.data };
                inData.isStenciled = true;
            }
        }
        else {
            inData = node.data;
        }
    }

    const [bData, setBData] = useState<any>(inData)
    if (userContext.isDuplicateAllLocationPage) {
        allowMultiLoc = false;
    }

    let newContext = userContext.isDuplicateAllLocationPage ? { ...userContext } : ((bLocationId && bLocationId.length > 1) ? { ...userContext, businessId: bLocationId } : { ...userContext })

    if (dataKey == BusienssControlNames.form) {
        newContext.formid = layout;
    }

    useEffect(() => {

        if (!bData) {
            const onComplete = (data: any) => { setBData(data); if (node) node.data = data; }
            const onFailure = (data: any) => { setBData("error"); console.warn("err", data); }

            try {
                switch (dataKey) {
                    case BusienssControlNames.services:
                        GetBusinessServices(newContext, onComplete, onFailure);
                        setBData(ServicesPlaceHolder);
                        break;
                    case BusienssControlNames.description:
                        GetBusinessDetails(newContext, onComplete, onFailure);
                        setBData({ isStenciled: true });
                        break;
                    case BusienssControlNames.contactless:
                        GetBusinessDetails(newContext, onComplete, onFailure);
                        setBData({ isStenciled: true });
                        break;
                    case BusienssControlNames.reviews:
                        GetBusinessReviews(newContext, onComplete, onFailure);
                        setBData(ReviewPlaceHolder);
                        break;
                    case BusienssControlNames.staff:
                        GetBusinessStaff(newContext, onComplete, onFailure);
                        setBData(StaffPlaceholder);
                        break;
                    case BusienssControlNames.socialLinks:
                        GetBusinessSocialLinks(newContext, onComplete, onFailure);
                        setBData({ isStenciled: true });
                        break;
                    case BusienssControlNames.hours:
                        GetBusienssHours(newContext, onComplete, onFailure);
                        setBData(HoursPlaceHolder);
                        break;
                    case BusienssControlNames.products:

                        GetBusinessProducts(newContext, onComplete, onFailure);
                        setBData(ProductPlaceHolder);
                        break;
                    case BusienssControlNames.gallery:
                        GetBusinessGallery(newContext, onComplete, onFailure);
                        setBData(VenueGalleryPlaceholder);
                        break;
                    case BusienssControlNames.facility:
                        GetFacilityInformation(newContext, onComplete, onFailure);
                        setBData(FacilityPlaceholder);
                        break;
                    case BusienssControlNames.dailyDeals:
                        getDailyDeals(newContext, onComplete, onFailure);
                        setBData(DailyDealsPlaceholder);
                        break;
                    case BusienssControlNames.giftCertificates:
                        GetGiftCertificates(newContext, onComplete, onFailure);
                        setBData(GiftCardPlaceHolder);
                        break;
                    case BusienssControlNames.businessMap:
                        GetBusinessMap(newContext, onComplete, onFailure);
                        setBData({ isStenciled: true });
                        break;
                    case BusienssControlNames.memberships:
                        GetBusinessMemberships(newContext, onComplete, onFailure);
                        setBData(MembershipPlaceHolder);
                        break;
                    case BusienssControlNames.packages:
                        GetBusinessPackages(newContext, onComplete, onFailure);
                        setBData(PackagesPlaceHolder);
                        break;
                    case BusienssControlNames.reviewpercentage:
                        GetBusinessReviewPecentage(newContext, onComplete, onFailure);
                        setBData(ReviewSummaryPlaceholder);
                        break;
                    case BusienssControlNames.call:
                        GetCommonReponse(newContext, onComplete, onFailure);
                        break;
                    case BusienssControlNames.message:
                        GetCommonReponse(newContext, onComplete, onFailure);
                        break;
                    case BusienssControlNames.productDetails:
                        let prodDetailsdoc = document.getElementById("data-details")
                        let data = prodDetailsdoc?.getAttribute("response-data");
                        let detailsContext = { ...newContext };
                        //detailsContext.businessId = 
                        let found = false;
                        if (data) {
                            let json = JSON.parse(data);
                            if (json) {
                                found = true;
                                setBData(json);
                                break;
                            }
                        }
                        if (!found) {
                            const result = GetProductInfoFromURL("", userContext.MultilocationData);
                            detailsContext.businessId = result.businessId;
                            let productid = result.productId;
                            GetProductDetails(detailsContext, productid, (data: any) => {

                                if (!result.isBundle)
                                    onComplete(data);
                                else {
                                    GetProductBundleDetails(detailsContext, productid, (bundleReturn: any) => {
                                        const bundleData = GetItemBundleInfoFromData(bundleReturn);
                                        data.bundleData = bundleData;
                                        onComplete(data);
                                    }, onFailure);
                                }


                            }, onFailure);
                            setBData(ProductDetailsPlaceHodler);
                        }
                        break;
                    case BusienssControlNames.cart:
                        GetBusinessCart(newContext, onComplete, onFailure);
                        break;
                    case BusienssControlNames.myaccount:
                        GetCommonReponse(newContext, onComplete, onFailure);
                        break;
                    case BusienssControlNames.booknow:
                        GetBusinessDetails(newContext, onComplete, onFailure);
                        break;
                    case BusienssControlNames.classes:
                        GetBusinessDetails(newContext, onComplete, onFailure);
                        break;
                    case BusienssControlNames.form:
                        GetBusienssForm(newContext, onComplete, onFailure);
                        setBData({ isStenciled: true });
                        break;
                    case BusienssControlNames.location:
                        GetBusinessLocation(newContext, onComplete, onFailure);
                        setBData({ isStenciled: true });
                        break;
                    case BusienssControlNames.Name:
                        GetBusinessName(newContext, onComplete, onFailure);
                        setBData({ isStenciled: true });
                        break;
                    default:
                        setBData("error");
                        setBData("loading");
                        break;
                }
            }
            catch (e) {
                console.warn("error loading data", e);
                setBData("error");

            }
        }

    }, [])

    if ((!bData && dataKey != BusienssControlNames.dailyDeals && dataKey != BusienssControlNames.booknow && dataKey != BusienssControlNames.classes && dataKey != BusienssControlNames.myaccount && dataKey != BusienssControlNames.cart) || bData == "loading") // waiting for data
        return (
            < >
                <EditorLoadingSpinner />
            </>
        )
    else if (bData == "empty") {
        return <NoRecordsBanner />
    }

    else if (bData == "error") { // bad data
        return <OopsBanner />
    }

    else {
        return <>
            <ErrorBoundry>
                <RenderByKey filters={filters} layout={layout} dataKey={dataKey} userContext={newContext} node={node} bData={bData} rootId={rootId ?? "-"} allowMultiLoc={allowMultiLoc === true} />
            </ErrorBoundry>
        </>
    }
}

function RenderByKey({ layout, dataKey, userContext, node, bData, rootId, allowMultiLoc, filters }: { bData: any, layout: string, dataKey: string, userContext: UserContext, inData?: any, node?: LayoutNode, rootId: string, allowMultiLoc: boolean, filters?: string }) {
    switch (dataKey) { // good data
        case BusienssControlNames.services:
            return <RenderBusinessServices layout={layout} data={bData} siteData={userContext} rootId={rootId} allowMultiLoc={allowMultiLoc} filter={filters} />
        case BusienssControlNames.description:
            return <RenderBusinessDescription layout={layout} data={bData} />
        case BusienssControlNames.contactless:
            return <RenderBusinessContactLess layout={layout} data={bData} />
        case BusienssControlNames.reviews:
            return <RenderBusinessReviews layout={layout} data={bData} userContext={userContext} SPID={"U2FsdGVkX18EI4k80FtcVtq4PzxA94OPqaGqyI1vxNM="} hideTitle={true} />
        case BusienssControlNames.staff:
            return <RenderBusinessStaff layout={layout} data={bData} siteData={userContext} />
        case BusienssControlNames.socialLinks:
            return <RenderBusinessSocialLinks layout={layout} bData={bData} />
        case BusienssControlNames.hours:
            return <RenderBusinessHours layout={layout} data={bData} />
        case BusienssControlNames.products:
            return <RenderBusinessProducts layout={layout} data={bData} userContext={userContext} allowMultiLoc={allowMultiLoc} />
        case BusienssControlNames.gallery:
            return <RenderBusinessGallery layout={layout} data={bData} userContext={userContext} />
        case BusienssControlNames.facility:
            return <RenderBusinessFacilityInfo layout={layout} data={bData} />
        case BusienssControlNames.dailyDeals:
            return <RenderBusinessDailyDeals layout={layout} data={bData} siteData={userContext} rootId={rootId} />
        case BusienssControlNames.giftCertificates:
            return <RenderBusinessGiftCards layout={layout} data={bData} userContext={userContext} allowMultiLoc={allowMultiLoc} />
        case BusienssControlNames.businessMap:
            return <RenderBusinessMap layout={layout} data={bData} userContext={userContext} />
        case BusienssControlNames.memberships:
            return <RenderBusinessMemberships layout={layout} data={bData} siteData={userContext} rootId={rootId} allowMultiLoc={allowMultiLoc} />
        case BusienssControlNames.packages:
            return <RenderBusinessPackages layout={layout} data={bData} siteData={userContext} rootId={rootId} allowMultiLoc={allowMultiLoc} />
        case BusienssControlNames.reviewpercentage:
            return <RenderBusinessReviewPercentage layout={layout} data={bData} userContext={userContext} />
        case BusienssControlNames.message:
            return <RenderBusinessMessage layout={layout} siteData={userContext} />
        case BusienssControlNames.call:
            return <RenderBusinessCall layout={layout} siteData={userContext} />
        case BusienssControlNames.productDetails:
            return <RenderProductDetails data={bData} userContext={userContext} />
        case BusienssControlNames.cart:
            return <RenderBusinessCart data={bData} userContext={userContext} />
        case BusienssControlNames.myaccount:
            return <RenderMyAccount layout={layout} userContext={userContext} businessID={""} countryID={0} />
        case BusienssControlNames.booknow:
            return <RenderBusinessBookNow layout={layout} data={bData} root={rootId} allowMultiLoc={allowMultiLoc} userContext={userContext} VgUrl={""} />
        case BusienssControlNames.classes:
            return <RenderBusinessClass layout={layout} data={bData} root={rootId} allowMultiLoc={allowMultiLoc} userContext={userContext} filter={filters} VgUrl={""} />
        case BusienssControlNames.form:
            if (bData != null) {
                if (bData.isActivate == false) {
                    return <RenderBusinessForm data={bData} formID={layout} isActivate={false} siteData={userContext} />
                }
                else {
                    return <RenderBusinessForm data={bData} formID={layout} isActivate={true} siteData={userContext} />
                }
            }
        case BusienssControlNames.location:

        const urlDom = document.getElementById("location-page-link");
        const clickurl = (urlDom? urlDom.getAttribute("href")??"":"");

            return <RenderBusinessLocation siteData={userContext} data={bData} layout={layout} layoutID={1} pages={clickurl} />
        case BusienssControlNames.Name:
                return <RenderBusinessName data={bData} layout={layout} siteData={userContext} />
        default:
            return <OopsBanner />
    }
}

