import { addtoCartEnum, utils } from '../utils/Utils';
import { AsyncAjaxPost } from "../components/Utilities/AsyncAjaxService"
import { useEffect, useState } from "react";
import Loader from '../components/Common/Loader';
import ReactDOM, { unmountComponentAtNode } from "react-dom";
import RenderBusinessCart from "../components/BusinessControls/RenderBusinessCart";
import { NoRecordsBanner } from '../components/OopsBanner';
import ReactDom from "react-dom";
import MultiLocationDropdown from '../VagaroComponent/MultiLocationDropdown';

const UseToAddCart = (props) => {

    const ServiceData = props.data;
    const SiteData = props.SiteData;
    const event = props.event;
    const allowMultiLoc = props.allowMultiLoc;
    const [isContentLoading, setContentLoading] = useState(false);
    const BusinessData = utils.MultiLocationData(props.SiteData, props.SiteData.bidHash);

    useEffect(() => {
        if (!utils.isNullEmptyOrWhiteSpace(props.EndBID)) {
            AddCart(props.EndBID, true, false, null);
        }
        else {
            AddCart("", true, false, null);
        }
    }, [props]);

    if (utils.isCheckEmptyOrWhiteSpace(BusinessData)) return <NoRecordsBanner message='Marchant data is not valid.' />;

    const ServiceAddCart = async (encBID) => {        
        let LoginDetails = utils.ReadCookie();
        setContentLoading(true);
        const Requestdata = {};
        Requestdata.EncServiceId = ServiceData.encServiceId;
        Requestdata.EncUid = LoginDetails.EncUid;
        Requestdata.noOfVisit = 0;
        Requestdata.promotionDetail = ServiceData.promotionDetail;
        Requestdata.promotionTitle = ServiceData.promotionTitle;
        Requestdata.ServiceDesc = ServiceData.serviceDesc;
        Requestdata.isCustomPackage = false;
        Requestdata.lstAddonService = [];
        Requestdata.recGiftCertificateId = "";
        await AsyncAjaxPost(
            (encBID != undefined && encBID != null && encBID != "") ? encBID + "/addtocart/serviceaddtocart" : SiteData.businessId + "/addtocart/serviceaddtocart",
            Requestdata,
            SiteData.accessToken,
            function onSuccess(data) {
                var servicecart = data.data.data;
                unmountComponentAtNode(document.getElementById("divcart"));
                ReactDOM.render(<RenderBusinessCart data={servicecart.itemCount} userContext={SiteData} />, document.getElementById("divcart"));
                setContentLoading(false);
            }
        )
    }

    const AddCart = async (encBID, open, IsCallback, CallBackData) => {
        let LoginDetails = utils.ReadCookie();
        if (props.cartBook === true) {
            if (LoginDetails.EncUid === undefined || LoginDetails.EncUid === "" || LoginDetails.EncUid === null) {
                window.wbc.openVagaroPage("divVagaroIframe", utils.VURL("Login", null) + addtoCartEnum.ServiceAddToCart + "&Data=" + event.target.id);
                return;
            }
            else if (allowMultiLoc && open !== false) {                
                const multiGroupService = ServiceData.multiGroupService;
                let Multilocations = [];
                if (!utils.isNullEmptyOrWhiteSpace(multiGroupService)) {
                    var businessData = JSON.parse(multiGroupService);
                    businessData.map((data, index) => {
                        let Data = [];
                        Data = SiteData.MultilocationData.filter(
                            (col) => col.encBIDHash == data.BID && col.isSellServicesOnline == true
                        );
                        if (Data.length > 0) {
                            Multilocations.push(Data[0]);
                        }
                    });
                }
                if (Multilocations.length > 1) {
                    return ReactDom.render(
                        <MultiLocationDropdown
                            Sitedata={Multilocations}
                            OnClickEvent={AddCart}
                            Mode={"serviceAddCart"}
                            userContext={SiteData}
                            service={ServiceData}
                            IsShowMap={true}
                        />,
                        document.getElementById("OpenDialogMultiLocation")
                    );
                }
                else {
                    ServiceAddCart(encBID);
                }
            }
            else {
                if (IsCallback) {
                    ServiceData.encServiceId = CallBackData.SID;
                    ServiceData.promotionDetail = "";
                    ServiceData.promotionTitle = "";
                    ServiceData.serviceDesc = "";
                }
                ServiceAddCart(encBID);
                return;
            }
        }
    }
    return <>{isContentLoading && < Loader />}</>;
}

export default UseToAddCart;
