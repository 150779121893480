import React from "react";
import ReactDOM from "react-dom";

export default function CustomToast(message: string, clsName:string) {
    let toastRoot = document.getElementById("toast-root");
    if (!toastRoot)
        return;

    ReactDOM.render(<RenderCustomToast message={message} clsName={clsName} />, toastRoot);
    // setTimeout(() => {
    //     if (toastRoot)
    //         ReactDOM.unmountComponentAtNode(toastRoot);

    // }, 3000);
}

function RenderCustomToast({ message, clsName }: { message: string, clsName:string }) {
    return <>
        <div className={clsName}>
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" fill="white"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" /></svg>
            {message}
        </div>
    </>
}