import { configureStore, getDefaultMiddleware, combineReducers } from '@reduxjs/toolkit'
import useTokenReducer from '../Slice/Token'
import useBusinessLocationReducer from '../Slice/BusinessLocation'
import useMySite from '../Slice/MySite'

import storage from 'redux-persist/lib/storage'
import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist'

const persistConfig = {
    key: 'root',
    storage: storage
}
export const rootReducers = combineReducers({
    Token: useTokenReducer,
    BusinessLocation: useBusinessLocationReducer,
    MySite: useMySite
})
const persistedReducer = persistReducer(persistConfig, rootReducers)
const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    }),
})
export default store