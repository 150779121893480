import React from "react";
import './loader.scss';

const Loader = (props) => {
  if (props.isgrid) {
    return (
      <div className="ingridLoading block-edit"><div className="loaderNew"><div className="loader-imgNew"></div></div></div>
    );
  }
  else {
    return (
      <div className="loaderNew block-edit"><div className="loader-imgNew"></div></div>
    );
  }

  
};
export default Loader;