import { UserContext } from "../Definitions/UserContext";

import LayoutNode from "../Definitions/LayoutNode";
import { AsyncAjaxGet, AsyncAjaxPatch, AsyncAjaxPost } from "./AsyncAjaxService";
import { utils } from "../../utils/Utils";

const VagaroAPIEnum = {
    getMerchantRoot: "merchants/",
    getBusinessHours: "/workinghours",
    getSocialLinks: "/sociallink",
    getServices: "/services",
    getServiceCategories: "/services/servicecategory",
    getClassCategories: "/classes/classcategory",
    getFacility: "/facilities",
    getReviews: "/reviews",
    getStaff: "/staffs",
    getProducts: "/products",
    getProductTypes: "/products/producttype",
    getProductBrands: "/products/brand",
    getPhotos: "/photos",
    getDailyDeals: "/dailydeals",
    GetGiftCertificates: "/giftcards",
    getBusinessMap: "/maps",
    getBusinessMemberships: "/memberships",
    getBusinessPackages: "/packages",
    getReviewPercentage: "/reviews/reviewpercentage",
    getProductDetails: "/products/",
    getProductBundleDetails: "/products/bundle/",
    getCartCount: "/addtocart/getcartcount",
    getPromoCodes: "/promotions/discount",
    updateToken: "Token/generatetoken",
    getForms: "/form",
    getFormList: "/form/list",
    getBusinessLogo: "/businesslogo",
    getBusinessLocation: "/getdistance",
    getBusinessName: "/businessname",
};


async function AsyncGetBusinessInformation(siteData: UserContext, url: string, onComplete?: Function, onFailure?: Function) {

    await ValidateToken(siteData);

    await AsyncAjaxGet(
        url,
        siteData.accessToken,
        function onSuccess(data: any) {
            if (onComplete) {
                const newData = data?.data?.data ?? null;
                onComplete(newData);
            }
        },
        function OnError(data: any) {
            if (onFailure) {
                onFailure(data);
            }

        }
    );
}


async function AsyncPostLocation(siteData: UserContext, latitude: number, longitude: number, url: string, onComplete?: Function, onFailure?: Function) {

    await ValidateToken(siteData);

    const requestdata: any = {};
    requestdata.latitude = utils.isNotEmptyOrWhiteSpace(latitude) ? latitude : 0;
    requestdata.longitude = utils.isNotEmptyOrWhiteSpace(longitude) ? longitude : 0;

    await AsyncAjaxPost(
        url,
        requestdata,
        siteData.accessToken,
        function onSuccess(data: any) {
            if (onComplete) {
                const newData = data?.data?.data ?? null;
                onComplete(newData);
            }
        },
        function OnError(data: any) {
            if (onFailure) {
                onFailure(data);
            }

        }
    );
}

export async function GetPromoCodes(userContext: UserContext, onComplete?: Function, onFailure?: Function) {
    await ValidateToken(userContext);
    const url = userContext.businessId + VagaroAPIEnum.getPromoCodes;
    await AsyncGetBusinessInformation(userContext, url, onComplete, onFailure);
}

export async function GetProductDetails(userContext: UserContext, productId: string, onComplete?: Function, onFailure?: Function) {

    await ValidateToken(userContext);
    const url = VagaroAPIEnum.getMerchantRoot + userContext.businessId + VagaroAPIEnum.getProductDetails + productId;
    await AsyncGetBusinessInformation(userContext, url, onComplete, onFailure);
}
export async function GetProductBundleDetails(userContext: UserContext, productId: string, onComplete?: Function, onFailure?: Function) {

    await ValidateToken(userContext);
    const url = VagaroAPIEnum.getMerchantRoot + userContext.businessId + VagaroAPIEnum.getProductBundleDetails + productId;
    await AsyncGetBusinessInformation(userContext, url, onComplete, onFailure);
}

async function CommonReponse(siteData: UserContext, onComplete?: Function, onFailure?: Function) {
    if (onComplete) {
        onComplete([]);
    }
}

async function GetBusinessServices(userContext: UserContext, onComplete?: Function, onFailure?: Function) {
    await ValidateToken(userContext);
    var IsEditor = (window as any).isEditor !== undefined ? (window as any).isEditor : false;
    const url = VagaroAPIEnum.getMerchantRoot + userContext.businessId + VagaroAPIEnum.getServices + "?IsEditor=" + IsEditor;
    await AsyncGetBusinessInformation(userContext, url, onComplete, onFailure);
}

async function GetBusinessServiceCategories(userContext: UserContext, businessId: string, onComplete?: Function, onFailure?: Function) {
    await ValidateToken(userContext);

    const url = VagaroAPIEnum.getMerchantRoot + businessId + VagaroAPIEnum.getServiceCategories;
    await AsyncGetBusinessInformation(userContext, url, onComplete, onFailure);
}


async function GetBusinessClassCategories(userContext: UserContext, businessId: string, onComplete?: Function, onFailure?: Function) {
    await ValidateToken(userContext);

    const url = VagaroAPIEnum.getMerchantRoot + businessId + VagaroAPIEnum.getClassCategories;
    await AsyncGetBusinessInformation(userContext, url, onComplete, onFailure);
}

export async function GetBusinessMap(userContext: UserContext, onComplete?: Function, onFailure?: Function) {
    await ValidateToken(userContext);
    const url = VagaroAPIEnum.getMerchantRoot + userContext.businessId + VagaroAPIEnum.getBusinessMap;
    await AsyncGetBusinessInformation(userContext, url, onComplete, onFailure);
}

export async function getProductBrands(userContext: UserContext, onComplete?: Function, onFailure?: Function, filter: string[] = []) {
    await ValidateToken(userContext);
    const url = VagaroAPIEnum.getMerchantRoot + userContext.businessId + VagaroAPIEnum.getProductBrands;
    const content = {
        "BrandlistFilter": filter
    }


    await AsyncAjaxPost(url, JSON.stringify(content), userContext.accessToken, onComplete, onFailure);
}

export async function getProductTypes(userContext: UserContext, onComplete?: Function, onFailure?: Function) {
    await ValidateToken(userContext);
    const url = VagaroAPIEnum.getMerchantRoot + userContext.businessId + VagaroAPIEnum.getProductTypes;
    await AsyncGetBusinessInformation(userContext, url, onComplete, onFailure);
}

export async function ValidateToken(userContext: UserContext) {

    let expire = (userContext.tokenExpiresAt ?? 0) - 1000;

    let current = Date.now();

    // console.log("Validating...",userContext);
    // console.log(new Date(expire??0)," vs current:",new Date(current));

    if (!expire || current >= expire) {

        await UpdateAccessToken(userContext);

    }



    return true;
}

export async function GetBusinessLogoURL(siteData: UserContext, onComplete?: (data: any) => void, onFailure?: (data: any) => void) {




    await ValidateToken(siteData);

    const rootURL = "merchants/" + siteData.businessId + VagaroAPIEnum.getBusinessLogo;
    await AsyncAjaxGet(
        rootURL,
        siteData.accessToken,
        function onSuccess(data: any) {

            let imgPath = data?.data?.data?.imagePath ?? "";

            if (onComplete) {
                onComplete(imgPath);
            }
        },
        function OnError(data: any) {

            if (onFailure) {
                onFailure("");
            }
        }
    );
}

export async function UpdateAccessToken(userContext: UserContext) {
    const url = VagaroAPIEnum.updateToken;
    let timeout = (window as any).vwbaccesstimeout;
    if (timeout && Date.now() < timeout) {
        return;
    }

    (window as any).vwbaccesstimeout = Date.now() + 10000;

    await AsyncAjaxPost(
        url,
        "",
        userContext.accessToken,
        function onSuccess(data: any) {

            const token = data?.data?.access_token;
            const expiresIn = data?.data?.expires_in;
            if (token && expiresIn) {
                userContext.accessToken = token;
                const newDate = new Date();
                userContext.tokenExpiresAt = newDate.getTime() + (expiresIn / 2) * 1000;
            }
            else {
                console.warn("Failed to update access token");
            }
        },
        function OnError(data: any) {
            console.warn("Failed to update access token", data);
        }

    )

}


export async function GetBusinessProducts(userContext: UserContext, onComplete?: Function, onFailure?: Function, pageNumber = 1, pageSize = 50, typeFilters: string[] = [], brandFilters: string[] = [], searchText = "") {
    var IsEditor = (window as any).isEditor !== undefined ? (window as any).isEditor : false;
    const url = VagaroAPIEnum.getMerchantRoot + userContext.businessId + VagaroAPIEnum.getProducts + "?pageNumber=" + pageNumber + "&pageSize=" + pageSize + "&IsEditor=" + IsEditor;
    await ValidateToken(userContext);
    const body = {
        "SearchText": searchText,
        "Brand": brandFilters,
        "ProductType": typeFilters,

    }
    await AsyncAjaxPost(
        url,
        JSON.stringify(body),
        userContext.accessToken,
        function onSuccess(data: any) {
            if (onComplete) {
                const newData = data?.data?.data ?? null;
                onComplete(newData);
            }
        },
        function OnError(data: any) {
            if (onFailure) {
                onFailure(data);
            }

        }
    );
}

export async function GetGiftCertificates(userContext: UserContext, onComplete?: Function, onFailure?: Function) {
    await ValidateToken(userContext);
    var IsEditor = (window as any).isEditor !== undefined ? (window as any).isEditor : false;
    const url = VagaroAPIEnum.getMerchantRoot + userContext.businessId + VagaroAPIEnum.GetGiftCertificates + "?IsEditor=" + IsEditor;
    await AsyncGetBusinessInformation(userContext, url, onComplete, onFailure);
}

export async function getDailyDeals(userContext: UserContext, onComplete?: Function, onFailure?: Function) {
    await ValidateToken(userContext);
    const url = VagaroAPIEnum.getMerchantRoot + userContext.businessId + VagaroAPIEnum.getDailyDeals;
    await AsyncGetBusinessInformation(userContext, url, onComplete, onFailure);
}

export async function GetBusinessGallery(userContext: UserContext, onComplete?: Function, onFailure?: Function, pageNumber = 1, pageSize = 500) {
    await ValidateToken(userContext);
    var url = VagaroAPIEnum.getMerchantRoot + userContext.businessId + VagaroAPIEnum.getPhotos + "?pageNumber=" + pageNumber + "&pageSize=" + pageSize;
    var LoginDetails = utils.ReadCookie();
    if (LoginDetails.EncUid !== undefined && LoginDetails.EncUid !== "" && LoginDetails.EncUid !== null && LoginDetails.EncUid !== 0) {
        url = VagaroAPIEnum.getMerchantRoot + userContext.businessId + VagaroAPIEnum.getPhotos + "?EncUserID=" + LoginDetails.EncUid + "&pageNumber=" + pageNumber + "&pageSize=" + pageSize;
    }
    await AsyncGetBusinessInformation(userContext, url, onComplete, onFailure);
}

export async function GetBusinessDetails(siteData: UserContext, onComplete?: (data: any) => void, onFailure?: (data: any) => void) {
    await ValidateToken(siteData);
    const url = VagaroAPIEnum.getMerchantRoot + siteData.businessId
    await AsyncGetBusinessInformation(siteData, url, onComplete, onFailure);
}



export async function GetBusienssHours(siteData: UserContext, onComplete?: (data: any) => void, onFailure?: (data: any) => void) {
    await ValidateToken(siteData);
    const url = VagaroAPIEnum.getMerchantRoot + siteData.businessId + VagaroAPIEnum.getBusinessHours;
    await AsyncGetBusinessInformation(siteData, url, onComplete, onFailure);
}

export async function GetFacilityInformation(siteData: UserContext, onComplete?: (data: any) => void, onFailure?: (data: any) => void) {
    await ValidateToken(siteData);
    const url = VagaroAPIEnum.getMerchantRoot + siteData.businessId + VagaroAPIEnum.getFacility;
    await AsyncGetBusinessInformation(siteData, url, onComplete, onFailure);
}

export async function GetBusinessSocialLinks(siteData: UserContext, onComplete?: (data: any) => void, onFailure?: (data: any) => void) {
    await ValidateToken(siteData);
    const url = VagaroAPIEnum.getMerchantRoot + siteData.businessId + VagaroAPIEnum.getSocialLinks;
    await AsyncGetBusinessInformation(siteData, url, onComplete, onFailure);
}

export async function GetBusinessReviews(siteData: UserContext, onComplete?: (data: any) => void, onFailure?: (data: any) => void, pageSize = 20, page = 1, sort = "1", type = "1", SPID = "U2FsdGVkX18EI4k80FtcVtq4PzxA94OPqaGqyI1vxNM=") {
    await ValidateToken(siteData);
    var url;
    if (SPID != "U2FsdGVkX18EI4k80FtcVtq4PzxA94OPqaGqyI1vxNM=") {
        url = VagaroAPIEnum.getMerchantRoot + siteData.businessId + VagaroAPIEnum.getReviews + "?pageSize=" + pageSize + "&pageNumber=" + page + "&filterType=" + type + "&sortType=" + sort + "&SPID=" + SPID;
    }
    else {
        url = VagaroAPIEnum.getMerchantRoot + siteData.businessId + VagaroAPIEnum.getReviews + "?pageSize=" + pageSize + "&pageNumber=" + page + "&filterType=" + type + "&sortType=" + sort;
    }
    await AsyncGetBusinessInformation(siteData, url, onComplete, onFailure);
}

export async function GetBusinessStaff(siteData: UserContext, onComplete?: (data: any) => void, onFailure?: (data: any) => void) {
    await ValidateToken(siteData);
    const url = VagaroAPIEnum.getMerchantRoot + siteData.businessId + VagaroAPIEnum.getStaff;
    await AsyncGetBusinessInformation(siteData, url, onComplete, onFailure);
}

export async function GetBusinessMemberships(userContext: UserContext, onComplete?: Function, onFailure?: Function) {
    await ValidateToken(userContext);
    var IsEditor = (window as any).isEditor !== undefined ? (window as any).isEditor : false;
    const url = VagaroAPIEnum.getMerchantRoot + userContext.businessId + VagaroAPIEnum.getBusinessMemberships + "?IsEditor=" + IsEditor;
    await AsyncGetBusinessInformation(userContext, url, onComplete, onFailure);
}


export async function GetBusinessLocation(userContext: UserContext, onComplete?: Function, onFailure?: Function) {
    await ValidateToken(userContext);
    const url = VagaroAPIEnum.getMerchantRoot + userContext.sitebid + VagaroAPIEnum.getBusinessLocation;
    if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(async function (position) {
            var latitude = position.coords.latitude;
            var longitude = position.coords.longitude;
            await AsyncPostLocation(userContext, latitude, longitude, url, onComplete, onFailure);
        }, async function (error) {
            await AsyncPostLocation(userContext, 0, 0, url, onComplete, onFailure);
        });
    }
    else {
        await AsyncPostLocation(userContext, 0, 0, url, onComplete, onFailure);
    }
}


export async function GetBusinessName(userContext: UserContext, onComplete?: Function, onFailure?: Function) {
    await ValidateToken(userContext);
    var IsEditor = (window as any).isEditor !== undefined ? (window as any).isEditor : false;
    const url = VagaroAPIEnum.getMerchantRoot + userContext.businessId + VagaroAPIEnum.getBusinessName + "?IsEditor=" + IsEditor;
    await AsyncGetBusinessInformation(userContext, url, onComplete, onFailure);
}

export async function GetBusinessPackages(userContext: UserContext, onComplete?: Function, onFailure?: Function) {
    await ValidateToken(userContext);
    var IsEditor = (window as any).isEditor !== undefined ? (window as any).isEditor : false;
    const url = VagaroAPIEnum.getMerchantRoot + userContext.businessId + VagaroAPIEnum.getBusinessPackages + "?IsEditor=" + IsEditor;
    await AsyncGetBusinessInformation(userContext, url, onComplete, onFailure);
}

export async function GetBusinessReviewPecentage(userContext: UserContext, onComplete?: Function, onFailure?: Function) {
    await ValidateToken(userContext);
    const url = VagaroAPIEnum.getMerchantRoot + userContext.businessId + VagaroAPIEnum.getReviewPercentage;
    await AsyncGetBusinessInformation(userContext, url, onComplete, onFailure);
}

export async function GetBusinessCart(userContext: UserContext, onComplete?: Function, onFailure?: Function) {
    await ValidateToken(userContext);
    var LoginDetails = utils.ReadCookie();
    if (LoginDetails.EncUid !== undefined && LoginDetails.EncUid !== "" && LoginDetails.EncUid !== null) {
        const url = userContext.businessId + VagaroAPIEnum.getCartCount + "?EncUid=" + LoginDetails.EncUid;
        await AsyncGetBusinessInformation(userContext, url, onComplete, onFailure);
    }
    else {
        if (onComplete) {
            onComplete("0");
        }
    }

}

export async function GetBusienssForm(siteData: UserContext, onComplete?: (data: any) => void, onFailure?: (data: any) => void) {
    await ValidateToken(siteData);
    const url = VagaroAPIEnum.getMerchantRoot + siteData.businessId + VagaroAPIEnum.getForms + "/" + (siteData.formid == "1" ? "659fc093145116743f6fd103" : (utils.isNotEmptyOrWhiteSpace(siteData.formid) ? siteData.formid : "659fc093145116743f6fd103"));
    await AsyncGetBusinessInformation(siteData, url, onComplete, onFailure);
}

export async function GetBusinessFormList(siteData: UserContext, location: string, onComplete?: (data: any) => void, onFailure?: (data: any) => void) {

    await ValidateToken(siteData);
    const url = VagaroAPIEnum.getMerchantRoot + location + VagaroAPIEnum.getFormList;
    await AsyncGetBusinessInformation(siteData, url, onComplete, onFailure);

}

export async function GetCommonReponse(userContext: UserContext, onComplete?: Function, onFailure?: Function) {
    await ValidateToken(userContext);
    await CommonReponse(userContext, onComplete, onFailure);
}

export { GetBusinessServices, GetBusinessServiceCategories, GetBusinessClassCategories };