import VGPopUp from '../components/Controls/VGPopUp';
import { Fragment } from "react";
import ReactDOM, { unmountComponentAtNode } from "react-dom";
import $ from "jquery";

const ResponseCode = {
  Success: 1000,
  ResponseFail: 1001,
  RecordExists: 1002,
  PartialAuthorize: 5008,
  NoRecordFound: 1032,
  Fail: 60000
}


const Site = {
    SiteName: "https://dev24.bookitall.com/"
}

const Form = {
  URL: "https://dev21forms.bookitall.com/"
}


const MySite = {
  SiteName: "https://mysite.bookitall.com"
}


const utils = {
  FormatPrice: (val, CurrencySymbol) => {
    let value = parseFloat(val);
    if (typeof val != undefined && val != null && val != "") {
      if (val < 0) {
        return (
          "(" +
          CurrencySymbol +
          utils.getPositiveValue(value).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
          +
          ")"
        );
      } else {
        return (
          CurrencySymbol +
          value.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        );
      }
    } else {
      return CurrencySymbol + "0.00";
    }
  },
  Validate: (value) => {
    if (typeof value != undefined && value != null && value != "" && value != 'undefined') {
      return true;
    }
    else {
      return false;
    }
  },
  FormatValue: (val) => {
    return ((val).toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }))
  },
  isNullEmptyOrWhiteSpace: function (val) {
    if (typeof (val) == "number") {
      val = val.toString();
    }
    return (val === null || val === undefined || val === "" || val.trim() === "");
  },
  isNotEmptyOrWhiteSpace: function (val) {
    return (val != undefined && val != null && val != "");
  },
  isCheckEmptyOrWhiteSpace: function (val) {
    return (val == undefined || val == null || val == "");
  },
  replaceNullOrUndefined: function (val) {
    return (val == null || val == undefined) ? "" : val;
  },
    ReadCookie: function (dbcall) {
        let decodedCookie = decodeURIComponent(document.cookie);
        let name = "";
        let value = "";
        let cookiearray = decodedCookie.split(';');
        for (var i = 0; i < cookiearray.length; i++) {
            name = cookiearray[i].split(/=(.*)/s)[0];
            if (name.trim() == "WebsiteBuilder") {
                if (dbcall === true) {
                    return value = JSON.parse(cookiearray[i].split(/=(.*)/s)[1]).LoginUserID;
                }
                value = JSON.parse(cookiearray[i].split(/=(.*)/s)[1]);
            }
        }
        return value
    },
  MultiLocationData: function (data, businessid) {
    var MultiLocationGroup = $.grep(data.MultilocationData, function (LocationType) {
      return (LocationType.encBIDHash == businessid);
    });
    if (MultiLocationGroup.length > 0) {
      return MultiLocationGroup[0];
    }
    else {
      return [];
    }
  },
  MultiLocationDataPhone: function (data, businessid, IsEditor) {
    if (IsEditor == false && data.MultilocationData.length > 1) {
      var MultiLocationGroup = $.grep(data.MultilocationData, function (LocationType) {
        return (LocationType.encBusinessID == businessid);
      });
      if (MultiLocationGroup.length > 0) {
        return MultiLocationGroup[0];
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  },
  MultiLocationBId: function (data, vagaroURL) {
    var MultiLocationGroup = $.grep(data, function (LocationType) {
      return (LocationType.vagaroURL == vagaroURL);
    });
    if (MultiLocationGroup.length > 0) {
      return MultiLocationGroup[0].encBID;
    }
    else {
      return [];
    }
  },
  MultiLocationBName: function (data, vagaroURL) {
    var MultiLocationGroup = $.grep(data, function (LocationType) {
      return (LocationType.vagaroURL == vagaroURL);
    });
    if (MultiLocationGroup.length > 0) {
      return MultiLocationGroup[0].businessName + " (" +  MultiLocationGroup[0].city + ")";
    }
    else {
      return "";
    }
  },
  MultiLocationBAddress: function (data, vagaroURL) {
    var MultiLocationGroup = $.grep(data, function (LocationType) {
      return (LocationType.vagaroURL == vagaroURL);
    });
    if (MultiLocationGroup.length > 0) {
      return MultiLocationGroup[0].street + ", " + MultiLocationGroup[0].city + ", " + MultiLocationGroup[0].stateCode  + " " + MultiLocationGroup[0].zip;
    }
    else {
      return "";
    }
  },
  Loaderstart : function(){
    $(".wbloader").css("display", "block");
    document.documentElement.style.setProperty("--wbc-primary-button-bg", "#333");
    document.documentElement.style.setProperty("--wbc-loader-alpha", "rgba(43,43,43,0.7)");
  },
  setLetter: function (firstName, lastName) {
    let firstNameHasNumber = /^[0-9]+$/.test(firstName.charAt(0))
    let lastNameHasNumber = /^[0-9]+$/.test(lastName.charAt(0))
    let firstNameSpecialCharacter = /^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/.test(firstName.charAt(0))
    let lastNameSpecialCharacter = /^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/.test(lastName.charAt(0))
    if ((firstName.charAt(0) === firstName.charAt(0).toUpperCase() && lastName.charAt(0) === lastName.charAt(0).toUpperCase()) && (firstNameHasNumber !== true && lastNameHasNumber !== true) && (firstNameSpecialCharacter !== true && lastNameSpecialCharacter !== true)) {
      return (
        <Fragment>
          <div className="review-profile-inner-text vg-avatar-inital-inner-text">
            {firstName.charAt(0).toUpperCase()}
            {lastName.charAt(0).toUpperCase()}
          </div>
        </Fragment>
      );
    } else if (firstName.charAt(0) === firstName.charAt(0).toLowerCase() && lastName.charAt(0) === lastName.charAt(0).toLowerCase()) {
      return (
        <Fragment>
          <div className="review-profile-inner-text vg-avatar-inital-inner-text">
            {firstName.charAt(0).toUpperCase()}
          </div>
        </Fragment>
      );
    } else if (firstName.charAt(0) === firstName.charAt(0).toUpperCase() && lastName.charAt(0) === lastName.charAt(0).toLowerCase()) {
      return (
        <Fragment>
          <div className="review-profile-inner-text vg-avatar-inital-inner-text">
            {firstName.charAt(0).toUpperCase()}
          </div>
        </Fragment>
      );
    } else if (firstName.charAt(0) === firstName.charAt(0).toLowerCase() && lastName.charAt(0) === lastName.charAt(0).toUpperCase()) {
      return (
        <Fragment>
          <div className="review-profile-inner-text vg-avatar-inital-inner-text">
            {lastName.charAt(0).toUpperCase()}
          </div>
        </Fragment>
      );
    }
    else if ((firstName.charAt(0) === firstName.charAt(0).toUpperCase() || firstName.charAt(0) === firstName.charAt(0).toLowerCase()) && lastNameHasNumber === true) {
      <Fragment>
        <div className="review-profile-inner-text vg-avatar-inital-inner-text">
          {firstName.charAt(0).toUpperCase()}
        </div>
      </Fragment>
    } else if ((lastName.charAt(0) === lastName.charAt(0).toUpperCase() || lastName.charAt(0) === lastName.charAt(0).toLowerCase()) && firstNameHasNumber === true) {
      <Fragment>
        <div className="review-profile-inner-text vg-avatar-inital-inner-text">
          {lastName.charAt(0).toUpperCase()}
        </div>
      </Fragment>
    }
    else if ((firstName.charAt(0) === firstName.charAt(0).toUpperCase() || firstName.charAt(0) === firstName.charAt(0).toLowerCase()) && lastNameSpecialCharacter === true) {
      <Fragment>
        <div className="review-profile-inner-text vg-avatar-inital-inner-text">
          {firstName.charAt(0).toUpperCase()}
        </div>
      </Fragment>
    } else if ((lastName.charAt(0) === lastName.charAt(0).toUpperCase() || lastName.charAt(0) === lastName.charAt(0).toLowerCase()) && firstNameSpecialCharacter === true) {
      <Fragment>
        <div className="review-profile-inner-text vg-avatar-inital-inner-text">
          {lastName.charAt(0).toUpperCase()}
        </div>
      </Fragment>
    }
    else if (firstNameHasNumber === true && lastNameHasNumber === true) {
      return (
        <Fragment>
          <div className="review-profile-inner-text vg-avatar-inital-inner-text">
            {firstName.charAt(0)}
          </div>
        </Fragment>
      );
    } else if (firstNameSpecialCharacter === true && lastNameSpecialCharacter === true) {
      return (
        <Fragment>
          <div className="review-profile-inner-text vg-avatar-inital-inner-text">
            {firstName.charAt(0)}
          </div>
        </Fragment>
      );
    }
  },
  formatPhoneNumber: (phoneNumber) => {
    phoneNumber = String(phoneNumber).replace(/\D/g, '');
    const formattedNumber = phoneNumber.replace(
      /^(\d{3})(\d{3})(\d{4})$/,
      '($1) $2-$3'
    );
    return formattedNumber;
  },
  ShowAlert: (title, body, OpenDialog) => {
    const AlertBody = (body) => {
      return (
        <div>
          {body}
        </div>
      )
    }
    const AlertFooter = () => {
      return (
        <Fragment>
          <button className="v-button primary invisible-button w-25" onClick={() => AlertClose()}>OK</button>
        </Fragment >
      )
    }
    const AlertClose = () => {
      unmountComponentAtNode(document.getElementById(OpenDialog));
    };
    return ReactDOM.render(
      <VGPopUp
        id={"Alert"}
        show={true}
        onHide={AlertClose}
        title={title}
        dialogClassName="vgn-popup vgn-modal-alert vgn-modal-small"
        body={AlertBody(body)}
        footer={AlertFooter()}
        isRemoveTabModalIndex={false}
        isShowFooter={true}
        backdrop={true}
      />,
      document.getElementById(OpenDialog)
    );
  },
  VURL: (siteName, VagaroURL, pageName) => {
    var URL = Site.SiteName;
    if (siteName !== null) {
      return URL + "login?Type=Builder&Event="

    } else if (pageName === "myaccount") {
      return URL + "myaccount?Type=Builder&Event=MyAccount";
    }
    else if (pageName === "vagaropaylater") {
        return URL + "myaccount/vagaropaylater?Type=Builder&Event=MyAccount";
    }
    else if (pageName === "facebookshare") {
      return URL + VagaroURL ;
    }
    else {
      return URL + VagaroURL + "/book-now?Type=Builder&Event="
    }
  },
  VURLClasses: (VagaroURL) => {
    return Site.SiteName + VagaroURL + "/classes?Type=Builder&Event="
  },
  SiteName: () => {
    return Site.SiteName
  },
  MySiteName: () => {
    return MySite.SiteName
  },
  FormURL: () => {
    return Form.URL
  },
  CountryTelephone: (TelNo, countryId, isUkNewFormat = false) => {
    try {
      let nVal = 0; let numberLength = 0;
      if (!TelNo || TelNo.trim() === "") {
        return TelNo;
      }
      TelNo = TelNo.replace(/[^\d]/g, ""); numberLength = TelNo.length;

      if (parseInt(TelNo, nVal)) {
        if (countryId === 1) {
          TelNo = `(${TelNo.substring(0, 3)}) ${TelNo.substring(3, 6)}-${TelNo.substring(6)}`;
        } else if (countryId === 2) {
          if (numberLength === 10) {
            TelNo = "0" + TelNo;
          }
          if (isUkNewFormat) {
            if (TelNo.substring(0, 2) === "01") {
              TelNo = `${TelNo.substring(0, 5)} ${TelNo.substring(5)}`;
            } else if (TelNo.substring(0, 2) === "02") {
              TelNo = `${TelNo.substring(0, 3)} ${TelNo.substring(3, 7)} ${TelNo.substring(7)}`;
            } else if (TelNo.substring(0, 2) === "07") {
              TelNo = `${TelNo.substring(0, 5)} ${TelNo.substring(5, 8)} ${TelNo.substring(8)}`;
            }
          } else {
            TelNo = `${TelNo.substring(0, 3)} ${TelNo.substring(3, 7)} ${TelNo.substring(7)}`;
          }
        } else if (countryId === 3) {
          TelNo = `${TelNo.substring(0, 3)}-${TelNo.substring(3, 6)}-${TelNo.substring(6)}`;
        } else if (countryId === 4) {
          if (numberLength === 9) {
            TelNo = "0" + TelNo;
          }
          TelNo = `(${TelNo.substring(0, 2)}) ${TelNo.substring(2, 6)} ${TelNo.substring(6)}`;
        }
        else {
          TelNo = `(${TelNo.substring(0, 2)}) ${TelNo.substring(2, 6)}-${TelNo.substring(6)}`;
        }
        return TelNo;
      } else {
        return TelNo;
      }
    } catch (error) {
      return TelNo;
    }
  },  
  IsFromProApp: () => {    
    if (navigator != null && navigator.userAgent != null && navigator.userAgent != "") {
      if (navigator.userAgent.indexOf("VagaroAndroidPhone") > -1 || (navigator.userAgent.indexOf("com.vagaro.iospro") > -1 || navigator.userAgent.indexOf("iPhone") > -1)) {        
        return true;
      }
      else {        
        return false;
      }
    }
    else {
      return false;
    }
  }
};

const addtoCartEnum = {
  ServiceAddToCart: "ServiceAddToCart",
  ServiceBook: "ServiceBook",
  StaffServiceBook: "StaffServiceBook",
  DealBookNow: "DealBookNow",
  BookNowPortfolio: "BookNowPortfolio",
  ProductAddToCart: "ProductAddToCart",
  MemebrshipAddToCart: "MemebrshipAddToCart",
  PackageAddToCart: "PackageAddToCart",
  PurchaseGC: "PurchaseGC",
  GCAddToCart: "GCAddToCart",
  Bookmarked: "Bookmarked",
  WriteReview: "WriteReview",
  StaffClassBook: "StaffClassBook",
  StaffMessagae: "StaffMessagae",
  SendMailMessage: "SendMailMessage",
  Cart: "Cart",
  GalleryLike: "GalleryLike"

}


export { Site, utils, ResponseCode, addtoCartEnum }

