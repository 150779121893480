import React, { ReactNode } from "react"
import { NoRecordsBanner } from "../OopsBanner";
import { BlogIcon, FacebookIcon, GooglePlusIcon, InstagramIcon, LinkedinIcon, PintrestIcon, MySpaceIcon, TikTokIcon, TwitterIcon, YelpIcon, YoutubeIcon, VagaroIcon } from "../Icons/SocialIcons";
import { UserContext } from "../Definitions/UserContext";

export default function RenderBusinessSocialLinks({bData,layout}:{bData:any,layout:string}){

switch(layout){

    default:
        return <RenderItems bData={bData} layout={layout} />
}

}


function RenderItems({bData,layout}:{bData:any,layout:string}){

    const isCircle = true;// layout === "1";
    

    if(bData?.isStenciled){
        return <div className="stenciled center-all" style={{display:"flex",gap:"inherit"}}     >
            <div className="stencil-item" style={{width:"1.8em",height:"1.8em",borderRadius:"50%"}}> </div>
            <div className="stencil-item" style={{width:"1.8em",height:"1.8em",borderRadius:"50%"}}> </div>
            <div className="stencil-item" style={{width:"1.8em",height:"1.8em",borderRadius:"50%"}}> </div>
            <div className="stencil-item" style={{width:"1.8em",height:"1.8em",borderRadius:"50%"}}> </div>
            </div>

    }

    if(!bData.Vagaro&&!bData.Blog&&!bData.Facebook&&!bData.GooglePlus&&!bData.Instagram&&!bData.Linkedin&&!bData.Pintrest&&!bData.MySpace&&!bData.TikTok&&!bData.Twitter&&!bData.Yelp&&!bData.Youtube){
        return <NoRecordsBanner message="No Social Links Found" />
    }



    return <>
   
    {bData.Blog         && bData.Blog.length>0          &&  <RenderSocialItem link={bData.Blog}      isCircle={isCircle}      icon={<BlogIcon/>}/>}
    {bData.Facebook     && bData.Facebook.length>0      && <RenderSocialItem link={bData.Facebook}   isCircle={isCircle}      icon={<FacebookIcon/>}/>}
    {bData.GooglePlus   && bData.GooglePlus.length>0    && <RenderSocialItem link={bData.GooglePlus} isCircle={isCircle}      icon={<GooglePlusIcon/>}/>}
    {bData.Instagram    && bData.Instagram.length>0     && <RenderSocialItem link={bData.Instagram}  isCircle={isCircle}      icon={<InstagramIcon/>}/>}
    {bData.Linkedin     && bData.Linkedin.length>0      && <RenderSocialItem link={bData.Linkedin}   isCircle={isCircle}      icon={<LinkedinIcon/>}/>}
    {bData.Pintrest     && bData.Pintrest.length>0      && <RenderSocialItem link={bData.Pintrest}   isCircle={isCircle}      icon={<PintrestIcon/>}/>}
    {bData.MySpace      && bData.MySpace.length>0       && <RenderSocialItem link={bData.MySpace}    isCircle={isCircle}      icon={<MySpaceIcon/>}/>}
    {bData.TikTok       && bData.TikTok.length>0        && <RenderSocialItem link={bData.TikTok}     isCircle={isCircle}      icon={<TikTokIcon/>}/>}
    {bData.Twitter      && bData.Twitter.length>0       && <RenderSocialItem link={bData.Twitter}    isCircle={isCircle}      icon={<TwitterIcon/>}/>}
    {bData.Yelp         && bData.Yelp.length>0          && <RenderSocialItem link={bData.Yelp}       isCircle={isCircle}      icon={<YelpIcon/>}/>}
    {bData.Youtube      && bData.Youtube.length>0       && <RenderSocialItem link={bData.Youtube}    isCircle={isCircle}      icon={<YoutubeIcon/>}/>}
    {bData.Vagaro       && bData.Vagaro.length>0        &&<RenderSocialItem link={bData.Vagaro}      isCircle={isCircle}      icon={<VagaroIcon/>}/>}

    </>

}









function RenderSocialItem({link,icon,isCircle}:{link:string,icon:ReactNode,isCircle:boolean}): JSX.Element {

  

    if(!link.includes("http")){

        if(!link.includes("www.")){
            link = "www."+link;
        }

        link = "https://"+link;
    }
    

return <div className="center-all social-icon" style={{width:"1.8em",height:"1.8em",borderRadius:"100%",padding:".4em",background:"transparent"}}>
    <a className="center-all max-100 " target="_blank" href = {link}  style={{color:"inherit"}} >
       {icon}
    </a>
</div>

}


