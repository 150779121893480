import React, { Fragment, useEffect, useRef, useState } from "react";
import VPLIcon from "../Icons/VPLIcon";
import { FacebookIcon, PintrestIcon, TwitterIcon } from "../Icons/SocialIcons";

import { UserContext } from "../Definitions/UserContext";
import OopsBanner, { NoRecordsBanner } from "../OopsBanner";
import { addtoCartEnum, utils } from "../../utils/Utils";
import { AsyncAjaxPost } from "../Utilities/AsyncAjaxService";
import Loader from "../Common/Loader";
import $ from "jquery";
import ReactDOM, { unmountComponentAtNode } from "react-dom";
import VGPopUp from "../Controls/VGPopUp";
import Toast from "../Utilities/Toast";
import RenderBusinessCart from "./RenderBusinessCart";

export default function RenderProductDetails({ userContext, data }: { userContext: UserContext, data: any}) {

    if (!data.imagePath) {
        data.imagePath = "";
    }

    const imgs = [];

    if (data && data.imageUrl1) {
        imgs.push(data.imageUrl1);

    }
    if (data && data.imageUrl2) {
        imgs.push(data.imageUrl2);

    }
    if (data && data.imageUrl3) {
        imgs.push(data.imageUrl3);

    }
    if (data && data.imageUrl4) {
        imgs.push(data.imageUrl4);

    }
    const AlertPopup = (message: string, title: string = "Out of Stock") => {
        const PopupClose = () => {
            let dom = document.getElementById("OpenDialog");
            if (dom) unmountComponentAtNode(dom);

        };

        return ReactDOM.render(
            <VGPopUp
                id={"total-quantity"}
                show={true}
                onHide={() => { PopupClose(); }}
                title={"Out of Stock"}
                dialogClassName="block-edit"
                body={
                    <div className="alertContent">
                        <p>{message}</p>
                    </div>
                }
                footer={
                    <Fragment>

                        {<button className={"v-button primary invisible-button"} onClick={(e) => { PopupClose(); }}>{"OK"}</button>}

                    </Fragment >
                }
                isRemoveTabModalIndex={false}
                isShowFooter={true}
                size={"modal-sm"}
                backdrop={true}
            />,
            document.getElementById("OpenDialog"));
    }


    const startingVPL = (data.sellingPrice&&data.sellingPrice>0)?"or 4 payments of "+(data.currencySymbol ?? "$")+(data.sellingPrice/4).toFixed(2)+" with ":""
    let totalQuantity = (data.isTrackInventory)?( data.totalQty ?? 0):999999999;
    

    const [imageUrl, setImageUrl] = useState(data.imagePath);
    const productName = data.name ?? "";
    const brandName = data.brandName ?? "";
    const vpl = useRef( startingVPL);
    const isVpl = data.vplStatus == 2;
    const [isLoading, setLoading] = useState(false);
    const productPrice =  useRef( (data.currencySymbol ?? "$") + data.sellingPrice?.toFixed(2) ?? "");
    const productDescription = data.description ?? "";
    const otherImages = imgs;
   
    const quantRef = useRef<HTMLInputElement>(null);
    const priceRef = useRef<HTMLSpanElement>(null);
    


    if(data.bundleInfo){
        totalQuantity = data.bundleInfo.maxQuantity;
    }

    const quantity = useRef(1);//leave as one for useeffect trigger..

    const isEditor = ((window as any).isEditor == true)

    const isStenciled = data?.isStenciled;

    const setQuantity = (quant: number) => {
        updateProductPrice(quant);
        quantity.current = quant;
    }

    useEffect(() => { // set to 0 if not in stock

        if(totalQuantity<=0&&quantity.current>0){
            updateProductPrice(0);
            if(quantRef.current){
                quantRef.current.value = "0";
            }
        }

        if(totalQuantity<0){
            AlertPopup("This product is out of stock based on the quantity you have selected","Out of Stock");
        }

        setImageUrl(data.imagePath);
    }, [data])


    
    const updateProductPrice = (qnty: number) => {

        const totalSellingPrice = data.sellingPrice * qnty;

        productPrice.current = (data.currencySymbol ?? "$") + (totalSellingPrice)?.toFixed(2) ?? "";
        if (priceRef.current) {
            priceRef.current.innerText = productPrice.current;
        }

        if(isVpl){
            let dom = document.getElementById("vpl-message");
            if(dom&&dom.parentElement){
            if(qnty==0){
                dom.parentElement.style.display="none";       
            }
            else{
                dom.parentElement.style.display="block";
            }
        }

            const vplPrice = (totalSellingPrice/4).toFixed(2);
            vpl.current = "or 4 payments of "+(data.currencySymbol ?? "$")+vplPrice+" with "; 
           
            if(dom)
                dom.innerText = vpl.current;
         
        }
    }

    const ProductImage = () => {
        return <div className="w-full flex-column full-width-mobile product-details-image-section"  style={{  width: "50%", maxWidth: "400px",minWidth:"300px" }}>
            <div className="w-full stencil-item " style={{ paddingBottom: "125%",borderRadius:"4px", backgroundSize: "cover", backgroundPosition: "center", backgroundImage: `url(${imageUrl})`, outline: "1px solid rgba(0,0,0,.1)" }}>

            </div>
            <div className="w-full flex-row justify-start row-gap-large" id="product-details-other-images" style={{ marginTop: "12px" }}>
                {otherImages.map((image, index) => {


                    return <div
                        onClick={() => { setImageUrl(image) }}
                        key={image} style={{ width: "50px",borderRadius:"4px", height: "50px", backgroundImage: `url(${image})`, backgroundSize: "cover", backgroundPosition: "center", outline: "1px solid rgba(0,0,0,.1)", cursor: "pointer", border: ((image == imageUrl) ? "1px solid #aaa" : "none") }}> </div>

                })}
            </div>
        </div>

    }
    const QuantityField = () => {





        return <div className="flex-row justify-between align-center "
            style={{ margin: "10px 0px 12px 0px" }}
        ><span>Quantity:</span> <div className="flex-row" >

                <input className="no-arrow-num text-align-center" style={{borderRadius:"0px", width: "50px", height: "32px", outline: "1px solid #aaa", border: "none",paddingRight:"6px"}} type="number" defaultValue={quantity.current} ref={quantRef} onChange={(e) => {

                    let target = e.target as HTMLInputElement;

                    if (e.target) {

                        let val = target.value.replace(/\D/g, '').replaceAll(".","").trim();

                        if (val == "")
                            val = "0";

                        let qInt = parseInt(val);
                        if (qInt > totalQuantity) {
                            AlertPopup("This product is out of stock based on the quantity you have selected");
                            qInt = totalQuantity;
                        }
                        if (qInt <= 0)
                            qInt = 1

                        if (quantRef.current)
                            quantRef.current.value = qInt.toString();
                        setQuantity(qInt)

                        target.value = qInt.toString();

                    }
                }} />
                <button
                    style={{ width: "40px", height: "32px", background: "#eee", outline: "1px solid #aaa", color: "#666" }}
                    className="invisible-button center-all font-large font-bold"
                    onClick={() => {

                        if (quantity.current > 1) {
                            setQuantity(quantity.current - 1);
                            if (quantRef.current) {
                                quantRef.current.value = (quantity.current).toString();
                            }

                        }

                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" height=".6em" fill="#333" viewBox="0 0 448 512" style={{position:"absolute"}}><path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z"/></svg>                </button>
                <button

                    style={{ width: "40px", height: "32px", background: "#eee", outline: "1px solid #aaa", color: "#666" }}
                    className="invisible-button center-all font-large font-bold"
                    onClick={() => {

                        if (quantity.current < totalQuantity) {
                            setQuantity(quantity.current + 1);
                            if (quantRef.current) {
                                quantRef.current.value = (quantity.current).toString();
                            }
                        }
                        else {
                            AlertPopup("This product is out of stock based on the quantity you have selected"); 
                        }
                    }}
                >

                <svg xmlns="http://www.w3.org/2000/svg" height=".6em" fill="#333"viewBox="0 0 448 512" style={{position:"absolute"}}><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/></svg>
                </button>

            </div>
        </div>
    }

    const ShareOptions = ({ extraClass }: { extraClass: string }) => {

        const encodedTitle = encodeURIComponent(productName)
        const encodedUrl = encodeURIComponent(window.location.href);

        const openPopup = (e: any, link: string) => {
            e.preventDefault();
            window.open(link, 'popup', 'width=600,height=500');

        }
        return <div className={"flex-row font-large row-gap-large justify-center " + extraClass} >
            <a className="no-a inherit-color" style={{ color: "inherit" }} href={`https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`} onClick={(e) => { openPopup(e, `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`) }} rel="noreferrer" target="_blank"><FacebookIcon /></a>
            <a className="no-a inherit-color" href={`https://twitter.com/intent/tweet?text=${encodedTitle}&url=${encodedUrl}`} onClick={(e) => { openPopup(e, `https://twitter.com/intent/tweet?text=${encodedTitle}&url=${encodedUrl}`) }} rel="noreferrer" target="_blank"><TwitterIcon /> </a>
            <a className="no-a inherit-color" href={`http://pinterest.com/pin/create/link/?url=${encodedUrl}`} onClick={(e) => { openPopup(e, `http://pinterest.com/pin/create/link/?url=${encodedUrl}`) }} rel="noreferrer" target="_blank"><PintrestIcon /></a>
        </div>

    }

    const Details = () => {
        return <div className="w-full flex-column col-gap-med full-width-mobile" style={{ padding: "32px", width: "50%" }}>
            <div className="flex-row align-start justify-between stencil-item max-content row-gap-large relative"><h1 className="full-width-mobile" style={{wordBreak:"break-word",fontSize:"32px",minWidth:"250px"}}>{productName} </h1> <ShareOptions extraClass="mobile-hide" /></div>
            <span className="stencil-item">{brandName}</span>
            <div className="flex-column col-gap-med stencil-item max-content" ><span ref={priceRef} className="font-bold font-large">{productPrice.current} </span>
                {isVpl && <span style={{ fontSize: "13px" }}  ><span id="vpl-message">{vpl.current}</span> <VPLIcon /></span>}
            </div>
            <p className="vp-1 stencil-item" style={{ textAlign: "left", marginTop: "8px", wordBreak: "break-word" }}>
                {productDescription}
            </p>
            {data?.bundleInfo?.includedItems&&data.bundleInfo.includedItems.length>0 &&
                <ul style={{ marginLeft: "-.75em" }}>
                {
                    data.bundleInfo.includedItems.map((item:string)=>{
                        return <li>{item}</li>
                    })
                }
            </ul>

            }
            {isStenciled ?
                <div className="stencil-item w-full" style={{ height: "40px", margin: "12px 0px" }}></div>
                :
                <QuantityField />
            }
            <button id="productaddcart" className="v-button primary w-full invisible-button " style={{ marginBottom: "8px",marginTop:"8px" }}
                onClick={(e) => ProductAddToCart(e.currentTarget.id)}
            >Add to Cart</button>
            <ShareOptions extraClass="show-mobile tablet-hide  w-full" />
        </div>
    }
    const ProductAddToCart = async (event: any) => {

        if (isEditor || quantity.current <= 0)
            return;


        let LoginDetails = utils.ReadCookie();
        if (LoginDetails.EncUid === undefined || LoginDetails.EncUid === "" || LoginDetails.EncUid === null) {
            window.wbc.openVagaroPage("divVagaroIframe", utils.VURL("Login", null) + addtoCartEnum.ProductAddToCart + "&Data="+event);
            $(".modal-backdrop").hide();
            $(".vgn-popup").hide();
            return;
        }
        else {
            let businessId ="";
            let itemId = "";
            let url = window.location.pathname;
            let split = url.split("/");
            if (split[split.length - 1] == "") {
                split.pop();
            }
            if (split[split.length - 2] != "") {
                businessId = split[split.length - 2];
            }
            if (split[split.length - 1] != "") {
                itemId = split[split.length - 1];
            }
            // let str = split.pop();
            // if (str && str.length > 1) {
            //     let numberSplit = str.split(/-(.*)/s);
            //     if (numberSplit.length >= 2) {
            //         businessId = numberSplit[0];
            //         itemId = numberSplit[1];
            //     }
            // }
            setLoading(true);
            const Requestdata: any = {};
            Requestdata.encinventoryID = itemId;
            Requestdata.quantity = quantity.current;
            Requestdata.totalQuantity = totalQuantity;
            Requestdata.isFromPoupup = 1;
            Requestdata.EncUid = LoginDetails.EncUid;

            await AsyncAjaxPost(
                businessId + "/addtocart/addtocart",
                Requestdata,
                userContext.accessToken,
                function onSuccess(data: any) {
                    //handlepopupClose();
                    setLoading(false);

                    var cartReuslt = data.data.data;


                    if (cartReuslt || cartReuslt === 0) {                        
                        let doms = document.getElementById("divcart");
                        if (doms) unmountComponentAtNode(doms);
                        ReactDOM.render(<RenderBusinessCart data={cartReuslt} userContext={userContext}/>, document.getElementById("divcart"));
                        Toast("Item added to cart");
                    }

                },
                function onError(data: any) {



                    console.log("error", data);
                    setLoading(false);
                    AlertPopup("Oops,something went wrong. please try again later", "Error");
                }
            )

        }
    }


    if (!data) {
        return <OopsBanner />
    }
    else if(!data.name&&!isStenciled){

        console.log(data);

    return <div className="center-all flex-column col-gap-large" style={{minWidth:"340px",minHeight:"400px"}}>
        <NoRecordsBanner message="Product Not Found" showInLive={true}/>
        <br></br>
        <a className="v-button primary w-full invisible-button "    
            href="./"
               
            >Go Back Home</a>
    </div>
}
    return (
        <>

            <div className={"w-full flex-row justify-center align-start flex-wrap " + (isStenciled ? "stenciled" : "")} id="product-details-split">
                <ProductImage />
                <Details />
            </div>
            <br></br>
            <br></br>

            {isLoading && <Loader />}

        </>

    )

}

export function GetItemBundleInfoFromData(data:any){

    let maxQuantity = 999999999;
    let includedItems:string [] = []
    for(let i = 0;i<data.length;i++){
        let item = data[i];

        if(item.isTrackInventory&&item.totalQty<maxQuantity){
            maxQuantity = item.totalQty;
        }

       let desc=item.name;
        if(item.bundleQty>1)
            desc+=` x ${item.bundleQty} Qty`;

        includedItems.push(desc);
    }

    return {
        maxQuantity:maxQuantity,
        includedItems:includedItems,
    }

}