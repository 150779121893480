import React from 'react';
// Import other necessary dependencies and components

import RenderStarRating from "../../Icons/StarRating";
import Dropdown from 'react-bootstrap/Dropdown';

import StaffReview from "../../../Hooks/StaffReview"
import { addtoCartEnum, utils } from "../../../utils/Utils";
import StaffMessage from "../../../Hooks/StaffMessage";
import { UserContext } from "../../Definitions/UserContext";
import StencilRows from "../../Binding/StencilRows";
import { AsyncAjaxGet } from "../../Utilities/AsyncAjaxService";
import styled from 'styled-components';
import '../layouts/component-layouts.css';
const avatarSize = "4.5em";
const avatarStyle = { width: avatarSize, height: avatarSize, borderRadius: "50%" };



interface StyledImageContainerProps {
  photoURL: string;
}

// Define the styled component for Flexbox layout

const ProfileCard = styled.div`
 
  border-radius: 50%;
  position: relative;
z-index: 1;
    /* Mobile */
    @media (min-width: 320px) {
    margin: 8px auto;
    width: 320px;
    height: 320px;
}
/* Tablet */
@media (min-width: 600px) {
    margin: 12px auto;
    width: 400px;
    height: 400px;
}
/* Desktop */
@media (min-width: 1024px) {
    margin: 12px auto;
    width: 500px;
    height: 500px;
}

  /* Mobile */
  @container (min-width: 320px) {
      margin: 8px auto;
      width: 320px;
      height: 320px;
  }
  /* Tablet */
  @container (min-width: 600px) {
      margin: 12px auto;
      width: 400px;
      height: 400px;
  }
  /* Desktop */
  @container (min-width: 1024px) {
      margin: 12px auto;
      width: 500px;
      height: 500px;
      
  }
`;

// Styled component
const StyledImageContainer = styled.div<StyledImageContainerProps>`
  border-radius: 50%;
  background: url(${props => props.photoURL}) no-repeat center center;
  background-size: cover;
  filter: brightness(60%);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: url(${props => props.photoURL}) no-repeat center center;
  background-size: cover;
  filter: brightness(65%);
  z-index: 1;
  /* Mobile */
  @container (min-width: 320x) {
      width: 100%;
      height: 100%;
  }
  /* Tablet */
  @container (min-width: 600px) {
      width: 100%;
      height: 100%;
  }
  /* Desktop */
  @container (min-width: 1024px) {
      width: 100%;
      height: 100%;
      
  }
 
`;

  const InfoContainer = styled.div`
  position: absolute;
  
    /* Mobile */
    @media (min-width: 320px) {
    top: 33%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white !important;
    padding: 30px;
}
/* Tablet */
@media (min-width: 600px) {
    top: 50%;
    left: 50%;
    width: 80%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
}
/* Desktop */
@media (min-width: 1099px) {
    top: 45%;
    left: 50%;
    width: 80%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;  
}

  /* Mobile */
  @container (min-width: 320px) {
      top: 33%;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
      text-align: center;
      color: white !important;
      padding: 30px;
  }
  /* Tablet */
  @container (min-width: 600px) {
      top: 50%;
      left: 50%;
      width: 80%;
      transform: translate(-50%, -50%);
      text-align: center;
      color: white;
  }
  /* Desktop */
  @container (min-width: 1099px) {
      top: 45%;
      left: 50%;
      width: 80%;
      transform: translate(-50%, -50%);
      text-align: center;
      color: white;  
  }
  `;

  const ContactContainer = styled.div`
  /* Mobile */
  @container (min-width: 360px) {
     
  }
  /* Tablet */
  @container (min-width: 768px) {
      
  }
  /* Desktop */
  @container (min-width: 1099px) {
      
  }
  
  font-size: 1.4em;
  margin-top: 16px;

  `;
  const StyledH3 = styled.h3`
  
  /* Mobile */
@media (min-width: 320px) {
    word-break: break-word;
    
    color: #fff !important;
}
/* Tablet */
@media (min-width: 600px) {
    word-break: break-word;
   
    color: #fff !important;
}
/* Desktop */
@media (min-width: 1024px) {
    word-break: break-word;
    
    color: #fff !important;
}

  /* Mobile */
  @container (min-width: 320px) {
      word-break: break-word;
      
      color: #fff !important;
  }
  /* Tablet */
  @container (min-width: 600px) {
      word-break: break-word;
       
      color: #fff !important;
  }
  /* Desktop */
  @container (min-width: 1024px) {
      word-break: break-word;
     
      color: #fff !important;
  }
  `;

  const RatingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  
    /* Mobile */
    @media (min-width: 360px) {
    gap: 12px;
    margin-top: 16px;
    }
    /* Tablet */
    @media (min-width: 768px) {
        gap: 16px;
        margin-top: 16px;
    }
    /* Desktop */
    @media (min-width: 1099px) {
        gap: 16px;
        margin-top: 16px;
    }


  /* Mobile */
  @container (min-width: 360px) {
      gap: 12px;
      margin-top: 16px;
  }
  /* Tablet */
  @container (min-width: 768px) {
      gap: 16px;
      margin-top: 16px;
  }
  /* Desktop */
  @container (min-width: 1099px) {
      gap: 16px;
      margin-top: 16px;
  }
  `;
  const BusinessSummary = styled.div`
 
  /* Mobile */
  @media (min-width: 320px) {
    display: none;
}
/* Tablet */
@media (min-width: 600px) {
    display: block;
    padding-top: 12px;
    padding-bottom: 16px;
   
    display: none;
 
}
/* Desktop */
@media (min-width: 1024px) {
    display: block;     
    margin-top: 24px;
   
}

  /* Mobile */
  @container (min-width: 320px) {
      display: none;
  }
  /* Tablet */
  @container (min-width: 600px) {
      display: block;
      padding-top: 12px;
      padding-bottom: 16px;
    
      display: none;
   
  }
  /* Desktop */
  @container (min-width: 1024px) {
      display: block;     
      margin-top: 8px;
       
  }
  `;

  const ButtonContainer = styled.div`
 
  width: 100%;
  text-align: center;
  z-index: 1;
  // Assuming flex-row, justify-center, w-full, gap-1 are class-based styles
  // from a CSS framework like Tailwind CSS, you can replicate their styles here.
  // Below is an example assuming Tailwind CSS class styles:

    /* Mobile */
  @media (min-width: 320px) {
  position: absolute;
  bottom: 15%;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 160px;
  gap: 0.25rem;

  }
  /* Tablet */
  @media (min-width: 600px) {
      position: absolute;
      bottom: 60px;
      left: 0;
      right: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 160px;
      gap: 0.25rem;
     
  }
  /* Desktop */
  @media (min-width: 1024px) {
      position: absolute;
      bottom: 72px;
      left: 0;
      right: 0;
      margin: auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 160px;
      gap: 0.25rem;
     
  }

  /* Mobile */
  @container (min-width: 320px) {
  position: absolute;
  bottom: 15%;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 160px;
  gap: 0.25rem;

  }
  /* Tablet */
  @container (min-width: 600px) {
      position: absolute;
      bottom: 60px;
      left: 0;
      right: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 160px;
      gap: 0.25rem;
     
  }
  /* Desktop */
  @container (min-width: 1024px) {
      position: absolute;
      bottom: 48px;
      left: 0;
      right: 0;
      margin: auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      gap: 0.25rem;
     
     
  }
`;


export default function RenderStaffMemberCircles({ staffMember, siteData, isStenciled, serviceList, layout }: { staffMember: any, siteData: UserContext, isStenciled: boolean, serviceList: any, layout: string }) {

  //validate data and replace with empty strings to avoid extra checks during rendering
  if (!staffMember.businessSummary)
      staffMember.businessSummary = "";
  if (!staffMember.spLargePhotoUrl)
      staffMember.spLargePhotoUrl = "";
  if (!staffMember.reviewRank)
      staffMember.reviewRank = 0;
  if (!staffMember.reviewCount)
      staffMember.reviewCount = 0;
  if (!staffMember.firstName)
      staffMember.firstName = "";
  if (!staffMember.lastName)
      staffMember.lastName = "";

  var IsEditor = (window as any).isEditor !== undefined ? (window as any).isEditor : false;
  var BusinessData = utils.MultiLocationData(siteData, siteData.bidHash);
  return (
      <>  
         <div className="p-4">
         <div style={{ cursor: "pointer" }} onClick={(event) => {
                if(event.target&&(event.target as HTMLElement).closest(".dropdown"))
                    return;
            !IsEditor && (window as any).Staffreview(staffMember, siteData)}}>
         <ProfileCard>
         
          {/* Full-width image at the top */}
          {staffMember.photoURLForOriginal ?
              <StyledImageContainer className="stencil-item staff-image-border" photoURL={staffMember.photoURLForOriginal} />
              :
              <div className="stencil-item staff-image-border" style={{borderRadius:"100%", width: "100%", height: "100%",filter:"brightness(65%)", background: "#8f8f8f", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <span style={{ fontSize: "3em", color: "#FFF" }}></span>
              </div>
          }

          {/* Centered content over the image */}
          <InfoContainer>
          <StyledH3>{staffMember.firstName} {staffMember.lastName}</StyledH3>
        
          <RatingContainer>
          {!isStenciled && <RenderStarRating rating={Math.round(staffMember.reviewRank)} starColor="yellow" isOutlined={false} />}
          <span className="vp-2 text-white">({staffMember.reviewCount})</span>
          </RatingContainer>

          
          <h4 className="text-white pt-4">{staffMember.cell}</h4>
          <BusinessSummary className="vp-2 text-white">{staffMember.businessSummary}</BusinessSummary>
          </InfoContainer>

          {/* Buttons and other interactive elements */}
          <ButtonContainer>
          {staffMember.isShowMessage &&
              <button className="invisible-button v-button v-button-with-icon secondary full-width-mobile full-width center-all font-weight-bold" onClick={(e) => {e.stopPropagation(); !IsEditor && (window as any).StaffMessage(staffMember, siteData, BusinessData)}}>
                  <i className="fal fa-envelope bicon px-1"></i>Message
              </button>
          }

          {serviceList > 0 && staffMember.isAllowBook ? staffMember.isServiceTab && staffMember.isClassTab ?
              <Dropdown >
                  <Dropdown.Toggle bsPrefix="invisible-button v-button primary full-width-mobile" id="dropdown-basic" >
                      {staffMember.bookText}<i className="bicon2 fal fa-angle-down px-1"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu >
                      <Dropdown.Item onClick={(e) => {e.stopPropagation();  !IsEditor && (window as any).BookStaffSerice(staffMember, BusinessData)}} className="no-a">Services</Dropdown.Item>
                      <Dropdown.Item onClick={(e) => {e.stopPropagation();  !IsEditor && (window as any).BookStaffClass(staffMember, BusinessData)}} className="no-a">Classes</Dropdown.Item>
                  </Dropdown.Menu>
              </Dropdown> 
              : 
              <button type="button" onClick={(e) => {e.stopPropagation();  !IsEditor && (window as any).BookServiceClass(staffMember, BusinessData)}} className="invisible-button v-button primary full-width-mobile" style={{ width: "138px" }}>{staffMember.bookText}</button>
              : ""}
          </ButtonContainer>
          
           </ProfileCard>
           </div>
         </div>
      </>
  )
}