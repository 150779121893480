import React from "react";

export default function RenderBusinessHours({ data, layout }: { data: any, layout: string }) {
    switch (layout) {
        default:
            return <RenderDefaultLayout data={data} />
    }
}

const leftStyle: {} = { textAlign: "left", padding: "0px 10px" };
const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

function RenderDefaultLayout({ data }: { data: any }) {
    for (let i = 0; i < daysOfWeek.length; i++) {
        if (i >= data.length || data[i].weekDay != daysOfWeek[i]) {
            data.splice(i, 0, { weekDay: daysOfWeek[i], openTime: "", closeTime: "", isOpen: false })
        }
    }

    const dayOfWeek = new Date().getDay();  
const isStenciled =  data[0]?.isStenciled===true;
    return <>
        <table className={"hours-table w-full "+(isStenciled?"stenciled":"")}>
            <tbody>
                {data.map((day: any, index: number) => {
                    return <MapHour isStenciled={isStenciled} key={day.weekDay} day={day} />
                })}
            </tbody>
        </table>
    </>
}

function MapHour({ day,isStenciled }: { day: { weekDay: string, openTime: string, closeTime: string, isOpen: boolean },isStenciled:boolean  }) {
    let openTime = day.openTime;
    let closeTime = day.closeTime;
    const style = day.isOpen ? { ...leftStyle, fontWeight: "600" } : leftStyle;
    return (
        <tr style={style}>
            <td>{day.weekDay}</td>
            {isStenciled?
            <td><div className="stencil-item w-full" style={{margin:"2px 0px",lineHeight:".75em"}}>12:00 am - 12:00 pm</div></td>:

            day.openTime.length > 1 ?
                <>
                    <td className="stencil-item" style={{ textAlign: "right" }}>
                        {openTime}
                        {" - "}
                        {closeTime}
                    </td>
                </>
                :
                <>
                    <td className="stencil-item" style={{ textAlign: "right" }}>Closed</td>
                </>
            }
        
        </tr>
    )
}