import React, { Fragment, useEffect, useState } from "react";
import "../../utils/select2_4.0.js";
import "../../Style/select2.css"
import $ from 'jquery';

const GroupSelect = (props) => {

    const Control = {
        ControlID: "#" + props.ControlID
    }


    const OpenSelect = () => {
        $('.select2-container').addClass(props.ControlID);
        $("body").append('<div class="select2backdrop ' + props.ControlID + '"></div>');
        $('.select2backdrop').on('click', function () {
            $(Control.ControlID + '.expandedWidth').select2("close");
        });
        var datetimepicker = $(".xdsoft_datetimepicker");
            setTimeout(() => {
                datetimepicker.trigger('close.xdsoft');

            }, 127);
        setTimeout(() => {
            $('.select2-selectAll--dropdown').hide()
        }, 100);

    };
    const OnChangeSelect = (val) => {
        props.onChange(val);
    }
    const CloseSelect = () => {
        $('.select2backdrop ').hide().remove();
        $('.select2-container').removeClass("driveFormMselect");
    }

    const ApplySelect2 = () => {
        if (!props.isSingleOption) {
            $(Control.ControlID).select2({
                placeholder: props.placeholder,
                closeOnSelect: true,
                searchInputPlaceholder: props.searchInputPlaceholder,
                minimumResultsForSearch: 1,
                maximumSelectionSize: 1,
                multiple: false,
            });
        } else {
            $(Control.ControlID).select2({
                placeholder: props.placeholder,
                templateResult: function (obj) {
                    if (!obj.id) {
                        return obj.text;
                    }
                    var objectData ="";
                    if (obj.title != "") {
                        objectData  = obj.title.split(",");
                    }
                    var $obj = "";
                    $obj = $('<div data-placeid="' + obj.id + '">' + objectData[0] + " " + '<label style="font-weight: bold;"> (' + objectData[1] + ')</label>' + '</div>');
                    return $obj;
                },
                closeOnSelect: true,
                searchInputPlaceholder: props.searchInputPlaceholder,
                minimumResultsForSearch: -1,
                maximumSelectionSize: 1,
                multiple: false,

            });
        }


    }

    useEffect(() => {
        ApplySelect2();
        $(Control.ControlID).on("change", function (e) {
            OnChangeSelect(e.target.value)
        })
        $(Control.ControlID + '.expandedWidth').on('select2:open', function (e) {
            OpenSelect()
        })
        $(Control.ControlID + '.expandedWidth').on('select2:close', function (e) {
            CloseSelect()
        });

    }, [props.placeholder]);



    return (
        <Fragment>
            <div className="groupSelectWrp" >
                {!props.isSingleOption ?
                    <select style={{ display: "none" }} id={props.ControlID} className="expandedWidth form-control" multiple="true" data-select-all="1" >
                        <option value='' hidden>{props.placeholder}</option>
                        {(props.options || []).map((data, index) => (
                            <optgroup label={data.text}>
                                {(data?.children || []).map((sdata, i) => (
                                    <option value={sdata.id}>{sdata.text}</option>
                                ))}
                            </optgroup>
                        ))}
                    </select>
                    :
                    <select className="form-control" id={props.ControlID} >
                        <option value='' hidden>{props.placeholder}</option>
                        {props.options.map((data) => (
                            <option value={data.id} title={data.text + "," + data.promotionText}>{data.text +" "+ data.promotionText}</option>

                        ))}
                    </select>}
            </div>

        </Fragment>
    )
};

export default GroupSelect;