import { createSlice } from '@reduxjs/toolkit'
import $ from "jquery"
export const MySiteSlice = createSlice({
    name: 'MySite',
    initialState: {
        VGURL: "",
        SiteURL: "",
    },
    reducers: {       
        SetSiteURL: (state, action) => {
            state.VGURL = action.payload.VGURL;
            state.SiteURL = action.payload.SiteURL;
            $(".wbloader").css("display", "none");
        }
    },
})

export const {
    SetSiteURL
} = MySiteSlice.actions

export default MySiteSlice.reducer;