export const LinkTypes = {

    Page:"page",
    Url:"url",
    Scroll:"scroll",
    MultiPage:"multiPage", // exlusive for booking
    Email:"email",
    Phone:"phone",
    PromoCode:"promoCode",
    DailyDeal:"dailyDeal",
    Form:"form",

}

export const LinkTypeSeperator = "|<linkType>|";

export const GetLinkAndType = (rawValue:string)=>{

    const split = rawValue.split(LinkTypeSeperator);
    let returnObj = {link:split[0],type:LinkTypes.Url};
    
    if(split.length>1){
        switch(split[1]){

            case LinkTypes.Page:
                returnObj.type = LinkTypes.Page;
                break;
            case LinkTypes.Scroll:
                returnObj.type = LinkTypes.Scroll;
                break;
            case LinkTypes.MultiPage:
                returnObj.type = LinkTypes.MultiPage;
                break;
            case LinkTypes.Email:
                returnObj.type = LinkTypes.Email;
                break;
            case LinkTypes.Phone:
                returnObj.type = LinkTypes.Phone;
                break;
            case LinkTypes.PromoCode:
                returnObj.type = LinkTypes.PromoCode;
                break;
            case LinkTypes.DailyDeal:
                returnObj.type = LinkTypes.DailyDeal;
                break;
                case LinkTypes.Form:
                    returnObj.type = LinkTypes.Form;
                    break;
            default:
                break;
        }
    }
    return returnObj;
}