import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import ReactDom from "react-dom";

import StaffMessage from "../../../Hooks/StaffMessage";
import StaffReview from "../../../Hooks/StaffReview"
import { addtoCartEnum, utils } from "../../../utils/Utils";
import StencilRows from "../../Binding/StencilRows";
import { UserContext } from "../../Definitions/UserContext";
// Import other necessary dependencies and components

import RenderStarRating from "../../Icons/StarRating";
import OopsBanner, { NoRecordsBanner } from "../../OopsBanner";
import { AsyncAjaxGet } from "../../Utilities/AsyncAjaxService";

const avatarSize = "4.5em";
const avatarStyle = { width: avatarSize, height: avatarSize, borderRadius: "50%" };

const defaultImageUrls = [
    "https://res.cloudinary.com/vagaro/image/upload/v1701719058/MySite/team-member-3_qiblxw.png",
    "https://res.cloudinary.com/vagaro/image/upload/v1701719056/MySite/team-member-1_rweura.png",
    "https://res.cloudinary.com/vagaro/image/upload/v1701719055/MySite/team-member-4_yovo2e.png",
    "https://res.cloudinary.com/vagaro/image/upload/v1701719055/MySite/team-member-2_hnyg2k.png",
    "https://res.cloudinary.com/vagaro/image/upload/v1701719055/MySite/team-member-5_serzz0.png"

    // ... add more URLs as needed
];



export default function RenderStaffMember({ staffMember, siteData, isStenciled, serviceList, layout }: { staffMember: any, siteData: UserContext, isStenciled: boolean, serviceList: any, layout: string }) {
    //validate data and replace with empty strings to avoid extra checks during rendering
    if (!staffMember.businessSummary)
        staffMember.businessSummary = "";
    if (!staffMember.spLargePhotoUrl)
        staffMember.spLargePhotoUrl = "";
    if (!staffMember.reviewRank)
        staffMember.reviewRank = 0;
    if (!staffMember.reviewCount)
        staffMember.reviewCount = 0;
    if (!staffMember.firstName)
        staffMember.firstName = "";
    if (!staffMember.lastName)
        staffMember.lastName = "";

    var IsEditor = (window as any).isEditor !== undefined ? (window as any).isEditor : false;
    var BusinessData = utils.MultiLocationData(siteData, siteData.bidHash);
    return (
        <div className="flex-column" style={{ alignContent: "center", width: "100%" }}>
            <div className="flex-row-to-column align-center gap-large" style={{ margin: "10px", cursor: "pointer" }} onClick={(event) => !IsEditor && (window as any).Staffreview(staffMember, siteData)}>

                {typeof staffMember.photoURL != 'undefined' && staffMember.photoURL != null && staffMember.photoURL != "" &&
                    <div className="staff-image-border" style={{ background: `url(${staffMember.photoURL})`, borderRadius: "100%", width: avatarSize, height: avatarSize, backgroundSize: "cover", backgroundPosition: "center", border: "1px solid  #ccc" }}></div>
                }

                {(typeof staffMember.photoURL == 'undefined' || staffMember.photoURL == null || staffMember.photoURL == "") &&
                    <div className="  center-all"
                        style={{
                            background: `#e7e7e7`,
                            border: '1px solid #cdcdcd',
                            color: "#333",
                            borderRadius: "50%",
                            width: avatarSize,
                            height: avatarSize,
                      
                           
                        }}>
                        <div className="staff-image-border review-profile-inner-div intialsname h-full w-full xvg-avatar-inital center-all stencil-item" style={{border:"1px solid #cdcdcd",borderRadius:"100px"}}>
                            <div className="review-profile-inner vg-avatar-inital-inner ">
                                <div className="review-profile-inner-text w-full">{staffMember.spintials}
                                </div>
                            </div>
                        </div>
                    </div>}

                    <div>
                        <h3 className="text-align-center-mobile" style={{ maxWidth: "500px", wordBreak: "break-word" }}>{staffMember.firstName} {staffMember.lastName}</h3>
                        <div className="flex-row align-center gap-large stencil-item review-icon-rating-staff"><div className="flex-row gap-small">{!isStenciled && <RenderStarRating rating={Math.round(staffMember.reviewRank)} starColor="yellow" isOutlined={false} />} </div> 
                        <div className="vp-2" style={{ paddingTop: ".2em" }}>({staffMember.reviewCount})</div></div>
                        <div className="staff-phone stencil-item text-align-center-mobile" style={{ marginTop: "4px", color: "inherit" }}>{staffMember.cell}</div>
                    </div>
                </div>
                {isStenciled ?
                    <StencilRows rowCount={3} />
                    :
                    <div className="vp-2" style={{ padding: "0px 16px", textAlign: "left" }}>{staffMember.businessSummary}</div>
                }
                <div className="flex-row justify-end w-full">
                    <div className="gap-large flex-row-to-column booknowdrp full-width-mobile" style={{ padding: "10px 16px", textAlign: "right" }}>
                        {staffMember.isShowMessage && <button className="invisible-button v-button secondary full-width-mobile center-all" onClick={() => !IsEditor && (window as any).StaffMessage(staffMember, siteData, BusinessData)}><i className="fal fa-envelope bicon"></i>Message</button>}

                        {serviceList > 0 && staffMember.isAllowBook ? staffMember.isServiceTab && staffMember.isClassTab ?
                            <Dropdown>
                                <Dropdown.Toggle bsPrefix="invisible-button v-button primary full-width-mobile" id="dropdown-basic">
                                    {staffMember.bookText}<i className="bicon2 fal fa-angle-down"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => !IsEditor && (window as any).BookStaffSerice(staffMember, BusinessData)} className="no-a">Services</Dropdown.Item>
                                    <Dropdown.Item onClick={() => !IsEditor && (window as any).BookStaffClass(staffMember, BusinessData)} className="no-a">Classes</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            :
                            <button type="button" onClick={() => !IsEditor && (window as any).BookServiceClass(staffMember, BusinessData)} className="invisible-button v-button primary full-width-mobile">{staffMember.bookText}</button>
                            : ""}
                    </div>
            </div>
        </div>
    )
}