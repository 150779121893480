const Site = {
    SiteName: "https://dev24.bookitall.com/",
    MySiteName: "mysite.bookitall.com",
    VagaroCoreApiUrl: "https://dev24apiv2.bookitall.com"
}

const utils = {
    Validate: (value) => {
        if (typeof value != undefined && value != null && value != "" && value != 'undefined') {
            return true;
        }
        else {
            return false;
        }
    }
}

export { utils, Site }

