import React from "react"

export default function StencilRows({rowCount}:{rowCount:number}) {

    const style = {
        maxHeight:"1em",
        overflow:"hidden",
   
        opacity:1
 }

 const rows = Array.from({ length: rowCount-1 }, (_, index) => index);

 return <div className="stenciled  flex-column gap-small w-full">
       {rows.map((item: any, index: number) => {
        return  <div key = {index} className='w-full stencil-item' style={style}>This page contains basic business information like your address, phone number, and your personal Vagaro URL. This information will show up on your Vagaro page andor custom website. This page contains basic business information like your address, phone number, and your personal Vagaro URL. This information will show up on your Vagaro page andor custom website. This page contains basic business information like your address, phone number, and your personal Vagaro URL. This information will show up on your Vagaro page andor custom website.</div>

       })}
        <div className='w-full stencil-item' style={{...style,maxWidth:"80%"}}>This page contains basic business information like your address, phone number, and your personal Vagaro URL. This information will show up on your Vagaro page andor custom website. This page contains basic business information like your address, phone number, and your personal Vagaro URL. This information will show up on your Vagaro page andor custom website. This page contains basic business information like your address, phone number, and your personal Vagaro URL. This information will show up on your Vagaro page andor custom website.</div>
 </div>

}