import { addtoCartEnum, utils } from '../utils/Utils';
import { AsyncAjaxPost } from "../components/Utilities/AsyncAjaxService"
import React, { useEffect, useState } from 'react'
import ReactDOM, { unmountComponentAtNode } from "react-dom";
import CommonMessagepopup from '../VagaroComponent/CommonMessagepopup'
import Loader from '../components/Common/Loader';
import RenderBusinessCart from "../components/BusinessControls/RenderBusinessCart";
import ReactDom from "react-dom";
import MultiLocationDropdown from '../VagaroComponent/MultiLocationDropdown';
import { NoRecordsBanner } from '../components/OopsBanner';

const MembershipAddToCart = (props) => {

    const MembershipData = props.data
    const SiteData = props.SiteData;
    const event = props.event;
    const allowMultiLoc = props.allowMultiLoc;
    const BusinessData = utils.MultiLocationData(props.SiteData, props.SiteData.bidHash);
    const [isContentLoading, setContentLoading] = useState(false);

    useEffect(() => {
        AddCart(false, "", true);
    }, [props]);

    const Replacemembership = () => {
        AddCart(true);
    }

    if (utils.isCheckEmptyOrWhiteSpace(BusinessData)) return <NoRecordsBanner message='Marchant data is not valid.' />;

    const AddCart = async (action, encBID, open, IsCallback, CallBackData) => {
        let LoginDetails = utils.ReadCookie();
        if (props.cartBook === true) {
            if (LoginDetails.EncUid === undefined || LoginDetails.EncUid === "" || LoginDetails.EncUid === null) {
                window.wbc.openVagaroPage("divVagaroIframe", utils.VURL("Login", null) + addtoCartEnum.MemebrshipAddToCart + "&Data=" + event.target.id);
                return;
            }
            if (allowMultiLoc && open !== false && action !== true) {
                const MultiGroupMembership = MembershipData.multiGroupMembership;
                let Multilocations = [];
                if (!utils.isNullEmptyOrWhiteSpace(MultiGroupMembership)) {
                    var businessData = JSON.parse(MultiGroupMembership);
                    businessData.map((data, index) => {
                        let Data = [];
                        Data = SiteData.MultilocationData.filter(
                            (col) => col.encBIDHash == data.BID
                        );
                        if (Data.length > 0) {
                            Multilocations.push(Data[0]);
                        }
                    });
                }
                if (Multilocations.length > 1) {
                    return ReactDom.render(
                        <MultiLocationDropdown
                            Sitedata={Multilocations}
                            OnClickEvent={AddCart}
                            Mode={"membershipAddCart"}
                            action={action}
                            userContext={SiteData}
                            Membership={MembershipData}
                            IsShowMap={true}
                        />,
                        document.getElementById("OpenDialogMultiLocation")
                    );
                }
            }
            if (IsCallback) {
                MembershipData.id = CallBackData.MID;
                MembershipData.promotionDetail = "";
                MembershipData.promotionTitle = "";
                MembershipData.description = "";
            }
            setContentLoading(true);
            const Requestdata = {};
            Requestdata.EncMid = MembershipData.id;
            Requestdata.EncUid = LoginDetails.EncUid;
            Requestdata.noOfVisit = 0;
            Requestdata.promotionDetail = MembershipData.promotionDetail;
            Requestdata.promotionTitle = MembershipData.promotionTitle;
            Requestdata.ServiceDesc = MembershipData.description;
            Requestdata.isCustomPackage = false;
            Requestdata.lstAddonService = [];
            Requestdata.userBusinessMembershipId = "";
            Requestdata.IsOverride = action ? true : false;
            await AsyncAjaxPost(
                (encBID != undefined && encBID != null && encBID != "") ? encBID + "/addtocart/membershipaddtocart" : SiteData.businessId + "/addtocart/membershipaddtocart",
                Requestdata,
                SiteData.accessToken,
                function onSuccess(data) {
                    var msg = data.data.data;
                    setContentLoading(false);
                    if (msg.isMemberShipExiting === true) {
                        return ReactDOM.render(
                            <CommonMessagepopup
                                IsMembeship={true}
                                Replacemembership={Replacemembership}
                            />,
                            document.getElementById("OpenDialog1")
                        )

                    } else if (msg.isMebershipPurchased === true) {
                        return ReactDOM.render(
                            <CommonMessagepopup
                                IsMembeshippurchased={true}
                            />,
                            document.getElementById("OpenDialog1")
                        )
                    }
                    else {
                        unmountComponentAtNode(document.getElementById("divcart"));
                        ReactDOM.render(<RenderBusinessCart data={msg.itemCount} userContext={SiteData} />, document.getElementById("divcart"));
                        unmountComponentAtNode(document.getElementById("OpenDialog1"));
                    }
                }
            )

        }

    }
    return <> {isContentLoading && < Loader />}</>;
}

export default MembershipAddToCart
