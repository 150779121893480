import React, { Fragment } from "react";
import VGDailyDealDetail from "../../VagaroComponent/VGDailyDealDetail"
import ReactDom from "react-dom";
import { LeftCaret, RightCaret } from "../Icons/Carets";
import { UserContext } from "../Definitions/UserContext";
import { NoRecordsBanner } from "../OopsBanner";
import { utils } from "../../utils/Utils";
import "../Styles/DailyDealCards.scss";

export default function RenderBusinessDailyDeals({ data, layout = "1", siteData, rootId = "" }: { data: any, layout: string, siteData: UserContext, rootId: string }) {    
    
    if (!data||!data.dailyDealList||data.dailyDealList.length == 0)
        return <NoRecordsBanner message='Daily deals will appear here once added.' />


        const isStenciled = data?.isStenciled===true;
    switch (layout) {
        default:
            return RenderDefaultLayout(data, layout, siteData, rootId,isStenciled);
    }
}

function RenderDefaultLayout(data: any, layout: string, siteData: UserContext, rootId: string,isStenciled:boolean = false) {
    const [showArrows, setShowArrows] = React.useState(false);
    const leftArrowRef = React.useRef<HTMLButtonElement>(null);
    const rightArrowRef = React.useRef<HTMLButtonElement>(null);
    
    const evaluateScroll = (parent:HTMLElement,delta:number) => {
        if(!parent||!parent.clientWidth)
            return;

        let width = parent.clientWidth;
        let pw = parent.scrollWidth;
        let sl = parent.scrollLeft + delta * width; 


        if(pw > width)
            setShowArrows(true);
        else{
            setShowArrows(false)
            return;
        }
        if(sl >= (pw-10) - width){
            if(rightArrowRef.current)
                rightArrowRef.current.disabled = true;
            //(document.getElementsByClassName("d-arrow-right")[0] as HTMLInputElement).disabled = true;
        } else {
            if(rightArrowRef.current)
                rightArrowRef.current.disabled = false;
           // (document.getElementsByClassName("d-arrow-right")[0] as HTMLInputElement).disabled = false;
        }

        if (sl <= 0){
            if(leftArrowRef.current)
                leftArrowRef.current.disabled = true;
           // (document.getElementsByClassName("d-arrow-left")[0] as HTMLInputElement).disabled = true; 
        } else {
            if(leftArrowRef.current)
                leftArrowRef.current.disabled = false;
            //(document.getElementsByClassName("d-arrow-left")[0] as HTMLInputElement).disabled = false;
        }

    }

    const scrollContainer = (delta: number) => {
        if (cardRef.current && cardRef.current.parentElement) {
            let parent = cardRef.current.parentElement;
            let width = parent.clientWidth;
            
            parent.scrollTo({ left: parent.scrollLeft + delta * width, behavior: "smooth" });                
            //evaluateScroll(parent,delta);
                
        }

    }

    React.useEffect(() => {
        evaluateScroll(cardRef.current?.parentElement as HTMLElement,0);        
        const resizeObserver = new ResizeObserver(entries => {
            window.requestAnimationFrame(function () {
                evaluateScroll(cardRef.current?.parentElement as HTMLElement,0);   
            });
        });

        if (cardRef.current?.parentElement)
            resizeObserver.observe(cardRef.current.parentElement as Element);

        return () => {
            resizeObserver.disconnect();
        }

    }, [])

    const cardRef = React.useRef<HTMLAnchorElement>(null);
    const isEditor = (window as any).isEditor == true;

    const flexWrap = (layout=="1")?"nowrap":"wrap";

    return <>
        <div 
        onScroll={()=>{evaluateScroll(cardRef.current?.parentElement as HTMLElement,0)}}
        className={"vgdailyDealList " + (isStenciled?"stenciled":"")} style={{ flexWrap: flexWrap, gap: "0", overflow: "auto", marginTop: (showArrows ? "36px" : "auto"), height: (showArrows ? "calc(100% - 36px)" : "100%") }}>
            {data.dailyDealList.map((deal: any, index: number) => {
                return <DailyDealCard key={index} deal={deal} layout={layout} siteData={siteData} isEditor={isEditor} useRef={index == 0 ? cardRef : null} salontime={data.salonTime} isStenciled={isStenciled}/>
            })}

        </div>
        {
            showArrows && layout=="1"&&<><div className="d-arrows">
                <LeftArrow scollFunc={scrollContainer} inRef={leftArrowRef} />
                <RightArrow scollFunc={scrollContainer} inRef={rightArrowRef} /></div></>
        }
    </>

}
declare global {
    interface Window { opendaily: any }
}

window.opendaily = function (data: any, layout: string, siteData: any,salontime:any) {
    opendailypop({ data, layout, siteData,salontime });
};

function opendailypop({ data, layout, siteData,salontime }: { data: any, layout: string, siteData: any,salontime:any }) {  
    var LoginDetails = utils.ReadCookie();  
    return ReactDom.render(
        <VGDailyDealDetail
            data={data}
            salontime={salontime}
            siteData={siteData}    
            LoginDetails={LoginDetails}        
        />,
        document.getElementById("OpenDialog")
    )
}

function DailyDealCard({ deal, layout, siteData, isEditor, useRef,salontime,isStenciled }: { deal: any, layout: string, siteData: any, isEditor: boolean,isStenciled?:boolean, useRef: React.RefObject<HTMLAnchorElement> | null,salontime:any }) {
    let link = isEditor ? "#" : deal?.dailyDealURL ?? "#";
    var IsEditor = (window as any).isEditor !== undefined ? (window as any).isEditor : false;
    return <a className="daily-deal-card flex-column gap-med no-a" style={{ color: "inherit", flexShrink: 0 , cursor:"pointer"}}
        ref={useRef}
        target="_blank"
        rel="noreferrer"
        onClick={()=> !IsEditor && (window as any).opendaily(deal,layout,siteData,salontime)}
        >
        <div className="" title={deal.promotionTitle}style={{width:"100%",}}>
            <div className="daily-deal-card-inner">
           
           { 
           
            <img
            style={{backgroundImage:`url(${deal.photoUrl })`, backgroundSize:"cover", backgroundRepeat:"no-repeat", backgroundPosition:"center"}}
                className="img-fluid stencil-item"
                src="https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com//PublicImages/Images/img-placeholder32.png"
             />
            }
            {deal.discountHtml.includes("<span")?
            
                <div className="d-price stencil-item" dangerouslySetInnerHTML={{__html:deal.discountHtml}}></div>
                :
                <div className="d-price stencil-item">{deal.discountHtml}</div>

            }
            </div>
        </div>
        <span className="vp-2"><b>{deal.promotionTitle}</b></span>


    </a>


}

function LeftArrow({ scollFunc,inRef }: { scollFunc: (num: number) => void,inRef:React.RefObject<HTMLButtonElement> | null }) {
    return <button ref={inRef} className=" d-arrow-left"
        onClick={() => scollFunc(-1)}

    >
       <i className="fal fa-angle-left"></i>
    </button>
}


function RightArrow({ scollFunc,inRef }: { scollFunc: (num: number) => void,inRef:React.RefObject<HTMLButtonElement> | null }) {
    return <button ref={inRef} className=" d-arrow-right"

        onClick={() => scollFunc(1)}
    >
        <i className="fal fa-angle-right"></i>
    </button>
}