import React, { useEffect, useState } from "react";

import LayoutNode, {
  getNodeComponentAttributeValue,
} from "../Definitions/LayoutNode";

import { PageData, UserContext } from "../Definitions/UserContext";
import {
  BusienssControlNames,
  GenericBusinessControl,
} from "../Binding/GenericBusinessControl";
import HamburgerIcon from "../Icons/Hamburger";
import { GetLinkFromLinkAttribute } from "./RenderButton";
import XIcon from "../Icons/XIcon";
import { DownCaret, RightCaret } from "../Icons/Carets";
import { LinkTypeSeperator, LinkTypes } from "../Definitions/LinkData";
import RenderButtonDropDown from "./RenderButtonDropdown";
import ReactDOM from "react-dom";
import { GetBusinessLogoURL } from "../Utilities/BusinessData";

export default function RenderNavigationBar({
  userContext,
  node,
}: {
  userContext: UserContext;
  node: LayoutNode;
}): JSX.Element {
  const isEditor = (window as any).isEditor == true;
  let logoURL = getNodeComponentAttributeValue(node, "logo", "");
  const mobileLogoUrl = getNodeComponentAttributeValue(node, "mobilelogo", "");
  const pageString = getNodeComponentAttributeValue(node, "pages");
  const pageData = (JSON.parse(pageString) as PageData[]) ?? [];
  const logoSize = getNodeComponentAttributeValue(node, "logoSize", "100px");

  const ctaMargin = "20px";
  const mobileLogoSize = getNodeComponentAttributeValue(
    node,
    "mobilelogoSize",
    "64px"
  );
  const layout = getNodeComponentAttributeValue(node, "navLayout", "1");
  const buttonName = getNodeComponentAttributeValue(node, "buttonName", "");
  const realButtonLink = getNodeComponentAttributeValue(node, "link", "#");

  const isPreview =
    window.location.href.endsWith("/preview") ||
    window.location.href.endsWith("/preview/");

  const ignoreDom = document.getElementById("ignored-pages");

  const ignoreList = ignoreDom ? ignoreDom.innerText.split(",") : [];

  let linearPages: PageData[] = [];
  for (let i = 0; i < pageData.length; i++) {
    let pg = pageData[i];
    linearPages.push(pg);
    if (pg.children) {
      for (let ii = 0; ii < pg.children.length; ii++) {
        linearPages.push(pg.children[ii]);
      }
    }
  }

  let buttonLink = GetLinkFromLinkAttribute(realButtonLink, pageData);
  if (isPreview) buttonLink += "/preview";

  useEffect(() => {

    if(!isEditor)
    FixedCTA();

  },[]);

  function OnLogoError(e: any) {
    let img = e.target as HTMLImageElement;

    if(!img) return;

    let src = img.src;

    if(src.length<1) return; //prevent infinite loop
    
    img.src = "";//remove src to prevent including this elem in the query selector 

    let otherImages = document.querySelectorAll("[src='"+src+"']");
    if(otherImages.length>0)
    GetBusinessLogoURL(userContext,(url)=>{

      for(let i = 0;i<otherImages.length;i++){
        let oimg = otherImages[i] as HTMLImageElement;
        oimg.src = url;
      }


    },()=>{});

  }

  function RenderMobileNavigationList({
    pageData,
    isEditor,
    alignType,
    ignoreList,
  }: {
    pageData: PageData[];
    isEditor: boolean;
    ignoreList: string[];
    buttonLink?: string;
    buttonName?: string;
    alignType?: string;
  }) {
    enum MobileNavState {
      Closed,
      Open,
      OpenToSubMenu,
    }

    const openToSubMenu = (inPage: PageData) => {
      if (
        navState === MobileNavState.OpenToSubMenu &&
        subMenu &&
        subMenu.pageId === inPage.pageId
      ) {
        setNavState(MobileNavState.Open);
        setSubMenu(null);
      } else {
        setNavState(MobileNavState.OpenToSubMenu);
        setSubMenu(inPage);
      }
    };

    const [navState, setNavState] = useState<MobileNavState>(
      MobileNavState.Closed
    );
    const [subMenu, setSubMenu] = useState<PageData | null>(null);
    if (MobileNavState.Open) {
      document.body.classList.add("mblmenuOpen");
    }
    if (navState == MobileNavState.Closed) {
      document.body.classList.remove("mblmenuOpen");
    }
    const flexClass = alignType == "right" ? "flex-row-reverse" : "flex-row";

    const clickOutsideRef = React.useRef(null);
    const clickOutside = (e: any) => {
      
      if(clickOutsideRef.current){

        let current = clickOutsideRef.current as any;

        if(e.clientY>current.getBoundingClientRect().bottom){
          setNavState(MobileNavState.Closed);
        }
      }
  
    };

    useEffect(() => {
      document.addEventListener("click", clickOutside);
      return () => {
        document.removeEventListener("click", clickOutside);
      };
    }, []);


    if (navState == MobileNavState.Closed)
      return (
        <div
          className={
            "mobile-navigation-menu w-full justify-between align-center " +
            flexClass
          }
        >
          <div className="flex-row">
            <div
              onClick={() => setNavState(MobileNavState.Open)}
              className="mobile-navigation center-all"
              style={{
                cursor: "pointer",
                fontSize: "30px",
                margin: " 0px",
                width: "32px",
                height: "32px",
              }}
            >
              <HamburgerIcon />
            </div>
          </div>

          {alignType == "center" && (
            <div style={{ padding: "12px", width: mobileLogoSize }}></div>
          )}
        </div>
      );
    else {
      const pages: PageData[] = pageData;

      const hhight = document.querySelector(".wbheader")?.clientHeight;
      return (
        <div
  
          className={
            "mobile-navigation-menu w-full justify-between align-center  " +
            flexClass
          }
        >
          <div className="flex-row ">
            <div
              id={"MobileNavOnClick"}
              onClick={() => setNavState(MobileNavState.Closed)}
              className="mobile-navigation center-all"
              style={{
                width: "32px",
                height: "32px",
                cursor: "pointer",
                fontSize: "30px",
                margin: "0px",
              }}
            >
              <XIcon />
            </div>
          </div>
          <div
          ref={clickOutsideRef}
            className="mobile-navigation-content"
            style={{
              maxHeight: `calc(90vh - ${hhight}px)`,
              overflow: "auto",
              paddingBottom: "env(safe-area-inset-bottom)",
            }}
          >

            <div className="mobile-navigation-scroll-content">

            {pages.map((item, index) => {
              if (item.isDisabled || item.isHidePage) return null;

              return (
                <>
                  <RenderNavListItemMobile
                    key={index}
                    openToMenu={openToSubMenu}
                    item={item}
                    isEditor={isEditor}
                    ignoreList={ignoreList}
                    selected={
                      navState === MobileNavState.OpenToSubMenu &&
                      subMenu != null &&
                      subMenu.pageId == item.pageId
                    }
                  />
                  {subMenu?.pageId === item.pageId &&
                    navState == MobileNavState.OpenToSubMenu && (
                      <div className="mobile-submenu">
                        {item.children?.map((child, index) => {
                          return (
                            <RenderNavListItemMobile
                              key={index}
                              openToMenu={() => {}}
                              item={child}
                              isEditor={isEditor}
                              ignoreList={ignoreList}
                              isNested={true}
                            />
                          );
                        })}
                      </div>
                    )}
                </>
              );
            })}
          {buttonLink &&
              buttonLink.length > 0 &&
              buttonName &&
              buttonName.length > 0 &&
           (
                <div
                  className="mobile-navigation"
                  style={{ padding: "4px 8px" }}
                >
              <CTAButton classAdd="w-full navigation-item"/>
                </div>
              )}


            {
              <div className="mobile-navigation ">
                <div
                  className="w-full flex-row justify-center mob-nav-list-item"
                  onClick={(e) => {
                    if (e.target) {
                      if (
                        (e.target as HTMLElement).classList.contains("v-login")
                      ) {
                        setNavState(MobileNavState.Closed);
                      }
                    }
                  }}
                >
                  <div
                    className="w-full center-all mobilebusinesscontrols"
                    style={
                      isEditor
                        ? {
                            pointerEvents: "none",
                            padding: "8px 8px",
                            lineHeight: "2em",
                          }
                        : { paddingTop: "4px", lineHeight: "2em" }
                    }
                    onClick={() => {
                      setNavState(MobileNavState.Closed);
                    }}
                  >
                    <GenericBusinessControl
                      layout={"2"}
                      dataKey="myaccount"
                      node={node}
                      userContext={userContext}
                    />
                  </div>
                </div>
                
              </div>
            }
            </div>
          </div>

          {alignType == "center" && (
            <div style={{ padding: "12px", width: mobileLogoSize }}></div>
          )}
        </div>
      );
    }
  }

  function RenderNavListItemMobile({
    item,
    openToMenu,
    isEditor,
    ignoreList,
    isNested,
    selected,
  }: {
    item: PageData;
    openToMenu: (pageData: PageData) => void;
    isEditor: boolean;
    ignoreList: string[];
    isNested?: boolean;
    selected?: boolean;
  }) {
    if (item.isFolder && (!item.children || item.children.length < 1)) {
      return null;
    }

    if (!item.children || item.children.length == 0) {

      if(item.isDisabled || item.isHidePage) return null;

      const link = GetLinkFromItem(item);
      if (link === buttonLink) {
        return null;
      }

      return (
        <div className="mobile-navigation" id={"mobile-" + item.url}>
          <a
            className="no-a navigation-item"
            style={
              isNested ? { paddingLeft: "32px", background: "#0000000f" } : {}
            }
            id={item.pageId}
            href={link}
          >
            {item.name}
          </a>
        </div>
      );
    } else {

      let index = item.children.findIndex((x) => !x.isDisabled&&!x.isHidePage && !ignoreList.includes(x.url));

     
      if (index <0 ) return null;

      return (
        <div
          className="no-link navigation-item"
          onClick={() => {
            openToMenu(item);
          }}
        >
          <span className="flex-row gap-med">
            {item.name} {selected ? <DownCaret /> : <RightCaret />}{" "}
          </span>
        </div>
      );
    }
  }

  const GetLinkFromItem = (item: PageData) => {
    let link = item.url;
    if (isPreview) link += "/preview";

    return link;
  };

  const FullNavItem = ({
    item,
    ignoreList,
  }: {
    item: PageData;
    ignoreList: string[];
  }) => {
    if (item.isDisabled || item.isHidePage) return null;

    const hrefLink = GetLinkFromItem(item);

    let activeClass = "";
    let current = window.location.href.split("/").pop()?.replace("/", "");
    activeClass = hrefLink === current ? "active" : "";
    if ((window as any).isEditor == true) {
      if ((window as any).currentvpage === item.pageId) {
        activeClass = "active";
      } else if (item.isFolder && item.children) {

      

        if (item.children.length < 1) return null;
        let index = item.children.findIndex((x) => !x.isDisabled&&!x.isHidePage && !ignoreList.includes(x.url));

        if(index<0)
          return null;

        for (let i = 0; i < item.children.length; i++) {
          if ((window as any).currentvpage === item.children[i].pageId) {
            activeClass = "active";
            break;
          }
        }
      }
    } else {
      if (item.isLandingPage) {
        // extra check to show the hompage as active when no page is selected
        if (window.location.href.includes("mysite")) {
          let index = window.location.href.indexOf(".com/");
          let newIndex = window.location.href.indexOf("/", index + 5);

          if (newIndex < 0 || newIndex >= window.location.href.length - 1)
            activeClass = "active";
        } else if (window.location.href.indexOf("/", 8) < 0) {
          // on normal website if there is no slash after https:// then it is home page as well
          activeClass = "active";
        }
      }
    }

    if (item.children && item.children.length > 0) {

      //check to see how many children are hidden
      let index = item.children.findIndex((x) => !x.isDisabled&&!x.isHidePage && !ignoreList.includes(x.url));

      //if all are hidden don't show the folder
      if (index <0) {
        return null;
      }

      for (let i = 0; i < item.children.length; i++) {
        if (current === item.children[i].url) {
          activeClass = "active";
          break;
        }
      }
    }

    return (
      <li id={item.url}>
        {item.isFolder ? (
          <span className={"no-a navigation-item " + activeClass}>
            {item.name}
          </span>
        ) : (
          <a
            className={"no-a navigation-item " + activeClass}
            href={GetLinkFromItem(item)}
          >
            {item.name}
          </a>
        )}

        {
          // create nested list
          item.isFolder && item.children && item.children.length > 0 && (
            <ul>
              {item.children.map((child, index) => {
                if (child.isDisabled || child.isHidePage) return null;

                return (
                  <FullNavItem
                    item={child}
                    key={index}
                    ignoreList={ignoreList}
                  />
                );
              })}
            </ul>
          )
        }
      </li>
    );
  };

  const homeLink = isPreview ? "#" : "./";

  const BusinessLogo = () => {
    if (!logoURL || logoURL.length < 1) return null;

    return (
      <a
        className="no-a"
        style={{ display: "block", width: "auto" }}
        href={"./"}
      >
        <img
          loading="lazy"
          src={logoURL}
          style={{
            height: logoSize,
            padding: "12px",
            zIndex: "10",
            pointerEvents: "none",
          }}
        />
      </a>
    );
  };
  const BusinessLogoMobile = () => {
    if (!mobileLogoUrl || mobileLogoUrl.length < 1) return null;

    return (
      <a
        className="no-a"
        style={{ display: "block", width: "auto" }}
        href={"./"}
      >
        {" "}
        <img
          loading="lazy"
          src={mobileLogoUrl}
          style={{
            height: mobileLogoSize,
            padding: "12px",
            zIndex: "10",
            pointerEvents: "none",
            maxWidth: "100%",
          }}
        />
      </a>
    );
  };
  const CTAButton = ({ classAdd,ignoreOnSamePage }: { classAdd: string,ignoreOnSamePage?:boolean }) => {
    if (buttonName.length < 1) return null;

    let missingLink = "";

    if (isEditor) {
      missingLink =
        buttonLink.length < 3 ? " missing-link b-link-" + buttonLink : "";
    }

    if (realButtonLink.includes(LinkTypes.MultiPage)) {
      let linkString = realButtonLink.split(LinkTypeSeperator)[0];

      if (linkString.length > 2) {
        let pages = linkString.split(",");

        let foundPages: { url: string; name: string }[] = [];

        for (let i = 0; i < linearPages.length; i++) {
          let lpage = linearPages[i];
          if (pages.includes(lpage.pageId) && !ignoreList.includes(lpage.url)) {
            
    
            //ignore if on same page
            if(ignoreOnSamePage&&(window.location.href.endsWith("/"+lpage.url)||window.location.href.endsWith("/"+lpage.url+"/preview")))
              return null;

            foundPages.push({ url: lpage.url, name: lpage.name });
          }
        }

        if (foundPages.length > 1) {
          return (
            <div
              className={"v-button primary relative inherit-font-w " + classAdd}
            >
              <RenderButtonDropDown
                component={node}
                pages={foundPages}
                inContent={buttonName}
              />
            </div>
          );
        } else if (foundPages.length == 1) {
          return (
            <a
              href={foundPages[0].url}
              className={
                "v-button primary  invisible-button" +
                missingLink +
                " " +
                classAdd
              }
            >
              {buttonName}
            </a>
          );
        }

        return <></>;
      }
    }

    else if(window.location.href.endsWith("/"+buttonLink))
      return null;

    return (
      <a
        href={buttonLink}
        className={
          "v-button primary invisible-button" + missingLink + " " + classAdd
        }
      >
        {buttonName}
      </a>
    );
  };

  const MapPagesFull = ({
    items,
    startIndex = 0,
    endIndex = 1000,
    forceWidth,
  }: {
    startIndex: number;
    endIndex: number;
    items: PageData[];
    forceWidth?: boolean;
  }) => {
    return (
      <>
        {items.map((item, index) => {
          if (index >= startIndex && index <= endIndex)
            return (
              <FullNavItem item={item} key={index} ignoreList={ignoreList} />
            );
          else return null;
        })}
      </>
    );
  };
  

  const FixedCTA = () => {

    let dom = document.getElementById("cta-root-dom");

    if(!dom){
      dom = document.createElement("div");
      dom.id = "cta-root-dom";
      document.body.appendChild(dom);


      ReactDOM.render(       <div className="cta-container">
      <CTAButton classAdd=" w-full"  ignoreOnSamePage={true} />
    </div>,dom

      )
    }

    // return (

    // );
  };


  const LogoTest = ()=>{

    let url = getNodeComponentAttributeValue(node, "bLogoTest", "");

    if(url.length<1) return null;

    return <img src={url} onError={OnLogoError} style={{display:"none"}}/>
  }


  const DefaultLayout = ({
    hAlign,
    vAlign,
  }: {
    hAlign: string;
    vAlign: string;
  }) => {
    return (
      <>{!isEditor&&<LogoTest/>}
        {isEditor&&<div className="cta-container">
      <CTAButton classAdd=" w-full"  ignoreOnSamePage={true} />
    </div>}
        <div className={"w-full  flex-row justify-start gap-med " + vAlign}>
          <div className="full-screen-navigation" style={{ flexGrow: 0 }}>
            <BusinessLogo />
          </div>
          <div
            className="mobile-navigation w-full flex-row jusity-end"
            style={{ flexGrow: 0 }}
          >
            <BusinessLogoMobile />
          </div>
          <nav
            className={
              " full-screen-navigation flex-row gap-med main-navigation  flex-wrap w-full " +
              hAlign +
              " " +
              vAlign
            }
          >
            <MapPagesFull items={pageData} startIndex={0} endIndex={100} />
          </nav>

          <div
            className={"account-container flex-row r justify-end " + vAlign}
            style={{ flexGrow: 0, paddingRight: "8px" }}
          >
            <div
              className="full-screen-navigation"
              style={{ marginRight: ctaMargin, marginLeft: "12px" }}
            >
              <CTAButton classAdd="" />
            </div>
            <AccountControls />
            <div className="mobile-navigation flex-row justify-end">
              <RenderMobileNavigationList
                ignoreList={ignoreList}
                pageData={pageData}
                isEditor={isEditor}
                buttonLink={buttonLink}
                buttonName={buttonName}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  const AccountControls = ({
    overrideStyle,
    addClass,
  }: {
    addClass?: string;
    overrideStyle?: {};
  }) => {
    return (
      <div
        className=" gap-med account-controls  font-med"
        style={{ marginBottom: "2px" }}
      >
        <div className="cart-holder">
          <GenericBusinessControl
            dataKey={BusienssControlNames.cart}
            userContext={userContext}
            node={node}
            layout={""}
          />
        </div>
        <div id="account-holder">
          <GenericBusinessControl
            layout={""}
            dataKey="myaccount"
            node={node}
            userContext={userContext}
          />
        </div>
      </div>
    );
  };

  const LayoutTall = ({
    hAlign,
    vAlign,
  }: {
    hAlign: string;
    vAlign: string;
  }) => {
    const margin = logoURL && logoURL.length > 0 ? logoSize : "0px";

    return (
      <>{!isEditor&&<LogoTest/>}
        {" "}
        {isEditor&&<div className="cta-container">
      <CTAButton classAdd=" w-full"  ignoreOnSamePage={true} />
    </div>}
        <div className={"w-full flex-row justify-start  gap-med " + vAlign}>
          <div
            className="full-screen-navigation"
            style={{ paddingLeft: "120px" }}
          >
            {buttonName && buttonName.length > 0 && (
              <div
                className="v-button primary"
                style={{ visibility: "hidden" }}
              >
                {buttonName}
              </div>
            )}
          </div>
          <div
            className="mobile-navigation w-full flex-row jusity-end"
            style={{ flexGrow: 0 }}
          >
            <BusinessLogoMobile />
          </div>
          <div className=" full-screen-navigation w-full  flex-column align-center justify-center">
            <div>
              <BusinessLogo />
            </div>
            <nav
              className={
                " full-screen-navigation flex-row gap-med main-navigation  flex-wrap w-full " +
                hAlign +
                " " +
                vAlign
              }
            >
              <MapPagesFull items={pageData} startIndex={0} endIndex={100} />
            </nav>
          </div>
          <div className="flex-column">
            <div
              className="full-screen-navigation"
              style={{ height: margin }}
            ></div>
            <div
              className={"account-container flex-row  justify-end " + vAlign}
            >
              <div
                className="full-screen-navigation"
                style={{ marginRight: ctaMargin, marginLeft: "12px" }}
              >
                <CTAButton classAdd="" />
              </div>
              <AccountControls />
              <div className="mobile-navigation flex-row justify-end">
                <RenderMobileNavigationList
                  ignoreList={ignoreList}
                  pageData={pageData}
                  isEditor={isEditor}
                  buttonLink={buttonLink}
                  buttonName={buttonName}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const LayoutSplit = ({
    hAlign,
    vAlign,
  }: {
    hAlign: string;
    vAlign: string;
  }) => {
    const mid = Math.floor(pageData.length / 2);

    return (
      <>{!isEditor&&<LogoTest/>}
       {isEditor&&<div className="cta-container">
      <CTAButton classAdd=" w-full"  ignoreOnSamePage={true} />
    </div>}
        <div className="w-full flex-row justify-start align-center  gap-med ">
          <div
            className="full-screen-navigation"
            style={{ flexGrow: 0, width: "100px" }}
          ></div>
          <div
            className="mobile-navigation w-full flex-row jusity-end"
            style={{ flexGrow: 0 }}
          >
            <BusinessLogoMobile />
          </div>
          <nav
            className={
              " full-screen-navigation flex-row gap-med main-navigation flex-wrap w-full " +
              hAlign +
              " " +
              vAlign
            }
          >
            <MapPagesFull
              items={pageData}
              startIndex={0}
              endIndex={mid - 1}
              forceWidth={true}
            />
            <BusinessLogo />
            <MapPagesFull
              items={pageData}
              startIndex={mid}
              endIndex={100}
              forceWidth={true}
            />
          </nav>

          <div
            className={"account-container flex-row justify-end   " + vAlign}
            style={{ flexGrow: 0 }}
          >
            <div
              className="full-screen-navigation"
              style={{ marginRight: ctaMargin, marginLeft: "12px" }}
            >
              <CTAButton classAdd="" />
            </div>
            <AccountControls />
            <div className="mobile-navigation flex-row justify-end">
              <RenderMobileNavigationList
                ignoreList={ignoreList}
                pageData={pageData}
                isEditor={isEditor}
                buttonLink={buttonLink}
                buttonName={buttonName}
              />
            </div>
          </div>
        </div>
      </>
    );
  };
  switch (layout) {
    default:
      return <DefaultLayout vAlign="align-center" hAlign="justify-center" />;

    case "2":
      return <DefaultLayout vAlign="align-end" hAlign="justify-center" />;
    case "3":
      return <LayoutSplit vAlign="align-center" hAlign="justify-center" />;
    case "4":
      return <LayoutSplit vAlign="align-end" hAlign="justify-center" />;
    case "5":
      return <DefaultLayout vAlign="align-center" hAlign="justify-start" />;
    case "6":
      return <DefaultLayout vAlign="align-end" hAlign="justify-start" />;
    case "7":
      return <DefaultLayout vAlign="align-center" hAlign="justify-end" />;
    case "8":
      return <DefaultLayout vAlign="align-end" hAlign="justify-end" />;
    case "9":
      return <LayoutTall vAlign="align-center" hAlign="justify-center" />;
  }
}
