import React from 'react';
import { GetNodeDomAttributes } from '../Utilities/GetNodeDomAttributes';
import  { getNodeComponentAttributeValue } from '../Definitions/LayoutNode';
import { WBRenderProps } from '../Definitions/WBRenderProps';


export default function RenderLayout({ component, children, screenSize,isEditor,userContext: siteData }: WBRenderProps): JSX.Element {
       
    // return         let sliderContainer = createGenericDom('div', 'before-after-container update-on-save');

    const imageList = getNodeComponentAttributeValue(component,"beforeAndAfterList","").trim();
    const imageListArray = imageList.split(",");
   
    const imgA = imageListArray.length>0&&imageListArray[0].length>1?"url("+imageListArray[0]+")":"grey";
    const imgB = imageListArray.length>1?"url("+imageListArray[1]+")":"#333";



    // sliderContainer.innerHTML = "<div class='b-a-img after' style=''></div><div class = 'b-a-img before''></div><input type='range' class='slider' min='0' max='100' value='50' oninput='beforeAfterOnInput(this);'/>";

    return <>
    
        <style>{bAndACSS}</style>
        <div {...GetNodeDomAttributes("v-before-after before-after-container max-100",component,screenSize,isEditor)}>

        <div className='b-a-img after'
            style={{background:imgB,backgroundPosition:"left center",backgroundSize:"cover"}}
        > 
        </div>
        <div className = 'b-a-img before'
                 style={{background:imgA,backgroundPosition:"left center",backgroundSize:"cover"}}
        >
        </div>
        <input type='range' className='slider' min='0' max='100' defaultValue='50' onInput={(e)=>{


            let slider = e.target as HTMLInputElement;
            if(slider.parentElement&&slider.parentElement.children.length>=1){

                let before = slider.parentElement.children[1] as HTMLElement;
                let width =  slider.value + "%";
                 before.style.clipPath = `polygon(0 0,${width} 0, ${width} 100%, 0 100%)`
            
            }

            
        }} />
        </div>
        </>
   
   }





const bAndACSS = `
.before-after-container {
    position: relative;
    width: 800px;
    height: 600px;
    overflow: hidden;
}

    .before-after-container > .b-a-img {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        bottom: 0;
        background-position: left center !important;
        background-size: cover !important;
    }

    .before-after-container > input {
        position: absolute;
        top: 0;
        left: 0px;
        width: 100%; /*required for firefox*/
        height: 100%;
        margin: 0;
        padding: 0;
        -webkit-appearance: none; /* Override default CSS styles */
        appearance: none;
        background: none;
        cursor: ew-resize;
        opacity: 0;
    }

.b-a-img.before.animate {
    animation: slider-bounce 4s steps(40) infinite;
}

.b-a-img.before {
   clip-path: polygon(0 0,50% 0, 50% 100%, 0 100%);
}







.canvas.slider{
    pointer-events: none;
}

`