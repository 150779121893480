import axios from "axios";
import { utils, Site } from "../Utils/Utils";

window.APIURL = Site.VagaroCoreApiUrl;

async function AsyncAjaxPost(Url, data, access_token, callback, onFailure) {
    let Group = localStorage.getItem('Group')
    if (!utils.Validate(Group)) {
        Group = "us02";
    }
    if (utils.Validate(Group)) {
        window.VagaroCoreApiUrl = Window.APIURL + "/" + Group + "/api/v2/";
    }
    else {
        window.VagaroCoreApiUrl = Window.APIURL + "/api/v2/";
    }
    const APIURL = window.VagaroCoreApiUrl + Url;
    const header = {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + access_token
    };
    await axios.post(APIURL, data, { headers: header })
        .then((response) => { callback(response) })
        .catch((error) => { onFailure(error) });
};

async function AsyncAjaxGet(Url, access_token, callback, onFailure) {
    let Group = localStorage.getItem('Group')
    if (!utils.Validate(Group)) {
        Group = "us02";
    }
    if (utils.Validate(Group)) {
        window.VagaroCoreApiUrl = Window.APIURL + "/" + Group + "/api/v2/";
    }
    else {
        window.VagaroCoreApiUrl = Window.APIURL + "/api/v2/";
    }
    const APIURL = window.VagaroCoreApiUrl + Url;
    const header = {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + access_token
    };
    await axios.get(APIURL, { headers: header })
        .then((response) => { callback(response) })
        .catch((error) => { onFailure(error) });
};

async function AsyncAjaxGetWithBody(Url, access_token, callback, onFailure) {
    let Group = localStorage.getItem('Group')
    if (!utils.Validate(Group)) {
        Group = "us02";
    }
    if (utils.Validate(Group)) {
        window.VagaroCoreApiUrl = Window.APIURL + "/" + Group + "/api/v2/";
    }
    else {
        window.VagaroCoreApiUrl = Window.APIURL + "/api/v2/";
    }
    const APIURL = window.VagaroCoreApiUrl + Url;
    const headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + access_token
    };
    await axios.get(APIURL, { headers: headers })
        .then((response) => { callback(response) })
        .catch((error) => { onFailure(error) });
};

async function AsyncAjaxPut(Url, access_token, data, callback, onFailure) {
    let Group = localStorage.getItem('Group')
    if (!utils.Validate(Group)) {
        Group = "us02";
    }
    if (utils.Validate(Group)) {
        window.VagaroCoreApiUrl = Window.APIURL + "/" + Group + "/api/v2/";
    }
    else {
        window.VagaroCoreApiUrl = Window.APIURL + "/api/v2/";
    }
    const APIURL = window.VagaroCoreApiUrl + Url;
    const headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + access_token
    };
    await axios.put(APIURL, data, { headers: headers })
        .then((response) => { callback(response) })
        .catch((error) => { onFailure(error) });
};

//async function AsyncAjaxDelete(Url, access_token, callback, onFailure) {
//    const APIURL = window.VagaroCoreApiUrl + Url;
//    const headers = {
//        "Content-Type": "application/json",
//        "Authorization": "Bearer " + access_token
//    };
//    await axios.delete(APIURL, { headers: headers })
//        .then((response) => { callback(response) })
//        .catch((error) => { onFailure(error) });
//};

async function AsyncAjaxPatch(Url, data, access_token, callback, onFailure) {
    let Group = localStorage.getItem('Group')
    if (!utils.Validate(Group)) {
        Group = "us02";
    }
    if (utils.Validate(Group)) {
        window.VagaroCoreApiUrl = Window.APIURL + "/" + Group + "/api/v2/";
    }
    else {
        window.VagaroCoreApiUrl = Window.APIURL + "/api/v2/";
    }
    const APIURL = window.VagaroCoreApiUrl + Url;
    const headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + access_token
    };
    await axios.patch(APIURL, data, { headers: headers })
        .then((response) => { callback(response) })
        .catch((error) => { onFailure(error) });
};

async function AjaxGenerateToken(Url, data, callback, onFailure) {
    let Group = localStorage.getItem('Group');
    if (!utils.Validate(Group)) {
        Group = "us02";
    }
    if (utils.Validate(Group)) {
        window.VagaroCoreApiUrl = Window.APIURL + "/" + Group + "/api/v2/";
    }
    else {
        window.VagaroCoreApiUrl = Window.APIURL + "/api/v2/";
    }
    const APIURL = window.VagaroCoreApiUrl + Url;
    const header = {
        "Content-Type": "application/json",
    };
    await axios.post(APIURL, data, { headers: header })
        .then((response) => { callback(response) })
        .catch((error) => { onFailure(error) });
};

export { AsyncAjaxPost, AsyncAjaxGet, AsyncAjaxPut,  AsyncAjaxPatch, AsyncAjaxGetWithBody, AjaxGenerateToken };
