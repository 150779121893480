export const  ProductDetailsHTML = `<div class="hierarchy v-page-root" style="cursor:default" id="e18043425a67cd429420fd5d835303f4">
<div class="hierarchy v-section product-details-section " id="vz3676b847b3c7514ac0e6f07d25adad9" componenttype="Section" >
   <div class="section-buffer" style="width:calc(50% - var(--half-width))"></div>
   <div class="hierarchy v-col " id="vS003ca8c74df1960a13571d27fa8698f" style="align-items:center; justify-content:center;gap:4px;" componenttype="Column">

         <div id="product-details" class="vb-control" data-link-key="productDetails" layout="1"></div>
  
   </div>
   <div class="section-buffer" style="width:calc(50% - var(--half-width))"></div>
</div>
</div>`