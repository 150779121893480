const alphebet = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";

export default function CreateNewGUID(){
    //issue with UUID import, src modified from https://www.tutorialspoint.com/how-to-create-guid-uuid-in-javascript

    var dt = new Date().getTime();
    let replace =  'xxxxxxxxxxxxxxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g,
    function( c ) {
       var rnd = Math.random() * 16;//random number in range 0 to 16
       rnd = (dt + rnd)%16 | 0;
       dt = Math.floor(dt/16);
       return (c === 'x' ? rnd : (rnd & 0x3 | 0x8)).toString(16);
    });

   // ids need to always start with a letter in order to be targeted via #{someid} in css
    const char = alphebet[Math.floor(Math.random() * alphebet.length)];
  
    return char+replace; 

}