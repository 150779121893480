import VGPopUp from '../components/Controls/VGPopUp';
import Dropdown from 'react-bootstrap/Dropdown';
import React, { useEffect, useState, useRef, Fragment } from 'react'
import '../VagaroComponent/DailyDealDetail.scss'
import ReactDOM, { createPortal, unmountComponentAtNode } from "react-dom";
import { AsyncAjaxGet, AsyncAjaxPost } from '../components/Utilities/AsyncAjaxService';
import Loader from '../components/Common/Loader';
import VgSendEmailPopup from './SendEmailPopup';
import { Site, addtoCartEnum, utils } from '../utils/Utils';
import CommonMessagepopup from '../VagaroComponent/CommonMessagepopup';
import GiftCertificatePopup from '../VagaroComponent/GiftCertificatePopup';
import $ from 'jquery';
import "../utils/select2_4.0.js";
import "../Style/select2.css"
import SingleDateRangePicker from './Controls/SingleDateRangePicker'
import moment from "moment";
import { destroydatepicker } from "./Controls/VagaroDateTimePicker";
import GroupSelect from "./Controls/GroupSelect";
import Toast from '../components/Utilities/Toast';
import _siteBuilderSocialMedia from "../../../vagaro-website-rendering/src/utils/sb-socialmediasharing";
import RenderBusinessCart from "../components/BusinessControls/RenderBusinessCart";
import GroupSingleSelect from './Controls/GroupSingleSelect';
const VGDailyDealDetail = (props) => {
    const [bussinessReview, setBussinessReview] = useState([]);
    const [availableDatesdata, setAvailableDatesdata] = useState([]);
    const [selectservice, setSelectservice] = useState("");
    
    const selectedservicetemp = useRef('');
    const [selectTime, setSelectTime] = useState(moment(props.salontime).format('MMM DD, YYYY'));
    const selectedtimeq = useRef(moment(props.salontime).format('MMM DD, YYYY'));
    const [isdailydealsloading, setIsdailydealsloading] = useState(false);
    const [bussinessReviewtotal, setBussinessReviewTotal] = useState(0);
    const [isvalidselectGcprice, setIsvalidSelectGcprice] = useState(false);
    const [selectGC, setSelectGC] = useState('');
    const [membershipdata, setMembershipdata] = useState([]);
    const [eventtype, setEventtype] = useState();
    const [servicedata, setServicedata] = useState([]);
    const [services, setServices] = useState([]);
    const [giftCertificatedata, setGiftCertificatedata] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [validatedate, setValidatedate] = useState("");
    const [totalused, settotaluse] = useState(0);
    const [show, setShow] = useState(false);
    const [iselectvalid, setIselectvalid] = useState(false);
    const siteData = props.siteData;
    const DailyDealsdata = props.data;
    const target = useRef(null);
    const salontime = props.salontime;
    const Merchantdata = utils.MultiLocationData(props.siteData, props.siteData.bidHash);
    const VagaroAPIEnum = {
        promotiondetails: siteData.businessId + "/promotions/promotiondetails",
        reviewpercentage: "merchants/" + siteData.businessId + "/reviews/reviewpercentage",
        memerbshipaddtocart: siteData.businessId + "/addtocart/membershipaddtocart",
        packageaddtocart: siteData.businessId + "/addtocart/serviceaddtocart"
    };

    const MinDate = availableDatesdata[0];
    const MaxDate = availableDatesdata[availableDatesdata.length - 1];

    useEffect(() => {
        GetDailyDealsDetail(DailyDealsdata.encPromotionID);
        BussinessReview();
        validetdate();
        totalUse();

    }, []);

    const closedaterange = () => {
        $("#VgReviewByEmployee .modal-body").on("scroll", function () {
            var datetimepicker = $(".xdsoft_datetimepicker");
            setTimeout(() => {
                datetimepicker.trigger("close.xdsoft");
            }, 5);
        });
    };
    const closeGCeDropdowMenu = () => {
        $("#VgReviewByEmployee .modal-body").on("scroll", function () {
            console.log("d");
            $("#ddlserviceSearchGC").select2("close");
        });
    }
    const openPopupfb = (e, link) => {
        var facebook = {
            id:"",
            callfrom:"Deals",
            BusinessName: Merchantdata.businessName,
            titletimestamp: props.data.dailyDealURL.split("deals/")[1] ? props.data.dailyDealURL.split("deals/")[1].includes("?") ?props.data.dailyDealURL.split("deals/")[1].split("?")[0] :props.data.dailyDealURL.split("deals/")[1]  : "",
            vagaroURL: Merchantdata.vagaroURL,
            Styletitle: props.data.promotionDesc.replaceAll('&',"@@"),
            image: props.data.photoUrl,
            BusinessUrl: "",
            msg:  'Deals has been posted.',
          }
        var jsonData = JSON.stringify(facebook).replace(/\\\"/g, '"').replace(/"/g, "\\\"");
        var fbShareURl = utils.VURL(null, "merchants/facebookPost","facebookshare")
        if (navigator.userAgent && navigator.userAgent.toLowerCase().includes("iphone")) {
            window.open(`https://www.facebook.com/sharer/sharer.php?u=${props.data.photoUrl}`, "pop", "width=600, height=400, scrollbars=no");
        }else{
            window.wbc.openVagaroPage("divVagaroIframe", fbShareURl + "?Type=Builder&Event=FaceBookShare&Data=" + jsonData);
        }
        return;
    }
    const totalUse = () => {
        var totaldata = "";
        if (DailyDealsdata.totalUsed === 0) {
            totaldata = DailyDealsdata.totalUsage;
        }
        else {
            totaldata = DailyDealsdata.totalUsage - DailyDealsdata.totalUsed;
        }
        settotaluse(totaldata);
    }
    const validetdate = () => {
        let validetdatetime = "";
        var vSalonDate = salontime;
        var vStartDate = DailyDealsdata.sDate;
        var vEndDate = DailyDealsdata.eDate;
        if (vStartDate <= vSalonDate) {
            validetdatetime = "Now" + (vEndDate == "" ? "" : " to " + vEndDate);
        }
        else {
            validetdatetime = vStartDate + (vEndDate == "" ? "" : " to " + vEndDate);
        }
        setValidatedate(validetdatetime);
    }
    const openPopup = (e, type) => {
        e.preventDefault();
       
        const twitter = DailyDealsdata.dailyDealURL + "&text=Check out this deal on Vagaro to for this business: " + Merchantdata.businessName + "<br />" + DailyDealsdata.promotionTitle + "&related=vagaro";

        var description = DailyDealsdata.promotionTitle + " , " + Merchantdata.businessName + " ,Valid:" + DailyDealsdata.discountHtml;

        var PinUrl = DailyDealsdata.dailyDealURL + "&text=" + DailyDealsdata.promotionTitle + " - Valid:" + DailyDealsdata.discountHtml + "&media=" + DailyDealsdata.photoUrl + "&description=" + description + "";
        console.log(window.location.href)
        if (type === "Twitter") {
            _siteBuilderSocialMedia.PostOnTwitter(escape(twitter));
        }
        else if (type === "pintrest") {
            _siteBuilderSocialMedia.PostOnPint(escape(PinUrl));
        }
        else {
            // _siteBuilderSocialMedia.PostonFacebook("", 'Deals', facebook);
        }

    };
    const Replacemembership = (data) => {
        MembershipAddCart(data, true);
        unmountComponentAtNode(document.getElementById("OpenDialog1"));
    }
    const Addtocart = (data, event) => {
        if (data.itemTypeID === 3) {
            PackageAddtoCart(data, event);
        }
        else {
            MembershipAddCart(data, false, event);
        }

    }

    const MembershipAddCart = async (memberShipData, action, event) => {
        var LoginDetails = utils.ReadCookie();
        if (LoginDetails.EncUid === undefined || LoginDetails.EncUid === "" || LoginDetails.EncUid === null) {
            window.wbc.openVagaroPage("divVagaroIframe", utils.VURL("Login", null) + addtoCartEnum.MemebrshipAddToCart + "&Data=" + event);
            $(".modal-backdrop").hide();
            $(".vgn-popup").hide();
            return;
        }
        setIsdailydealsloading(true)
        const Requestdata = {};
        Requestdata.EncMid = memberShipData.encID;
        Requestdata.promotionDetail = memberShipData.promotionDetail;
        Requestdata.promotionTitle = memberShipData.promotionTitle;
        Requestdata.ServiceDesc = memberShipData.description;
        Requestdata.EncUid = LoginDetails.EncUid;
        Requestdata.isCustomPackage = false;
        Requestdata.noOfVisit = 0;
        Requestdata.lstAddonService = [];
        Requestdata.userBusinessMembershipId = "";
        Requestdata.IsOverride = action ? true : false;
        await AsyncAjaxPost(
            VagaroAPIEnum.memerbshipaddtocart,
            Requestdata,
            siteData.accessToken,
            function onSuccess(data) {
                var msg = data.data.data;
                setIsdailydealsloading(false);
                if (msg.isMemberShipExiting === true) {
                    return ReactDOM.render(
                        <CommonMessagepopup
                            IsMembeship={true}
                            Replacemembership={() => Replacemembership(memberShipData)}
                        />,
                        document.getElementById("OpenDialog1")
                    )

                } else if (msg.isMebershipPurchased === true) {
                    return ReactDOM.render(
                        <CommonMessagepopup
                            IsMembeshippurchased={true}
                        />,
                        document.getElementById("OpenDialog1")
                    )

                }
                else if ((msg.isMebershipPurchased === false && msg.isMemberShipExiting === false)) {
                    return ReactDOM.render(
                        <VGPopUp
                            title={"Vagaro"}
                            isShowFooter={true}
                            show={true}
                            isRemoveTabModalIndex={false}
                            onHide={PopupClosepackagemembership}
                            body={<div>Item has been Added to Cart Successfully. Do you want to Checkout ?</div>}
                            footer={<>
                                <button class="v-button secondary invisible-button" onClick={() => PopupClosepackagemembership()}>Keep Shopping</button>
                                <button class="v-button primary invisible-button" onClick={() => closedom()} >Checkout</button></>}
                        />, document.getElementById("OpenDialog2")
                    )
                }
                else {                    
                    Toast("Membership Add To Cart Successfully");
                    unmountComponentAtNode(document.getElementById("divcart"));
                    ReactDOM.render(<RenderBusinessCart data={msg.itemCount} userContext={siteData}/>, document.getElementById("divcart"));
                    setIsdailydealsloading(false);
                    unmountComponentAtNode(document.getElementById("OpenDialog1"));
                }
            }
        )



    }

    const PackageAddtoCart = async (packAgeData, event) => {
        var LoginDetails = utils.ReadCookie();
        if (LoginDetails.EncUid === undefined || LoginDetails.EncUid === "" || LoginDetails.EncUid === null) {
            window.wbc.openVagaroPage("divVagaroIframe", utils.VURL("Login", null) + addtoCartEnum.PackageAddToCart + "&Data=" + event);
            $(".modal-backdrop").hide();
            $(".vgn-popup").hide();
            return;
        }
        setIsdailydealsloading(true)
        const Requestdata = {};
        Requestdata.EncServiceId = packAgeData.encID;
        Requestdata.promotionDetail = packAgeData.promotionDetail;
        Requestdata.promotionTitle = packAgeData.promotionTitle;
        Requestdata.ServiceDesc = packAgeData.description;
        Requestdata.EncUid = LoginDetails.EncUid;
        Requestdata.noOfVisit = 0;
        Requestdata.isCustomPackage = true;
        Requestdata.lstAddonService = [];
        Requestdata.IsInitialDiscount = false;
        Requestdata.InitialDiscount = 0;
        Requestdata.IsAmount = false;
        Requestdata.BillingCycle = 0;
        Requestdata.recGiftCertificateId = "";
        await AsyncAjaxPost(
            VagaroAPIEnum.packageaddtocart,
            Requestdata,
            siteData.accessToken,
            function onSuccess(data) {
                var packagecart = data.data.data;                
                unmountComponentAtNode(document.getElementById("divcart"));
                ReactDOM.render(<RenderBusinessCart data={packagecart.itemCount} userContext={siteData}/>, document.getElementById("divcart"));
                setIsdailydealsloading(false);
                ReactDOM.render(
                    <VGPopUp
                        title={"Vagaro"}
                        isShowFooter={true}
                        show={true}
                        isRemoveTabModalIndex={false}
                        onHide={PopupClosepackagemembership}
                        body={<div>Item has been Added to Cart Successfully. Do you want to Checkout ?</div>}
                        footer={<>
                            <button class="v-button secondary invisible-button" onClick={() => PopupClosepackagemembership()}>Keep Shopping</button>
                            <button class="v-button primary invisible-button" onClick={() => closedom()} >Checkout</button></>}
                    />, document.getElementById("OpenDialog2")
                )
            }
        )
    }
    const closedom = () => {
        window.wbc.openVagaroPage("divVagaroIframe",  Site.SiteName + Merchantdata.vagaroURL + "/shopping-bag?Type=Builder&Event=ShoppingBag");
        unmountComponentAtNode(document.getElementById("OpenDialog"));
        unmountComponentAtNode(document.getElementById("OpenDialog2"));
    }

    const GetDailyDealsDetail = async (PromotionID) => {
        setLoading(true);
        await AsyncAjaxGet(
            VagaroAPIEnum.promotiondetails + "?EncPromotionID=" + PromotionID,
            siteData.accessToken,
            function onSuccess(data) {
                try {
                    if (data != null && data.data != null && data.data.data != null) {
                        setMembershipdata(data.data.data.membershipPackage);
                        setGiftCertificatedata(data.data.data.giftCertificate);
                        setAvailableDatesdata(data.data.data.availableDates);

                        if (data.data.data.service.length > 0) {
                            var ServiceList = data.data.data.service;
                            var result = [];
                            var sData = {};

                            var parentResult = [];
                            ServiceList.map((item) => {

                                if (parentResult.length == 0) {
                                    var pService = {};
                                    pService.encServiceID = item.encParentServiceID;
                                    pService.serviceName = item.parentServiceTitle;
                                    pService.encServiceIDHash = item.encParentServiceIDHash;
                                    parentResult.push(pService);
                                }
                                else {
                                    var pServiceList = parentResult.filter(
                                        (col) => col.encServiceIDHash === item.encParentServiceIDHash
                                    );
                                    if (pServiceList.length == 0) {
                                        var pService = {};
                                        pService.encServiceID = item.encParentServiceID;
                                        pService.serviceName = item.parentServiceTitle;
                                        pService.encServiceIDHash = item.encParentServiceIDHash;
                                        parentResult.push(pService);
                                    }
                                }
                            });
                            parentResult.forEach((data, index) => {
                                var serviceFilter = ServiceList.filter(
                                    (col) => col.encParentServiceIDHash === data.encServiceIDHash
                                );
                                var childrenList = [];
                                if (serviceFilter.length > 0) {
                                    serviceFilter.forEach(sdata => {
                                        var servicedata = {};
                                        servicedata.id = sdata.encServiceID;
                                        servicedata.text = sdata.serviceTitle;
                                        childrenList.push(servicedata);
                                    });
                                }
                                var sData = {};
                                sData.children = childrenList;
                                sData.text = data.serviceName;
                                result.push(sData);
                            });
                            setServicedata(result);
                            BindService(result);
                            setServices(ServiceList);

                        }
                    }
                    setLoading(false);
                } catch (error) {
                    setLoading(false);
                }
            },
            function OnError() {
                setLoading(false);
            }
        );
    }

    let $select2Businessobject = null;

    const BindService = (datas) => {
        var serviceList = {};
        var copyserviceList = {};
        serviceList.results = datas;
        if (typeof serviceList.results != "undefined") {
            copyserviceList = $.extend(true, [], serviceList);
        }
        $select2Businessobject = $("#txtServiceSearch").select2({
            placeholder: "Select Service",
            minimumInputLength: 1,
            selectOnClick: true,
            maximumInputLength: 100,
            addSelectedClass: true,
            defaultselectedid: 0,
            combobox: true,
            formatInputTooShort: false,
            formatNoMatches: false,
            data: copyserviceList,
            matcher: function (term, text, option) {
                var SearchResult = SearchService(term, text, option, false);
                if (SearchResult == false && term.length >= 3) {
                    SearchResult = SearchService(term, text, option, true);
                }

                return SearchResult;
            },
        });

        $select2Businessobject.change(function () {
            if ($select2Businessobject.select2('data')[0].id != undefined && $select2Businessobject.select2('data')[0].id != "") {

                $select2Businessobject.select2('data')[0].id = "";
            }
        });
    }


    function SearchService(term, text, option, istextfound) {
        var spaceReplaceRegex = /\s\s+/g;
        var Regex = /[^0-9a-zA-Z\" \"]+/gi;

        var FilterSearch = term.replace(spaceReplaceRegex, ' ');
        FilterSearch = FilterSearch.toLowerCase().replace(Regex, '');

        var arrsSearchKeyword = FilterSearch.split(' ');
        var matchedArray = '';
        var reqmatchCount = arrsSearchKeyword.length;
        var totMatchFound = 0;
        for (var i = 0; i <= arrsSearchKeyword.length - 1; i++) {
            var TextSearch = text.replace(spaceReplaceRegex, ' ');
            TextSearch = TextSearch.toLowerCase().replace(Regex, '');
            if ((istextfound == false && (TextSearch.match("^" + arrsSearchKeyword[i]) || TextSearch.indexOf(" " + arrsSearchKeyword[i]) != -1)) || (istextfound && TextSearch.match(arrsSearchKeyword[i]))) {
                if (matchedArray == "" || arrsSearchKeyword[i].indexOf(matchedArray) < 0) {
                    totMatchFound += 1;
                }
                matchedArray = " " + TextSearch;
                if (totMatchFound >= reqmatchCount) {
                    break;
                }
            }
        }
        if (totMatchFound >= reqmatchCount) {
            option.isShow = true;
            return true;
        }
        else
            return false;
    }

    const splitprice = (html, index) => {
        const price = document.createElement('div');
        price.innerHTML = html;
        if (price.children[index] && html.includes("span")) {
            return price.children[index].innerText;
        }
        else {
            return index == 1 ? html : "";
        }
    }

    const BussinessReview = async () => {
        setLoading(true);
        await AsyncAjaxGet(
            VagaroAPIEnum.reviewpercentage,
            siteData.accessToken,
            function onSuccess(data) {
                try {
                    if (data != null && data.data != null && data.data.data != null) {
                        setBussinessReview(data.data.data.rank);
                        setBussinessReviewTotal(data.data.data.reviewPercentage[0].total);
                    }
                    else {
                        setLoading(false);
                    }
                } catch (error) {
                    setLoading(false);
                }
            },
            function OnError() {
                setLoading(false);
            }
        );
    }

    const giftCertificatePopup = (event) => {    
        if (selectGC === undefined || selectGC === null || selectGC == 0) {
            setIsvalidSelectGcprice(true);
        }
        else {
            return ReactDOM.render(
                <GiftCertificatePopup
                    {...props}
                    selectGC={selectGC.length > 0 ? true : false}
                    selectGCdata={selectGC}

                />,
                document.getElementById("OpenDialog1")
            )
        }

    }

    const validateDealRange = (selectdate) => {
        selectedtimeq.current = selectdate;
        var hdnPromotionStartDate = moment(DailyDealsdata.sDate).format('MMM DD, YYYY');
        var hdnPromotionEndDate = DailyDealsdata.eDate === "" ? "" : moment(DailyDealsdata.eDate).format('MMM DD, YYYY');
        if (typeof (hdnPromotionStartDate) != 'undefined' && hdnPromotionStartDate != '' && typeof (hdnPromotionEndDate) != 'undefined') {
            var bookingdate = selectdate;
            var pStartDate = hdnPromotionStartDate;
            var messageStr = '';
            if (hdnPromotionEndDate != '') {
                var pEndDate = hdnPromotionEndDate
                if ((moment(pStartDate).diff(bookingdate, 'minutes')) > 0 || (moment(bookingdate).diff(pEndDate, 'minutes')) > 0) {
                    messageStr = "The date selected is outside of " + moment(pStartDate).format('MMM') + " " + moment(pStartDate).format('D') + " - " + moment(pEndDate).format('MMM') + " " + moment(pEndDate).format('D') + ". If you continue, the daily deal will not be applied.";
                    validatepopup(messageStr);
                }
            }
            else {
                if ((moment(pStartDate).diff(bookingdate, 'minutes')) > 0) {
                    messageStr = "The deal will be applicable from " + moment(pStartDate).format('MMM') + " " + moment(pStartDate).format('D') + ". If you continue, the daily deal will not be applied.";
                    validatepopup(messageStr);
                }
            }
        }
    }

    const serviceonchange = (value) => {
        if(value === ""){
        setSelectservice("");
        selectedservicetemp.current = "";
        setIselectvalid(false);
        }
        else{
        const arr = services.find((e) => e.encServiceID == value)
        setSelectservice(arr.serviceTitle);
        setEventtype(arr.eventtype)
        selectedservicetemp.current = arr.encServiceID;
        setIselectvalid(false);

        }
        
    }
    const Gconchange = (val) => {
        var data = giftCertificatedata.find((d) => d.id == val);
        setSelectGC(data);
        setIsvalidSelectGcprice(false);
    }
    const dateOnChange = (date) => {
        selectedtimeq.current = date;
    }

    const Booknow = () => {
        if (selectedservicetemp.current === undefined || selectedservicetemp.current === "" || selectedservicetemp.current === null) {
            setIselectvalid(true);
        }
        else {
            var jsonData = JSON.stringify({ encserviceid: selectedservicetemp.current, bookingDateTime: selectedtimeq.current, encpromotionid: DailyDealsdata.encPromotionID }).replace(/\\\"/g, '"').replace(/"/g, "\\\"");
            destroydatepicker({
                divid: "#AddTimeCard-inputpopup",
                textid: "#dailydealdaterange",
                dateTimeFormat: "MMM D, YYYY",
                isMobileFriendly: false,
            });
            if(eventtype === 2){
                window.wbc.openVagaroPage("divVagaroIframe", utils.VURLClasses( Merchantdata.vagaroURL) + addtoCartEnum.DealBookNow + "&Data=" + jsonData);
            }
            else{
                window.wbc.openVagaroPage("divVagaroIframe", utils.VURL(null, Merchantdata.vagaroURL) + addtoCartEnum.DealBookNow + "&Data=" + jsonData);
            }
           
            unmountComponentAtNode(document.getElementById("OpenDialog"));
            unmountComponentAtNode(document.getElementById("OpenDialog1"));
            return;
        }
    };

    const validatepopup = (msg) => {
        return ReactDOM.render(
            <VGPopUp
                id={"validatepopup"}
                show={true}
                isShowFooter={true}
                onHide={PopupClosevalidatepopup}
                title={"Regular Price"}
                body={<>
                    {msg}
                </>}
                footer={<>
                    <button class="v-button primary invisible-button" style={{ width: "100%" }} onClick={() => { Booknow(); PopupClosevalidatepopup() }}>Continue</button></>}

            />, document.getElementById("OpenDialog1")
        )
    }

    const popupBody = () => {

        const CustomShareToggle = React.forwardRef(({ children, onClick }, ref) => (
            <a
                href=""
                ref={ref}
                className='w-button w-button-secondary no-a'
                onClick={(e) => {
                    e.preventDefault();
                    onClick(e);
                }}
            >
                {children}
            </a>
        ));


        return (
            <Fragment>
                {isdailydealsloading && <Loader />}
                <div className="vg-editor-dailydeal">
                    <div className="daily-column">
                        <div className="left-column-daily booksummaryborder">
                            <div className="daily-dealleftinner">
                                <div className="pos-relative" id="divPromoImage">
                                    <img
                                        className="blockPlaceholder"
                                        alt="Placeholder"
                                        src="https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/PublicImages/Images/img-placeholder32.png"
                                        width="100%"
                                    />

                                    <div
                                        id="imgPromotion"
                                        className="lazy daily-detail-banner card-bgimg card-imgabsolute"
                                        tabIndex={-1}
                                        src={DailyDealsdata.photoUrl}
                                        style={{ backgroundImage: `url('${DailyDealsdata.photoUrl}')` }}
                                        title={DailyDealsdata.promotionTitle}
                                    />
                                    <div
                                        className="deal-listing-discount badge-button-discount"
                                    >
                                        {DailyDealsdata.discountHtml.includes("<span") ?
                                            <div style={{ background: "#333", color: "#eee", padding: "4px 8px", borderRadius: "4px 0px 0px 0px" }} dangerouslySetInnerHTML={{ __html: DailyDealsdata.discountHtml }}></div>
                                            :
                                            <div style={{ background: "#333", color: "#eee", padding: "4px 8px", borderRadius: "4px 0px 0px 0px" }}>{DailyDealsdata.discountHtml}</div>
                                        }
                                        <span

                                            itemProp="priceCurrency"
                                            style={{ display: "none" }}
                                        >
                                            $
                                        </span>
                                    </div>
                                    <div>
                                        {totalused > 0 && <div id="ctl00_dvuserCount" class="deal-listing-discount-left dvuserCount badge-button-alert"><span id="TotalNumberofuserCount" itemprop="Count">{totalused} LEFT</span></div>}
                                    </div>
                                </div>

                                <div className="deals-text-cont">
                                    <h1 className="daily-deal-large-header">
                                        {DailyDealsdata.promotionTitle}
                                    </h1>
                                    <div className="daily-deal-bus-and-distance-div border-primary">
                                        <div className="bus-name-daily thm-textColor">
                                            <a

                                                className="business-page-link txttransnone no-a"
                                                role="link"
                                                aria-labelledby="lblSalonName"
                                            >
                                                <span id="lblSalonName" className="">
                                                    {Merchantdata.businessName}
                                                </span>
                                            </a>
                                        </div>

                                    </div>
                                    <div

                                        className="card-rating-div ratings-daily border-primary"
                                    >
                                        <div className="dealsvgstars">
                                            {Math.round(bussinessReview.general) !== undefined && Math.round(bussinessReview.general) === 0 && <div className="vgstars">
                                                <span className="star"></span>
                                                <span className="star"></span>
                                                <span className="star"></span>
                                                <span className="star"></span>
                                                <span className="star"></span>
                                            </div>}
                                            {Math.round(bussinessReview.general) !== undefined && Math.round(bussinessReview.general) === 1 && <div className="vgstars">
                                                <span className="star yellow v-filled-star"></span>
                                                <span className="star"></span>
                                                <span className="star"></span>
                                                <span className="star"></span>
                                                <span className="star"></span>
                                            </div>}
                                            {Math.round(bussinessReview.general) !== undefined && Math.round(bussinessReview.general) === 2 && <div className="vgstars">
                                                <span className="star yellow v-filled-star"></span>
                                                <span className="star yellow v-filled-star"></span>
                                                <span className="star"></span>
                                                <span className="star"></span>
                                                <span className="star"></span>
                                            </div>}
                                            {Math.round(bussinessReview.general) !== undefined && Math.round(bussinessReview.general) === 3 && <div className="vgstars">
                                                <span className="star yellow v-filled-star"></span>
                                                <span className="star yellow v-filled-star"></span>
                                                <span className="star yellow v-filled-star"></span>
                                                <span className="star"></span>
                                                <span className="star"></span>
                                            </div>}
                                            {Math.round(bussinessReview.general) !== undefined && Math.round(bussinessReview.general) === 4 && <div className="vgstars">
                                                <span className="star yellow v-filled-star"></span>
                                                <span className="star yellow v-filled-star"></span>
                                                <span className="star yellow v-filled-star"></span>
                                                <span className="star yellow v-filled-star"></span>
                                                <span className="star"></span>
                                            </div>}
                                            {Math.round(bussinessReview.general) !== undefined && Math.round(bussinessReview.general) === 5 && <div className="vgstars">
                                                <span className="star yellow v-filled-star"></span>
                                                <span className="star yellow v-filled-star"></span>
                                                <span className="star yellow v-filled-star"></span>
                                                <span className="star yellow v-filled-star"></span>
                                                <span className="star yellow v-filled-star"></span>
                                            </div>}
                                            <div className="miles-div">({bussinessReviewtotal})</div>
                                        </div>
                                    </div>
                                    <div className='mobile-res-dailydeals'>
                                    {servicedata.length > 0 &&
                                        <>
                                         <div className={`form-row ${iselectvalid && "form-row-invalid"}`} >
                                            <div className="">
                                                <GroupSingleSelect iselectvalid={iselectvalid} ControlID={"ddlserviceSearch"} options={servicedata} placeholder={"Select Service"} searchInputPlaceholder={"Search Service "} onBlur={(val) => serviceonchange(val)} onChange={(val) => serviceonchange(val)}  value={selectservice} />
                                            </div>
                                            </div>
                                        
                                            <div className='form-row'>
                                                <div id="divdaterangeid" className='vg-datepicker-wb'>
                                                    <SingleDateRangePicker ControlID="dailydealdaterange" MinDate={MinDate} MaxDate={MaxDate} validateDealRange={validateDealRange} salontime={salontime} availableDatesdata={availableDatesdata} setSelectTime={setSelectTime} dateOnChange={dateOnChange} />
                                                </div>
                                            </div>
                                            <div className='bknwbtn'>
                                                <button className='v-button primary invisible-button' id={"btnbookService"} onClick={() => Booknow()}>Book Now</button>
                                            </div>
                                            <div className="dealshare">
                                             <Dropdown>
                                                <Dropdown.Toggle as={CustomShareToggle} id="dropdown-custom-components">
                                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M503.691 189.836L327.687 37.851C312.281 24.546 288 35.347 288 56.015v80.053C127.371 137.907 0 170.1 0 322.326c0 61.441 39.581 122.309 83.333 154.132 13.653 9.931 33.111-2.533 28.077-18.631C66.066 312.814 132.917 274.316 288 272.085V360c0 20.7 24.3 31.453 39.687 18.164l176.004-152c11.071-9.562 11.086-26.753 0-36.328z" /></svg> <span class="share-btn-dd">Share</span>
                                                </Dropdown.Toggle>
            
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={(e) => { openPopupfb(e, `https://www.facebook.com/sharer/sharer.php?u=${DailyDealsdata.dailyDealURL}`) }}><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" /></svg> Facebook</Dropdown.Item>
                                                    <Dropdown.Item onClick={(e) => { openPopup(e, "Twitter") }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 496 512"><path d="M496 256c0 137-111 248-248 248-25.6 0-50.2-3.9-73.4-11.1 10.1-16.5 25.2-43.5 30.8-65 3-11.6 15.4-59 15.4-59 8.1 15.4 31.7 28.5 56.8 28.5 74.8 0 128.7-68.8 128.7-154.3 0-81.9-66.9-143.2-152.9-143.2-107 0-163.9 71.8-163.9 150.1 0 36.4 19.4 81.7 50.3 96.1 4.7 2.2 7.2 1.2 8.3-3.3.8-3.4 5-20.3 6.9-28.1.6-2.5.3-4.7-1.7-7.1-10.1-12.5-18.3-35.3-18.3-56.6 0-54.7 41.4-107.6 112-107.6 60.9 0 103.6 41.5 103.6 100.9 0 67.1-33.9 113.6-78 113.6-24.3 0-42.6-20.1-36.7-44.8 7-29.5 20.5-61.3 20.5-82.6 0-19-10.2-34.9-31.4-34.9-24.9 0-44.9 25.7-44.9 60.2 0 22 7.4 36.8 7.4 36.8s-24.5 103.8-29 123.2c-5 21.4-3 51.6-.9 71.2C65.4 450.9 0 361.1 0 256 0 119 111 8 248 8s248 111 248 248z" /></svg><path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" /></svg> Twitter
                                                    </Dropdown.Item>
                                                    <Dropdown.Item onClick={(e) => { openPopup(e, "pintrest") }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 496 512"><path d="M496 256c0 137-111 248-248 248-25.6 0-50.2-3.9-73.4-11.1 10.1-16.5 25.2-43.5 30.8-65 3-11.6 15.4-59 15.4-59 8.1 15.4 31.7 28.5 56.8 28.5 74.8 0 128.7-68.8 128.7-154.3 0-81.9-66.9-143.2-152.9-143.2-107 0-163.9 71.8-163.9 150.1 0 36.4 19.4 81.7 50.3 96.1 4.7 2.2 7.2 1.2 8.3-3.3.8-3.4 5-20.3 6.9-28.1.6-2.5.3-4.7-1.7-7.1-10.1-12.5-18.3-35.3-18.3-56.6 0-54.7 41.4-107.6 112-107.6 60.9 0 103.6 41.5 103.6 100.9 0 67.1-33.9 113.6-78 113.6-24.3 0-42.6-20.1-36.7-44.8 7-29.5 20.5-61.3 20.5-82.6 0-19-10.2-34.9-31.4-34.9-24.9 0-44.9 25.7-44.9 60.2 0 22 7.4 36.8 7.4 36.8s-24.5 103.8-29 123.2c-5 21.4-3 51.6-.9 71.2C65.4 450.9 0 361.1 0 256 0 119 111 8 248 8s248 111 248 248z" /></svg> Pinterest
                                                    </Dropdown.Item>
                                                    <VgSendEmailPopup EncUid={props.LoginDetails.EncUid} ForDailyDeal={<Dropdown.Item className="share-social-icon facebook font-awesome-brand" >
                                                        <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z" /></svg> Email
                                                    </Dropdown.Item>} Image={DailyDealsdata.photoUrl} BusinessName={Merchantdata.businessName} city={Merchantdata.city} businessID={Merchantdata.businessID} vagaroURL={Merchantdata.vagaroURL} stateCode={Merchantdata.stateCode} headername={DailyDealsdata.promotionTitle} accessToken={siteData.accessToken} price={DailyDealsdata.discountHtml} type={2} bid={siteData.businessId} promotionBeforePrice={splitprice(DailyDealsdata.discountHtml, 0)} promotionAfterPrice={splitprice(DailyDealsdata.discountHtml, 1)} offer={DailyDealsdata.discountHtml.includes("<span") ?
                                                        <div style={{ background: "#333", color: "#eee", padding: "4px 8px", borderRadius: "4px 0px 0px 0px" }} dangerouslySetInnerHTML={{ __html: DailyDealsdata.discountHtml }}></div>
                                                        :
                                                        <div style={{ background: "#333", color: "#eee", padding: "4px 8px", borderRadius: "4px 0px 0px 0px" }}>{DailyDealsdata.discountHtml}</div>
                                                    } />
                                                </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </>
                                    }
                                    </div>
                                    </div>
                                    <div>
                                    <div
                                        className="deals-valid"
                                        style={{ fontWeight: "600" }}
                                    >
                                        <span aria-hidden="true">
                                            Valid:
                                            <span className="bold-text" style={{ marginLeft: "5px" }}>
                                                {validatedate}
                                            </span>
                                        </span>
                                    </div>
                                    <div className="daily-deals title web-display-small">
                                        <b>Highlights</b>
                                    </div>
                                    <p
                                        id="promotionDesHome" 
                                        className="text-normal text-alignJustify daily-deals-promotiondesc"
                                        itemProp="description"
                                    >
                                        {DailyDealsdata.promotionDesc}
                                    </p>
                                    {DailyDealsdata.promoLimitation !== "" &&
                                        <>
                                            <div className="daily-deals title ">
                                                <b>Fine Print</b>
                                            </div>
                                            <p
                                                id="promotionDesHome" 
                                                className="text-normal text-alignJustify daily-deals-promotiondesc"
                                                itemProp="description"
                                            >
                                                {DailyDealsdata.promoLimitation}
                                            </p></>
                                    }
                                    <div

                                        className="daily-deals title web-display-small"
                                        style={{ display: "none" }}
                                    >
                                        <b>
                                            <span id="ctl00_lblFinePrintTxt" />
                                        </b>
                                    </div>
                                    <p

                                        className="text-normal text-alignJustify"
                                    />
                                    <span
                                        id="lblPromotionLimitTitle"
                                        className="daily-deals title web-display-small"
                                    >
                                        <b>About {Merchantdata.businessName}</b>
                                    </span>
                                    <div className='daily-deals-promotiondesc' dangerouslySetInnerHTML={{ __html: Merchantdata.description }}>
                                    </div>
                                    <div className="daily-deals-map-div w-clearfix">
                                        <a
                                            href={DailyDealsdata.mapUrl}
                                            id="map_dailydealsdetail1"
                                            rel="noreferrer"
                                            className="map-marker-div map-div w-widget business-static-map lazy cusrerpointer"
                                            target="_blank"
                                            style={{
                                                display: "block",
                                                backgroundImage:
                                                    `url('${DailyDealsdata.imageMapUrl}')`
                                            }}
                                        />
                                        <address className="location-div">
                                            <div>
                                                <i className="fas fa-location-arrow"></i>
                                                <strong className="BusinessMiles" />
                                            </div>
                                            <div className='map-text'>
                                                <div className="mapbname">
                                                    {Merchantdata.businessName}
                                                </div>
                                                <div
                                                    className="promo-address-col"
                                                >
                                                    {DailyDealsdata.address}
                                                </div>
                                            </div>
                                        </address>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <aside className={`right-side-daily booksummaryborder ucdaily-deal-rightside  ${giftCertificatedata.length > 0 ? "gcpurchaseright" : ""}`} role="none">
                            {membershipdata.length > 0 && membershipdata.map((data) => {
                                return (
                                    <>
                                        <article className="deal-package-card">
                                            <h3 className='vg-custom-style'>{data.membershipName}</h3>
                                            <div className="deal-price vg-custom-style">{data.strPrice.includes("<span") ?
                                                <div className='vp-3' dangerouslySetInnerHTML={{ __html: data.strPrice }}></div>
                                                :
                                                <div>{data.strPrice}</div>
                                            }</div>
                                            <div className="desc">
                                                <p className='vg-custom-style'>{data.description}</p>
                                            </div>
                                            {data.strBillingCycle !== undefined && data.strBillingCycle !== "" && <p class="p"><span style={{ color: "#777" }}>{data.strBillingCycle}</span></p>}
                                            <div className="deal-card-action">
                                                <a
                                                    id={"Dailydealscart" + data.id}
                                                    href="javascript:void(0);"
                                                    className="v-button primary"
                                                    onClick={(e) => Addtocart(data, e.target.id)}
                                                >Add to Cart</a>
                                            </div>
                                        </article>
                                    </>
                                )
                            })
                            }

                            
                            {giftCertificatedata.length > 0 &&
                                <><div className={`form-row ${isvalidselectGcprice && "form-row-invalid"}`} >
                                    <div className="">
                                        <GroupSelect isSingleOption ControlID={"ddlserviceSearchGC"} options={giftCertificatedata} placeholder={"Select Gift Card Amount"} searchInputPlaceholder={"Search Service "} onChange={(val) => Gconchange(val)} giftCertificatedata={giftCertificatedata} />
                                    </div>
                                </div>
                                    <div className='bknwbtn'>
                                        <button id='btnPurchase' className='v-button primary invisible-button' onClick={(event) => giftCertificatePopup(event)}>Purchase</button>
                                    </div>


                                </>

                            }
                            {DailyDealsdata.filterHtml != null && DailyDealsdata.filterHtml != undefined && DailyDealsdata.filterHtml != '' &&
                                <>
                                    <div dangerouslySetInnerHTML={{ __html: DailyDealsdata.filterHtml }}></div>
                                </>
                            }
                           {servicedata.length === 0 && 
                            <div className="dealshare">
                                <Dropdown>
                                    <Dropdown.Toggle as={CustomShareToggle} id="dropdown-custom-components">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M503.691 189.836L327.687 37.851C312.281 24.546 288 35.347 288 56.015v80.053C127.371 137.907 0 170.1 0 322.326c0 61.441 39.581 122.309 83.333 154.132 13.653 9.931 33.111-2.533 28.077-18.631C66.066 312.814 132.917 274.316 288 272.085V360c0 20.7 24.3 31.453 39.687 18.164l176.004-152c11.071-9.562 11.086-26.753 0-36.328z" /></svg> <span className='share-btn-dd'>Share</span>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item className='no-a' onClick={(e) => { openPopupfb(e, `https://www.facebook.com/sharer/sharer.php?u=${DailyDealsdata.dailyDealURL}`) }}><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" /></svg> Facebook</Dropdown.Item>
                                        <Dropdown.Item className='no-a' onClick={(e) => { openPopup(e, "Twitter") }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 496 512"><path d="M496 256c0 137-111 248-248 248-25.6 0-50.2-3.9-73.4-11.1 10.1-16.5 25.2-43.5 30.8-65 3-11.6 15.4-59 15.4-59 8.1 15.4 31.7 28.5 56.8 28.5 74.8 0 128.7-68.8 128.7-154.3 0-81.9-66.9-143.2-152.9-143.2-107 0-163.9 71.8-163.9 150.1 0 36.4 19.4 81.7 50.3 96.1 4.7 2.2 7.2 1.2 8.3-3.3.8-3.4 5-20.3 6.9-28.1.6-2.5.3-4.7-1.7-7.1-10.1-12.5-18.3-35.3-18.3-56.6 0-54.7 41.4-107.6 112-107.6 60.9 0 103.6 41.5 103.6 100.9 0 67.1-33.9 113.6-78 113.6-24.3 0-42.6-20.1-36.7-44.8 7-29.5 20.5-61.3 20.5-82.6 0-19-10.2-34.9-31.4-34.9-24.9 0-44.9 25.7-44.9 60.2 0 22 7.4 36.8 7.4 36.8s-24.5 103.8-29 123.2c-5 21.4-3 51.6-.9 71.2C65.4 450.9 0 361.1 0 256 0 119 111 8 248 8s248 111 248 248z" /></svg><path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" /></svg> Twitter
                                        </Dropdown.Item>
                                        <Dropdown.Item className='no-a' onClick={(e) => { openPopup(e, "pintrest") }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 496 512"><path d="M496 256c0 137-111 248-248 248-25.6 0-50.2-3.9-73.4-11.1 10.1-16.5 25.2-43.5 30.8-65 3-11.6 15.4-59 15.4-59 8.1 15.4 31.7 28.5 56.8 28.5 74.8 0 128.7-68.8 128.7-154.3 0-81.9-66.9-143.2-152.9-143.2-107 0-163.9 71.8-163.9 150.1 0 36.4 19.4 81.7 50.3 96.1 4.7 2.2 7.2 1.2 8.3-3.3.8-3.4 5-20.3 6.9-28.1.6-2.5.3-4.7-1.7-7.1-10.1-12.5-18.3-35.3-18.3-56.6 0-54.7 41.4-107.6 112-107.6 60.9 0 103.6 41.5 103.6 100.9 0 67.1-33.9 113.6-78 113.6-24.3 0-42.6-20.1-36.7-44.8 7-29.5 20.5-61.3 20.5-82.6 0-19-10.2-34.9-31.4-34.9-24.9 0-44.9 25.7-44.9 60.2 0 22 7.4 36.8 7.4 36.8s-24.5 103.8-29 123.2c-5 21.4-3 51.6-.9 71.2C65.4 450.9 0 361.1 0 256 0 119 111 8 248 8s248 111 248 248z" /></svg> Pinterest
                                        </Dropdown.Item>
                                        <VgSendEmailPopup EncUid={props.LoginDetails.EncUid} ForDailyDeal={<Dropdown.Item className="share-social-icon facebook font-awesome-brand no-a" >
                                            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z" /></svg> Email
                                        </Dropdown.Item>} Image={DailyDealsdata.photoUrl} BusinessName={Merchantdata.businessName} city={Merchantdata.city} businessID={Merchantdata.businessID} vagaroURL={Merchantdata.vagaroURL} stateCode={Merchantdata.stateCode} headername={DailyDealsdata.promotionTitle} accessToken={siteData.accessToken} price={DailyDealsdata.discountHtml} type={2} bid={siteData.businessId} promotionBeforePrice={splitprice(DailyDealsdata.discountHtml, 0)} promotionAfterPrice={splitprice(DailyDealsdata.discountHtml, 1)} offer={DailyDealsdata.discountHtml.includes("<span") ?
                                            <div style={{ background: "#333", color: "#eee", padding: "4px 8px", borderRadius: "4px 0px 0px 0px" }} dangerouslySetInnerHTML={{ __html: DailyDealsdata.discountHtml }}></div>
                                            :
                                            <div style={{ background: "#333", color: "#eee", padding: "4px 8px", borderRadius: "4px 0px 0px 0px" }}>{DailyDealsdata.discountHtml}</div>
                                        } />
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>}
                        </aside>
                    </div>
                </div>
            </Fragment>
        )
    }

    const PopupCloseConfirmation = () => {
        destroydatepicker({
            divid: "#AddTimeCard-inputpopup",
            textid: "#dailydealdaterange",
            dateTimeFormat: "MMM D, YYYY",
            isMobileFriendly: false,

        });
        unmountComponentAtNode(document.getElementById("OpenDialog"));

    };
    const PopupClosepackagemembership = () => {
        unmountComponentAtNode(document.getElementById("OpenDialog2"));
    };
    const PopupClosevalidatepopup = () => {
        unmountComponentAtNode(document.getElementById("OpenDialog1"));
        var datetimepicker = $(".xdsoft_datetimepicker");
        setTimeout(() => {
          datetimepicker.trigger("close.xdsoft");
        }, 121);
    };
    const callbackforSelect2 = () => {
        if (servicedata.length > 0) {
            setTimeout(function () {
                closedaterange();
            }, 800)
        }
        if (giftCertificatedata.length > 0) {
            setTimeout(function () {
                closeGCeDropdowMenu()
            }, 800)
        }
    }
    return createPortal(
        isLoading ? <Loader /> :
            <><VGPopUp
                id={"VgReviewByEmployee"}
                show={true}
                title={"Daily Deals"}
                body={popupBody()}
                isRemoveTabModalIndex={true}
                isShowFooter={true}
                onHide={PopupCloseConfirmation}
                size={"xl"}
                backdrop={true}

                fullscreen
            />
            </>,
        document.getElementById("OpenDialog"), callbackforSelect2()
    );
}
export default VGDailyDealDetail;