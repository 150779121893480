import React, { useEffect, useRef, useState } from "react";
import ReactDom, { createPortal, unmountComponentAtNode } from "react-dom";
import PhotoGallaryPopupView from "./PhotoGallaryPopupView";
import $ from "jquery";
import Masonry from "../utils/masonry.pkgd.js";
import imagesLoaded from "../utils/imagesloaded.pkgd.js";
import PhotoSwipeLightbox from "../utils/photoswipe-lightbox.esm.js";
import PhotoSwipe from "../utils/photoswipe.esm.js";
import "../components/Styles/PhotoGallary.scss";
import { Modal } from "react-bootstrap";
import Loader from "../components/Common/Loader";
import { utils, ResponseCode } from "../utils/Utils";
import { AsyncAjaxGet } from "../components/Utilities/AsyncAjaxService";
import RenderBusinessGallery from "../components/BusinessControls/RenderBusinessGallery";

const PhotoGallaryPop = (props) => {
  const ReactBusinessId = props.userContext.businessId;
  const access_token = props.userContext.accessToken;
  const dataBindKey = "data-link-key";
  const StyleLikeChanges = useRef(false)
  const [RespnceData, setData] = useState(props.data);
  const [isLoading, setLoading] = useState(false);
  const [nextPage, setPages] = useState(0);
  const [isRender, setRender] = useState(true)
  const modelBodyRef = useRef(null)
  const componetLoadRef = useRef(false)



  const htmlContent = (element, data, arr) => {
    var ArrayValue = RespnceData.records.photoDetail.find(
      (arr) => arr.styleID == data.StyleID
    );
    var RendomNumber =
      data.StyleID +
      (Math.floor(Math.random() * (9999 - 1 + 1)) + 1).toString();
    ReactDom.render(
      <PhotoGallaryPopupView
        {...props}
        openFrom="Popup"
        key={RendomNumber}
        Data={ArrayValue}
        merchantName={RespnceData.records.merchantName}
        element={element.element}
        StyleLikeChanges={StyleLikeChanges}
      />,
      element.element
    );
  };
  const SetPhotoGrid = () => {
    var grid = document.querySelector(".vgStylePhotos");
    var msnry = new Masonry(grid, {
      itemSelector: ".vgphoto-m-item",
      columnWidth: ".vgphoto-m-item-sizer",
      percentPosition: true,
      gutter: 5,
      horizontalOrder: true,
    });
    imagesLoaded(grid).on("progress", function () {
      msnry.layout();
    });
  };
  const StyleImage = (props) => {
    const [source, setSource] = useState("");
    const [width, setWidth] = useState("");
    const [height, setHeight] = useState("");
    const [hoverdCard, setHoverdCard] = useState(false);

    function handleHoverState(newVal) {
      setHoverdCard(newVal);
  
    }

    useEffect(() => {
      const img = new Image();
      img.src = props.src;
      img.onload = () => {
        setSource(props.src);
        setWidth(img.width);
        setHeight(img.height);
      };
    }, [props.src]);
    return (
      <>
        {props.html ? (
          <div className={`vgphoto-m-item ${hoverdCard ? "hoverd" : ""}`}>
            <div className="vgphoto-m-item-card-list">
              <PhotoGallaryPopupView
                {...props}
                Grid
                key={props.data.styleID}
                Data={props.data}
                merchantName={RespnceData.records.photoDetail.merchantName}
                width={width}
                source={source}
                changehover={handleHoverState}
                StyleLikeChanges={StyleLikeChanges}
              />
            </div>
          </div>
        ) : (
          <a
            href={false}
            id={`vgphoto-m-item-${props.index}`}
            data-like={props.data.likeCounter}
            data-style={props.data.styleID}
            data-pswp-type={props.html ? "html" : ""}
            data-pswp-src={source}
            data-pswp-width={props.html ? "" : width}
            data-pswp-height={props.html ? "" : height}
            className={`no-a vgphoto-m-item`}
                      >
            <img alt="" src={source} width={width} />
          </a>
        )}
      </>
    );
  };
const StyleBinding = async () => {
    if (utils.Validate(ReactBusinessId) && utils.Validate(access_token)) {
      setLoading(true);
      setPages(nextPage + 1);
      let LoginDetails = utils.ReadCookie();
      var photoUrl = ``;
      if (LoginDetails.EncUid === undefined || LoginDetails.EncUid === "" || LoginDetails.EncUid === null) {
        photoUrl = `merchants/${ReactBusinessId}/photos?pageNumber=${nextPage + 1}&pageSize=10`;
      }else{
        photoUrl = `merchants/${ReactBusinessId}/photos?EncUserID=${LoginDetails.EncUid}&pageNumber=${nextPage + 1}&pageSize=10`;
      }
      await AsyncAjaxGet(
        photoUrl,
        access_token,
        function onSuccess(data) {
          try {
            if (
              data != null &&
              data.data != null &&
              data.data.responseCode == ResponseCode.Success
            ) {
              if (data != null && data.data != null && data.data.data != null) {
                var Respnce = RespnceData;
                Respnce.MerchantDetails = {
                  merchantName: data.data.data.records.merchantName, //"Demo Business (website builder)"
                  merchantEmail: data.data.data.records.merchantEmail, //"wb@mailinator.com"
                  city: data.data.data.records.city, //"Pleasanton/sa34"
                  stateName: data.data.data.records.stateName, //"California"
                  stateCode: data.data.data.records.stateCode, //"CA"
                  vagaroURL: data.data.data.records.vagaroURL, //"wb"
                };
                var oldArr = nextPage === 0 ? [] : Respnce.records.photoDetail;
                var NewArr = oldArr.concat(data.data.data.records.photoDetail);
                Respnce.records.photoDetail = NewArr;
                setData(Respnce);
                setTimeout(() => {
                  SetPhotoGrid();
                  const container = modelBodyRef.current;
                  if (container) {
                    if (!(container.scrollHeight > container.offsetHeight) && nextPage == 0 && RespnceData?.totalRecords > 9) {
                      componetLoadRef.current = true;
                      setRender(!isRender);
                    } 
                  }
                }, 500);
              }
            }
            setLoading(false);
          } catch (error) {
            setLoading(false);
          }
        },
        function OnError() {
          setLoading(false);
        }
      );
    }
  };
  const RenderComponent = async () => {
    if (utils.Validate(ReactBusinessId) && utils.Validate(access_token)) {
      let LoginDetails = utils.ReadCookie();
      var photoUrl = ``;

      var photoUrl = ``;
      if (LoginDetails.EncUid === undefined || LoginDetails.EncUid === "" || LoginDetails.EncUid === null) {
        photoUrl = `merchants/${ReactBusinessId}/photos?pageNumber=${nextPage + 1}&pageSize=10`;
      }else{
        photoUrl = `merchants/${ReactBusinessId}/photos?EncUserID=${LoginDetails.EncUid}&pageNumber=${nextPage + 1}&pageSize=10`;
      }
      await AsyncAjaxGet(
        photoUrl,
        access_token,
        function onSuccess(data) {
          try {
            if (
              data != null &&
              data.data != null &&
              data.data.responseCode == ResponseCode.Success
            ) {
              if (data != null && data.data != null && data.data.data != null) {
                var Responce = data.data.data;
                let dataBindElements = document.querySelectorAll(`[${dataBindKey}]`);
                dataBindElements.forEach((element) => {
                    let layout = element.getAttribute("layout");
                    let dataKey = element.getAttribute(dataBindKey);
                    if (layout && dataKey === "gallery") {
                      ReactDom.render(<RenderBusinessGallery layout={layout} data={Responce} userContext={props.userContext} />, element);
                    }
                })
              }
            }
          } catch (error) {
            console.log("error:",error);
          }
        },
        function OnError() {
          console.log("error");
        }
      );
    }
  };

  const imageClickAction = () => {
    return false;
  }


  useEffect(() => {
    let lightbox = new PhotoSwipeLightbox({
      gallery: ".vgStylePhotos",
      children: "a",
      padding: { top: 40, bottom: 40, left: 100, right: 100 },
      pswpModule: PhotoSwipe,
      showHideAnimationType: 'none',
      zoomAnimationDuration: false,
      loop: false,
      initialZoomLevel: "fit",
      secondaryZoomLevel: "fit",
      maxZoomLevel: "fit",
      imageClickAction: imageClickAction(),     
    });
    lightbox.on("contentLoad", (e) => {
      const { content } = e;
      if (content.type === "html") {
        e.preventDefault(); 
        var RendomKey = (
          Math.floor(Math.random() * (9999 - 1 + 1)) + 1
          ).toString();
          var data = {};
          content.element = document.createElement("div");
          content.element.key = RendomKey;
          content.element.id = `html-container-Photo-${content.data.element.dataset.style}`;
          content.element.className = `pswp__content`;
        data.Like = content.data.element.dataset.like;
        data.StyleID = content.data.element.dataset.style;
        htmlContent(content, data, RespnceData.records.photoDetail);
      }
    });
    lightbox.on('contentActivate', ({ content }) => {
      console.log('contentActivate', content);
      if (content.type === "image") {
          const elements = document.getElementsByClassName("pswp__img");
          const elementsArray = Array.from(elements);
          elementsArray.forEach((element,index) => {
              if (element.parentElement) {
                  element.parentElement.title = 'Gallery Image';
              }
          });
      }
    });

    lightbox.init();
    return () => {
      lightbox.destroy();
      lightbox = null;
    };
  }, []);
  const firstCalls = useRef(true)
  useEffect(() => {
    if (firstCalls.current) {
      firstCalls.current = false;
      StyleBinding();
    }
  })
  useEffect(() => {
    setTimeout(function () {
      SetPhotoGrid();
    }, 500);
      window.addEventListener("resize", SetPhotoGrid);
  }, [RespnceData]);
  useEffect(() => {
    if (componetLoadRef.current) {
      componetLoadRef.current = false;
      StyleBinding();
    }
  }, [isRender])
  

  const handleScroll = (e) => {
    const bottom =
      e.target.clientHeight + e.target.scrollTop + 10 >= e.target.scrollHeight;
    const DataLenth =
      RespnceData.records.photoDetail.length < RespnceData.totalRecords - 1;
    if (bottom && DataLenth && isLoading == false) {
      setLoading(true);
      StyleBinding();
    }
  };

  const PopupCloseConfirmation = () => {
    if (StyleLikeChanges.current) {
      RenderComponent();
    }
    unmountComponentAtNode(document.getElementById("OpenDialog"));
  };

  const VgThemes =()=>{
    var styleobject = {};
        var gblstyle = $("#dvglbstyle");
        var primarybutton  = gblstyle.find("#btnprimarybutton");
        var secondorybutton  = gblstyle.find("#btnsecondary");
        var primarybuttonHover  = gblstyle.find("#btnprimarybuttonhover");
        var secondorybuttonHover  = gblstyle.find("#btnsecondarybuttonhover");  
        var lblstyle   = gblstyle.find("#txtfontfamily");
        var btnrating  =gblstyle.find("#btnrating");
        var glbbackground = gblstyle.find("#glbbackground");
        styleobject.primarybuttoncolor = primarybutton.css("color");
        styleobject.primarybuttonbackgroundcolor = primarybutton.css("background-color");
        styleobject.primarybuttonHoverbackgroundcolor = primarybuttonHover.css("background-color");
        styleobject.primarybuttonborderradius = primarybutton.css("border-radius");
        styleobject.primarybuttonboxshadow = primarybutton.css("box-shadow");
        styleobject.secondorybuttonbuttoncolor = secondorybutton.css("color");
        styleobject.secondorybuttonbuttonbackgroundcolor = secondorybutton.css("background-color");
        styleobject.secondorybuttonbuttonHoverbackgroundcolor = secondorybuttonHover.css("background-color");    
        styleobject.secondorybuttonbuttonborderradius = secondorybutton.css("border-radius");
        styleobject.secondorybuttonbuttonboxshadow = secondorybutton.css("box-shadow"); 
        styleobject.lblstylefontfamily = lblstyle.css("font-family");
        styleobject.lblstylecolor = lblstyle.css("color");  
        styleobject.lblstylefontweight = lblstyle.css("font-weight");
        styleobject.lblstylefontsize = lblstyle.css("font-size");
        styleobject.btnratingcolor = btnrating.css("color");
        styleobject.glbbackgroundcolor = glbbackground.css("background-color");
    return(
        <>
            <style>
                {
                    `:root{
                        --vg-primary-bg-modal:${styleobject.glbbackgroundcolor};
                        --vg-body-text-color-modal:${styleobject.lblstylecolor};
                    }
                    `
                }
            </style>
        </>
    )
    
}

  return createPortal(
    <>
    <VgThemes />
      {isLoading && <Loader />}
      <Modal
        id={"vgGridPhoto"}
        show={true}
        onHide={() => PopupCloseConfirmation()}
        dialogClassName={"vgPhotoGridview justify-content-modal"}
        fullscreen={false}
        size={"xl"}
        centered
        scrollable={true}
        className="vgn-popup"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <br />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body ref={modelBodyRef} onScroll={handleScroll}>
          <div className="vgStylePhotos">
            <div className="vgphoto-m-item-sizer"></div>
            {RespnceData.records.photoDetail.map((img, index) => {
              return (
                <>
                  <StyleImage
                    {...props}
                    data={img}
                    mData={RespnceData}
                    src={
                      img.styleID !== "U2FsdGVkX18EI4k80FtcVtq4PzxA94OPqaGqyI1vxNM="
                        ? img.photoUrl.replace("/Original/", "Style/Original/")
                        : img.photoUrl
                    }
                    html={img.styleID !== "U2FsdGVkX18EI4k80FtcVtq4PzxA94OPqaGqyI1vxNM=" ? true : false}
                    index={index}
                  />
                </>
              );
            })}
          </div>
        </Modal.Body>
      </Modal>
    </>,
    document.getElementById("OpenDialog")
  );
};
export default PhotoGallaryPop;
