import React from "react";
import GiftCertificatesPopup from "../../VagaroComponent/GCPopup";
import ReactDom from "react-dom";
import { UserContext } from "../Definitions/UserContext";
import { addtoCartEnum, utils } from "../../utils/Utils";
import OopsBanner, { NoRecordsBanner } from "../OopsBanner";


export default function RenderBusinessGiftCards({ data, layout, userContext, allowMultiLoc }: { data: any, layout: string, userContext: UserContext, allowMultiLoc: boolean }) {

    if (data == null) return <OopsBanner />;

    if (data.length == 0) {
        return <NoRecordsBanner message='No gift certificates available.' />
    }

    switch (layout) {
        default:
            return <RenderDefaultLayout data={data} userContext={userContext} allowMultiLoc={allowMultiLoc} />
    }
}

function RenderDefaultLayout({ data, userContext, allowMultiLoc }: { data: any, userContext: UserContext, allowMultiLoc: boolean }) {

    const isStenciled = data[0]?.isStenciled === true;

    const rows = [];
    for (let i = 0; i < data.length; i += 3) {
        const row = [];
        for (let j = 0; j < 3; j++) {
            if (data[i + j])
                row.push(data[i + j]);
            else {
                row.push({ photo: "" });
            }
        }
        rows.push(row);
    }

    return <>
         <div className="gift-certificate-contianer">
            
        {/*Per design team we are not including any labels/headings inside components to allow users to phrase it themselves 
        <div className="" role="contentinfo" aria-label="There are Different types of Gift Certificates available in Vagaro. Please Select Category For Gift Certificate"><h3 className="gctitle-comp">Select a Category</h3></div> */}
        <div className={"flex-row flex-wrap resizable-container align-start w-full max-100 gc-cards-grid" + (isStenciled ? "stenciled" : "")} style={{ gap: "inherit" }}>
            {data.map((giftCard: any, index: number) => { return <RenderGiftCard key={index} giftCard={giftCard} userContext={userContext} isStencil={isStenciled} allowMultiLoc={allowMultiLoc} /> })}
        </div>
        </div>
    </>

}


function RenderGiftCard({ giftCard, userContext, isStencil, allowMultiLoc }: { giftCard: any, userContext: UserContext, isStencil?: boolean, allowMultiLoc: boolean }) {
    

    
    
    const isEditor = (window as any).isEditor !== undefined ? (window as any).isEditor : false
    if (!giftCard.photo)
        giftCard.photo = "";
    if (!giftCard.giftCertificateCatName)
        giftCard.giftCertificateCatName = "";
    if(giftCard.giftCertificateCatName=="Business Created")
        giftCard.giftCertificateCatName = "Branded";

    return (
        <>
            <div id={'gcpopup' + giftCard.giftCertificateCatIds} className="gift-card quarter-third-half-full" onClick={(event) => !isEditor && GCPopup(giftCard, userContext, event, allowMultiLoc)}>
                <div className="inner-gift-card stencil-item" style={{ width: "100%", backgroundImage: `url(${giftCard.photo})`, backgroundPosition: "center", backgroundSize: "cover", position: "relative" }}>
                    <div className="gift-card-text center-all w-full stencil-item vp-1 " style={{ fontSize: "1.5em", position: "absolute", textAlign: "center", bottom: "0px", background: "linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.6))", color: "white", height: "auto", paddingBottom: "10px",margin:"0px" }} >
                        {giftCard.giftCertificateCatName}</div>
                </div>

            </div>
        </>

    )

}

function GCPopup(giftCard: any, userContext: UserContext, event: any, allowMultiLoc: boolean) {
    var LoginDetails = utils.ReadCookie();
    if (LoginDetails === undefined || LoginDetails === "" || LoginDetails === null) {
        window.wbc.openVagaroPage("divVagaroIframe", utils.VURL("Login", null) + addtoCartEnum.GCAddToCart + "&Data=" + event.currentTarget.id);
        return;
    }
    const BusinessData = utils.MultiLocationData(userContext, userContext.bidHash);
    return ReactDom.render(
        <GiftCertificatesPopup giftCard={giftCard} userContext={userContext} businessData={BusinessData} LoginDetails={LoginDetails} allowMultiLoc={allowMultiLoc} />,
        document.getElementById("OpenDialog")
    );
}