import React, { Fragment, useEffect, useState, useRef } from "react";
import $, { param } from "jquery";
import { applydatepicker, destroydatepicker, HideShowdatepicker } from "./VagaroDateTimePicker";
import { Form } from "react-bootstrap";
import moment from "moment";
const SingleDateRangePicker = (props) => {
    
    const ControlID = props.ControlID ? props.ControlID : "dtEntryDate"
    const Control = {
        ControlID: "#" + ControlID
    }

    const VgThemes =()=>{
        var styleobject = {};
        var gblstyle = $("#dvglbstyle");
        var primarybutton  = gblstyle.find("#btnprimarybutton");
        var secondorybutton  = gblstyle.find("#btnsecondary");
        var primarybuttonHover  = gblstyle.find("#btnprimarybuttonhover");
        var secondorybuttonHover  = gblstyle.find("#btnsecondarybuttonhover");  
        var lblstyle   = gblstyle.find("#txtfontfamily");
        var btnrating  =gblstyle.find("#btnrating");
        var glbbackground = gblstyle.find("#glbbackground");
        styleobject.primarybuttoncolor = primarybutton.css("color");
        styleobject.primarybuttonbackgroundcolor = primarybutton.css("background-color");
        styleobject.primarybuttonHoverbackgroundcolor = primarybuttonHover.css("background-color");
        styleobject.primarybuttonborderradius = primarybutton.css("border-radius");
        styleobject.primarybuttonboxshadow = primarybutton.css("box-shadow");
        styleobject.secondorybuttonbuttoncolor = secondorybutton.css("color");
        styleobject.secondorybuttonbuttonbackgroundcolor = secondorybutton.css("background-color");
        styleobject.secondorybuttonbuttonHoverbackgroundcolor = secondorybuttonHover.css("background-color");    
        styleobject.secondorybuttonbuttonborderradius = secondorybutton.css("border-radius");
        styleobject.secondorybuttonbuttonboxshadow = secondorybutton.css("box-shadow"); 
        styleobject.lblstylefontfamily = lblstyle.css("font-family");
        styleobject.lblstylecolor = lblstyle.css("color");  
        styleobject.lblstylefontweight = lblstyle.css("font-weight");
        styleobject.lblstylefontsize = lblstyle.css("font-size");
        styleobject.btnratingcolor = btnrating.css("color");
        styleobject.glbbackgroundcolor = glbbackground.css("background-color");
        //document.body.style.setProperty("--vg-primary-bg",`${styleobject?.primarybuttoncolor};`)
        document.body.style.setProperty("--vg-primary-bg",`${styleobject.primarybuttonbackgroundcolor}`);
        document.body.style.setProperty("--vg-primary-text-color",`${styleobject.primarybuttoncolor}`);
            
          
        
        
        
        
    }
    const resetValue = () => {
        $(Control.ControlID).val("Send Now").trigger("change");;
    }
  const firstcallChanges = useRef(true);
    useEffect(() => {
        VgThemes();
        if (props.custom) {
            applydatepicker({
                divid: $(Control.ControlID),
                textid: $(Control.ControlID),
                // parentID:"#AddTimeCard-inputpopup",
                dateTimeFormat: "MMM D, YYYY - h:mm A",
                isMobileFriendly: false,
                scrollMonth: false,
                MinDate: new Date(),
                MaxDate: new Date('2099-01-01'),
                yearEnd: ((new Date()).getFullYear() + 76),
                pickTime: true, formatTime: "h:mm A",
                callBackOnChange: function (params) {
                    props.dateOnChange(moment(params).format('YYYY-MM-DD HH:mm:ss'));                
                },
                callBackOnHide: function (params,id) {
                    const inputString = params.val();
                    const parsedDate = moment(inputString, "MMM D, YYYY - h:mm A");
                    const formattedDate = parsedDate.format("YYYY-MM-DD HH:mm:ss");         
                    props.dateOnChange(formattedDate);                
                },
                call:function(){

                }
                
                
            })
            if (firstcallChanges.current) {
                $(Control.ControlID).val(props.placeholder ? props.placeholder: "");
            }
           
        }else{
            applydatepicker({
                divid: "#AddTimeCard-inputpopup",
                textid: $(Control.ControlID),
                dateTimeFormat: "MMM D, YYYY",
                isMobileFriendly: false,
                MinDate: new Date(props.MinDate),
                MaxDate: new Date(props.MaxDate),
                yearStart: ((new Date(props.MinDate)).getFullYear()),
                yearEnd: ((new Date(props.MaxDate)).getFullYear()),
                defaultDateTime: moment(props.salontime).format('MMM D, YYYY'),
                pickTime: false, 
                formatTime: "h:mm A",
                callBackOnChange: function (params) {
                    props.validateDealRange(moment(params).format('MMM DD, YYYY'));
                    props.setSelectTime(moment(params).format('MMM DD, YYYY'));
                    props.dateOnChange(moment(params).format('MMM DD, YYYY'));                
                },
                
            })
        }
               
        if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {            
            document.addEventListener("click", function (e) {
                var datetimepicker = document.getElementsByClassName("xdsoft_datetimepicker");
                var doprdowndate = document.getElementById("dailydealdaterange");
                var outside = true;

                for (let index = 0; index < datetimepicker.length; index++) {
                    const element = datetimepicker[index];
                    if(element.contains(e.target)){
                            outside = false;
                    } 
                   
                }
                if (doprdowndate && doprdowndate.contains(e.target)) {
                    outside = false;
                }
                if (outside) {
                    var datepicker = $(".xdsoft_datetimepicker");
                    setTimeout(() => {
                        datepicker.trigger("close.xdsoft");
                    }, 121);
                }
           
        }); 
    }
    if (props.applyAnyTime === false) {
        $(".hmanytime-text").hide();
    }
    firstcallChanges.current = false
    }, []);


    return (
        <Fragment>
            <div id="AddTimeCard-inputpopup" className="datepicker_wrp selectanydatepicer gc-datepicker-new-default" >
                <Form.Control
                    required
                    type="button"
                    placeholder="Select Date"
                    id={ControlID}
                    disabled={props.disabled}
                    title={props.title ? props.title : ""}
                />
                {props.custom &&<>
                <div onClick={() => resetValue()}><i class="fa fa-times never-icon f-18" aria-hidden="true"></i></div>
               
                <i class="fa fa-clock-o clock-icon f-18"></i>
                </>
                }
                <i className="fal fa-calendar-alt check-cal-icon f-18"></i>
            </div>
            
        </Fragment>
    )
}

export default SingleDateRangePicker;