import React from "react";
import OopsBanner from "./OopsBanner";
export type ErrorBoundaryProps = {
}

export type ErrorBoundaryState = {
  error:any,
  errorInfo:any
}

export default class ErrorBoundary extends React.Component <ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props:ErrorBoundaryProps) {
      super(props);
      this.state = { error: null, errorInfo: null };
    }
    
    componentDidCatch(error:any, errorInfo:any) {
      // Catch errors in any components below and re-render with error message
      this.setState({
        error: error,
        errorInfo: errorInfo
      })
      // You can also log error messages to an error reporting service here
    }
    
    render() {
      if (this.state.errorInfo) {
        // Error path
        return (<>
            <OopsBanner/>

          </>
        );
      }
      // Normally, just render children
      return this.props.children;
    }  
  }