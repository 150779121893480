export const trackingAcceptedCookie = "_vwbtracking-accepted";
export const hasAcceptedValue = "accepted";


export function GetTrackingCookieName() {

  if(window.location.href.includes("mysite.vagaro.com")){
    let split = window.location.pathname.split("/");
    if(split.length > 1){
      return  trackingAcceptedCookie+split[1];
    }
   
  }
  else{
    return trackingAcceptedCookie + window.location.hostname;
  }

  
  return trackingAcceptedCookie +window.location.hostname;

 


}

export function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }



  export function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }