import React, { useState, useEffect, Fragment, useRef } from "react";
import LayoutNode from "../Definitions/LayoutNode";
import { UserContext } from "../Definitions/UserContext";
import EditorLoadingSpinner from "../EditorLoadingSpinner";
import RenderStarRating from "../Icons/StarRating";
import { GetBusinessReviews } from "../Utilities/BusinessData";
import OopsBanner from "../OopsBanner";
import Dropdown from 'react-bootstrap/Dropdown';
import VgWriteReview from "../../VagaroComponent/VgWriteReview";
import ReactDOM, { unmountComponentAtNode } from "react-dom";
import { addtoCartEnum, utils } from "../../utils/Utils";
import $ from 'jquery';
import { ReviewPlaceHolder } from "../Binding/BusinessPlaceholderData";
import PhotoSwipeLightbox from "../../utils/photoswipe-lightbox.esm";
import PhotoSwipe from "../../utils/photoswipe.esm";
import styles from './RenderReviews2.module.css';


const counttoFive = [1, 2, 3, 4, 5];
const avatarStyle = { width: "48px", height: "48px", borderRadius: "50%", };
const ImageStyle = { width: "100px", height: "100px", borderRadius: "0%", backgroundSize: "cover", backgroundPosition: "center" };

const starColor = "#f6d645";



export default function RenderBusinessReviews({ layout, userContext, data, hideWriteOption, SPID, hideTitle, isPopup }: { layout: string, userContext: UserContext, data: any, hideWriteOption?: boolean, SPID: string, hideTitle?: boolean, isPopup?: boolean }): JSX.Element {

    const [bData, setBData] = useState(data)
    const [page, setPage] = useState(1);
    const [sortFilter, setSortFilter] = useState("1");
    const [typeFilter, setTypeFilter] = useState("1");
    const isStenciled = bData?.isStenciled == true;
    //const btnStyle = siteData.siteStyling.buttonPrimary.screenSizeStyleAttributes[ScreenSize.Desktop]; 
    const btnStyle = {fontFamily:"monospace",fontSize:"16px",color:"black", padding:"4px 10px", borderRadius:"4px",boxShadow:"0px 1px 2px 2px #00000033",margin:"4px",background:"#ffffff99",height:"32px" };
    const btnStyleSelected: {} = {...btnStyle, pointerEvents: "none",boxShadow:"none", color: "white",background:"#33333377" };
    const isPopupAvailable = isPopup;
    const scrollToRef = useRef(null);
    
    useEffect(() => {
        if (!bData || bData?.isStenciled) {
            GetBusinessReviews(userContext, (data: any) => { setBData(data); }, (error: any) => { setBData("error") }, 20, page, sortFilter, typeFilter, SPID);
            setBData(ReviewPlaceHolder);
        }
    }, [page, sortFilter, typeFilter])

    const ReloadReview = () => {
        GetBusinessReviews(userContext, (data: any) => { setBData(data); }, (error: any) => { setBData("error") }, 20, page, sortFilter, typeFilter, SPID);
        FilterRow();
    }


    const scrollToTop = () => {
        setTimeout(() => {
            if (scrollToRef.current) {
                (scrollToRef.current as HTMLElement).scrollIntoView();
            }

        }, 10);
    }

    const FilterRow = () => {
        var IsEditor = (window as any).isEditor !== undefined ? (window as any).isEditor : false;
        return <Fragment>
            <div className="review-body-title title-with-btn align-start" ref={scrollToRef} >
                <div className="page-title review-text-head">{hideTitle != true && "Reviews"}</div>
                <div className="review-sorting gap-small">
                    <div className="review-filter-section">
                        {bData && bData.records && bData.records.length > 0 &&
                            <Fragment>
                                <FilterType typeFilter={typeFilter} onFilter={(val: string) => { setTypeFilter(val); setBData(null); }} />
                                <FilterSort sortFilter={sortFilter} onFilter={(val: string) => { setSortFilter(val); setBData(null); }} />
                            </Fragment>
                        }
                    </div>
                    {SPID == "U2FsdGVkX18EI4k80FtcVtq4PzxA94OPqaGqyI1vxNM=" && <button className="v-button primary invisible-button" id="writeReview"
                        style={{


                            margin: "0 6px",
                            fontSize: "14px",
                        }}
                        onClick={(event) => !IsEditor && (window as any).WriteReviews(userContext, SPID, event, ReloadReview)}>Write a review</button>}
                </div>
            </div>
            {bData && bData.records && bData.records.length == 0 &&
                <div className="reviewbtnparent bethe-first center-all" role="none">
                    <button id="beTheFirstReview" onClick={(event) => !IsEditor && (window as any).WriteReviews(userContext, SPID, event, ReloadReview)} className="v-button secondary vagarodefualt-sec invisible-button">Be the first to give a review!</button>
                </div>
            }
            <input type="hidden" id="RenderReloadReview" onClick={() => ReloadReview()} />

        </Fragment>

    }

    if (bData == "error") {
        return <OopsBanner />
    }

    switch (layout) {
        case "2": return <RenderCleanLayout data={bData} />
        default:
            return <RenderDefaultLayout data={bData} />
    }

    function RenderReviewItem({ item }: { item: any }): JSX.Element {
        const rating = item.averageRank as number;
        const reviewImages = item.reviewImages as string;
        let ReviewImg: Array<string> = [];
        if (typeof reviewImages != 'undefined' && reviewImages != null && reviewImages != "") {
            ReviewImg = reviewImages.split(',');
        }
        let dateString = new Date(item.publishDate).toDateString().substring(4);
        if (dateString.length > 5)
            dateString = dateString.substring(0, dateString.length - 5) + "," + dateString.substring(dateString.length - 5);
        return <div className="review-item">
            <div className="review-item-head align-center" key="review-user-row">
                {typeof item.reviewerPhoto != 'undefined' && item.reviewerPhoto != null && item.reviewerPhoto != "" && !isStenciled &&
                    <img className="review-user-image mr15" style={avatarStyle} src={item.reviewerPhoto} key={item.reviewerPhoto + item.reviewer + item.publishDate} alt={item.reviewer} />
                }
                {(typeof item.reviewerPhoto == 'undefined' || item.reviewerPhoto == null || item.reviewerPhoto == "") &&
                    <div className="review-profile vagaro-avatar-md mr15">
                        <div className="review-profile-inner-div intialsname vg-avatar-inital" style={{ display: "block" }}>
                            <div className="review-profile-inner vg-avatar-inital-inner">
                                <div className="review-profile-inner-text vg-avatar-inital-inner-text stencil-item" style={{ borderRadius: "100px" }}>{item.reviewerIntials}</div>
                            </div>
                        </div>
                    </div>
                }
                <div className="review-item-meta stencil-item">
                    <h3 className="review-name">{item.reviewer}</h3>
                    <div className="review-date vp-2">{dateString}</div>
                </div>
            </div>
            {

                isStenciled ? <div className="stencil-item" style={{ width: "200px", height: "24px", margin: "4px 0px" }}></div> :
                    <div className="vg-star-rating review-stars stencil-itme">
                        <RenderStarRating rating={rating} starColor={starColor} isOutlined={false} />
                        {item.isUserHaveAppointment && <Fragment><div className="verified">
                            <i className="far fa-check"></i> Verified</div>
                        </Fragment>}
                    </div>
            }
            {item.venueReview && item.venueReview.length > 2 &&
                <div className="review-text-row">
                    <h3 className="">Venue</h3>
                    <p className="vp-2">{item.venueReview}</p>
                </div>
            }
            {item.serviceProviderReview && item.serviceProviderReview.length > 2 &&
                <div className="review-text-row ">
                    <h3 className="">{item.serviceProviderName}</h3>
                    <p className="vp-2 ">{item.serviceProviderReview}</p>
                </div>
            }
            {typeof item.reviewImages != 'undefined' && item.reviewImages != null && item.reviewImages != "" && ReviewImg.length > 0 &&
                <Fragment>
                    <div className={`v-photo-gallery${item.divID}`}>
                        <ReviewImages key={item.divID} data={ReviewImg} cls={`.v-photo-gallery${item.divID}`} />
                    </div>
                </Fragment>
            }
        </div>
    }

    function RenderReviewItem2({ item }: { item: any }): JSX.Element {
        const rating = item.averageRank as number;
        const reviewImages = item.reviewImages as string;
        let ReviewImg: Array<string> = [];
        if (typeof reviewImages != 'undefined' && reviewImages != null && reviewImages != "") {
            ReviewImg = reviewImages.split(',');
        }
        let dateString = new Date(item.publishDate).toDateString().substring(4);
        if (dateString.length > 5)
            dateString = dateString.substring(0, dateString.length - 5) + "," + dateString.substring(dateString.length - 5);
        return <>

        
            <div className={`container-query-container   ${styles.reviewsWrapper}`}>
                <div className={`${styles.review }`} key="review-user-row">
                    {/* {typeof item.reviewerPhoto != 'undefined' && item.reviewerPhoto != null && item.reviewerPhoto != "" && !isStenciled &&
                    <img className="review-user-image mr15" style={avatarStyle} src={item.reviewerPhoto} key={item.reviewerPhoto + item.reviewer + item.publishDate} alt={item.reviewer} />
                }
                {(typeof item.reviewerPhoto == 'undefined' || item.reviewerPhoto == null || item.reviewerPhoto == "") &&
                    <div className="review-profile vagaro-avatar-md mr15">
                        <div className="review-profile-inner-div intialsname vg-avatar-inital" style={{ display: "block" }}>
                            <div className="review-profile-inner vg-avatar-inital-inner">
                                <div className="review-profile-inner-text vg-avatar-inital-inner-text stencil-item" style={{ borderRadius: "100px" }}>{item.reviewerIntials}</div>
                            </div>
                        </div>
                    </div>
                } */}
                    <div className={` ${styles.metaContainer }`}>

                        {

                        isStenciled ? <div className="stencil-item" style={{ width: "200px", height: "24px", margin: "4px 0px" }}></div> :
                            <div className="vg-star-rating review-stars stencil-itme">
                                <RenderStarRating rating={rating} starColor={starColor} isOutlined={false} />
                            </div>
                        }

                       <div className="glowingCard">
                       <h3 style={{color:"#333"}} className={styles.username + " stencil-item"}>{item.reviewer}</h3>
                        <div className={styles.date+ " stencil-item"}>{dateString}</div>
                       </div>
                        {item.isUserHaveAppointment && <Fragment>
                            <div>
                            <span className={styles.circleWrapper + " stencil-item"}> <i className="far fa-check"></i></span><span className="stencil-item"> Verified</span></div>
                        </Fragment>}

                        
                    </div>

                    <div className={styles.outerWrapper}>
                        {item.venueReview && item.venueReview.length > 2 &&
                            <div className={styles.venueResponseWrapper}>
                                <h3 style={{color:"#333"}} className={styles.username + " stencil-item"}>Venue</h3>
                                <p  style = {{color:"#333"}} className={styles.comment + " stencil-item vp-2"}>{item.venueReview}</p>
                                <hr style={{
                                    color: '#000000',
                                    backgroundColor: '#666666',
                                    height: .5,
                                    width: "100%",
                                    borderColor : '#666666'
                                }}/>
                            </div>
                        }
                        {item.serviceProviderReview && item.serviceProviderReview.length > 2 &&
                            <div className={styles.serviceProviderResponseWrapper}>
                                <h3 style={{color:"#333"}} className={styles.username + " stencil-item"}>{item.serviceProviderName}</h3>
                                <p style={{color:"#333"}} className={styles.comment + " stencil-item vp-2"}>{item.serviceProviderReview}</p>
                                <hr style={{
                                    color: '#000000',
                                    backgroundColor: '#666666',
                                  
                                    width: "100%",
                                    borderColor : '#666666'
                                }}/>
                            </div>
                           
                        }
                        {typeof item.reviewImages != 'undefined' && item.reviewImages != null && item.reviewImages != "" && ReviewImg.length > 0 &&
                            <Fragment>
                                <div className={`v-photo-gallery${item.divID}`}>
                                    <ReviewImages key={item.divID} data={ReviewImg} cls={`.v-photo-gallery${item.divID}`} />
                                </div>
                            </Fragment>
                        }
                    </div>
                </div>


            </div>
        </>

    }

    function ReviewImages({ data, cls }: { data: any, cls: string }): JSX.Element {
        useEffect(() => {
            let lightbox = new PhotoSwipeLightbox({
                gallery: cls,
                children: ".review-user-image",
                padding: { top: 40, bottom: 40, left: 100, right: 100 },
                // type:"html",
                loop: false,
                pswpModule: PhotoSwipe,
                showHideAnimationType: 'none',
                zoomAnimationDuration: false,
                initialZoomLevel: "fit",
                secondaryZoomLevel: "fit",
                maxZoomLevel: "fit",
                tapAction: 'close',
                imageClickAction: () => { return false },
            });
            lightbox.init();
            return () => {
                lightbox.destroy();
                // lightbox = null;
            };
        }, [])

        return (<>
            <div className="div-flex d-flex review-user-row">
                {
                    data.map((image: any, index: any) => {
                        return <div className="review-user-image mr15" style={{ ...ImageStyle, backgroundImage: "url(" + image + ")" }} key={image + index} >

                            <ReviewImagesTag Img={image} Index={index} />
                        </div>
                    })
                }
            </div>
        </>)
    }

    function ReviewImagesTag({ Img, Index }: { Img: any, Index: any }): JSX.Element {
        const img = new Image()
        const [width, setWidth] = useState(0);
        const [height, setHeight] = useState(0);
        img.src = Img
        img.onload = () => {
            setWidth(img.width)
            setHeight(img.height)
        }
        return <>
            <a
                data-pswp-type={""}
                data-style={Index}
                data-pswp-src={Img}
                className={`vgphoto-m-item`}
                id={`no-a vgphoto-m-item-${Index}`}
                data-pswp-width={width}
                data-pswp-height={height}
                href="_blank"
                rel="noopener"
            >
                <img alt="" src={Img} style={{ width: "0", height: "0", display: "none" }} />
            </a>
        </>
    }


    function Pagination (){

  
      return  <div className="flex-row  justify-center "  >
        {
            page > 1 &&
            <button className="invisible-button" style={btnStyle} onClick={() => { setPage(page - 1); setBData(null); scrollToTop(); }}><ChevronLeft/></button>
        }
        {
            bData.totalPages > 1 &&
            counttoFive.map((num, index: number) => {
                const pageNumb = page + index - 2;
                if (pageNumb === page) {
                    return <button className=" invisible-button" style={btnStyleSelected} key={index + "a"}> {pageNumb}</button>
                }
                else if (pageNumb > 0 && pageNumb <= (bData.totalPages as number)) {
                    return <button className=" invisible-button" style={btnStyle} key={index + "b"} onClick={() => { setPage(pageNumb); setBData(null); scrollToTop(); }}>{pageNumb}</button>
                }
                else
                    return null;
            })
        }
        {
            page < (bData.totalPages as number) &&
            <button className="invisible-button" style={btnStyle} onClick={() => { setPage(page + 1); setBData(null); scrollToTop(); }}><ChevronRight/></button>
        }
    </div>
    }


    function RenderDefaultLayout({ data }: { data: any }): JSX.Element {

        return (<Fragment>
            {/* <FilterRow /> */}
            {typeof bData != 'undefined' && bData != null &&
                <Fragment>

                    <div className={`reviewbody ${(isPopupAvailable ? "review-data-popup " : "") + (isStenciled ? "stenciled" : "")} ${bData && bData.records && bData.records.length > 0 ? "bodyhasFilter" : ""}`}>
                        <FilterRow />
                        <div className="reviewbyemployee">
                            {bData.records &&
                                bData.records.map((review: any, index: number) => {
                                    return <RenderReviewItem key={index} item={review} />
                                })
                            }
                        </div>

                            <Pagination />

                    </div>
            
                </Fragment>
            }
            {typeof bData == 'undefined' || bData == null &&
                <Fragment><EditorLoadingSpinner></EditorLoadingSpinner></Fragment>
            }
        </Fragment>);

    }

    function RenderCleanLayout({ data }: { data: any }): JSX.Element {
        return (<Fragment>
            {/* <FilterRow /> */}
            {/* <RenderReviews2 /> */}
            {typeof bData != 'undefined' && bData != null &&
                <Fragment>

                    <div style={{color:"#333",overflow:"auto"}} className={`reviewbody ${(isPopupAvailable ? "review-data-popup " : "") + (isStenciled ? "stenciled" : "")} ${bData && bData.records && bData.records.length > 0 ? "bodyhasFilter" : ""}`}>
                        <FilterRow />
                        <div className={styles.reviewsContainer}>
                            {bData.records &&
                                bData.records.map((review: any, index: number) => {
                                    return <RenderReviewItem2 key={index} item={review} />
                                })
                            }
                        </div>
                            <Pagination/>
                    </div>
                </Fragment>
            }
            {typeof bData == 'undefined' || bData == null &&
                <Fragment><EditorLoadingSpinner></EditorLoadingSpinner></Fragment>
            }
        </Fragment>);

    }
}


function FilterSort({ onFilter, sortFilter }: { onFilter: Function, sortFilter: any }): JSX.Element {
    var IsEditor = (window as any).isEditor !== undefined ? (window as any).isEditor : false;
    return <Dropdown>
        <Dropdown.Toggle variant="w-button-secondary" as="a" bsPrefix="v-button invisible-button secondary no-a no-margin" id="dropdown-basic">
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512" fill="currentColor"><path d="M15 377l96 96c9.4 9.4 24.6 9.4 33.9 0l96-96c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-55 55V56c0-13.3-10.7-24-24-24s-24 10.7-24 24V398.1L49 343c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9zM312 480h48c13.3 0 24-10.7 24-24s-10.7-24-24-24H312c-13.3 0-24 10.7-24 24s10.7 24 24 24zm0-128H424c13.3 0 24-10.7 24-24s-10.7-24-24-24H312c-13.3 0-24 10.7-24 24s10.7 24 24 24zm0-128H488c13.3 0 24-10.7 24-24s-10.7-24-24-24H312c-13.3 0-24 10.7-24 24s10.7 24 24 24zm0-128H552c13.3 0 24-10.7 24-24s-10.7-24-24-24H312c-13.3 0-24 10.7-24 24s10.7 24 24 24z" /></svg>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.875 5.42188L6.39844 9.71094C6.25781 9.82812 6.11719 9.875 6 9.875C5.85938 9.875 5.71875 9.82812 5.60156 9.73438L1.10156 5.42188C0.867188 5.21094 0.867188 4.83594 1.07812 4.625C1.28906 4.39062 1.66406 4.39062 1.875 4.60156L6 8.53906L10.1016 4.60156C10.3125 4.39062 10.6875 4.39062 10.8984 4.625C11.1094 4.83594 11.1094 5.21094 10.875 5.42188Z" fill="currentColor" />
            </svg>
        </Dropdown.Toggle>
        {!IsEditor &&
            <Dropdown.Menu>
                <Dropdown.Item active={!IsEditor && sortFilter == 1 ? true : false} onClick={() => { !IsEditor && sortFilter != 1 && onFilter("1") }}>Newest</Dropdown.Item>
                <Dropdown.Item active={!IsEditor && sortFilter == 2 ? true : false} onClick={() => { !IsEditor && sortFilter != 2 && onFilter("2") }}>Oldest</Dropdown.Item>
                <Dropdown.Item active={!IsEditor && sortFilter == 3 ? true : false} onClick={() => { !IsEditor && sortFilter != 3 && onFilter("3") }}>Highest Rated</Dropdown.Item>
                <Dropdown.Item active={!IsEditor && sortFilter == 4 ? true : false} onClick={() => { !IsEditor && sortFilter != 4 && onFilter("4") }}>Lowest Rated</Dropdown.Item>
                <Dropdown.Item active={!IsEditor && sortFilter == 6 ? true : false} onClick={() => { !IsEditor && sortFilter != 6 && onFilter("6") }}>Service Provider</Dropdown.Item>
            </Dropdown.Menu>
        }
    </Dropdown>
}
function FilterType({ onFilter, typeFilter }: { onFilter: Function, typeFilter: any }) {
    var IsEditor = (window as any).isEditor !== undefined ? (window as any).isEditor : false;
    return <Dropdown>
        <Dropdown.Toggle variant="w-button-secondar no-a" as="a" bsPrefix="v-button invisible-button secondary no-a no-margin" id="dropdown-basic">
            <svg width="18" height="17" viewBox="0 0 18 17" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 1.78125C0 1.00781 0.597656 0.375 1.37109 0.375H16.5938C17.3672 0.375 18 1.00781 18 1.78125C18 2.09766 17.8594 2.41406 17.6484 2.66016L11.25 10.4297V14.9648C11.25 15.5977 10.6875 16.125 10.0547 16.125C9.77344 16.125 9.52734 16.0547 9.31641 15.8789L7.17188 14.2266C6.89062 14.0156 6.75 13.6992 6.75 13.3477V10.4297L0.316406 2.66016C0.105469 2.41406 0 2.09766 0 1.78125ZM1.37109 1.5C1.23047 1.5 1.125 1.64062 1.125 1.78125C1.125 1.85156 1.125 1.88672 1.16016 1.95703L7.73438 9.86719C7.80469 9.97266 7.875 10.1133 7.875 10.2188V13.3477L9.98438 15C10.0195 15 10.0195 15 10.0547 15C10.0898 15 10.125 15 10.125 14.9648V10.2188C10.125 10.1133 10.1602 9.97266 10.2305 9.86719L16.8047 1.95703C16.8398 1.88672 16.875 1.85156 16.875 1.78125C16.875 1.64062 16.7344 1.5 16.5938 1.5H1.37109Z" fill="currentColor" />
            </svg>

            <svg width="12" height="12" viewBox="0 0 12 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.875 5.42188L6.39844 9.71094C6.25781 9.82812 6.11719 9.875 6 9.875C5.85938 9.875 5.71875 9.82812 5.60156 9.73438L1.10156 5.42188C0.867188 5.21094 0.867188 4.83594 1.07812 4.625C1.28906 4.39062 1.66406 4.39062 1.875 4.60156L6 8.53906L10.1016 4.60156C10.3125 4.39062 10.6875 4.39062 10.8984 4.625C11.1094 4.83594 11.1094 5.21094 10.875 5.42188Z" fill="currentColor" />
            </svg>

        </Dropdown.Toggle>
        {!IsEditor &&
            <Dropdown.Menu>
                <Dropdown.Item active={!IsEditor && typeFilter == 1 ? true : false} onClick={() => { !IsEditor && typeFilter == 0 && onFilter("1") }}>Reviews With Comments</Dropdown.Item>
                <Dropdown.Item active={!IsEditor && typeFilter == 0 ? true : false} onClick={() => { !IsEditor && typeFilter == 1 && onFilter("0") }}>All Reviews</Dropdown.Item>
            </Dropdown.Menu>
        }
    </Dropdown>
}
declare global {
    interface Window { WriteReviews: any }
}

window.WriteReviews = function (userContext: UserContext, SPID: number, event: any, ReloadReview: any) {
    OpenWriteReviews({ userContext, SPID, event, ReloadReview })
};
function OpenWriteReviews({ userContext, SPID, event, ReloadReview }: { userContext: UserContext, SPID: number, event: any, ReloadReview: any }) {
    let LoginDetails = utils.ReadCookie();
    if (LoginDetails === undefined || LoginDetails === "" || LoginDetails === null) {
        window.wbc.openVagaroPage("divVagaroIframe", utils.VURL("Login", null) + addtoCartEnum.WriteReview + "&Data=" + event.target.id);
        let element = document.getElementsByClassName('vgn-popup');
        if (element.length > 0) {
            $(".modal-backdrop").hide();
            $(".vgn-popup").hide();
        }
        return;
    }
    return ReactDOM.render(
        <VgWriteReview
            userContext={userContext}
            SPID={SPID}
            LoginDetails={LoginDetails}
            ReloadReview={ReloadReview}
        />,
        document.getElementById("OpenDialog")
    )
}

function ChevronRight(){
    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="currentColor" height="1em"><path d="M299.3 244.7c6.2 6.2 6.2 16.4 0 22.6l-192 192c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6L265.4 256 84.7 75.3c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0l192 192z"/></svg>
}

function ChevronLeft(){
    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"  fill="currentColor" height="1em"><path d="M20.7 267.3c-6.2-6.2-6.2-16.4 0-22.6l192-192c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6L54.6 256 235.3 436.7c6.2 6.2 6.2 16.4 0 22.6s-16.4 6.2-22.6 0l-192-192z"/></svg>
}