import React, { Fragment, useEffect, useState, useRef } from "react";
import { addtoCartEnum, Site, utils } from "../../utils/Utils";
import ReactDom, { unmountComponentAtNode } from "react-dom";
import $ from "jquery";
import VGPopUp from "../Controls/VGPopUp";
import RenderBusinessCart from "./RenderBusinessCart";
const avatarStyle = { width: "30px", height: "30px", borderRadius: "50%", };

export default function RenderMyAccount({ layout = "1", userContext, businessID, countryID }: { layout: string, userContext: any, businessID: string, countryID: number }) {
    const [reload, setReLoad] = useState(false);
    const checklogin = useRef(true);
    let CID = 1;
    if (countryID > 0) {
        CID = countryID;
    }
    else {
        const BusinessData = utils.MultiLocationData(userContext, userContext.businessId);
        if (typeof BusinessData != undefined && BusinessData != null) {
            CID = BusinessData.countryID;
        }
    }



    useEffect(() => {
        if (reload) {
            MyAccount();
        }
    }, [reload]);

    switch (layout) {
        default:
            return <MyAccount />
    }


    function MyAccount() {
        var IsEditor = (window as any).isEditor !== undefined ? (window as any).isEditor : false;
        var LoginDetails = utils.ReadCookie();
        if (LoginDetails == '') {
            checklogin.current = true;
        }
        else {
            checklogin.current = false;
        }
        return (
            <Fragment>
                {IsEditor ? <a role="button" className={" v-login w-full  v-button center-all" + (layout == "2" ? " primary" : "")} style={layout != "2" ? { padding: "0px", background: "transparent" } : {}}>Login</a>
                    : checklogin.current == true ?
                        <a id="anklogout" role="button" className={" v-login w-full  v-button center-all" + (layout == "2" ? " primary" : "")} style={layout != "2" ? { padding: "0px", background: "transparent" } : {}} onClick={() => !IsEditor && Login()}>Login</a> :
                        <div id="dvlogin" className="v-login review-profile profile-drop-menu dropdown">
                            <div className="review-profile-inner-div intialsname vg-avatar-inital hideinmob" style={{ display: "block" }}>
                                <div className="review-profile-inner vg-avatar-inital-inner">
                                    {LoginDetails.UImage != 'undefined' && LoginDetails.UImage != "" && LoginDetails.UImage.indexOf("/user-male-img_155_new.png") < 0 && LoginDetails.UImage.indexOf("/user-female-img_155.jpg") < 0 ?
                                        <img className="review-user-image" style={avatarStyle} src={LoginDetails.UImage} key={LoginDetails.UImage} /> :
                                        <div className="review-profile-inner-text vg-avatar-inital-inner-text">{utils.setLetter(LoginDetails.UName.split(" ")[0], LoginDetails.UName.split(" ")[1])}</div>
                                    }
                                </div>
                            </div>
                            <div className="pofile-drop">
                                <div className="navigation-item d-flex align-center" onClick={() => MyAccountNavigation("myaccount")}>
                                    {LoginDetails.UImage != 'undefined' && LoginDetails.UImage != "" && LoginDetails.UImage.indexOf("/user-male-img_155_new.png") < 0 && LoginDetails.UImage.indexOf("/user-female-img_155.jpg") < 0 ?
                                        <img className="review-user-image mr15" style={avatarStyle} src={LoginDetails.UImage} key={LoginDetails.UImage} />
                                        :
                                        <div className="vagaro-avatar-sm mr15">
                                            <div className="intialsname vg-avatar-inital" style={{ display: "block" }}>
                                                <div className="vg-avatar-inital-inner">
                                                    <div className="review-profile-inner-text vg-avatar-inital-inner-text">{utils.setLetter(LoginDetails.UName.split(" ")[0], LoginDetails.UName.split(" ")[1])}</div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    My Profile
                                </div>
                                <a className="navigation-item no-a" onClick={() => MyAccountNavigation("familyfriends")} href="javascript:void(0)"><i className="fal fa-users"></i>Family &amp; Friends</a>
                                <a className="navigation-item no-a" onClick={() => MyAccountNavigation("notification")} href="javascript:void(0)"><i className="fal fa-bell"></i>Notification Settings</a>
                                <a className="navigation-item no-a" onClick={() => MyAccountNavigation("bookmarks")} href="javascript:void(0)"><i className="fal fa-bookmark"></i>Bookmarks</a>
                                <a className="navigation-item no-a" onClick={() => MyAccountNavigation("reviews")} href="javascript:void(0)"><i className="fal fa-edit"></i>Reviews</a>
                                <a className="navigation-item no-a" onClick={() => MyAccountNavigation("appointments")} href="javascript:void(0)"><i className="fal fa-calendar"></i>Appointments</a>
                                <a className="navigation-item no-a" onClick={() => MyAccountNavigation("products")} href="javascript:void(0)"><i className="vgr-icon vgr-product"></i>Products</a>
                                <a className="navigation-item no-a" onClick={() => MyAccountNavigation("packages")} href="javascript:void(0)" ><i className="fal fa-box-open"></i>Packages</a>
                                <a className="navigation-item no-a" onClick={() => MyAccountNavigation("memberships")} href="javascript:void(0)" ><i className="fal fa-id-card-alt"></i>Memberships</a>
                                <a className="navigation-item no-a" onClick={() => MyAccountNavigation("paymentmethods")} href="javascript:void(0)" ><i className="fal fa-credit-card"></i>Payment Methods</a>
                                {
                                    CID == 1 &&
                                    <a className="navigation-item no-a" onClick={() => MyAccountNavigation("vagaropaylater")} href="javascript:void(0)" ><i className="vgr-icon-img"><svg width="20" height="" viewBox="0 0 254 232" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<path d="M251.948 32.8125C248.951 21.5956 241.759 12.2411 231.698 6.43999C210.955 -5.54751 184.326 1.60218 172.338 22.3448L127 100.884L81.64 22.3234C69.6525 1.58077 43.0445 -5.54751 22.2805 6.41858C1.5378 18.4061 -5.59048 45.014 6.37562 65.7781L89.3034 209.393C95.1045 219.454 104.48 226.625 115.676 229.643C119.422 230.649 123.211 231.141 127 231.141C131.345 231.141 135.648 230.478 139.801 229.172C149.926 226.068 158.959 219.261 164.675 209.393L247.603 65.7781C253.404 55.7386 254.945 44.0294 251.948 32.8125ZM29.3873 18.7486C43.3442 10.6998 61.2612 15.4948 69.31 29.4517L118.78 115.12L89.4319 165.959C87.8264 168.721 86.5634 171.611 85.6216 174.586L18.6842 58.6712C10.6355 44.7144 15.4305 26.7973 29.3873 18.7486ZM235.294 58.6712L152.366 202.286C149.69 206.931 145.923 210.549 141.577 213.032L141.556 212.989C137.339 215.429 132.737 216.692 128.177 216.863C128.027 216.863 127.877 216.885 127.706 216.885C127.556 216.885 127.428 216.885 127.278 216.885C122.226 216.928 117.11 215.686 112.444 212.989C98.4867 204.94 93.6917 187.023 101.74 173.066L184.668 29.4517C188.564 22.6873 194.879 17.8495 202.414 15.8373C204.94 15.1523 207.487 14.8312 210.035 14.8312C215.108 14.8312 220.096 16.1584 224.591 18.77C231.355 22.6659 236.193 28.9808 238.205 36.5158C240.217 44.0294 239.19 51.9069 235.294 58.6712Z" fill="currentColor"/>
<path d="M128.22 197.919C134.982 197.919 140.464 192.437 140.464 185.675C140.464 178.913 134.982 173.431 128.22 173.431C121.458 173.431 115.976 178.913 115.976 185.675C115.976 192.437 121.458 197.919 128.22 197.919Z" fill="currentColor"/></svg>

                                        </i>Vagaro Pay Later</a>
                                }
                                <a className="navigation-item no-a" onClick={() => MyAccountNavigation("points")} href="javascript:void(0)" ><i className="fal fa-star"></i>Points</a>
                                <a className="navigation-item no-a" onClick={() => MyAccountNavigation("gift")} href="javascript:void(0)" ><i className="fal fa-gift"></i>Gift Cards</a>
                                <a className="navigation-item no-a" onClick={() => MyAccountNavigation("invoices")} href="javascript:void(0)"><i className="fal fa-file-invoice"></i>Invoices</a>
                                <a className="navigation-item no-a" onClick={() => LogoutPopup()}><i className="fal fa-power-off"></i>Logout</a>

                            </div>

                        </div>

                }
            </Fragment>
        )
    }


    function Login() {
        if ($("#MobileNavOnClick").length > 0) {
            $("#MobileNavOnClick").click();
        }
        window.wbc.openVagaroPage("divVagaroIframe", utils.VURL("Login", null) + addtoCartEnum.Cart + "&Data=");
    }
    function GoToMyAccount() {
        window.location.href = "myaccount";
    }


    function MyAccountNavigation(NavigationType: string) {
        var widgetCookieString = "";
        var vsdId = localStorage.getItem("vdsid")
        if (vsdId != null && vsdId != '' && vsdId != undefined) {
            widgetCookieString = "&wgVal=" + vsdId;
        }
        var navurl = "";

        if (businessID != "") {
            if (NavigationType === "myaccount") {
                navurl = Site.SiteName + "/MyAccount?Type=Builder&Event=MyAccount&encData=" + businessID + widgetCookieString;
            }
            else {
                navurl = Site.SiteName + "/MyAccount/" + NavigationType + "?Type=Builder&Event=MyAccount&encData=" + businessID + widgetCookieString;
            }
        }
        else {
            if (NavigationType === "myaccount") {
                navurl = Site.SiteName + "/MyAccount?Type=Builder&Event=MyAccount&encData=" + userContext.businessId + widgetCookieString;
            }
            else {
                navurl = Site.SiteName + "/MyAccount/" + NavigationType + "?Type=Builder&Event=MyAccount&encData=" + userContext.businessId + widgetCookieString;
            }
        }
        window.wbc.openVagaroPage("divVagaroIframe", navurl);
        return;
    }

    function PopupClose() {
        let doms = document.getElementById("OpenDialog");
        if (doms) unmountComponentAtNode(doms);
    };

    function LogoutPopup() {
        return ReactDom.render(
            <VGPopUp
                id={"LogoutPopup"}
                show={true}
                onHide={PopupClose}
                title={"Logout"}
                dialogClassName="webbuilder-preivew-style"
                body={
                    <div className="alertContent">
                        <p>Are you sure you want to logout?</p>
                    </div>
                }
                footer={
                    <Fragment>
                        <button className="v-button secondary" onClick={() => PopupClose()}>Cancel</button>
                        <button className="v-button primary invisible-button" onClick={() => Logout()}>OK</button>
                    </Fragment >
                }
                isRemoveTabModalIndex={false}
                isShowFooter={true}
                size={"md"}
                backdrop={true}
            />,
            document.getElementById("OpenDialog"))
    }


    function Logout() {
        $(".wbloader").css("display", "block");
        deleteCookie("WebsiteBuilder,vPowerV2");
        ReactDom.render(<RenderBusinessCart data={"0"} userContext={userContext} />, document.getElementById("divcart"));
        setReLoad(true);
        /* Logout from Vagaro - Darshan Bhatt on 22/09/2023 */
        $('body').append('<div id=divDummyIframe" style="display:none;"><iframe class="b-iframe" id="iFrame_divDummyIframe" style="display:none;"></iframe></div>');
        $("#iFrame_divDummyIframe").attr("src", utils.SiteName() + "Login?Type=LogoutfromBuilder&Event=WBLogout");
        $("#iFrame_divDummyIframe").on("load", function (e) {
            $("#divDummyIframe").remove()
        });
        PopupClose();
    }

    function deleteCookie(cookieName: any) {
        const cookies = cookieName.split(",")
        cookies.forEach((cookieName: any) => {
            document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        });
    }
}

declare global {
    interface Window { LogoutmyAccount: any; }
}

window.LogoutmyAccount = function () {
    $(".wbloader").css("display", "block");
    deleteCookie("WebsiteBuilder,vPowerV2");
    /* Logout from Vagaro - Darshan Bhatt on 22/09/2023 */
    $('body').append('<div id=divDummyIframe" style="display:none;"><iframe class="b-iframe" id="iFrame_divDummyIframe" style="display:none;"></iframe></div>');
    $("#iFrame_divDummyIframe").attr("src", utils.SiteName() + "Login?Type=LogoutfromBuilder&Event=WBLogout");
    $("#iFrame_divDummyIframe").on("load", function (e) {
        $("#divDummyIframe").remove()
    });

}

function deleteCookie(cookieName: any) {
    const cookies = cookieName.split(",")
    cookies.forEach((cookieName: any) => {
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    });
}