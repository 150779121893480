export const wBComponentTypeNames = {
    accordion:"Accordian",
    section: "Section",
    column: "Column",
    heading: "Heading",
    pageRoot: "PageRoot",
    row: "Row",
    text: "Text",
    image: "Image",
    spacer: "Spacer",
    button: "button",
    dropDownMen: "dropDownMenue",
    container: "container",
    navigationBar: "navigationBar",
    navigationList: "navigationList",
    footer: "footer",
    test: "Test",
    imageSlider: "imageSlider",
    imageGrid: "imageGrid",
    iFrame: "iframe",
    videoPlayer:"videoPlayer",
    beforeAndAfter:"beforeAndAfter",
    htmlEmbed:"htmlEmbed",
    announcementBar:"announcementBar",
    cookieBanner:"cookieBanner",
    
    productDetails:"productDetails",
    businessDescription: "businessDescription",
    businessHours: "businessHours",
    businessProducts: "businessProducts",
    businessReviews: "businessReviews",
    businessGallery: "businessGallery",
    businessMap: "businessMap",
    businessServices: "businessServices",
    businessMemberships: "businessMemberships",
    businessPackages: "businessPackages",
    businessGiftCertificates: "businessGiftCertificates",
   
    businessSocialLinks: "businessSocialLinks",
    businessFacilityInformation:"businessFacility",
    businessDailyDeals:"businessDailyDeals",
    busienssStaff:"busienssStaff",
    businessreviewpercentage:"businessreviewpercentage",
    testComponent:"testComponent",
    businessMessage: "businessMessage",
    businessCall: "businessCall",
    businessContactLess: "businessContactLess",
    businessCart: "businessCart",
    myaccount: "myaccount",
    businessBookNow: "businessBookNow",
    businessClass: "businessClass",
    businessForm: "businessForm",
    businessLocation: "businessLocation",
    businessName: "businessName",
  };
  