import React from "react";
import { GetNodeDomAttributes } from "../Utilities/GetNodeDomAttributes";
import { WBRenderProps } from "../Definitions/WBRenderProps";
import { LinkTypeSeperator, LinkTypes } from "../Definitions/LinkData";
import { PageData, UserContext } from "../Definitions/UserContext";
import Dropdown from "react-bootstrap/esm/Dropdown";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import LayoutNode, { getNodeComponentAttributeValue } from "../Definitions/LayoutNode";
import ReactDOM from "react-dom";
import TrackedDropdown from "../TrackedDropdown";

export default function RenderButtonDropDown({

  component,
  pages,
  inContent

}: {component:LayoutNode,pages?: { url: string; name: string }[],inContent?:string}) {

  let foundPages: { url: string; name: string }[];
  let content = "";
  if(!pages){
  const ignoreDom = document.getElementById("ignored-pages");

  const ignoreList = ignoreDom ? ignoreDom.innerText.split(",") : [];

  const linearPages: PageData[] = JSON.parse(getNodeComponentAttributeValue(component,"menuPages","[]"));

   content = getNodeComponentAttributeValue(component,"savedContent","");

   foundPages = linearPages.filter((pg) => {
    return !ignoreList.includes(pg.url);
  });
  }
  else{
    foundPages = pages;
    content = inContent??"";
  }
  const missingLink = foundPages.length > 0 ? "" : " missing-link ";
  const domRef = React.useRef<HTMLDivElement>(null);

  const closePopUp = (inDom:any) => {

    if(!inDom)
    inDom = document.getElementById("btn-dropdown-container");

    if(inDom)
    ReactDOM.unmountComponentAtNode(inDom);
  }

  const openPopUp = () => {
 
      let dom = document.getElementById("btn-dropdown-container");
    
 
      if(!dom){
        dom = document.createElement("div");
        dom.id = "btn-dropdown-container";
        document.body.appendChild(dom);
      }
    


    if(domRef.current){
      let parent = domRef.current.parentElement;
      ReactDOM.render(<TrackedDropdown options={foundPages} domTarget={parent!} onClose={()=>closePopUp(dom)} />,dom)
    }

  }


  if (foundPages.length > 1) {


    return (
      <>      <span className="center-all" style={{visibility:"hidden",opacity:"0"}} dangerouslySetInnerHTML={{__html:content}}></span> {/* use duplicate placeholder to make sure button size is consistant with editors */}
      <button
        style={{background:"none"}}
        className={" invisible-button relative comp-drop-down no-a center-all inherit-font-w  inherit-color dropown-caret" + missingLink + " " }
        dangerouslySetInnerHTML={{__html:content}}
        onClick={openPopUp}
      >
     
      </button>
      <div ref={domRef}></div>
      </>


    );

  } else if (foundPages.length == 1) {
    return (
      <>  <span className="center-all" style={{visibility:"hidden",opacity:"0"}} dangerouslySetInnerHTML={{__html:content}}></span> {/* use duplicate placeholder to make sure button size is consistant with editors */}
      <a
        href={foundPages[0].url}
        className={" invisible-button comp-drop-down  inherit-font-w no-a center-all" + missingLink + " " }
        dangerouslySetInnerHTML={{__html:content}}
      >
      
      </a>
      </>
    );
  }
  return <></>;
}


