import { addtoCartEnum, utils } from '../utils/Utils';
import { AsyncAjaxPost } from "../components/Utilities/AsyncAjaxService"
import React, { useEffect, useState } from 'react'
import Loader from '../components/Common/Loader';
import ReactDOM, { unmountComponentAtNode } from "react-dom";
import RenderBusinessCart from "../components/BusinessControls/RenderBusinessCart";
import { NoRecordsBanner } from '../components/OopsBanner';
import ReactDom from "react-dom";
import MultiLocationDropdown from "../VagaroComponent/MultiLocationDropdown"

const PackageAddToCart = (props) => {
    const PackageData = props.data
    const SiteData = props.SiteData;
    const event = props.event
    const allowMultiLoc = props.allowMultiLoc;
    const [isContentLoading, setContentLoading] = useState(false);
    const BusinessData = utils.MultiLocationData(props.SiteData, props.SiteData.bidHash);

    useEffect(() => {
        AddCart("", true, false, null);
    }, [props]);

      if (utils.isCheckEmptyOrWhiteSpace(BusinessData)) return <NoRecordsBanner message='Marchant data is not valid.' />;

    const AddCart = async (encBID, open, IsCallback, CallBackData) => {
        let LoginDetails = utils.ReadCookie();
        if (LoginDetails.EncUid === undefined || LoginDetails.EncUid === "" || LoginDetails.EncUid === null) {
        window.wbc.openVagaroPage("divVagaroIframe", utils.VURL("Login", null) + addtoCartEnum.PackageAddToCart + "&Data=" + event.target.id);
        return;
        }
        if (allowMultiLoc && open !== false) {
            const MultiGroupPackage = PackageData.multiGroupPackage;
            let Multilocations = [];
            if (!utils.isNullEmptyOrWhiteSpace(MultiGroupPackage)) {
                var businessData = JSON.parse(MultiGroupPackage);
                businessData.map((data, index) => {
                    let Data = [];
                    Data = SiteData.MultilocationData.filter(
                        (col) => col.encBIDHash == data.BID
                    );
                    if (Data.length > 0) {
                        Multilocations.push(Data[0]);
                    }
                });
            }
            if (Multilocations.length > 1) {
                return ReactDom.render(
                    <MultiLocationDropdown
                        Sitedata={Multilocations}
                        OnClickEvent={AddCart}
                        Mode={"packageAddCart"}
                        userContext={SiteData}
                        Package={PackageData}
                        IsShowMap={true}
                    />,
                    document.getElementById("OpenDialogMultiLocation")
                );
            }
        }
        if (IsCallback) {
            PackageData.id = CallBackData.PID;
            PackageData.promotionDetail = "";
            PackageData.packageName = "";
            PackageData.description = "";
        }
        setContentLoading(true);
        const Requestdata = {};
        Requestdata.EncServiceId = PackageData.id;
        Requestdata.EncUid = LoginDetails.EncUid;
        Requestdata.noOfVisit = 0;
        Requestdata.promotionDetail = PackageData.promotionDetail;
        Requestdata.promotionTitle = PackageData.packageName;
        Requestdata.ServiceDesc = PackageData.description;
        Requestdata.isCustomPackage = true;
        Requestdata.lstAddonService = [];
        Requestdata.IsInitialDiscount = false;
        Requestdata.InitialDiscount = 0;
        Requestdata.IsAmount = false;
        Requestdata.BillingCycle = 0;
        Requestdata.recGiftCertificateId = "";
        await AsyncAjaxPost(
            (encBID != undefined && encBID != null && encBID != "") ? encBID + "/addtocart/serviceaddtocart" : SiteData.businessId + "/addtocart/serviceaddtocart",
            Requestdata,
            SiteData.accessToken,
            function onSuccess(data) {
                var packagecart = data.data.data;
                unmountComponentAtNode(document.getElementById("divcart"));
                ReactDOM.render(<RenderBusinessCart data={packagecart.itemCount} userContext={SiteData} />, document.getElementById("divcart"));
                setContentLoading(false);
            }
        )
    }
    return <>{isContentLoading && < Loader />}</>;
}

export default PackageAddToCart
