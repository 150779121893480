import { addtoCartEnum, utils } from '../utils/Utils';
import VGPopUp from '../components/Controls/VGPopUp';
import React, { Fragment } from 'react';
import { unmountComponentAtNode, createPortal } from "react-dom";

const CommonMessagepopup = (props) => {

    const PopupTitle = () => {
        if (props.IsMembeship) {
            return "Multiple Memberships in Cart"
        }
        else if (props.isService) {
            return "Daily Deals Available";
        }
        else if (props.IsMembeshippurchased) {
            return "You Already Have a Membership";
        }
        else if (props.isBusinessCall) {
            return "Alert";
        }
        else if (props.isCart) {
            return "Alert";
        }
        else {
            return "Unable to Write Review";
        }
    };

    const PopupBody = () => {
        if (props.IsMembeship) {
            return (
                <Fragment>
                    <div>A membership already exists in your cart, would you like to replace it?</div>
                </Fragment>
            )
        }
        else if (props.isService) {
            return (
                <Fragment>
                    <div class="promo-container font-small" style={{ fontWeight: "bold", marginBottom: "4px" }}> <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512" fill="currentColor"><path d="M0 80V229.5c0 17 6.7 33.3 18.7 45.3l176 176c25 25 65.5 25 90.5 0L418.7 317.3c25-25 25-65.5 0-90.5l-176-176c-12-12-28.3-18.7-45.3-18.7H48C21.5 32 0 53.5 0 80zm112 32a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"></path></svg>hello</div>
                    <br />
                    <Fragment>
                        View the booking availability of employees who offer this daily deal to claim your discount. Viewing all employees may limit your access to claim this deal.
                    </Fragment>
                </Fragment>
            )
        } else if (props.IsMembeshippurchased) {
            return (
                <Fragment>
                    <Fragment>
                        You can only have one active membership at this business. If you would like to change or modify your membership, contact the business.
                    </Fragment>
                </Fragment>
            )
        }
        else if (props.isBusinessCall) {
            return (
                <Fragment>
                    <Fragment>
                        Business telephone is not exists.
                    </Fragment>
                </Fragment>
            )
        }
        else if (props.isCart) {
            return (
                <Fragment>
                    <Fragment>
                        No item in cart.
                    </Fragment>
                </Fragment>
            )
        }
        else {
            return (
                <Fragment>
                    <div>You are an employee at this business. Employees cannot write reviews for their own business.</div>
                </Fragment>
            )
        }
    }

    const Footer = () => {
        if (props.IsMembeship) {
            return (
                <Fragment>
                    <button onClick={() => handlepopupClose()} className="v-button secondary invisible-button">No</button>
                    <button onClick={props.Replacemembership} className="v-button primary invisible-button">Replace Membership</button>
                </Fragment>
            )
        }
        else if (props.isService) {
            return (
                <Fragment>
                    <button onClick={() => DailyDeals(true)} className="v-button secondary invisible-button">View All</button>
                    <button onClick={() => DailyDeals(false)} className="v-button primary invisible-button">View Daily Deal</button>
                </Fragment>
            )
        }
        else if (props.isBusinessCall) {
            return (
                <Fragment>

                    <button onClick={() => handlepopupClose()} className="v-button secondary invisible-button">Close</button>
                </Fragment>
            )
        }
        else if (props.isCart) {
            return (
                <Fragment>
                    <button onClick={() => handlepopupClose()} className="v-button primary invisible-button">OK</button>
                </Fragment>
            )
        }
        else {
            return (
                <Fragment>
                    <button onClick={() => handlepopupClose()} className="v-button secondary invisible-button">OK</button>
                </Fragment>
            )
        }
    }

    const DailyDeals = (action) => {
        var jsonData = JSON.stringify({ serviceid: props.ServiceData.serviceId, providerID: 0, styleID: 0, styleName: "", bookingDateTime: "", PromotionID: "", EventType: props.ServiceData.isBundle ? "5" : "1", isdeeplinkentry: null, PromotionProviderID: action ? "" : null }).replace(/\\\"/g, '"').replace(/"/g, "\\\"");
        if (action) {
            unmountComponentAtNode(document.getElementById("OpenDialog1"));
            window.wbc.openVagaroPage("divVagaroIframe", utils.VURL(null, props.BusinessData.vagaroURL) + addtoCartEnum.ServiceBook + "&Data=" + jsonData);
            return;
        }
        else {
            unmountComponentAtNode(document.getElementById("OpenDialog1"));
            window.wbc.openVagaroPage("divVagaroIframe", utils.VURL(null, props.BusinessData.vagaroURL) + addtoCartEnum.ServiceBook + "&Data=" + jsonData);
            return;
        }
    };

    const handlepopupClose = () => {
        if (props.isService) {
            unmountComponentAtNode(document.getElementById("OpenDialog1"));
        }
        if (props.IsMembeship) {
            unmountComponentAtNode(document.getElementById("OpenDialog1"));
        }

        unmountComponentAtNode(document.getElementById("OpenDialog1"));
    };

    return createPortal(
        <VGPopUp
            show={true}
            title={PopupTitle()}
            onHide={handlepopupClose}
            body={PopupBody()}
            footer={Footer()}
            dialogClassName={"vgn-modal-alert"}
            isShowFooter={true}
        />,
        document.getElementById("OpenDialog1")
    )
}

export default CommonMessagepopup;