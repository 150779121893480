import React, { Fragment, useRef } from "react";
import OopsBanner from "../OopsBanner";
import { utils } from "../../utils/Utils";
import { AsyncAjaxGet } from "../Utilities/AsyncAjaxService";
import ReactDom, { unmountComponentAtNode } from "react-dom";
import VGPopUp from "../Controls/VGPopUp";

export default function RenderBusinessMap({ data, layout, userContext }: { data: any, layout: string, userContext: any }) {
    if (!data)
        return <OopsBanner />

    if (!data.mapUrl)
        data.mapUrl = "";
    if (!data.photoUrl)
        data.photoUrl = "";
    if (!data.address)
        data.address = "no location found for this business...";

    switch (layout) {
        case "2":
            return <LocationOnlyLayout data={data} userContext={userContext} />
        default:
            return <DefaultLayout data={data} userContext={userContext} />
    }

}

function DefaultLayout({ data, userContext }: { data: any, userContext: any }) {
    var IsEditor = (window as any).isEditor !== undefined ? (window as any).isEditor : false;
    var CountryName = "";
    if (data.countryID == 2) {
        CountryName = "United Kingdom";
    }
    else if (data.countryID == 3) {
        CountryName = "Canada";
    }
    else if (data.countryID == 4) {
        CountryName = "Australia";
    }
    else {
        CountryName = "United States";
    }
    return (
        <Fragment>
            <div className={"w-full h-full flex-column gap-small " + (data.isStenciled ? "stenciled" : "")}>
                <a className="business-map w-full h-full stencil-item no- a" href="javascript:void(0)" onClick={() => !IsEditor && MapUrl(data, userContext)} style={{ display: "block", backgroundImage: `url(${data.photoUrl})`, backgroundPosition: "center", backgroundSize: "cover" }}></a>
                {(data.isMobileServiceMandatory == 0 || data.isMobileServiceMandatory == 2) &&
                    <a href="javascript:void(0)" onClick={() => !IsEditor && MapUrl(data, userContext)} className="stencil-item map-label"><MapLocationIcon />&nbsp;{data.address.replaceAll(",", ", ")}</a>
                }
                {(data.isMobileServiceMandatory == 1 || (data.isMobileServiceMandatory == 2 && data.hasOutCallService == true)) && data.cityCount > 0 &&
                    <a href="javascript:void(0)" onClick={() => !IsEditor && ShowCityModal(data.cityDetails, data.cityCount, data.businessName)} className="stencil-item map-label" style={{ display: "flex" }}><span style={{ marginTop: "5px" }}><HouseCallLocationIcon IsBlack={false} /></span>&nbsp;We are a Mobile Business Servicing {data.cityCount} Cities</a>
                }
                {(data.isMobileServiceMandatory == 1 || (data.isMobileServiceMandatory == 2 && data.hasOutCallService == true)) && data.cityCount == 0 &&
                    <a href="javascript:void(0)" onClick={() => !IsEditor} className="stencil-item map-label" style={{ display: "flex", cursor: "default" }}><span><HouseCallLocationIcon IsBlack={true} /></span>&nbsp;We are a Mobile Business Servicing Everywhere in the {CountryName}</a>
                }
            </div>
        </Fragment>
    )
}

function LocationOnlyLayout({ data, userContext }: { data: any, userContext: any }) {
    var IsEditor = (window as any).isEditor !== undefined ? (window as any).isEditor : false;
    var CountryName = "";
    if (data.countryID == 2) {
        CountryName = "United Kingdom";
    }
    else if (data.countryID == 3) {
        CountryName = "Canada";
    }
    else if (data.countryID == 4) {
        CountryName = "Australia";
    }
    else {
        CountryName = "United States";
    }
    return <Fragment>
        <div className={(data.isStenciled ? "stenciled" : "")}>
            {(data.isMobileServiceMandatory == 0 || data.isMobileServiceMandatory == 2) &&
                <a href="javascript:void(0)" onClick={() => !IsEditor && MapUrl(data, userContext)} className="stencil-item map-label"><MapLocationIcon />&nbsp;{data.address.replaceAll(",", ", ")}</a>
            }
            {(data.isMobileServiceMandatory == 1 || (data.isMobileServiceMandatory == 2 && data.hasOutCallService == true)) && data.cityCount > 0 &&
                <a href="javascript:void(0)" onClick={() => !IsEditor && ShowCityModal(data.cityDetails, data.cityCount, data.businessName)} className="stencil-item map-label" style={{ display: "flex", alignItems: "center" }}><span style={{ marginTop: "5px;" }}><HouseCallLocationIcon IsBlack={false} /></span>&nbsp;We are a Mobile Business Servicing {data.cityCount} Cities</a>
            }
            {(data.isMobileServiceMandatory == 1 || (data.isMobileServiceMandatory == 2 && data.hasOutCallService == true)) && data.cityCount == 0 &&
                <a href="javascript:void(0)" onClick={() => !IsEditor} className="stencil-item map-label" style={{ display: "flex", alignItems: "center", cursor: "default" }}><span><HouseCallLocationIcon IsBlack={true} /></span>&nbsp;We are a Mobile Business Servicing Everywhere in the {CountryName}</a>
            }
        </div>
    </Fragment>

}



function MapLocationIcon() {
    return <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512" fill="currentColor"> <path d="M429.6 92.1c4.9-11.9 2.1-25.6-7-34.7s-22.8-11.9-34.7-7l-352 144c-14.2 5.8-22.2 20.8-19.3 35.8s16.1 25.8 31.4 25.8H224V432c0 15.3 10.8 28.4 25.8 31.4s30-5.1 35.8-19.3l144-352z" /></svg>
}

function HouseCallLocationIcon({ IsBlack }: { IsBlack: any }) {
    if (IsBlack) {
        return <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="currentColor"><path fill-rule="evenodd" clip-rule="evenodd" d="M2 14.803v6.447c0 .414.336.75.75.75h1.614a.75.75 0 0 0 .74-.627L5.5 19h13l.395 2.373a.75.75 0 0 0 .74.627h1.615a.75.75 0 0 0 .75-.75v-6.447a5.954 5.954 0 0 0-1-3.303l-.78-1.17a1.994 1.994 0 0 1-.178-.33h.994a.75.75 0 0 0 .671-.415l.25-.5A.75.75 0 0 0 21.287 8H19.6l-.31-1.546a2.5 2.5 0 0 0-1.885-1.944C15.943 4.17 14.141 4 12 4c-2.142 0-3.943.17-5.405.51a2.5 2.5 0 0 0-1.886 1.944L4.399 8H2.714a.75.75 0 0 0-.67 1.085l.25.5a.75.75 0 0 0 .67.415h.995a1.999 1.999 0 0 1-.178.33L3 11.5c-.652.978-1 2.127-1 3.303zm15.961-4.799a4 4 0 0 0 .34.997H5.699c.157-.315.271-.65.34-.997l.632-3.157a.5.5 0 0 1 .377-.39C8.346 6.157 10 6 12 6c2 0 3.654.156 4.952.458a.5.5 0 0 1 .378.389l.631 3.157zM5.5 16a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM20 14.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" /></svg>
    }
    else {
        return <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="currentColor"><path fill-rule="evenodd" clip-rule="evenodd" d="M2 14.803v6.447c0 .414.336.75.75.75h1.614a.75.75 0 0 0 .74-.627L5.5 19h13l.395 2.373a.75.75 0 0 0 .74.627h1.615a.75.75 0 0 0 .75-.75v-6.447a5.954 5.954 0 0 0-1-3.303l-.78-1.17a1.994 1.994 0 0 1-.178-.33h.994a.75.75 0 0 0 .671-.415l.25-.5A.75.75 0 0 0 21.287 8H19.6l-.31-1.546a2.5 2.5 0 0 0-1.885-1.944C15.943 4.17 14.141 4 12 4c-2.142 0-3.943.17-5.405.51a2.5 2.5 0 0 0-1.886 1.944L4.399 8H2.714a.75.75 0 0 0-.67 1.085l.25.5a.75.75 0 0 0 .67.415h.995a1.999 1.999 0 0 1-.178.33L3 11.5c-.652.978-1 2.127-1 3.303zm15.961-4.799a4 4 0 0 0 .34.997H5.699c.157-.315.271-.65.34-.997l.632-3.157a.5.5 0 0 1 .377-.39C8.346 6.157 10 6 12 6c2 0 3.654.156 4.952.458a.5.5 0 0 1 .378.389l.631 3.157zM5.5 16a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM20 14.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" /></svg>
    }
}

const PopupClose = () => {
    let dom = document.getElementById("OpenDialog");
    if (dom) unmountComponentAtNode(dom);

};

function ShowCityModal(CityList: any, CityCount: any, BusinessName: any) {
    return ReactDom.render(
        <VGPopUp
            id={"ShowCityModal"}
            show={true}
            onHide={PopupClose}
            title={BusinessName + "'s Service Cities"}
            dialogClassName="block-edit"
            body={
                <div className="alertContent">
                    {CityList.map((city: any, index: number) => {
                        return <Fragment><span>{city}</span></Fragment>
                    })}
                </div>
            }
            footer={
                <Fragment>
                    <button className="w-button w-button-secondary" onClick={() => PopupClose()}>Ok</button>
                </Fragment >
            }
            isRemoveTabModalIndex={false}
            isShowFooter={true}
            size={"md"}
        />,
        document.getElementById("OpenDialog"));
}

function MapUrl(Data: any, userContext: any) {
    if (Data.isMobileServiceMandatory != 1) {
        let LoginUserDetails = utils.ReadCookie();

        if (LoginUserDetails == undefined || LoginUserDetails == null || LoginUserDetails == "") {
            window.open(Data.mapUrl, '_blank', 'noopener,noreferrer');
        }
        else {
            const Url = userContext.businessId + `/User?EncUid=` + LoginUserDetails.EncUid;
            AsyncAjaxGet(
                Url,
                userContext.accessToken,
                function onSuccess(data: any) {
                    if (data !== null && data.data !== null && data.data.data !== null) {
                        if (data.data.data !== undefined && data.data.data !== null && data.data.data !== '') {
                            let mapaData = data.data.data;
                            let street = (mapaData.street == undefined || mapaData.street == null) ? "" : mapaData.street.replace("#", " No ").replace("'", "\\'").replace("\n", "").replace("\r", "") + ", ";
                            let city = (mapaData.city == undefined || mapaData.city == null) ? "" : mapaData.city + ", ";
                            let stateName = (mapaData.stateName == undefined || mapaData.stateName == null) ? "" : mapaData.stateName + " ";
                            let zip = (mapaData.zip == undefined || mapaData.zip == null) ? "" : mapaData.zip;
                            let shopAddress = decodeURIComponent(`${street}${city}${stateName}${zip}`);
                            let url = Data.shopOwnerUrl + shopAddress + "&hl=en";
                            window.open(url, '_blank', 'noopener,noreferrer');
                        }
                    }
                },
                function onFailure() {
                    console.log("error");
                }
            );

        }
    }
    else {
        if (Data.cityCount > 0) {
            ShowCityModal(Data.cityDetails, Data.cityCount, Data.businessName);
        }
    }

}
