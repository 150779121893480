import React, { Fragment, useEffect, useRef, useState } from "react";
import ReactDom, { unmountComponentAtNode } from "react-dom";
import Form from "react-bootstrap/Form";
import VGPopUp from "../components/Controls/VGPopUp";
import "../components/Styles/SendEmailPopup.scss";
import Loader from "../components/Common/Loader";
import $ from "jquery";
import { AsyncAjaxPost } from "../components/Utilities/AsyncAjaxService";
import { addtoCartEnum, utils } from "../utils/Utils";

const VgSendEmailPopup = (props) => {
    const sendEmailModel = {
        PhotoGallary: 1,
        DailyDeal: 2
    }
    const [isLoading, setLoading] = useState(false);
    const [stateEffect, setShowEffect] = useState(false);
    const [valueState, setStateValues] = useState({
        ReceiverEmail: "",
        RequiredReceiverEmail: false,
        ReceiverName: "",
        RequiredReceiverName: false,
        Subject: "",
        RequiredSubject: false,
        YourName: "",
        RequiredYourName: false,
        YourEmail: "",
        RequiredYourEmail: false,
        Message: "",
        RequiredMessage: false,
        RequiredValidReceiverEmail: false,
        RequiredValidYourEmail: false,
    })
    const validationcheck = (event) => {
        if (event !== "" || event !== undefined || event !== null) {
            const regex = /^[A-Za-z0-9_\+-]+(\.[A-Za-z0-9_\+-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*\.([A-Za-z]{2,10})$/;
            if (!event || regex.test(event) === false) {
                return true;
            } else {
                return false;
            }
        }
    }
    const values = useRef({
        ReceiverEmail: null,
        ReceiverName: null,
        Subject: null,
        YourName: null,
        YourEmail: null,
        Message: null,
    });
    
    const OpenWriteMessagePopup = () => {
        let LoginDetails = utils.ReadCookie();
        if (typeof (LoginDetails.EncUid) === 'undefined' || LoginDetails.EncUid === "" || LoginDetails.EncUid === null) {
            return ReactDom.render(
                 <VGPopUp
                     title={"Alert"}
                     isShowFooter={true}
                     show={true}
                     isRemoveTabModalIndex={false}
                     onHide={PopupCloseConfirmationmail}
                     dialogClassName={"vgn-modal-alert vgn-modal-small"}
                     body={"Login is required to send message."}
                     footer={<Fragment><button class="v-button primary invisible-button" onClick={() => PopupCloseConfirmationmail()}>OK</button></Fragment>}
                 />, document.getElementById("OpenDialog2")
             )
         }        
        const bImage = props.Image;
        const popupBody = () => {
            return (
                <>
                    {isLoading && <Loader />}
                    <div className="senemailfrom">
                        <div className="businesscover">

                            <div className="cover-business-name">
                                {props.type ===  sendEmailModel.PhotoGallary ? 
                                <>
                                <div className="bnme text-center">
                                {props.photoName}
                                </div>
                                </> 
                                : 
                                <>
                                <div className="bnme">
                                {props.headername && props.headername}
                                </div>
                                <div className="ssbname">
                                {props.BusinessName}</div>
                                </> }
                            </div>

                            <div className="cover-business-image">
                                <img alt="" src={bImage} />
                                {props.offer && <div
                                className="deal-listing-discount badge-button-discount"
                                itemProp="offers"
                                itemScope=""
                                itemType="http://schema.org/Offer"
                            >
                                {props.offer}
                                <span
                                    id="ctl00_seoCurrencySymbol"
                                    itemProp="priceCurrency"
                                    style={{ display: "none" }}
                                >
                                    $
                                </span>
                            </div>}
                            </div>
                            {props.type ===  sendEmailModel.PhotoGallary && <><div className="photo-details">
                                <div className="b-detail-row">
                                    <div className="btd btdbold">{props.headername}</div>
                                    <div className="btd">{props.CurrencySymbol}{ props.price !== "" ? parseFloat(props.price).toFixed(2):""}</div>
                                </div>
                                <div className="b-detail-row">
                                    <div className="btd">{props.BusinessName}</div>
                                </div>
                                <div className="b-detail-row">
                                    <div className="btd">{(props.stateCode && props.city) ? `${props.city},${props.stateCode}` : props.city ? props.city : props.stateCode}</div>
                                </div>
                                <div className="b-detail-row">
                                    <div className="btd">{props.likeCounter > 0 && props.likeCounter + (props.likeCounter > 1 ? " Likes" : " Like")}</div>
                                </div>                                
                                </div>
                            </>} 
                            
                        </div>

                        <div className="sendemailform">
                            <Form className="form-content horizontal-vg-form">

                                <Form.Group className={`form-row`}>
                                    <Form.Label><span className="astrik">*</span>Receiver Email</Form.Label>
                                    <div className="from-control-wrapper" id="divReceiverEmail">
                                        <input
                                            type="text"
                                            ref={values.current.ReceiverEmail}
                                            
                                            className={valueState.RequiredReceiverEmail ? "form-control errorInput" : "form-control"}
                                            onChange={(e) => {
                                                valueState.ReceiverEmail = e.target.value;
                                                valueState.RequiredReceiverEmail = false;
                                                setStateValues(valueState)
                                                $(`#txtReceiverEmail`).hide()
                                                $(`#RequiredValidReceiverEmail`).hide();
                                                $(`#divReceiverEmail`).removeClass("form-row-invalid")
                                                if (e.target.value == "") {
                                                    valueState.RequiredValidReceiverEmail = false;
                                                }else if (validationcheck(e.target.value)) {
                                                    valueState.RequiredValidReceiverEmail = true;
                                                }else{
                                                    valueState.RequiredValidReceiverEmail = false;
                                                }
                                            }}
                                        />
                                        <div id={"txtReceiverEmail"} className="invalid-feedback" for="txtReceiverEmail">required.</div>
                                        <div id={"RequiredValidReceiverEmail"} className="invalid-feedback" type="invalid">Please enter a valid email address.</div>
                                    </div>
                                </Form.Group>
                                <Form.Group className={`form-row`}>
                                    <Form.Label><span className="astrik">*</span>Receiver Name</Form.Label>
                                    <div className="from-control-wrapper" id="divReceiverName">
                                        <input
                                            type="text"
                                            ref={values.current.ReceiverName}
                                            
                                            className={valueState.RequiredReceiverName ? "form-control errorInput" : "form-control"}
                                            onChange={(e) => {
                                                valueState.ReceiverName = e.target.value;
                                                valueState.RequiredReceiverName = false;
                                                $(`#divReceiverName`).removeClass("form-row-invalid")
                                                $(`#txtReceiverName`).hide()
                                                setStateValues(valueState)

                                            }}
                                        />
                                        <div id={"txtReceiverName"} className="invalid-feedback" for="txtReceiverName">required.</div>
                                    </div>
                                </Form.Group>
                                <Form.Group className={`form-row`}>
                                    <Form.Label><span className="astrik">*</span>Subject</Form.Label>
                                    <div className="from-control-wrapper" id="divSubject">
                                        <input
                                            type="text"
                                            ref={values.current.Subject}
                                            
                                            className={valueState.RequiredSubject ? "form-control errorInput" : "form-control"}
                                            onChange={(e) => {
                                                valueState.Subject = e.target.value;
                                                valueState.RequiredSubject = false;
                                                $(`#txtSubject`).hide()
                                                $(`#divSubject`).removeClass("form-row-invalid")
                                                setStateValues(valueState)

                                            }}
                                        />
                                        <div id={"txtSubject"} className="invalid-feedback" for="txtSubject">required.</div>
                                    </div>
                                </Form.Group>
                                <Form.Group className={`form-row`}>
                                    <Form.Label><span className="astrik">*</span>Your Name</Form.Label>
                                    <div className="from-control-wrapper" id="divYourName">
                                        <input
                                            type="text"
                                            ref={values.current.YourName}
                                            
                                            className={valueState.RequiredYourName ? "form-control errorInput" : "form-control"}
                                            onChange={(e) => {
                                                valueState.YourName = e.target.value;
                                                valueState.RequiredYourName = false;
                                                $(`#txtYourName`).hide()
                                                $(`#divYourName`).removeClass("form-row-invalid")
                                                setStateValues(valueState)

                                            }}
                                        />
                                        <div id={"txtYourName"} className="invalid-feedback" for="txtYourName">required.</div>
                                    </div>
                                </Form.Group>
                                <Form.Group className={`form-row`}>
                                    <Form.Label><span className="astrik">*</span>Your Email</Form.Label>
                                    <div className="from-control-wrapper" id="divYourEmail">
                                        <input
                                            type="text"
                                            ref={values.current.YourEmail}
                                            
                                            className={valueState.RequiredYourEmail ? "form-control errorInput" : "form-control"}
                                            onChange={(e) => {
                                                valueState.YourEmail = e.target.value;
                                                valueState.RequiredYourEmail = false;
                                                $(`#txtYourEmail`).hide()
                                                $(`#RequiredValidYourEmail`).hide();
                                                $(`#divYourEmail`).removeClass("form-row-invalid")
                                                setStateValues(valueState)
                                                if (e.target.value == "") {
                                                    valueState.RequiredValidYourEmail = false;
                                                }else if (validationcheck(e.target.value)) {
                                                    valueState.RequiredValidYourEmail = true;
                                                }else{
                                                    valueState.RequiredValidYourEmail = false;
                                                }

                                            }}
                                        />
                                        <div id={"txtYourEmail"} className="invalid-feedback" for="txtYourEmail">required.</div>
                                        <div id={"RequiredValidYourEmail"} className="invalid-feedback" type="invalid">Please enter a valid email address.</div>
                                    </div>
                                </Form.Group>


                                <Form.Group className={`form-row`}>
                                    <Form.Label><span className="astrik">*</span>Message</Form.Label>
                                    <div className="from-control-wrapper" id="divMessage">
                                        <textarea
                                            
                                            ref={values.current.Message}
                                            maxLength={100}
                                            cols={25}
                                            className={valueState.RequiredMessage ? "form-control errorInput" : "form-control"}
                                            onChange={(e) => {
                                                valueState.Message = e.target.value;
                                                valueState.RequiredMessage = false;
                                                $(`#txtMessage`).hide()
                                                $(`#divMessage`).removeClass("form-row-invalid")
                                                setStateValues(valueState)

                                            }}
                                            rows={15}
                                        ></textarea>
                                        <div id={"txtMessage"} className="invalid-feedback" for="txtMessage">required.</div>
                                    </div>
                                </Form.Group>
                            </Form>
                        </div>
                    </div>
                </>
            )
        }
        const ValidationRequired = (responce) => {
            var isvalid = true
            for (const key in valueState) {
                if (key.includes("RequiredValid")) {
                    if (valueState[key]) {
                        $(`#${key}`).show()
                        isvalid = false;
                    }else{
                        $(`#${key}`).hide()
                    }
                }
                if (!key.includes("Required")) {
                    if (valueState[key].trim() == "") {
                        valueState["Required" + key] = true;
                        $(`#txt` + key).show()
                        setStateValues(valueState)
                        isvalid = false;
                        $(`#div`+ key).addClass("form-row-invalid")
                    } else {
                        $(`#txt` + key).hide()
                        $(`#div`+ key).removeClass("form-row-invalid")
                    }
                }
            }
            setShowEffect(!stateEffect);
            return isvalid;
        }
        const getRequest = () => {
            var type = props.type;
            const req = {};
            req.Body = valueState.Message;
            req.ReceiverName = valueState.ReceiverName;
            req.SenderName = valueState.YourName;
            req.Mailto = valueState.ReceiverEmail;
            req.Mailfrom = valueState.YourEmail;
            req.Subject = valueState.Subject;
            req.Image = props.Image;
            req.StyleTitle = props.headername;
            req.SpAddress = (props.stateCode && props.city) ? `${props.city},${props.stateCode}` : props.city ? props.city : props.stateCode;
            req.BusinessUrl = window.location.origin ;
            req.VagaroLink = window.location.origin+ '/';
            req.BusinessName = props.BusinessName;
            req.businessPrice = "";
            req.UserPhoto = "";
            req.UserCDNUrl = "";
            req.UserName = "";
            req.EncBid = props.bid;
            req.EncUid = LoginDetails.EncUid;
            req.isShowBook = true;
            if (sendEmailModel.DailyDeal == type) {
                req.callFrom = "Deals";
                req.MailHeader = "Deals Sharing";
                req.service = "";
                req.promotionBeforePrice = props.promotionBeforePrice;
                req.promotionAfterPrice = props.promotionAfterPrice;

            } else if (sendEmailModel.PhotoGallary == type) {
                req.MailHeader = "Portfolio Sharing";
                req.callFrom = "Photos";
                req.promotionBeforePrice = "";
                req.promotionAfterPrice = "";
                req.service = props.headername;

            } else {
                req.promotionBeforePrice = "";
                req.promotionAfterPrice = "";
                req.callFrom = "";
                req.service = "";

            }
            return req
        }
        const SendEmail = async (refrance) => {
            setLoading(true)
            var isvalidate = ValidationRequired(true)
            if (isvalidate) {
                setTimeout(() => {
                    setLoading(false)
                }, 100);
                const Requestdata = getRequest();
                const url = props.bid + "/SendMail/sharesporstyle";
                await AsyncAjaxPost(
                    url,
                    Requestdata,
                    props.accessToken,
                    function onSuccess(data) {
                        try {
                            if (data != null && data.data != null && data.data.data != null) {
                                ReactDom.render(
                                    <VGPopUp
                                        title={"Alert"}
                                        isShowFooter={true}
                                        show={true}
                                        isRemoveTabModalIndex={false}
                                        onHide={PopupCloseConfirmationmail}
                                        dialogClassName={"vgn-popup vgn-modal-alert vgn-modal-small"}
                                        body={"Your email sent successfully"}
                                        footer={<button class="v-button primary invisible-button" onClick={() => PopupCloseConfirmationmail()}>OK</button>}
                                    />, document.getElementById("OpenDialog2")
                                )
                            }
                            setLoading(false);
                        } catch (error) {
                            setLoading(false);
                        }
                    },
                    function OnError() {
                        setLoading(false);
                    }
                )
                // unmountComponentAtNode(document.getElementById("OpenDialog1"));
            }
        };


        return ReactDom.render(
            <VGPopUp
                id={"vgSendMessage"}
                show={true}
                onHide={PopupCloseConfirmation}
                title={"Send Email"}
                dialogClassName="vgui-send-email"
                body={popupBody()}
                footer={
                    <Fragment>
                        <button className="v-button secondary invisible-button" onClick={PopupCloseConfirmation}>Close</button>
                        <button className="v-button primary invisible-button" onClick={() => SendEmail()}> Send</button>
                    </Fragment >
                }
                isRemoveTabModalIndex={true}
                otherRemoveTabClass={"pswp--open"}
                isShowFooter={true}
                size={"modal-sm"}
                backdrop={true}
            />,
            document.getElementById("OpenDialog1"));
    }
    const PopupCloseConfirmation = () => {
        unmountComponentAtNode(document.getElementById("OpenDialog1"));
    };
    const PopupCloseConfirmationmail = () => {
        unmountComponentAtNode(document.getElementById("OpenDialog2"));
        unmountComponentAtNode(document.getElementById("OpenDialog1"));
    };


    return <>
        {props.ForDailyDeal ? <div onClick={() => OpenWriteMessagePopup()}> {props.ForDailyDeal}</div> :
            <a href={false} className="w-button no-a" onClick={() => OpenWriteMessagePopup()}>Send to an email</a>}
    </>;
}
export default VgSendEmailPopup;