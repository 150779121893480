import jQuery from "jquery";
import $ from "jquery";
import ReactDOM, { unmountComponentAtNode } from "react-dom";
import RenderBusinessCart from "../BusinessControls/RenderBusinessCart";
import React from "react";
import RenderMyAccount from "../BusinessControls/RenderMyAccount";


export function UpdateCartCount(newAmount: any) {


    if ($('#cartdisplay').length === 1) {

        if (newAmount == "0") {
            $("#cartdisplay").addClass('hidden');

        }
        else {
            $("#cartdisplay").removeClass('hidden');

        }

        $("#cartdisplay").html(newAmount);

    }

    const node = document.getElementById("divcart");
    if (node) {
        unmountComponentAtNode(node);
        ReactDOM.render(<RenderBusinessCart data={newAmount} userContext={""} />, node);
    }


}

export function RefreshAccountLogin(CurbusinessID: string, CurcountryID: number) {
    const node = document.getElementById("account-holder");
    console.log("account node", node);
    if (node) {
        unmountComponentAtNode(node);
        ReactDOM.render(<RenderMyAccount layout="1" userContext={""} businessID={CurbusinessID} countryID={CurcountryID} />, node);
    }
}