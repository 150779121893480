export default function GetProductInfoFromURL(url:string,multiLocationData:any,inBusiness?:string) {
    if(!url||url.length<0){
        url = window.location.pathname;
    }

    const result = {productId:"",businessId:inBusiness??"",isBundle: window.location.pathname.includes("/product-details/bundle/")};

    let split = url.split("/");

    if(split.length>0&&split[split.length-1].trim() == ""){ //remove empty
        split.pop();
    }

    if(split.length>0&&split[split.length-1].includes("preview")){ // remove appended preview
        split.pop();
    }

    if(split.length<3)
        return result;


    result.productId = split.pop()??"";
    result.businessId = split.pop()??inBusiness??"";
    
    return result;

   
}