import heic2any from "heic2any";
import ImageCompressor from "compressorjs";

function getJPEGFileFromHEICWeb(file, fileName, onLoadCallback) {
    var newFile ;
    heic2any({
        blob: file,
        toType: "image/jpeg",
    })
    .then(function (resultBlob) {
        var MaxLength = 1048576;// bytes
        // if converted image size is greater then 1 MB then we will compress that image
        if (resultBlob.size > MaxLength) {
            new ImageCompressor(resultBlob, {
                quality: 0.6,
                mimeType: 'image/jpeg',
                convertSize: 5000000,
                success: function (resultBlob) {
                     newFile = new File([resultBlob], fileName, {
                        type: "image/jpeg",
                    });
                    onLoadCallback(newFile);
                },
                error: function (e) {
                    console.log(e.message);
                },
            });
        }
        else {
             newFile = new File([resultBlob], fileName, {
                type: "image/jpeg",
            });
            onLoadCallback(newFile);
        }
    })
    .catch(function (x) {
        console.log(x.message);
    });
}
export default getJPEGFileFromHEICWeb