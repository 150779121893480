import React, { Fragment, useEffect, useRef } from "react";
import "../../Style/groupSingleSelect.css"

const GroupSingleSelect = (props) => {
    
    const dropdownData = useRef(props.options || [])
    const firstTime = useRef(true);
    const CheckIncludes = (child,filterText)=> {
        var allAvailable = true
        child = child.text.replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase();
        filterText = filterText.replace(/[^a-zA-Z0-9 ]/g, '').trim();
        if (filterText.split(" ").length > 1) {
            filterText.split(" ").map(val => !child.includes(val) && (allAvailable = false))
            return allAvailable
        }else{
            return child.includes(filterText)
        }     
    }

    const ChangesApply = (search) => {      
        const inputField = document.querySelector('.input-field');
        const dropdownContent = document.querySelector('.Service-dropdown-content');
        const filterText = search ? inputField.value.toLowerCase() : "";
            dropdownContent.innerHTML = ''; 
            dropdownData.current.forEach(category => {
                const filteredChildren = category.children.filter(child => CheckIncludes(child,filterText) );
                if (filteredChildren.length > 0) {
                    const categoryElement = document.createElement('div');
                    const categoryDIV = document.createElement('div');
                    const categoryUL = document.createElement('ul');
                    categoryDIV.innerText = category.text;
                    categoryElement.className = "active";
                    categoryDIV.addEventListener('click', function (e) { 
                        if (e.target.parentElement.className.includes("active")) {
                            e.target.parentElement.className = ""
                        }else{
                            e.target.parentElement.className = "active"
                        }
                    })
                    dropdownContent.appendChild(categoryElement);
                    categoryElement.appendChild(categoryDIV);
                    categoryElement.appendChild(categoryUL);
                    filteredChildren.forEach(child => {
                        const itemElement = document.createElement('li');
                        itemElement.textContent = child.text;
                        // Highlight the matched text
                        if (filterText !== '' && child.text.toLowerCase().includes(filterText)) {
                            var escapedFilterText = filterText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
                            const highlightedText = child.text.replace(new RegExp(escapedFilterText, 'gi'), match => `<span class="highlight">${match}</span>`);
                            itemElement.innerHTML = highlightedText;
                        }

                        itemElement.classList.add('dropdown-item');
                        categoryUL.appendChild(itemElement);
                        itemElement.addEventListener('click', function () {
                            inputField.value = child.text;
                            props.onChange(child.id);
                            dropdownContent.style.display = 'none'; // Close the dropdown
                        });
                    });
                }
            });
            if (filterText === '') {
                dropdownContent.style.display = 'block'; 
            } else {
                dropdownContent.style.display = 'block'; 
            }

    }
    

    useEffect(() => {
        dropdownData.current = props.options || [];
        const inputField = document.querySelector('.input-field');
        const dropdownContent = document.querySelector('.Service-dropdown-content');
        inputField.addEventListener('input', function () {
            ChangesApply(true);
        });
        inputField.addEventListener('click', function () {
            ChangesApply(false);
        });
        document.addEventListener('click', function (event) {
            if (!dropdownContent.contains(event.target) && event.target !== inputField) {
                dropdownContent.style.display = 'none';
            }
        });
        if(props.ControlID==="ddlserviceSearch" && props.options.length == 1 && props.options[0].children.length == 1 && firstTime.current){
            firstTime.current = false;        
            var valuesToSelect = props.options[0].children[0];
            inputField.value = valuesToSelect.text;
            props.onChange(valuesToSelect.id);
            dropdownContent.style.display = 'none';
        }
    }, [props.placeholder,props.options]);

    return (
        <Fragment>
           <div className="vg-wb-select">
           <input id={props.ControlID} type="text" className="input-field form-control" placeholder={props.placeholder} onBlur={(e) => { e.target.value === "" ? props.onChange(""): e.target.value = props.value} } tabindex={props.inputTabindex} autoComplete="off" spellcheck="false"/>
                <div className="Service-dropdown-content dropdown-content">
                </div>
            </div>
        </Fragment>
    )
};

export default GroupSingleSelect;