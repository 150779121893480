export const ServicesPlaceHolder = 
  

      [{
        "isStenciled": true,
        "businessID": 300778,
        "vplStatus": 2,
        "minimumAmount": 35.00,
        "maximumAmount": 1500.00,
        "currencySymbol": "£",
        "serviceCategoryList": [
          {
            "categoryIds": "nxj5Yt32Bhr9FevGiwL8RW",
            "serviceCategoryName": "Hair Touch-up and Other",
            "serviceList": [
              {
                "serviceId": 5555,
                "serviceTitle": "                ",
                "serviceDesc": "                ",
                "serviceImageUrl": "",
                "price": 50.00,
                "promotionTitle": "",
                "promotionDetail": "",
                "promotionDiscount": 0.0,
                "priceText": "",
                "earnedPointsText": "",
                "pricePointText": "",
                "bookText": "Book Now",
                "vplMessage": "",
                "isOutCallService": false,
                "isZoomIntegrated": false,
                "isShowAddCartButton": false,
                "isBundle": false,
                "isAvailableAddOn": false,
                "isShowPriceAsStartingPoint": false
              },
                  {
                    "serviceId": 5555,
                    "serviceTitle": "                ",
                    "serviceDesc": "                ",
                    "serviceImageUrl": "",
                    "price": 50.00,
                    "promotionTitle": "",
                    "promotionDetail": "",
                    "promotionDiscount": 0.0,
                    "priceText": "",
                    "earnedPointsText": "",
                    "pricePointText": "",
                    "bookText": "Book Now",
                    "vplMessage": "",
                    "isOutCallService": false,
                    "isZoomIntegrated": false,
                    "isShowAddCartButton": false,
                    "isBundle": false,
                    "isAvailableAddOn": false,
                    "isShowPriceAsStartingPoint": false
                  },
                  {
                    "serviceId": 5555,
                    "serviceTitle": "                ",
                    "serviceDesc": "                ",
                    "serviceImageUrl": "",
                    "price": 50.00,
                    "promotionTitle": "",
                    "promotionDetail": "",
                    "promotionDiscount": 0.0,
                    "priceText": "",
                    "earnedPointsText": "",
                    "pricePointText": "",
                    "bookText": "Book Now",
                    "vplMessage": "",
                    "isOutCallService": false,
                    "isZoomIntegrated": false,
                    "isShowAddCartButton": false,
                    "isBundle": false,
                    "isAvailableAddOn": false,
                    "isShowPriceAsStartingPoint": false
                  },
              
                  {
                    "serviceId": 5555,
                    "serviceTitle": "                ",
                    "serviceDesc": "                ",
                    "serviceImageUrl": "",
                    "price": 50.00,
                    "promotionTitle": "",
                    "promotionDetail": "",
                    "promotionDiscount": 0.0,
                    "priceText": "",
                    "earnedPointsText": "",
                    "pricePointText": "",
                    "bookText": "Book Now",
                    "vplMessage": "",
                    "isOutCallService": false,
                    "isZoomIntegrated": false,
                    "isShowAddCartButton": false,
                    "isBundle": false,
                    "isAvailableAddOn": false,
                    "isShowPriceAsStartingPoint": false
                  },
     
             
            ]
          },
          {
            "categoryIds": "                ",
            "serviceCategoryName": "*******************",
            "serviceList": []
          },
          {
            "categoryIds": "                ",
            "serviceCategoryName": "*******************",
            "serviceList": []
          },

        ]
      }]
export const PackagesPlaceHolder = [
    {
        "isStenciled": true,
      "vplStatus": 2,
      "minimumAmount": 35.00,
      "maximumAmount": 1500.00,
      "currencySymbol": "£",
      "packageList": [
        {
          "packageId": 435,
          "packageIds": "                ",
          "packageName": "                ",
          "description": "********* ****************** ************* *************** ************ ****** ******* ********* ****************** ************* *************** ************ ****** *******",
          "promotionDetail": "",
          "promotionTitle": "",
          "promotionValue": "0.00",
          "initialDiscount": 0.00,
          "billingCycleString": "",
          "isRecurring": true,
          "vplMessage": ""
        },
        {
            "packageId": 435,
            "packageIds": "                ",
            "packageName": "                ",
            "description":  "********* ****************** ************* *************** ************ ****** ******* ********* ****************** ************* *************** ************ ****** *******",
            "charge": "£300/Month",
            "promotionDetail": "",
            "promotionTitle": "",
            "promotionValue": "0.00",
            "initialDiscount": 0.00,
            "billingCycleString": "",
            "isRecurring": true,
            "vplMessage": ""
          },
          {
            "packageId": 435,
            "packageIds": "                ",
            "packageName": "                ",
            "description":  "********* ****************** ************* *************** ************ ****** ******* ********* ****************** ************* *************** ************ ****** *******",
            "promotionDetail": "",
            "promotionTitle": "",
            "promotionValue": "0.00",
            "initialDiscount": 0.00,
            "billingCycleString": "",
            "isRecurring": true,
            "vplMessage": ""
          },
          {
              "packageId": 435,
              "packageIds": "                ",
              "packageName": "                ",
              "description":  "********* ****************** ************* *************** ************ ****** ******* ********* ****************** ************* *************** ************ ****** *******",
              "charge": "£300/Month",
              "promotionDetail": "",
              "promotionTitle": "",
              "promotionValue": "0.00",
              "initialDiscount": 0.00,
              "billingCycleString": "",
              "isRecurring": true,
              "vplMessage": ""
            },


      ]
    }
  ]

export const GiftCardPlaceHolder=[
  {
    "isStenciled": true,
    "giftCertificateCatIds": "xxxxxxxxxxxxxxxxx",
    "giftCertificateCatName": "Certifcate Category",
    "height": 400,
    "photo": "#",
    "width": 700
  },
  {
    "isStenciled": true,
    "giftCertificateCatIds": "xxxxxxxxxxxxxxxxx",
    "giftCertificateCatName": "Certifcate Category",
    "height": 400,
    "photo": "#",
    "width": 700
  },
  {
    "isStenciled": true,
    "giftCertificateCatIds": "xxxxxxxxxxxxxxxxx",
    "giftCertificateCatName": "Certifcate Category",
    "height": 400,
    "photo": "#",
    "width": 700
  },
  {
    "isStenciled": true,
    "giftCertificateCatIds": "xxxxxxxxxxxxxxxxx",
    "giftCertificateCatName": "Certifcate Category",
    "height": 400,
    "photo": "#",
    "width": 700
  },
  {
    "isStenciled": true,
    "giftCertificateCatIds": "xxxxxxxxxxxxxxxxx",
    "giftCertificateCatName": "Certifcate Category",
    "height": 400,
    "photo": "#",
    "width": 700
  },
  {
    "isStenciled": true,
    "giftCertificateCatIds": "xxxxxxxxxxxxxxxxx",
    "giftCertificateCatName": "Certifcate Category",
    "height": 400,
    "photo": "#",
    "width": 700
  },
  {
    "isStenciled": true,
    "giftCertificateCatIds": "xxxxxxxxxxxxxxxxx",
    "giftCertificateCatName": "Certifcate Category",
    "height": 400,
    "photo": "#",
    "width": 700
  },
  {
    "isStenciled": true,
    "giftCertificateCatIds": "xxxxxxxxxxxxxxxxx",
    "giftCertificateCatName": "Certifcate Category",
    "height": 400,
    "photo": "#",
    "width": 700
  },
  {
    "isStenciled": true,
    "giftCertificateCatIds": "xxxxxxxxxxxxxxxxx",
    "giftCertificateCatName": "Certifcate Category",
    "height": 400,
    "photo": "#",
    "width": 700
  },
  
]

export const MembershipPlaceHolder = [
    {
        "isStenciled": true,
      "vplStatus": 2,
      "minimumAmount": 35.00,
      "maximumAmount": 1500.00,
      "currencySymbol": "£",
      "membershipList": [
        {
          "membershipId": 706,
          "membershipIds": "                        ",
          "membershipName": "                       ",
          "description": "                                                                                                              ",
          "promotionDetail": "",
          "promotionTitle": "",
          "promotionValue": "          ",
          "initialDiscount": 50.00,
          "billingCycleString": "Discount applied to first 2 payments.",
          "isRecurring": true,
          "vplMessage": ""
        },
        {
          "membershipId": 709,
          "membershipIds": "                        ",
          "membershipName": "                       ",
          "description": "                                                                                                              ",
          "promotionDetail": "",
          "promotionTitle": "",
          "promotionValue": "          ",
          "initialDiscount": 50.00,
          "billingCycleString": "Discount applied to first 2 payments.",
          "isRecurring": true,
          "vplMessage": ""
        },
        {
          "membershipId": 711,
          "membershipIds": "                        ",
          "membershipName": "                       ",
          "description": "                                                                                                              ",
          "promotionDetail": "",
          "promotionTitle": "",
          "promotionValue": "          ",
          "initialDiscount": 50.00,
          "billingCycleString": "Discount applied to first 2 payments.",
          "isRecurring": true,
          "vplMessage": ""
        },
        {
          "membershipId": 712,
          "membershipIds": "                        ",
          "membershipName": "                       ",
          "description": "                                                                                                              ",
          "promotionDetail": "",
          "promotionTitle": "",
          "promotionValue": "          ",
          "initialDiscount": 50.00,
          "billingCycleString": "Discount applied to first 2 payments.",
          "isRecurring": true,
          "vplMessage": ""
        },
      ]
    }
  ]

  export const ReviewPlaceHolder = {

    "isStenciled": true,
    "pageNumber": 1,
    "pageSize": 20,
    "firstPage": "",
    "lastPage": "",
    "totalPages": 1,
    "totalRecords": 5,
    "records": [
      {
        "reviewIds": "woHqXvquyIBueaPc6zfbFS",
        "reviewer": "       ",
        "averageRank": 4.0,
        "serviceProviderName": "                ",
        "reviewerIntials": "  ",
        "reviewerPhoto": "",
        "venueReview": "",
        "serviceProviderReview": "                  ",
        "venueRank": 4.0,
        "punctualityRank": 4.0,
        "valueRank": 4.0,
        "serviceProviderRank": 4.0,
        "publishDate": "                       ",
        "isUserHaveAppointment": true
      },
      {
        "reviewIds": "woHqXvquyIBueaPc6zfbFS",
        "reviewer": "       ",
        "averageRank": 4.0,
        "serviceProviderName": "                ",
        "reviewerIntials": "  ",
        "reviewerPhoto": "",
        "venueReview": "",
        "serviceProviderReview": "                  ",
        "venueRank": 4.0,
        "punctualityRank": 4.0,
        "valueRank": 4.0,
        "serviceProviderRank": 4.0,
        "publishDate": "                       ",
        "isUserHaveAppointment": true
      },
      {
        "reviewIds": "woHqXvquyIBueaPc6zfbFS",
        "reviewer": "       ",
        "averageRank": 4.0,
        "serviceProviderName": "                ",
        "reviewerIntials": "  ",
        "reviewerPhoto": "",
        "venueReview": "",
        "serviceProviderReview": "                  ",
        "venueRank": 4.0,
        "punctualityRank": 4.0,
        "valueRank": 4.0,
        "serviceProviderRank": 4.0,
        "publishDate": "                       ",
        "isUserHaveAppointment": true
      },
 
      {
        "reviewIds": "                           ",
        "reviewer": "            ",
        "averageRank": 4.0,
        "serviceProviderName": "            ",
        "reviewerIntials": "  ",
        "reviewerPhoto": "",
        "venueReview": "                     ",
        "serviceProviderReview": "                ",
        "venueRank": 4.0,
        "punctualityRank": 4.0,
        "valueRank": 4.0,
        "serviceProviderRank": 4.0,
        "publishDate": "2023-08-21T06:08:38.347Z",
        "isUserHaveAppointment": true
      },

      {
        "reviewIds": "zbMeu5aIOMDSSqZG49iqdG",
        "reviewer": "          ",
        "averageRank": 4.0,
        "serviceProviderName": "          ",
        "reviewerIntials": "  ",
        "reviewerPhoto": "",
        "venueReview": "",
        "serviceProviderReview": "             ",
        "venueRank": 4.0,
        "punctualityRank": 4.0,
        "valueRank": 4.0,
        "serviceProviderRank": 4.0,
        "publishDate": "2023-08-18T11:20:34.470Z",
        "isUserHaveAppointment": true
      },

    ]
  }
  
export const dailyDealsPlaceholder = {
        "isStenciled": true,
        "salonTime": "Sep 18, 2023 05:48 AM",
        "dailyDealList": [
          {
            "dealDiscountAmtType": 2,
            "promotionTitle": "Daily deal feature 10%",
            "promotionDesc": "Daily deal feature 10% Daily deal feature 10%Daily deal feature 10%Daily deal feature 10%Daily deal feature 10%Daily deal feature 10%Daily deal feature 10%Daily deal feature 10%Daily deal feature 10%Daily deal feature 10%Daily deal feature 10%Daily deal feature 10%Daily deal feature 10%Daily deal feature 10%Daily deal feature 10%Daily deal feature 10%Daily deal feature 10%Daily deal feature 10%Daily deal feature 10%Daily deal feature 10%Daily deal feature 10%",
            "startDate": "2023-08-07T00:00:55",
            "sDate": "Aug 07, 2023 12:00 AM",
            "eDate": "",
            "photoUrl": "#",
            "promotionID": 9016672,
            "publishDate": "2023-08-07T07:25:03.000Z",
            "discountHtml": "UP TO 10% OFF",
            "dailyDealURL": "#",
            "promoLimitation": "Daily deal feature 10%Daily deal feature 10%Daily deal feature 10%",
            "salonTime": "0001-01-01T00:00:00",
            "custFilter": "1,2",
            "totalUsage": 0,
            "totalUsed": 0
          },
          {
            "dealDiscountAmtType": 2,
            "promotionTitle": "50%off",
            "promotionDesc": "Daily deal feature 10% Daily deal feature 10%Daily deal feature 10%Daily deal feature 10%Daily deal feature 10%Daily deal feature 10%Daily deal feature 10%Daily deal feature 10%Daily deal feature 10%Daily deal feature 10%Daily deal feature 10%Daily deal feature 10%Daily deal feature 10%Daily deal feature 10%Daily deal feature 10%Daily deal feature 10%Daily deal feature 10%Daily deal feature 10%Daily deal feature 10%Daily deal feature 10%Daily deal feature 10%",
            "startDate": "2023-08-17T23:00:22",
            "sDate": "Aug 17, 2023 11:00 PM",
            "eDate": "",
            "photoUrl": "#",
            "promotionID": 9016680,
            "publishDate": "2023-08-18T06:30:43.000Z",
            "discountHtml": "UP TO 50% OFF",
            "dailyDealURL": "#",
            "promoLimitation": "",
            "salonTime": "0001-01-01T00:00:00",
            "custFilter": "1,2",
            "totalUsage": 0,
            "totalUsed": 0
          }
        ]
    }

export const FacilityPlaceholder = 
  {
    "isStenciled": true,
    "Phone": "               ",
    "Accepts Walk-in": "   ",
    "Payment Methods Accepted": "                                 ",
    "Parking": "             ",
    "Snack bar": " Available",
    "Handicap Access": " Available",
    "Spoken languages": "                   ",
    "Kid Friendly": "Yes",
    "Special Instructions": "quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. ",
    "Appointment Cancellation Policy": " ",
    "Class Cancellation Policy": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. "
  }


export const ProductPlaceHolder = {
  "isStenciled":true,
  "pageNumber": 1,
  "pageSize": 50,
  "firstPage": "",
  "lastPage": "",
  "totalPages": 1,
  "totalRecords": 4,
  "records": [
    {
      "inventoryIds": "",
      "name": "                         ",
      "brandName": "                    ",
      "sellingPrice": 85.00,
      "totalQty": 100,
      "imageUrl": "",
      "height": 293,
      "width": 196,
      "totalPage": 4,
      "tax": 0.0,
      "productPriceWithBrand": "                     / £85.00"
    },
    {
      "inventoryIds": "",
      "name": "          ",
      "brandName": "  ",
      "sellingPrice": 150.00,
      "totalQty": 100,
      "imageUrl": "",
      "height": 293,
      "width": 196,
      "totalPage": 4,
      "tax": 0.0,
      "productPriceWithBrand": "302 / £150.00"
    },
    {
      "inventoryIds": "",
      "name": "                 ",
      "brandName": "        ",
      "sellingPrice": 69.00,
      "totalQty": 1000,
      "imageUrl": "",
      "height": 293,
      "width": 196,
      "totalPage": 4,
      "tax": 0.0,
      "productPriceWithBrand": "365 / £69.00"
    },

  ]
}

export const ProductDetailsPlaceHodler ={
  isStenciled:true,
  brandName: "                  ",
  currencySymbol: "$",
  description: "                                                     ",
  imagePath: "",
  imageUrl1: "",
  imageUrl2: "",
  imageUrl3: "",
  imageUrl4: "",
  inventoryID: 105738,
  isTrackInventory: true,
  maximumAmount: 1500,
  minimumAmount: 35,
  name: "           ",
  productTypeName: "            ",
sellingPrice: 35,
  totalQty: 999,
  vplMessage: "                   ",
  vplStatus: 2
}

export const HoursPlaceHolder = 
    [
        {
          "weekDay": "Sunday",
          "dayID": 1,
          "openTime": "00:00 AM",
          "closeTime": "00:00 PM",
          "isOpen": false,
          "isStenciled": true,
        },
        {
          "weekDay": "Monday",
          "dayID": 2,
          "openTime": "00:00 AM",
          "closeTime": "00:00 PM",
          "isOpen": true
        },
        {
          "weekDay": "Tuesday",
          "dayID": 3,
          "openTime": "00:00 AM",
          "closeTime": "00:00 PM",
          "isOpen": false
        },
        {
          "weekDay": "Wednesday",
          "dayID": 4,
          "openTime": "00:00 AM",
          "closeTime": "00:00 PM",
          "isOpen": true
        },
        {
          "weekDay": "Thursday",
          "dayID": 5,
          "openTime": "00:00 AM",
          "closeTime": "00:00 PM",
          "isOpen": true
        },
        {
          "weekDay": "Friday",
          "dayID": 6,
          "openTime": "00:00 AM",
          "closeTime": "00:00 PM",
          "isOpen": true
        },
        {
          "weekDay": "Saturday",
          "dayID": 7,
          "openTime": "00:00 AM",
          "closeTime": "00:00 PM",
          "isOpen": true
        }
      ]

export const ReviewSummaryPlaceholder = {
  "isStenciled": true,
  "rank": {
    "overall": 3.2,
    "punctuality": 3.5,
    "value": 3.2,
    "service": 2.4,
    "general": 3.15
  },
  "reviewPercentage": [
    {
      "review": 5,
      "reviewAvg": 3,
      "total": 15,
      "percent": 60.00,
      "venueRankAverage": 3.15
    },
    {
      "review": 4,
      "reviewAvg": 4,
      "total": 15,
      "percent": 80.00,
      "venueRankAverage": 3.15
    },
    {
      "review": 3,
      "reviewAvg": 5,
      "total": 15,
      "percent": 100.00,
      "venueRankAverage": 3.15
    },
    {
      "review": 2,
      "reviewAvg": 1,
      "total": 15,
      "percent": 20.00,
      "venueRankAverage": 3.15
    },
    {
      "review": 1,
      "reviewAvg": 2,
      "total": 15,
      "percent": 40.00,
      "venueRankAverage": 3.15
    }
  ]
}

export const StaffPlaceholder = [
  {
    "isStenciled":true,
    "serviceProviderID": 10000,
    "firstName": "Salon",
    "lastName": "Salon",
    "cell": "(555) 555-5555",
    "businessSummary": "",
    "reviewRank": 5,
    "reviewCount": 5,
    "spintials": "SS",
    "photoURL": "",
    "bookText": "Book",
    "isServiceTab": true,
    "isClassTab": true,
    "emailId": "fakeemail@placeholder.com",
    "isShowMessage": true,
    "isAllowBook": true
  },
  {
    "isStenciled":true,
    "serviceProviderID": 10000,
    "firstName": "Salon",
    "lastName": "Salon",
    "cell": "(555) 555-5555",
    "businessSummary": "",
    "reviewRank": 5,
    "reviewCount": 5,
    "spintials": "SS",
    "photoURL": "",
    "bookText": "Book",
    "isServiceTab": true,
    "isClassTab": true,
    "emailId": "fakeemail@placeholder.com",
    "isShowMessage": true,
    "isAllowBook": true
  },
  {
    "isStenciled":true,
    "serviceProviderID": 10000,
    "firstName": "Salon",
    "lastName": "Salon",
    "cell": "(555) 555-5555",
    "businessSummary": "",
    "reviewRank": 5,
    "reviewCount": 5,
    "spintials": "SS",
    "photoURL": "",
    "bookText": "Book",
    "isServiceTab": true,
    "isClassTab": true,
    "emailId": "fakeemail@placeholder.com",
    "isShowMessage": true,
    "isAllowBook": true
  },
]

export const VenueGalleryPlaceholder = {
"isStenciled":true,
  "pageNumber": 1,
  "pageSize": 50,
  "firstPage": "",
  "lastPage": "",
  "totalPages": 1,
  "totalRecords": 6,
  "records": {
    "merchantName": "Demo Business (website builder)",
    "merchantEmail": "asdfasdfasdfasdf",
    "city": "Pleasanton",
    "stateName": "California",
    "stateCode": "CA",
    "vagaroURL": "wb",
    "photoDetail": [
      {
        "styleID": 0,
        "photoName": "",
        "serviceTitle": "",
        "photoUrl": "#",
        "photoDescription": "",
        "price": 0.00,
        "likeCounter": 0,
        "isLikeByUser": 0,
        "serviceID": 0,
        "serviceProviderID": 0
      },
      {
        "styleID": 0,
        "photoName": "",
        "serviceTitle": "",
        "photoUrl": "#",
        "photoDescription": "",
        "price": 0.00,
        "likeCounter": 0,
        "isLikeByUser": 0,
        "serviceID": 0,
        "serviceProviderID": 0
      },
      {
        "styleID": 0,
        "photoName": "",
        "serviceTitle": "",
        "photoUrl": "#",
        "photoDescription": "",
        "price": 0.00,
        "likeCounter": 0,
        "isLikeByUser": 0,
        "serviceID": 0,
        "serviceProviderID": 0
      },
      {
        "styleID": 0,
        "photoName": "",
        "serviceTitle": "",
        "photoUrl": "#",
        "photoDescription": "",
        "price": 0.00,
        "likeCounter": 0,
        "isLikeByUser": 0,
        "serviceID": 0,
        "serviceProviderID": 0
      },
      {
        "styleID": 0,
        "photoName": "",
        "serviceTitle": "",
        "photoUrl": "#",
        "photoDescription": "",
        "price": 0.00,
        "likeCounter": 0,
        "isLikeByUser": 0,
        "serviceID": 0,
        "serviceProviderID": 0
      },
      {
        "styleID": 0,
        "photoName": "",
        "serviceTitle": "",
        "photoUrl": "#",
        "photoDescription": "",
        "price": 0.00,
        "likeCounter": 0,
        "isLikeByUser": 0,
        "serviceID": 0,
        "serviceProviderID": 0
      },
  

 
    ]
  }
}