import React, { Fragment, useEffect, useState } from "react";
import OopsBanner from "../OopsBanner";
import VgWriteReview from "../../VagaroComponent/VgWriteReview";
import Reviews from "../../VagaroComponent/VgReviews";
import ReactDOM from "react-dom";
import { AsyncAjaxGet, AsyncAjaxPost } from "../Utilities/AsyncAjaxService";
import { UserContext } from "../Definitions/UserContext";
import { addtoCartEnum, utils } from "../../utils/Utils";
import Loader from "../Common/Loader";

export default function RenderBusinessReviewPercentage({ data, layout, userContext }: { data: any, layout: string, userContext: UserContext }) {


    const bData = data;
    const isStenciled = data?.isStenciled === true;
    if (!bData) return <OopsBanner />
    return (
        <RenderReviewPercentage data={bData} layout={layout} rank={bData.rank} reviewPercentage={bData.reviewPercentage} userContext={userContext} isStenciled={isStenciled} />
    );
}


//Entry point to render individual review based no layout
function RenderReviewPercentage({ data, layout, rank, reviewPercentage, userContext,isStenciled }: { data: any, layout: string, rank: any, reviewPercentage: any, userContext: UserContext,isStenciled:boolean }) {
    switch (layout) {
        default: return <RenderReviewPercentageDefault layout ={layout} data={data} rank={rank} reviewPercentage={reviewPercentage} userContext={userContext} isStenciled={isStenciled} />
    }
}

function RenderReviewPercentageDefault({ data, rank,layout, reviewPercentage, userContext,isStenciled }: {layout:string, data: any, rank: any, reviewPercentage: any, userContext: UserContext,isStenciled:boolean }) {
    var IsEditor = (window as any).isEditor !== undefined ? (window as any).isEditor : false;
    return (
        <Fragment>
            <div className={"vgui-start-rating-container "+(isStenciled?"stenciled":"")}>
                <div className="vgui-start-rating-row" style={{cursor:"pointer"}} onClick={(e)=> !IsEditor && (window as any).Reviews(data, userContext,e)}>
                    <ReviewGeneral reviewPercentage={reviewPercentage} />
                    <ReviewPercentage reviewPercentage={reviewPercentage} />
                    <ReviewPercentageSecond rank={rank} />
                  
                </div>
                {layout=="1"&&<ReviewPercentageBookmark data={data} userContext={userContext} />}
            </div>
        </Fragment>
    )
}

function ReviewGeneral({ reviewPercentage }: { reviewPercentage: any }) {
    return (
        <div className="star-rating-col" style={{flexGrow:"0",padding:"0 40px"}}>
            <div className="overall-rating">
                <h2 className="big-font">{reviewPercentage[0].venueRankAverage.toFixed(1)}</h2>
                {Math.round(reviewPercentage[0].venueRankAverage) == 0 &&
                    <div className="vgstars">
                        <span className="star"></span>
                        <span className="star"></span>
                        <span className="star"></span>
                        <span className="star"></span>
                        <span className="star"></span>
                    </div>
                }
                {Math.round(reviewPercentage[0].venueRankAverage) == 1 &&
                    <div className="vgstars">
                        <span className="star yellow v-filled-star"></span>
                        <span className="star"></span>
                        <span className="star"></span>
                        <span className="star"></span>
                        <span className="star"></span>
                    </div>
                }
                {Math.round(reviewPercentage[0].venueRankAverage) == 2 &&
                    <div className="vgstars">
                        <span className="star yellow v-filled-star"></span>
                        <span className="star yellow v-filled-star"></span>
                        <span className="star"></span>
                        <span className="star"></span>
                        <span className="star"></span>
                    </div>
                }
                {Math.round(reviewPercentage[0].venueRankAverage) == 3 &&
                    <div className="vgstars">
                        <span className="star yellow v-filled-star"></span>
                        <span className="star yellow v-filled-star"></span>
                        <span className="star yellow v-filled-star"></span>
                        <span className="star"></span>
                        <span className="star"></span>
                    </div>
                }
                {Math.round(reviewPercentage[0].venueRankAverage) == 4 &&
                    <div className="vgstars">
                        <span className="star yellow v-filled-star"></span>
                        <span className="star yellow v-filled-star"></span>
                        <span className="star yellow v-filled-star"></span>
                        <span className="star yellow v-filled-star"></span>
                        <span className="star"></span>
                    </div>
                }
                {Math.round(reviewPercentage[0].venueRankAverage) == 5 &&
                    <div className="vgstars">
                        <span className="star yellow v-filled-star"></span>
                        <span className="star yellow v-filled-star"></span>
                        <span className="star yellow v-filled-star"></span>
                        <span className="star yellow v-filled-star"></span>
                        <span className="star yellow v-filled-star"></span>
                    </div>
                }
            </div>
            {reviewPercentage.length > 0 && reviewPercentage[0].total > 0 &&
                <div className="number-of-reviews stencil-item">{reviewPercentage[0].total} Reviews</div>
            }
        </div>
    )
}

function ReviewPercentage({ reviewPercentage }: { reviewPercentage: any }) {

    let Review5: any = reviewPercentage.filter(function (x: (any)) {
        return x.review == 5
    });

    let Review5Percentage: number = 0;
    if (Review5.length > 0) {
        Review5Percentage = Review5[0].percent;
    }

    let Review4: any = reviewPercentage.filter(function (x: (any)) {
        return x.review == 4
    });

    var Review4Percentage: number = 0;
    if (Review4.length > 0) {
        Review4Percentage = Review4[0].percent;
    }

    let Review3: any = reviewPercentage.filter(function (x: (any)) {
        return x.review == 3
    });

    let Review3Percentage: number = 0;
    if (Review3.length > 0) {
        Review3Percentage = Review3[0].percent;
    }

    let Review2: any = reviewPercentage.filter(function (x: (any)) {
        return x.review == 2
    });

    let Review2Percentage: number = 0;
    if (Review2.length > 0) {
        Review2Percentage = Review2[0].percent;
    }

    let Review1: any = reviewPercentage.filter(function (x: (any)) {
        return x.review == 1
    });

    let Review1Percentage: number = 0;
    if (Review1.length > 0) {
        Review1Percentage = Review1[0].percent;
    }


    return (
        <div className="star-rating-col bars-row">
            <div className="review-bars">

                <div className="review-bar-row vp-2">
                    <div className="review-bar-num ">5</div>
                    <div className="review-bar" style={{ width: Review5Percentage + "%" }}></div>
                </div>
                <div className="review-bar-row vp-2">
                    <div className="review-bar-num">4</div>
                    <div className="review-bar" style={{ width: Review4Percentage + "%" }}></div>
                </div>
                <div className="review-bar-row vp-2">
                    <div className="review-bar-num">3</div>
                    <div className="review-bar" style={{ width: Review3Percentage + "%" }}></div>
                </div>
                <div className="review-bar-row vp-2">
                    <div className="review-bar-num">2</div>
                    <div className="review-bar" style={{ width: Review2Percentage + "%" }}></div>
                </div>
                <div className="review-bar-row vp-2">
                    <div className="review-bar-num">1</div>
                    <div className="review-bar" style={{ width: Review1Percentage + "%" }}></div>
                </div>
            </div>
        </div>

    )
}

function ReviewPercentageSecond({ rank }: { rank: any }) {

    let overallRank = ValueToPercentage(rank.general);
    let punctualityRank = ValueToPercentage(rank.punctuality);
    let ValueRank = ValueToPercentage(rank.value);
    let ServiceRank = ValueToPercentage(rank.service);



    return (
        <div className="star-rating-col">
            <div className="ratings-rows">
                <div className="ratings-row">
                    <div className="rating-text vp-2">Overall</div>
                    <div className="rating-row-stars">
                        <div className="vgstars">
                            <span className="star"></span>
                            <span className="star"></span>
                            <span className="star"></span>
                            <span className="star"></span>
                            <span className="star"></span>
                            <div className="reatedstar-colored" style={{ width: `${overallRank}%` }}>
                                <span className="star yellow v-filled-star"></span>
                                <span className="star yellow v-filled-star"></span>
                                <span className="star yellow v-filled-star"></span>
                                <span className="star yellow v-filled-star"></span>
                                <span className="star yellow v-filled-star"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ratings-row">
                    <div className="rating-text vp-2">Punctuality</div>
                    <div className="rating-row-stars">
                        <div className="vgstars">
                            <span className="star "></span>
                            <span className="star"></span>
                            <span className="star"></span>
                            <span className="star"></span>
                            <span className="star"></span>
                            <div className="reatedstar-colored" style={{ width: `${punctualityRank}%` }}>
                                <span className="star yellow v-filled-star"></span>
                                <span className="star yellow v-filled-star"></span>
                                <span className="star yellow v-filled-star"></span>
                                <span className="star yellow v-filled-star"></span>
                                <span className="star yellow v-filled-star"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ratings-row">
                    <div className="rating-text vp-2">Value</div>
                    <div className="rating-row-stars">
                        <div className="vgstars">
                            <span className="star"></span>
                            <span className="star"></span>
                            <span className="star"></span>
                            <span className="star"></span>
                            <span className="star"></span>
                            <div className="reatedstar-colored" style={{ width: `${ValueRank}%` }}>
                                <span className="star yellow v-filled-star"></span>
                                <span className="star yellow v-filled-star"></span>
                                <span className="star yellow v-filled-star"></span>
                                <span className="star yellow v-filled-star"></span>
                                <span className="star yellow v-filled-star"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ratings-row">
                    <div className="rating-text vp-2">Service</div>
                    <div className="rating-row-stars">
                        <div className="vgstars">
                            <span className="star"></span>
                            <span className="star"></span>
                            <span className="star"></span>
                            <span className="star"></span>
                            <span className="star"></span>
                            <div className="reatedstar-colored" style={{ width: `${ServiceRank}%` }}>
                                <span className="star yellow v-filled-star"></span>
                                <span className="star yellow v-filled-star"></span>
                                <span className="star yellow v-filled-star"></span>
                                <span className="star yellow v-filled-star"></span>
                                <span className="star yellow v-filled-star"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const ValueToPercentage = (percentage: any) => {
    return (((Math.round(percentage)) * 100) / 5)
}

declare global {
    interface Window { WriteReview: any; }
    interface Window { Reviews: any; }
}

window.WriteReview = function (data: any, userContext: UserContext, event: any) {
    OpenWriteReview({ data, userContext, event })
};
window.Reviews = function (data: any, userContext: UserContext,event:any) {
    const bookMark = document.getElementById('bookMark');
    const writeReviews = document.getElementById('writeReviews');
    if(bookMark || writeReviews){
        if ( (bookMark && !bookMark.contains(event.target)) && (writeReviews && !writeReviews.contains(event.target))) {
            OpenReviews({ data, userContext})
        }
    }
    else{
        OpenReviews({ data, userContext})
    }    
};

function OpenWriteReview({ data, userContext, event }: { data: any; userContext: UserContext; event: any; }) {
    let LoginDetails = utils.ReadCookie();
    if (LoginDetails === undefined || LoginDetails === "" || LoginDetails === null) {
        window.wbc.openVagaroPage("divVagaroIframe", utils.VURL("Login", null) + addtoCartEnum.WriteReview + "&Data=" + event.target.id);
        return;
    }
    return ReactDOM.render(
        <VgWriteReview
            data={data}
            userContext={userContext}
            LoginDetails={LoginDetails}
        />,
        document.getElementById("OpenDialog1")
    )
}
function OpenReviews({ data, userContext }: { data: any; userContext: UserContext;  }) {
    return ReactDOM.render(
        <Reviews
            data={data}
            userContext={userContext}
        />,
        document.getElementById("OpenDialog")
    )
}
function ReviewPercentageBookmark({ data, userContext }: { data: any; userContext: UserContext; }) {
    const [isContentLoading, setContentLoading] = useState(false);
    var IsEditor = (window as any).isEditor !== undefined ? (window as any).isEditor : false;

    const [Bookmark, setBookmark] = useState(false)
    const [showfavorite, setshowfavorite] = useState(false)

    useEffect(() => {
        let LoginDetails = utils.ReadCookie();
        if (LoginDetails != undefined && LoginDetails != "" && LoginDetails != null) {
            Showfavorites();
        }
    }, [])

    const Showfavorites = () => {
        let LoginDetails = utils.ReadCookie();
        const photoUrl = `${userContext.businessId}/Bookmark/showfavorites?EncUid=` + LoginDetails.EncUid;
        AsyncAjaxGet(
            photoUrl,
            userContext.accessToken,
            function onSuccess(data: any) {
                if (data != null && data.data != null && data.data.data != null) {
                    if (data.data.data.tblUserFavorite == 1) {
                        setshowfavorite(true)
                        setBookmark(true)
                    } else {
                        setshowfavorite(false)
                        setBookmark(false)
                    }
                }
            }, function onFailure() {
                console.log("error")
            }
        );
    }

    const Openbookmark = (operation: string, event: any) => {
        let LoginDetails = utils.ReadCookie();
        if (LoginDetails === undefined || LoginDetails === "" || LoginDetails === null) {
            window.wbc.openVagaroPage("divVagaroIframe", utils.VURL("Login", null) + addtoCartEnum.Bookmarked + "&Data=" + event.target.id);
            return;
        }
        setContentLoading(true);
        const Url = `${userContext.businessId}/BookMark/addtofavorite`;
        const body = {
            "operationtype": operation,
            "EncUid": LoginDetails.EncUid,
        }
        AsyncAjaxPost(
            Url,
            JSON.stringify(body),
            userContext.accessToken,
            function onSuccess(Data: any) {
                if (Data != null && Data.data != null && Data.data.data != null) {
                    if (Data.data.data.isStatus == 1) {
                        setBookmark(true)
                        setshowfavorite(true)
                    } else {
                        setBookmark(false)
                        setshowfavorite(false)
                    }
                    setContentLoading(false);
                }
                setContentLoading(false);
            },
            function onFailure() {
                console.log("error")
            }
        );
    }
    const handleChange = (event: any) => {
        if (Bookmark) {
            var remove = "remove";
            Openbookmark(remove, event);
        } else {
            var add = "add";
            Openbookmark(add, event)
        }
    }
    return (
        <Fragment>
            {isContentLoading && < Loader />}
            <div className="star-rating-col bookmark-row">
                <div className="bookmark-btnsrows" style={{ padding: "0 12px" }}>
                    <div className="bookmarkbnt" style={{ cursor: "pointer" }}>
                        {!Bookmark && !showfavorite ? <a id='bookMark' className="v-button secondary w-full w-nowrap" onClick={(event) => !IsEditor && handleChange(event)}><i className={Bookmark ? "far fa-bookmark active vgbookmarkicon" : "far fa-bookmark vgbookmarkicon"}></i>Bookmark on Vagaro</a>

                            : (<a id='bookMark' className="v-button secondary w-full w-nowrap" onClick={(event) => !IsEditor && handleChange(event)}><i className={Bookmark ? "far fa-bookmark active vgbookmarkicon" : "far fa-bookmark vgbookmarkicon"}></i>Bookmarked on Vagaro</a>)}
                    </div>
                    <div className="bookmarkbnt">
                        <a id='writeReviews' className="v-button secondary invisible-button w-full w-nowrap" style={{ cursor: "pointer" }} onClick={(event) => !IsEditor && (window as any).WriteReview(data, userContext, event)}>Write a review</a>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}