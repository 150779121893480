import React, { Fragment, useRef } from "react";
import { addtoCartEnum, utils } from "../../utils/Utils";
import ReactDom from "react-dom";
import CommonMessagepopup from "../../VagaroComponent/CommonMessagepopup";
import MultiLocationDropdown from "../../VagaroComponent/MultiLocationDropdown";
import { Site } from "../../utils/Utils";
import { AsyncAjaxGet } from "../Utilities/AsyncAjaxService";
import $ from 'jquery';

export default function RenderBusinessCart({ data, userContext }: { data: any, userContext: any }) {

    const ref = useRef(userContext);

    window.RefreshCart = function (data: any) {
        return ReactDom.render(
            <Cart
                data={data}
                userContext={ref.current}
            />,
            document.getElementById("divcart")
        )
    }

    const bData = data;
    return (
        (typeof bData != 'undefined' && bData != null) ? <Cart data={bData} userContext={userContext} /> : <Cart data={0} userContext={userContext} />
    );
}

function Cart({ data, userContext }: { data: any, userContext: any }) {
    var IsEditor = (window as any).isEditor !== undefined ? (window as any).isEditor : false;

    return <Fragment>
        <div id="divcart">
            <a onClick={(event) => !IsEditor && (window as any).BusinessCart(data, userContext, true)} className="cart-comp no-a" role="button"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-cart" viewBox="0 0 16 16"> <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" /> </svg><span className={"cart-comp-num " + ((!data || data == "0" || data == 0) ? "hidden" : "")} id="cartdisplay"><span>{data}</span></span></a>
        </div>
    </Fragment>
}

declare global {
    interface Window { BusinessCart: any; RefreshCart: any; }
}



window.BusinessCart = function (data: any, userContext: any, isOpen: boolean, VgUrl: any) {
    let LoginDetails = utils.ReadCookie();
    let LoginUserID = LoginDetails.EncUid;

    if (LoginUserID === undefined || LoginUserID === "" || LoginUserID === null) {
        window.wbc.openVagaroPage("divVagaroIframe", utils.VURL("Login", null) + addtoCartEnum.Cart + "&Data=");
        return;
    }
    else {
        if (data == 0) {
            return ReactDom.render(
                <CommonMessagepopup
                    isCart={true}
                />,
                document.getElementById("OpenDialog1")
            );
        } else if (userContext.MultilocationData.length > 1 && isOpen !== false) {
            $(".wbloader").css("display", "block");
            const Url = userContext.businessId + "/addtocart/getcartcountmultilocation?EncUid=" + LoginUserID
            AsyncAjaxGet(
                Url,
                userContext.accessToken,
                function onSuccess(data: any) {
                    if (data !== null && data.data !== null && data.data.data !== null) {
                        if (data.data.data.lstBusinessCart.length > 1) {
                            $(".wbloader").css("display", "none");
                            let Multilocations: any[] = [];
                            var BData = userContext.MultilocationData.filter(
                                (col: any) => col.businessID > 0
                            );
                            BData.map((Data: any) => {
                                let lstBusinessCart = data.data.data.lstBusinessCart;
                                var BusinessData = lstBusinessCart.filter(
                                    (col: any) => col.encBIDHash == Data.encBIDHash
                                );
                                if (BusinessData.length > 0) {
                                    Multilocations.push(Data);
                                }
                            })
                            return ReactDom.render(
                                <MultiLocationDropdown
                                    Sitedata={Multilocations}
                                    OnClickEvent={window.BusinessCart}
                                    Mode={"Cart"}
                                    userContext={userContext}
                                    data={data}
                                    LoginUserID={LoginUserID}
                                    IsShowMap={true}
                                />,
                                document.getElementById("OpenDialogMultiLocation")
                            );
                        }
                        else {
                            if (data.data.data.lstBusinessCart.length == 1) {
                                var bid = data.data.data.lstBusinessCart[0].encBIDHash;
                                var BusinessData = userContext.MultilocationData.filter(
                                    (col: any) => col.encBIDHash == bid
                                );
                                if (BusinessData.length == 1) {

                                    var widgetCookieString = "";
                                    var vsdId = localStorage.getItem("vdsid")
                                    if (vsdId != null && vsdId != '' && vsdId != undefined) {
                                        widgetCookieString = "&wgVal=" + vsdId;
                                    }
                    
                                    window.wbc.openVagaroPage("divVagaroIframe", Site.SiteName + BusinessData[0].vagaroURL + "/shopping-bag?Type=Builder&Event=ShoppingBag" + widgetCookieString)
                                }
                            }
                        }

                    }
                },
                function onFailure() {
                    console.log("error");
                }
            );

        } else {
            if (utils.isCheckEmptyOrWhiteSpace(VgUrl)) {
                window.location.href = "shopping-bag";
            }
            else {
                var widgetCookieString = "";
                var vsdId = localStorage.getItem("vdsid")
                if (vsdId != null && vsdId != '' && vsdId != undefined) {
                    widgetCookieString = "&wgVal=" + vsdId;
                }
                window.wbc.openVagaroPage("divVagaroIframe", Site.SiteName + VgUrl + "/shopping-bag?Type=Builder&Event=ShoppingBag" + widgetCookieString)
            }

        }
    }
}
