import React, { Fragment, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import ReactDOM, { createPortal, unmountComponentAtNode } from 'react-dom';
import VgStarRating from "../components/Controls/VgStarRating";
import { ResponseCode, utils } from "../utils/Utils";
import { AsyncAjaxGet, AsyncAjaxPost } from "../components/Utilities/AsyncAjaxService";
import VGPopUp from "../components/Controls/VGPopUp";
import "./VgWriteReview.scss";
import Loader from "../components/Common/Loader";
import getJPEGFileFromHEICWeb from "../utils/Webhook-jpg";
import $ from "jquery";
import detect from "../../node_modules/detect.js/detect";
import Toast from "../components/Utilities/Toast";
import { Dropdown, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import RenderBusinessReviewPercentage from "../components/BusinessControls/RenderBusinessReviewPercentage";

const VgWriteReview = (props) => {

    const BusinessId = props.userContext.businessId;
    const access_token = props.userContext.accessToken;

    const BusinessLocation = utils.MultiLocationData(props.userContext, props.userContext.bidHash);

    const VagaroAPIEnum = {
        GetServiceProvider: BusinessId + "/ServiceProvider/getserviceproviderbybusinessid",
        SaveReview: "merchants/" + BusinessId + "/reviews/savereview",
        CheckReview: "merchants/" + BusinessId + "/reviews/userreviewsresult",
        SaveReviewImage: "merchants/" + BusinessId + "/reviews/savereviewimage",
    };

    const [providerCount, setProviderCount] = useState(0);
    const [businessCount, setBusinessCount] = useState(0);
    const [serviceProvider, setServiceProvider] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [serviceChange, setServiceChange] = useState("");
    const [reviewImage, setReviewImage] = useState([]);
    const [punctualityCount, setPunctualityCount] = useState(0);
    const [overallCount, setOverallCount] = useState(0);
    const [valueCount, setValueCount] = useState(0);
    const [serviceCount, setServiceCount] = useState(0);
    const [businessReview, setBusinessReview] = useState("");
    const [provierReview, setProvierReview] = useState("");
    const [checkReviewResult, setCheckReviewResult] = useState();
    const [ChangEffect, SetEffect] = useState(false);
    const [reviewImageBase64, setreviewImageBase64] = useState([]);
    const [shareOnFacebook, setShareOnFacebook] = useState(1);

    useEffect(() => {
        ServiceProvider();
        CheckReview();
    }, []);
    var reviewImageXML = "";
    //from about
    const SaveReviewRequest = () => {
        const Requestdata = {};
        Requestdata.rnkPunctuality = punctualityCount;
        Requestdata.rnkVenue = overallCount;
        Requestdata.rnkValue = valueCount;
        Requestdata.rnkService = serviceCount;
        Requestdata.VenueReview = businessReview;
        Requestdata.SPReview = provierReview;
        Requestdata.EncUid = props.LoginDetails.EncUid;
        Requestdata.iSPID = serviceChange.id !== 'Select Service Provider' ? serviceChange.id : null;
        Requestdata.isFromShopdetail = true;
        Requestdata.cboServiceProviderValue = serviceChange !== "" && serviceChange.id.toString() !== 'Select Service Provider' ? serviceChange.id.toString() : "U2FsdGVkX18EI4k80FtcVtq4PzxA94OPqaGqyI1vxNM=";
        Requestdata.reviewPoint = 0;
        Requestdata.reviewImage = reviewImageXML;
        Requestdata.iUserID = null; //loginuserid
        Requestdata.rnkCovid = 0;
        Requestdata.rnkVPL = 0;
        Requestdata.IsCovidBusiness = true;
        Requestdata.isFromCovidCleanCheckout = false;
        return Requestdata;
    };

    const ShowAlert = (title, body, strConfirmButtonText) => {
        const AlertBody = (body) => {
            return (
                <div>
                    {body}
                </div>
            )
        }
        const AlertFooter = () => {
            return (
                <Fragment>
                    <button className="v-button primary w-25" onClick={() => AlertClose()}>{strConfirmButtonText}</button>
                </Fragment >
            )
        }
        const AlertClose = () => {
            if (title !== "Alert") {
                unmountComponentAtNode(document.getElementById("OpenDialog"));
                unmountComponentAtNode(document.getElementById("OpenDialog1"));
            }
            unmountComponentAtNode(document.getElementById("OpenDialog2"));
        };
        return ReactDOM.render(
            <VGPopUp
                id={"Alert"}
                show={true}
                onHide={AlertClose}
                title={title}
                dialogClassName="vgn-modal-alert vgn-modal-small writereview-alert"
                body={AlertBody(body)}
                footer={AlertFooter()}
                isRemoveTabModalIndex={false}
                isShowFooter={true}
                backdrop={true}
            />,
            document.getElementById("OpenDialog2")
        );
    }
    const CheckReview = async () => {
        if (utils.Validate(access_token)) {
            setLoading(true);
            await AsyncAjaxGet(
                VagaroAPIEnum.CheckReview + `?EncUid=${props.LoginDetails.EncUid}`,
                access_token,
                function onSuccess(data) {
                    try {
                        if (data != null && data.data != null) {
                            if (data != null && data.data != null && data.data.data != null) {
                                setCheckReviewResult(data.data.data.returnValue);
                                var StrMassage = "";
                                var Title = "Unable to Write Review";
                                var strConfirmButtonText = "Close";
                                if (data.data.data.returnValue === -1) {
                                    StrMassage = "Sorry! For this salon, you can not give more than one review per day.";
                                    ShowAlert(Title, StrMassage, strConfirmButtonText);
                                    setLoading(false);
                                    return;
                                }
                                else if (data.data.data.returnValue === -2) {
                                    StrMassage = "All reviews are mandatory.";
                                    ShowAlert(Title, StrMassage, strConfirmButtonText);
                                    setLoading(false);
                                    return;
                                }
                                else if (data.data.data.returnValue === -3) {
                                    StrMassage = "You can't give reviews to yourself.";
                                    ShowAlert(Title, StrMassage, strConfirmButtonText);
                                    setLoading(false);
                                    return;
                                }
                                else if (data.data.data.returnValue === -4) {
                                    strConfirmButtonText = "OK";
                                    StrMassage = "You are an employee at this business. Employees cannot write reviews for their own business.";
                                    ShowAlert(Title, StrMassage, strConfirmButtonText);
                                    setLoading(false);
                                    return;
                                }
                                else if (data.data.data.returnValue === -5) {
                                    StrMassage = "Sorry! You can't give a review to the salon as you are an inactive user."
                                    ShowAlert(Title, StrMassage, strConfirmButtonText);
                                    setLoading(false);
                                    return;
                                }
                                else if (data.data.data.returnValue === -7) {
                                    Title = "Unable to Write Review";
                                    StrMassage = "You cannot give a review to a business you have never visited.";
                                    ShowAlert(Title, StrMassage, strConfirmButtonText);
                                    setLoading(false);
                                    return;
                                }
                            }
                        }
                    }
                    catch (error) {
                        setLoading(false);
                    }
                },
                function OnError() {
                    setLoading(false);
                }
            );
        }
        else {
            utils.ShowAlert("Message", "Please reload this page and try again.", "OpenDialog1");
        }
    }
    const dataBindKey = "data-link-key";
    const RenderComponent = async () => {
        if (utils.Validate(BusinessId) && utils.Validate(access_token)) {

            var photoUrl = `merchants/${BusinessId}/reviews/reviewpercentage`;

            await AsyncAjaxGet(
                photoUrl,
                access_token,
                function onSuccess(data) {
                    try {
                        if (
                            data != null &&
                            data.data != null &&
                            data.data.responseCode == ResponseCode.Success
                        ) {
                            if (data != null && data.data != null && data.data.data != null) {
                                var Responce = data.data.data;
                                let dataBindElements = document.querySelectorAll(`[${dataBindKey}]`);
                                dataBindElements.forEach((element) => {
                                    let layout = element.getAttribute("layout");
                                    let dataKey = element.getAttribute(dataBindKey);
                                    if (layout && dataKey === "reviewpercentage") {
                                        ReactDOM.render(<RenderBusinessReviewPercentage layout={layout} data={Responce} userContext={props.userContext} />, element);
                                    }
                                })
                            }
                        }
                    } catch (error) {
                        console.log("error:", error);
                    }
                },
                function OnError() {
                    console.log("error");
                }
            );
        }
    };
    const openPopup = (e, link) => {
        e.preventDefault();
        window.open(link, 'popup', 'width=600,height=500');
    }
    const encodedUrl = encodeURIComponent(window.location.href);
    const handleToggleChange = (value) => {
        setShareOnFacebook(value);
    }
    const SaveButton = async (e, type) => {
        e.preventDefault();
        var Title = "Message";
        if (serviceCount === 0 || overallCount === 0 || valueCount === 0 || punctualityCount === 0) {
            ShowAlert("Alert", "All reviews are mandatory.", "Close");
            return;
        }
        if (props.LoginDetails.EncUid === document.getElementById("ddlprovider").value) {
            utils.ShowAlert(Title, "You can not give reviews to yourself.", "OpenDialog2");
            return;
        }
        await SaveReviewImage(e);
        await SaveReview(e);
    }
    const SaveReview = async (e) => {
        e.preventDefault();
        if (utils.Validate(access_token)) {
            setLoading(true);
            const Requestdata = SaveReviewRequest();
            await AsyncAjaxPost(
                VagaroAPIEnum.SaveReview,
                Requestdata,
                access_token,
                function onSuccess(data) {
                    try {
                        PopupClose();
                        setLoading(false);
                        Toast("Your review has been posted.");
                        if (shareOnFacebook === 2) {
                            openPopup(e, `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`);
                        }
                        RenderComponent();
                        if ($("#RenderReloadReview").length > 0) {
                            $("#RenderReloadReview").click();
                        }
                        if (props.ReloadReview != undefined) {
                            props.ReloadReview();
                        }
                    }
                    catch (error) {
                        console.log("error")
                    }
                },
                function OnError() {
                    console.log("error")
                }
            );
        }
        else {
            utils.ShowAlert("Message", "Please reload this page and try again.", "OpenDialog1");
        }
    }
    const SaveReviewImage = async (e) => {
        e.preventDefault();
        if (utils.Validate(access_token)) {
            setLoading(true);
            const Requestdata = reviewImageBase64;
            await AsyncAjaxPost(
                VagaroAPIEnum.SaveReviewImage,
                Requestdata,
                access_token,
                function onSuccess(data) {
                    if (data != null && data.data != null && data.data.responseCode == ResponseCode.Success && data.data.data != null) {
                        try {
                            reviewImageXML = data.data.data;
                            setLoading(false);
                        }
                        catch (error) {
                            console.log("error")
                        }
                    }
                },
                function OnError() {
                    console.log("error")
                }
            );
        }
        else {
            utils.ShowAlert("Message", "Please reload this page and try again.", "OpenDialog1");
        }
    }
    const ServiceProvider = async () => {
        if (utils.Validate(access_token)) {
            setLoading(true);
            await AsyncAjaxGet(
                VagaroAPIEnum.GetServiceProvider,
                access_token,
                function onSuccess(data) {
                    try {
                        if (data != null && data.data != null && data.data.data != null) {
                            setServiceProvider(data.data.data);
                            setLoading(false);
                        } else {
                            setLoading(false);
                        }
                    } catch (error) {
                        setLoading(false);
                    }
                },
                function OnError() {
                    setLoading(false);
                }
            );
        } else {
            utils.ShowAlert(
                "Message",
                "Please reload this page and try again.",
                "OpenDialog1"
            );
        }
    }

    const PopupCloseConfirmation = () => {
        unmountComponentAtNode(document.getElementById("OpenDialog2"));
    };

    const DeleteImage = (indexToDelete) => {
        var data = reviewImage.filter((_, index) => index !== indexToDelete);
        setReviewImage(data);
        SetEffect(!ChangEffect);
        // unmountComponentAtNode(document.getElementById("OpenDialog2"));
    }

    const DeleteUploadConfirmation = (index) => {
        return ReactDOM.render(
            <VGPopUp
                id={"DeleteReviwImage"}
                show={true}
                onHide={PopupCloseConfirmation}
                title={"Delete Review Image"}
                dialogClassName="cancelauto-renew-alert"
                body={
                    <div className="alertContent">
                        <p>Your layout image will be removed from the database. Are you sure you want to remove image?</p>
                    </div>
                }
                footer={
                    <Fragment>
                        <button className="w-button w-button-secondary" onClick={() => PopupCloseConfirmation()}>Cancel</button>
                        <button className="w-button w-button-destructive-outline" onClick={() => DeleteImage(index)}>Delete</button>
                    </Fragment >
                }
                isRemoveTabModalIndex={false}
                isShowFooter={true}
                size={"modal-sm"}
                backdrop={true}
            />,
            document.getElementById("OpenDialog2"));
    }
    const PopupClose = () => {
        unmountComponentAtNode(document.getElementById("OpenDialog"));
        unmountComponentAtNode(document.getElementById("OpenDialog1"));
        unmountComponentAtNode(document.getElementById("OpenDialog2"));
    };
    const UploadReviewFileClick = (e) => {
        e.target.value = "";
    };
    const CheckFileSize = (ContentLength, MaxLength) => {
        if (ContentLength <= MaxLength) {
            return true;
        }
        return false;
    };

    function isValidFile(validExtensions, fileName) {
        fileName = fileName.toLowerCase();
        var validHEICExtensions = /(HEIC|heic)$/i;
        if (fileName.match(validExtensions) != null) {
            return true;
        } else if (
            fileName.match(validHEICExtensions) != null &&
            isWebBrowserSupportHEIC()
        ) {
            return true;
        }

        return false;
    }
    function isWebBrowserSupportHEIC() {
        var b = detect.parse(navigator.userAgent);
        var browserName = b.browser.family.toLowerCase(),
            browserversion = parseInt(b.browser.version);
        var isIE = /*@cc_on!@*/ false || !!document.documentMode;
        var isEdge = !isIE && !!window.StyleMedia;
        if (isIE || isEdge) {
            return false;
        } else if (
            (browserName.indexOf("chrome") > -1 && browserversion > 67) ||
            (browserName.indexOf("firefox") > -1 && browserversion > 61) ||
            (browserName.indexOf("safari") > -1 && browserversion > 11.1)
        )
            return true;
        return false;
    }
    const GeneralFileUploadValidation = (filesDetails, divMain) => {
        setLoading(true);
        $('.drop-file-upload-lg').prop('title', "No file chosen");
        if (isValidFile(/(ico|gif|png|jpg|jpeg|ICO|GIF|PNG|JPG|JPEG)$/i, filesDetails.name)) {
            var MaxLength = 4194304;
            if (CheckFileSize(filesDetails.size, MaxLength)) {
                if (isValidFile(/(gif|GIF)$/i, filesDetails.name)) {
                    setLoading(false)
                    const TitleEnum = "Please select valid file.";
                    const BodyEnum = "select valid file.";
                    utils.ShowAlert(TitleEnum, BodyEnum, "OpenDialog2");
                } else {
                    setLoading(false)
                    var reader = new FileReader();
                    reader.readAsDataURL(filesDetails);
                    reader.onload = function () {
                        var data = reviewImage;
                        var dataBase64 = reviewImageBase64;
                        var base64 = reader.result.split(",");
                        data.push({
                            "ActionName": "Add",
                            "Image_Type": filesDetails.type,
                            "ImageInputStream": null,
                            "ImageName": filesDetails.name,
                            "base64": base64
                        });
                        dataBase64.push({
                            "ActionName": "Add",
                            "Image_Type": filesDetails.type,
                            "ImageInputStream": null,
                            "ImageName": filesDetails.name,
                            "Base64Image": `url(${base64.slice(0, 2).join(",")})`,
                            "Foldername": "ReviewPhoto/Original/"
                        });
                        setReviewImage(data)
                        setreviewImageBase64(dataBase64);
                        SetEffect(!ChangEffect);
                    };
                }
            }
            else {
                setLoading(false)
                const TitleEnum = "Invalid Image Size";
                const BodyEnum = "File size limit exceeds 4 MB.";
                utils.ShowAlert(TitleEnum, BodyEnum, "OpenDialog2");
            }
        } else {
            setLoading(false)
            const TitleEnum = "Invalid File Type";
            const BodyEnum = "Please Select valid file";
            utils.ShowAlert(TitleEnum, BodyEnum, "OpenDialog2");
        }
    };
    const onChangeReviewUploadFile = (e, divMain, props) => {
        setLoading(true);
        e.target.parentNode.parentNode.classList.remove('over');

        var filesDetails = e.target.files[0];
        var filesName;

        if (isValidFile(/(HEIC|heic)$/i, filesDetails.name)) {
            var MaxLength = 4194304;
            if (CheckFileSize(filesDetails.size, MaxLength)) {
                filesName = e.target.files[0].name.toLowerCase().replace(/\.heic/, ".jpeg");
                getJPEGFileFromHEICWeb(filesDetails, filesName, function (file) {
                    filesDetails = file;
                    $("#generalupload").attr("style", "display:none");
                    setLoading(false);
                    var reader = new FileReader();
                    reader.readAsDataURL(filesDetails);
                    reader.onload = function () {
                        var base64 = reader.result.split(",");
                        var data = reviewImage;
                        var dataBase64 = reviewImageBase64;
                        data.push({
                            "ActionName": "Add",
                            "Image_Type": filesDetails.type,
                            "ImageInputStream": null,
                            "ImageName": filesDetails.name,
                            "base64": base64
                        });
                        dataBase64.push({
                            "ActionName": "Add",
                            "Image_Type": filesDetails.type,
                            "ImageInputStream": null,
                            "ImageName": filesDetails.name,
                            "Base64Image": `url(${base64.slice(0, 2).join(",")})`,
                            "Foldername": "ReviewPhoto/Original/"
                        });
                        setReviewImage(data);
                        setreviewImageBase64(dataBase64);
                        SetEffect(!ChangEffect);
                    };
                });
            } else {
                setLoading(false)
                const TitleEnum = "Alert!";
                const BodyEnum = "File size limit exceeds 4 MB.";
                utils.ShowAlert(TitleEnum, BodyEnum, "OpenDialog2");
            }
        } else {
            GeneralFileUploadValidation(filesDetails, divMain);
        }
    }
    const handleDragEnter = (e) => {

        let getparent = e.target.parentNode.parentNode;
        getparent.classList.add('over')
    }
    const handleDragLeave = (e) => {
        let getparent = e.target.parentNode.parentNode;
        getparent.classList.remove('over')
    }

    const setLetter = (photo, firstName, lastName) => {
        let image = photo;
        let firstNameHasNumber = /^[0-9]+$/.test(firstName.charAt(0))
        let lastNameHasNumber = /^[0-9]+$/.test(lastName.charAt(0))
        let firstNameSpecialCharacter = /^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/.test(firstName.charAt(0))
        let lastNameSpecialCharacter = /^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/.test(lastName.charAt(0))
        if (image !== undefined && image !== null && image !== '' && image.includes("user-female-img_155.jpg") === false && image.includes("user-male-img_155_new.png") === false) {
            return (<div className="review-userImg" style={{ backgroundImage: `url(${photo})` }}></div>)
        }
        else if ((firstName.charAt(0) === firstName.charAt(0).toUpperCase() && lastName.charAt(0) === lastName.charAt(0).toUpperCase()) && (firstNameHasNumber !== true && lastNameHasNumber !== true) && (firstNameSpecialCharacter !== true && lastNameSpecialCharacter !== true)) {
            return (
                <Fragment>
                    <div class="review-profile vagaro-avatar-md mr15">
                        <div class="review-profile-inner-div intialsname vg-avatar-inital" style={{ display: "block" }}>
                            <div class="review-profile-inner vg-avatar-inital-inner">
                                <div class="review-profile-inner-text vg-avatar-inital-inner-text">
                                    {firstName.charAt(0).toUpperCase()}
                                    {lastName.charAt(0).toUpperCase()}
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        } else if (firstName.charAt(0) === firstName.charAt(0).toLowerCase() && lastName.charAt(0) === lastName.charAt(0).toLowerCase()) {
            return (
                <Fragment>
                    <div class="review-profile vagaro-avatar-md mr15">
                        <div class="review-profile-inner-div intialsname vg-avatar-inital" style={{ display: "block" }}>
                            <div class="review-profile-inner vg-avatar-inital-inner">
                                <div class="review-profile-inner-text vg-avatar-inital-inner-text">
                                    {firstName.charAt(0).toUpperCase()}
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        } else if (firstName.charAt(0) === firstName.charAt(0).toUpperCase() && lastName.charAt(0) === lastName.charAt(0).toLowerCase()) {
            return (
                <Fragment>
                    <div class="review-profile vagaro-avatar-md mr15">
                        <div class="review-profile-inner-div intialsname vg-avatar-inital" style={{ display: "block" }}>
                            <div class="review-profile-inner vg-avatar-inital-inner">
                                <div class="review-profile-inner-text vg-avatar-inital-inner-text">
                                    {firstName.charAt(0).toUpperCase()}
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        } else if (firstName.charAt(0) === firstName.charAt(0).toLowerCase() && lastName.charAt(0) === lastName.charAt(0).toUpperCase()) {
            return (
                <Fragment>
                    <div class="review-profile vagaro-avatar-md mr15">
                        <div class="review-profile-inner-div intialsname vg-avatar-inital" style={{ display: "block" }}>
                            <div class="review-profile-inner vg-avatar-inital-inner">
                                <div class="review-profile-inner-text vg-avatar-inital-inner-text">
                                    {lastName.charAt(0).toUpperCase()}
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        }
        else if ((firstName.charAt(0) === firstName.charAt(0).toUpperCase() || firstName.charAt(0) === firstName.charAt(0).toLowerCase()) && lastNameHasNumber === true) {
            <Fragment>
                <div class="review-profile vagaro-avatar-md mr15">
                    <div class="review-profile-inner-div intialsname vg-avatar-inital" style={{ display: "block" }}>
                        <div class="review-profile-inner vg-avatar-inital-inner">
                            <div class="review-profile-inner-text vg-avatar-inital-inner-text">
                                {firstName.charAt(0).toUpperCase()}
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        } else if ((lastName.charAt(0) === lastName.charAt(0).toUpperCase() || lastName.charAt(0) === lastName.charAt(0).toLowerCase()) && firstNameHasNumber === true) {
            <Fragment>
                <div class="review-profile vagaro-avatar-md mr15">
                    <div class="review-profile-inner-div intialsname vg-avatar-inital" style={{ display: "block" }}>
                        <div class="review-profile-inner vg-avatar-inital-inner">
                            <div class="review-profile-inner-text vg-avatar-inital-inner-text">
                                {lastName.charAt(0).toUpperCase()}
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        }
        else if ((firstName.charAt(0) === firstName.charAt(0).toUpperCase() || firstName.charAt(0) === firstName.charAt(0).toLowerCase()) && lastNameSpecialCharacter === true) {
            <Fragment>
                <div class="review-profile vagaro-avatar-md mr15">
                    <div class="review-profile-inner-div intialsname vg-avatar-inital" style={{ display: "block" }}>
                        <div class="review-profile-inner vg-avatar-inital-inner">
                            <div class="review-profile-inner-text vg-avatar-inital-inner-text">
                                {firstName.charAt(0).toUpperCase()}
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        } else if ((lastName.charAt(0) === lastName.charAt(0).toUpperCase() || lastName.charAt(0) === lastName.charAt(0).toLowerCase()) && firstNameSpecialCharacter === true) {
            <Fragment>
                <div class="review-profile vagaro-avatar-md mr15">
                    <div class="review-profile-inner-div intialsname vg-avatar-inital" style={{ display: "block" }}>
                        <div class="review-profile-inner vg-avatar-inital-inner">
                            <div class="review-profile-inner-text vg-avatar-inital-inner-text">
                                {lastName.charAt(0).toUpperCase()}
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        }
        else if (firstNameHasNumber === true && lastNameHasNumber === true) {
            return (
                <Fragment>
                    <div class="review-profile vagaro-avatar-md mr15">
                        <div class="review-profile-inner-div intialsname vg-avatar-inital" style={{ display: "block" }}>
                            <div class="review-profile-inner vg-avatar-inital-inner">
                                <div class="review-profile-inner-text vg-avatar-inital-inner-text">
                                    {firstName.charAt(0)}
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        } else if (firstNameSpecialCharacter === true && lastNameSpecialCharacter === true) {
            return (
                <Fragment>
                    <div class="review-profile vagaro-avatar-md mr15">
                        <div class="review-profile-inner-div intialsname vg-avatar-inital" style={{ display: "block" }}>
                            <div class="review-profile-inner vg-avatar-inital-inner">
                                <div class="review-profile-inner-text vg-avatar-inital-inner-text">
                                    {firstName.charAt(0)}
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        }
    }
    return createPortal(
        <Fragment>
            {isLoading && <Loader />}
            <VGPopUp
                id={"writereviewpopup"}
                show={true}
                onHide={PopupClose}
                title="Write Review"
                dialogClassName="writereview-pop"
                body={
                    <Fragment>
                        <div className="vg-write-review-wrp">
                            <div className="left">
                                <div className="business-image">
                                    <img src={BusinessLocation.cdnUrl + "Original/" + BusinessLocation.businessImage} />
                                </div>
                                <div className="business-detail-title">
                                    <div className="review-business-name">{BusinessLocation.businessName}</div>
                                    <div className="review-locations">{BusinessLocation.city + ", " + BusinessLocation.stateCode}</div>
                                </div>
                                <div className="review-user-details">
                                    <div className="reviewuser">
                                        {/* <div className="review-userImg" style={{ backgroundImage: `url(${props.LoginDetails.UImage})` }}></div> */}
                                        {setLetter(props.LoginDetails.UImage, props.LoginDetails.UName.split(" ")[0], props.LoginDetails.UName.split(" ")[1])}
                                        <div className="review-username">{props.LoginDetails.UName}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="right">
                                <Form className="form-content">
                                    <Fragment>
                                        <div className="review-row">
                                            <div className="review-col">
                                                <div className="review-lbl"><span className="astrik">*</span>Your Overall Review</div>
                                                <VgStarRating
                                                    setRating={setOverallCount}
                                                    rating={overallCount}
                                                />
                                            </div>
                                            <div className="review-col">
                                                <div className="review-lbl"><span className="astrik">*</span>Punctuality</div>
                                                <VgStarRating
                                                    setRating={setPunctualityCount}
                                                    rating={punctualityCount}
                                                />
                                            </div>
                                        </div>
                                        <div className="review-row">
                                            <div className="review-col">
                                                <div className="review-lbl"><span className="astrik">*</span>Value</div>
                                                <VgStarRating
                                                    setRating={setValueCount}
                                                    rating={valueCount}
                                                />
                                            </div>
                                            <div className="review-col">
                                                <div className="review-lbl"><span className="astrik">*</span>Service</div>
                                                <VgStarRating
                                                    setRating={setServiceCount}
                                                    rating={serviceCount}
                                                />
                                            </div>
                                        </div>
                                    </Fragment>
                                    <div className="review-form-wrapper">
                                        <Form.Group className={`form-row`}>
                                            <Form.Label>Select your service provider:</Form.Label>
                                            <div className="vg-wb-select">
                                                <Form.Select className="form-control" id="ddlprovider"
                                                    onChange={(e) => { setServiceChange({ id: e.target.value, name: e.target.options[e.target.selectedIndex].innerText, index: e.target.selectedIndex }) }}>
                                                    <option>Select Service Provider</option>
                                                    {serviceProvider.length > 0 &&
                                                        serviceProvider.map((z) => {
                                                            if (props.SPID === z.encServiceProviderID && serviceChange === "") {
                                                                setServiceChange({ id: z.encServiceProviderID, name: z.serviceProviderName, index: z.encServiceProviderID })
                                                            }
                                                            return <option selected={props.SPID === z.encServiceProviderID ? "selected" : ""} value={z.encServiceProviderID}>{z.serviceProviderName}</option>;
                                                        }, this)}
                                                </Form.Select>
                                            </div>
                                        </Form.Group>
                                        {(serviceChange !== "" && serviceChange.index > 0 && serviceChange !== null) &&
                                            <Form.Group className={`form-row`}>
                                                <span className="countSpan">{providerCount}/2500</span>
                                                <Form.Label>Leave a review for {serviceChange.name} (optional):</Form.Label>
                                                <Form.Control
                                                    id="txtSPComments"
                                                    as="textarea"
                                                    maxLength={2500}
                                                    rows={3}
                                                    placeholder={`${serviceChange.name} appreciates your feedback.`}
                                                    onChange={(e) => { setProvierReview(e.target.value.trim().substring(0, 2500)); setProviderCount(e.target.textLength) }}
                                                />
                                            </Form.Group>
                                        }
                                        <Form.Group className={`form-row`}>
                                            <span className="countSpan">{businessCount}/2500</span>
                                            <Form.Label>Leave a review for the business (optional):</Form.Label>
                                            <Form.Control
                                                id="txtComments"
                                                as="textarea"
                                                maxLength={2500}
                                                rows={3}
                                                placeholder="Your review helps the vagaro community find and book the best professionals."
                                                onChange={(e) => { setBusinessReview(e.target.value.trim().substring(0, 2500)); setBusinessCount(e.target.textLength) }}
                                            />
                                        </Form.Group>
                                        <Form.Group className={`form-row`}>
                                            <Form.Label>Add up to 3 photos (optional):</Form.Label>
                                            <div className="multifileupload-wrp py-t8">
                                                {reviewImage.length < 3 &&

                                                    <Fragment>

                                                        <div className="drop-file-upload">
                                                            <i className="fas fa-image" />
                                                            <span className="upload-note">
                                                                Drag and drop image here or
                                                            </span>
                                                            <span className="upload-trigger">
                                                                Choose File
                                                            </span>

                                                            <Form.Control
                                                                type="file"
                                                                className={`uploadfile-control ${props.className ? props.className : ""}`}
                                                                id="generalupload"
                                                                onClick={UploadReviewFileClick}
                                                                onChange={onChangeReviewUploadFile}
                                                                onDragEnter={handleDragEnter}
                                                                onDragLeave={handleDragLeave}
                                                            />
                                                            <Form.Label htmlFor="generalupload" style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%", cursor: "pointer" }}></Form.Label>

                                                        </div>
                                                    </Fragment>

                                                }


                                                {reviewImage.map((rev, index) => {
                                                    return (
                                                        <div className="drop-file-upload uploaded" style={{ backgroundImage: `url("${rev.base64.slice(0, 2).join(",")}")` }}>
                                                            <div className="showdelete delete-option-horizontal">
                                                                <div>
                                                                    <Dropdown className="moremenu dropdown">
                                                                        <Dropdown.Toggle className="more-3dot">
                                                                            <i
                                                                                className="far fa-ellipsis-v"
                                                                                aria-hidden="true"
                                                                            />
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu align="end">
                                                                            <Dropdown.Item className="no-a" onClick={(e) => { DeleteImage(index) }} title="Delete">
                                                                                Delete
                                                                            </Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </Form.Group>
                                        <Form.Group className={`form-row form-horizontal-row`}>
                                            <Form.Label>Share on Facebook<span className="sub-lbl">Share your review on Facebook to support the business.</span></Form.Label>
                                            <div className="vg-btn-switch">
                                                <ToggleButtonGroup type="radio" name="options" defaultValue={1} bsPrefix="" onChange={handleToggleChange}>
                                                    <ToggleButton id="shareon" value={1}>
                                                        Off
                                                    </ToggleButton>
                                                    <ToggleButton id="shareoff" value={2}>
                                                        On
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
                                            </div>
                                        </Form.Group>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </Fragment>

                }
                footer={
                    <Fragment>
                        <button className="v-button secondary invisible-button" onClick={() => PopupClose()}>Close</button>
                        <button className="v-button primary invisible-button" onClick={SaveButton}>Post Review</button>
                    </Fragment>
                }
                isRemoveTabModalIndex={true}
                isShowFooter={true}
                size={"xl"}
                backdrop={true}
            />
        </Fragment>,
        document.getElementById("OpenDialog1")
    );
}



export default VgWriteReview;