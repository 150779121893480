import axios from "axios";

async function AsyncAjaxPost(Url, data, callback, onFailure) {
    const APIURL = window.location.origin + Url;
    const header = {
        "Content-Type": "application/json"
    };
    await axios.post(APIURL, data, { headers: header })
        .then((response) => { callback(response) })
        .catch((error) => { onFailure(error) });
};

export { AsyncAjaxPost };
