import React, { Fragment } from 'react';
import { NoRecordsBanner } from '../OopsBanner';

export default function RenderBusinessFacilityInfo({ data, layout }: { data: any, layout: string }) {



    if (!data || data.length == 0) {
        return <NoRecordsBanner message='Facility Information is not available.' />
    }

    const isStencil = data?.isStenciled === true;

    let mapList = []
    for (let key in data) {
        mapList.push({ key: key, value: data[key] });
    }

    switch (layout) {
        default:
            return <DefaultLayout mapList={mapList} isStencil={isStencil} />
    }
}

const endKeys = ["Appointment Cancellation Policy", "Special Instructions","Class Cancellation Policy"]

function DefaultLayout({ mapList, isStencil }: { mapList: any, isStencil: boolean }) {
    return <div className={isStencil ? "stenciled w-full" : " w-full "} style={{ display: "flex", justifyContent: "inherit", alignContent: "inherit", alignItems: "inherit", gap: "inherit", flexDirection: "inherit", flexWrap: "inherit" }}>
        {
            mapList.map((item: any, index: number) => {
                return < RenderFacilityKeyValuePair key={item.key} vkey={item.key} value={item.value} />
            })
        }
    </div>
}

function RenderFacilityKeyValuePair({ vkey, value }: { vkey: string, value: string }) {
    if (!value || value.length == 0)
        return null;
    if (vkey == "Website") {
        return null; // don't include website in facilty information since user is already on site (per Alec + Fred)
    }
    var WebsiteName = "";
    var Phoneno = "";

    if (vkey == "Phone") {
        Phoneno=value.replace("(","").replace(")","").replace(" ","").replace(" ","")
    }

    const cls = (endKeys.includes(vkey)) ? "w-full" : "w-half full-width-mobile";
    return <div style={{ textAlign: "left", padding: "0 .75em .75em 0" }} className={'facility-item flex-column gap-small ' + cls} >
        <h4 className='facility-title'><strong>{vkey}</strong></h4>
        {vkey == "Phone" ? <a className="vp-2  inherit-color fac-desc"  href={"tel:"+Phoneno}>{value}</a>  : vkey == "Website" ? <a href={value} target='_blank' rel="noreferrer" className="vp-2 inherit-color fac-desc"><u>{WebsiteName}</u></a> : vkey == "Phone" ? <a target='_blank' rel="noreferrer" href={`tel:${value.replace(/\D/g, '')}`} className="vp-2  inherit-color" >{value}</a> : <div className="vp-2 fac-desc" dangerouslySetInnerHTML={{__html:value}}></div>}
    </div>

}