import React, { Fragment } from 'react';
import { NoRecordsBanner } from '../OopsBanner';

export default function RenderBusinessDescription({ data, layout }: { data: any, layout: string }) {

       const isStencil = data?.isStenciled === true;
        const style = {
                     maxHeight:"1em",
                     overflow:"hidden",
                     opacity:1
              }
       if(isStencil){
              return <div className="stenciled  flex-column gap-small w-full">
              <div className='w-full stencil-item' style={style}>This page contains basic business information like your address, phone number, and your personal Vagaro URL. This information will show up on your Vagaro page andor custom website. This page contains basic business information like your address, phone number, and your personal Vagaro URL. This information will show up on your Vagaro page andor custom website. This page contains basic business information like your address, phone number, and your personal Vagaro URL. This information will show up on your Vagaro page andor custom website.</div>
              <div className='w-full stencil-item' style={{...style,maxWidth:"80%"}}>This page contains basic business information like your address, phone number, and your personal Vagaro URL. This information will show up on your Vagaro page andor custom website. This page contains basic business information like your address, phone number, and your personal Vagaro URL. This information will show up on your Vagaro page andor custom website. This page contains basic business information like your address, phone number, and your personal Vagaro URL. This information will show up on your Vagaro page andor custom website.</div>
       </div>
       }

       if (!data.covidHighlightMessage || typeof data.covidHighlightMessage == undefined || data.covidHighlightMessage == null || data.covidHighlightMessage == "" || data.covidHighlightMessage.length == 0)
              return <NoRecordsBanner message='Contactless description is not available.' />

       switch (layout) {
              default:
                     return <Fragment><div dangerouslySetInnerHTML={{ __html: (typeof data.covidHighlightMessage != undefined && data.covidHighlightMessage != null && data.covidHighlightMessage != "") ? data.covidHighlightMessage.replace("&nbsp;", "").trim() : "" }}></div></Fragment>
       }

}