import React from 'react';
// Import other necessary dependencies and components
import { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

import { utils } from "../../../utils/Utils";
import StencilRows from "../../Binding/StencilRows";
import { UserContext } from "../../Definitions/UserContext";
import RenderStarRating from "../../Icons/StarRating";
import styles from "./Renderalternatingprofiles.module.css";

import "./altlayoutcards.css"

const avatarSize = "4.5em";
const avatarStyle = { width: avatarSize, height: avatarSize, borderRadius: "50%" };


export default function RenderAlternatingProfiles({ isReversed, staffMember, siteData, isStenciled, serviceList, layout }: { staffMember: any, siteData: UserContext, isStenciled: boolean, serviceList: any, layout: string, isReversed: boolean }) {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 576);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 576);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    // Select a default image URL, for example, the first one in the array
    // if (!staffMember.photoURLForOriginal) {
    //     staffMember.photoURLForOriginal = defaultImageUrls[4];
    // }

    //validate data and replace with empty strings to avoid extra checks during rendering
    if (!staffMember.businessSummary)
        staffMember.businessSummary = "";
    if (!staffMember.spLargePhotoUrl)
        staffMember.spLargePhotoUrl = "";
    if (!staffMember.reviewRank)
        staffMember.reviewRank = 0;
    if (!staffMember.reviewCount)
        staffMember.reviewCount = 0;
    if (!staffMember.firstName)
        staffMember.firstName = "";
    if (!staffMember.lastName)
        staffMember.lastName = "";

    var IsEditor = (window as any).isEditor !== undefined ? (window as any).isEditor : false;
    var BusinessData = utils.MultiLocationData(siteData, siteData.bidHash);

    const rowClass = isReversed ? 'dir-row-reverse' : 'flex-row-normal';


    return (
        <div className={`${styles.profileAltRow} w-full`}>
            <div className={styles.flexRow}>
                <div className={`flex-row-to-column w-full ${rowClass}`} style={{ wordBreak: "break-word", paddingLeft: "8px", paddingRight: "8px", maxWidth: "1300px", margin: "auto"}}>
                    {/* Text content is here. */}
                 
                        <div className={`alt-flex-item  w-full `} >
                            <div className="flex-column alternating-card-text-wrapper text-align-center-mobile">
                                <div className="" style={{ cursor: "pointer" }} onClick={(event) => !IsEditor && (window as any).Staffreview(staffMember, siteData)}>
                                    <div>
                                        <h4 style={{ maxWidth: "500px", wordBreak: "break-word" }}>{staffMember.title}</h4>
                                        <h4 style={{ maxWidth: "500px", wordBreak: "break-word", paddingBottom: "12.944px", fontSize: "38.832px", color: "" }}>{staffMember.firstName} {staffMember.lastName}</h4>

                                        <div className={styles.mobileCenterOthersLeft}>
                                            <div className="flex-row align-center gap-large stencil-item review-icon-rating-staff vp-2" style={{ marginBottom: "12.944px" }}>
                                                <div className="flex-row gap-small text-align-center-mobile">{!isStenciled && <RenderStarRating rating={Math.round(staffMember.reviewRank)} starColor="yellow" isOutlined={false} />}</div>
                                                <div style={{ paddingTop: ".2em" }}>({staffMember.reviewCount})</div>
                                            </div>
                                        </div>
                                        <h3 className="staff-phone stencil-item text-align-center-mobile" style={{ marginTop: "4px", color: "" }}>{staffMember.cell}
                                        </h3>
                                    </div>
                                </div>
                                {isStenciled ?
                                    <StencilRows rowCount={3} />
                                    :
                                    <div className="vp-2 mx-auto mobile-text-align-left " style={{ width: "100%", textAlign: isReversed ? "left" : "left", padding: "20px;" }}>
                                        {staffMember.businessSummary}
                                    </div>
                                }
                                {/* Button section */}
                                <div className="gap-large flex-row-to-column booknowdrp full-width-mobile" style={{ padding: "10px 0px", textAlign: "right" }}>
                                    {staffMember.isShowMessage && <button className="invisible-button v-button v-button-with-icon secondary full-width-mobile center-all" onClick={() => !IsEditor && (window as any).StaffMessage(staffMember, siteData, BusinessData)}>
                                        <i className="fal fa-envelope bicon"></i>Message</button>}

                                    {serviceList > 0 && staffMember.isAllowBook ? staffMember.isServiceTab && staffMember.isClassTab ?
                                        <Dropdown>
                                            <Dropdown.Toggle bsPrefix="invisible-button v-button primary full-width-mobile" id="dropdown-basic">
                                                {staffMember.bookText}<i className="bicon2 fal fa-angle-down"></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => !IsEditor && (window as any).BookStaffSerice(staffMember, BusinessData)} className="no-a">Services</Dropdown.Item>
                                                <Dropdown.Item onClick={() => !IsEditor && (window as any).BookStaffClass(staffMember, BusinessData)} className="no-a">Classes</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        :
                                        <button onClick={() => !IsEditor && (window as any).BookServiceClass(staffMember, BusinessData)} className="invisible-button v-button primary full-width-mobile" >{staffMember.bookText}</button>
                                        : ""}
                                </div>
                            </div>
                        </div>
                        {/* Image is here */}
                        <div className={`alternating-card-image-wrapper`} >
                            {typeof staffMember.photoURLForOriginal != 'undefined' && staffMember.photoURLForOriginal != null && staffMember.photoURLForOriginal != "" &&
                                <div className="aspect-ratio-container">
                                    <div className={`stencil-item staff-image-border ${styles.profileImage}`}
                                    style={{ backgroundImage: `url(${staffMember.photoURLForOriginal})` }}
                                    ></div>
                                </div>
                            }

                            {(typeof staffMember.photoURLForOriginal == 'undefined' || staffMember.photoURLForOriginal == null || staffMember.photoURLForOriginal == "") &&
                                <div className="stencil-item staff-image-border" style={{ overflow: "hidden" }}>
                                    <div className={`${styles.initialPlaceholderWrapper}`}>
                                        <div className={styles.staffInitialPlaceholderImage}>
                                            <div className="review-profile-inner-div intialsname xvg-avatar-inital center-all stencil-item" style={avatarStyle}>
                                                <div className="review-profile-inner vg-avatar-inital-inner ">
                                                    <div className="review-profile-inner-text w-full">{staffMember.spintials}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>}
                        </div>
                    
                </div>
            </div>
        </div>
    )
}