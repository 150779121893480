import $ from "jquery";
import { json } from "react-router-dom";
import { RefreshAccountLogin } from "vagaro-website-rendering/src/components/Utilities/BusinessFrameUtility";
import { Site } from "../../../Utils/Utils";
import { utils } from "vagaro-website-rendering/src/utils/Utils";

var QueryStringValue = "";
var glbIframeParentID = "";
var isSendScrollPostMessage = false;
window.addEventListener('message', listner, false);
//var wbisAndroidMobile = userAgent.match(/Android/i) != null && userAgent.match(/Mobile/i) != null && userAgent.match(/Windows/i) == null && $("#mobile-check").is(":visible");
//var wbiOS = /iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
var isMobile = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(navigator.userAgent);
function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}
function listner(e) {
    if (e != null && e != 'undefined' && e.data != null && e.data != 'undefined') {
        if (isJsonString(e.data || e.originalEvent.data)) {
            var m = JSON.parse(e.data || e.originalEvent.data);
            if (typeof m.object != 'undefined' && m.object == 'getStoratevaluefromParent' && typeof m.storagedetail != 'undefined' && m.storagedetail != null) {
                m.storagedetail.Value = localStorage.getItem(m.storagedetail.Key);
                var json = { object: 'getStoratevalueatChild', storagedetail: m.storagedetail };
                console.log("getStoratevalueatChild fired")
                $('#' + glbIframeParentID).find('iframe')[0].contentWindow.postMessage(JSON.stringify(json), '*');

            }
            else if (typeof m.object != 'undefined' && m.object == 'setStoratevalueintoParent' && typeof m.storagedetail != 'undefined' && m.storagedetail != null) {
                if (m.storagedetail.Value != '') {
                    localStorage.setItem(m.storagedetail.Key, m.storagedetail.Value);
                } else {
                    localStorage.removeItem(m.storagedetail.Key);
                }
            }
            switch (m.eventType) {
                case 'iframeLoaded':
                    if (m.parentID != '') {
                        $('#' + m.parentID).show();
                        $('#' + m.parentID).find("iframe").show();
                        ////Clear Iframe HTML
                        //$('#' + m.parentID).find("iframe").empty();
                        Showloader(false);
                    }
                    break;
                case 'setHeightBuilder': case "setHeightFormBuilder":
                    if (typeof m.height !== 'undefined' && m.height != null) {
                        if (m.parentID != '') {
                            glbIframeParentID = m.parentID;
                            var calHeight = ($(window).height() - $("#wbheader").height() - $("#wbfooter").height());
                            //Set Dynmically Height and Min-Height on Iframe Parent Div
                            var parentDiv = document.getElementById(m.parentID);
                            parentDiv.style.cssText = "height:" + m.height + "px!important;min-height:" + calHeight + "px !important";
                            //Set Dynmically Height and Min-Height on Iframe
                            parentDiv.querySelector("iframe").style.cssText = "height:" + m.height + "px!important;min-height:" + calHeight + "px !important";
                            sendPosition(glbIframeParentID);
                        }
                    }
                    break;
                case 'setFacebookFrame':
                    if (m.parentID != '') {
                        glbIframeParentID = m.parentID;
                        //Set Dynmically Height and Min-Height on Iframe Parent Div
                        var parentDiv = document.getElementById(m.parentID);
                        parentDiv.style.cssText = "height:0px!important;min-height:0px !important";
                        //Set Dynmically Height and Min-Height on Iframe
                        parentDiv.querySelector("iframe").style.cssText = "height:0px!important;min-height:0px !important";
                        sendPosition(glbIframeParentID);
                    }
                    break;

                case "ServiceAddToCart": case "ProductAddToCart": case "WriteReview":
                    SetCookie("WebsiteBuilder", JSON.stringify(m.ResponseData));
                    window.wbc.hideIframe();
                    $("#divVagaroIframe").hide();
                    $("#divcontent").show();
                    if (m.RequestData == "AddtocartContinue" || m.RequestData == "productaddcart" || m.RequestData == "beTheFirstReview") {
                        $(".modal-backdrop").show();
                        $(".vgn-popup").show();
                    }
                    setAfterLoginData();
                    $("#" + m.RequestData)[0].click();
                    break;
                case "GCAddToCart": case 'Bookmarked': case "GalleryLike":
                    SetCookie("WebsiteBuilder", JSON.stringify(m.ResponseData));
                    window.wbc.hideIframe();
                    $("#divVagaroIframe").hide();
                    $("#divcontent").show();
                    setAfterLoginData();
                    $("#" + m.RequestData)[0].click();
                    break;
                case 'PackageAddToCart': case "MemebrshipAddToCart": case "PurchaseGC":
                    SetCookie("WebsiteBuilder", JSON.stringify(m.ResponseData));
                    window.wbc.hideIframe();
                    $("#divVagaroIframe").hide();
                    $("#divcontent").show();
                    $(".modal-backdrop").show();
                    $(".vgn-popup").show();
                    setAfterLoginData();
                    $("#" + m.RequestData)[0].click();
                    break;
                case 'ShoppingBag':
                    window.wbc.hideIframe();
                    $("#divVagaroIframe").hide();
                    $("#divcontent").show();
                    setAfterLoginData();
                    break;
                case 'closeIframe':
                    if (m.parentID != '' && typeof m.parentID != 'undefined') {
                        $('#' + m.parentID).hide();
                    }
                    else {
                        window.wbc.hideIframe();
                        $("#divVagaroIframe").hide();
                    }
                    $("#divcontent").show();
                    $(".modal-backdrop").show();
                    $(".vgn-popup").show();
                    break;
                case 'DealBookNow': case 'WBFormsLogin':
                    SetCookie("WebsiteBuilder", JSON.stringify(m.ResponseData));
                    setAfterLoginData();
                    RefreshCart(m.cartCount);
                    if (m.eventType == "WBFormsLogin") {
                        window.parent.location.reload(true);
                    }
                    break;
                case 'Cart': case 'StaffServiceBook': case 'StaffClassBook': case 'ServiceBook': case 'BookNowPortfolio': case 'WBBusinessClasses': case 'WBBusinessServices':
                    SetCookie("WebsiteBuilder", JSON.stringify(m.ResponseData));
                    if (m.parentID != '' && typeof m.parentID != 'undefined') {
                        $('#' + m.parentID).hide();
                    }
                    else {
                        window.wbc.hideIframe();
                        $("#divVagaroIframe").hide();
                    }
                    if (m.eventType == "ServiceBook" || m.eventType == "StaffServiceBook" || m.eventType == "StaffClassBook") {
                        $("#divcontent").hide();
                    }
                    else {
                        $("#divcontent").show();
                    }
                    setAfterLoginData();
                    RefreshCart(m.cartCount);
                    RefreshClass();
                    break;
                case 'MyAccount':
                    SetCookie("WebsiteBuilder", JSON.stringify(m.ResponseData));
                    if (m.parentID != '' && typeof m.parentID != 'undefined') {
                        $('#' + m.parentID).hide();
                    }
                    else {
                        window.wbc.hideIframe();
                        $("#divVagaroIframe").hide();
                    }
                    if (m.eventType == "ServiceBook" || m.eventType == "StaffServiceBook" || m.eventType == "StaffClassBook") {
                        $("#divcontent").hide();
                    }
                    else {
                        $("#divcontent").show();
                    }
                    setAfterLoginData();
                    RefreshCart(m.cartCount);
                    RefreshMyAccount();
                    break;
                case 'modalPosition':
                    if (m.parentID != '' && typeof m.parentID != 'undefined') {
                        glbIframeParentID = m.parentID
                    }
                    else {
                        glbIframeParentID = "";
                    }
                    break;
                case 'updateCartCount':
                    RefreshCart(m.cartCount);
                    break;
                case 'KeepShopping':
                    if (window.location.href.toLowerCase().indexOf("shopping-bag") >= 0) {
                        window.location.href = window.location.href.toLowerCase().replace("/shopping-bag", "");
                    }
                    else {
                        window.parent.location.reload(true);
                    }
                    break;
                case 'WBLogout': case 'WBBusinessDetailPage': case 'MyAccountVPL': case 'MyaccountLogout':
                    if (window.location.hostname == Site.MySiteName) {
                        RefreshClass();
                        if (m.eventType == "MyAccountVPL") {
                            var accountiframe = $('#divVagaroIframe').find('iframe');
                            accountiframe.attr('src', utils.VURL(null, null, "vagaropaylater"));
                        }
                        else if (m.eventType == "MyaccountLogout") {
                            LogoutmyAccount();
                        }
                        else {
                            var myArrayNew = window.location.pathname.split("/").filter(function (el) {
                                return el != null && el != "";
                            });
                            if (myArrayNew.length >= 1) {
                                let path = window.location.pathname;
                                let IsPreview = path.includes("/preview");
                                if(IsPreview == true){
                                    window.location.href = window.location.origin + "/" + myArrayNew[0] + "/preview";   
                                }
                                else{                                    
                                    window.location.href = window.location.origin + "/" + myArrayNew[0] + "/";
                                }
                            }
                        }
                    }
                    else {
                        window.location.href = "/";
                    }
                    Showloader(false);
                    break;
                case 'passiveAlert':
                    if (m.type == "warning") {
                        passiveLAertSmall(m.message, m.type, "fa-exclamation-triangle");
                        setTimeout(function () {
                            $(".wbvg-passive-alert").removeClass("wbvg-passive-show").hide();
                        }, 1000);
                    } else if (m.type == "alert") {

                    } else if (m.type == "success") {
                        passiveLAertSmall(m.message, m.type, "far fa-check");
                        setTimeout(function () {
                            $(".wbvg-passive-alert").removeClass("wbvg-passive-show").hide();
                        }, 1000);

                    }
                    break;
                case 'modalPositionFull':
                    if (m.parentID != '' && typeof m.parentID != 'undefined') {
                        glbIframeParentID = m.parentID
                    }
                    else {
                        glbIframeParentID = "";
                    }
                    var headerHeight = 0;
                    if ($("#wbheader").hasClass("fixed-above")) {
                        headerHeight = $("#wbheader").outerHeight();
                    } else {
                        headerHeight = 0;
                    }
                    var mobFrameHeieht = ($(window).height() - headerHeight);
                    var parentDiv = document.getElementById(m.parentID);
                    document.body.scrollTop = parentDiv.getBoundingClientRect().top + window.pageYOffset - headerHeight;
                    $("html").addClass("wbMobile");
                    parentDiv.style.cssText = "height:" + mobFrameHeieht + "px!important;min-height:" + mobFrameHeieht + "px !important";
                    parentDiv.querySelector("iframe").style.cssText = "height:" + mobFrameHeieht + "px!important;min-height:" + mobFrameHeieht + "px !important";
                    break;
                case 'modalDatePickerOpen':
                    if (m.parentID != '' && typeof m.parentID != 'undefined') {
                        glbIframeParentID = m.parentID
                    }
                    else {
                        glbIframeParentID = "";
                    }
                    var headerHeight = 0;
                    if ($("#wbheader").hasClass("fixed-above")) {
                        headerHeight = $("#wbheader").outerHeight();
                    } else {
                        headerHeight = 0;
                    }
                    var mobFrameHeieht = ($(window).height() - headerHeight);
                    var parentDiv = document.getElementById(m.parentID);
                    document.body.scrollTop = parentDiv.getBoundingClientRect().top + window.pageYOffset - headerHeight;
                    setTimeout(function () {
                        $("html").addClass("wbMobile");
                    }, 500);
                    parentDiv.style.cssText = "height:" + mobFrameHeieht + "px!important;min-height:" + mobFrameHeieht + "px !important";
                    parentDiv.querySelector("iframe").style.cssText = "height:" + mobFrameHeieht + "px!important;min-height:" + mobFrameHeieht + "px !important";

                    break;
                case 'modalDatePickerclose':
                    if (m.parentID != '' && typeof m.parentID != 'undefined') {
                        glbIframeParentID = m.parentID
                    }
                    else {
                        glbIframeParentID = "";
                    }
                    setTimeout(function () {
                        $("html").removeClass("wbMobile");
                    }, 100);

                    break;
                case 'modalPositionFullRemove':
                    if (m.parentID != '' && typeof m.parentID != 'undefined') {
                        glbIframeParentID = m.parentID
                    }
                    else {
                        glbIframeParentID = "";
                    }

                    setTimeout(function () {
                        $("html").removeClass("wbMobile");
                    }, 100);
                    break;
                case 'openpagenewtab':
                    if (m.newtaburl != '' && typeof m.newtaburl != 'undefined') {
                        window.open(m.newtaburl, '_blank');
                    }
                    else {
                        window.location.href = "/";
                    }
                    break;
                case 'MyAccountScroll':
                    document.body.scrollTop = 0;
                    break;               
            }
        }
    }
}
function RefreshClass() {
    if (window.location.href.toLowerCase().indexOf("classes") >= 0) {
        var classiframe = $('#divclassesiframe').find('iframe');
        classiframe.attr('src', classiframe.attr('src'));
    }
}

function RefreshMyAccount() {
    if (window.location.href.toLowerCase().indexOf("myaccount") >= 0) {
        var MyAccountiframe = $('#divVagaroIframe').find('iframe');
        MyAccountiframe.attr('src', MyAccountiframe.attr('src'));
    }
}

function passiveLAertSmall(alertText, alertType, alertIcon) {
    var $alertTargetDiv = $(".wbvg-passive-alert");
    var $iconDiv = $(".wbvg-passive-alert-icon");
    var $alertTextDiv = $(".wbvg-passive-alert-text");
    $alertTargetDiv.css('top', $("#wbheader").height() + 5).addClass(alertType).show();
    $iconDiv.addClass(alertIcon);
    $alertTextDiv.text(alertText);
    setTimeout(function () {
        $alertTargetDiv.addClass("wbvg-passive-show")
    }, 50)

}
function setAfterLoginData() {
    let cookieVal = getCookie("WebsiteBuilder");
    if (cookieVal != undefined && cookieVal != "" && cookieVal != null) {
        var IsMultilocation = JSON.parse(JSON.parse(localStorage["persist:root"]).BusinessLocation).data
        var CurbusinessID;
        var CurcountryID;
        if (IsMultilocation.length > 1) {
            CurbusinessID = JSON.parse(JSON.parse(localStorage["persist:root"]).BusinessLocation).data.filter((data) => data.isMultilocationGroup == false)[0].encBID
            CurcountryID = JSON.parse(JSON.parse(localStorage["persist:root"]).BusinessLocation).data.filter((data) => data.isMultilocationGroup == false)[0].countryID
        }
        else {
            CurbusinessID = JSON.parse(JSON.parse(localStorage["persist:root"]).BusinessLocation).data[0].encBID;
            CurcountryID = JSON.parse(JSON.parse(localStorage["persist:root"]).BusinessLocation).data[0].countryID;
        }
        RefreshAccountLogin(CurbusinessID, CurcountryID);
    }
}
function SetCookie(cname, cvalue, minutes) {
    var d = new Date();
    d.setTime(d.getTime() + (minutes * 60000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
function getCookie(c_name) {
    if (typeof ($.cookie) != "undefined")
        return $.cookie(c_name)
    else {
        var i, x, y, ARRcookies = document.cookie.split(";");
        for (i = 0; i < ARRcookies.length; i++) {
            x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
            y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
            x = x.replace(/^\s+|\s+$/g, "");
            if (x == c_name) {
                return unescape(y);
            }
        }
    }
}
//Get parameter value
function getQueryStringData(param) {
    var splitArr = QueryStringValue.split('&'), res;
    for (var i = 0; i < splitArr.length; i += 1) {
        var paramName = splitArr[i],
            sParameterName = (paramName || '').split(/=(.*)/s);
        if (sParameterName[0] === param) {
            res = sParameterName[1];
        }
    }
    return ((typeof res != 'undefined' && res != null && res != "") ? decodeURIComponent(res) : '');
}
function Showloader(val) {
    if ($(".wbloader") == undefined || $(".wbloader") == 'undefined')
        return;
    if (val == true) {
        $(".wbloader").css("display", "block");
    }
    else {

        $(".wbloader").css("display", "none");
    }
}
$(window).on("scroll", function (event) {
    if (!(isMobile)) {
        sendPosition(glbIframeParentID);
    }
    if (isSendScrollPostMessage &&
        $(document.scrollingElement).scrollTop() + $(document.scrollingElement).height() + $("#wbfooter").height() + 100 > document.scrollingElement.scrollHeight) {
        document.getElementById('iFrame_divVagaroIframe').contentWindow.postMessage('{"eventType": "lazyloadFromWebsiteBuilder"}', getSourcePopup(glbIframeParentID));
    }
});
function sendPosition(glbIframeParentID) {
    if (glbIframeParentID != '' && typeof glbIframeParentID != 'undefined') {
        if (document.scrollingElement != null) {
                var currentScrollPositionofParent = document.scrollingElement.scrollTop;
                var iframestartposition = $('#' + glbIframeParentID).find('iframe').offset().top;
                var modalTop = currentScrollPositionofParent - iframestartposition
                var modalHeight = window.screen.availHeight;
                if (modalTop < 0) {
                    modalTop = 0;
                }
                var viewportHeightW = window.innerHeight;
                var divHeight = 148 / 2;
                var scrollPositionW = currentScrollPositionofParent;
                var newTopW = (viewportHeightW - divHeight) / 2 + scrollPositionW;
                $('#' + glbIframeParentID).find('iframe')[0].contentWindow.postMessage('{"eventType": "modalPosition", "modalTop" : ' + modalTop + ',"modalHeight" : "' + modalHeight + '", "getCenterPoint" : "' + newTopW + '", "viewportHeightW" : "' + viewportHeightW + '", "scrollPositionW" : "' + scrollPositionW + '"}', getSourcePopup(glbIframeParentID));
            }
        }
    }
var _targetpopup = null;
function getSourcePopup(glbIframeParentID) {
    if (_targetpopup == null) {
        var targetRegexPopup = /^(https*:\/\/[^\/]*).*$/g;
        var match = targetRegexPopup.exec($('#' + glbIframeParentID).find('iframe').attr('src'));
        if (match) {
            _targetpopup = match[1];
        }
    }
    return _targetpopup;
}
$.fn.WebsiteBuilderCommunication = function (options, onReadyCallback) {
    var o = $.extend({}, $.fn.WebsiteBuilderCommunication.defaults, options);
    var $frame = this;
    $frame.post = o.post;
    $frame.destroy = function () {
    }
    $frame.websitebuilder = {
        getSize: function () {
            _postMessage('{"eventType": "getSize"}');
        },
        getHeight: function () {
            _postMessage('{"eventType": "getHeight"}');
        },
        addStyle: function (style, parentID, businessID, cookieobject) {
            _postMessage('{"eventType": "addStyle", "style" : ' + JSON.stringify(style) + ',"parentID" : "' + parentID + '","businessID" : ' + JSON.stringify(businessID) + ',"cookieobject" : ' + JSON.stringify(cookieobject) + '}');
        },
    };
    return $frame.each(function () {
        createPopup(o.modal);
    });
    function createPopup(modal) {
        $frame.html('');
        var glbbackground = $("#dvglbstyle").find("#glbbackground");
        glbbackground = glbbackground.css("background-color");
        var $iframe = $('<iframe min-height="80vh" class="b-iframe" ' + o.iframeAttr + ' id="iFrame_' + $frame.attr('id') + '" style="display:none;background-color:' + glbbackground + '"></iframe>');
        if (modal == true) {
            var $popup = $("<div id='frame_apgProgress_" + $frame[0].id + "' style='display:none;'></div>" +
                "<div style='position: relative; ' id='divinnertop_up'>" +
                "<div id='ccfram_backgroundElement' style='position: fixed; left: 0px; top: 0px; width: 100%; height: 100%;' class='ModalPopupBG'></div>" +
                "<div style='color: white; font-family: \"Lucida Grande\",\"Arial\"; font-size: 11px; position: absolute; text-align: center;width: 100%; z-index: 2001; top: 6px; position:relative;'></div>" +
                "<div style='width: auto; height: auto; margin: 0 -7px;z-index:9999; position:relative' class='contentContainer'>" +
                "</div>" +
                "</div>");

            $popup.find('.contentContainer').append($iframe);
            $popup.appendTo($frame);
        }
        else {
            $iframe.appendTo($frame);
        }
        $frame.addClass("vgFrameWrapper");
        $frame.css("min-height", "80vh");

        $frame.data('iframe_id', $iframe.attr('id'));
        $iframe.attr('src', o.loadUrl);
        triggerCall(o.receivers.onOpening);
        $iframe.on("load", function (e) {
            var cssStyle = "";
            var businessID = "";
            var cookieobject = {};
            var parentID = $iframe.parent().attr('id');
            cssStyle = wbc.getStyle();
            var IsMultilocation = JSON.parse(JSON.parse(localStorage["persist:root"]).BusinessLocation).data
            if (IsMultilocation.length > 1) {
                businessID = JSON.parse(JSON.parse(localStorage["persist:root"]).BusinessLocation).data.filter((data) => data.isMultilocationGroup == false)[0].businessID
            }
            else {
                businessID = JSON.parse(JSON.parse(localStorage["persist:root"]).BusinessLocation).data[0].businessID;
            }
            cookieobject.vdsid = localStorage.getItem("vdsid");
            wbc.frame.websitebuilder.addStyle(cssStyle, parentID, businessID, cookieobject);
            window.setTimeout(() => {
                ShowFrame(modal);
                if (typeof onReadyCallback == 'function') {
                    onReadyCallback();
                }
                sendPosition($frame.attr('id'));
            }, 1000);

        });
    }
    function ShowFrame(modal) {
        o.loaded = true;
        $frame.find('iframe').css("display", "block");
        Showloader(false);
        wbc.frame.websitebuilder.getHeight();
        if (modal == true) {
            ShowPopup(o.height, o.width, $frame.attr('id'));
        } else {
            $('#' + wbc.contentContainer).show();
        }
        if (wbc.loader != null) wbc.loader(false);
    }
    function ShowPopup(frameHeight, frameWidth, contentContainer) {
        var divTopLeftarray, divTop, divLeft, viewPortHeight;
        var divtop_up = document.getElementById(contentContainer);
        divTopLeftarray = getTopLeftPosition(frameHeight, frameWidth).split('|');
        divTop = divTopLeftarray[0];
        divLeft = divTopLeftarray[1];
        viewPortHeight = divTopLeftarray[2];
        divtop_up.style.left = divLeft - 20 + 'px';
        var scrollTop;
        if (document.documentElement.scrollTop != document.body.scrollTop) {
            scrollTop = document.documentElement.scrollTop + document.body.scrollTop
        } else {
            scrollTop = document.body.scrollTop;
        }
        if (scrollTop > 0)
            divTop = parseInt(divTop) + scrollTop;
        divTop = divTop - 20;
        divtop_up.style.top = divTop + 'px';
        divtop_up.style.position = 'absolute';
        divtop_up.width = 'auto';
        divtop_up.height = 'auto';
        divtop_up.style.zIndex = 100001;
        divtop_up.style.display = "";
    }
    function getTopLeftPosition(frameHeight, frameWidth) {
        var viewportheightarray, viewportheight, viewportwidth, divTop, divLeft;
        viewportheightarray = getViewPortHeightWidth().split('|');
        viewportheight = viewportheightarray[0];
        viewportwidth = viewportheightarray[1];
        divTop = (viewportheight - frameHeight) / 2;
        if (divTop < 0)
            divTop = 1;
        divLeft = (viewportwidth - frameWidth) / 2;
        return divTop + '|' + divLeft + '|' + viewportheight;
    }
    function getViewPortHeightWidth() {
        //****************************************************************************************************************
        // Viewportwidth and height
        var viewportwidth;
        var viewportheight;

        // the more standards compliant browsers (mozilla/netscape/opera/IE7) use window.innerWidth and window.innerHeight
        if (typeof window.innerWidth != 'undefined') {
            viewportwidth = window.innerWidth,
                viewportheight = window.innerHeight
        }
        // IE6 in standards compliant mode (i.e. with a valid doctype as the first line in the document)
        else if (typeof document.documentElement != 'undefined'
            && typeof document.documentElement.clientWidth !=
            'undefined' && document.documentElement.clientWidth != 0) {
            viewportwidth = document.documentElement.clientWidth,
                viewportheight = document.documentElement.clientHeight
        }
        // older versions of IE
        else {
            viewportwidth = document.getElementsByTagName('body')[0].clientWidth,
                viewportheight = document.getElementsByTagName('body')[0].clientHeight
        }
        return viewportheight + '|' + viewportwidth;
    }
    function _postMessage(message) {
        try {
            if (document.getElementById($frame.data('iframe_id')) != undefined || document.getElementById($frame.data('iframe_id')) != null) {
                document.getElementById($frame.data('iframe_id')).contentWindow.postMessage(message, getSource());
            }
        }
        catch (e) {
            console.log(e);
        }
    }
    var _target = null;
    function getSource() {
        if (_target == null) {
            var targetRegex = /^(https*:\/\/[^\/]*).*$/g;
            var match = targetRegex.exec($('#' + $frame.data('iframe_id')).attr('src'));
            if (match) {
                _target = match[1];
            }
        }
        return _target;
    }
    function triggerCall(func, arg) {
        $.isFunction(func) && func.call(this, arg);
    }
}

$.fn.WebsiteBuilderCommunication.defaults = {
    height: 375
    , width: 480
    , loaded: false
    , appendTo: 'body'
    , iframeAttr: 'scrolling="no" frameborder="0"'
    , loadUrl: ''
    , modal: true
    , modalClose: true
    , opacity: 1
    , position: ['auto', 'auto'] // x, y,
    , positionStyle: 'absolute'// absolute or fixed
    , scrollBar: true
    , speed: 250 // open & close speed
    , transition: 'fadeIn' //transitions: fadeIn, slideDown, slideIn, slideBack
    , transitionClose: false
    , zIndex: 1000001 // popup gets z-index 9999, modal overlay 9998
    , showLog: true
    , afterCallBackHandle: 0 // 0 -- handle error in zenswipe, 1 -- do not handle error zenswipe
    , iswebview: false
    , ignorePro: false
    , isfullwidth: false,
    receivers: {
        onGetSize: function (m) { if (typeof (onGetSizeCallback) == 'function') onGetSizeCallback(m); }
    }
};
(function () {
    window.wbc = {
        frameUrl: null,
        frame: null,
        contentContainer: null,
        modal: false,
        height: 375,
        width: 480,
        displayLoading: function (progressor) {
            this.loader(true);
        },
        hideLoading: function (progressor) {
            this.loader(false);
        },
        getSize: function (height, width) {
            websitebuilder.getSize();
        },
        hideIframe: function () {
            $('#iFrame_divVagaroIframe').hide();
        },
        getStyle: function () {
            var styleobject = {};
            var gblstyle = $("#dvglbstyle");
            var primarybutton = gblstyle.find("#btnprimarybutton");
            var secondorybutton = gblstyle.find("#btnsecondary");
            var primarybuttonHover = gblstyle.find("#btnprimarybuttonhover");
            var secondorybuttonHover = gblstyle.find("#btnsecondarybuttonhover");
            var lblstyle = gblstyle.find("#txtfontfamily");
            var btnrating = gblstyle.find("#btnrating");
            var glbbackground = gblstyle.find("#glbbackground");
            var glbHeadFonts = gblstyle.find("#glbHeadingFont");
            styleobject.primarybuttoncolor = primarybutton.css("color");
            styleobject.primarybuttonbackgroundcolor = primarybutton.css("background-color");
            styleobject.primarybuttonHoverbackgroundcolor = primarybuttonHover.css("background-color");
            styleobject.primarybuttonborderradius = primarybutton.css("border-radius");
            styleobject.primarybuttonboxshadow = primarybutton.css("box-shadow");
            styleobject.secondorybuttonbuttoncolor = secondorybutton.css("color");
            styleobject.secondorybuttonbuttonbackgroundcolor = secondorybutton.css("background-color");
            styleobject.secondorybuttonbuttonHoverbackgroundcolor = secondorybuttonHover.css("background-color");
            styleobject.secondorybuttonbuttonborderradius = secondorybutton.css("border-radius");
            styleobject.secondorybuttonbuttonboxshadow = secondorybutton.css("box-shadow");
            styleobject.lblstylefontfamily = 'proxima-nova';
            styleobject.lblstylecolor = lblstyle.css("color");
            styleobject.lblstylefontweight = lblstyle.css("font-weight");
            styleobject.lblstylefontsize = lblstyle.css("font-size");
            styleobject.btnratingcolor = btnrating.css("color");
            styleobject.glbbackgroundcolor = glbbackground.css("background-color");
            styleobject.glbHeadingFonts = 'proxima-nova';
            return styleobject;
        },
        openVagaroPage: function (contentContainer, url, onReadyCallback) {
            Showloader(true);
            document.body.classList.add("hide-cta")
            this.modal = false;
            this.contentContainer = contentContainer;
            var calHeight = ($(window).height() - $("#wbheader").height() - $("#wbfooter").height());
            var dynamicHeight = (isNaN(calHeight) ? $(window).height() : calHeight);
            QueryStringValue = url;
            var wbCookieVal = getCookie("WebsiteBuilder");
            var eventType = getQueryStringData('Event');

            if (eventType == 'MyAccount') {
                isSendScrollPostMessage = true;
            }
            else {
                isSendScrollPostMessage = false;
            }

            if (eventType == "FaceBookShare") {
                wbc.frame = $('#' + wbc.contentContainer).WebsiteBuilderCommunication({ loadUrl: url, modal: window.wbc.modal, height: dynamicHeight, width: "100%" }, onReadyCallback);
            }
            else if (wbCookieVal != undefined && wbCookieVal != "" && wbCookieVal != null) {
                eventType = getQueryStringData('Event');
                if (eventType == "ServiceAddToCart" || eventType == "PackageAddToCart" || eventType == "ProductAddToCart" || eventType == "MemebrshipAddToCart" || eventType == "PurchaseGC") {
                    var addtocart = getQueryStringData('Data');
                    $("#" + addtocart).trigger('click');
                }
                else if (eventType == "ServiceBook" || eventType == "StaffServiceBook" || eventType == "StaffClassBook" || eventType == "GCAddToCart" || eventType == "ShoppingBag" || eventType == "BookNowPortfolio" || eventType == "DealBookNow" || eventType == "MyAccount" || eventType == "WBBusinessClasses" || eventType == "WBBusinessServices") {
                    $('#' + wbc.contentContainer).show();
                    $("#divcontent").hide();
                    wbc.frame = $('#' + wbc.contentContainer).WebsiteBuilderCommunication({ loadUrl: url, modal: window.wbc.modal, height: dynamicHeight, width: "100%" }, onReadyCallback);
                }
            }
            else {
                $('#' + wbc.contentContainer).show();
                $("#divcontent").hide();
                wbc.frame = $('#' + wbc.contentContainer).WebsiteBuilderCommunication({ loadUrl: url, modal: window.wbc.modal, height: dynamicHeight, width: "100%" }, onReadyCallback);
            }
        },
        GotoMultilocation: function () {
            glbIframeParentID = "";
        }
    };

})();
//$(document).ready(function () {
//    var autoCookieVal = getCookie("WebsiteBuilder");
//    if (typeof autoCookieVal === 'undefined' || autoCookieVal == "" || autoCookieVal == null) {
//        $('body').append('<div id=divAutoLoginIframe" style="display:none;"><iframe class="b-iframe" id="iFrame_divAutoLoginIframe" style="display:none;"></iframe></div>');
//        $("#iFrame_divAutoLoginIframe").attr("src", Site.SiteName + "login?Type=Builder&Event=Cart&Data=");
//        $("#iFrame_divAutoLoginIframe").on("load", function (e) {
//            $("#divAutoLoginIframe").remove();
//        });
//    }
//});