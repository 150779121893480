import React, { Component, Fragment } from 'react';
import { AsyncAjaxPost } from "./AsyncAjaxService";
import { Helmet, HelmetProvider } from "react-helmet-async";
import 'vagaro-website-rendering/src/components/Styles/page.content.css'
import BindDataToComponents from "vagaro-website-rendering/src/components/Binding/BindDataToComponents"
import  {AnimationHandler} from "vagaro-website-rendering/src/components/Binding/ScreenStateHandler"
import "../assets/js/WebsiteBuilder/BuilderCommunication.js"
import { AsyncAjaxGet } from './AsyncAjaxServiceV2';
import GetProductInfoFromURL from 'vagaro-website-rendering/src/components/Utilities/GetProductInfoFromURL';
import VagaroIframe from 'vagaro-website-rendering/src/components/Utilities/VagaoIframe'
import { utils } from 'vagaro-website-rendering/src/utils/Utils';
import $ from "jquery";
import {  Site } from "../Utils/Utils"
import {GetItemBundleInfoFromData } from 'vagaro-website-rendering/src/components/BusinessControls/RenderProductDetails'
import {ProductDetailsHTML} from 'vagaro-website-rendering/src/components/Definitions/ProductDetailsHTML';
import {hasAcceptedValue, getCookie,GetTrackingCookieName } from 'vagaro-website-rendering/src/components/Utilities/CookieUtils';

export class Pages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: null,
            metaInformation: {
                title: "",
                description: "",
                canonical: 'http://example.com/path/to/page',
                meta: {
                    charset: 'utf-8',
                    name: {
                        keywords: 'react,meta,document,html,tags'
                    }
                }
            },
            generalSetting: {
                googleanalyticsId: "",
                headCode: "",
                webclip: "",
                footerCode: "",
                faviconLogo: ""
            },
            accesstoken: props.accesstoken,
            multilocationdata: props.multilocationdata,
            merchantId: 0,
            miscData: "",
            VGURL: props.VGURL
        }
        this.getData = this.getData.bind(this);

   
    }

    componentDidMount() {
    
        this.getData();
    }

    componentDidUpdate(prevProps, prevState) {
      
        if (prevState.content == null && this.state.content != null) {
            var bid = this.state.content.encBID;
            const accToken = this.state.accesstoken;
            const isMysite = this.state.content.isMySite;
            var sitebid = this.state.content.encSiteBID;

            const multilocationdata = this.state.multilocationdata;
            var bidHash = this.state.content.encBIDHash;           
            const isDuplicateAllLocationPage = this.state.content.isDuplicateAllLocationPage;
            var aliasName = this.state.content.aliasName;

            if (!utils.Validate(aliasName) && isDuplicateAllLocationPage) {
                const items = JSON.parse(localStorage.getItem('location'));
                if (items) {
                    if (utils.Validate(items.bid) && utils.Validate(items.bidHash)) {
                        bid = items.bid;
                        bidHash = items.bidHash;
                    }
                }
            }

            //use temporary ids for now   
                if (typeof accToken != "undefined" && accToken != null && accToken != "") {
                let element = document.getElementById('divVagaroIframe');
                if (element != null) {
                    let path = window.location.pathname;
                    let shoppingBag = path.indexOf("/shopping-bag");
                    let myAccount = path.indexOf("/myaccount");
                    if (shoppingBag >= 0) {
                        var widgetCookieString = "";
                        var vsdId = localStorage.getItem("vdsid")
                        if (vsdId != null && vsdId != '' && vsdId != undefined) {
                            widgetCookieString = "&wgVal=" + vsdId;
                        }
                        window.wbc.openVagaroPage("divVagaroIframe", Site.SiteName + this.state.content.vagaroURL + "/shopping-bag?Type=Builder&Event=ShoppingBag" + widgetCookieString)
                    }
                    if (myAccount >= 0) {
                        var widgetCookieString = "";
                        var vsdId = localStorage.getItem("vdsid")
                        if (vsdId != null && vsdId != '' && vsdId != undefined) {
                            widgetCookieString = "&wgVal=" + vsdId;
                        }
                        window.wbc.openVagaroPage("divVagaroIframe", utils.VURL(null, null, "myaccount") + "&encData=" + bid + widgetCookieString);
                    }
                }                
                    BindDataToComponents(bid, accToken, multilocationdata, bidHash, this.props.accessgenerationtime + 40 * 60000, isMysite, undefined, this.state.VGURL, isDuplicateAllLocationPage, sitebid);                
            }
        }
    }

    async getData() {
        utils.Loaderstart();
        let contentdata = {};
        let misc = {};
        let deniedStyle = "";
        let denyList = "";
        let metaInformationdata = [];
        let generalSettingdata = [];
        const Requestdata = {};
        let merchantId = 0;
      
        // seperate page for every product, to accomplish this the product details page needs to be routed specially
        //for product details page ignore directories as this will be used to dynamically get content inside component. we want to keep the "/" instead of query params for SEO and usabilty;
        let path = window.location.pathname;
        let detailsIndex = path.indexOf("/product-details/");  
        //For Website is Preview or Publish
        let IsPreview = path.includes("/preview");
        if (detailsIndex > -1) {
            //load the home page information then later replace with product html
            path = path.substring(0, detailsIndex);
        }

        Requestdata.PageUrl = path;
        Requestdata.VGURL = this.state.VGURL;
        Requestdata.IsPreview = IsPreview == true ? true : false;
        Requestdata.businessLocation = this.state.multilocationdata;
        await AsyncAjaxPost(
            "/Pages",
            Requestdata,
            function onSuccess(data) {  

                try {                 
                    if (data != null && data.data != null && data.status == 200) {                           
                        contentdata = data.data;
                        $(".wbloader").css("display", "none");
                        if (contentdata.isNewCustomWebSite == true && contentdata.isActive == true) {
                            if (!contentdata.isPageAvailable) {
                                if (!contentdata.isMySite) {
                                    window.location.href = "/";
                                }
                                else {
                                    if (Requestdata.PageUrl != "/") {
                                        if (typeof Requestdata.VGURL != 'undefined' && Requestdata.VGURL != null && Requestdata.VGURL != '') {
                                            window.location.href = window.location.origin + "/" + Requestdata.VGURL + "/"
                                        }
                                    }
                                }
                            }
                            else {
                                if (contentdata.isNewCustomWebSite) {
                                    merchantId = contentdata.merchantId;
                                    try {
                                        var DeniedPages = [];                                        
                                        if (typeof contentdata.pageVCList != 'undefined' && contentdata.pageVCList != null && contentdata.pageVCList != "") {
                                            var dataobj = $.parseJSON(contentdata.pageVCList);
                                            if (typeof dataobj != 'undefined' && dataobj != null && dataobj != "") {
                                                dataobj.map((data, i) => {                                                    
                                                    var component = data.component;
                                                    if (typeof component != 'undefined' && component != null && component != "" && component.length > 0) {
                                                        for (var icontent = 0; icontent < component.length; icontent++) {                                                            
                                                            var controltype = component[icontent].Type;
                                                            if (controltype == "businessBookNow" && contentdata.isAllowBusinessData.isShowBookNowTab) {
                                                                break;
                                                            }
                                                            if (controltype == "businessClass" && contentdata.isAllowBusinessData.isShowClassTab) {
                                                                break;
                                                            }
                                                            if (controltype == "businessServices" && contentdata.isAllowBusinessData.isShowServiceTab) {
                                                                break;
                                                            }
                                                            else if (controltype == "businessProducts" && contentdata.isAllowBusinessData.isShowProductTab) {
                                                                break;
                                                            }
                                                            else if (controltype == "businessPackages" && contentdata.isAllowBusinessData.isPackageShow) {
                                                                break;
                                                            }
                                                            else if (controltype == "businessMemberships" && contentdata.isAllowBusinessData.isShowMembership) {
                                                                break;
                                                            }
                                                            else if (controltype == "businessGiftCertificates" && contentdata.isAllowBusinessData.isShowGCTab) {
                                                                break;
                                                            }
                                                            else if (controltype == "businessDailyDeals" && contentdata.isAllowBusinessData.isShowDailyDeals) {
                                                                break;
                                                            }
                                                            else if (controltype == "businessDescription" || controltype == "businessReviews" || controltype == "busienssStaff" || controltype == "businessGallery" || controltype == "businessSocialLinks" || controltype == "businessHours" || controltype == "businessFacility" || controltype == "businessMap" || controltype == "businessreviewpercentage" || controltype == "businessMessage" || controltype == "businessCall" || controltype == "businessContactLess" || controltype == "businessCart" || controltype == "myaccount") {
                                                                break;
                                                            }
                                                            if ((icontent + 1) == component.length) {
                                                                DeniedPages.push(data.URLSlug);
                                                                denyList += (denyList.length>0)?(","+data.URLSlug):data.URLSlug;
                                                                //hide pages via inline style
                                                                deniedStyle += "#" + data.URLSlug + "{display:none !important;}";
                                                                deniedStyle += "#mobile-" + data.URLSlug + "{display:none !important;}";
                                                            }
                                                        }
                                                    }
                                                });

                                                if (!contentdata.isMySite) {
                                                    let Pageurl = Requestdata.PageUrl.slice(1);
                                                    let PageDenied = DeniedPages.filter(
                                                        (col) => col == Pageurl
                                                    );
                                                    if (PageDenied.length > 0) {
                                                        contentdata = [];
                                                        window.location.href = "/";
                                                    }
                                                }
                                                else {
                                                    let MysitePageurl = Requestdata.PageUrl.replace("/" + Requestdata.VGURL, "");
                                                    let Pageurl = MysitePageurl.slice(1);
                                                    let PageDenied = DeniedPages.filter(
                                                        (col) => col == Pageurl
                                                    );
                                                    if (PageDenied.length > 0) {
                                                        contentdata = [];
                                                        if (Requestdata.PageUrl != "/") {
                                                            if (typeof Requestdata.VGURL != 'undefined' && Requestdata.VGURL != null && Requestdata.VGURL != '') {
                                                                window.location.href = window.location.origin + "/" + Requestdata.VGURL + "/"
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    catch {

                                    }
                                    if (typeof contentdata.generalSettings != 'undefined' && contentdata.generalSettings != null) {
                                        generalSettingdata = {
                                            googleanalyticsId: contentdata.generalSettings.googleanalyticsId,
                                            headCode: contentdata.generalSettings.headCode,
                                            webClip: contentdata.generalSettings.webClip,
                                            footerCode: contentdata.generalSettings.footerCode,
                                            faviconLogo: contentdata.generalSettings.faviconLogo,
                                        }
                                    }

                                    metaInformationdata = {
                                        title: typeof contentdata.titleTag != 'undefined' && contentdata.titleTag != null && contentdata.titleTag != "" ? contentdata.titleTag : contentdata.businessName,
                                        description: typeof contentdata.description != 'undefined' && contentdata.description != null && contentdata.description != "" ? contentdata.description : "",
                                        canonical: 'http://example.com/path/to/page',
                                        meta: {
                                            charset: 'utf-8',
                                            name: {
                                                keywords: 'react,meta,document,html,tags'
                                            }
                                        },
                                        openGraphURL: typeof contentdata.openGraphURL != 'undefined' && contentdata.openGraphURL != null && contentdata.openGraphURL != "" ? contentdata.openGraphURL : "",
                                    }
                                    if (typeof contentdata.group != 'undefined' && contentdata.group != null && contentdata.group != '') {
                                        localStorage.setItem('Group', contentdata.group);
                                    }
                                }
                                else {
                                    if (typeof contentdata.vagaroURL != 'undefined' && contentdata.vagaroURL != null && contentdata.vagaroURL != '') {
                                        window.location.href = Site.SiteName + contentdata.vagaroURL;
                                    }
                                }
                            }
                        }
                        else {
                            if (typeof contentdata.vagaroURL != 'undefined' && contentdata.vagaroURL != null && contentdata.vagaroURL != '') {
                                window.location.href = Site.SiteName + contentdata.vagaroURL;
                            }
                        }
                    }
                    else {
               
                        contentdata = [];
                        metaInformationdata = [];
                        generalSettingdata = [];
                        merchantId = 0;
                    }

                }
                catch (error) {

                    console.warn(error);
                }
            },
            function OnError(error) {
                console.warn(error);
            }
        )
  
   
        //if we are using Product details, make a seperate call to get the product information and update SEO. 
        if (detailsIndex >= 0) {
         
            const presult = GetProductInfoFromURL("",this.state.multilocationdata);
            const endPoint = "merchants/" + presult.businessId + "/products/" + presult.productId;

            let product = {};
      
            await AsyncAjaxGet(
                endPoint,
                this.state.accesstoken,
                function onSuccess(data) {
            

                    try {
                        if (data != null && data.data != null && data.status >= 200 && data.status < 300) {
                            product = data.data.data;
            
                           
                            metaInformationdata.title = typeof product.name != 'undefined' && product.name != null && product.name != "" ? product.name : "";
                            metaInformationdata.description = typeof product.description != 'undefined' && product.description != null && product.description != "" ? product.description : "";

                        }
                    }
                    catch (error) {
                        console.warn("product", error);
                    }
                },
                function OnError(error) {
                    console.warn("product", error);
                }
            )

            if(presult.isBundle){
           
                const bEndProduct = "merchants/" + presult.businessId + "/products/bundle/" + presult.productId;

                await AsyncAjaxGet(
                    bEndProduct,
                    this.state.accesstoken,
                    function onSuccess(bndlProduct){

                        const bundleInfo = GetItemBundleInfoFromData(bndlProduct.data.data);
     
                        product.bundleInfo = bundleInfo;

                    },
                    function OnError(error) {
                        console.warn("product", error);
                    }

                )




            }
   
            misc.product = JSON.stringify(product);
            contentdata.content = ProductDetailsHTML;
        }
     
     
      
        misc.deniedStyle = deniedStyle;
        misc.denyList = denyList;
  
        this.setState({ content: contentdata, metaInformation: metaInformationdata, generalSetting: generalSettingdata, merchantId: merchantId, miscData: misc });
    }




    render() {

        const { content, metaInformation, generalSetting } = this.state;
        const rootName = Site.MySiteName;
        const wLocation = window.location.href;

        const hasCookieBanner = content?.footer?.includes("dismiss-cookie-banner");
        const acceptedTracking = !hasCookieBanner || getCookie(GetTrackingCookieName()) == hasAcceptedValue;



        //get font includes from style content

        let siteStyle = content?.styleContent??""
        let includeSplit = siteStyle.split("/*vwb-font-includes");
        const fontIncludes = includeSplit.length > 1 ? includeSplit[1].split(","): [];
        if(includeSplit.length>1)
            siteStyle = includeSplit[0];

        const index = wLocation.indexOf(rootName);
        let base = "/";
        if (index > 0) {
            if (utils.Validate(content) && utils.Validate(content.aliasName)) {
                base = window.location.origin + "/" + this.state.VGURL + "/" + content.aliasName + "/";
            }
            else {
                base = window.location.origin + "/" + this.state.VGURL + "/";
            }
        }
        else {
            if (utils.Validate(content) && utils.Validate(content.aliasName)) {
                base = window.location.origin + "/" + content.aliasName + "/";
            }
        }
     

        let navHead = content?.header??"";
        let annoucement = "";

        if(navHead){
            let split = navHead.split("<!--split-->");

            if(split.length>1){

                navHead = split[1];
                annoucement = split[0];
            }
        }




        return (
            <Fragment>
                {
                    typeof content != 'undefined' && content != null && typeof content.content != 'undefined' && content.content != null &&
                    <>
                    <HelmetProvider>
                        {this.state.generalSetting.headCode &&<span id="head-code" style={{display:"none"}} dangerouslySetInnerHTML={{__html:this.state.generalSetting.headCode}}></span>}
                        {this.state.miscData.deniedStyle &&<style>{this.state.miscData.deniedStyle}</style>}
                        {this.state.miscData.denyList &&
                           <span id="ignored-pages" style={{display:"none"}}>{this.state.miscData.denyList}</span>
                        }
                        <div className='fixed-above fixed-container' dangerouslySetInnerHTML={{__html:annoucement}}></div>
                        <header id="wbheader" className={"wbheader " +(navHead&&navHead.includes("fixed-above")?"fixed-above":"")} dangerouslySetInnerHTML={{ __html: navHead }}></header>
                            {
                                //include data in raw html so API does not need to be called again for product details page
                                this.state.miscData?.product&& this.state.miscData.product.length > 4 && <div style={{ display: "none" }} id="data-details" {...{ "response-data": this.state.miscData.product }} ></div>
                            }
                        <div className="application">
                            <Helmet>
                           
                                <base href={base} />
                                <meta name="robots" content={ (this.state.metaInformation.noindex||window.location.href.endsWith("/preview")||window.location.href.endsWith("/preview/"))?"noindex,nofollow": "index"} />
                                <meta charSet="utf-8" />
                                <meta id="meta-debug" data={hasCookieBanner.toString() }></meta>
                                <title>{metaInformation.title}</title>
                                <meta name="description" content={metaInformation.description}></meta>
                                {
                                //for now use actual url for canonical and og: url
                                }
                                <link rel="canonical" href={window.location.href} />
                                {this.state.generalSetting.webClip && <link rel="apple-touch-icon" sizes="256x256" href={content.cdnurl + "Websitebuilder/" + generalSetting.webClip}></link>}
                                {generalSetting.faviconLogo&&generalSetting.faviconLogo.length>1&&<link rel="icon" type="image/x-icon" href={content.cdnurl + "Websitebuilder/" + generalSetting.faviconLogo}></link>}
                                {generalSetting.faviconLogo&& generalSetting.faviconLogo.length>1 && <link rel="shortcut icon" type="image/x-icon" href={content.cdnurl + "Websitebuilder/" + generalSetting.faviconLogo}></link>}
                                {
                                    fontIncludes.map((f,index)=>{
                                        if(f.length>10){
                                            return <link rel="stylesheet" key={index} href={f}/>
                                        }
                                        else
                                            return null;

                                    })
                                }


                                {
                                //Open Graph
                                }
                                <meta property="og:title" content={content.siteName + " - "+ metaInformation.title} />
                                <meta property="og:type" content="website" />
                                {metaInformation.openGraphURL&& <meta property="og:image" content={metaInformation.openGraphURL} />  }
                                <meta property="og:url" content={window.location.href} />
                       
                                {//Google Analytics 
                                this.state.generalSetting.googleanalyticsId&&
                                    
                                    <>
                           
                                    {
                                    acceptedTracking&&
                                    <>
                                    <script async src={"https://www.googletagmanager.com/gtag/js?id="+this.state.generalSetting.googleanalyticsId}></script>
                                
                                    <script>
                                        {`
                                            window.dataLayer = window.dataLayer || [];
                                            function gtag(){window.dataLayer.push(arguments);}
                                            gtag('js', new Date());
                                            
                                            gtag('config', '${this.state.generalSetting.googleanalyticsId}');
                                        `}
                                    </script>
                                    </>
                                     }
                                    </>
                                    
                                }  
                         
                            </Helmet>
                    
                            <span id="ganalytics" style={{display:"none"}} data-id={this.state.generalSetting.googleanalyticsId}></span>
                           <div id="toast-root"></div>
                           <div id="dvglbstyle" style={{ display: "none" }}>
                                    <button id="btnprimarybutton" className="v-button primary invisible-button"></button>
                                    <button id="btnprimarybuttonhover" className="v-button-hover primary"></button>
                                    <button id="btnsecondary" className="v-button secondary"></button>
                                    <button id="btnsecondarybuttonhover" className="v-button-hover secondary"></button>
                                    <div id="txtfontfamily" className="v-text"></div>
                                    <button id="btnrating" className="v-filled-star"></button>
                                <button id="glbbackground" className="v-site"></button>
                                    <h2 id='glbHeadingFont'></h2>
                                </div>
                                <div className="wbvg-passive-alert vg-passive-alert-small">
                                    <div className="wbvg-passive-alert-content">
                                        <i className="far fa-warning wbvg-passive-alert-icon"></i>
                                        <div className="wbvg-passive-alert-text"></div>
                                    </div>
                                </div>
                           <VagaroIframe />
                           <style id="global-styling" dangerouslySetInnerHTML={{__html:siteStyle}}></style>
                           <div id="divcontent" dangerouslySetInnerHTML={{ __html: content.content }} style={{ minHeight: "calc(100vh - 100px)" }} ></div>
                           <footer id="wbfooter" dangerouslySetInnerHTML={{ __html: content.footer }}></footer>

                           {this.state.generalSetting.footerCode &&<span style={{display:"none"}} id='footer-code' dangerouslySetInnerHTML={{__html:this.state.generalSetting.footerCode}}></span>}
                        </div>
                        <AnimationHandler />
                    </HelmetProvider>
                    </>
                }
            </Fragment>
        );
    }
}
