import React, { Fragment, useEffect, useState } from "react";
import ReactDOM, { unmountComponentAtNode } from 'react-dom';
import VGPopUp from "../components/Controls/VGPopUp";
import "./VgWriteReview.scss";
import Loader from "../components/Common/Loader";
import RenderBusinessReviews from "../components/BusinessControls/RenderBusinessReviews";
import { GetBusinessReviews } from "../components/Utilities/BusinessData";

const Reviews = (props) => {
    
    const [businessReviews, setBusinessReviews] = useState()
    const [isLoading, setLoading] = useState(true);

    const ShowAlert = (title, body, strConfirmButtonText) => {
        const AlertClose = () => {
            unmountComponentAtNode(document.getElementById("OpenDialog1"));
            unmountComponentAtNode(document.getElementById("OpenDialog"));
        };
        return ReactDOM.render(
            <VGPopUp
                id={"Alert"}
                show={true}
                onHide={AlertClose}
                title={title}
                dialogClassName="vgn-popup vgn-modal-alert vgn-modal-small"
                body={ 
                <div>
                    {body}
                </div>}
                footer={ 
                <Fragment>
                    <button className="v-button primary invisible-button w-25" onClick={() => AlertClose()}>{strConfirmButtonText}</button>
                </Fragment >}
                isRemoveTabModalIndex={false}
                isShowFooter={true}
                backdrop={true}
            />,
            document.getElementById("OpenDialog1")
        );
    }
    const PopupClose = () => {
        unmountComponentAtNode(document.getElementById("OpenDialog"));
    };

    const Reviews = (data) => {
        return ReactDOM.render(
                <Fragment>
                {isLoading && <Loader />}
                <VGPopUp
                    id={"writereviewpopup"}
                    show={true}
                    onHide={PopupClose}
                    title="&nbsp;"
                    dialogClassName="md"
                    body={
                        <Fragment>
                            <RenderBusinessReviews layout={"1"} data={data} userContext={props.userContext} SPID={"U2FsdGVkX18EI4k80FtcVtq4PzxA94OPqaGqyI1vxNM="} isPopup={true} />
                        </Fragment>
                    }
                    footer={<></>}
                    isRemoveTabModalIndex={true}
                    isShowFooter={true}
                    size={"xl"}
                    backdrop={true}
                />
            </Fragment>,
            document.getElementById("OpenDialog"));
    }
    useEffect(() => {
        if (!businessReviews) {
            GetBusinessReviews(props.userContext, (data) => { setLoading(false); setBusinessReviews(data);}, (error) => { setLoading(false); ShowAlert("Message", "Please reload this page and try again.", "OK");    }, 20, 1,);
        }else{
            Reviews(businessReviews)
        }
    }, [businessReviews])


    return <></>
}



export default Reviews;