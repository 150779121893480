import React, { Fragment } from 'react';
import { utils } from '../../utils/Utils';
import $ from "jquery";
import FormActivate from '../../VagaroComponent/FormActivate';
import ReactDOM from "react-dom";
import '../../components/Styles/FormComponents.scss'
import { NoRecordsBanner } from '../OopsBanner';

export default function RenderBusinessForm({ data, formID, isActivate, siteData }: { data: any, formID: string, isActivate: boolean, siteData: any }) {
    var IsEditor = (window as any).isEditor !== undefined ? (window as any).isEditor : false;
    let LoginDetails = utils.ReadCookie();
    let EncUid = "U2FsdGVkX19XuPwoaB0Z5i4YBuj1CY04an3fq05ikN0=";
    if (LoginDetails.EncUid != undefined && LoginDetails.EncUid != "" && LoginDetails.EncUid != null) {
        EncUid = LoginDetails.EncUid;
    }

    const onActivate = () => {

        let designer = (window as any).webDesigner;
        if (designer) {
            designer.activateAllForms();
        }

    }

    var glbbackground: any = $("#dvglbstyle").find("#glbbackground");
    glbbackground = glbbackground.css("background-color");

    const Activate = () => {
        if (utils.isNotEmptyOrWhiteSpace(siteData) && utils.isNotEmptyOrWhiteSpace(siteData.businessId)) {
            return ReactDOM.render(<FormActivate data={data} formID={formID} onActivate={onActivate} isActivate={isActivate} siteData={siteData} userID={siteData.userId} />, document.getElementById("OpenDialog"))
        }
    }

    const isStencil = data?.isStenciled === true;
    const style = {
        maxHeight: "1em",
        overflow: "hidden",
        opacity: 1
    }
    if (isStencil) {
        return <div className="stenciled  flex-column gap-small w-full max-100" style={{ width: "1071px" }}>
            <div className='w-full stencil-item' style={style}>Forms gives you the ability to seamlessly integrate your waivers and forms with the Check-In App</div>
            <div className='w-full stencil-item' style={{ ...style, maxWidth: "100%" }}>Your business can require waivers when signing up, joining a membership, or purchasing a package. Forms can be required for certain clients. Vagaro will automatically notify and remind your clients of required forms</div>
            <div className='w-full stencil-item' style={{ ...style, maxWidth: "100%" }}>Once you've created forms, Vagaro's Forms features include ways to manage them, get them completed by customers (both new and existing), control your employee's access to forms, and, if needed, deactivate your forms subscription.</div>
        </div>
    }

    return IsEditor ?
        <Fragment>
            {isActivate === true ?
                <Fragment>
                    {(utils.isNotEmptyOrWhiteSpace(formID) && formID == "1") ?
                        <Fragment>
                            <NoRecordsBanner message='No Form is Selected' vagaroLink='https://www.vagaro.com/merchants/forms' linkText='Create New Form' />
                        </Fragment>
                        :
                        <Fragment>
                            <div id={"dvglbstyle"} style={{ display: "none" }}>
                                <button id="btnprimarybutton" className="v-button primary invisible-button"></button>
                                <button id="btnprimarybuttonhover" className="v-button-hover primary"></button>
                                <button id="btnsecondary" className="v-button secondary"></button>
                                <button id="btnsecondarybuttonhover" className="v-button-hover secondary"></button>
                                <div id="txtfontfamily" className="v-text"></div>
                                <button id="btnrating" className="v-filled-star"></button>
                                <button id="glbbackground" className="v-site"></button>
                                <h2 id="glbHeadingFont"></h2>
                            </div>
                            <div id="divformsiframe" className='vgFrameWrapper' style={{ minHeight: "80vh" }}>
                                <iframe key={formID} scrolling="no" id={"form-" + formID} style={{ backgroundColor: glbbackground, minHeight: "80vh" }} onLoad={() => { LoadForm(formID) }} src={utils.FormURL() + "/" + formID + "?Type=Builder&lid=" + EncUid + ""} width="100%"></iframe>
                                {IsEditor && <div style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%" }} onClick={(e) => e.preventDefault()}></div>}
                            </div>
                        </Fragment>
                    }

                </Fragment>
                :
                <Fragment>
                    <div className='form-component-wrapper form-wb-wrapper'>
                        <div className="settings-body-div forms newScrollFix" style={{ overflow: 'hidden' }}>
                            <div className='new-form-card'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="65" height="69" viewBox="0 0 65 69" fill="none">
                                    <path d="M50.5 12.5C53.75 12.5 56.5 15.25 56.5 18.5V62.5C56.5 65.875 53.75 68.5 50.5 68.5H14.5C11.125 68.5 8.5 65.875 8.5 62.5V18.5C8.5 15.25 11.125 12.5 14.5 12.5H24.5C24.5 8.125 28 4.5 32.5 4.5C36.875 4.5 40.5 8.125 40.5 12.5H50.5ZM32.5 9.5C30.75 9.5 29.5 10.875 29.5 12.5C29.5 14.25 30.75 15.5 32.5 15.5C34.125 15.5 35.5 14.25 35.5 12.5C35.5 10.875 34.125 9.5 32.5 9.5ZM50.5 61.75V19.25C50.5 18.875 50.125 18.5 49.75 18.5H44.5V23C44.5 23.875 43.75 24.5 43 24.5H22C21.125 24.5 20.5 23.875 20.5 23V18.5H15.25C14.75 18.5 14.5 18.875 14.5 19.25V61.75C14.5 62.25 14.75 62.5 15.25 62.5H49.75C50.125 62.5 50.5 62.25 50.5 61.75Z" fill="#333333"/>
                                </svg>
                                <div className="heading-text-div forms-heading">
                                    <h1>Require Forms To Be Activated</h1>
                                    <h1 className="cc-subheading">You do not have the feature for Forms. Please activate it to use this component.</h1>
                                    <div className="div-block-45 hero">
                                        <div className="btn-width80per settings-get-started">
                                            <div onClick={() => Activate()} className="get-started-btn" style={{ transition: 'all 0.2s ease 0s' }}>
                                                <div style={{ cursor: "pointer" }} className="get-text"><span id="spnFormsPrice"><span>Try it Free - {data.currencySymbol}{data.formsCharge}/mo After Trial</span></span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>
                </Fragment>
            }
        </Fragment> : <Fragment>
            {(utils.isNotEmptyOrWhiteSpace(formID) && formID == "1") || utils.isNotEmptyOrWhiteSpace(data.formId) == false || utils.isNotEmptyOrWhiteSpace(data.isActivate) == false ?
                <Fragment></Fragment> :
                <Fragment>
                    <div id={"dvglbstyle"} style={{ display: "none" }}>
                        <button id="btnprimarybutton" className="v-button primary invisible-button"></button>
                        <button id="btnprimarybuttonhover" className="v-button-hover primary"></button>
                        <button id="btnsecondary" className="v-button secondary"></button>
                        <button id="btnsecondarybuttonhover" className="v-button-hover secondary"></button>
                        <div id="txtfontfamily" className="v-text"></div>
                        <button id="btnrating" className="v-filled-star"></button>
                        <button id="glbbackground" className="v-site"></button>
                        <h2 id="glbHeadingFont"></h2>
                    </div>
                    <div id="divformsiframe" className='vgFrameWrapper' style={{ minHeight: "80vh" }}>
                        <iframe key={formID} scrolling="no" id={"form-" + formID} style={{ backgroundColor: glbbackground, minHeight: "80vh" }} onLoad={() => { LoadForm(formID) }} src={utils.FormURL() + "/" + formID + "?Type=Builder&lid=" + EncUid + ""} width="100%"></iframe>
                        {IsEditor && <div style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%" }} onClick={(e) => e.preventDefault()}></div>}
                    </div>
                </Fragment>
            }
        </Fragment>
}

const sendPositionbook = function (glbIframeParentID: any, src: any) {
    if (glbIframeParentID != '' && typeof glbIframeParentID != 'undefined') {
        var parentid = $('#' + glbIframeParentID) as any;
        var currentScrollPositionofParent = (document.scrollingElement as any).scrollTop;
        var iframestartposition = parentid.find('iframe').offset().top;
        var modalTop = currentScrollPositionofParent - iframestartposition
        var modalHeight = window.screen.availHeight;
        if (modalTop < 0) {
            modalTop = 0;
        }
        parentid.find('iframe')[0].contentWindow.postMessage('{"eventType": "modalPosition", "modalTop" : ' + modalTop + ',"modalHeight" : "' + modalHeight + '"}', getSource(src));
    }
}

const LoadForm = function (root: string) {
    const getId = 'form-' + root;
    if (document.getElementById(getId) !== undefined) {
        var iframeElement = document.getElementById(getId) as any;
        if (iframeElement) {
            var styleobject: any = {};
            var gblstyle: any = $("#dvglbstyle");
            var primarybutton: any = gblstyle.find("#btnprimarybutton");
            var secondorybutton: any = gblstyle.find("#btnsecondary");
            var primarybuttonHover: any = gblstyle.find("#btnprimarybuttonhover");
            var secondorybuttonHover = gblstyle.find("#btnsecondarybuttonhover");
            var lblstyle: any = gblstyle.find("#txtfontfamily");
            var btnrating: any = gblstyle.find("#btnrating");
            var glbbackground: any = gblstyle.find("#glbbackground");
            var glbHeadFonts: any = gblstyle.find("#glbHeadingFont");
            styleobject.primarybuttoncolor = primarybutton.css("color");
            styleobject.primarybuttonbackgroundcolor = primarybutton.css("background-color");
            styleobject.primarybuttonHoverbackgroundcolor = primarybuttonHover.css("background-color");
            styleobject.primarybuttonborderradius = primarybutton.css("border-radius");
            styleobject.primarybuttonboxshadow = primarybutton.css("box-shadow");
            styleobject.secondorybuttonbuttoncolor = secondorybutton.css("color");
            styleobject.secondorybuttonbuttonbackgroundcolor = secondorybutton.css("background-color");
            styleobject.secondorybuttonbuttonHoverbackgroundcolor = secondorybuttonHover.css("background-color");
            styleobject.secondorybuttonbuttonborderradius = secondorybutton.css("border-radius");
            styleobject.secondorybuttonbuttonboxshadow = secondorybutton.css("box-shadow");
            styleobject.lblstylefontfamily = 'proxima-nova'
            styleobject.lblstylecolor = lblstyle.css("color");
            styleobject.lblstylefontweight = lblstyle.css("font-weight");
            styleobject.lblstylefontsize = lblstyle.css("font-size");
            styleobject.btnratingcolor = btnrating.css("color");
            styleobject.glbbackgroundcolor = glbbackground.css("background-color");
            styleobject.glbHeadingFonts = 'proxima-nova'
            var cookieobject: any = {};
            cookieobject.vdsid = localStorage.getItem("vdsid");
            //var styledata: any = { 'eventType': 'addStyle', style: styleobject, 'parentID': 'divformsiframe', 'cookieobject': cookieobject }
            var styledata: any = { 'eventType': 'getHeightFormBuilder', 'style': styleobject, 'parentID': 'divformsiframe' }
            iframeElement.contentWindow.postMessage(JSON.stringify(styledata), getSource(iframeElement.src));
            sendPositionbook('divformsiframe', iframeElement.src);
        }
    }
}

function getSource(src: any) {
    var _target = null;
    if (_target == null) {
        var targetRegex = /^(https*:\/\/[^\/]*).*$/g;
        var match = targetRegex.exec(src);
        if (match) {
            _target = match[1];
        }
    }
    return _target;
}
