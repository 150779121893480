import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import store from './app/store';
let persistor = persistStore(store);

const baseUrl = document.getElementById('base-url').getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
     <React.StrictMode>
        <BrowserRouter  basename={baseUrl}>
            <Provider store={store}>
                <PersistGate persistor={persistor}>
                    <App />
                </PersistGate>
            </Provider>
        </BrowserRouter>
    </React.StrictMode>,
  rootElement);


