import React from 'react';

const counttoFive = [1, 2, 3, 4, 5];
export function Star({ color, inClass, isOutlined }: { color: string, inClass?: string, isOutlined:boolean }) {    
    //return <svg  xmlns="http://www.w3.org/2000/svg" style= {{height:"1.5em",width:"1.5em",color:color}} viewBox="0 0 576 512" fill="currentColor"><path d="M288.1 0l86.5 164 182.7 31.6L428 328.5 454.4 512 288.1 430.2 121.7 512l26.4-183.5L18.9 195.6 201.5 164 288.1 0z"/></svg>      
    return(
    <svg className={inClass} style={(color.length > 0) ? { height: "1.5em", width: "1.5em", color: color } : { height: "1.25em", width: "1.25em" }} viewBox="0 0 533 514" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        {color.length > 0 && isOutlined ? <path d="M265 0C275 0 283 6 287 14L356 155L509 178C518 179 525 186 528 194C531 203 529 212 522 219L411 329L437 484C439 493 435 503 428 508C420 513 410 514 402 510L265 436L128 510C120 514 111 513 103 508C96 503 92 493 93 484L120 329L9 219C2 212 0 203 3 194C6 186 13 179 22 178L175 155L244 14C248 6 256 0 265 0ZM265 79L213 188C209 195 203 200 195 201L76 218L162 303C168 309 170 317 169 325L149 444L254 388C261 384 270 384 277 388L382 444L362 325C360 317 363 309 369 303L454 218L336 201C328 200 321 195 318 188L265 79Z"/> :
        <path d="M360 151L503 172C515 174 525 182 529 194C533 205 530 218 521 226L417 329L442 475C444 487 439 499 429 506C419 514 406 514 395 509L267 440L138 509C128 514 114 514 105 506C95 499 90 487 92 475L116 329L12 226C3 218 0 205 4 194C8 182 18 174 30 172L174 151L238 18C243 7 254 0 267 0C279 0 290 7 295 18L360 151Z" />
        }
    </svg>
    )   

}

export default function RenderStarRating({ rating, starColor,isOutlined }: { rating: number, starColor: string, isOutlined:boolean  }) {

    return <>{counttoFive.map((num, index: number) => {
        let isSolid = (index < rating)
    return (
            <Star key={index} inClass={isSolid ? "review-star v-filled-star" : ""} color={(isSolid) ? "" : "#e7e7e7"}  isOutlined={isOutlined} />
        )
    })}
    </>

}