import React, { Fragment, useState } from 'react';
import ReactDom, { unmountComponentAtNode, createPortal } from "react-dom";
import VGPopUp from '../components/Controls/VGPopUp'
import GroupMultilocationSelect from './Controls/GroupMultilocationSelect';
import { utils } from '../utils/Utils';
import { AsyncAjaxGet } from '../components/Utilities/AsyncAjaxService';
import Toast, { WarningToast } from '../components/Utilities/Toast';
import $ from 'jquery'
import Loader from '../components/Common/Loader';
import MultiLocationEmbaded from './Controls/MultiLocationEmbaded';

const MultiLocationDropdown = (props) => {
    const [validation, setvalidation] = useState(false);
    const Sitedata = props.Sitedata;
    const BusinessData = utils.MultiLocationData(props.userContext, props.userContext.bidHash);
    const [isLoading, setLoading] = useState(false);

    const GetBusinessTabInfo = async (encBID, businessData, VagaroURL) => {
        const url = "merchants/" + encBID;
        await AsyncAjaxGet(
            url,
            props.userContext.accessToken,
            function onSuccess(data) {
                try {
                    if (data != null && data.data != null && data.data.data != null) {
                        var businessinfo = data.data.data
                        $(".wbloader").css("display", "none");
                        switch (props.Mode) {
                            case "wbBook":
                                if (businessinfo.isShowBookNowTab) {
                                    props.OnClickEvent(businessData);
                                } else {
                                    Toast(businessinfo.businessName + " can not provide Book-Now.");
                                }
                                break;
                            case "wbClasses":
                                if (businessinfo.isShowClassTab) {
                                    props.OnClickEvent(businessData);
                                    break;
                                }
                                else {
                                    Toast(businessinfo.businessName + " can not provide Class.");
                                }
                                break;
                            case "packageAddCart":
                                if (businessinfo.isPackageShow) {
                                    const multiGroupPackage = props.Package.multiGroupPackage;
                                    if (!utils.isNullEmptyOrWhiteSpace(multiGroupPackage)) {
                                        var pdata = JSON.parse(multiGroupPackage);
                                        if (pdata.length > 0) {
                                            var location = pdata.filter(
                                                (col) => col.BID === businessData
                                            );
                                            if (location.length > 0) {
                                                var packageData = {};
                                                packageData.PID = location[0].PID;
                                                props.OnClickEvent(encBID, false, true, packageData);
                                            }
                                        }
                                        else {
                                            Toast(businessinfo.businessName + " can not provide Package.");
                                        }
                                    }
                                }
                                else {
                                    Toast(businessinfo.businessName + " can not provide Package.");
                                }
                                break;

                            case "membershipAddCart":
                                if (businessinfo.isShowMembership) {
                                    const MultiGroupMembership = props.Membership.multiGroupMembership;
                                    if (!utils.isNullEmptyOrWhiteSpace(MultiGroupMembership)) {
                                        var mdata = JSON.parse(MultiGroupMembership);
                                        if (mdata.length > 0) {
                                            var location = mdata.filter(
                                                (col) => col.BID === businessData
                                            );
                                            if (location.length > 0) {
                                                var membershipData = {};
                                                membershipData.MID = location[0].MID;
                                                props.OnClickEvent(props.action, encBID, false, true, membershipData);
                                            }
                                        }
                                        else {
                                            Toast(businessinfo.businessName + " can not provide Membership.");
                                        }
                                    }
                                }
                                else {
                                    Toast(businessinfo.businessName + " can not provide Membership.");
                                }
                                break;

                            case "gcAddCart":
                                if (businessinfo.isShowGCTab) {
                                    const MultiGroupGC = props.GiftCard.multiGroupGC;
                                    if (!utils.isNullEmptyOrWhiteSpace(MultiGroupGC)) {
                                        var mdata = JSON.parse(MultiGroupGC);
                                        if (mdata.length > 0) {
                                            var location = mdata.filter(
                                                (col) => col.BID === businessData
                                            );
                                            if (location.length > 0) {
                                                props.OnClickEvent(encBID, false);
                                                props.setammoutvalue(0);
                                            }
                                        }
                                        else {
                                            Toast(businessinfo.businessName + "can not provide Gift Certificate");
                                        }
                                    }
                                }
                                else {
                                    Toast(businessinfo.businessName + "can not provide Gift Certificate");
                                }
                                break;

                            case "serviceAddCart":
                                if (businessinfo.isShowServiceTab) {
                                    const multiGroupService = props.service.multiGroupService;
                                    if (!utils.isNullEmptyOrWhiteSpace(multiGroupService)) {
                                        var sdata = JSON.parse(multiGroupService);
                                        if (sdata.length > 0) {
                                            var location = sdata.filter(
                                                (col) => col.BID === businessData
                                            );
                                            if (location.length > 0) {
                                                var serviceData = {};
                                                serviceData.SID = location[0].SID;
                                                serviceData.ISADDON = location[0].ISADDON;
                                                props.OnClickEvent(encBID, false, true, serviceData);
                                            }
                                        }
                                        else {
                                            Toast(businessinfo.businessName + " can not provide Services.");
                                        }
                                    }
                                }
                                else {
                                    Toast(businessinfo.businessName + " can not provide Services.");
                                }
                                break;
                            case "addOnServiceAddCart":
                                if (businessinfo.isShowServiceTab) {
                                    const multiGroupService = props.service.multiGroupService;
                                    if (!utils.isNullEmptyOrWhiteSpace(multiGroupService)) {
                                        var servicedata = JSON.parse(multiGroupService);
                                        if (servicedata.length > 0) {
                                            var location = servicedata.filter(
                                                (col) => col.BID === businessData
                                            );
                                            if (location.length > 0) {
                                                props.OnClickEvent(props.service, props.commonData, props.userContext, props.event, props.allowMultiLoc, false, encBID, location[0].SID, location[0].ISADDON);
                                            }
                                        }
                                        else {
                                            Toast(businessinfo.businessName + " can not provide Services.");
                                        }
                                    }
                                    else {
                                        props.OnClickEvent(props.service, props.commonData, props.userContext, props.event, props.allowMultiLoc, false, encBID);
                                    }
                                }
                                else {
                                    Toast(businessinfo.businessName + " can not provide Services.");
                                }
                                break;
                            case "productAddCart":
                                if (businessinfo.isShowProductTab) {
                                    const multiGroupInventory = props.Item.multiGroupInventory;
                                    if (!utils.isNullEmptyOrWhiteSpace(multiGroupInventory)) {
                                        var servicedata = JSON.parse(multiGroupInventory);
                                        if (servicedata.length > 0) {
                                            var businessfilter = props.userContext.MultilocationData.filter(
                                                (col) => col.encBID === encBID
                                            );
                                            var location = servicedata.filter(
                                                (col) => col.BID === businessData
                                            );
                                            if (location.length > 0) {
                                                props.OnClickEvent(props.Item, props.userContext, props.allowMultiLoc, false, encBID, location[0].IID);
                                            }
                                        }
                                        else {
                                            Toast(businessinfo.businessName + "can not provide Product");
                                        }
                                    }
                                    else {
                                        props.OnClickEvent(props.Sitedata, props.userContext, props.userContext, props.event, props.allowMultiLoc, false, encBID);
                                    }
                                }
                                else {
                                    Toast(businessinfo.businessName + " can not provide Product.");
                                }
                                break;
                            case "Cart":
                                props.OnClickEvent(props.data, props.userContext, false, VagaroURL);
                                break;
                            case "ServiceBook":
                                if (businessinfo.isShowServiceTab) {
                                    const multiGroupService = props.service.multiGroupService;
                                    if (!utils.isNullEmptyOrWhiteSpace(multiGroupService)) {
                                        var servicedata = JSON.parse(multiGroupService);
                                        if (servicedata.length > 0) {
                                            var location = servicedata.filter(
                                                (col) => col.BID === businessData
                                            );
                                            if (location.length > 0) {
                                                props.OnClickEvent(props.service, props.commonData, props.userContext, props.event, props.allowMultiLoc, false, VagaroURL, location[0].SID, location[0].ISADDON);
                                            }
                                        }
                                        else {
                                            Toast(businessinfo.businessName + " can not provide Book.");
                                        }
                                    }
                                    else {
                                        props.OnClickEvent(props.service, props.commonData, props.userContext, props.event, props.allowMultiLoc, false, VagaroURL);
                                    }
                                }
                                else {
                                    Toast(businessinfo.businessName + "can not provide Book");
                                }
                                break;

                            default:
                                break;
                        }
                    }
                    else {

                    }

                } catch (error) {
                    setLoading(false);
                }
            },
            function OnError() {
                setLoading(false);
            }
        );
    }

    const PopupBody = () => {
        return (
            <Fragment>
                {validation && WarningToast("Please select atleast one location.", setvalidation)}
                {utils.IsFromProApp() && <div id="warning-toast-root"></div>}
                <div className={`form-row search-control-location`} style={{ position: 'relative' }}>
                    {props.IsShowMap ?
                        <MultiLocationEmbaded ControlID={"wbmultilocationgroup"} userContext={props.userContext} Sitedata={Sitedata} layoutID={1} options={Sitedata} searchInputPlaceholder={"Search Business"} onChange={(encBID, bidHash, vagaroURL) => EventChangeEmbeded(encBID, bidHash, vagaroURL)} Mode={props.Mode} isShowHeader={false} />
                        :
                        <GroupMultilocationSelect ControlID={"wbmultilocationgroup"} options={Sitedata} searchInputPlaceholder={"Search Business"} onChange={(value) => EventChange(value)} Mode={props.Mode} />
                    }
                </div>

            </Fragment>
        );
    }


    const handlepopupClose = () => {
        if (props.Mode == "wbClasses" || props.Mode == "wbBook") {
            props.OnClickEvent(BusinessData.vagaroURL);
            unmountComponentAtNode(document.getElementById("OpenDialogMultiLocation"));
        }
        else {
            unmountComponentAtNode(document.getElementById("OpenDialogMultiLocation"));
        }
    }

    const EventChange = (value) => {
        var tempEncID = ""
        if (value === undefined || value === null || value === "") {
            setvalidation(true);
        }
        else {
            setvalidation(false)
            if (props.Mode == "Cart" || props.Mode == "ServiceBook" || props.Mode == "wbClasses" || props.Mode == "wbBook") {
                tempEncID = utils.MultiLocationBId(Sitedata, value);
            }
            else {
                tempEncID = value;
            }
            GetBusinessTabInfo(tempEncID, value);
            $(".wbloader").css("display", "block");
            unmountComponentAtNode(document.getElementById("OpenDialogMultiLocation"));

        }
    };

    const EventChangeEmbeded = (EncBID, EncBIDHash, VagaroURL) => {
        if (EncBID === undefined || EncBID === null || EncBID === "") {
            setvalidation(true);
        }
        else {
            setvalidation(false)
            GetBusinessTabInfo(EncBID, EncBIDHash, VagaroURL);
            $(".wbloader").css("display", "block");
            unmountComponentAtNode(document.getElementById("OpenDialogMultiLocation"));
        }
    };

    const PopupTitle = () => {
        return (
            <Fragment>
                <span>Select Business Location</span>
                {!utils.IsFromProApp() && <div id="warning-toast-root"></div>}
            </Fragment>
        )

    }


    return createPortal(
        <Fragment>
            {isLoading && <Loader />}
            <VGPopUp
                show={true}
                title={PopupTitle()}
                onHide={handlepopupClose}
                body={PopupBody()}
                footer={""}
                dialogClassName={"modal-fullscreen vgn-modal-alert locationpopup mobile-fullscreen multilocationselectpopup modal-lg modal-dialog-centered modal-dialog-scrollable "}
                isShowFooter={false}
                isRemoveTabModalIndex={true}
                isvgnpopup={true}
                size="lg"
            />
        </Fragment>,
        document.getElementById("OpenDialogMultiLocation")
    )
}

export default MultiLocationDropdown;
