import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import $ from "jquery";

const VGPopUp = (props) => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const VgThemes =()=>{
        var styleobject = {};
        var gblstyle = $("#dvglbstyle");
        var primarybutton  = gblstyle.find("#btnprimarybutton");
        var secondorybutton  = gblstyle.find("#btnsecondary");
        var primarybuttonHover  = gblstyle.find("#btnprimarybuttonhover");
        var secondorybuttonHover  = gblstyle.find("#btnsecondarybuttonhover");  
        var lblstyle   = gblstyle.find("#txtfontfamily");
        var btnrating  =gblstyle.find("#btnrating");
        var glbbackground = gblstyle.find("#glbbackground");
        styleobject.primarybuttoncolor = primarybutton.css("color");
        styleobject.primarybuttonbackgroundcolor = primarybutton.css("background-color");
        styleobject.primarybuttonHoverbackgroundcolor = primarybuttonHover.css("background-color");
        styleobject.primarybuttonborderradius = primarybutton.css("border-radius");
        styleobject.primarybuttonboxshadow = primarybutton.css("box-shadow");
        styleobject.secondorybuttonbuttoncolor = secondorybutton.css("color");
        styleobject.secondorybuttonbuttonbackgroundcolor = secondorybutton.css("background-color");
        styleobject.secondorybuttonbuttonHoverbackgroundcolor = secondorybuttonHover.css("background-color");    
        styleobject.secondorybuttonbuttonborderradius = secondorybutton.css("border-radius");
        styleobject.secondorybuttonbuttonboxshadow = secondorybutton.css("box-shadow"); 
        styleobject.lblstylefontfamily = lblstyle.css("font-family");
        styleobject.lblstylecolor = lblstyle.css("color");  
        styleobject.lblstylefontweight = lblstyle.css("font-weight");
        styleobject.lblstylefontsize = lblstyle.css("font-size");
        styleobject.btnratingcolor = btnrating.css("color");
        styleobject.glbbackgroundcolor = glbbackground.css("background-color");
        //document.body.style.setProperty("--vg-primary-bg",`${styleobject?.primarybuttoncolor};`)
        return(
            <>
                <style>
                    {
                        `:root{
                            --vg-primary-bg-modal:${styleobject.glbbackgroundcolor};
                            --vg-body-text-color-modal:${styleobject.lblstylecolor};
                            --vg-body-font:${styleobject.lblstylefontfamily};
                        }
                        `
                    }
                </style>
            </>
        )
        //document.body.style.setProperty("--vg-primary-bg",`${styleobject.primarybuttonbackgroundcolor}`);
        //document.body.style.setProperty("--vg-primary-text-color",`${styleobject.primarybuttoncolor}`);        
    }


    useEffect(() => {
        
        if (props.isRemoveTabModalIndex) {
            let $allActiveBootboxesModals = $('.modal');
            $allActiveBootboxesModals.each(function (index, modal) {
                let $modal = $(modal);
                $modal.removeAttr('tabindex');
            });
            if (props.otherRemoveTabClass) {
                let $OtherModals = $(`.${props.otherRemoveTabClass}`);
                $OtherModals.each(function (index, modal) {
                    let $modal = $(modal);
                    $modal.removeAttr('tabindex');
                });
            }
        }
    }, []);

    return (
        <> <VgThemes />
        <Modal
            id={props.id}
            show={props.show}
            onHide={props.onHide}
            dialogClassName={props.dialogClassName}
            size={props.size != undefined && props.size != null && props.size != "" ? props.size : " lg"}
            centered
            scrollable={true} className={props.isvgnpopup != undefined && props.isvgnpopup != null && props.isvgnpopup != "" ? "" : "vgn-popup"} 
        >
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
                {props.description}

            </Modal.Header>
            <Modal.Body onScroll={props.handleScroll}>{props.body}</Modal.Body>
            {
                props.isShowFooter &&
                <Modal.Footer>
                    {props.footer}</Modal.Footer>
                   
            }
        </Modal>
        </>
    );
};
export default VGPopUp;
