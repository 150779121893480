import React from "react";
import { VagaroIcon } from "./Icons/SocialIcons";

export default function OopsBanner() {

    return <div className="center-all" style={{padding:"12px",margin:"12px",borderRadius:"5px",color:"white",background:"#B42318",width:"calc(100% - 24px)",wordBreak:"break-word"}}>
    Oops! Something went wrong. Please try again later.
    </div>

}

export function NoRecordsBanner({message,showInLive,vagaroLink,linkText}:{message?:string,showInLive?:boolean,vagaroLink?:string,linkText?:string}){

    let msg = message??"No Records Found"
    let isEditor = (window as any).isEditor === true;
    let newLink = vagaroLink;
    if(newLink){
        if(!window.location.hostname.includes('mysite.vagaro.com')){
            newLink = newLink.replace('www.vagaro.com','dev24.bookitall.com');
           }
    }

    if(!isEditor&&!showInLive){
        return null;
    }

    return <div className="v-button secondary flex-column justify-center align-center" style={{pointerEvents:"none",padding:"12px",margin:"12px",borderRadius:"5px",width:"calc(100% - 24px)",wordBreak:"break-word"}}>
   {msg}
   {
    isEditor&&!showInLive&&
    <>
    <div className="font-small" style={{opacity:".7",fontWeight:"bold"}}>This message will be hidden on your published site.</div>
    {newLink&&
    <button className="w-button w-button-primary center-all gap-med" style={{marginTop:"12px",pointerEvents:"all"}} onClick={()=>{
        
            window.open(newLink)
        }
    }>{linkText??"Edit in Vagaro"}<VagaroIcon/></button> }
    </>
    }
    </div>
    
}