import React from "react";
import { useState, useRef } from "react";
import LayoutNode, { getNodeComponentAttributeValue } from "../Definitions/LayoutNode";
import ReactPlayer from "react-player";
import styled from 'styled-components';
import MissingSourceDisplay from "../MissingSourceDisplay";


interface RenderVideoProps {
   videoSource: string;
   videoTitle?: string;
   videoSubtitle?: string;
   vCardTitle?: string;
   vCardSubtitle?: string;
   vCardDesc?: string;
   videoDescription?: string;
   thumbnail: string;
   autoPlay: boolean;
   videoControls: boolean;
   loop: boolean;
}

const LightBox = styled.div`
 position: fixed;
 top: 0;
 left: 0;
 width: 100%;
 height: 100%;
 background-color: rgba(0, 0, 0, 0.8);
 display: flex;
 align-items: center;
 justify-content: center;
 z-index: 1000;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  color: white;
  font-size: 24px; // Adjust size as needed
  font-weight: 600;
  z-index: 1010; // Ensure it's above other content

  &:before {
    content: '✕'; // Unicode X character
  }
`;

const VideoCard = styled.div`
// Card background
 // border-radius: 10px; // Rounded corners for the card
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Box shadow for the card
  padding-left: 8px;
  padding-top: 8px;
  padding-right: 8px; // Padding around the card content
  padding-bottom: 16px;
  word-break:break-word;
  text-align: center; // Center the text
 // Space below the card
`;

const baseFontSize = 16; // Base font size in pixels

const Title = styled.h3`
  padding-top: ${baseFontSize * 0.1}px !important; // Space above the title
 
  text-align: left !important;
`;

const Subtitle = styled.h4`
  
  text-align: left !important;
`;

const CardParagraph = styled.p`
  
  text-align: left !important;
  
`;

// Define a styled div for the image container
const ImageContainer = styled.div`
  position: relative; // Needed for absolute positioning of the play button
  width: 100%; // Adjust width as needed
  height: 100%; // Adjust height as needed
 // border: 1px solid #ccc; // Border style
 // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Box shadow
 
  overflow: hidden; // Ensures the image doesn't overflow the container
  display: flex; // Flexbox to center the image
  align-items: center; // Center align vertically
  justify-content: center; // Center align horizontally
`;

// Define a styled div for the image container
const VideoCardImageContainer = styled.div`
  position: relative; // Needed for absolute positioning of the play button
  width: 100%; // Adjust width as needed
  height: 100%; // Adjust height as needed
   // Border style
   // Box shadow
 border-radius: 8px; // Rounded corners for the card
  overflow: hidden; // Ensures the image doesn't overflow the container
  display: flex; // Flexbox to center the image
  align-items: center; // Center align vertically
  justify-content: center; // Center align horizontally
`;


// Create a styled div for the player wrapper
const PlayerWrapper = styled.div`
  position: relative;
  width: 100%; // Adjust as needed
  max-width: 900px; // Adjust as needed
  margin: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  margin-top: 64px;
  height: 0;
  padding-top: 56.25%; // Aspect ratio 16:9 - Adjust as needed
`;


// Style for ReactPlayer
const StyledReactPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;


const PlayerWrapper2 = styled.div`
  position: relative;
  width: 100%; // Adjust as needed
  max-width: 90vw; // Adjust as needed
  margin: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  height: 0;
  padding-top: 56.25%;
 // Aspect ratio 16:9 - Adjust as needed
`;

const StyledReactPlayer2 = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
  max-height:100vh;
  border: 30px solid transparent;

  @media (max-width: 768px) {
   border:none;
 }
`;

const HoverableDiv = styled.div`
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
`;

const PlayButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px; // Adjust size as needed
  height: 80px; // Adjust size as needed
  background-color: rgba(255, 255, 255, 0.5); // Semi-transparent white
  border-radius: 50%; // Circular shape
  border: 2px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  transition: transform 0.3s ease-in-out;
  
  /* Set the transform origin to the center */
  transform-origin: center;

  /* Apply scaling to the PlayButton on hover */
  ${HoverableDiv}:hover & {
    transform: translate(-50%, -50%) scale(1.05); // Adjust scale value as needed
  }

  &:before {
   content: "";
   width: 0px;
   height: 0px;
   border-top: 12px solid transparent;
   border-right: 15px solid transparent;
   border-bottom: 12px solid transparent;
   border-left: 15px solid black;
   transform: translateX(10px);
  }
`;

const RenderDefaultStyle: React.FC<RenderVideoProps> = ({ videoSource, autoPlay, videoControls, loop }) => {
   return (
      <ReactPlayer url={videoSource} width={"100%"} height={"100%"} playing={autoPlay} playsinline={autoPlay} muted= {autoPlay} volume={1} controls={videoControls} loop={loop} />
   );
}

const RenderRoundedCornerStyle: React.FC<RenderVideoProps> = ({ videoSource, autoPlay, videoControls, loop }) => {
   return (
      <PlayerWrapper>
         <StyledReactPlayer
            url={videoSource}
            playing={autoPlay}
            controls={videoControls}
            loop={loop}
            width="100%"
            height="100%"
         />
      </PlayerWrapper>
   );
}

const RenderLightBoxStyle: React.FC<RenderVideoProps> = ({ videoSource, autoPlay, thumbnail, videoControls, loop }) => {
   const [showLightBox, setShowLightBox] = useState(false);


   
   const toggleLightBox = () => {

      let isEditor = (window as any).isEditor;

      if(isEditor){

         let dom = document.getElementById("preview-content");

         if(!dom   && !showLightBox){
            return;
         }
      }

      setShowLightBox(!showLightBox);
   };

   return (
      <>
         <HoverableDiv className="center-all w-full h-full" 
         
         onClick={toggleLightBox}>
            {/* <img src="https://res.cloudinary.com/vagaro/image/upload/v1670000629/SalonStudy-Banner-800px_yde9zd.webp" alt="" /> */}
            <ImageContainer 
            
            >
                               
               <PlayButton onClick={toggleLightBox} />
               {!thumbnail ? <MissingSourceDisplay/>:
               <img className="w-full h-full" style={{objectFit:"cover"}} src={thumbnail} alt="" />
               }
            </ImageContainer>
         </HoverableDiv>
         {showLightBox && (
            <LightBox onClick={toggleLightBox}>
               <CloseButton onClick={toggleLightBox} />
               <PlayerWrapper2>
                  <StyledReactPlayer2
                     url={videoSource}
                     playing={showLightBox}
                     controls={true}
                     loop={loop}
                     width="100%"
                     height="100%"
           
                  />
               </PlayerWrapper2>
            </LightBox>
         )}
      </>
   );
};


const RenderVideoCardStyle: React.FC<RenderVideoProps> = ({ videoSource, videoTitle, videoSubtitle, videoDescription,  autoPlay, thumbnail, videoControls, loop }) => {
   const [showLightBox, setShowLightBox] = useState(false);


   const toggleLightBox = () => {

      let isEditor = (window as any).isEditor;

      if(isEditor){

         let dom = document.getElementById("preview-content");

         if(!dom   && !showLightBox){
            return;
         }
      }

      setShowLightBox(!showLightBox);
   };

   

   return (
      <>
         <VideoCard >

            <HoverableDiv>
            <div className="center-all" onClick={toggleLightBox}>
               <VideoCardImageContainer>
                  <PlayButton onClick={toggleLightBox} />
                  {!thumbnail ? <MissingSourceDisplay styling={{padding:"23% 0"}}/>:
                     <img className="w-full h-full" style={{objectFit:"cover"}} src={thumbnail} alt="" />
                  }
             
               </VideoCardImageContainer>
            </div>
            </HoverableDiv>
            <Title className="video-text">{videoTitle}</Title>
            <Subtitle className="video-text">{videoSubtitle}</Subtitle>
            <CardParagraph className="video-text">{videoDescription}</CardParagraph>
         </VideoCard>
         {showLightBox && (
            <LightBox onClick={toggleLightBox} >
               <CloseButton onClick={toggleLightBox}  />
               <PlayerWrapper2 >
                  <StyledReactPlayer2
                  
                     url={videoSource}
                     playing={showLightBox}
                     controls={true}
                     loop={loop}
                     width="100%"
                     height="100%"
                    

                  />
               </PlayerWrapper2>
            </LightBox>
         )}
      </>
   );
};

export default function RenderVideoPlayer({ component }: { component: LayoutNode }) {
   let videoSource = getNodeComponentAttributeValue(component, "videoSrc", "");
   let videoTitle = getNodeComponentAttributeValue(component, "vCardTitle", "");
   let videoSubtitle = getNodeComponentAttributeValue(component, "vCardSubtitle", "");
   let videoDescription = getNodeComponentAttributeValue(component, "vCardDesc", "");

   const autoPlay = getNodeComponentAttributeValue(component, "autoPlay", "off") === "on";
   const videoControls = getNodeComponentAttributeValue(component, "videoControls", "") === "on";
   const loop = getNodeComponentAttributeValue(component, "loop", "") === "on";
   const layout = getNodeComponentAttributeValue(component, "videoStyles", "");
   const thumbnail = getNodeComponentAttributeValue(component, "thumbnail", "");

   switch (layout) {
      case "2":
         return <RenderRoundedCornerStyle videoSource={videoSource} thumbnail={thumbnail} autoPlay={autoPlay} videoControls={videoControls} loop={loop} />
      case "3":
         return <RenderLightBoxStyle videoSource={videoSource} thumbnail={thumbnail} autoPlay={autoPlay} videoControls={videoControls} loop={loop} />
      case "4":
         return <RenderVideoCardStyle videoSource={videoSource} videoTitle={videoTitle} videoSubtitle={videoSubtitle} videoDescription={videoDescription} thumbnail={thumbnail} autoPlay={autoPlay} videoControls={videoControls} loop={loop} />
      default:
         return <RenderDefaultStyle videoSource={videoSource} thumbnail={thumbnail} autoPlay={autoPlay} videoControls={videoControls} loop={loop} />;
   }
}
