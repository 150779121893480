import React, { Fragment, memo, useEffect, useRef, useState } from "react";
import OopsBanner, { NoRecordsBanner } from "../OopsBanner";
import CustomMultiSelect, { CheckSelect } from "../CustomMultiSelect";
import { GetBusinessProducts, getProductBrands, getProductTypes } from "../Utilities/BusinessData";
import { UserContext } from "../Definitions/UserContext";
import EditorLoadingSpinner from "../EditorLoadingSpinner";
import ReactDom from "react-dom";
import { utils } from "../../utils/Utils";
import RenderProduct from "../../VagaroComponent/ProductDetails"
import ProductDetails from "../../VagaroComponent/ProductDetails";
import MultiLocationDropdown from "../../VagaroComponent/MultiLocationDropdown";

export default function RenderBusinessProducts({ data, layout, userContext, allowMultiLoc }: { data: any, layout: string, userContext: UserContext, allowMultiLoc: any }) {


    const [bData, setBData] = useState<any>(data);
    const [searchText, setSearchText] = React.useState("");
    const [typeFilters, setTypeFilters] = React.useState<string[]>([]);
    const [brandFilters, setBrandFilters] = React.useState<string[]>([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [page, setPage] = React.useState(1);
    const [lastOpened, setLastOpened] = React.useState(0);
    const [availableTypes, setAvailableTypes] = React.useState<null | { label: string, value: string }[]>(null);
    const [availableBrands, setAvailableBrands] = React.useState<null | { label: string, value: string }[]>(null);
    const searchRef = React.useRef<HTMLInputElement>(null);
    const loadingArr = [{ label: "Loading...", value: "loading" }];

    const businessIndex = GetIndexOfBusinessIdFromMultiLocation(userContext.businessId, userContext);
    const isStencil = bData?.isStenciled;
    const stencilClass = (bData?.isStenciled === true) ? "stenciled" : "";
    const totalPages = bData?.totalPages ?? 1;
    const scrollToRef = useRef(null);
    const [udpateState, setUpdateSate] = React.useState(0);


    const tryScollUp = () => {

        if(scrollToRef.current)
       ( scrollToRef.current as any).scrollIntoView({ behavior: "smooth" });
    }

    const updateAvailable = () => {

        setAvailableBrands(loadingArr);
        setAvailableTypes(loadingArr);

        updateProductTypes();
        updateBrands();

    }

    useEffect(() => {
        if (isStencil && !data.isStenciled) {
            setBData(data);
            updateAvailable();

        }

    })

    const updateBrands = () => {

        const filters = typeFilters.length > 0 && typeFilters[0] != "loading" ? typeFilters : [];
        getProductBrands(userContext, (data: any) => {
            try {
                data = data.data.data;
                let newBrands = [];
                for (let i = 0; i < data.length; i++) {
                    newBrands.push({ label: data[i].brandName, value: data[i].brandIDs });
                }
                setAvailableBrands(newBrands);
                if (availableTypes != loadingArr)
                    setIsLoading(false);
            }
            catch (e) {
                console.warn("error loading brands", e);
            }

        }, (data: any) => {

        }, filters);


    }

    const updateProductTypes = () => {
        getProductTypes(userContext, (data: any) => {
            try {
                let newTypes = [];
                for (let i = 0; i < data.length; i++) {
                    newTypes.push({ label: data[i].productTypeName, value: data[i].productTypeIDs });
                }
                setAvailableTypes(newTypes);
                if (availableBrands != loadingArr)
                    setIsLoading(false);
            }
            catch (e) {
                console.warn("error loading types", e);
            }
        }, (data: any) => { });

    }

    useEffect(() => {
        if (availableTypes == null && availableBrands == null && !isStencil) {
            //set to non null value to flag as loading (avoid duplicates)
            updateAvailable();

        }

    }, [])

    useEffect(() => {
        if (!isStencil)
            updateBrands();

    }, [typeFilters])

    useEffect(() => {

        let selectedBrands = brandFilters;

        selectedBrands = selectedBrands.filter((v) => availableBrands?.find((o) => o.value == v));
        if (!isStencil)
            setBrandFilters(selectedBrands);


    }, [udpateState])

    useEffect(() => {

        if (page > totalPages && !isStencil)
            setPage(1);

    }, [bData])

    useEffect(() => {
        if (!isStencil) {

            UpdateInventoryByFilters(searchText);
        }


    }, [typeFilters, brandFilters, page])

    const UpdateInventoryByFilters = (text: string) => {

        setIsLoading(true);
        let sText = text ?? searchText;
        GetBusinessProducts(userContext, (data: any) => {
            setBData(data);
            setIsLoading(false);
        }, (data: any) => {
            console.warn("error loading products");
        }, page, 50, typeFilters, brandFilters, sText);
    }

    const items = bData?.records ?? [];
    const initSearch = () => {
        let text = searchRef.current?.value ?? ""
        setSearchText(text);
        setLastOpened(0);
        UpdateInventoryByFilters(text);
    }


    const PaginationRow = () => {

        const linkStyle = { textDecoration: "underline", padding: "0px 10px" }

        return <div className="flex-row justify-center gam-med w-full">
            {page > 1 && <button className=" invisible-button v-link" onClick={() => { setPage(page - 1); UpdateInventoryByFilters(searchText);tryScollUp(); }} style={linkStyle}>Previous</button>}

            {page > 1 && <button className="invisible-button v-link" onClick={() => { setPage(page + 1); UpdateInventoryByFilters(searchText);tryScollUp(); }} style={linkStyle}>{page - 1}</button>}

            {totalPages > 1 && <div className="center-all v-button" style={{ padding: "10px" }}>{page}</div>}

            {page < totalPages && <button className="invisible-button v-link" onClick={() => { setPage(page + 1); UpdateInventoryByFilters(searchText); tryScollUp();}} style={linkStyle}>{page + 1}</button>}

            {page < totalPages && <button className="invisible-button v-link" onClick={() => { setPage(page + 1); UpdateInventoryByFilters(searchText); tryScollUp();}} style={linkStyle}>Next</button>}



        </div>

    }

    const FilterRows = ({ vertical }: { vertical: boolean }) => {

        const cls = (vertical) ? "w-full" : "third full-width-mobile";


        return (
            <div className={"product-search-filter-row full-width-mobile  " + stencilClass + " " + (vertical ? "flex-column gap-med justify-start  full-width-tablet" : "")} style={(vertical) ? { flexShrink: "0", width: "275px", padding: "12px", top: "0",justifyContent:"flex-start" } : {}} >
                <div className={"product-search-filter-row full-width-mobile " + (vertical ? "flex-column gap-med justify-start  full-width-tablet" : "")}>
                    <div className={"relative " + cls}>

                        <input ref={searchRef} defaultValue={searchText} placeholder="Search" type="text" className=" w-full" style={{ background: "white", border: "1px solid rgb(199, 199, 199)", borderRadius: "5px", color: "#333", padding: "8px 8px 8px 32px" }}

                            onKeyDown={(e) => {
                                if (e.key == "Enter")
                                    initSearch();

                            }}

                        ></input>
                        <SearchIcon />
                    </div>
                    <div className={"relative " + cls}>
                        {vertical && false ?
                            <div className="flex-column gap-med">
                                <br></br>
                                <label><b>Product Type</b></label>
                                {availableTypes?.map((item: any, index: number) => {

                                    return <Fragment key={index}>
                                        <div>
                                            <input type="checkbox" id="vehicle1" value="Bike" />
                                            <label style={{ marginLeft: "8px" }}  >{item.label} </label>
                                        </div>
                                    </Fragment>


                                })}


                            </div> :
                            <>
                                <CustomMultiSelect key={"multiSelect1" + udpateState} startOpen={lastOpened == 1} options={availableTypes ?? []} footer={null} placeholder="Select Product Type" defaultValues={typeFilters}

                                    onChange={(values) => {
                                        setTypeFilters(values); setLastOpened(1); setSearchText(searchRef.current?.value ?? ""); setUpdateSate(udpateState + 1);


                                    }} />
                            </>
                        }

                    </div>
                    <div className={" relative " + cls}>
                        {/* {vertical&&false?
                    <div className="flex-column gap-med">
                        <br></br>
                    <label className="stencil-item"><b>Brand Name</b></label>
                        {availableBrands?.map((item: any, index: number) => {

                            return <Fragment key={index}>
                                <div>
                              <input type="checkbox" id="vehicle1"  value="Bike" />
                              <label style={{marginLeft:"8px"}}  >{item.label} </label>
                              </div>
                            </Fragment>


                        })}

                        <br></br>
                    </div>: */

                            <>
                                <CustomMultiSelect startOpen={lastOpened == 2} key={"multiSelect2" + udpateState} options={availableBrands ?? []} footer={null} placeholder="Select Brand" defaultValues={brandFilters}
                                    onChange={(values) => { setBrandFilters(values); setLastOpened(2); setSearchText(searchRef.current?.value ?? ""); setUpdateSate(udpateState + 1) }} />
                            </>
                        }
                    </div>
                </div>
                <div className={cls} style={{ padding: "4px" }}>
                    <button role="button" className="v-button primary invisible-button w-full" onClick={initSearch}>Search</button>
                </div>
            </div>
        )
    }

    //if no records found when no filters/search present busienss does not have any online products to display
    if(page==1&&items.length==0&&typeFilters.length==0&&brandFilters.length==0&&searchText==""){
        return <NoRecordsBanner showInLive={false} message="No Products Found"/>
    }

    switch (layout) {
        case "2":
            return <>
            <span ref={scrollToRef}></span>
                <div className="flex-row-to-column-tablet align-start w-full">
                    <FilterRows vertical={true} />
                    <div className="flex-row align-start flex-wrap w-full">
                        {(!items || items.length == 0) && <NoRecordsBanner showInLive={true} message='No records found.' />}
                        {items.map((item: any, index: number) => {
                            return <RenderProductItem item={item} key={index} siteData={userContext} vertical={true} stencilClass={stencilClass} businessIndex={businessIndex} allowMultiLoc={allowMultiLoc} />
                        })
                        }
                        <PaginationRow />
                        {isLoading && <div className="center-all w-full h-full" style={{ position: "absolute", top: "0", left: "0", background: "#00000077", zIndex: "10" }}><EditorLoadingSpinner /></div>}

                    </div>
                </div>
            </>

        default:
            return <>
        <span ref={scrollToRef}></span>
                <FilterRows vertical={false} />
                {(!items || items.length == 0) && <NoRecordsBanner showInLive={true} message='No records found.' />}
                {items.map((item: any, index: number) => {
                    return <RenderProductItem item={item} key={index} siteData={userContext} vertical={false} stencilClass={stencilClass} businessIndex={businessIndex} allowMultiLoc={allowMultiLoc} />
                })
                }
                <PaginationRow />
                {isLoading && <div className="center-all w-full h-full" style={{ position: "absolute", top: "0", left: "0", background: "#00000077", zIndex: "10" }}><EditorLoadingSpinner /></div>}
            </>

    }
}

export function GetBusinessIdByIndex(index: number, userContext: UserContext) {

    if (userContext.MultilocationData && userContext.MultilocationData.length > index) {
        return userContext.MultilocationData[index].encBID;
    }
    return userContext.businessId;
}

export function GetIndexOfBusinessIdFromMultiLocation(businessId: string, userContext: UserContext) {


    if (userContext.MultilocationData && userContext.MultilocationData.length > 0) {
        for (let i = 0; i < userContext.MultilocationData.length; i++) {
            if (userContext.MultilocationData[i].encBID == businessId)
                return i;
        }
    }

    return 0;
}

function RenderProductItem({ item, siteData, vertical, stencilClass, businessIndex, allowMultiLoc }: { item: any; siteData: UserContext, vertical: boolean, stencilClass: string, businessIndex: number, allowMultiLoc: any }) {


    if (!item.name)
        item.name = "Unamed";
    if (!item.brandName)
        item.brandName = "";
    if (!item.price) {

        //no valid price use the productPriceWithBrand Value instead
        item.price = (item.productPriceWithBrand) ? item.productPriceWithBrand : "";

    }

    if (item.productPriceWithBrand && item.productPriceWithBrand.includes("/")) {
        item.price = item.productPriceWithBrand.split("/")[1];

    }


    var IsEditor = (window as any).isEditor !== undefined ? (window as any).isEditor : false;

    return <a href={(IsEditor)?"#":getProductLinkForHref(item,siteData,"","")} className={"product-card flex-column quarter-third-half-full no-a " + stencilClass} style={{ padding: "10px", color: "inherit", cursor: "pointer", }}
        onClick={(e) =>{e.preventDefault(); !IsEditor && (window as any).renderProdct(item, siteData, allowMultiLoc, true, "", "");}}
    >

        <div className="stencil-item" style={{ width: "100%", paddingBottom: (vertical) ? "100%" : "125%", marginBottom: "4px", backgroundImage: `url(${item.imageUrl})`, backgroundSize: "cover", backgroundPosition: "center", flexShrink: "0", border: "1px solid #ccc",borderRadius:(vertical?'4px':"0px") }}></div>
        <div className="stencil-item max-content"><b>{item.name}</b></div>
        <div className="stencil-item max-content">{item.brandName}</div>
        <div className="stencil-item max-content">{item.price}</div>

    </a>

}

declare global {
    interface Window { renderProdct: any; }
}

window.renderProdct = function (Item: any, siteData: any, allowMultiLoc: any, isopen: boolean, businessId: any, inventoryID: any) {

    if (allowMultiLoc && isopen !== false) {
        const multiGroupInventory = Item.multiGroupInventory;
        let Multilocations: any[] = [];
        var businessData = JSON.parse(multiGroupInventory);
        if (!utils.isNullEmptyOrWhiteSpace(multiGroupInventory)) {
            businessData.map((data: any, index: any) => {
                let Data: any[];
                Data = siteData.MultilocationData.filter(
                    (col: any) => col.encBIDHash == data.BID
                );
                if (Data.length > 0) {
                    Multilocations.push(Data[0]);
                }
            });
        }
        if (Multilocations.length > 1) {
            return ReactDom.render(
                <MultiLocationDropdown
                    Sitedata={Multilocations}
                    OnClickEvent={window.renderProdct}
                    Mode={"productAddCart"}
                    userContext={siteData}
                    Item={Item}
                    allowMultiLoc={allowMultiLoc}
                    isopen={isopen}
                    businessId={businessId}
                    IsShowMap={true}
                />,
                document.getElementById("OpenDialogMultiLocation")
            );
        }
    }

    const BusinessData = utils.MultiLocationData(siteData, siteData.bidHash);
    const linkID = inventoryID != undefined && inventoryID != null && inventoryID != "" ? inventoryID : Item.inventoryIds
    const encBid = businessId != undefined && businessId != null && businessId != "" ? businessId : BusinessData.encBID
    let alink = (Item.isBundle) ? `product-details/bundle/${encBid}/${linkID}` : `product-details/${encBid}/${linkID}`;

    //carry over preview link
    if(window.location.href.endsWith("/preview")||window.location.href.endsWith("/preview/"))
        alink+="/preview";

    window.location.href = alink;
};

function getProductLinkForHref(Item: any, siteData: any, businessId: any, inventoryID: any){
    const BusinessData = utils.MultiLocationData(siteData, siteData.bidHash);
    const linkID = inventoryID != undefined && inventoryID != null && inventoryID != "" ? inventoryID : Item.inventoryIds
    const encBid = businessId != undefined && businessId != null && businessId != "" ? businessId : BusinessData.encBID
    const alink = (Item.isBundle) ? `product-details/bundle/${encBid}/${linkID}` : `product-details/${encBid}/${linkID}`;
   
    return alink;


}


function SearchIcon() {
    return <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" fill="#333" style={{ position: "absolute", left: "14px", top: "16px", pointerEvents: "none" }}><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" /></svg>
}