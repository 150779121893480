import React from "react";
import ReactDOM from "react-dom";

export default function VagaoIframe (){

    const closeVagaroIframe = () => {
        let frame = document.getElementById("divVagaroIframe");
        if(frame){
            frame.classList.remove("active");
            ReactDOM.unmountComponentAtNode(frame);
        }
    }

    return <div className="divVagaroIframeContainer"id="vagaroIframeContainer"> <div className="vagaroIframe" id="divVagaroIframe">
       
    </div></div>



}