import React from 'react';
// Import other necessary dependencies and components

import RenderStarRating from "../../Icons/StarRating";
import Dropdown from 'react-bootstrap/Dropdown';

import StaffReview from "../../../Hooks/StaffReview"
import { addtoCartEnum, utils } from "../../../utils/Utils";
import StaffMessage from "../../../Hooks/StaffMessage";
import { UserContext } from "../../Definitions/UserContext";
import StencilRows from "../../Binding/StencilRows";
import { AsyncAjaxGet } from "../../Utilities/AsyncAjaxService";
import styled from 'styled-components';

const avatarSize = "4.5em";
const avatarStyle = { width: avatarSize, height: avatarSize, borderRadius: "50%" };



interface StyledImageContainerProps {
    photoURL: string;
}

// Define the styled component for Flexbox layout



const ProfileCard = styled.div`
  position: relative;
 

  transition: transform 0.3s ease; // Transition for transform
  cursor: pointer;
  &:hover {
    & > .TestDiv {
      opacity: 1;
      visibility: visible;
    }
  }

  /* Mobile */
  @container (min-width: 320px) {
      margin: 8px auto;
      width: 180px;
      height: 180px;
     
  }

  @media (min-width: 320px) {
    margin: 8px auto;
    width: 180px;
    height: 180px;
}
  /* Tablet */
  @container (min-width: 600px) {
      margin: 12px auto;
      width: 180px;
      height: 180px;
     
  }
  @media (min-width: 600px) {
    margin: 12px auto;
      width: 180px;
      height: 180px;
      
}
  /* Desktop */
  @container (min-width: 1024px) {
      margin: 12px auto;
      width: 200px;
      height: 200px;
       
  }

  @media (min-width: 600px) {
    margin: 12px auto;
      width: 200px;
      height: 200px;
    
}
`;

// Styled component
const StyledImageContainer = styled.div<StyledImageContainerProps>`
  background: url(${props => props.photoURL}) no-repeat center center;
  background-size: cover;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: url(${props => props.photoURL}) no-repeat center center;
  background-size: cover;
  filter: brightness(100%);

  /* Mobile */
  @container (min-width: 360px) {
      width: 100%;
      height: 100%;
  }
  /* Tablet */
  @container (min-width: 768px) {
      width: 100%;
      height: 100%;
  }
  /* Desktop */
  @container (min-width: 1099px) {
      width: 100%;
      height: 100%;
  }
 
`;

const StyledH4 = styled.h4`
  
/* Mobile */
@media (min-width: 360px) {
  word-break: break-word;
 
  padding-top: 12px;
  color: #333333 !important;
  text-align: center; // Centering text
  
}

/* Tablet */
@media (min-width: 768px) {
  word-break: break-word;
  
  padding-top: 12px;
  color: #333333 !important;
  text-align: center; // Centering text
  
}

/* Desktop */
@media (min-width: 1099px) {
  word-break: break-word;
 
  padding-top: 12px;
  color: #333333 !important;
  text-align: center; // Centering text
  
}
  `;

const ButtonContainer = styled.div`
    width: 100%;
    text-align: center;
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.25rem;

  // Mobile
  @media (min-width: 667px) {
    flex-direction: column;
  }

  @container (min-width: 667px) {
    flex-direction: column;
    justify-content: center;
   
    items-align: center;
    margin: 0 auto;
    width: 180px;
  }

  // Tablet
  @media (min-width: 668px) and (max-width: 1098px) {
    flex-direction: column;
    justify-content: center;
   
    items-align: center;
    margin: 0 auto;
    width: 180px;
  }

      // Tablet
  @container (min-width: 668px) and (max-width: 1098px) {
    flex-direction: column;
    justify-content: center;
    
    items-align: center;
    margin: 0 auto;
    width: 180px;
  }
  // Desktop
  @media (min-width: 1099px) {
    flex-direction: column;
    justify-content: center;
   
    align-items: center;
    margin: 0 auto;
    margin: 0 auto;
    width: 180px;
  }
  @container (min-width: 1099px) {
    flex-direction: column;
    justify-content: center;
  
    items-align: center;
    margin: 0 auto;
    width: 180px;
  }
`;


const TestDiv = styled.div`
  position: absolute; 
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0; // Initially hidden
  visibility: hidden; // Initially hidden
  transition: opacity 0.3s ease, visibility 0.3s ease; // Smooth transition
  
`;


export default function RenderStaffMemberSmallCircles({ staffMember, siteData, isStenciled, serviceList, layout }: { staffMember: any, siteData: UserContext, isStenciled: boolean, serviceList: any, layout: string }) {

    //validate data and replace with empty strings to avoid extra checks during rendering
    if (!staffMember.businessSummary)
        staffMember.businessSummary = "";
    if (!staffMember.spLargePhotoUrl)
        staffMember.spLargePhotoUrl = "";
    if (!staffMember.reviewRank)
        staffMember.reviewRank = 0;
    if (!staffMember.reviewCount)
        staffMember.reviewCount = 0;
    if (!staffMember.firstName)
        staffMember.firstName = "";
    if (!staffMember.lastName)
        staffMember.lastName = "";

    var IsEditor = (window as any).isEditor !== undefined ? (window as any).isEditor : false;
    var BusinessData = utils.MultiLocationData(siteData, siteData.bidHash);
    return (
        <>
       
            <div className="p-4 " onClick={(event) => {
                if(event.target&&(event.target as HTMLElement).closest(".dropdown"))
                    return;
            !IsEditor && (window as any).Staffreview(staffMember, siteData)}}>
                <ProfileCard  >

                    {/* Full-width image at the top */}
                    {staffMember.photoURLForOriginal ?
                        <StyledImageContainer photoURL={staffMember.photoURLForOriginal} className='staff-image-border' />
                        :
                        <div className='staff-image-border' style={{ width: "100%", height: "100%", background: "#e7e7e7", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "50%" }}>
                            <span style={{ fontSize: "3em", color: "#FFF" }}>{staffMember.spintials}</span>
                        </div>
                    }
                    <TestDiv className="TestDiv" >
                        <ButtonContainer className="center-all">
                            {staffMember.isShowMessage &&
                                <button className="btn btn-lg btn-light p-2 v-button secondary" onClick={(e) => {e.stopPropagation(); !IsEditor && (window as any).StaffMessage(staffMember, siteData, BusinessData)}} style={{ width: "140px", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <i className="fal fa-envelope bicon px-1"></i>Message
                                </button>
                            }

                            {serviceList > 0 && staffMember.isAllowBook ? staffMember.isServiceTab && staffMember.isClassTab ?
                                <Dropdown>
                                    <Dropdown.Toggle bsPrefix="invisible-button v-button primary full-width-mobile" id="dropdown-basic"style={{ height: "48px", width: "140px", zIndex: "2"}}>
                                        {staffMember.bookText}<i className="bicon2 fal fa-angle-down px-1"></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu style={{ zIndex: "999" }}>
                                        <Dropdown.Item onClick={(e) => {e.stopPropagation(); !IsEditor && (window as any).BookStaffSerice(staffMember, BusinessData)}} className="no-a">Services</Dropdown.Item>
                                        <Dropdown.Item onClick={(e) => {e.stopPropagation(); !IsEditor && (window as any).BookStaffClass(staffMember, BusinessData)}} className="no-a">Classes</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                :
                                <button onClick={(e) => {e.stopPropagation(); !IsEditor && (window as any).BookServiceClass(staffMember, BusinessData)}} className="btn btn-lg btn-light p-2 v-button primary" style={{ width: "140px", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>{staffMember.bookText}</button>
                                : ""}
                      </ButtonContainer>
                    </TestDiv>
                </ProfileCard>

                <h4 style={{ textAlign: "center", zIndex: "1" }}>{staffMember.firstName} {staffMember.lastName}</h4> 

            </div>

        </>
    )
}