import React, { useEffect, useRef, useState } from "react";
import "../../src/components/Styles/PhotoGallary.scss";
import { Overlay } from "react-bootstrap";
import VgSendEmailPopup from "./SendEmailPopup";
import { AsyncAjaxGet, AsyncAjaxPut } from "../components/Utilities/AsyncAjaxService";
import Loader from "../components/Common/Loader";
import { addtoCartEnum, utils } from '../utils/Utils';
import "../../../vagaro-website-rendering/src/utils/sb-socialmediasharing";
import {  unmountComponentAtNode } from "react-dom";
import $ from "jquery";
import Dropdown from 'react-bootstrap/Dropdown';
import _siteBuilderSocialMedia from "../../../vagaro-website-rendering/src/utils/sb-socialmediasharing";
const PhotoGallaryPopupView = (props) => {
  const [isLoading, setLoading] = useState(false);
  const VagaroAPIEnum = {
    getPhotos: `merchants/${props.userContext.businessId}/photos/getStylePhotos`,
    udapteLikes: `merchants/${props.userContext.businessId}/photos/likestyle`
  };
  const { serviceID, serviceProviderID, serviceTitle, photoName, price, styleID, photoDescription } = props.Data
  const { businessId, accessToken } = props.userContext;
  const [likeCounter, setLikeCounter] = useState(props.Data.likeCounter);
  const [isLikeByUser, setIsLikeByUser] = useState(props.Data.isLikeByUser);
  const [show, setShow] = useState(false);
  const [images, setImages] = useState([]);
  const [provider, setProvider] = useState("");
  const Image = props.Data.photoUrl;
  const url = props.Data.photoUrl.split("/Original/")[0]
  const { businessName, businessID, vagaroURL, stateCode, city, currencySymbol, email } = props.businessData;
  
  const GetImages = async () => {
    if (images.length == 0) {
      setLoading(true)
      await AsyncAjaxGet(
          VagaroAPIEnum.getPhotos + `?EncStyleID=${props.Data.styleID}`,
        props.userContext.accessToken,
        function onSuccess(data) {
          if (data != null,
            data.data !== null,
            data.data.data !== null,
            data.data.data[0] !== null) {
            var array = data.data.data[0].split("|##|");
            var Provider = array[1];
            var imageDetails = array[0].split("|~|");
            var ImagesArry = imageDetails.map((x) => ({ name: x.split("|~~|")[0], image: x.split("|~~|")[1] }));
            setImages(ImagesArry)
            setProvider(Provider)
            setLoading(false)
          }
        },
        function OnError(data) {
          console.log("error")
        })
    }
  }
  const CustomShareToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        className='v-button secondary'
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </a>
));
const ClosePswp = () =>{
  var buttonClose = document.querySelector('.pswp__button--close');
  if (buttonClose) {
    buttonClose.click();
  }
}
const IfremOpen = () =>{
  ClosePswp();
  if (props.Grid || props.openFrom == "Popup") {
    unmountComponentAtNode(document.getElementById("OpenDialog"));
  }
}
const booknowPortfolio = () => {
  var jsonData = JSON.stringify({ encserviceid: serviceID, encspid: serviceProviderID, encstyleid:styleID, styletitle: serviceTitle}).replace(/\\\"/g, '"').replace(/"/g, "\\\"");
  window.wbc.openVagaroPage("divVagaroIframe", utils.VURL(null, vagaroURL) + addtoCartEnum.BookNowPortfolio + "&Data=" + jsonData,IfremOpen);
  IfremOpen();
  return;
};
  
  const openShare = (e,from) => {

    e.preventDefault();
    const imageUrl = Image.replace("/Original/", "Style/Original/"); // URL to the image you want to share
    const title = window.location.protocol + '//' + window.location.host; // Title for the shared content
    const description = serviceTitle; // Description for the shared content

    // Encode the parameters for the URL
    const encodedImageUrl = encodeURIComponent(imageUrl);
    const encodedTitle = encodeURIComponent(title);
    const encodedDescription = encodeURIComponent(description);

    // Facebook sharing link
    const facebookShareLink = `https://www.facebook.com/sharer/sharer.php?display=popup&u=${encodedImageUrl}&quote=${encodedTitle + encodedDescription}`;

    var facebook = {
      id:"",
      callfrom:"Photos",
      Address:"",
      BusinessName:businessName,
      BusinessUrl: "",
      PinUrl: window.location.protocol + '//' + window.location.host ,
      Servicetitle: serviceTitle,
      Styletitle:photoName,
      businessID:businessID,
      image:Image.replace("/Original/", "Style/Original/"),
      msg: 'Portfolio has been posted.',


    }

    var twitter = {
      url:`url=${window.location.protocol + '//' + window.location.host + window.location.pathname}&text=Check out what I found @${businessName} - ${provider}&related=vagaro`
    }
    var pinterest = {
      url:`${window.location.protocol + '//' + window.location.host}&text=${businessName} - ${photoName}&media=${Image.replace("/Original/", "Style/Original/")}&description=${serviceTitle}`,
      queryString : `${window.location.protocol + '//' + window.location.host}&text=${businessName} - ${photoName}&media=${Image.replace("/Original/", "Style/Original/")}&description=${serviceTitle}`
    }
    // Calculate the left and top positions to center the window
      const left = window.innerWidth / 2 - 600 / 2;
      const top = window.innerHeight / 2 - 500 / 2;
      const features = `width=${600},height=${500},left=${left},top=${top}`;

    if( from === "facebook"){
      if (navigator.userAgent && navigator.userAgent.toLowerCase().includes("iphone")) {
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodedImageUrl}&quote=${encodedTitle + encodedDescription}`, "pop", "width=600, height=400, scrollbars=no");
      }else{
        var jsonData = JSON.stringify(facebook).replace(/\\\"/g, '"').replace(/"/g, "\\\"");
        var fbShareURl = utils.VURL(null, "merchants/facebookPost","facebookshare")
        window.wbc.openVagaroPage("divVagaroIframe", fbShareURl + "?Type=Builder&Event=FaceBookShare&Data=" + jsonData);
      }
      return;
    }
    if( from === "twitter"){ 
      _siteBuilderSocialMedia.PostOnTwitter(twitter.url); 
    }
    if( from === "pinterest" ){
      _siteBuilderSocialMedia.PostOnPint(pinterest.url); 
    }
  }
  const UpdateLike = async (data, event) => {
    let LoginDetails = utils.ReadCookie();
    if (LoginDetails.EncUid === undefined || LoginDetails.EncUid === "" || LoginDetails.EncUid === null) {
      window.wbc.openVagaroPage("divVagaroIframe", utils.VURL("Login", null) + addtoCartEnum.GalleryLike + "&Data=" + event,IfremOpen);
      return;
    }
    var likeUpdate = isLikeByUser == 0 ? 1 : 0;
    const previousState = isLikeByUser;
    const previousCount = likeCounter;
    var user = LoginDetails.EncUid;
    if (previousState == 0) {
      setIsLikeByUser(likeUpdate)
      setLikeCounter(likeCounter + 1)
      props.Data.isLikeByUser = user
      props.Data.likeCounter = likeCounter + 1
    }else{
      setIsLikeByUser(likeUpdate)
      setLikeCounter(likeCounter - 1)
      props.Data.isLikeByUser = 0
      props.Data.likeCounter = likeCounter - 1
    }
    var requset = {
      StyleID: data.styleID,
      UserID: user,
      IsLike: likeUpdate,
      UserType: props.userContext.userType.toString()
    };
    await AsyncAjaxPut(
      VagaroAPIEnum.udapteLikes,
      props.userContext.accessToken,
      requset,
      function onSuccess(data) {
        if (data.data.message === "Success") {
          if (props.StyleLikeChanges) {
            props.StyleLikeChanges.current = true;
          }
          if (previousState == 0) {
            props.Data.isLikeByUser = user 
            setIsLikeByUser(user)
            props.Data.likeCounter = previousCount + 1
            setLikeCounter(previousCount + 1)
          }else{
            props.Data.isLikeByUser = 0 
            setIsLikeByUser(0)
            props.Data.likeCounter = previousCount - 1
            setLikeCounter(previousCount - 1)
          }

        } else {
          previousState(previousState)
        }
      },
      function OnError(data) {
        console.log("error")
      })
  }
 
  const backtap = useRef(true) 
  const Container = useRef(null) 
  useEffect(() => {
    GetImages()
    if (!props.Grid) {
      const Element = props.element;
      Container.current = Element.querySelector('.htmlContainerPhoto');
      Element.addEventListener('wheel', function(event) {
        const Containers = Container.current;
        const scrollAmount = 30; 
        if (event.deltaY > 0) {
          Containers.scrollTop += scrollAmount
        } else if (event.deltaY < 0) {
          Containers.scrollTop -= scrollAmount
        }
      });
    }
    window.addEventListener('pointerdown', function(e){ 
      const content = document.getElementsByClassName("htmlContainerphoto");
      const Models = document.getElementsByClassName("vgn-popup");
      const buttons = document.getElementsByClassName("pswp__button");
      var Clsnames = e.target.className ? e.target.className.toString() : "" ;
      var clickOut = true;
      if (e.target){
        for (let index = 0; index < content.length; index++) {
          const obj = content[index];
          if (obj.contains(e.target)) {
            clickOut = false
          }
        }
        for (let index = 0; index < buttons.length; index++) {
          const btn = buttons[index];
          if (btn.contains(e.target)) {
            clickOut = false
          } 
        }
        for (let index = 0; index < Models.length; index++) {
          const model = Models[index];
          if (model.contains(e.target)) {
            clickOut = false
          } 
        }
        if (Clsnames.includes("pswp__button") || Clsnames.includes("pswp__img") || Clsnames.includes("pswp__img") || Clsnames.includes("btn-close") || Clsnames.includes("w-button") || Clsnames.includes("vgn-popup")|| Models.length > 1) {
          clickOut = false
        }
      }
      if (clickOut && backtap.current ) {
          backtap.current = false
          ClosePswp()
          setTimeout(() => {
            backtap.current = true
          }, 20);
      }
    });
  }, []);

  const handleShow = () => {
    setShow(!show);
    props.changehover(!show)
  }
  const handleShowRemoved = () => {
    setShow(false);
    props.changehover(false)
  }
  const target = useRef(null);

  const VgThemes =()=>{
    var styleobject = {};
        var gblstyle = $("#dvglbstyle");
        var primarybutton  = gblstyle.find("#btnprimarybutton");
        var secondorybutton  = gblstyle.find("#btnsecondary");
        var primarybuttonHover  = gblstyle.find("#btnprimarybuttonhover");
        var secondorybuttonHover  = gblstyle.find("#btnsecondarybuttonhover");  
        var lblstyle   = gblstyle.find("#txtfontfamily");
        var btnrating  =gblstyle.find("#btnrating");
        var glbbackground = gblstyle.find("#glbbackground");
        styleobject.primarybuttoncolor = primarybutton.css("color");
        styleobject.primarybuttonbackgroundcolor = primarybutton.css("background-color");
        styleobject.primarybuttonHoverbackgroundcolor = primarybuttonHover.css("background-color");
        styleobject.primarybuttonborderradius = primarybutton.css("border-radius");
        styleobject.primarybuttonboxshadow = primarybutton.css("box-shadow");
        styleobject.secondorybuttonbuttoncolor = secondorybutton.css("color");
        styleobject.secondorybuttonbuttonbackgroundcolor = secondorybutton.css("background-color");
        styleobject.secondorybuttonbuttonHoverbackgroundcolor = secondorybuttonHover.css("background-color");    
        styleobject.secondorybuttonbuttonborderradius = secondorybutton.css("border-radius");
        styleobject.secondorybuttonbuttonboxshadow = secondorybutton.css("box-shadow"); 
        styleobject.lblstylefontfamily = lblstyle.css("font-family");
        styleobject.lblstylecolor = lblstyle.css("color");  
        styleobject.lblstylefontweight = lblstyle.css("font-weight");
        styleobject.lblstylefontsize = lblstyle.css("font-size");
        styleobject.btnratingcolor = btnrating.css("color");
        styleobject.glbbackgroundcolor = glbbackground.css("background-color");
    return(
        <>
            <style>
                {
                    `:root{
                        --vg-primary-bg-modal:${styleobject.glbbackgroundcolor};
                        --vg-body-text-color-modal:${styleobject.lblstylecolor};
                    }
                    `
                }
            </style>
        </>
    )
    
}


  return (
    <>
      {!props.Grid ?
        <>
          {isLoading && <Loader />}
          <div className="pswp-html-content">
            <VgThemes/>
            <div className={`${images.length > 1? "htmlContainerPhotoMulti htmlContainerPhoto":"htmlContainerPhoto"} `}>
            
              <div className="htmlcontitemmax">
              
              <div className="gallary-top-meta">
                <div className="left-metas">
                  <div
                    id={`imgLikePopUp-${props.Data.styleID}`}
                    onClick={(event) => UpdateLike(props.Data, event.currentTarget.id)}
                    className={`btnlike button-icon v-button secondary  ${isLikeByUser === 0 ? "" : "liked"}`}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z" /></svg>

                  </div>
                  <div
                    className="send-button"
                    id={`divSharePopUp-${props.Data.styleID}`}
                  >
                    <Dropdown>
                      <Dropdown.Toggle as={CustomShareToggle} id="dropdown-custom-components">
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" fill="currentColor"><path d="M503.691 189.836L327.687 37.851C312.281 24.546 288 35.347 288 56.015v80.053C127.371 137.907 0 170.1 0 322.326c0 61.441 39.581 122.309 83.333 154.132 13.653 9.931 33.111-2.533 28.077-18.631C66.066 312.814 132.917 274.316 288 272.085V360c0 20.7 24.3 31.453 39.687 18.164l176.004-152c11.071-9.562 11.086-26.753 0-36.328z" /></svg>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                      <div
                          {...props}
                          onMouseLeave={() => setShow(false)}
                          className="sharePopOver"
                          style={{
                            ...props.style,
                          }}
                        >
                          <div className="email-share-div">

                            <div className="email-text-field secondary-button">

                              <div className="text-block-22" onClick={() => setShow(false)}>
                                <VgSendEmailPopup type={1} Image={Image.replace("/Original/", "Style/Original/")} headername={serviceTitle} stateCode={stateCode} city={city} BusinessName={businessName} businessID={businessID} vagaroURL={vagaroURL} photoName={photoName} price={price} styleID={styleID} bid={businessId} accessToken={accessToken} CurrencySymbol={currencySymbol} likeCounter={likeCounter} />
                                <label htmlFor="">


                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="share-style-div">
                            <div className="text-block-23 web-body-font">
                              Share this style:
                            </div>
                            <div className="share-social-icon-div">

                              <div className="share-social-icon facebook font-awesome-brand" onClick={(e)=> openShare(e,"facebook")}>
                                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" /></svg>
                              </div>
                              <div className="share-social-icon twitter font-awesome-brand" onClick={(e)=> openShare(e,"twitter")}>
                                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" /></svg>
                              </div>
                              <div className="share-social-icon pinterest font-awesome-brand" onClick={(e)=> openShare(e,"pinterest")}>
                                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 496 512"><path d="M496 256c0 137-111 248-248 248-25.6 0-50.2-3.9-73.4-11.1 10.1-16.5 25.2-43.5 30.8-65 3-11.6 15.4-59 15.4-59 8.1 15.4 31.7 28.5 56.8 28.5 74.8 0 128.7-68.8 128.7-154.3 0-81.9-66.9-143.2-152.9-143.2-107 0-163.9 71.8-163.9 150.1 0 36.4 19.4 81.7 50.3 96.1 4.7 2.2 7.2 1.2 8.3-3.3.8-3.4 5-20.3 6.9-28.1.6-2.5.3-4.7-1.7-7.1-10.1-12.5-18.3-35.3-18.3-56.6 0-54.7 41.4-107.6 112-107.6 60.9 0 103.6 41.5 103.6 100.9 0 67.1-33.9 113.6-78 113.6-24.3 0-42.6-20.1-36.7-44.8 7-29.5 20.5-61.3 20.5-82.6 0-19-10.2-34.9-31.4-34.9-24.9 0-44.9 25.7-44.9 60.2 0 22 7.4 36.8 7.4 36.8s-24.5 103.8-29 123.2c-5 21.4-3 51.6-.9 71.2C65.4 450.9 0 361.1 0 256 0 119 111 8 248 8s248 111 248 248z" /></svg>
                              </div>
                            </div>
                          </div>
                        </div>
                       
                      </Dropdown.Menu>
                    </Dropdown>

                    
                  </div>
                </div>
                {props.Data.isOnlineService === true && props.Data.isShowPriceOnline===true && <div className="meta-btns">
                  <button className="invisible-button v-button primary btn btn-primary" style={{ marginRight: "10px" }} onClick={() => booknowPortfolio()}>Book</button>
                  <button className="invisible-button v-button primary btn btn-primary" onClick={() => booknowPortfolio()}>
                   {`${currencySymbol}${props.Data.price.toFixed(2)}` }
                  </button>
                </div>}
              </div>
              <div className="portfolio-cardcontent">
                <div id={`divServiceTitlePopup-${props.Data.styleID}`} className="heading">
                  {props.Data.photoName}
                </div>
                <div id={`divStylistName-${props.Data.styleID}`}>
                  by {provider}
                </div>
              </div>
              <div className="portfolio-images" id={`divStyleImages-${props.Data.styleID}`}>
                {images.map((data, index) => (
                  <div key={index} className="portfolio-image">
                    <img
                      className="img-fluid"
                      alt=""
                      src={
                        url + "Style/Original/" + data.image
                      }
                    />
                    <div className="title-dec">{data.name}</div>
                  </div>
                ))}
              </div>
              <div className="portfolio-dec">
                <div id={`divSalonNamePopUp-${props.Data.styleID}`} className="portfolio-dec-head">
                  {businessName}
                </div>
                <p id={`divStyleDescPopUp-${props.Data.styleID}`}>
                  {props.Data.photoDescription}
                 <br />
                {likeCounter > 0 && likeCounter + (likeCounter > 1 ? " Likes" :" Like")}
              
                </p>
              </div>

              
              </div>  
              
              
            </div>
          </div>
        </>
        :
        <>
          {isLoading && <Loader />}
          <div className="gallary-top-meta">
            <div className="left-metas">
              <div
                id={`imgLikePopUp-${props.Data.styleID}`}
                onClick={(event) => UpdateLike(props.Data,event.currentTarget.id)}
                className={`btnlike button-icon v-button secondary ${isLikeByUser === 0 ? "" : "liked"}`}
              >
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z" /></svg>

              </div>
              <div
                className="send-button"
                id={`divSharePopUp-${props.Data.styleID}`}
              >

                      

                <div
                  className="button-icon v-button secondary"
                  ref={target}
                  onClick={handleShow}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M503.691 189.836L327.687 37.851C312.281 24.546 288 35.347 288 56.015v80.053C127.371 137.907 0 170.1 0 322.326c0 61.441 39.581 122.309 83.333 154.132 13.653 9.931 33.111-2.533 28.077-18.631C66.066 312.814 132.917 274.316 288 272.085V360c0 20.7 24.3 31.453 39.687 18.164l176.004-152c11.071-9.562 11.086-26.753 0-36.328z" /></svg>
                </div>
                <Overlay target={target.current} show={show} placement="bottom-start">
                  {({
                    placement: _placement,
                    arrowProps: _arrowProps,
                    show: _show,
                    popper: _popper,
                    hasDoneInitialMeasure: _hasDoneInitialMeasure,
                    ...props
                  }) => (
                    <div
                      {...props}
                      onMouseLeave={handleShowRemoved}
                      className="sharePopOver"
                      style={{
                        ...props.style,
                      }}
                    >
                      <div className="email-share-div">

                        <div className="email-text-field secondary-button">

                          <div className="text-block-22" onClick={handleShowRemoved}>
                            <VgSendEmailPopup  likeCounter={likeCounter}  type={1} Image={Image.replace("/Original/", "Style/Original/")} headername={serviceTitle} stateCode={stateCode} city={city} BusinessName={businessName} businessID={businessID} vagaroURL={vagaroURL} photoName={photoName} price={price} styleID={styleID} bid={businessId} accessToken={accessToken} CurrencySymbol={currencySymbol} />
                            <label htmlFor="">


                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="share-style-div">
                        <div className="text-block-23 web-body-font">
                          Share this style:
                        </div>
                        <div className="share-social-icon-div">

                          <div className="share-social-icon facebook font-awesome-brand" onClick={(e)=> openShare(e,"facebook")}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" /></svg>
                          </div>
                          <div className="share-social-icon twitter font-awesome-brand" onClick={(e)=> openShare(e,"twitter")}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" /></svg>
                          </div>
                          <div className="share-social-icon pinterest font-awesome-brand" onClick={(e)=> openShare(e,"pinterest")}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 496 512"><path d="M496 256c0 137-111 248-248 248-25.6 0-50.2-3.9-73.4-11.1 10.1-16.5 25.2-43.5 30.8-65 3-11.6 15.4-59 15.4-59 8.1 15.4 31.7 28.5 56.8 28.5 74.8 0 128.7-68.8 128.7-154.3 0-81.9-66.9-143.2-152.9-143.2-107 0-163.9 71.8-163.9 150.1 0 36.4 19.4 81.7 50.3 96.1 4.7 2.2 7.2 1.2 8.3-3.3.8-3.4 5-20.3 6.9-28.1.6-2.5.3-4.7-1.7-7.1-10.1-12.5-18.3-35.3-18.3-56.6 0-54.7 41.4-107.6 112-107.6 60.9 0 103.6 41.5 103.6 100.9 0 67.1-33.9 113.6-78 113.6-24.3 0-42.6-20.1-36.7-44.8 7-29.5 20.5-61.3 20.5-82.6 0-19-10.2-34.9-31.4-34.9-24.9 0-44.9 25.7-44.9 60.2 0 22 7.4 36.8 7.4 36.8s-24.5 103.8-29 123.2c-5 21.4-3 51.6-.9 71.2C65.4 450.9 0 361.1 0 256 0 119 111 8 248 8s248 111 248 248z" /></svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Overlay>
              </div>
            </div>
            <div className="meta-btns">
              <button className="invisible-button v-button primary btn btn-primary" onClick={() => booknowPortfolio()}>Book</button>
            </div>
          </div>
          <a
            href={false}
            id={`vgphoto-m-item-${props.index}`}
            data-like={props.data.likeCounter}
            data-style={props.data.styleID}
            data-pswp-type={"html"}
            data-pswp-src={props.source}
            className="no-a"
          >
            <img alt="" src={props.source} width={props.width} />
          </a>
          <div className="portfolio-cardcontent">
            <div id={`divServiceTitlePopup-${props.Data.styleID}`} className="heading d-flex justify-content-between">
              <div className="text-block-4 mainNavTextColor web-subheading-font" id="divServiceTitle" itemprop="name">{props.Data.serviceTitle}</div>
            {props.Data.isOnlineService === true && props.Data.isShowPriceOnline===true &&  <div className="text-block-7 mainNavTextColor" id="divPrice"> { `${currencySymbol}${parseFloat(price).toFixed(2)}`}</div>}
            </div>
            <div id={`divStylistName-${props.Data.styleID}`}>
              {businessName}
              <br />
              {city}{stateCode !== "" && city !== "" && ", "}{stateCode}
              <p>
            {likeCounter > 0 && likeCounter + (likeCounter > 1 ? " Likes" :" Like")}
          </p>
            </div>
          </div>
          
        </>
      }
    </>
  );
};
export default PhotoGallaryPopupView;
