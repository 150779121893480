import React, { useEffect } from "react";
import RenderBusinessReviews from "./RenderBusinessReviews";
import OopsBanner from "../OopsBanner";
import EditorLoadingSpinner from "../EditorLoadingSpinner";
import { GetBusinessReviews } from "../Utilities/BusinessData";
import { UserContext } from "../Definitions/UserContext";

export default function RenderReviewWrapper({ userContext, staffData }: { userContext: UserContext, staffData: any }) {
   
    const [data, setData] = React.useState<any>(null);

    useEffect(() => {
        if (data == null) {
         
            GetBusinessReviews(userContext, (data: any) => { setData(data); }, (error: any) => { setData(null) }, 20, 1, "1", "1", staffData.encServiceProviderID);
            setData("loading");
        }
    }, []);

    if (data&&data!="loading")
        return <RenderBusinessReviews layout={"0"} userContext={
            {
                //Reassamble the user context using provided id and token for proper rendering
                businessId: userContext.businessId,
                accessToken: userContext.accessToken,
                tokenExpiresAt: undefined,
                styleId: "",
                layoutId: "",
                isAdmin: false,
                imageMapping: [],
                userId: 0,
                pages: [],
                cdnURL: "",
                userType: 0,
                MultilocationData : userContext.MultilocationData,
                isMysite : false,
                bidHash : userContext.bidHash
            }
        } data={data} SPID={staffData.encServiceProviderID}  hideTitle={false}/>
    

    else {
        return <EditorLoadingSpinner />
    }
}