import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import ReactDom from "react-dom";
import styled from 'styled-components';

import StaffMessage from "../../../Hooks/StaffMessage";
import StaffReview from "../../../Hooks/StaffReview"
import { addtoCartEnum, utils } from "../../../utils/Utils";
import StencilRows from "../../Binding/StencilRows";
import { UserContext } from "../../Definitions/UserContext";
import RenderStarRating from "../../Icons/StarRating";
import OopsBanner, { NoRecordsBanner } from "../../OopsBanner";
import { AsyncAjaxGet } from "../../Utilities/AsyncAjaxService";
import styles from './ResponsiveGrid.module.css';

const avatarSize = "16.5em";
const avatarStyle = { width: avatarSize, height: avatarSize, borderRadius: "50%" };


const HideShowDiv = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0; // Initially hidden
  visibility: hidden; // Initially hidden
  transition: opacity 0.3s ease, visibility 0.3s ease; // Smooth transition
  backdrop-filter: blur(8px); /* Add a blur effect */
  -webkit-backdrop-filter: blur(8px); /* Add a blur effect for Safari */
  z-index: 999; // Stay on top
`;

const RectProfileWrapper = styled.div`
  width: 16.5em;
  /* Add any other CSS styles you need here */
  
    /* Mobile */
    @media (min-width: 320px) {
      
      width: 100% !important;
      padding: 8px;
      margin: auto;
       
}

  /* Mobile */
  @container (min-width: 320px) {
      width: 100% !important;
      padding: 8px;
      margin: auto;
      margin-bottom: 16px;
      display: flex-col;
      justify-content: center;
      
  }

/* Tablet */
@media (min-width: 600px) {
      width: 16.5em;
   
}

  /* Tablet */
  @container (min-width: 600px) {
    width: 16.5em !important;
    
  }

/* Desktop */
@media (min-width: 1024px) {
  width: 16.5em;
}
  /* Desktop */
  @container (min-width: 1024px) {
    width: 16.5em;
      
  }
`;

const ProfileCard = styled.div`
  position: relative;
  overflow: hidden;
  
  transition: transform 0.3s ease; // Transition for transform
  cursor: pointer;

  &:hover {
    & > .HideShowDiv {
      opacity: 1;
      visibility: visible;
    }
  }
 

  
`;

export default function RenderStaffRectangles({ staffMember, siteData, isStenciled, serviceList, layout }: { staffMember: any, siteData: UserContext, isStenciled: boolean, serviceList: any, layout: string }) {


  if (!staffMember.businessSummary)
    staffMember.businessSummary = "";
  if (!staffMember.spLargePhotoUrl)
    staffMember.spLargePhotoUrl = "";
  if (!staffMember.reviewRank)
    staffMember.reviewRank = 0;
  if (!staffMember.reviewCount)
    staffMember.reviewCount = 0;
  if (!staffMember.firstName)
    staffMember.firstName = "";
  if (!staffMember.lastName)
    staffMember.lastName = "";

  var IsEditor = (window as any).isEditor !== undefined ? (window as any).isEditor : false;
  var BusinessData = utils.MultiLocationData(siteData, siteData.bidHash);

  return (
    <>
      <RectProfileWrapper>
        <div className="" style={{ cursor: "pointer" }} onClick={(event) => !IsEditor && (window as any).Staffreview(staffMember, siteData)} >
          {typeof staffMember.photoURLForOriginal != 'undefined' && staffMember.photoURLForOriginal != null && staffMember.photoURLForOriginal != "" &&
            <ProfileCard className="staff-image-border  center-all">
              {
                staffMember?.photoURLForOriginal ?
                  <img
                    className={styles.RectStaffProfileImage}
                    src={staffMember.photoURLForOriginal}
                    alt="Staff Member"
                  /> :
                  <img src={staffMember.spintials} alt="" />
              }
              {/* Hidden buttons appear on hover */}
              <HideShowDiv className="HideShowDiv">
              <div className={`${styles.buttonContainer} center-all`}>
                  {staffMember.isShowMessage &&
                    <button className="btn btn-lg btn-light p-2 v-button secondary" onClick={(e) => {e.stopPropagation(); !IsEditor && (window as any).StaffMessage(staffMember, siteData, BusinessData)}} style={{ width: "140px", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <i className="fal fa-envelope bicon px-2"></i>Message
                    </button>
                  }

                  {serviceList > 0 && staffMember.isAllowBook ? staffMember.isServiceTab && staffMember.isClassTab ?
                    <Dropdown className="">
                      <Dropdown.Toggle bsPrefix="invisible-button v-button secondary" id="dropdown-basic" style={{ width: "140px", height: "46px", fontSize: "18px" }}>
                        {staffMember.bookText}<i className="bicon2 fal fa-angle-down px-1"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={(e) => { e.stopPropagation(); !IsEditor && (window as any).BookStaffSerice(staffMember, BusinessData)}} className="no-a">Services</Dropdown.Item>
                        <Dropdown.Item onClick={(e) => {e.stopPropagation(); !IsEditor && (window as any).BookStaffClass(staffMember, BusinessData)}} className="no-a">Classes</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    :
                    <button onClick={(e) => { e.stopPropagation(); !IsEditor && (window as any).BookServiceClass(staffMember, BusinessData)}} className="btn btn-lg btn-light p-2 v-button primary" style={{ width: "140px", height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>{staffMember.bookText}</button>
                    : ""}
                </div>
              </HideShowDiv>
            </ProfileCard>
          }

          {(typeof staffMember.photoURLForOriginal == 'undefined' || staffMember.photoURLForOriginal == null || staffMember.photoURLForOriginal == "") &&
            <> <ProfileCard className=" stencil-item staff-image-border">
              <div className="stencil-item staff-image-border" style={{ overflow: "hidden" }}>
                <div className={`${styles.rectInitialPlaceholderWrapper}`}>

                  <div className={styles.staffInitialPlaceholderImage}>
                    <div className="review-profile-inner-div intialsname xvg-avatar-inital center-all stencil-item" style={avatarStyle}>
                      <div className="review-profile-inner vg-avatar-inital-inner ">
                        <div className="review-profile-inner-text w-full">{staffMember.spintials}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <HideShowDiv className="HideShowDiv">
              <div className={`${styles.buttonContainer} center-all`}>
                  {staffMember.isShowMessage &&
                    <button className="btn btn-lg btn-light p-2 v-button secondary" onClick={(e) => {e.stopPropagation(); !IsEditor && (window as any).StaffMessage(staffMember, siteData, BusinessData)}} style={{ width: "140px", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <i className="fal fa-envelope bicon px-2"></i>Message
                    </button>
                  }

                  {serviceList > 0 && staffMember.isAllowBook ? staffMember.isServiceTab && staffMember.isClassTab ?
                    <Dropdown className="">
                      <Dropdown.Toggle bsPrefix="invisible-button v-button secondary" id="dropdown-basic" style={{ width: "140px", height: "46px", fontSize: "18px" }}>
                        {staffMember.bookText}<i className="bicon2 fal fa-angle-down px-1"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={(e) => {e.stopPropagation(); !IsEditor && (window as any).BookStaffSerice(staffMember, BusinessData)}} className="no-a">Services</Dropdown.Item>
                        <Dropdown.Item onClick={(e) => {e.stopPropagation(); !IsEditor && (window as any).BookStaffClass(staffMember, BusinessData)}} className="no-a">Classes</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    :
                    <button onClick={(e) => {e.stopPropagation(); !IsEditor && (window as any).BookServiceClass(staffMember, BusinessData)}} className="btn btn-lg btn-light p-2 v-button primary" style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "0px" }}>{staffMember.bookText}</button>
                    : ""}
                </div>
              </HideShowDiv>
              </ProfileCard>
            </>
          }

          <div>
            <h2 className={styles.staffTitle}>
              {staffMember.firstName} {staffMember.lastName}
            </h2>
          </div>
        </div>
        {isStenciled ?
          <StencilRows rowCount={3} />
          :
          <div className={`vp-2 ${styles.staffBusinessSummaryText}`} >
            {staffMember.businessSummary.slice(0, 100)}
          </div>
        
        }
        

        </RectProfileWrapper>
      
    </>
  )
}
