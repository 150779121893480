import ReactDOM from "react-dom";
import { UserContext } from "../Definitions/UserContext";
import { BusienssControlNames, GenericBusinessControl } from "./GenericBusinessControl";
import React from "react";
import LayoutNode from "../Definitions/LayoutNode";
import GenericDynamicComponent from "./GenericDynamicComponent";
import { ValidateToken } from "../Utilities/BusinessData";
import {hasAcceptedValue, getCookie,setCookie, GetTrackingCookieName } from "../Utilities/CookieUtils";
import { BindAllToggles } from "./BindAllToggles";

const dataBindKey = "data-link-key";
const componetAttKey = "component-attributes"

export default async function BindDataToComponents(businessId: string, accessToken: string,  MultilocationData : any, bidHash:string, tokenExpiresAt?: number,isMysite? : boolean,onFinish?:Function,VGURL? :string, isDuplicateAllLocationPage?:boolean, sitebid?:string) {
 


    //const expiresAt = (accessgenerationtime??0)  + 40 * 60000;

  //reassmble the user context from the input
    const context: UserContext = {
        businessId: businessId,
        accessToken: accessToken,
        styleId: "",
        layoutId: "",
        userId: 0,
        pages: [],
        userType: 1,
        cdnURL: "",
        tokenExpiresAt: tokenExpiresAt,      
        imageMapping: [],
        MultilocationData : MultilocationData,
        isMysite : isMysite??false,
        bidHash :bidHash,
        vgurl:VGURL,
        isDuplicateAllLocationPage:isDuplicateAllLocationPage,
        sitebid:sitebid
    }


    await ValidateToken(context);
  
    try{

    //bind all business controls
    let dataBindElements = document.querySelectorAll(`[${dataBindKey}]`);

    dataBindElements.forEach((element) => {

        let layout = element.getAttribute("layout");
        let dataKey = element.getAttribute(dataBindKey);
        let bid = element.getAttribute("encbid");
        let filters = element.getAttribute("filters")??"";
        if(filters){
            filters = decodeURIComponent(filters);
        }


        let allowMulti =  element.getAttribute("multilocal") == "true" ? true:false;


        //for certain components hide cta so it doesn't interfere with functionality
        if(dataKey&&(dataKey === BusienssControlNames.booknow||dataKey === BusienssControlNames.classes||dataKey===BusienssControlNames.productDetails)){
            document.body.classList.add("hide-cta");

        }

        if (layout && dataKey) {
            ReactDOM.render(<GenericBusinessControl dataKey={dataKey} filters={filters} userContext={context} bLocationId={bid} layout={layout} rootId={element.id.substring(0,6)} allowMultiLoc={allowMulti}  />, element);
        }
    })
    }
    catch(e){
        console.warn(e);
    }

    //bind all functional components (image sliders etc)
    let compBindElements = document.querySelectorAll(`[${componetAttKey}]`);

    compBindElements.forEach((element) => {

        let atts = element.getAttribute(componetAttKey);

        if (atts)
            try {
                let str = decodeURIComponent(atts);
             
                let attData = JSON.parse(str);
         
           
                if (atts.length > 0 && attData) {


                    let node = new LayoutNode();
                    node.id = element.id;//flag to not include atts this way
                    node.componentAttributes = attData;
                    let key = element.getAttribute("componenttype") ?? "";
                    element.setAttribute(componetAttKey, ""); //remove all node data from the dom


                    ReactDOM.render(<GenericDynamicComponent dataKey={key} node={node} context={context}  />, element);


                }
            }
            catch (e) {
                console.warn(e);
               
            }


    })


    const embeds = document.querySelectorAll(".v-site.render .v-html-embed script, #head-code script, #footer-code script");



    for(let i = 0;i<embeds.length;i++){

 
        let scrpt = embeds[i];
        
        let src = scrpt.getAttribute("src");
        let parent = scrpt.parentElement;
        
    

   
            if(parent){
            
                let newScript = document.createElement("script");
                newScript.async = true;
                if(src)
                 newScript.src = src+"?cachebuster="+Date.now();
                newScript.innerHTML = scrpt.innerHTML;
            
               parent.replaceChild(newScript,scrpt);
                
            }
    
    }


    BindAllToggles();

    const buttonLinks = document.querySelectorAll(`.scroll-link`);

    for(let i = 0;i<buttonLinks.length;i++){
   
        if(!buttonLinks[i].classList.contains("hierarchy"))
            continue;
        let href = buttonLinks[i].getAttribute("href");
        if(href&&href.startsWith("#")){
            href = window.location.href + href;
            buttonLinks[i].setAttribute("href",href);
        }

    }

    //annoucement banner
    const clsAnnouncement = document.getElementById("close-announcement-btn");
    if(clsAnnouncement){
        let announcement = clsAnnouncement.parentElement;

        let expiresAt = announcement?.getAttribute("data-expires-at");
        let expired = false;
        if(expiresAt){

            let date = new Date(expiresAt);
            
            if(date &&!isNaN(date.getTime())){
                let now = new Date();
                expired = date.getTime()<now.getTime();
            }

        }


        let storage = window.sessionStorage.getItem("announcement-closed");
        if( (!(window as any).isEditor&&storage&&storage=="true")||expired){
          
            
            if(announcement&&announcement.parentElement){
                announcement.parentElement.removeChild(announcement);
            }
        }


        clsAnnouncement.onclick = (e)=>{
      
            if(clsAnnouncement){
                let announcement = clsAnnouncement.parentElement;
       
                if(announcement&&announcement.parentElement){
                    e.stopPropagation();
                    e.preventDefault();
                    window.sessionStorage.setItem("announcement-closed","true");
                    announcement.parentElement.removeChild(announcement);
                }
            }
        };

    }

    //cookie banner
    const clsCookie = document.getElementById("dismiss-cookie-banner");
    if(clsCookie){

        let accepted = getCookie(GetTrackingCookieName()) == hasAcceptedValue

        let acceptBtn = document.getElementById("accept-cookie-banner");
   
        const closeBanner = ()=>{
            let parent = clsCookie.parentElement;
            if(parent&&parent.parentElement){
                parent.parentElement.remove();
            }
            
        };

        if(accepted){
            closeBanner();
            
        }

        else if(acceptBtn){
            acceptBtn.onclick = (e)=>{
              
                setCookie(GetTrackingCookieName(),hasAcceptedValue,365);

                let gidDom = document.getElementById("ganalytics");
                if(gidDom){
                    let id = gidDom.getAttribute("data-id");

                    if(id){

                        let gScript = document.createElement("script");
                        gScript.async = true;
                        gScript.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
                        
                    

                        document.head.appendChild(gScript);

                        let script = document.createElement("script");
                        script.async = true;
                        
                        script.innerHTML = `
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){window.dataLayer.push(arguments);}
                        gtag('js', new Date());
                        
                        gtag('config', '${id}');

                        gtag('event', 'page_view', {
                            page_title: document.title,
                            page_location: window.location.href,
                          });
                        `
                        document.head.appendChild(script);
                    }
                    
                    
                }

                closeBanner();
            }
        }

        clsCookie.onclick = (e)=>{
            closeBanner();
        };

    }

    //override static links with preview links
    if(window.location.href.endsWith("/preview")){
        let links = document.querySelectorAll("a");
       
        for(let i = 0;i<links.length;i++){
            if(!links[i].classList.contains("hierarchy"))
                continue;
            let href = links[i].getAttribute("href");
            if(href&&!href.includes("www.")&&!href.endsWith("/preview")){
                href = href + "/preview";
                links[i].setAttribute("href",href);
            }
        }

    }

    const jsAsCSS = (name: string, value: string) => {
        document.documentElement.style.setProperty(name, value);
    }
    
    const setCSSVariable = () => {
        try{
        var wbcprimaryBtn = document.getElementById('btnprimarybutton')!;
        var wbcprimaryBtnBg = getComputedStyle(wbcprimaryBtn, '').backgroundColor;
        var loaderAlpha = `rgba(${wbcprimaryBtnBg.slice(4, -1)},0.7)`;
        jsAsCSS("--wbc-primary-button-bg", wbcprimaryBtnBg);
        jsAsCSS("--wbc-loader-alpha", loaderAlpha);
        }
        catch(error){
            console.warn(error);
        }
        //console.log(loaderAlpha)
    };
    setCSSVariable()
        
    onFinish&&onFinish();

}
