import React, { useEffect, useRef } from "react";
import { WBRenderProps } from "../Definitions/WBRenderProps";
import { GetNodeDomAttributes } from "../Utilities/GetNodeDomAttributes";
import { getNodeComponentAttributeValue } from "../Definitions/LayoutNode";
import MissingSourceDisplay from "../MissingSourceDisplay";
import SimpleImageSlider from "react-simple-image-slider";

export default function RenderImageSlider({component,children,screenSize,userContext: siteData,isEditor}:WBRenderProps): JSX.Element {

     
       
 

  
    let imageList = getNodeComponentAttributeValue(component,"imageSliderList","");
    const controls = getNodeComponentAttributeValue(component,"imageSliderControls","true")== "true";
    const delay = parseFloat(getNodeComponentAttributeValue(component,"imageSliderDelay","5"));
    const autoplay = getNodeComponentAttributeValue(component,"imageSliderAutoplay","")== "true" && delay>0;
    const swipeStartX = useRef(0);
    const swipeCurrnet = useRef(0);
    const containerRef = useRef<HTMLDivElement>(null);
    const isSwiping = useRef(false);
    let imageListArray =  imageList.length>0?imageList.split(","):[];
 
    let classn = (imageListArray.length<=0)?" v-image-slider-empty":"v-image-slider";
    const atts = GetNodeDomAttributes(classn,component,screenSize,isEditor);


    const startSwipe = ( e:TouchEvent )=>{
        if(containerRef.current&&containerRef.current.contains(e.target as Node)){
       
          
            swipeStartX.current = e.touches[0].clientX;
            isSwiping.current = true;
        }

    }

    const swipeDir=(dir:number)=>{
        
        if(containerRef.current){
        let btns = containerRef.current?.querySelectorAll("button");

            if(btns.length>1){
                if(dir>0){
                    btns[1].click();
                }
                else{
                    btns[0].click();
                }
            }

        }
        
    }

    const endSipe = (e:TouchEvent)=>{


        if(isSwiping.current){
            let dif = swipeCurrnet.current - swipeStartX.current;
         
            if(dif>30){
                swipeDir(-1);

            }
            else if(dif<-30){
                swipeDir(1);
          
            }

        }



        isSwiping.current = false;
    }

    const trackSwipe = (e:TouchEvent)=>{

        swipeCurrnet.current = e.touches[0].clientX;
    }

    useEffect(() => {

        document.addEventListener("touchstart",startSwipe);
        document.addEventListener("touchmove",trackSwipe);
        document.addEventListener("touchend",endSipe);

        return ()=>{
            document.removeEventListener("touchstart",startSwipe);
            document.removeEventListener("touchmove",trackSwipe);
            document.removeEventListener("touchend",endSipe);
        }

    },[]);
    if(imageListArray.length<=0){

        return (

            <div {...atts}>
                 <MissingSourceDisplay/>
            </div>
        )

    }

    return <div {...atts} ref={containerRef}>
    {/* @TODO, use a ref object to dynamically adjust the width and height of the slider */}
    <SimpleImageSlider
            key ={imageListArray.length>0?imageListArray[0]+autoplay+delay:"empty"} //udpate the key when the image list changes in order to update the slider in real time
            width={500}
            height={300}
            images={imageListArray}
            showBullets={controls}
            showNavs={controls}
            autoPlay={autoplay}
            autoPlayDelay={delay}
        />
    </div>
}

