import $ from "jquery"; 
import "./select2_4.0.js";
import { AsyncAjaxGet } from "../components/Utilities/AsyncAjaxService.jsx";
import { AsYouType } from "libphonenumber-js";


var PhonePlugin = function (element,prop,onChageCode,OnChageNumber) {
    if (!window._glbPluginCountryList) {
        window._glbPluginCountryList = [];
    }
    var settings = $.extend({
        Control: element,
        ControlId: element.attr('id'),
        CurrentLocationCountryID: 1,
        CountryId: 0,
        UserRole: 2, //1 : Business, 2 : Public
        ControlText: 'Cell',
        IsPhoneNoRequire: false,
        IsDisabled: false,
        ScrollType: 2,
        CellOldValue: "",
        ValidateReturnObject: {},
        
    }, prop);
    var common = {
        model: {
            countryFlag: { 1: "us-f", 2: "uk-f", 3: "canada-f", 4: "aus-f" },
            countryDialingCode: { 1: "1", 2: "44", 3: "1", 4: "61" },
            CountryId: (typeof settings.CountryId == 'undefined' || settings.CountryId == 0) ? settings.CurrentLocationCountryID : settings.CountryId,
            DialingCode: '+1',
            errorMessage: 'Something went wrong, Try Again.',
            IsPaste: false,
            IsSelected: false,
            nDotCount: 0,
            CountryList: {},
            CountryCode: "US",
        },
        ControlsPrefix: {
            PhoneControl: '#' + settings.ControlId,
            PhoneControllerCountry: '#PhoneControllerCountry' + settings.ControlId,
            countrySelectOption: '#countryselectoption' + settings.ControlId,
            ddlMainCountry: '#ddlMainCountry' + settings.ControlId,
            dvWrap: '#dvWrap' + settings.ControlId,
            dvDialingCode: '#dvDialingCode' + settings.ControlId
        },
        Message: {
            InvalidPhone: "Invalid ",
            InvalidLength: " should be of ",
            Required: " is required.",
        },
         WarningType: {
             Required : 1,
             InvalidPhone: 2,
             InvalidLength:3,
        }
    };
    var phonePluginGenerateHtml = () => {
        var searchTextBox = settings.ControlId;
        var tabIndex = document.getElementById(searchTextBox).tabIndex;
        $("#" + searchTextBox).wrap('<div id="dvWrap' + searchTextBox + '" class="select-country-mar vg-select-icon-mar input-focus-relative address-phone-plugin">').parent().wrap('<div class="addresswithcountry vagaro-dropdown-icon clearfix">');
        var htmlCountry = ' <div class="w-custom-dropdown countrydropdown allflag-show">';
        var cntryDropDownID = "'" + common.ControlsPrefix.countrySelectOption + "'";
        htmlCountry += '<div id="PhoneControllerCountry' + searchTextBox + '" class="selectedcountry" ></div > ';
        htmlCountry += '<div id="dvDialingCode' + searchTextBox + '" class="country-code" >+1</div > ';

        htmlCountry += '<select id="ddlMainCountry' + searchTextBox + '" class="form-control" tabindex="' + tabIndex + '">  </select>';
        htmlCountry += '</div>';
        htmlCountry += '<ul id="countryselectoption' + searchTextBox + '" class="countryselectoption" style="display: none">';
        htmlCountry += ' </ul>';

        if ($('#countryselectoption' + searchTextBox).length <= 0) {
            if ($("#" + searchTextBox).parent() != undefined) {
                $("#" + searchTextBox).parent().before(htmlCountry);
            } else {
                $("#" + searchTextBox).before(htmlCountry);
            }
            phonePluginGetCountryList();
            $(common.ControlsPrefix.PhoneControl).on('blur', function () {
                phonePluginPhoneNumberBlur();
            });
            $(common.ControlsPrefix.PhoneControl).on('keyup', function (event) {
                phonePluginSetPhonePattern();
                phonePluginPasteHandlerKeyup(event, 3);
            });
            $(common.ControlsPrefix.PhoneControllerCountry).bind("click", function () {
                if (!settings.IsDisabled && !$(common.ControlsPrefix.countrySelectOption).is(":visible")) {
                    $(".countryselectoption").hide();
                }
            });
        }
    }

    var phonePluginGetCountryList = () => {
        if (!CheckGlobalCountryList()) {
            var _Data = {};
            var _RegisterDeviceReq = {};
            _RegisterDeviceReq.businessID = 0;
            _RegisterDeviceReq.loginUserID = 0;
            _Data.RegisterDeviceRequest = _RegisterDeviceReq;
            AsyncAjaxGet(
                `countrylist/countrylistdetails`,
                prop.Token,
                function onSuccess(data) {
                    try {
                        if (
                            data != null &&
                            data.data != null &&
                            data.data.responseCode == 1000
                        ) {
                            if (data != null && data.data != null && data.data.data != null) {
                                phonePluginGetCountryList_Success(data.data.data)
                            }
                        }else if (                                    
                            data != null &&
                            data.data != null &&
                            data.data.responseCode == 1032
                        ) {
                            phonePluginGetCountryList_Fail() 
                        }
                    }
                    catch (error) {
                        phonePluginGetCountryList_Fail() 
                    }
                },
                function OnError() {
                    phonePluginGetCountryList_Fail() 

                }
            );

        }
        else {
            phonePluginGetCountryList_Success(window._glbPluginCountryList);
        }
    }
    var phonePluginGetCountryList_Success = (result) => {
        if (typeof result != 'undefined' && result != null && result.length > 0) {
            if (!CheckGlobalCountryList()) {
                window._glbPluginCountryList = result;
            }
            common.model.countryFlag = {};
            common.model.countryDialingCode = {};
            common.model.CountryList = result;

            var currCountryStr = '<optgroup label="Current Location">';
            var otherCountryStr = '<optgroup label="Other Locations">';
            result.sort((a, b) => {
                var nameA = a.countryName.toUpperCase();
                var nameB = b.countryName.toUpperCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            });
            $.each(result, function (data, value) {
                if (settings.UserRole == 2 || (settings.UserRole == 1 && !phonePluginIsNullOrUndefineOrBlank(value.isBusinessUseOnly) && value.isBusinessUseOnly == true)) {
                    var liId = "liFront" + settings.ControlId + data ;
                    var option = "<li  id='" + liId + "' class=" + value.cssClass + " >" + value.countryName + "  +" + value.dialingCode + " </li>";
                    $("#" + liId).bind("click", function () {
                        common.model.CountryId = value.countryID;
                        common.model.DialingCode = value.dialingCode;
                        phonePluginSelectCountry();
                    });
                    common.model.countryFlag[value.countryID] = value.cssClass;
                    common.model.countryDialingCode[value.countryID] = value.dialingCode;

                    if (value.countryID == settings.CurrentLocationCountryID) {
                        currCountryStr += ' <option class="' + value.cssClass + '" data-Css="'+ value.cssClass +'" value="' + value.countryID + '">' + value.countryName + '+' + value.dialingCode + '</option> </optgroup >'
                    }
                    else {
                        otherCountryStr += ' <option class="' + value.cssClass + '" data-Css="'+ value.cssClass +'" value="' + value.countryID + '">' + value.countryName + '+' + value.dialingCode + '</option>';
                    }
                }
            });
            otherCountryStr += '</optgroup >';
            $(common.ControlsPrefix.ddlMainCountry).append(currCountryStr + otherCountryStr);
            $(common.ControlsPrefix.ddlMainCountry).select2({
                placeholder:"Recipient Phone",
                searchInputPlaceholder: "Search for a Country",
                closeOnSelect: true,
                templateResult: formatCountry,
            }).on('select2-selected', function (e) {
                common.model.CountryId = parseInt(e.choice.id);
                phonePluginSelectCountry();
                phonePluginSetPhonePattern();
                setTimeout(function () {
                    $(common.ControlsPrefix.PhoneControl).focus();
                }, 100);
            }).on('change', function (event) {
                common.model.CountryId = parseInt(event.target.value);
                phonePluginSelectCountry();
                phonePluginSetPhonePattern();
                $(".select2-selection__rendered").removeAttr('title');
                setTimeout(function () {
                    $(common.ControlsPrefix.PhoneControl).focus();
                }, 100);

            }).on('select2:open', function (e) {
                $('.select2-container').addClass("all-country-flag all-country-flag-react ");
                $('.select2-container').find('ul.select2-results__options').addClass('select2-result-sub');
                $('.select2-search__field').attr("placeholder", "Search for a Country").attr("autocomplete", "off");
                var dropdowns = $('.select2-container');
                $.grep(dropdowns,function(dropdown){
                    var add = true;
                    for (const key in dropdown.classList) {
                        if (dropdown.classList[key] == "select2") {
                            add = false;
                        }
                    }
                    if (add == true && !dropdown.className.includes(" all-country-flag-dropdown") ) {
                        dropdown.className += " all-country-flag-dropdown"
                    }
                })
               

            }).on('select2-open', function (e) {
                if (typeof IsMobileDevice == 'undefined' || phonePluginIsNullOrUndefineOrBlank(IsMobileDevice)) {                    
                    var isiPhone = (navigator.userAgent.match(/iphone/i) != null);
                    var isWindowsPhone = navigator.userAgent.match(/windows phone/i) || false;
                    var AndroidMobile = navigator.userAgent.match(/Android/i) != null && navigator.userAgent.match(/Mobile/i) != null && navigator.userAgent.match(/Windows/i) == null;
                    var IsMobileDevice = (/iPad/.test(navigator.userAgent) || isiPhone || isWindowsPhone || AndroidMobile || (/^((?!chrome|android).)*safari/i.test(navigator.userAgent) && ($(window).width() <= 850 || $(window).height() <= 850)) || /androidcheckin/.test(navigator.userAgent));
                }
                if (IsMobileDevice == true) {
                    $('.select2-input text-primary demo, :focus,input').prop('focus', false).blur();
                }
                if (element.selectedIndex > 0) {
                    var viewport = $('#select2-drop .select2-results');
                    var itemTop = viewport.find('.select2-highlighted').position().top;
                    if (settings.ScrollType == 1) {
                        viewport.scrollTop(itemTop + viewport.scrollTop());
                    } else {
                        viewport.scrollTop(itemTop);
                    }
                }
            });
            $(".select2-selection__rendered").removeAttr('title');
            phonePluginSelectCountry();
            phonePluginSetPhonePattern();
        }
    }
    var manageFlag = () => {
        $.grep($('.select2-container').find('div.countrys-Code'), function (Countory) {
            if (Countory.firstChild.className != "") {
                Countory.parentElement.classList.add(Countory.firstChild.className)
            }
        });
    }
    var formatCountry = (country,container) => {
        var CountryDialing = country.text.split("+");
        var Css = country.element != undefined ?  country.element.className : ""
        var $country = $(
            '<div class="select2-result-label countrys-Code ' + Css + '"><span class="' + Css + '" >' + CountryDialing[0] + '</span><span class="country-code-text" >' + (phonePluginIsNullOrUndefineOrBlank(CountryDialing[1]) == true ? '' : ('+' + CountryDialing[1])) + '</span></div>'
        );
        return $country;
    }
    var phonePluginGetCountryList_Fail = (result) => {
        // swal({
        //     title: common.model.errorMessage, text: "",
        //     timer: 4000, type: "error", showConfirmButton: false, customClass: 'swal-nobutton swal-white-greenbtn',
        // }, function (isConfirm) {
        //     swal.close();
        // });
    }
    var phonePluginIsNullOrUndefineOrBlank = (data) => {
        if (typeof (data) == "undefined" || data == undefined || data == "" || data == null) {
            return true;
        }
        return false;
    }
    var phonePluginSelectCountry = () => {
        if (common.model.CountryId == 0) {
            common.model.CountryId = 1;
        }
        if (typeof common.model.countryFlag[common.model.CountryId] == "undefined" || common.model.countryFlag[common.model.CountryId] == null) {
            common.model.CountryId = 1;
        }
        var countryFlag = common.model.countryFlag[common.model.CountryId];
        if (phonePluginIsNullOrUndefineOrBlank(countryFlag)) {
            common.model.CountryId = settings.CurrentLocationCountryID;
            countryFlag = common.model.countryFlag[common.model.CountryId];
        }
        common.model.DialingCode = common.model.countryDialingCode[common.model.CountryId];        
        $(common.ControlsPrefix.PhoneControllerCountry).removeClass();
        $(common.ControlsPrefix.PhoneControllerCountry).addClass("selectedcountry " + countryFlag);
        $(common.ControlsPrefix.myCountryPopup).removeClass();
        $(common.ControlsPrefix.myCountryPopup).addClass("selectedcountry " + countryFlag);
        $(common.ControlsPrefix.countrySelectOption).removeAttr("style").attr("style", "display:none");
        $(common.ControlsPrefix.countrySelectOption).removeClass("active");
        $(common.ControlsPrefix.dvWrap).removeClass();
        $(common.ControlsPrefix.dvWrap).addClass("select-country-mar vg-select-icon-mar input-focus-relative " + countryFlag);
        $(common.ControlsPrefix.dvDialingCode).text('+' + common.model.DialingCode);
        onChageCode(common.model.CountryId)
    }
    var phonePluginPhoneNumberBlur = () => {
        phonePluginRemoveHTMLTag();
        phonePluginValidateText(3, 0);
        phonePluginValidateCellNo();
    }
    var phonePluginRemoveHTMLTag = () => {
        var obj = settings.Control;
        var textData = $(obj).val().trim();
        $(obj).val(phonePluginCheckAndRemoveHTMLTag(textData));
    }
    var phonePluginCheckAndRemoveHTMLTag = (val) => {
        var re = /(<([^>]+)>)/gi;
        if (val.match(re)) {
            val = val.replace(/(<([^>]+)>)/gi, '');
        }
        return val;
    }
    var nDotCount = 0;
    var phonePluginValidateText = (entryMode, decimalPlaces) => {
        var textbox = $(common.ControlsPrefix.PhoneControl);
        nDotCount = 0;
        var i = 0;
        var appendZerosString;
        textbox.val(phonePluginCheckMaxLength(phonePluginCheckStringChars(textbox.val(), entryMode), 20));
        if (entryMode == 1 && phonePluginIdentifyIfDot(textbox.val()) == true) {
            textbox.val(Math.round(textbox.val() * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces));
            if (phonePluginIdentifyIfDot(textbox.val()) == false) {
                if (decimalPlaces > 0)
                    appendZerosString = ".";
                else
                    appendZerosString = "";
                for (i = 0; i < decimalPlaces; i++) {
                    appendZerosString = appendZerosString + "0";
                }
            }
            else {
                if (((textbox.val().length - 1) - phonePluginDotPosition(textbox.val())) < decimalPlaces) {
                    appendZerosString = ""
                    for (i = ((textbox.val().length - 1) - phonePluginDotPosition(textbox.val())); i < decimalPlaces; i++) {
                        appendZerosString = appendZerosString + "0";
                    }
                }
            }
        }
        else if (entryMode == 1 && phonePluginIdentifyIfDot(textbox.val()) == false) {
            textbox.val(Math.round(textbox.val() * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces));
            if (decimalPlaces > 0)
                appendZerosString = ".";
            else
                appendZerosString = "";
            for (i = 0; i < decimalPlaces; i++) {
                appendZerosString = appendZerosString + "0";
            }
        }
    }
    var phonePluginCheckMaxLength = (actualstring, maxlength) => {
        if ((maxlength > 0) && (maxlength < actualstring.length)) {
            var actualstringarr = actualstring.split('');
            var returnstring = "";
            var k = 0;
            for (k = 0; k < maxlength; k++) {
                returnstring = returnstring + actualstringarr[k];
            }
            return (returnstring);
        }
        else {
            return (actualstring);
        }
    }
    var phonePluginCheckStringChars = (string, entryMode) => {
        var onlyNumbers = "";
        var len = string.length;
        var strarr = string.split('');
        var localDotCount = 0;
        var j = 0;
        for (j = 0; j < len; j++) {
            if ((phonePluginIsNumeric(strarr[j], entryMode)) == true) {
                if (entryMode == 1) {
                    if (strarr[j] == '.' && localDotCount == 0 && nDotCount != 1) {
                        onlyNumbers = onlyNumbers + strarr[j];
                        localDotCount = localDotCount + 1;
                        nDotCount = 1;
                    }
                    else if (strarr[j] != '.') {
                        onlyNumbers = onlyNumbers + strarr[j];
                    }
                }
                else {
                    onlyNumbers = onlyNumbers + strarr[j];
                }

            }
        }
        return (onlyNumbers);
    }
    var phonePluginIsNumeric = (sText, entryMode) => {
        var ValidChars;
        if (entryMode == 0) //Numeric Entries
        {
            ValidChars = "0123456789";
        }
        else if (entryMode == 1)//Numeric Entries with Decimal Point
        {
            ValidChars = "0123456789.";
        }
        else if (entryMode == 2)//Numeric Entries with Spaces for Phone numbers
        {
            ValidChars = "0123456789 ";
        }
        else if (entryMode == 3)//Numeric Entries with small bracket and dash for Phone numbers((222)-223-3333)
        {
            ValidChars = "0123456789()- ";
        }
        var IsNumber = true;
        var Char;
        for (var i = 0; i < sText.length && IsNumber == true; i++) {
            Char = sText.charAt(i);
            if (ValidChars.indexOf(Char) == -1) {
                IsNumber = false;
            }
        }
        return IsNumber;
    }
    var phonePluginIdentifyIfDot = (stringToCheck) => {
        var d = 0;
        var dotPresent = false;
        for (d = 0; d < stringToCheck.length; d++) {
            if (stringToCheck.charAt(d) == '.') {
                dotPresent = true;
            }
        }
        return dotPresent;
    }
    var phonePluginDotPosition = (stringToCheck) => {
        var d = 0;
        var dotPosition = 0;
        for (d = 0; d < stringToCheck.length; d++) {
            if (stringToCheck.charAt(d) == '.') {
                dotPosition = d;
            }
        }
        return dotPosition;
    }
    var phonePluginValidateCellNo = (IsReturnObject = false) => {
        var txtCell = $(common.ControlsPrefix.PhoneControl);
        var isSucess = true;
        var countryID = common.model.CountryId;
        var WarningMessage = "";
        var WarningType = 0;
        if (txtCell.val().length > 0) {
            var plength = "";
            if (countryID <= 4) {
                var Minlength = (countryID == 4 || countryID == 2) ? 9 : 10;
                var Maxlength = (countryID == 4 || countryID == 2) ? 11 : 10;
                plength = (Minlength == Maxlength) ? " 10 " : " 9 to 11 ";
                if (txtCell.val().replace(/[^0-9.]/g, "").trim() == "") {
                    isSucess = false;
                    WarningMessage = "Please enter " + settings.ControlText + ".";
                    WarningType = common.WarningType.Required;
                   
                }               
                else if (txtCell.val().trim().match(/\d/g).length < Minlength || txtCell.val().trim().match(/\d/g).length > Maxlength) {
                    isSucess = false;
                    WarningMessage = settings.ControlText + common.Message.InvalidLength + plength+ 'digits.';
                    WarningType = common.WarningType.InvalidLength;
                }
                
            }
            else {
                var ValidatePhone = new AsYouType(phonePluginGetCountryData(common.model.CountryId).countryCode);
                var number = ValidatePhone.input(txtCell.val().replace(/[^0-9.]/g, ""));
                isSucess = ValidatePhone.isValid();
            }
        }
        else if (settings.IsPhoneNoRequire == true) {
            isSucess = false;
            WarningType = common.WarningType.Required;
            WarningMessage = settings.ControlText + common.Message.Required;
        }
        if (isSucess) {
            txtCell.removeClass('errorInput');
        }
        else {
            txtCell.addClass('errorInput');
            if (IsReturnObject == false) {
                WarningType = (!phonePluginIsNullOrUndefineOrBlank(WarningMessage)) ? WarningType : common.WarningType.InvalidPhone;
                //Warning MSG
                // if (typeof Webflow != "undefined" && Webflow != null && typeof Webflow.require != "undefined" && Webflow.require != null ) {
                //     phonePluginShowHideWarningMessage((!phonePluginIsNullOrUndefineOrBlank(WarningMessage)) ? WarningMessage : common.Message.InvalidPhone + settings.ControlText + ".");
                // }
            }
        }
        if (IsReturnObject == false) {
            return isSucess;
        }
        else {
            var ValidateCellNoResult = {};
            ValidateCellNoResult.WarningMessage = (!phonePluginIsNullOrUndefineOrBlank(WarningMessage)) ? WarningMessage : common.Message.InvalidPhone + settings.ControlText + ".";
            ValidateCellNoResult.isSucess = isSucess;
            ValidateCellNoResult.WarningType = WarningType;
            return ValidateCellNoResult;
        }
    }
    var phonePluginPasteHandlerKeyup = (e, entryMode) => {
        var textbox = settings.Control;
        if (navigator.appName == "Microsoft Internet Explorer") {
            if (common.model.IsSelected) {
                common.model.nDotCount = 0;
                var pastestring = phonePluginCheckMaxLength(phonePluginStripChars(textbox.val(), entryMode), 11);
                textbox.val(pastestring);
                common.model.IsSelected = false;
            }
            if (e.keyCode == 8 || e.keyCode == 46) // Code written for resetting the dot counter to 0 on delete of "."
            {
                if (!phonePluginIdentifyIfDot(textbox.val())) {
                    common.model.nDotCount = 0;
                }
            }
            if (textbox.val() == "") {
                common.model.nDotCount = 0;
            }
        }
        else {
            if ((e.type == 'keyup') && common.model.IsPaste) {
                common.model.nDotCount = 0;
                var pastestring = phonePluginCheckMaxLength(phonePluginStripChars(textbox.val(), entryMode), 11);
                textbox.val(pastestring);
                common.model.IsPaste = false;
            }
            if (e.which == 8 || e.which == 46) // Code written for resetting the dot counter to 0 on delete of "."
            {
                if (!phonePluginIdentifyIfDot(textbox.val())) {
                    common.model.nDotCount = 0;
                }
            }
            if (textbox.val() == "") {
                common.model.nDotCount = 0;
            }
        }
    }
    var phonePluginStripChars = (string, entryMode) => {
        var onlyNumbers = "";
        var len = string.length;
        var strarr = string.split('');
        var localDotCount = 0;
        var j = 0;
        for (j = 0; j < len; j++) {
            if ((phonePluginIsNumeric(strarr[j], entryMode)) == true) {
                if (entryMode == 1) {
                    if (strarr[j] == '.' && localDotCount == 0 && common.model.nDotCount != 1) {
                        onlyNumbers = onlyNumbers + strarr[j];
                        localDotCount = localDotCount + 1;
                        common.model.nDotCount = 1;
                    }
                    else if (strarr[j] != '.') {
                        onlyNumbers = onlyNumbers + strarr[j];
                    }
                }
                else {
                    onlyNumbers = onlyNumbers + strarr[j];
                }

            }
        }
        return (onlyNumbers);
    }
    var phonePluginSetPhonePattern = () => {
        var countryId = common.model.CountryId;
        var phoneNumber = $(common.ControlsPrefix.PhoneControl);
        if (typeof countryId != "undefined" && countryId != null && countryId != "") {
            var phoneVal = phoneNumber.val().replace(/([()-\s])+/g, '');
            var valLenght = phoneVal.length;
            if (valLenght <= 0) {
                return;
            }
            if (valLenght == 11 && phoneVal[0] == 0 && parseInt(countryId) <=4) {
                settings.CellOldValue = phoneVal;
                phoneVal = phoneVal.substring(1);
                valLenght = phoneVal.length;
            }
            if ((countryId == '1' || countryId == '3') && valLenght == 10) {
                if (countryId == '3') {
                    var number = phoneVal.replace(/(\d{3})\-?(\d{3})\-?(\d{4})/, '$1-$2-$3');
                    if (settings.CellOldValue != number || phoneNumber.val() != settings.CellOldValue) {
                        phoneNumber.val(number);
                        settings.CellOldValue = number;
                    }
                } else {
                    var number = phoneVal.replace(/(\d{3})\-?(\d{3})\-?(\d{4})/, '($1) $2-$3');
                    if (settings.CellOldValue != number || phoneNumber.val() != settings.CellOldValue) {
                        phoneNumber.val(number);
                        settings.CellOldValue = number;
                    }
                }

            }
            else if (countryId == '2') {//UK               
                if (valLenght >= 9 && valLenght <= 11) {
                    if (phoneVal.substring(0, 1) == "1") {
                        number = phoneVal.replace(/(\d{4})\-?(\d{6})/, '$1 $2');
                    }
                    else if (phoneVal.substring(0, 1) == "2") {
                        number = phoneVal.replace(/(\d{2})\-?(\d{4})\-?(\d{4})/, '$1 $2 $3');
                    }
                    else if (phoneVal.substring(0, 1) == "7") {
                        number = phoneVal.replace(/(\d{4})\-?(\d{3})\-?(\d{3})/, '$1 $2 $3');
                    } else {
                        number = phoneVal.replace(/(\d{2})\-?(\d{4})\-?(\d{4})/, '$1 $2 $3');
                    }
                    if (settings.CellOldValue != number || phoneNumber.val() != settings.CellOldValue) {
                        phoneNumber.val(number);
                        settings.CellOldValue = number;
                    }
                }
            }
            else if (countryId == '4') {
                if (valLenght >= 9 && valLenght <= 11) {
                    
                    var number = phoneVal.replace(/(\d{1})\-?(\d{4})\-?(\d{4})/, '($1) $2 $3');
                    if (valLenght == 10) {
                        number = phoneVal.replace(/(\d{2})\-?(\d{4})\-?(\d{4})/, '($1) $2 $3');
                    }
                    if (settings.CellOldValue != number || phoneNumber.val() != settings.CellOldValue) {
                        phoneNumber.val(number);
                        settings.CellOldValue = number;
                    }
                }
            }
            else {
                var PhoneFormate = new AsYouType(phonePluginGetCountryData(common.model.CountryId).countryCode);
                var number = PhoneFormate.input(phoneVal);
                var isSucess = PhoneFormate.isValid();
                if (isSucess && (settings.CellOldValue != number || phoneNumber.val() != settings.CellOldValue)) {
                    phoneNumber.val(number);
                    settings.CellOldValue = number;
                }
            }
        } else {
            var number = phoneNumber.val().replace(/(\d{3})\-?(\d{3})\-?(\d{4})/, '($1)-$2-$3');
            phoneNumber.val(number);
        }
        OnChageNumber(phoneNumber.val().replace(/([()-\s])+/g, ''));
    }
    var phonePluginShowHideWarningMessage = (strMessage) => {
        // $('.banner-warning .text-block-28').html(strMessage);
        // var ix = Webflow.require('ix');
        // var $target = $('.banner-warning');
        // var trigger;
        // var sTimeSec = phonePluginSetDisplayMessageTime(strMessage);

        // if (typeof ver != "undefined") {
        //     if (ver[0] <= 9) {
        //         var trigger = { "type": "click", "preserve3d": true, "stepsA": [{ "display": "-webkit-flex", "transition": "transform 300ms ease 0", "x": "0px", "y": "50px", "z": "0px" }, { "wait": sTimeSec }, { "transition": "transform 300 ease 0", "x": "0px", "y": "0px", "z": "0px" }, { "display": "none" }], "stepsB": [] };
        //         ix.run(trigger, $target);
        //     } else {
        //         var trigger = { "type": "click", "preserve3d": true, "stepsA": [{ "display": "flex", "transition": "transform 300ms ease 0", "x": "0px", "y": "50px", "z": "0px" }, { "wait": sTimeSec }, { "transition": "transform 300 ease 0", "x": "0px", "y": "0px", "z": "0px" }, { "display": "none" }], "stepsB": [] };
        //         ix.run(trigger, $target);
        //     }
        // } else {
        //     var trigger = { "type": "click", "preserve3d": true, "stepsA": [{ "display": "flex", "transition": "transform 300ms ease 0", "x": "0px", "y": "50px", "z": "0px" }, { "wait": sTimeSec }, { "transition": "transform 300 ease 0", "x": "0px", "y": "0px", "z": "0px" }, { "display": "none" }], "stepsB": [] };
        //     ix.run(trigger, $target);
        // }
    }
    var phonePluginSetDisplayMessageTime = (strMessage) => {
        var sTimesec = "2s"
        if (strMessage != '' && strMessage.length > 0) {
            if (strMessage.length < 50)
                sTimesec = "2s"
            else if (strMessage.length < 100)
                sTimesec = "3s"
            else if (strMessage.length < 200)
                sTimesec = "4s"
            else if (strMessage.length > 200)
                sTimesec = "7s"
        }
        return sTimesec;
    }
    var phonePluginGetCountryData = (CountryId) => {      
            var Country = $.grep(common.model.CountryList, function (n) {
                return (n.countryID == parseInt(CountryId));
            });
            return Country[0];
    }
    var CheckGlobalCountryList = () => {
        if (typeof window._glbPluginCountryList == 'undefined' || window._glbPluginCountryList == null || Object.keys(window._glbPluginCountryList).length == 0) {
            return false;
        }
        return true;
    }
    if (!phonePluginIsNullOrUndefineOrBlank(settings.ControlId)) {
        phonePluginGenerateHtml();
    }
    var publicMethod = {
        validatePhoneNumber: function (IsReturnObject = false, errorSpan = null, args = null) {
            if (IsReturnObject == false) {
                return phonePluginValidateCellNo(IsReturnObject);
            } else {
                if (typeof (args) != undefined && args != null) {
                    args.IsValid = true;
                }
                var ValidateReturnObject = phonePluginValidateCellNo(IsReturnObject);
                if (typeof (args) != undefined && args != null) {
                    args.IsValid = ValidateReturnObject.isSucess;
                }
                if (ValidateReturnObject.isSucess == false) {
                    settings.ValidateReturnObject = ValidateReturnObject;
                    if (errorSpan != null) {
                        $(errorSpan).html(ValidateReturnObject.WarningMessage);
                        $(errorSpan).show();
                    }
                    return ValidateReturnObject.isSucess;
                } else {
                    if (errorSpan != null) {
                        $(errorSpan).hide();
                    }
                    return ValidateReturnObject.isSucess;
                }

            }
        },
        setPhoneData: function (countryId, phoneNo) {
            common.model.CountryId = countryId;
            settings.CellOldValue = "";
            if (!phonePluginIsNullOrUndefineOrBlank(phoneNo)) {
                $(common.ControlsPrefix.PhoneControl).val(phoneNo);
            }
            phonePluginSelectCountry();
            phonePluginSetPhonePattern();
            return $(common.ControlsPrefix.PhoneControl).val();
        },
        getPhoneData: function () {
            var phoneData = {};
            phoneData.countryId = parseInt(common.model.CountryId);
            var FormatCellNo = '';
            var CellNo = '';
            if ($(common.ControlsPrefix.PhoneControl).val() != null && $(common.ControlsPrefix.PhoneControl).val() != "") {
                FormatCellNo = $(common.ControlsPrefix.PhoneControl).val().replace(/([()-\s])+/g, '');
                CellNo = (phoneData.countryId == 2) ? (FormatCellNo.length > 11 ? FormatCellNo.substring(1) : FormatCellNo) : ((FormatCellNo.length > 10) ? FormatCellNo.substring(1) : FormatCellNo);
            }
            phoneData.phoneNo = CellNo;
            phoneData.dialingCode = common.model.DialingCode;
            phoneData.countryCode = phonePluginGetCountryData(common.model.CountryId).CountryCode;
            return phoneData;
        },
        getCountryId: function () {
            return common.model.CountryId;
        },
        setDisabledControl: function (isDisabled) {
            settings.IsDisabled = isDisabled;
            $(common.ControlsPrefix.PhoneControl).attr('disabled', isDisabled);
            $(common.ControlsPrefix.ddlMainCountry).attr("disabled", isDisabled);
            var countryFlag = common.model.countryFlag[common.model.CountryId];
            $(common.ControlsPrefix.PhoneControllerCountry).removeClass();
            $(common.ControlsPrefix.PhoneControllerCountry).addClass("selectedcountry " + (isDisabled == true ? "select2-container-disabled " : "") + countryFlag);

        },
        setDisabledCountry: function (isDisabled) {
            settings.IsDisabled = isDisabled;
            $(common.ControlsPrefix.ddlMainCountry).attr("disabled", isDisabled);
            var countryFlag = common.model.countryFlag[common.model.CountryId];
            $(common.ControlsPrefix.PhoneControllerCountry).removeClass();
            $(common.ControlsPrefix.PhoneControllerCountry).addClass("selectedcountry " + (isDisabled == true ? "select2-container-disabled " : "") + countryFlag);
        },
        getCountryList: function () {
            return common.model.CountryList;
        },
        getdialingCode: function (CountryId) {
            if (CountryId > 0) {

                return phonePluginGetCountryData(CountryId).DialingCode;
            }
            else {
                return "";
            }
        },
        getphoneformat: function (phonenumber, countryId) {
            if (typeof countryId != "undefined" && countryId != null && countryId != "") {
                var phoneVal = phonenumber.replace(/([()-\s])+/g, ''); 
                var valLenght = phoneVal.length;
                if (valLenght <= 0) {
                    return;
                }
                if (phoneVal[0] == 0 && parseInt(countryId) <= 4) {
                    settings.CellOldValue = phoneVal;
                    phoneVal = phoneVal.substring(1);
                    valLenght = phoneVal.length;
                }
                if ((countryId == '1' || countryId == '3') && (valLenght == 9 || valLenght == 10)) {
                    if (countryId == '3') {
                        var number = phoneVal.replace(/(\d{3})\-?(\d{3})\-?(\d{4})/, '$1-$2-$3');
                            return number;
                    } else {
                        var number = phoneVal.replace(/(\d{3})\-?(\d{3})\-?(\d{4})/, '($1) $2-$3');
                            return number;
                    }
                }
                else if (countryId == '2') {
                    if (valLenght == 9 || valLenght == 10) {
                        if (phoneVal.substring(0, 1) == "1") {
                            number = phoneVal.replace(/(\d{4})\-?(\d{6})/, '$1 $2');
                        }
                        else if (phoneVal.substring(0, 1) == "2") {
                            number = phoneVal.replace(/(\d{2})\-?(\d{4})\-?(\d{4})/, '$1 $2 $3');
                        }
                        else if (phoneVal.substring(0, 1) == "7") {
                            number = phoneVal.replace(/(\d{4})\-?(\d{3})\-?(\d{3})/, '$1 $2 $3');
                        } else {
                            number = phoneVal.replace(/(\d{2})\-?(\d{4})\-?(\d{4})/, '$1 $2 $3');
                        }  
                            return number;
                    }
                }
                else if (countryId == '4') {
                    if (valLenght == 8 || valLenght == 9) {
                        var number = phoneVal.replace(/(\d{1})\-?(\d{4})\-?(\d{4})/, '($1) $2 $3');     
                            return number;
                    }
                    else if (valLenght == 10) {
                        var number = phoneVal.replace(/(\d{2})\-?(\d{4})\-?(\d{4})/, '($1) $2 $3');
                        return number;
                    }
                    
                }
                else {
                    var PhoneFormate = new AsYouType(phonePluginGetCountryData(countryId > 0 ? countryId : 1).CountryCode);
                    var number = PhoneFormate.input(phonenumber);
                    return number;
                }
            }
            return phonenumber;
        },
        getValidateMessage: function () {
            return settings.ValidateReturnObject;
        },
    };
    return publicMethod;
}
$(document).on("click", function (event) {
    try {
        if ($(event.target).is(".select2-container") === false) {
            if ($(".select2-dropdown-open").length > 0) {
                $(".select2-dropdown-open").select2('close');
            }
        }
    } catch (e) {
    }
});

export {PhonePlugin};