import { useEffect, useState } from "react";
import {  LeftCaret, RightCaret } from "../Icons/Carets";
import HamburgerIcon from "../Icons/Hamburger";
import XIcon from "../Icons/XIcon";
import { PageData } from "../Definitions/UserContext";
import React from "react";
import LayoutNode, { AttributeCollection, getNodeComponentAttributeValue } from "../Definitions/LayoutNode";
import { GetLinkFromLinkAttribute } from "./RenderButton";
import DOMPurify from 'dompurify';

export default function RenderNavigationList({
  node,
  isEditor,
}: {
  node: AttributeCollection;
  isEditor: boolean;
  
}) {

  const pageString = getNodeComponentAttributeValue(node, "pages","[]");
  const pageData = JSON.parse(pageString) as PageData[]??[];
  const logo = getNodeComponentAttributeValue(node, "logo","");

  const buttonName = getNodeComponentAttributeValue(node, "buttonName","");
  let buttonLink = getNodeComponentAttributeValue(node, "link","#");

  if((window as any).isEditor==true){
    buttonLink = "#";
  }
  else if(buttonLink.length>2)
  buttonLink = GetLinkFromLinkAttribute(buttonLink,pageData);
  
    return (
      <>
      <RenderMobileNavigationList pageData={pageData} isEditor={isEditor} buttonLink={buttonLink} buttonName={buttonName}/>
        {logo && logo.length>0 &&
      <Logo logo={logo}/>
        }

        <div className="flex-row nav-menu-row main-navigation relative" style={{gap:"inherit",rowGap:"6px"}}>
        {pageData.length>0 && pageData.map((item, index) => (
          <RenderNavListItem key={index} item={item}  isEditor={isEditor}/>
        ))}
        </div>
        {buttonName&&buttonName.length>0 &&
        <RenderCTAButton buttonName={buttonName} buttonLink={buttonLink}/>
        }
      </>
    )
  
}

function RenderCTAButton({buttonName,buttonLink}:{buttonName:string,buttonLink:string}){


  return <a className="v-button primary invisible-button nav-button" href={buttonLink} style={{fontSize:"1rem"}}>{buttonName}</a>
}

function Logo({logo}:{logo:string}){

  return <img className="nav-logo h-full" src={logo} style={{maxHeight:"300px"}}/>
          

}

function RenderMobileNavigationList({ pageData,isEditor,buttonLink,buttonName }: { pageData: PageData[],isEditor:boolean,buttonLink?:string,buttonName?:string }) {
  enum MobileNavState {
    Closed,
    Open,
    OpenToSubMenu,
  }

  const openToSubMenu = (subMenu: PageData) => {
    setNavState(MobileNavState.OpenToSubMenu);
    setSubMenu(subMenu);
  };

  const [navState, setNavState] = useState<MobileNavState>(
    MobileNavState.Closed
  );
  const [subMenu, setSubMenu] = useState<PageData | null>(null);

  if (navState == MobileNavState.Closed)
    return (
      <div  onClick={() => setNavState(MobileNavState.Open)} className="mobile-navigation" style={{cursor:"pointer"}}>
        <HamburgerIcon />
      </div>
    );
  else {

    const pages:PageData[] = (navState == MobileNavState.OpenToSubMenu) ? subMenu?.children??pageData : pageData;

    return (
      <>
        <div  onClick={() => setNavState(MobileNavState.Closed)} className="mobile-navigation" style={{cursor:"pointer"}}>
          <XIcon /></div>
          <div className="mobile-navigation-content">
          {subMenu && navState == MobileNavState.OpenToSubMenu && (
            <button className = "invisible-button no-link back-btn mobile-navigation" onClick={() => setNavState(MobileNavState.Open)}>
              <LeftCaret /><span>Back</span>
            </button>
          )}

          {pages.map((item, index) => (
            <RenderNavListItemMobile key={index} openToMenu={openToSubMenu} item={item} isEditor={isEditor} />
          ))}
          {buttonLink&&buttonLink.length>0 && buttonName&&buttonName.length>0 && navState != MobileNavState.OpenToSubMenu&&

            <div className="mobile-navigation" style={{paddingBottom:"16px"}}>
              <a className="v-button primary invisible-button" href={buttonLink} > {buttonName}</a>
            </div>

          }


        </div>
      </>
    );
  }
}

function RenderNavListItemMobile({
  item,
  openToMenu,
  isEditor
}: {
  item: PageData;
  openToMenu: (pageData: PageData) => void;
  isEditor:boolean;
}) {



  if (!item.children||item.children.length==0){

    let basePath = "";
    if(isEditor && window.location.href.includes("/preview")){
      let last = window.location.href.lastIndexOf("/");
      basePath = window.location.href.substring(0,last)+"/";


    }

    const link = (isEditor)?(
      basePath.length>0?basePath+item.pageId:"#"
      ):item.url;

    const sanitizedLink = DOMPurify.sanitize(link);
    return (

      

      <div className="mobile-navigation">
        <a className="no-a nav-link" id={item.pageId} href={sanitizedLink}>{item.name}</a>
      </div>
    );

  }
  else {
    return (
      <div
      className="no-link mobile-navigation"
        onClick={() => {
          openToMenu(item);
        }}
      >
        <span>{item.name} <RightCaret/> </span>
  
      </div>
    );
  }
}

function RenderNavListItem({
  item,
  isEditor,

}: {
  item: {
    isDisabled: boolean;
    pageId:string;
    name: string;
    url: string;
    children?: { name: string; url: string,isDisabled:boolean,pageId:string,isHidePage:boolean }[];
    isHidePage: boolean;
  },

  isEditor:boolean
}) {

  if(item.isDisabled)
    return null;

  if (!item.children||item.children.length == 0){
    let basePath = "";
    if(isEditor && window.location.href.includes("/preview")){
      let last = window.location.href.lastIndexOf("/");
      basePath = window.location.href.substring(0,last)+"/";


    }

    const link = (isEditor)?(
      basePath.length>0?basePath+item.pageId:"#"
      ):item.url;

    const sanitizedLink = DOMPurify.sanitize(link);

    return (
      <li className="full-navigation">
        <a className= "no-a nav-link" id={item.pageId} href={sanitizedLink}> {item.name} </a>
      </li>
    );
  }
  else {
    return (
      <li className="no-link full-navigation">
        <span>
          {item.name}
       
        </span>
        <ul className="v-navigation-child-list full-navigation">
          {item.children.map((item, index) => (
            <RenderNavListItem key={index} item={item} isEditor={isEditor}/>
          ))}
        </ul>
      </li>
    );
  }
}

