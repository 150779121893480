export const ToggleNames = {
    toggleTrigger: "toggle-trigger",
    findToggleInParent: "toggle-up",
    findToggleInChild: "toggle-down",
    toggleSiblings:"toggle-siblings",
    toggleable: "toggleable",
    toggledOn: "toggled-on"

}

export function BindAllToggles(){


    let doms = document.querySelectorAll(".render .toggle-trigger");

    for (let i = 0; i < doms.length; i++) {
        let trigger = doms[i];

        let target:any = trigger;
        if(trigger.classList.contains("toggle-up")){
           target = trigger.closest(".toggleable");
        }
        else if(trigger.classList.contains("toggle-down")){
            target = trigger.querySelector(".toggleable");
        }

        if(target&&trigger){
            (trigger as any).onclick =()=>{
                if(target.classList.contains(ToggleNames.toggleSiblings)&&!target.classList.contains(ToggleNames.toggledOn)){
                    let parent = target.parentElement;
                    if(parent){
                        let siblings = parent.querySelectorAll(".toggleable");
                        for(let i = 0;i<siblings.length;i++){
                            siblings[i].classList.remove(ToggleNames.toggledOn);
                        }
                    }
                }
                target.classList.toggle("toggled-on");
                target.classList.add("after-toggle");
                setTimeout(()=>{
                    target.classList.remove("after-toggle");
                },350);
            };
        }

    }

}