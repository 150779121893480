import React, { useEffect } from "react";

export default function EditorLoadingSpinner({fontSize}:{fontSize?:number}) {

    const font = fontSize?fontSize+"px":"20px";


  return (
    <div className="center-all w-full h-full">
      <div className="loading-spinner" style={{fontSize:font}}></div>
    </div>
 
  );
}