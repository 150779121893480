import { AsyncAjaxPost } from '../components/Utilities/AsyncAjaxService';
import VGPopUp from '../components/Controls/VGPopUp';
import React, { Fragment, useState } from 'react';
import { Form } from 'react-bootstrap';
import { createPortal, unmountComponentAtNode } from 'react-dom';
import "./VgMessage.scss";
import $ from 'jquery'
import { utils } from '../utils/Utils';
import Loader from '../components/Common/Loader';
import CustomToast from '../components/Utilities/CustomToast';
import { Site } from "../utils/Utils";

const StaffMessage = (props) => {

    const StaffMessage = props.data;
    const LoginDetails = props.LoginDetails;
    const siteData = props.siteData;
    const isMessage = props.isMessage;
    const [senderName, setSenderName] = useState(false);
    const [senderEmail, setsenderEmail] = useState(false);
    const [senderBody, setSenderBody] = useState(false);
    const [validEmail, setValidEmail] = useState(false);
    const [isContentLoading, setContentLoading] = useState(false);
    const BusinessLocation = utils.MultiLocationData(props.siteData, props.siteData.bidHash);


    const VagaroAPIEnum = {
        sendMessage: siteData.businessId + "/serviceprovider/" + StaffMessage.encServiceProviderID + "/email"        
    };


    const PopupTitle = () => {
        if (LoginDetails.EncUid === undefined || LoginDetails.EncUid === "" || LoginDetails.EncUid === null) {
            return "Alert"
        }
        else {
            const bImage = isMessage === true ? BusinessLocation.cdnUrl + "Original/" + BusinessLocation.businessImage : StaffMessage.photoURL;
            return (
                <Fragment>
                    <div className="bnameHead">
                        {(typeof bImage == 'undefined' || bImage == null || bImage == "") &&
                            <div style={{ display: "block" }}>
                                <div className="bimage">{isMessage === true ? BusinessLocation.spintials : StaffMessage.spintials}</div>
                            </div>
                        }
                        {(typeof bImage !== 'undefined' && bImage !== null && bImage !== "") && <div className="bimage" style={{ backgroundImage: `url(${bImage})` }}></div>}
                        {isMessage === true ? BusinessLocation.businessName : StaffMessage.firstName + " " + StaffMessage.lastName}
                    </div>
                </Fragment>
            )
        }
    };

    const PopupBody = () => {
        if (LoginDetails.EncUid === undefined || LoginDetails.EncUid === "" || LoginDetails.EncUid === null) {
            return "Login is required to send message."
        }
        else {
            return (
                <Fragment>
                    {isContentLoading && < Loader />}
                    <div className="messagepopupform">
                        <Form className="form-content">
                            <div className="name-email-div-flex">
                                <Form.Group className={`form-row ${senderName && "form-row-invalid"}`} >
                                    <Form.Control defaultValue={isMessage === true ? "" : LoginDetails.UName} onChange={(e) => handleChangeName(e.target.value)} autoComplete='off' id='txtSenderName' maxLength={100} type="text" placeholder="Your name" />
                                    {senderName && <Form.Control.Feedback type="invalid">required</Form.Control.Feedback>}
                                </Form.Group>
                                <Form.Group className={`form-row ${(senderEmail || validEmail) && "form-row-invalid"}`}>
                                    <Form.Control defaultValue={isMessage === true ? "" : LoginDetails.UEmail} onBlur={(e) => handleChangeEmail(e.target.value.trim())} onChange={(e) => handleOnChangeEmail(e.target.value.trim())} autoComplete='off' id='txtSenderEmail' maxLength={100} type="text" placeholder="Your email address" />
                                    {senderEmail && <Form.Control.Feedback type="invalid">required</Form.Control.Feedback>}
                                    {validEmail && <Form.Control.Feedback type="invalid">Please enter a valid email address.</Form.Control.Feedback>}
                                </Form.Group>
                            </div>
                            <Form.Group className={`form-row ${senderBody && "form-row-invalid"}`}>
                                <Form.Control as="textarea" id='txtbody' onChange={(e) => handleChangeBody(e.target.value)} maxLength={5000} autoComplete='off' placeholder="Write your message..." rows={3} />
                                {senderBody && <Form.Control.Feedback type="invalid">required</Form.Control.Feedback>}
                            </Form.Group>
                        </Form>
                    </div>
                </Fragment>
            )
        }
    }

    const Footer = () => {
        if (LoginDetails.EncUid === undefined || LoginDetails.EncUid === "" || LoginDetails.EncUid === null) {
            return (
                <Fragment>
                    <button onClick={() => handlepopupClose()} className="v-button primary invisible-button">OK</button>
                </Fragment>
            )
        }
        else {
            return (
                <Fragment>
                    <button onClick={() => sendMail()} className="v-button primary invisible-button">Send</button>
                </Fragment >
            )
        }
    }

    const handlepopupClose = () => {
        unmountComponentAtNode(document.getElementById("OpenDialog"));
    };

    const handleChangeEmail = (event) => {
        if (event === "" || event === undefined || event === null) {
            setValidEmail(false);
            setsenderEmail(true);
        }
        else if (event !== "" || event !== undefined || event !== null) {
            setsenderEmail(false);
            setValidEmail(false);
            EmailValidation(event);
        }
        else {
            setsenderEmail(false);
        }
    };

    const handleOnChangeEmail = (event) => {
        if (event === "" || event === undefined || event === null) {
            setValidEmail(false);
        }
        else if (event !== "" || event !== undefined || event !== null) {
            setsenderEmail(false);
            setValidEmail(false);
        }
        else {
            setsenderEmail(false);
        }
    };



    const EmailValidation = (EmailAddress) => {
        const regex = /^[A-Za-z0-9_\+-]+(\.[A-Za-z0-9_\+-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*\.([A-Za-z]{2,10})$/;
        if (!EmailAddress || regex.test(EmailAddress) === false) {
            setValidEmail(true);
            return true;
        } else {
            setValidEmail(false);
            return false;
        }
    };

    const handleChangeName = (event) => {
        if (event === "" || event === undefined || event === null) {
            setSenderName(true);
        }
        else {
            setSenderName(false);
        }
    };

    const handleChangeBody = (event) => {
        if (event === "" || event === undefined || event === null) {
            setSenderBody(true);
        }
        else {
            setSenderBody(false);
        }
    };

    const sendMail = async () => {
        var validate = false;
        if ($('#txtSenderName').val() === "" || $('#txtSenderName').val() === undefined || $('#txtSenderName').val() === null) {
            setSenderName(true);
            validate = true;
        }
        if ($('#txtSenderEmail').val() === "" || $('#txtSenderEmail').val() === undefined || $('#txtSenderEmail').val() === null) {
            setsenderEmail(true);
            validate = true;
        }
        else {
            if (EmailValidation($('#txtSenderEmail').val())) {
                validate = true;
            }
        }
        if ($('#txtbody').val() === "" || $('#txtbody').val() === undefined || $('#txtbody').val() === null) {
            setSenderBody(true);
            validate = true;
        }
        if (validate === true) {
            return;
        }
        else {
            setSenderName(false);
            EmailValidation($('#txtSenderEmail').val());
            setSenderBody(false);
            setContentLoading(true);
            const Requestdata = {};
            Requestdata.Subject = isMessage ? "Message to Business" : "Email to Service Provider"
            Requestdata.PageUrl = isMessage ? "" : Site.SiteName + BusinessLocation.vagaroURL + "/staff";
            Requestdata.FromName = document.getElementById("txtSenderName").value.trim();
            Requestdata.FromEmail = document.getElementById("txtSenderEmail").value.trim();
            Requestdata.Body = $('#txtbody').val();
            Requestdata.SPName = isMessage ? BusinessLocation.businessName : StaffMessage.firstName + " " + StaffMessage.lastName;
            Requestdata.Imageurl = "";
            Requestdata.ContactNo = "";
            Requestdata.EncSPId = isMessage ? "U2FsdGVkX19RaRCoxsZnmWHv7WXC5pa8QX6mjZ4chvo=" : StaffMessage.encServiceProviderID;
            Requestdata.StaffEmail = isMessage ? BusinessLocation.email : StaffMessage.emailId;
            await AsyncAjaxPost(
                VagaroAPIEnum.sendMessage,
                Requestdata,
                siteData.accessToken,
                function onSuccess(data) {
                    handlepopupClose();
                    setContentLoading(false);
                    var SentName = isMessage === true ? BusinessLocation.businessName : StaffMessage.firstName + " " + StaffMessage.lastName
                    CustomToast("Message sent to " + SentName + ".", "CustomToast");
                })
        }
    }


    return createPortal(
        <VGPopUp
            show={true}
            title={PopupTitle()}
            onHide={handlepopupClose}
            body={PopupBody()}
            footer={Footer()}
            dialogClassName={LoginDetails.EncUid === undefined || LoginDetails.EncUid === "" || LoginDetails.EncUid === null ? "vgn-modal-alert vgn-modal-small" : "vgui-send-message"}
            isShowFooter={true}
        />,
        document.getElementById("OpenDialog")
    )
}

export default StaffMessage;
