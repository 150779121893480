import React from 'react';

import RenderStarRating from "../../Icons/StarRating";
import Dropdown from 'react-bootstrap/Dropdown';
import { addtoCartEnum, utils } from "../../../utils/Utils";
import { UserContext } from "../../Definitions/UserContext";
import StencilRows from "../../Binding/StencilRows";
import styles from './RenderStaffMemberCards.module.css';


const avatarSize = "4.5em";
const avatarStyle = { width: avatarSize, height: avatarSize, borderRadius: "50%" };




const goldenRatio = 1.61803398875;

// Calculate the padding based on the golden ratio
const topPadding = 8;
const leftPadding = topPadding * goldenRatio;


export default function RenderStaffMemberCards({ staffMember, siteData, isStenciled, serviceList, layout }: { staffMember: any, siteData: UserContext, isStenciled: boolean, serviceList: any, layout: string }) {

    // if (!staffMember.photoURLForOriginal) {
    //     staffMember.photoURLForOriginal = defaultImageUrls[4];
    // }
   
    staffMember.photoURL = staffMember.photoURLForOriginal;
    //validate data and replace with empty strings to avoid extra checks during rendering
    if (!staffMember.businessSummary)
        staffMember.businessSummary = "";
    if (!staffMember.spLargePhotoUrl)
        staffMember.spLargePhotoUrl = "";
    if (!staffMember.reviewRank)
        staffMember.reviewRank = 0;
    if (!staffMember.reviewCount)
        staffMember.reviewCount = 0;
    if (!staffMember.firstName)
        staffMember.firstName = "";
    if (!staffMember.lastName)
        staffMember.lastName = "";

    var IsEditor = (window as any).isEditor !== undefined ? (window as any).isEditor : false;
    var BusinessData = utils.MultiLocationData(siteData, siteData.bidHash);
    return (
        <>
            <div className="flex-column justify-between perfect-shadow " style={{ alignContent: "center", width: "100%", height: "100%", margin: "auto", borderRadius: "8px" }}>
                <div  onClick={(event) => !IsEditor && (window as any).Staffreview(staffMember, siteData)} >
                    {/* Full-width image at the top */}
            
                     {typeof staffMember.photoURLForOriginal != 'undefined' && staffMember.photoURLForOriginal != null && staffMember.photoURLForOriginal != "" &&
                                <div className="aspect-ratio-container">
                                    <div className={`stencil-item staff-image-border ${styles.profileImage}`} style={{ backgroundImage: `url(${staffMember.photoURLForOriginal})`, borderRadius: "8px 8px 0px 0px"  }}></div>

                                </div>
                            }

                        {(typeof staffMember.photoURLForOriginal == 'undefined' || staffMember.photoURLForOriginal == null || staffMember.photoURLForOriginal == "") &&
                            <div className=" stencil-item " style={{ borderRadius: "8px 8px 0px 0px" }}>
                                <div style={{borderRadius:"8px 8px 0 0"}} className={`${styles.initialPlaceholderWrapper} staff-image-border`}>

                                    <div className={styles.staffInitialPlaceholderImage}>
                                        <div className="review-profile-inner-div intialsname xvg-avatar-inital center-all stencil-item" style={avatarStyle}>
                                            <div className="review-profile-inner vg-avatar-inital-inner ">
                                                <h3 className="review-profile-inner-text w-full">{staffMember.spintials}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                    {/* Centered content below the image */}
                    <div style={{ padding: "16px", textAlign: "center" }}>
                    <h4 style={{ maxWidth: "500px", wordBreak: "break-word" }}>{staffMember.title}</h4>
                                        <h3 style={{ maxWidth: "500px", wordBreak: "break-word", paddingBottom: "12.944px", color: "inherit" }}>{staffMember.firstName} {staffMember.lastName}</h3>
                        <div style={{ display: "flex", justifyContent: "center", paddingTop: "8px", alignItems: "center", gap: "14px", marginBottom: "12.944px" }}>
                            {!isStenciled && <RenderStarRating rating={Math.round(staffMember.reviewRank)} starColor="yellow" isOutlined={false} />}
                            <span className="vp-2">({staffMember.reviewCount})</span>
                        </div>
                        <h3 className="staff-phone stencil-item text-align-center-mobile" style={{ marginTop: "4px", color: "inherit" }}>{staffMember.cell}
                                        </h3>
                    </div>

                    {isStenciled ?
                        <StencilRows rowCount={3} />
                        :
                        <div className={"vp-2"} style={{  padding: `${topPadding}px ${leftPadding}px`, textAlign: "left" }}>
                            {staffMember.businessSummary}
                        </div>
                    }
                </div>
                <div className="flex-row justify-center w-full">
                <div className="gap-large flex-row-to-column booknowdrp full-width-mobile" style={{ padding: "10px 16px 30px", textAlign: "right" }}>
                  {staffMember.isShowMessage && <button className="invisible-button v-button v-button-with-icon secondary full-width-mobile center-all" onClick={() => !IsEditor && (window as any).StaffMessage(staffMember, siteData, BusinessData)}><i className="fal fa-envelope bicon"></i>Message</button>}

                  {serviceList > 0 && staffMember.isAllowBook ? staffMember.isServiceTab && staffMember.isClassTab ?
                      <Dropdown>
                          <Dropdown.Toggle bsPrefix="invisible-button v-button primary full-width-mobile" id="dropdown-basic">
                              {staffMember.bookText}<i className="bicon2 fal fa-angle-down"></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                              <Dropdown.Item onClick={() => !IsEditor && (window as any).BookStaffSerice(staffMember, BusinessData)} className="no-a">Services</Dropdown.Item>
                              <Dropdown.Item onClick={() => !IsEditor && (window as any).BookStaffClass(staffMember, BusinessData)} className="no-a">Classes</Dropdown.Item>
                          </Dropdown.Menu>
                      </Dropdown>
                      :
                      <button type="button" onClick={() => !IsEditor && (window as any).BookServiceClass(staffMember, BusinessData)} className="invisible-button v-button primary full-width-mobile">{staffMember.bookText}</button>
                      : ""}
              </div>
          </div>
            </div>



        </>
    )
}
