import React, { Fragment, useState } from 'react'
import '../components/Styles/FormComponents.scss'
import { createPortal, unmountComponentAtNode } from "react-dom";
import VGPopUp from '../components/Controls/VGPopUp';
import { AsyncAjaxGet } from "../components/Utilities/AsyncAjaxService";
import { ResponseCode, utils } from "../utils/Utils";
import Loader from '../components/Common/Loader';
import $ from "jquery";

const FormActivate = (props) => {

    const [FormURL, setFormURL] = useState("");
    const [isLoading, setLoading] = useState(false);

    const PopupClose = () => {
        unmountComponentAtNode(document.getElementById("OpenDialog"));
    };

    const ActivateForms = async () => {
        setLoading(true);
        const FormActivateURL = "merchants/" + props.siteData.businessId + "/Form/activate?CreatedBy=" + props.userID;
        await AsyncAjaxGet(
            FormActivateURL,
            props.siteData.accessToken,
            function onSuccess(data) {
                try {
                    if (
                        data != null &&
                        data.data != null &&
                        data.data.responseCode == ResponseCode.Success
                    ) {
                        if (data != null && data.data != null && data.data.data != null) {
                            var activateReposnse = data.data.data;
                            if (activateReposnse.isFormsEnable) {
                                if (activateReposnse.formsCount > 0) {
                                    unmountComponentAtNode(document.getElementById("OpenDialog"));
                                }
                                else {
                                    if (utils.isNotEmptyOrWhiteSpace(activateReposnse) && utils.isNotEmptyOrWhiteSpace(activateReposnse.formsURL)) {
                                        setFormURL(activateReposnse.formsURL);
                                    }
                                    else {
                                        unmountComponentAtNode(document.getElementById("OpenDialog"));
                                    }
                                }

                                props.onActivate();
                            }
                        }
                        setLoading(false);
                    }
                    setLoading(false);
                } catch (error) {
                    setLoading(false);
                }
            },
            function OnError() {
                setLoading(false);
            }
        );
    }


    const sendPositionbook = function (glbIframeParentID, src) {
        if (glbIframeParentID != '' && typeof glbIframeParentID != 'undefined') {
            var parentid = $('#' + glbIframeParentID);
            var currentScrollPositionofParent = (document.scrollingElement).scrollTop;
            var iframestartposition = parentid.find('iframe').offset().top;
            var modalTop = currentScrollPositionofParent - iframestartposition
            var modalHeight = window.screen.availHeight;
            if (modalTop < 0) {
                modalTop = 0;
            }
            parentid.find('iframe')[0].contentWindow.postMessage('{"eventType": "modalPosition", "modalTop" : ' + modalTop + ',"modalHeight" : "' + modalHeight + '"}', getSource(src));
        }
    }

    const LoadForm = function (root) {
        const getId = 'form-' + root;
        if (document.getElementById(getId) !== undefined) {
            var iframeElement = document.getElementById(getId);
            if (iframeElement) {
                var styleobject = {};
                var gblstyle = $("#dvglbstyle");
                var primarybutton = gblstyle.find("#btnprimarybutton");
                var secondorybutton = gblstyle.find("#btnsecondary");
                var primarybuttonHover = gblstyle.find("#btnprimarybuttonhover");
                var secondorybuttonHover = gblstyle.find("#btnsecondarybuttonhover");
                var lblstyle = gblstyle.find("#txtfontfamily");
                var btnrating = gblstyle.find("#btnrating");
                var glbbackground = gblstyle.find("#glbbackground");
                var glbHeadFonts = gblstyle.find("#glbHeadingFont");
                styleobject.primarybuttoncolor = primarybutton.css("color");
                styleobject.primarybuttonbackgroundcolor = primarybutton.css("background-color");
                styleobject.primarybuttonHoverbackgroundcolor = primarybuttonHover.css("background-color");
                styleobject.primarybuttonborderradius = primarybutton.css("border-radius");
                styleobject.primarybuttonboxshadow = primarybutton.css("box-shadow");
                styleobject.secondorybuttonbuttoncolor = secondorybutton.css("color");
                styleobject.secondorybuttonbuttonbackgroundcolor = secondorybutton.css("background-color");
                styleobject.secondorybuttonbuttonHoverbackgroundcolor = secondorybuttonHover.css("background-color");
                styleobject.secondorybuttonbuttonborderradius = secondorybutton.css("border-radius");
                styleobject.secondorybuttonbuttonboxshadow = secondorybutton.css("box-shadow");
                styleobject.lblstylefontfamily = 'proxima-nova'
                styleobject.lblstylecolor = lblstyle.css("color");
                styleobject.lblstylefontweight = lblstyle.css("font-weight");
                styleobject.lblstylefontsize = lblstyle.css("font-size");
                styleobject.btnratingcolor = btnrating.css("color");
                styleobject.glbbackgroundcolor = glbbackground.css("background-color");
                styleobject.glbHeadingFonts = 'proxima-nova'
                var cookieobject = {};
                cookieobject.vdsid = localStorage.getItem("vdsid");
                //var styledata: any = { 'eventType': 'addStyle', style: styleobject, 'parentID': 'divformsiframe', 'cookieobject': cookieobject }
                var styledata = { 'eventType': 'getHeightFormBuilder', 'parentID': 'divformsiframe' }
                iframeElement.contentWindow.postMessage(JSON.stringify(styledata), getSource(iframeElement.src));
                sendPositionbook('divformsiframe', iframeElement.src);
            }
        }
    }

    const getSource = (src) => {
        var _target = null;
        if (_target == null) {
            var targetRegex = /^(https*:\/\/[^\/]*).*$/g;
            var match = targetRegex.exec(src);
            if (match) {
                _target = match[1];
            }
        }
        return _target;
    }


    return createPortal(

        <VGPopUp
            id={"FormActivate"}
            show={true}
            onHide={PopupClose}
            title={utils.isNotEmptyOrWhiteSpace(FormURL) ? "Business Form" : "Form Activate"}
            size="xl"
            dialogClassName={"modal-xl"}
            isShowFooter={false}
            body={
                <Fragment>
                    {isLoading && <Loader />}
                    {utils.isNotEmptyOrWhiteSpace(FormURL) ?
                        <div id="divformsiframe" className='vgFrameWrapper' style={{ minHeight: "75vh" }}>
                            <iframe key="formeditor" scrolling="no" id="formeditor" style={{ minHeight: "75vh" }} onLoad={() => { LoadForm("formeditor") }} src={FormURL} width="100%"></iframe>
                        </div>

                        :
                        <Fragment>
                            <div className='form-component-wrapper'>
                                <div className="settings-body-div forms newScrollFix" tabIndex="0" style={{ overflow: 'hidden' }}>
                                    <div className="forms-sales" style={{ height: "400px!important" }}>
                                        <div className="hero-banner flex-center">
                                            <div className="heading-text-div forms-heading">
                                                <h1>Forms</h1>
                                                <h1 className="cc-subheading">Create custom forms and automatically send them to your clients before or after appointments.</h1>
                                                <div className="div-block-45 hero">
                                                    <div className="btn-width80per settings-get-started">
                                                        <div onClick={() => ActivateForms()} className="get-started-btn" style={{ transition: 'all 0.2s ease 0s' }}>
                                                            <div className="get-text"><span id="spnFormsPrice"><span>Try it Free - {props.data.currencySymbol}{props.data.formsCharge}/mo After Trial</span></span></div>
                                                        </div>
                                                    </div>

                                                </div>
                                                {/* <div className="forms-play" >
                                                        <div className="text-block-282"></div>
                                                        <div className="text-block-281">Play Video</div>
                                                    </div> */}
                                            </div>
                                            <div className="cc-hero-image-div store">
                                                <img src="https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/forms-ipad-iphone-x.png" width="1000" alt="" className="cc-hero-image store" style={{ opacity: "1", transform: "translateX(0px) translateY(0px) translateZ(0px) rotateX(0deg) rotateY(0deg) rotateZ(-27deg)", transition: "opacity 600ms ease 0s, transform 600ms ease 0s" }} />
                                            </div>
                                        </div>
                                        <div className="online-form-body-new" id="formsBody">
                                            <div className="store-section flex limit-flex">
                                                <div className='heading-block justify'>
                                                    <h1>Simply Drag & Drop to Create</h1>
                                                    <div className='content-block'>
                                                        With an easy drag and drop builder, you can create your own custom form and add specific question types like multiple choice, checkboxes, short answer, and more!
                                                    </div>
                                                </div>
                                                <div className='div-block-345 space-left'>
                                                    <img src="https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/froms-dragdrop-img.gif" alt="" width="412.5" class="image-36 shadow"></img>
                                                </div>
                                            </div>


                                            <div className="store-section flex flip limit-flex">
                                                <div className='div-block-345'>
                                                    <img src="https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/from-create-newimg.jpg" width="400" alt="" class="image-36 shadow"></img>
                                                </div>
                                                <div className='heading-block justify'>
                                                    <h1>Quick Start with Templates</h1>
                                                    <div className='content-block'>
                                                        Get a jumpstart with our templates to quickly create an form for clients to fill out while booking an appointment or a form to send to clients after their appointment is completed. There is even a SOAP Note template and a liability waiver template to help you get started.
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="store-section flex limit-flex">
                                                <div className='heading-block justify'>
                                                    <h1>Appointment Intake Forms</h1>
                                                    <div className='content-block'>
                                                        Create forms that are required when booking a specific service or class. Determine whether the form is required every time or just the first time the client books that service or class.
                                                    </div>
                                                </div>
                                                <div className='div-block-345 space-left'>
                                                    <img src="https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/forms-appointment-Intake-img.png" alt="" width="412.5" class="image-36 shadow"></img>
                                                </div>
                                            </div>


                                            <div className="store-section flex flip limit-flex">
                                                <div className='div-block-345'>
                                                    <img src="https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/forms-rate-img.png" alt="" width="377.5" class="image-36 shadow" />
                                                </div>
                                                <div className='heading-block justify'>
                                                    <h1>Customer Feedback</h1>
                                                    <div className='content-block'>
                                                        Curious about a customer's experience? Automatically send them a form with specific questions about their visit to find out what is working and what could be improved.
                                                    </div>
                                                </div>
                                            </div>



                                            <div className="store-section flex limit-flex">
                                                <div className='heading-block justify'>
                                                    <h1>Liability Waiver</h1>
                                                    <div className='content-block'>
                                                        Gather customer consent with liability waivers. Clients will be asked to digitally sign a waiver or contract that is inserted into the form.
                                                    </div>
                                                </div>
                                                <div className='div-block-345 space-left'>
                                                    <img src="https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/forms-sign-img.png" alt="" width="500" class="image-36 shadow"></img>
                                                </div>
                                            </div>


                                            <div className='store-section limit-flex padding-bott15'>
                                                <div className='heading-block'>
                                                    <h1>Create a Custom Form</h1>
                                                    <div className='content-block'>
                                                        Drag and drop different question types into your form.
                                                    </div>
                                                </div>
                                                <div className='custom-icons-list left'>
                                                    <div class="div-25">
                                                        <div class="div-block-41 left">
                                                            <div class="div-block-346 v-icon">
                                                                <div></div>
                                                            </div>
                                                            <h1 class="simple-header small">Short Answer</h1>
                                                        </div>
                                                    </div>
                                                    <div class="div-25">
                                                        <div class="div-block-41 left">
                                                            <div class="div-block-346">
                                                                <div></div>
                                                            </div>
                                                            <h1 class="simple-header small">Long Answer</h1>
                                                        </div>
                                                    </div>
                                                    <div class="div-25">
                                                        <div class="div-block-41 left">
                                                            <div class="div-block-346">
                                                                <div></div>
                                                            </div>
                                                            <h1 class="simple-header small">Choose One</h1>
                                                        </div>
                                                    </div>
                                                    <div class="div-25">
                                                        <div class="div-block-41 left">
                                                            <div class="div-block-346">
                                                                <div></div>
                                                            </div>
                                                            <h1 class="simple-header small">Multiple Choice</h1>
                                                        </div>
                                                    </div>
                                                    <div class="div-25">
                                                        <div class="div-block-41 left">
                                                            <div class="div-block-346">
                                                                <div></div>
                                                            </div>
                                                            <h1 class="simple-header small">Dropdown</h1>
                                                        </div>
                                                    </div>
                                                    <div class="div-25">
                                                        <div class="div-block-41 left">
                                                            <div class="div-block-346 v-icon">
                                                                <div></div>
                                                            </div>
                                                            <h1 class="simple-header small">Scale</h1>
                                                        </div>
                                                    </div>
                                                    <div class="div-25">
                                                        <div class="div-block-41 left">
                                                            <div class="div-block-346">
                                                                <div></div>
                                                            </div>
                                                            <h1 class="simple-header small">Contact</h1>
                                                        </div>
                                                    </div>
                                                    <div class="div-25">
                                                        <div class="div-block-41 left">
                                                            <div class="div-block-346">
                                                                <div></div>
                                                            </div>
                                                            <h1 class="simple-header small">Date &amp; Time</h1>
                                                        </div>
                                                    </div>
                                                    <div class="div-25">
                                                        <div class="div-block-41 left">
                                                            <div class="div-block-346">
                                                                <div></div>
                                                            </div>
                                                            <h1 class="simple-header small">Signature</h1>
                                                        </div>
                                                    </div>
                                                    <div class="div-25">
                                                        <div class="div-block-41 left">
                                                            <div class="div-block-346">
                                                                <div></div>
                                                            </div>
                                                            <h1 class="simple-header small">Image</h1>
                                                        </div>
                                                    </div>
                                                    <div class="div-25">
                                                        <div class="div-block-41 left">
                                                            <div class="div-block-346">
                                                                <div></div>
                                                            </div>
                                                            <h1 class="simple-header small">Text</h1>
                                                        </div>
                                                    </div>
                                                    <div class="div-25">
                                                        <div class="div-block-41 left">
                                                            <div class="div-block-346">
                                                                <div class="div-block-347"></div>
                                                            </div>
                                                            <h1 class="simple-header small">Separator</h1>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    }
                </Fragment>
            }           
            isRemoveTabModalIndex={false}
            backdrop={true}
        />,
        document.getElementById("OpenDialog"));
}

export default FormActivate
