

import {ScreenSize} from "../Definitions/ScreenSize";
import LayoutNode, { getNodeComponentAttributeValue, getNodeRootStyleAttributeValue, getNodeStyleAttributes } from "../Definitions/LayoutNode";
import { attributeTypes, componentAttributeTypes } from "../Definitions/AttributeTypes";
import { wBComponentTypeNames } from "../Definitions/WBComponentTypeNames";
import { UserContext } from "../Definitions/UserContext";

export function GetNodeDomAttributes(
    classNameAdd: string,
    component: LayoutNode,
    screenSize: ScreenSize,
    isEditor :boolean
  ) {

 

    const id = (isEditor ? component.id : component.id.length>2?"v"+component.id:"");
    const animation = component.componentAttributes[componentAttributeTypes.animation]??"";
    const visibility = component.componentAttributes[componentAttributeTypes.visibility]??"";
    const fixedHeight = getNodeRootStyleAttributeValue(component, ScreenSize.Desktop,attributeTypes.height,"").includes("px")?" fixed-height ":"";
    const isAbsolute = getNodeRootStyleAttributeValue(component, ScreenSize.Desktop,attributeTypes.position,"") === "absolute";
    classNameAdd+=" "+visibility + (isAbsolute?" vwb-absolute ":"");
    let styles = {};

    if (isEditor) {
      styles = getNodeStyleAttributes(component, ScreenSize.Desktop) ?? {};

      switch (screenSize) {
        case ScreenSize.Mobile:
          styles = {
            ...styles,
            ...getNodeStyleAttributes(component, ScreenSize.Tablet),
            ...getNodeStyleAttributes(component, ScreenSize.Mobile),
          };
          break;
        case ScreenSize.Tablet:
          styles = {
            ...styles,
            ...getNodeStyleAttributes(component, ScreenSize.Tablet),
          };
          break;
        default:
          break;
      }

      

      let overlay = component.componentAttributes[componentAttributeTypes.overlayColor];

      if(overlay && overlay.length>3){
       
      
        let getStyle = styles as any;
        let shadow = getStyle.boxShadow;

        if(shadow && shadow!="none"){
          shadow += ` ,inset 0 0 0 30000px ${overlay}`;

        }
        else{
          shadow = `inset 0 0 0 30000px ${overlay}`;
        }

        styles = {...styles,boxShadow:shadow};
      }
  


    }

    else
      classNameAdd+=" "+component.tokenClasses;

    classNameAdd+=fixedHeight;

    if(animation!="")
    return {
      className: `hierarchy ${classNameAdd}`,
      id: id ,
      vanimation:animation,
      componenttype: component.typename,
      style: styles,
    };
    else
      return{
        className: `hierarchy ${classNameAdd}`,
        id: id,
        componenttype: component.typename,
        style: styles,

      }
  }

export function GetLocationHoverAtt(node:LayoutNode,userContext:UserContext){

  let location  = getNodeComponentAttributeValue(node,componentAttributeTypes.multiLocation,"");


  if(location.length<=0){
    return {};
  }

  let bNameStr = " Vagaro";
  if(userContext.MultilocationData&&userContext.MultilocationData.length>0)
  for(let i = 0;i<userContext.MultilocationData.length;i++){
      let loc = userContext.MultilocationData[i];

      if(loc.encBID==location){
        bNameStr = loc.businessName;
        break;
      }
  }

  return {"data-location-hover":"⇄ Synced with "+bNameStr};


}

export function GetNodeComponenentAttributeBinding(node:LayoutNode,isEditor:boolean){

  if(node.id.length<=0||isEditor){ // if the node doens't have a valid this indicates it's a temp one that's rendering live

    return {};
  }

  const str = JSON.stringify(node.componentAttributes);
  const encoded = encodeURIComponent(str);

  return {"component-attributes": encoded};

}