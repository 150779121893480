    import React, { Fragment } from "react";
import ReactDom from "react-dom";
import MembershipAddToCart from "../../Hooks/MembershipAddToCart";
import OopsBanner from "../OopsBanner";
import { UserContext } from "../Definitions/UserContext";
import { NoRecordsBanner } from '../OopsBanner';
import "../Styles/BusinessComponents.scss";
export default function RenderBusinessMemberships({ data, layout = "1", siteData, rootId = "", allowMultiLoc }: { data: any, layout: string, siteData: UserContext, rootId: string, allowMultiLoc:boolean }) {


    //invalid data:
    if (data == null) return <OopsBanner />;

    if (data.length == 0) {
        return <NoRecordsBanner message='No memberships available.' />
    }

    const bData = data[0];
    const SiteData = siteData;
    const isStenciled = bData?.isStenciled == true;
    //invalid data:
    if (!bData?.membershipList) return <OopsBanner />

    //map the categories to accordians 
    return (
        <Fragment>
            <div className={"services-container "+(isStenciled?"stenciled":"")} >
                <div className="membership-flex-row  flex-wrap">
                    {bData?.membershipList.map((membership: any, index: number) => {
                        return (
                            <RenderMembership membership={membership} key={index} data={bData} layout={layout} siteData={SiteData} rootId={rootId + index} allowMultiLoc={allowMultiLoc}/>
                        );
                    })}
                </div>
            </div>
        </Fragment>
    );
}

//Entry point to render individual membership based no layout
function RenderMembership({ membership, data, layout, siteData, rootId, allowMultiLoc }: { membership: any, data: any, layout: string, siteData: any, rootId: string, allowMultiLoc:boolean }) {

    switch (layout) {
        case "2": return <RenderMembershipDefault membership={membership} data={data} siteData={siteData} rootId={rootId} width="100%" allowMultiLoc={allowMultiLoc} />
        case "3": return <RenderMembershipCard membership={membership} data={data} siteData={siteData} rootId={rootId} allowMultiLoc={allowMultiLoc}  />
        default: return <RenderMembershipDefault membership={membership} data={data} siteData={siteData} rootId={rootId} width="50%" allowMultiLoc={allowMultiLoc}/>
    }

}


function RenderMembershipOne({ membership, data, siteData, rootId, allowMultiLoc }: { membership: any, data: any, siteData: any, rootId: string, allowMultiLoc:boolean }) {
    return (
        <div className="b-panel-container   w-full " style={{ padding: "8px"}}>
        <div
            className="service-box one service-panel flex-row align-start gap-small "
            style={{ width: "100%" }}>
            <div className="   flex-column justify-between align-start w-full" style={{ padding: "12px" }}>
                <div className="flex-column gap-med w-full">
                    <div className="flex-row justify-between w-full align-start">
                        <div><MembershipTitle membership={membership} />
                        </div>
                        <MembershipPricing membership={membership} data={data} />
                    </div>
                    <div className="flex-row justify-end align-start">
                        <MembershipVPL membership={membership} data={data}/>
                    </div>
                    <MembershipDescription membership={membership} />
              
                    <div className="flex-row justify-end align-start">
                        <MembershipBillingCycle membership={membership} />
                    </div>
                </div>
                <div className="flex-row justify-end w-full">
                    <MembershipButtons membership={membership} data={data} siteData={siteData} rootId={rootId} allowMultiLoc={allowMultiLoc}/>
                </div>
            </div>
        </div>
        </div>
    );
}

function RenderMembershipCard({ membership, data, siteData, rootId, allowMultiLoc }: { membership: any, data: any, siteData: any, rootId: string, allowMultiLoc:boolean}) {
    return (
        <div className="b-panel-container   quarter-third-half-full " style={{ padding: "8px"}}>
        <div className="flex-column service-panel gap-med w-full  full-width-mobile service-card   " style={{ padding: "6px" }}>
            <MembershipTitle membership={membership} />
            <MembershipPricing membership={membership} data={data} />
            <MembershipVPL membership={membership} data={data}/>
            <MembershipDescription membership={membership} />
            <MembershipBillingCycle membership={membership} />
            <div className="flex-row justify-end w-full    align-end">
                <MembershipButtons membership={membership} data={data} siteData={siteData} rootId={rootId} allowMultiLoc={allowMultiLoc}/>
            </div>
        </div>
        </div>
    );

}


function RenderMembershipDefault({ membership, data, siteData, rootId,width, allowMultiLoc }: { membership: any, data: any, siteData: any, rootId: string,width:string, allowMultiLoc:boolean }) {
  
    return <div className="b-panel-container full-width-tablet" style={{ padding: "8px",width:width}}>
    <div className="service-box four service-panel flex-column gap-med justify-between align-start w-full   " style={{ padding: "20px" }}>
        <div className="flex-row gap-large w-full">
            <div className="flex-column    w-full justify-between">
                <div className="flex-column w-full    panel-content">
                    <div className="title-pricing-row  align-start " style={{ paddingBottom: "4px" }}>
                        <span><MembershipTitle membership={membership} /></span>
                        <span className="membership-pricing-text stencil-item"><MembershipPricing membership={membership} data={data} /></span>
                    </div>
                    <div className="flex-row  right-to-left-align align-start">
                        <MembershipVPL membership={membership} data={data}/>
                    </div>
                    <div className="flex-row  right-to-left-align  align-start">
                        <MembershipBillingCycle membership={membership} />
                    </div>

                    <div><MembershipDescription membership={membership} /></div>
                </div>
            </div>
        </div>
        <div className="flex-row justify-end w-full"><MembershipButtons membership={membership} data={data} siteData={siteData} rootId={rootId} allowMultiLoc={allowMultiLoc}/></div>
    </div>
    </div>
}

function MembershipTitle({ membership }: { membership: any }) {
    return <h4 className="service-title ">{membership.membershipName}</h4>
}

function MembershipDescription({ membership }: { membership: any }) {
    return <div className="service-description vp-2 " style={{ padding: "0px 0px 8px", marginBottom: "12px" }}><p className="service-description stencil-item" style={{wordBreak:"break-word",wordWrap:"break-word",marginTop:"8px",marginBottom:"8px", color:"#3333",opacity:.9}}>{membership.billingCycleString}</p>{membership.description}
    </div>
}

function MembershipVPL({ membership ,data }: { membership: any ,data:any }) {
    return <Fragment>
            {membership.vplMessage && membership.vplMessage.length > 0 &&    
            <div  className="s-other stencil-item"style={{ fontSize: "12px" }}>
                {membership.vplMessage} &nbsp;
                <VPLIcon />
            </div>
        }
    </Fragment>
}


function MembershipBillingCycle({ membership }: { membership: any }) {
    return <Fragment>
        {membership.initialDiscount > 0 || (typeof membership.promotionDetail != 'undefined' && membership.promotionDetail != '' && membership.billingCycleString != 'undefined' && membership.billingCycleString != '') && (
            <div className="service-description stencil-item" style={{ fontSize: "12px" }}>
                {membership.BillingCycleString} &nbsp;
            </div>
        )}
    </Fragment>
}


function VPLIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 200 39"
            fill="currentColor"
        >
            <g>
                <path d="M47.5265 34.3823V9.84253C47.5265 8.81932 48.091 8.20967 49.1097 8.20967H50.7735C50.9919 8.18922 51.212 8.21742 51.4183 8.29232C51.6247 8.36719 51.8119 8.4869 51.9668 8.64288C52.1216 8.79885 52.2403 8.98725 52.3144 9.19463C52.3884 9.40201 52.4158 9.62324 52.3949 9.84253V11.0874C53.9823 8.73406 56.3254 7.7876 58.889 7.7876C64.4409 7.7876 68.227 12.2257 68.227 17.8363C68.227 23.4467 64.4493 27.8893 58.889 27.8893C56.3423 27.8893 53.9823 26.9385 52.3949 24.5894V34.395C52.3949 35.4225 51.7922 35.9895 50.7735 35.9895H49.131C48.9138 36.0181 48.6931 35.9963 48.4859 35.9257C48.2785 35.8549 48.0902 35.7375 47.9351 35.5823C47.7801 35.4269 47.6627 35.238 47.5919 35.0301C47.5211 34.8219 47.4987 34.6003 47.5265 34.3823ZM63.3457 17.8448C63.3457 14.5833 61.3848 11.8761 57.9468 11.8761C54.5852 11.8761 52.3185 14.4341 52.2847 17.8661C52.3185 21.3194 54.5852 23.8603 57.9468 23.8603C61.3848 23.839 63.3457 21.1446 63.3457 17.8448Z" />
                <path d="M68.6091 17.8455C68.6091 12.235 72.4291 7.79688 77.9725 7.79688C80.5192 7.79688 82.8452 8.74333 84.4666 11.0967V9.84328C84.4666 8.82007 85.0354 8.21041 86.054 8.21041H87.7136C87.9324 8.18929 88.1531 8.217 88.3598 8.29159C88.5666 8.36617 88.7544 8.48577 88.9098 8.64184C89.0652 8.79789 89.1843 8.98654 89.2585 9.19425C89.3327 9.40195 89.3603 9.6236 89.3392 9.84328V25.8862C89.3392 26.9136 88.7365 27.4807 87.7136 27.4807H86.0583C85.8439 27.5051 85.6268 27.4807 85.4232 27.4092C85.2194 27.3377 85.0344 27.2211 84.8819 27.0679C84.7293 26.9147 84.6131 26.7287 84.5419 26.5242C84.4707 26.3197 84.4464 26.1015 84.4709 25.8862V24.6072C82.8495 26.9563 80.5064 27.9071 77.9767 27.9071C72.4207 27.8985 68.6091 23.4518 68.6091 17.8455ZM84.543 17.8455C84.5048 14.3965 82.2765 11.8769 78.919 11.8769C75.481 11.8769 73.4776 14.6054 73.4776 17.8669C73.4776 21.1284 75.481 23.861 78.919 23.861C82.2765 23.8397 84.5048 21.3202 84.543 17.8455Z" />
                <path d="M88.1061 35.4786C87.1638 35.0523 86.9771 34.2295 87.3888 33.3171L87.6562 32.6734C88.0807 31.6842 88.7513 31.535 89.7317 31.9146C90.4591 32.1895 91.2259 32.3436 92.0025 32.3708C95.0968 32.3708 96.7989 29.7147 98.1571 26.4533L90.9414 10.1033C90.4492 9.00339 90.9796 8.20616 92.185 8.20616H94.2267C94.6292 8.17723 95.0294 8.2887 95.3595 8.52169C95.6897 8.75471 95.9297 9.09501 96.0391 9.48515L100.644 21.205L105.063 9.48515C105.189 9.0901 105.444 8.74921 105.787 8.51725C106.13 8.28529 106.54 8.17572 106.952 8.20616H108.951C110.122 8.20616 110.649 9.00339 110.161 10.1033L103.59 25.3533C102.079 28.8065 99.927 36.391 92.2614 36.391C90.8277 36.3857 89.4113 36.0747 88.1061 35.4786Z" />
                <path d="M118.026 25.837V1.59997C118.002 1.3848 118.026 1.16686 118.097 0.962429C118.169 0.758004 118.285 0.572386 118.438 0.419453C118.59 0.266522 118.775 0.150234 118.979 0.0792857C119.183 0.00833759 119.4 -0.0154355 119.614 0.00974413H121.312C121.526 -0.0146047 121.742 0.00974522 121.946 0.0809876C122.149 0.15223 122.334 0.268538 122.486 0.421283C122.639 0.574029 122.755 0.759294 122.827 0.963339C122.898 1.16738 122.923 1.38497 122.899 1.59997V25.837C122.923 26.0523 122.899 26.2704 122.828 26.475C122.757 26.6795 122.641 26.8654 122.488 27.0187C122.336 27.1719 122.15 27.2885 121.947 27.36C121.743 27.4315 121.526 27.4559 121.312 27.4315H119.614C119.4 27.4559 119.182 27.4315 118.979 27.36C118.775 27.2885 118.59 27.1719 118.438 27.0187C118.285 26.8654 118.169 26.6795 118.098 26.475C118.026 26.2704 118.002 26.0523 118.026 25.837Z" />
                <path d="M124.565 17.8455C124.565 12.235 128.385 7.79688 133.928 7.79688C136.475 7.79688 138.801 8.74333 140.426 11.0967V9.84328C140.426 8.82007 140.991 8.21041 142.014 8.21041H143.673C143.892 8.19005 144.112 8.21831 144.319 8.29319C144.525 8.36804 144.713 8.48766 144.868 8.64355C145.023 8.79943 145.142 8.98774 145.217 9.1951C145.291 9.40246 145.319 9.62376 145.299 9.84328V25.8862C145.299 26.9136 144.692 27.4807 143.673 27.4807H142.014C141.799 27.5051 141.582 27.4807 141.379 27.4092C141.175 27.3377 140.99 27.2211 140.837 27.0679C140.685 26.9147 140.569 26.7287 140.497 26.5242C140.426 26.3197 140.402 26.1015 140.426 25.8862V24.6072C138.801 26.9563 136.462 27.9071 133.928 27.9071C128.381 27.8985 124.565 23.4518 124.565 17.8455ZM140.499 17.8455C140.465 14.3965 138.236 11.8769 134.875 11.8769C131.437 11.8769 129.437 14.6054 129.437 17.8669C129.437 21.1284 131.437 23.8397 134.87 23.8397C138.236 23.8397 140.465 21.3202 140.499 17.8455Z" />
                <path d="M149.301 21.2233V11.942H147.178C147.064 11.9533 146.949 11.9389 146.841 11.9001C146.733 11.8612 146.635 11.7987 146.553 11.7172C146.472 11.6357 146.41 11.5371 146.371 11.4285C146.333 11.3199 146.318 11.2041 146.33 11.0893V9.08554C146.318 8.96976 146.332 8.85285 146.37 8.74304C146.409 8.63321 146.47 8.53315 146.551 8.44987C146.632 8.36657 146.73 8.30208 146.839 8.26092C146.947 8.21975 147.063 8.20292 147.178 8.21158H149.522L150.201 4.0804C150.212 3.91824 150.255 3.75992 150.328 3.61466C150.4 3.46938 150.501 3.34005 150.624 3.23416C150.747 3.12826 150.889 3.04793 151.043 2.99778C151.197 2.94765 151.359 2.92873 151.521 2.9421H152.845C153.013 2.92461 153.183 2.94505 153.342 3.00193C153.501 3.05881 153.645 3.15066 153.764 3.27081C153.884 3.39096 153.975 3.53632 154.031 3.69632C154.087 3.8563 154.107 4.02687 154.089 4.19551V8.21158H158.847C158.964 8.19957 159.082 8.21392 159.193 8.25361C159.304 8.29328 159.405 8.35729 159.488 8.44097C159.572 8.52464 159.635 8.62592 159.675 8.7374C159.714 8.84889 159.729 8.96782 159.717 9.08554V11.0595C159.729 11.1762 159.714 11.2941 159.674 11.4044C159.634 11.5148 159.57 11.6146 159.486 11.6965C159.403 11.7785 159.302 11.8404 159.191 11.8778C159.08 11.9151 158.963 11.9269 158.847 11.9121H154.105V21.4151C154.105 23.1588 154.976 23.841 155.994 23.841C156.248 23.8541 156.501 23.8142 156.738 23.724C156.975 23.6339 157.191 23.4953 157.372 23.3169C157.553 23.1386 157.696 22.9244 157.79 22.6881C157.885 22.4519 157.929 22.1983 157.921 21.9437C157.926 21.7157 157.913 21.4877 157.883 21.2617C157.807 20.6562 157.994 20.3152 158.6 20.3152H160.6C160.819 20.285 161.042 20.3383 161.224 20.4644C161.406 20.5906 161.534 20.7808 161.584 20.9973C161.691 21.4708 161.754 21.9532 161.771 22.4383C161.771 26.0025 159.28 27.8996 155.693 27.8996C152.522 27.8996 149.301 26.3819 149.301 21.2233Z" />
                <path d="M161.25 17.9072C161.25 12.4074 165.444 7.78174 171.675 7.78174C176.811 7.78174 181.264 10.8897 181.64 17.0332C181.718 18.0223 181.076 18.5509 180.058 18.5509H165.97C165.894 21.4841 168.466 23.8758 172.43 23.8758C174.106 23.8763 175.745 23.389 177.15 22.4732C177.488 22.2212 177.91 22.1118 178.326 22.1684C178.743 22.225 179.12 22.443 179.379 22.7758L179.718 23.2021C180.397 23.9611 180.359 24.9075 179.417 25.628C177.334 27.177 174.792 27.9787 172.201 27.9047C165.406 27.8961 161.25 23.4196 161.25 17.9072ZM176.955 15.4813C176.2 12.4458 173.785 11.6869 171.747 11.6869C169.625 11.6869 167.099 12.5396 166.195 15.4813H176.955Z" />
                <path d="M183.126 25.8886V9.84562C183.126 8.82243 183.69 8.21278 184.708 8.21278H186.295C187.315 8.21278 187.883 8.82243 187.883 9.84562V12.5017C188.524 9.20187 190.982 7.81201 192.942 7.81201C193.196 7.82106 193.449 7.84527 193.699 7.88449C194.548 7.96124 194.679 8.64336 194.679 9.40224V11.2994C194.679 12.2843 194.229 12.702 193.508 12.5784C193.186 12.5045 192.858 12.4659 192.528 12.4633C190.715 12.4633 187.995 13.5632 187.995 19.8601V25.8886C187.995 26.916 187.391 27.4829 186.373 27.4829H184.708C184.495 27.5077 184.277 27.4833 184.074 27.4118C183.87 27.3403 183.686 27.2235 183.533 27.0701C183.382 26.9169 183.265 26.7309 183.195 26.5262C183.124 26.3217 183.101 26.1037 183.126 25.8886Z" />
                <path d="M39.2819 5.16552C39.0569 4.28575 38.6614 3.45915 38.1179 2.73297C37.5747 2.00677 36.8943 1.39525 36.1156 0.933315C35.3426 0.478196 34.487 0.18302 33.5987 0.0651662C32.7104 -0.0526869 31.8077 0.00916601 30.9435 0.247082C30.0796 0.485001 29.2715 0.894172 28.5673 1.4505C27.863 2.00683 27.2767 2.69907 26.8428 3.48648L19.7581 16.0794L12.6683 3.48153C12.2341 2.69456 11.6475 2.00279 10.9431 1.44695C10.2386 0.891111 9.43058 0.482441 8.56655 0.244993C7.70249 0.007546 6.79988 -0.0538802 5.91184 0.0643307C5.02381 0.182542 4.1683 0.478001 3.39565 0.933315C1.83021 1.883 0.694776 3.408 0.230304 5.18468C-0.23417 6.96138 0.00887172 8.84995 0.907851 10.4496L13.868 33.4823C14.3059 34.2709 14.8958 34.9638 15.6032 35.5208C16.3106 36.0779 17.1213 36.4879 17.9881 36.7268C18.5644 36.8878 19.1599 36.9692 19.7581 36.9688C20.4376 36.9701 21.1129 36.8633 21.7592 36.6527C23.4075 36.1296 24.7961 34.9962 25.6432 33.4823L38.6034 10.4496C39.5073 8.84462 39.7507 6.94836 39.2819 5.16552ZM4.5068 2.90869C5.02676 2.60289 5.60233 2.40451 6.19968 2.32522C6.79703 2.24594 7.40413 2.28731 7.98534 2.44695C8.56655 2.60658 9.11013 2.88125 9.5842 3.25481C10.0583 3.62837 10.4532 4.09329 10.746 4.6223L18.4749 18.361L13.8877 26.5142C13.636 26.952 13.4316 27.4156 13.278 27.8971L2.83516 9.30885C2.22949 8.23339 2.06527 6.96306 2.37743 5.7679C2.68959 4.57274 3.4535 3.54698 4.5068 2.90869ZM36.6761 9.32862L23.7208 32.3416C23.3225 33.06 22.7403 33.6582 22.0345 34.0749C21.4001 34.4523 20.6817 34.6647 19.945 34.6923H19.8073C18.9907 34.7014 18.1871 34.488 17.4817 34.0749C16.4251 33.4346 15.6591 32.4055 15.3469 31.2062C15.0346 30.0071 15.2008 28.733 15.8101 27.6551L28.7702 4.6223C29.1614 3.91132 29.7356 3.31895 30.4327 2.90716C31.1298 2.49537 31.9243 2.27927 32.7329 2.28149C33.5331 2.28206 34.3181 2.50052 35.0044 2.91362C35.5286 3.22317 35.9866 3.63388 36.352 4.1221C36.7174 4.61031 36.983 5.16634 37.1333 5.75814C37.4493 6.95622 37.2852 8.23098 36.6761 9.30885V9.32862Z" />
                <path d="M19.9505 31.643C20.3415 31.6537 20.7269 31.5471 21.0574 31.3367C21.3878 31.1264 21.6484 30.8218 21.8055 30.4619C21.9626 30.102 22.0094 29.7033 21.9397 29.3166C21.8702 28.9298 21.6873 28.5728 21.4145 28.2911C21.1417 28.0094 20.7914 27.8158 20.4085 27.735C20.0256 27.6543 19.6274 27.6901 19.2649 27.8378C18.9024 27.9858 18.5921 28.2387 18.3734 28.5646C18.1548 28.8907 18.0381 29.2746 18.0379 29.6676C18.0334 29.9236 18.0793 30.1782 18.1732 30.4164C18.2669 30.6546 18.4069 30.8716 18.5846 31.0552C18.7624 31.2388 18.9747 31.3853 19.2091 31.4863C19.4435 31.5871 19.6954 31.6404 19.9505 31.643Z" />
                <path
                    d="M192.068 25.1487C192.068 24.5492 192.243 23.9629 192.573 23.4641C192.904 22.9656 193.373 22.5769 193.92 22.3475C194.47 22.118 195.074 22.058 195.655 22.175C196.237 22.292 196.773 22.5807 197.192 23.0047C197.612 23.4288 197.899 23.9691 198.014 24.5572C198.131 25.1454 198.072 25.755 197.845 26.3091C197.616 26.8631 197.232 27.3368 196.738 27.6699C196.245 28.0031 195.665 28.1809 195.07 28.1809C194.274 28.1809 193.51 27.8615 192.947 27.2929C192.383 26.7241 192.068 25.953 192.068 25.1487Z"
                    strokeWidth="0.974855"
                />
            </g>
        </svg>
    );
}

function MembershipPricing({ membership, data }: { membership: any, data: any }) {
    const promotionValue=membership.promotionValue;
    const promotionVal = promotionValue.split("/");
    const chargeFrequency = promotionVal[1];
    const taxInclude= (parseFloat(membership.charge) - parseFloat(membership.charge) * membership.initialDiscount/100).toFixed(2);

    return <Fragment>
        {(typeof membership.promotionDetail != 'undefined' && membership.promotionDetail != '' && membership.promotionValue != 'undefined' && membership.promotionValue != '') ?
            <div className="service-pricing s-other stencil-item" >
                <div className="font-med flex-column gap-small" >
                    <span style={{ textDecoration: "line-through", textAlign: "right",color:"#777" }}>
                        {membership.currencySymbol + membership.charge}
                    </span>
                    <span className="membership-pricing-text stencil-item">
                        {membership.taxes !== "" ?(membership.currencySymbol+taxInclude+"/"+chargeFrequency):(membership.promotionValue)}
                    </span>
                </div>
            </div>
            :
            <Fragment>
                {membership.promotionValue != 0 ?
                    <div className="font-med flex-column ">
                        <span style={{ textDecoration: "line-through",textAlign: "right",color:"#777" }}>
                            {membership.currencySymbol + membership.charge}
                        </span>
                        <span>
                        {membership.taxes !== "" ?(membership.currencySymbol+taxInclude+"/"+chargeFrequency):(membership.promotionValue)}
                        </span>
                    </div> : <>{membership.currencySymbol + membership.charge}</>}
            </Fragment>
        }
    </Fragment>
}

function MembershipButtons({ membership, data, siteData, rootId, allowMultiLoc }: { membership: any, data: any, siteData: any, rootId: string, allowMultiLoc:boolean }) {
    var IsEditor = (window as any).isEditor !== undefined ? (window as any).isEditor : false;
    return <div className="flex-row gap-med w-full justify-end">
        <a role="button" id={"membership-atc-" + rootId} className="v-button primary invisible-button full-width-475" onClick={(event) => !IsEditor && (window as any).membershipAddToCart(membership, data, siteData, event, allowMultiLoc)}>Add to Cart</a>
    </div>
}
declare global {
    interface Window { membershipAddToCart: any; }
}

window.membershipAddToCart = function (membership: any, data: any, siteData: any, event: any, allowMultiLoc:boolean) {
    OpenMembershipAddToCart({ membership, data, siteData, event, allowMultiLoc });
};

function OpenMembershipAddToCart({ membership, data, siteData, event,allowMultiLoc }: { membership: any, data: any, siteData: any, event: any, allowMultiLoc:boolean }) {
    return ReactDom.render(
        <MembershipAddToCart
            data={membership}
            cartBook={true}
            SiteData={siteData}
            event={event}
            ispackage={false}
            allowMultiLoc={allowMultiLoc}
        />,
        document.getElementById("OpenDialog")
    );

}