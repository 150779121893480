/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { NoRecordsBanner } from "../OopsBanner";
import ReactDom from "react-dom";
import { UserContext } from "../Definitions/UserContext";
import PhotoGallaryPop from "../../VagaroComponent/PhotoGallaryPop";
import PhotoSwipeLightbox from "../../utils/photoswipe-lightbox.esm";
import PhotoSwipe from "../../utils/photoswipe.esm";
import PhotoGallaryPopupView from "../../VagaroComponent/PhotoGallaryPopupView";
import { utils } from "../../utils/Utils";
import styled from 'styled-components';
import './RenderBusinessGallery.scss';
const goldenRatio = 1.61803398875;
const marginBottom = `${16 * goldenRatio}px`; // Convert marginBottom to use the golden ratio



export default function RenderBusinessGallery({ layout, userContext, data }: { layout: string, userContext: UserContext, data: any }) {

    const isEditor = (window as any).isEditor !== undefined ? (window as any).isEditor : false
    const Records = data;

    const isStenciled = data?.isStenciled === true;

    if (data?.totalRecords == 0) {
        return <NoRecordsBanner message='Venue gallery is not available.' />
    }

    let photos = data?.records;

    if (!photos || photos.length == 0) {
        return <NoRecordsBanner message='Venue gallery is not available.' />
    }

    switch (layout) {
        case '2':
            return <RenderGridLayout edit={isEditor} userContext={userContext} pDetails={photos?.photoDetail ?? null} data={Records} isStenciled={isStenciled} />;
        case '3':
            return <RenderQuadGridLayout edit={isEditor} userContext={userContext} pDetails={photos?.photoDetail ?? null} data={Records} isStenciled={isStenciled} />;
        case '4':
            return <RenderMosaicGridLayout edit={isEditor} userContext={userContext} pDetails={photos?.photoDetail ?? null} data={Records} isStenciled={isStenciled} />;
        default:
            return <RenderDefaultLayout edit={isEditor} userContext={userContext} pDetails={photos?.photoDetail ?? null} data={Records} isStenciled={isStenciled} />;
    }


}

const imageClickAction = () => {
    return false;
}

function RenderDefaultLayout({ edit, userContext, pDetails, data, isStenciled }: { edit: boolean, userContext: UserContext, pDetails: any, data: any, isStenciled: boolean }) {
    const BusinessData = utils.MultiLocationData(userContext, userContext.bidHash);
    useEffect(() => {
        lightboxEffect(pDetails,data,edit,userContext,BusinessData,isStenciled);

    });

    if (!pDetails || pDetails.length == 0)
        return <NoRecordsBanner />

    const firstThree = (pDetails.length > 4) ? pDetails.slice(0, 3) : pDetails.slice(0, 4);
    const indexLimt = pDetails.length > 4 ? 3 : 4
    const firdBusinessDetail = {
        styleID: "U2FsdGVkX18EI4k80FtcVtq4PzxA94OPqaGqyI1vxNM=",
        photoUrl: BusinessData.cdnUrl + "Original/" + BusinessData.businessImage,
    }
    return <>
        <div className={"flex-row w-full " + (isStenciled ? "stenciled" : "")} style={{ gap: "inherit" }}>
            <RenderPhoto photo={firdBusinessDetail} key={"photo"} index={-1} hide={true} isStenciled={isStenciled} />
            {
                pDetails.map((photo: any, index: number) => {
                    return <RenderPhoto isStenciled={isStenciled} photo={photo} key={index + "photo"} index={index} hide={indexLimt <= index ? true : false} />
                })
            }
            {pDetails.length > 4 && <AdditionalPhotos edit={edit} userContext={userContext} photo={pDetails[3]} numOfExtra={pDetails.length - indexLimt - 1} data={data} businessData={BusinessData} />}
        </div>
    </>
}

const GridContainer = styled.div`
  display: grid;
  width: 100%;
  margin: auto;
  max-width: 1300px; // Adjust as needed
  grid-template-columns: repeat(3, 1fr); // 3-column grid
  gap: 16px; // Adjust the gap as needed
  padding: 16px; // Add padding for the white border effect

 


  // Tablet (768px and up)
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr); // 2-column grid for tablets
    gap: 8px; // Adjust the gap as needed
  }

  // Mobile (576px and up)
  @media screen and (max-width: 576px) {
    grid-template-columns: repeat(1, 1fr); // 1-column grid for mobile
    gap: 6px; // Adjust the gap as needed
    padding: 8px; // Add padding for the white border effect
    margin-top: 16px; // Adjust margin as needed
  }

   // Tablet (768px and up)
   @container (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr); // 2-column grid for tablets
    gap: 8px; // Adjust the gap as needed
  }

  // Mobile (576px and up)
  @container (max-width: 576px) {
    grid-template-columns: repeat(1, 1fr); // 1-column grid for mobile
    gap: 6px; // Adjust the gap as needed
    padding: 8px; // Add padding for the white border effect
    margin-top: 16px; // Adjust margin as needed
  }
  // Add more styles as needed for other breakpoints
`;


const MasonryGridContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  padding-bottom: 16px;
  padding-top: 16px;
  column-count: 2; /* Number of columns for larger screens */
  column-gap: calc(16px * ${goldenRatio}); /* Column gap based on the golden ratio */
  row-gap: 16px; /* Vertical gap */

  & > div {
    /* Apply to all images */
    width: 100%;
    break-inside: avoid-column; /* Prevents images from being split across columns */
  }

  & > div:nth-child(odd) {
    /* Target odd images (1st, 3rd, 5th, etc.) */
    grid-row-end: span 2; /* Make odd images portrait */
  }

  & > div:nth-child(even) {
    /* Target even images (2nd, 4th, 6th, etc.) */
    grid-row-end: span 1; /* Make even images landscape */
  }

  // Tablet and up
  @media screen and (max-width: 768px) {
      column-count: 2;
      column-gap: 24px;
  }
// Tablet and up
@container(max-width: 768px) {
      column-count: 2;
      column-gap: 24px;
      .photo-card {
        height: 300px !important;
      }
  }
  // Mobile
  @container (max-width: 576px) {
      column-count: 1; /* One column for mobile */
      column-gap: 2px;
      padding-top: 4px;
      margin-top: 0px;
      .photo-card {
        height: 300px !important;
      }
  }
`;


function RenderGridLayout({ edit, userContext, pDetails, data, isStenciled }: { edit: boolean, userContext: UserContext, pDetails: any, data: any, isStenciled: boolean }) {
    const BusinessData = utils.MultiLocationData(userContext, userContext.bidHash);
    useEffect(() => {
        lightboxEffect(pDetails,data,edit,userContext,BusinessData,isStenciled);
    });

    if (!pDetails || pDetails.length == 0)
        return <NoRecordsBanner />


    const firdBusinessDetail = {
        styleID: "U2FsdGVkX18EI4k80FtcVtq4PzxA94OPqaGqyI1vxNM=",
        photoUrl: BusinessData.cdnUrl + "Original/" + BusinessData.businessImage,
    }
    return <>
        <GridContainer className={isStenciled ? "stenciled" : ""}>
            <RenderGridPhoto photo={firdBusinessDetail} key={"photo"} index={-1} hide={true} isStenciled={isStenciled} />
            {pDetails.map((photo: any, index: number) => (
                <RenderGridPhoto isStenciled={isStenciled} photo={photo} key={index + "photo"} index={index} hide={false} />
            ))}
            {/* {pDetails.length > 8 && <AdditionalGridPhotos edit={edit} userContext={userContext} photo={pDetails[8]} numOfExtra={pDetails.length - indexLimt } data={data} businessData={BusinessData} />} */}
        </GridContainer>

    </>
}


const OverlappingGridContainer = styled.div`
  display: grid;
  width: 100%;
  max-width: 1300px;
  margin: auto;

`;



function RenderQuadGridLayout({ edit, userContext, pDetails, data, isStenciled }: { edit: boolean, userContext: UserContext, pDetails: any, data: any, isStenciled: boolean }) {
 
    const BusinessData = utils.MultiLocationData(userContext, userContext.bidHash);
    useEffect(() => {
        lightboxEffect(pDetails,data,edit,userContext,BusinessData,isStenciled);
    });

    if (!pDetails || pDetails.length == 0)
        return <NoRecordsBanner />

    // const firstThree = (pDetails.length > 9) ? pDetails.slice(0, 8) : pDetails.slice(0, 8);
    const indexLimt = pDetails.length > 3 ? 4 : pDetails.length;
    const firdBusinessDetail = {
        styleID: "U2FsdGVkX18EI4k80FtcVtq4PzxA94OPqaGqyI1vxNM=",
        photoUrl: BusinessData.cdnUrl + "Original/" + BusinessData.businessImage,
    }
    return <>


<OverlappingGridContainer className={`${isStenciled ? "stenciled" : ""}`}>

<RenderGrid2Photo photo={firdBusinessDetail} key={"photo"} index={-1} hide={true} isStenciled={isStenciled} />

<div className="row" style={{padding: "0"}}>
    {pDetails.map((photo: any, index: number) => { // Use slice here


        if(index>3){
           return <RenderGrid3Photo isStenciled={isStenciled} photo={photo} key={index + "photo"} hide={true} index={index}/>
        }

        const isLeft = index % 2 === 0; // Determines if the box should be left or right
        const isSmall = index >= 2; // Determines if the box should be small

        // Determine the class for the box
        let boxClass = `box ${isLeft ? "box--left" : "box--right"}`;
        if (isSmall) {
            boxClass += " box--small";
        }

        return (
            <div className={boxClass}>
                <div className="box__inner">
                    {/* Render your custom component here */}
                    <RenderGrid3Photo 
                        isStenciled={isStenciled} 
                        photo={photo} 
                        key={index + "photo"} 
                        index={index} 
                        hide={indexLimt <= index} // Check if this logic is still needed
                    />
                </div>
            </div>
        );
    })}
</div>

{pDetails.length > 4 && <AdditionalGridPhotos3 edit={edit} userContext={userContext} photo={pDetails[4]} numOfExtra={pDetails.length - indexLimt - 1} data={data} businessData={BusinessData} />}
</OverlappingGridContainer>


        {/* new div to map over */}
 
        
     

    </>
}

function lightboxEffect(pDetails:any,data:any,edit:boolean,userContext:UserContext,BusinessData:any,isStenciled:boolean){
    if ((pDetails && pDetails.length > 0) && !edit && !isStenciled) {
        let lightbox = new PhotoSwipeLightbox({
            gallery: '.v-photo-gallery',
            children: '.card-images',
            padding: { top: 8, bottom: 8, left: 8, right: 8 },
            // type:"html",
            loop: false,
            pswpModule: PhotoSwipe,
            showHideAnimationType: 'none',
            zoomAnimationDuration: false,
            initialZoomLevel: "fit",
            secondaryZoomLevel: "fit",
            maxZoomLevel: "fit",
            imageClickAction: imageClickAction(),
        });
        lightbox.on("contentLoad", (e) => {
            const { content } = e;


            if (content.type === "html") {
                e.preventDefault();
                var RendomNumber = data.StyleID + (Math.floor(Math.random() * (9999 - 1 + 1)) + 1).toString();
                var dataset = content.data.element.children[0].dataset;
                content.element = document.createElement("div");
                content.element.id = `html-container-Photo-${dataset.style}`;
                content.element.className = `pswp__content`;
                var ArrayValue = pDetails.find((arr: any) => arr.styleID == dataset.style);

                let elem = content.element

                const closePopup = () => {
                    if (elem)
                        ReactDom.unmountComponentAtNode(elem);

                    let remainder = document.querySelector(".pswp");
                    if (remainder) {
                        remainder.remove();
                    }
                }
                ReactDom.render(
                    <PhotoGallaryPopupView
                        userContext={userContext}
                        businessData={BusinessData}
                        key={RendomNumber}
                        Data={ArrayValue}
                        closeView={closePopup}
                        merchantName={"RespnceData.records.merchantName"}
                        element={content.element}
                    />,
                    content.element
                );
            }

        });
        lightbox.on('contentActivate', ({ content }) => {

            if (content.type === "image") {
                const elements = document.getElementsByClassName("pswp__img");
                const elementsArray = Array.from(elements);
                elementsArray.forEach((element, index) => {
                    if (element.parentElement) {
                        if (content.index == 0) {
                            element.parentElement.title = BusinessData.businessName + ' Logo';
                        } else {
                            element.parentElement.title = 'Gallery Image';
                        }
                    }
                });
            }
        });
        lightbox.init();
        return () => {
            lightbox.destroy();
        };

    }

}


function RenderMosaicGridLayout({ edit, userContext, pDetails, data, isStenciled }: { edit: boolean, userContext: UserContext, pDetails: any, data: any, isStenciled: boolean }) {
    const BusinessData = utils.MultiLocationData(userContext, userContext.bidHash);
    // const limitedPDetails = pDetails.slice(0, 8);
     // Calculate half of the available index, rounded down.
     const halfIndex = Math.floor(pDetails.length / 1);
     const limitedPDetails = pDetails.slice(0, halfIndex);
    useEffect(() => {
       lightboxEffect(pDetails,data,edit,userContext,BusinessData,isStenciled);
    });

        if (!limitedPDetails || limitedPDetails.length == 0)
        return <NoRecordsBanner />

    // const firstThree = (pDetails.length > 9) ? pDetails.slice(0, 8) : pDetails.slice(0, 8);
    
    const firdBusinessDetail = {
        styleID: "U2FsdGVkX18EI4k80FtcVtq4PzxA94OPqaGqyI1vxNM=",
        photoUrl: BusinessData.cdnUrl + "Original/" + BusinessData.businessImage,
    }
    return <>
       <MasonryGridContainer className={isStenciled ? "stenciled" : ""}>
            <RenderMosaicGridPhoto photo={firdBusinessDetail} key={"photo"} index={-1} hide={true} isStenciled={isStenciled} />
            {limitedPDetails.map((photo: any, index: number) => (
                <RenderMosaicGridPhoto isStenciled={isStenciled} photo={photo} key={index + "photo"} index={index} hide={false} />
            ))}
            {/* {pDetails.length > halfIndex && <AdditionalMosaicGridPhotos edit={edit} userContext={userContext} photo={pDetails[halfIndex]} numOfExtra={pDetails.length - halfIndex -1} data={data} businessData={BusinessData} />} */}
        </MasonryGridContainer>
    </>
}
function AdditionalPhotos({ edit, userContext, photo, numOfExtra, data, businessData }: { edit: Boolean, userContext: UserContext, photo: any, numOfExtra: number, data: any, businessData: any }) {
    var image = photo.styleID !== "U2FsdGVkX18EI4k80FtcVtq4PzxA94OPqaGqyI1vxNM=" ? photo.photoUrl.replace("/Original/", "Style/Original/") : photo.photoUrl
    return <div className="photo-card stencil-item " style={{ width: "100%", backgroundImage: `url(${image})`, backgroundSize: "cover", backgroundPosition: "center", cursor: "pointer", flex: ".66", position: "relative" }}>
        <div
            className="w-full h-full center-all stencil-item"
            style={{ background: "#00000066", color: "white", position: "absolute", top: "0", left: "0", textAlign: "center", overflow: "hidden" }}
            onClick={(event) => !edit && PhotoGallaryPopup({ userContext, photo, numOfExtra, data, businessData })}
        >
            + {numOfExtra} more
        </div>
    </div>

}

function AdditionalGridPhotos({ edit, userContext, photo, numOfExtra, data, businessData }: { edit: Boolean, userContext: UserContext, photo: any, numOfExtra: number, data: any, businessData: any }) {
    var image = photo.styleID !== "U2FsdGVkX18EI4k80FtcVtq4PzxA94OPqaGqyI1vxNM=" ? photo.photoUrl.replace("/Original/", "Style/Original/") : photo.photoUrl
    return <div className="photo-card stencil-item" style={{ height: "300px", width: "100%", backgroundImage: `url(${image})`, backgroundSize: "cover", backgroundPosition: "center", cursor: "pointer", flex: ".66", position: "relative" }}>
        <div
            className="w-full h-full center-all stencil-item"
            style={{ background: "#00000066", color: "white", position: "absolute", top: "0", left: "0", textAlign: "center", overflow: "hidden" }}
            onClick={(event) => !edit && PhotoGallaryPopup({ userContext, photo, numOfExtra, data, businessData })}
        >
            + {numOfExtra} more
        </div>
    </div>

}

function AdditionalGridPhotos3({ edit, userContext, photo, numOfExtra, data, businessData }: { edit: Boolean, userContext: UserContext, photo: any, numOfExtra: number, data: any, businessData: any }) {
    var image = photo.styleID !== "U2FsdGVkX18EI4k80FtcVtq4PzxA94OPqaGqyI1vxNM=" ? photo.photoUrl.replace("/Original/", "Style/Original/") : photo.photoUrl
    return <div className="photo-card stencil-item" style={{ height: "300px", marginBottom:"16px", backgroundImage: `url(${image})`, backgroundSize: "cover", backgroundPosition: "center", cursor: "pointer", flex: ".66", position: "relative" }}>
        <div
            className="w-full h-full center-all stencil-item"
            style={{ background: "#00000066", color: "white", position: "absolute", top: "0", left: "0", textAlign: "center", overflow: "hidden" }}
            onClick={(event) => !edit && PhotoGallaryPopup({ userContext, photo, numOfExtra, data, businessData })}
        >
            + {numOfExtra} more
        </div>
    </div>

}



function PhotoGallaryPopup({ userContext, photo, numOfExtra, data, businessData }: { userContext: UserContext, photo: any, numOfExtra: number, data: any, businessData: any }) {
    const firstIndexData = data;
    firstIndexData.records.photoDetail = firstIndexData.records.photoDetail.slice(0, 9)
    return ReactDom.render(
        <PhotoGallaryPop userContext={userContext} data={firstIndexData} businessData={businessData} />,
        document.getElementById("OpenDialog")
    );
}

function RenderPhoto({ photo, index, hide, isStenciled }: { photo: any, index: number, hide: boolean, isStenciled: boolean }) {
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    if (photo.styleID == 0 || photo.styleID == "0") {
        return <></>
    }
    const photoUrl: string = photo.styleID !== "U2FsdGVkX18EI4k80FtcVtq4PzxA94OPqaGqyI1vxNM=" ? photo.photoUrl.replace("/Original/", "Style/Original/") : photo.photoUrl;

    //network occasionally shows excessive network calls to /undefined due to invalid photoURL so ignore this
    if (photoUrl.endsWith("/undefined")) {
        return <></>;
    }
    const img = new Image()

    img.src = photo.photoUrl
    img.onload = () => {
        setWidth(img.width)
        setHeight(img.height)
    }

    if (photoUrl == "undefined" || photoUrl == null || photoUrl == "") {
        return <></>
    }


    return <div
        className="photo-card card-images stencil-item h-full"
        style={{ width: "100%", maxWidth: "36%", backgroundImage: `url(${photoUrl})`, backgroundSize: "cover", backgroundPosition: "center", cursor: "pointer", flex: "1", display: (hide ? "none" : "block") }}
    >
        <a
            data-pswp-type={photo.styleID !== "U2FsdGVkX18EI4k80FtcVtq4PzxA94OPqaGqyI1vxNM=" ? "html" : ""}
            data-style={photo.styleID}
            data-pswp-src={photoUrl}
            className={`no-a vgphoto-m-item`}
            id={`vgphoto-m-item-${index}`}
            data-pswp-width={width === 0 ? "" : width}
            data-pswp-height={height === 0 ? "" : height} >
            <img alt="" src={photoUrl} style={{ width: "0", height: "0", }} />
        </a>
    </div>
}

function RenderGridPhoto({ photo, index, hide, isStenciled }: { photo: any, index: number, hide: boolean, isStenciled: boolean }) {
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    if (photo.styleID == 0 || photo.styleID == "0") {
        return <></>
    }
    const photoUrl: string = photo.styleID !== "U2FsdGVkX18EI4k80FtcVtq4PzxA94OPqaGqyI1vxNM=" ? photo.photoUrl.replace("/Original/", "Style/Original/") : photo.photoUrl;

    //network occasionally shows excessive network calls to /undefined due to invalid photoURL so ignore this
    if (photoUrl.endsWith("/undefined")) {
        return <></>;
    }
    const img = new Image()

    img.src = photo.photoUrl
    img.onload = () => {
        setWidth(img.width)
        setHeight(img.height)
    }

    if (photoUrl == "undefined" || photoUrl == null || photoUrl == "") {
        return <></>
    }


    return <div
        className="photo-card card-images stencil-item h-full"
        style={{ height: "300px", width: "100%", maxWidth: "100%", backgroundImage: `url(${photoUrl})`, backgroundSize: "cover", backgroundPosition: "center", cursor: "pointer", flex: "1", display: (hide ? "none" : "block") }}
    >
        <a
            data-pswp-type={photo.styleID !== "U2FsdGVkX18EI4k80FtcVtq4PzxA94OPqaGqyI1vxNM=" ? "html" : ""}
            data-style={photo.styleID}
            data-pswp-src={photoUrl}
            className={`no-a vgphoto-m-item`}
            id={`vgphoto-m-item-${index}`}
            data-pswp-width={width === 0 ? "" : width}
            data-pswp-height={height === 0 ? "" : height} >
            <img alt="" src={photoUrl} style={{ width: "0", height: "0", }} />
        </a>
    </div>
}

function RenderGrid2Photo({ photo, index, hide, isStenciled }: { photo: any, index: number, hide: boolean, isStenciled: boolean }) {
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    if (photo.styleID == 0 || photo.styleID == "0") {
        return <></>
    }
    const photoUrl: string = photo.styleID !== "U2FsdGVkX18EI4k80FtcVtq4PzxA94OPqaGqyI1vxNM=" ? photo.photoUrl.replace("/Original/", "Style/Original/") : photo.photoUrl;

    //network occasionally shows excessive network calls to /undefined due to invalid photoURL so ignore this
    if (photoUrl.endsWith("/undefined")) {
        return <></>;
    }
    const img = new Image()

    img.src = photo.photoUrl
    img.onload = () => {
        setWidth(img.width)
        setHeight(img.height)
    }

    if (photoUrl == "undefined" || photoUrl == null || photoUrl == "") {
        return <></>
    }


    return <div
        className="photo-card card-images stencil-item h-full"
        style={{ height: "300px", width: "100%", maxWidth: "100%", backgroundImage: `url(${photoUrl})`, backgroundSize: "cover", backgroundPosition: "center", cursor: "pointer", flex: "1", display: (hide ? "none" : "block") }}
    >
        <a
            data-pswp-type={photo.styleID !== "U2FsdGVkX18EI4k80FtcVtq4PzxA94OPqaGqyI1vxNM=" ? "html" : ""}
            data-style={photo.styleID}
            data-pswp-src={photoUrl}
            className={`no-a vgphoto-m-item`}
            id={`vgphoto-m-item-${index}`}
            data-pswp-width={width === 0 ? "" : width}
            data-pswp-height={height === 0 ? "" : height} >
            <img alt="" src={photoUrl} style={{ width: "0", height: "0", }} />
        </a>
    </div>
}

// used for overlapping Layout
function RenderGrid3Photo({ photo, index, hide, isStenciled }: { photo: any, index: number, hide: boolean, isStenciled: boolean }) {
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    
    if (photo.styleID == 0 || photo.styleID == "0") {
        return <></>
    }
    const photoUrl: string = photo.styleID !== "U2FsdGVkX18EI4k80FtcVtq4PzxA94OPqaGqyI1vxNM=" ? photo.photoUrl.replace("/Original/", "Style/Original/") : photo.photoUrl;

    //network occasionally shows excessive network calls to /undefined due to invalid photoURL so ignore this
    if (photoUrl.endsWith("/undefined")) {
        return <></>;
    }
    const img = new Image()

    img.src = photo.photoUrl
    img.onload = () => {
        setWidth(img.width)
        setHeight(img.height)
    }

    if (photoUrl == "undefined" || photoUrl == null || photoUrl == "") {
        return <></>
    }


    return <div
        className="overlap-photo-card card-images stencil-item h-full"
        style={{ width: "100%", maxWidth: "100%", backgroundImage: `url(${photoUrl})`, backgroundSize: "cover", backgroundPosition: "center", cursor: "pointer", flex: "1", display: (hide ? "none" : "block") }}
    >
        <a
            data-pswp-type={photo.styleID !== "U2FsdGVkX18EI4k80FtcVtq4PzxA94OPqaGqyI1vxNM=" ? "html" : ""}
            data-style={photo.styleID}
            data-pswp-src={photoUrl}
            className={`no-a vgphoto-m-item`}
            id={`vgphoto-m-item-${index}`}
            data-pswp-width={width === 0 ? "" : width}
            data-pswp-height={height === 0 ? "" : height} >
            <img alt="" src={photoUrl} style={{ width: "0", height: "0", }} />
        </a>
    </div>
}

function RenderMosaicGridPhoto({ photo, index, hide, isStenciled }: { photo: any, index: number, hide: boolean, isStenciled: boolean }) {
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        function handleResize() {
            setIsMobile(window.innerWidth < 768);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

     let photoCardStyle: React.CSSProperties = {
        flex: "1",
        display: (hide ? "none" : "block"),
        width: "100%",
        marginBottom: marginBottom,
        height: isMobile ? "200px" : (index === 0 || index % 2 === 0) ? "800px" : "400px",
    };

    // Define different image heights based on index or other conditions
    if (index === 0) {
        // First image can have a larger height
        photoCardStyle = {
            ...photoCardStyle,
            height: "800px",
            width: "100%", // Customize the height as needed
        };
    } else if (index % 2 === 0) {
        // Even-indexed images can have a medium height
        photoCardStyle = {
            ...photoCardStyle,
            height: "800px", // Customize the height as needed
        };
    } else {
        // Odd-indexed images can have a smaller height
        photoCardStyle = {
            ...photoCardStyle,
            height: "400px", // Customize the height as needed
        };
    } 

    if (photo.styleID == 0 || photo.styleID == "0") {
        return <></>
    }
    const photoUrl: string = photo.styleID !== "U2FsdGVkX18EI4k80FtcVtq4PzxA94OPqaGqyI1vxNM=" ? photo.photoUrl.replace("/Original/", "Style/Original/") : photo.photoUrl;

    //network occasionally shows excessive network calls to /undefined due to invalid photoURL so ignore this
    if (photoUrl.endsWith("/undefined")) {
        return <></>;
    }
    const img = new Image()

    img.src = photo.photoUrl
    img.onload = () => {
        setWidth(img.width)
        setHeight(img.height)
    }

    if (photoUrl == "undefined" || photoUrl == null || photoUrl == "") {
        return <></>
    }

    return <div
        className="photo-card card-images stencil-item h-full"
        style={{
            ...photoCardStyle,
            maxWidth: "100%",
            backgroundImage: `url(${photoUrl})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            cursor: "pointer",
        }}
    >
        <a
            data-pswp-type={photo.styleID !== "U2FsdGVkX18EI4k80FtcVtq4PzxA94OPqaGqyI1vxNM=" ? "html" : ""}
            data-style={photo.styleID}
            data-pswp-src={photoUrl}
            className={`no-a vgphoto-m-item`}
            id={`vgphoto-m-item-${index}`}
            data-pswp-width={width === 0 ? "" : width}
            data-pswp-height={height === 0 ? "" : height} >
            <img alt="" src={photoUrl} style={{ width: "0", height: "0", }} />
        </a>
    </div>
}