import React, { Fragment } from "react";
import { UserContext } from "../Definitions/UserContext";
import { NoRecordsBanner } from '../OopsBanner';

export default function RenderBusinessName({ data, layout = "1", siteData }: { data: any, layout: string, siteData: UserContext }) {
    if (data?.isStenciled) {
        const style = {
            maxHeight: "1em",
            overflow: "hidden",
            opacity: 1
        }
        return <div className="stenciled  flex-column gap-small">
            <div className='w-full stencil-item' style={style}>This page contains basic business name.</div>           
        </div>
    }

    else if (!data.businessName || data.businessName.length == 0)
        return <NoRecordsBanner message='Business Name is not available.' />

    const businessName = data.businessName;//.replace(/&amp;/g, "&").replace(/&lt;/g, "<").replace(/&gt;/g, ">");

    switch (layout) {

        case "h-1":
            return <h1 dangerouslySetInnerHTML={{ __html: businessName }}></h1>
        case "h-2":
            return <h2 dangerouslySetInnerHTML={{ __html: businessName }}></h2>
        case "h-3":
            return <h3 dangerouslySetInnerHTML={{ __html: businessName }}></h3>
        case "h-4":
            return <h4 dangerouslySetInnerHTML={{ __html: businessName }}></h4>
        case "p-1":
            return <p className="vp-1" dangerouslySetInnerHTML={{ __html: businessName }}></p>
        case "p-2":
            return <p className="vp-2" dangerouslySetInnerHTML={{ __html: businessName }}></p>
        case "p-3":
            return <p className= "vp-3" dangerouslySetInnerHTML={{ __html: businessName }}></p>
    
        default:
            return <span dangerouslySetInnerHTML={{ __html: businessName }}></span> //setting inner to handle special chars and emojies
    }
}
