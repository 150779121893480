import React from "react";
import { GetNodeComponenentAttributeBinding, GetNodeDomAttributes } from "../Utilities/GetNodeDomAttributes";
import { WBRenderProps } from "../Definitions/WBRenderProps";
import { LinkTypeSeperator, LinkTypes } from "../Definitions/LinkData";
import { PageData, UserContext } from "../Definitions/UserContext";
import LayoutNode, { getNodeComponentAttributeValue, setNodeComponentAttributeValue } from "../Definitions/LayoutNode";
import { wBComponentTypeNames } from "../Definitions/WBComponentTypeNames";
import RenderButtonDropdown from "./RenderButtonDropdown";

export default function RenderButton({
  screenSize,
  component,
  isEditor,
  userContext,
}: WBRenderProps) {
  let link = component.componentAttributes["link"] ?? "";

  let origLink = link;

  link = GetLinkFromLinkAttribute(link, userContext.pages);

  let btnType = component.componentAttributes["buttonType"] ?? "primary";

  const targetType = getNodeComponentAttributeValue(component,"linkOpenType","_self")??"_self";
  const hrefClass = link && link.startsWith("#") ? " scroll-link " : "";


  const isMultiLink = origLink.includes(LinkTypes.MultiPage);

  let caret = "";

  //anchor tags inside other anchor tags are invalid, traverse up the parent list and if inside another link render as a div instead.
  if(!isEditor){

    let parent = component.parent;
    while(parent){
 
      if(parent.typename === wBComponentTypeNames.container){
     
        if(parent.componentAttributes["isLink"]==="true"){
          
          return     <div
      
          {...GetNodeDomAttributes(
            "v-button no-a center-all "  + btnType ,
            component,
            screenSize,
            isEditor
          )}
    
     
          dangerouslySetInnerHTML={{ __html: component.content ?? "" }}
          onClick={
            isEditor
              ? (e) => {
                  e.preventDefault();
                }
              : (e) => {}
          }
        ></div>

     
        }
      }
      parent = parent.parent;
    }


  }


  if(isMultiLink){


    let split = origLink.split(LinkTypeSeperator);
    if(split.length>0)
      split = split[0].split(",");

    let pages: { url: string; name: string }[] = [];

    for(let i = 0;i<userContext.pages.length;i++){


        const page = userContext.pages[i];
        if(split.includes(page.pageId)){

          pages.push({url:page.url,name:page.name});
   
        }

    
        for(let e = 0;page.children&&e<page.children.length;e++){
          if(split.includes(page.children[e].pageId)){
            pages.push({url:page.children[e].url,name:page.children[e].name});
         
          }
        }

    }

  

      setNodeComponentAttributeValue(component,"menuPages",JSON.stringify(pages));
      setNodeComponentAttributeValue(component,"savedContent",component.content??"");

      if(pages.length>1 &&!isEditor)
      return  <div {...GetNodeDomAttributes("v-button relative center-all "+btnType ,component,screenSize,isEditor)} {...GetNodeComponenentAttributeBinding(component, isEditor)}>


       <RenderButtonDropdown component={component}/>
      </div>;
      //render the button/dropdown
    
   
    if(pages.length>1&&isEditor){
      caret = " dropown-caret ";
    }

    if(pages.length == 1){
      link = pages[0].url;
    }
  }


  
  const missingLinkIndication = ((link&&link.length>3)||!component.content||component.content.length<2) ? "" : " missing-link ";
  return (
    <a
      rel="noreferrer"
      {...GetNodeDomAttributes(
        "v-button no-a center-all " + hrefClass + btnType + missingLinkIndication+caret,
        component,
        screenSize,
        isEditor
      )}
      target={targetType}
      href={link}
      dangerouslySetInnerHTML={{ __html: component.content ?? "" }}
      onClick={
        isEditor
          ? (e) => {
              e.preventDefault();
            }
          : (e) => {}
      }
    ></a>
  );
}

export function GetLinkFromLinkAttribute(link: string, pages: PageData[],outName?:{name:string}) {
  const split = link.split(LinkTypeSeperator);

  if (split.length > 1) {
    const lType = split[1];
    link = split[0];
    const saveURL = (split.length>2)?split[2]:"/\/";
    switch (lType) {
      case LinkTypes.Page:
        for (let i = 0; i < pages.length; i++) {

          const page = pages[i];
          if (pages[i].pageId == link||pages[i].url === saveURL) {
            if(outName){
              outName.name = page.name;
            }
            return pages[i].url;
          }
      

          for (let e = 0; page.children && e < page.children.length; e++) {
            if (page.children[e].pageId == link||page.children[e].url === saveURL) {
              if(outName){
                outName.name = page.name;
              }
              return page.children[e].url;
            }
          }

        }

        return "#";

      case LinkTypes.Scroll:
        return "#v" + split[0];
  
      case LinkTypes.Email:
        return "mailto:" + split[0];
      
      case LinkTypes.DailyDeal:
        return split[0];

      case LinkTypes.Form:
        return split[0];
        
      case LinkTypes.PromoCode:
        return "#";
      
      case LinkTypes.Phone:
        return "tel:" + split[0].replace(/[^0-9]/g, "");

      default:
        return link;
    }
  }

  return link;
}
