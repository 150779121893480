import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AsyncAjaxGet } from 'components/AsyncAjaxServiceV2';


let BusinessData = [];
const VagaroAPIEnum = {
    businessMultilocation: "businesslogincustomerdetails/businesslocationasync"
};
export const BusinessLocation = createAsyncThunk(
    'GetBusinessLocation',
    async (Requestdata, thunkApi) => {
        var IsCheckMultilocation = false;
        const items = JSON.parse(localStorage.getItem('location'));
        if (items) {
            IsCheckMultilocation = true;
        }
        await AsyncAjaxGet(
            VagaroAPIEnum.businessMultilocation + "?" + (Requestdata.VGURL != "" ? ("SiteName=&VagaroURL=" + Requestdata.VGURL) : ("SiteName=" + Requestdata.SiteName + "&VagaroURL=")) + "&IsCheckMultilocation=" + IsCheckMultilocation,
            Requestdata.access_token,
            function onSuccess(data) {
                if (data != null && data.data != null) {
                    BusinessData = data.data.data;
                    if (typeof BusinessData != "undefined" && BusinessData != null && BusinessData != "" && BusinessData.length > 0) {
                        localStorage.setItem('VgBUrl', Requestdata.VGURL);
                        if (IsCheckMultilocation) {
                            let BData = BusinessData.filter(
                                (col) => col.encBIDHash == items.bidHash && col.isSelected == true
                            );
                            if (typeof BData == "undefined" ||
                                BData == null ||
                                BData == "" || BData.length == 0) {
                                localStorage.removeItem('location');
                            }
                        }
                    }
                    $(".wbloader").css("display", "none");
                }
                else {
                    return thunkApi.rejectWithValue([]);
                }
            },
            function OnError() {
                return thunkApi.rejectWithValue([]);
            }
        );
        return thunkApi.fulfillWithValue(BusinessData);
    }
)

export const BusinessLocationSlice = createSlice({
    name: 'BusinessLocation',
    initialState: {        
        data: [],
        isCompletedLocationCall: false
    },
    extraReducers: {
        [BusinessLocation.fulfilled]: (state, action) => {
            if (typeof action.payload !== undefined && action.payload !== null) {             
                state.data = action.payload;
                state.isCompletedLocationCall = true;
            }
        }
    }
})
export default BusinessLocationSlice.reducer;
